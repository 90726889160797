import React, { Component } from "react";
import TimeSeriesGraph from '../items/TimeSeriesGraph';

/**
 * TunnelPerformance component class
 * Description:
 * Display the tunnel RTT and Drop rate graphs for 2 hours backward and refresh every 1 min
 */
class TunnelPerformance extends Component {

  render() {

    const lineInfoOptions = {
      'RTT':{
        'color':'#1F618D',
        'yval': (d)=> {
          const rtt = (d.rtt)? d.rtt:0;
          return rtt;
        },
        'axisLocation': 'right',
        'grid': 'width',
        'units': ' [msec]'
      },
      'Status':{
        'color':'#000000',
        'yval': (d)=> {
          const status = (d.status && d.status === 'up')? 100:0;
          return status;
        },
        'axisLocation': 'right',
        'grid': 'width',
        'units': ' [up/down]'
      },
      'DropRate':{
        'color':'#B9770E',
        'yval': (d)=> {
          const drop_rate = (d.drop_rate || d.drop_rate === 0)? d.drop_rate:100;
          return drop_rate;
        },
        'axisLocation': 'right',
        'grid': 'width',
        'units': ' [%]'
      }
    };

    const lineInfo = {};
    lineInfo[this.props.graphType] = lineInfoOptions[this.props.graphType];

    return (
      <TimeSeriesGraph
        height={200}
        lastUpdate={this.props.lastUpdate}
        traffic={this.props.traffic}
        lineInfo={lineInfo}
        divId={this.props.divId}
      />
    );
  }
}

export default TunnelPerformance;
