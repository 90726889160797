import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import { getQRCode } from '../../../redux/reducers/Auth'
import { mainRedirectTo } from '../../../redux/reducers/Main'
import QRCode from 'qrcode'
import Verify from './Verify';

let qrCodeInterval = null;

const clearTimeout = () => {
  if (qrCodeInterval) {
    clearInterval(qrCodeInterval)
    qrCodeInterval = null;
  }
}

const Scan = props => {
  const [dataUrl, setDataUrl] = useState(false);

  // clean up only
  useEffect(() => {
    return () => {
      clearTimeout()
    };
  }, []);

  const startQrCodeInterval = () => {
    if (!qrCodeInterval) {
      qrCodeInterval = setInterval(() => {
        fetchQRCode()
      }, 30000)

      fetchQRCode()
    }
  }

  const fetchQRCode = () => {
    props.getQRCode(res => {
      QRCode.toDataURL(res.configUri, function (err, url) {
        setDataUrl(url)
      })
    });
  }

  if (dataUrl) {
    return (
      <div>
        <div className="text-center">
         <small className="mt-3 text-muted">Scan the QR code with an authenticator application</small>
        </div>
        <div className="mfa-scan">
          <div>
            <img src={dataUrl} className="img-fluid" alt="qrCode" />
          </div>
        </div>
        <div className="mt-2">
          <Verify isFirstValidation={true} onVerified={props.onVerified} />
        </div>
      </div>
    )
  }

  if (!dataUrl) {
    return (
      <div>
        <div className="text-center">
          {props.showTitle === false ? null : (
            <div>Two-factor authentication has been enabled for your account</div>
          )}
          <small className="mt-3 text-muted">Prepare an authenticator application (such as Google Authenticator) and continue to get QR code</small>
        </div>
        <div className="text-center mt-3">
          <Button type="button" color="primary" onClick={startQrCodeInterval}>
            Get QR Code
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getQRCode: (cb) => dispatch(getQRCode(cb)),
    mainRedirectTo: (path) => dispatch(mainRedirectTo(path))
  }
}

export default connect(null, mapDispatchToProps)(Scan);
