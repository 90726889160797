import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, CardBody, Card, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from 'react-tooltip';
import { rateConversion } from '../utils/Conversions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { TT, LanguageContext } from '../containers/Language';

const initialPagination = 10;
class Admin extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props)

    this.state = {
      showAll: false,
      mounted: true
    }
    this.paginationOptions = this.paginationOptions.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    this.props.getInfo(0, 10, {"key":"account_name","value":"asc"}, {});
  }

  onTableChange = (type, newState) => {
    if (this.state.mounted) { // Data already pulled on mount
      this.setState({mounted:false});
      return;
    }
    this.setState({ showAll: false })
    const page = newState.page;// skip = (page * size)
    const size = newState.sizePerPage;// limit

    let sort =  null;
    if (newState.sortField) {
      sort = {key: newState.sortField, value: newState.sortOrder}
    }

    const filters = {};
    for (const key in newState.filters) {
      filters[key] = newState.filters[key].filterVal;
    }

    this.props.getInfo(page, size, sort, filters);
  }

  paginationOptions = () => {
    const toTTString = this.context.toTTString
    const self = this;
    return {
      paginationSize: initialPagination,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      totalSize:  self.props.info ? self.props.info.accounts.all : 0,
      paginationTotalRenderer: (from, to, size) => {
        return (
          <span className="react-bootstrap-table-pagination-total">
            <TT params={{from: from, to: to, size: self.props.info ? self.props.info.accounts.all : size}}>Showing #from# to #to# of #size# Results</TT>
          </span>
        )
      },
      sizePerPageList: [{text: initialPagination.toString(), value: initialPagination}, {text: '50', value: 50}, {text: '100', value: 100}]
    }
  };

  render() {
    const toTTString = this.context.toTTString
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => {
        return (
          <Card>
          <CardBody>
            <Row>
              <Col md={2}><b><TT>Account ID</TT></b></Col>
              <Col>{row.account_id}</Col>
            </Row>

            <Row className="mt-3">
              <Col md={2}><b><TT>Country</TT></b></Col>
              <Col><TT>{row.country}</TT></Col>
            </Row>

            <Row className="mt-3">
              <Col md={2}><b><TT>Billing Id</TT></b></Col>
              <Col>{row.billing_customer_id}</Col>
            </Row>

            <Row className="mt-3">
              <Col md={2} size="sm"><b><TT>Account Bytes</TT>:</b></Col>
              <Col md={6}>
                <BootstrapTable
                  striped
                  size="sm"
                  condensed
                  keyField="organization_id"
                  rowStyle={{ height: '10px'}}
                  data={Object.keys(row.account_bytes ?? []).sort((a, b) => new Date(a) - new Date(b))}
                  columns={[
                    {
                      text: <TT>Month</TT>,
                      dataField: '',
                      sort: false,
                      editable: false,
                      formatter: (content, internalRow) => {
                        return internalRow
                      },
                      footer: row => null
                    },
                    {
                      text: <TT>Bytes</TT>,
                      dataField: 'account_bytes',
                      sort: false,
                      editable: false,
                      formatter: (content, internalRow) => {
                        return rateConversion(row.account_bytes[internalRow].bytes, 2)
                      },
                      footer: () => {
                        let bytes = 0;
                        for (const mon in row.account_bytes) {
                          bytes += row.account_bytes[mon].bytes
                        }
                        return rateConversion(bytes, 2, "")
                      }
                    },
                  ]}
                  noDataIndication={toTTString('No  Bytes')}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={2} size="sm"><b><TT>Users</TT>:</b></Col>
              <Col md={6}>
                <BootstrapTable
                  striped
                  size="sm"
                  condensed
                  keyField="email"
                  rowStyle={{ height: '10px'}}
                  data={row.users}
                  columns={[
                    {
                      text: <TT>Email</TT>,
                      dataField: 'email',
                      sort: false,
                      editable: false,
                    },
                    {
                      text: <TT>Role</TT>,
                      dataField: 'role',
                      sort: false,
                      editable: false,
                      formatter: (content, row) => {
                        return <TT>{row.role}</TT>
                      }
                    }
                  ]}
                  noDataIndication={toTTString('No  Users')}
                />
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={12}>
              <ToolkitProvider
                  keyField="organization_id"
                  data={row.organizations}
                  columns={ organizationsColumns(row, toTTString) }
                  exportCSV={{
                    fileName: `flexiwan_${row.account_name}_organizations.csv`
                  }}
                >
                  { orgProps => (
                    <>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Organization header here to be in the same line as export button */}
                      <div><b><TT>Organizations</TT>:</b></div>
                      <FwExportCSVButton { ...orgProps.csvProps } text={toTTString("Export Organizations")}/>
                    </div>

                    <BootstrapTable
                      striped
                      condensed
                      { ...orgProps.baseProps }
                      noDataIndication={toTTString('No Organizations')}
                      defaultSorted={[{ dataField: 'organization_name', order: 'asc' }]}
                    />
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
          </Card>
        )
      }
    };

    return(
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Admin</TT></BreadcrumbItem>
        </Breadcrumb>
        {this.props.info ? (
          <>
            <h4><TT>flexiWAN Admin</TT></h4>
            <Card>
              <CardBody>
                <Row>
                <Col md={1}><TT>Accounts</TT>:</Col>
                  <Col md={1}>
                    {this.props.info ? this.props.info.accounts.all : 0}
                  </Col>
                  <Col md={1}><TT>Users</TT>:</Col>
                  <Col md={1}>
                    {this.props.info.num_registered_users}
                  </Col>
                  <Col className="col-auto"><TT>Connected Devices</TT>:</Col>
                  <Col md={1}>
                    {this.props.info.numConnectedDevices}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <Row className="mt-2">
                  <Col>
                    <ToolkitProvider
                      keyField="account_id"
                      data={this.props.info ? this.props.info.accounts.data : [] }
                      columns={ adminColumns(this.props.info ? this.props.info.accounts.data : [], toTTString) }
                      exportCSV={{
                        fileName: 'flexiwan_accounts.csv'
                      }}
                    >
                      {props => (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            {/* Accounts header here to be in the same line as export button */}
                            <div><b><TT>Accounts</TT>:</b></div>
                            <div className="d-flex">
                                <FwExportCSVButton
                                  { ...props.csvProps }
                                  setAllState={() => this.setState({showAll: true})}
                                  text={toTTString("Export All Accounts")}
                                  fetchAll
                                  isShowAllMode={this.state.showAll}
                                  getInfo={this.props.getInfo}
                                />
                              </div>
                          </div>

                          <BootstrapTable
                            remote={{ pagination: this.state.showAll ? false : true, filter: true, sort:  this.state.showAll ? false : true } }
                            striped
                            filterPosition="top"
                            condensed
                            { ...props.baseProps }
                            onTableChange={this.onTableChange}
                            filter={filterFactory()}
                            defaultSorted={[{ dataField: 'account_name', order: 'asc' }]}
                            pagination={paginationFactory(this.paginationOptions())}
                            noDataIndication={toTTString('No Data')}
                            rowClasses="hover-pointer"
                            expandRow={ expandRow }
                          />
                        </>
                      )}
                    </ToolkitProvider>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col md={2}><TT>Monthly System Stats</TT>:</Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      keyField="month"
                      data={this.props.info ? this.props.info.monthlyStats : [] }
                      noDataIndication={toTTString('No Stats')}
                      columns={[
                        {
                          text: <TT>Month</TT>,
                          dataField: 'month',
                          sort: false,
                          editable: false,
                        },
                        {
                          text: <TT>Total Bytes</TT>,
                          dataField: 'totalBytes',
                          sort: false,
                          editable: false,
                          formatter: (content, row) => {
                            return rateConversion(row.totalBytes, 2, "");
                          }
                        },
                        {
                          text: <TT>Contributing Orgs</TT>,
                          dataField: 'activeOrgs',
                          sort: false,
                          editable: false,
                        },
                        {
                          text: <TT>Contributing Devices</TT>,
                          dataField: 'activeDevices',
                          sort: false,
                          editable: false,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col md={2}><TT>Connected Devices</TT>:</Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      keyField="accountId"
                      data={this.props.info ? this.props.info.connectedDevices : [] }
                      noDataIndication={toTTString('No Connected devices')}
                      columns={[
                        {
                          text: <TT>Account</TT>,
                          dataField: 'accountName',
                          sort: false,
                          editable: false,
                          formatter: (content, row) => {
                            return (
                              <>
                                <span data-tip data-for='account-data'>{row.accountId}</span>
                                <ReactTooltip id={`account-data`}>
                                  {row.accountName}
                              </ReactTooltip>
                            </>)
                          }
                        },
                        {
                          text: <TT>Org</TT>,
                          dataField: 'orgName',
                          sort: false,
                          editable: false,
                          formatter: (content, row) => {
                            return (
                              <>
                                <span data-tip data-for='org-data'>{row.org}</span>
                                <ReactTooltip id={`org-data`}>
                                  {row.orgName}
                              </ReactTooltip>
                            </>)
                          }
                        },
                        {
                          text: <TT>Machine</TT>,
                          dataField: 'machineID',
                          sort: false,
                          editable: false,
                        },
                        {
                          text: <TT>Status</TT>,
                          dataField: 'status',
                          sort: false,
                          editable: false,
                        },
                        {
                          text: <TT>Version</TT>,
                          dataField: 'version',
                          sort: false,
                          editable: false,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>)
        : null}
      </React.Fragment>
    );
  }
}

const FwExportCSVButton = (props) => {
  const handleClick = () => {
    if (props.fetchAll && !props.isShowAllMode) {
      // page zero and size 0 == all
      props.setAllState();
      props.getInfo(0, 0, null, {}, () => {
          props.onExport();
      });
    } else {
      props.onExport();
    }
  };
  return (
    <div>
      <button className="btn btn-primary btn-sm m-2" onClick={ handleClick }>{props.text}</button>
    </div>
  );
};

const adminColumns = (data, toTTString) => {
  const res = [
    {
      text: toTTString("Account Id"),
      dataField: 'account_id',
      hidden: true,
      sort: false,
      editable: false,
      filter: textFilter({
        placeholder: '',
        delay: 1500
      })
    },
    {
      text: toTTString("Account Name"),
      dataField: 'account_name',
      sort: false,
      editable: false,
      filter: textFilter({
        placeholder: '',
        delay: 1500
      })
    },
    {
      text: toTTString("Country"),
      dataField: 'country',
      hidden: true,
      formatter: (row, content) => {
        return toTTString(row.country)
      }
    },
    {
      text: toTTString("Orgs"),
      dataField: 'num_organizations',
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerFormatter: (colm, idx, components) => {
        return (
        <>
          <span data-tip data-for='orgs-header'><TT>Orgs</TT></span>
          <ReactTooltip id={`orgs-header`}>
            <TT>Current Organizations</TT>
        </ReactTooltip>
        {components.sortElement}
        </>)
      },
      csvFormatter: (content, row) => {
        if (row.organizations.length) {
          return row.organizations.map(o => o.organization_name).join(', ')
        }
        return '';
      }
    },
    {
      text: toTTString("Current"),
      dataField: 'num_devices',
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "17%" };
      },
      headerFormatter: (colm, idx, components) => {
        return (
        <>
          <span data-tip data-for='devices-header'><TT>Current</TT></span>
          <ReactTooltip id={`devices-header`}>
            <TT>Current Devices/Features</TT>
          </ReactTooltip>
        {components.sortElement}
        </>)
      },
      formatter: (content, row) => {
        const items = [];

        if (content) {
          items.push(<>registered devices: {content}</>)
        }

        for (const feature in row?.billingInfo?.features ?? {}) {
          items.push(<><br />{feature}: {row.billingInfo.features[feature].current}</>)
        }

        return items
      }
    },
    {
      text: toTTString("Tunnels"),
      dataField: 'num_tunnels',
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: (colm, idx, components) => {
        return (
        <>
          <span data-tip data-for='tunnels-header'><TT>Tunnels</TT></span>
          <ReactTooltip id={`tunnels-header`}>
            <TT>Current Tunnels</TT>
        </ReactTooltip>
        {components.sortElement}
        </>)
      }
    },
    {
      text: toTTString("Next Billing"),
      dataField: 'billingInfo.max',
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      headerFormatter: (colm, idx, components) => {
        return (
        <>
          <span data-tip data-for='next-billing-header'><TT>Next Billing</TT></span>
          <ReactTooltip id={`next-billing-header`}>
            <TT>The current max devices in current billing period</TT>. <TT>This may change if more devices are registered</TT>
          </ReactTooltip>
        </>)
      },
      csvFormatter: (content, row) => {
        if (row?.billingInfo?.max) {
          return row.billingInfo.max;
        }
        return '';
      },
      formatter: (content, row) => {
        const items = [];

        if (content) {
          items.push(<>devices: {content}</>)
        }

        for (const feature in row?.billingInfo?.features ?? {}) {
          items.push(<><br />{feature}: {row.billingInfo.features[feature].max}</>)
        }

        return items
      }
    },
    {
      text: toTTString("Previous Billing"),
      dataField: 'billingInfo.lastBillingMax',
      sort: false,
      editable: false,
      formatter: (content, row) => {
        const items = [];

        if (row?.billingInfo?.lastBillingMax) {
          items.push(<>devices: {content}</>)
        }

        for (const feature in row?.billingInfo?.features ?? {}) {
          items.push(<><br />{feature}: {row.billingInfo.features[feature].lastBillingMax}</>)
        }

        return (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {items}
          </div>
          {row?.billingInfo?.lastBillingDate ? (
            <small className="text-muted"> {new Date(row.billingInfo.lastBillingDate).toDateString()}</small>
          ) : null}
        </div>);
      },
      headerFormatter: (colm, idx, components) => {
        return (
        <>
          <span data-tip data-for='previous-billing-header'><TT>Previous Billing</TT></span>
          <ReactTooltip id={`previous-billing-header`}>
            <TT>Number of device billed in the previous billing period</TT>
          </ReactTooltip>
        {components.sortElement}
        </>)
      },
      csvFormatter: (content, row) => {
        if (row?.billingInfo?.lastBillingMax) {
          return row.billingInfo.lastBillingMax;
        }
        return '';
      }
    },
    {
      text: toTTString("Previous Billing Date"),
      dataField: 'billingInfo.lastBillingDate',
      hidden: true,
      csvFormatter: (content, row) => {
        if (row.billingInfo.lastBillingDate) {
          return `${new Date(row.billingInfo.lastBillingDate).toDateString()}`
        }
        return '';
      }
    },
    {
      text: toTTString("Users"),
      dataField: null,
      hidden: true,
      csvFormatter: (content, row) => {
        if (row.users.length) {
          return row.users.map(u => `${u.name} (${u.email} - ${toTTString(u.role)})`).join(', ')
        }
        return '';
      }
    }
  ];

  const d = new Date();
  d.setDate(1)
  for (var i = 0; i < 6; i++) {
    const month = d.toLocaleDateString();
    res.push({
      text: {month} + '-' + toTTString('Bytes'),
      dataField: null,
      hidden: true,
      csvFormatter: (content, row) => {
        if (row.account_bytes && row.account_bytes[month]) {
          return rateConversion(row.account_bytes[month].bytes, 2, "")
        }
        return '';
      }
    })

    res.push({
      text: {month} + '-' + toTTString('Devices'),
      dataField: null,
      hidden: true,
      csvFormatter: (content, row) => {
        if (row.account_bytes && row.account_bytes[month]) {
          return row.account_bytes[month].devices
        }
        return '';
      }
    })

    d.setMonth(d.getMonth() - 1)
  }

  return res;
}

const organizationsColumns = (accountRow, toTTString) => [
  {
    text: toTTString("ID"),
    dataField: 'organization_id',
    sort: false,
    editable: false,
  },
  {
    text: toTTString("Name"),
    dataField: 'organization_name',
    sort: true,
    editable: false,
  },
  {
    text: toTTString("Current"),
    dataField: 'num_devices',
    sort: true,
    editable: false,
    headerStyle: (colum, colIndex) => {
      return { width: "17%" };
    },
    formatter: (content, orgRow) => {
      const items = [];

      items.push(<><TT>registered devices</TT>: {content}</>)

      for (const feature in orgRow?.billingInfo?.features ?? {}) {
        items.push(<><br />{feature}: {orgRow.billingInfo.features[feature].current}</>)
      }

      return items
    }
  },
  {
    text: toTTString("Tunnels"),
    dataField: 'num_tunnels',
    sort: true,
    editable: false,
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
  },
  {
    text: toTTString("Bytes"),
    dataField: null,
    sort: true,
    editable: false,
    sortValue: (colum, row) => {
      let bytes = 0;
      for (const mon in row.bytes) {
        bytes += row.bytes[mon]
      }
      return bytes;
    },
    headerFormatter: (colm, idx, components) => {
      return (
      <>
        <span data-tip data-for='org-bytes-header'><TT>Bytes</TT></span>
        <ReactTooltip id={`org-bytes-header`}>
          <TT>Bytes in current calender month</TT>. (<TT>Hover wil show bytes history</TT>)
      </ReactTooltip>
      {components.sortElement}
      </>)
    },
    csvFormatter: (col, row) => {
      let bytes = 0;
      for (const mon in row.bytes) {
        bytes += row.bytes[mon]
      }
      return rateConversion(bytes, 2, "")
    },
    formatter: (colum, row) => {
      let bytes = 0;
      for (const mon in row.bytes) {
        bytes += row.bytes[mon]
      }
      return (
        <>
          <span data-tip data-for={`org-${row.organization_id}`}>
            {rateConversion(bytes, 2, "")}
          </span>
          {
            row.bytes ? (
              <ReactTooltip id={`org-${row.organization_id}`}>
                {
                  Object.keys(row.bytes).map(b =>
                    <>
                    <span><b>{b}:</b> {rateConversion(row.bytes[b], 2, "")}</span>
                    <br></br>
                    </>
                  )
                }
              </ReactTooltip>
            ) : null
          }
        </>
      );
    },
  },
  {
    text: toTTString("Next Billing"),
    dataField: 'billingInfo.max',
    sort: false,
    editable: false,
    formatter: (cell, orgRow, idx, extraData) => {
      const items = [];

      if (extraData.billing_customer_id && orgRow.billingInfo) {
        items.push(<><TT>devices</TT>: {orgRow.billingInfo.max}</>)
      }

      for (const feature in orgRow?.billingInfo?.features ?? {}) {
        items.push(<><br />{feature}: {orgRow.billingInfo.features[feature].max}</>)
      }

      return items
    },
    headerFormatter: (colm, idx, components) => {
      return (
      <>
        <span data-tip data-for='next-billing-header'><TT>Next Billing</TT></span>
        <ReactTooltip id={`next-billing-header`}>
          <TT>The current max devices in current billing period</TT>. <TT>This may change if more devices are registered</TT>
      </ReactTooltip>
      </>)
    },
    formatExtraData: accountRow
  },
  {
    text: toTTString("Previous Billing"),
    dataField: 'billingInfo.lastBillingMax',
    sort: false,
    editable: false,
    formatter: (content, orgRow) => {
      if (!orgRow.billingInfo) {
        return null
      }

      const items = [];

      items.push(<><TT>devices</TT>: {orgRow.billingInfo.lastBillingMax}</>)

      for (const feature in orgRow?.billingInfo?.features ?? {}) {
        items.push(<><br />{feature}: {orgRow.billingInfo.features[feature].lastBillingMax}</>)
      }

      return items
    },
    headerFormatter: (colm, idx, components) => {
      return (
      <>
        <span data-tip data-for='previous-billing-header'><TT>Previous Billing</TT></span>
        <ReactTooltip id={`previous-billing-header`}>
          <TT>Number of device billed in the previous billing period</TT>
      </ReactTooltip>
      {components.sortElement}
      </>)
    }
  },
]

export default Admin;