import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Card, CardBody } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { required, maxLength, minLength } from '../../utils/Validators';
import { FWTextInput } from '../Common';
import { TT, LanguageContext  } from '../../containers/Language'

class AddAccessToken extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      permissionTo: "account"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getMemberOptions('account',
    (resp) => {
      this.props.changeForm('addAccessToken.accessKeyPermissionTo')('account',{'silent':true});
      this.props.changeForm('addAccessToken.accessKeyEntity')(this.props.members.options[0].id,{'silent':true})
    });
  }

  handleSubmit(values) {
    console.log('Adding Access Key: ' + JSON.stringify(values));
    this.props.addAccessToken(values, this.props.resetForm);
  }

  getUserRole(permissionTo) {
    switch(permissionTo) {
      case 'account':
        return 'owner';
      case 'group':
      case 'organization':
        return 'manager';
      default:
        return 'owner';
    }
  }

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem><TT>Users</TT></BreadcrumbItem>
          <BreadcrumbItem><Link to="/accesstokens">Access Keys</Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Add Access Key</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Add Access Key</TT></h4>
        <div className="col-md-7">
          <Card id='addAccessTokenCard'>
            <CardBody>
              <Form model="addAccessToken" onSubmit={(values) => this.handleSubmit(values)}>
                <Row className="form-group">
                  <Label htmlFor="name" md={3}>
                    <TT>Name</TT>
                  </Label>
                  <Col md={9}>
                    <Control.text
                      model=".name"
                      id="name"
                      name="name"
                      placeholder={toTTString('Default')}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        minLength: minLength(2),
                        maxLength: maxLength(15),
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".name"
                      show="touched"
                      messages={{
                        required: toTTString('Required field'),
                        minLength: toTTString("Length must be at least 2"),
                        maxLength: toTTString("Length must be at most 15")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="accessKeyPermissionTo" md={3}>
                    <TT>Permission To</TT> <span className="helpTooltip" data-tip data-for='permissionTip'></span>
                    <ReactTooltip id='permissionTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                      <TT>The hierarchy level to which you want to give access to</TT>.<br/>
                      <TT>"Account" - the highest level allowing to manage or view all of the Organizations (networks, companies) in the Account</TT>.<br/>
                      <TT>"Group" - assign permission to a collection of Organizations that are part of a specific Group</TT>.<br/>
                      <TT>"Organization" - assign permission to a single Organization</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".accessKeyPermissionTo"
                      id="accessKeyPermissionTo"
                      name="accessKeyPermissionTo"
                      placeholder={toTTString("Account")}
                      className="form-control"
                      onChange={event => {
                        this.props.getMemberOptions(event.target.value,
                          (resp) => this.props.changeForm('addAccessToken.accessKeyEntity')(this.props.members.options[0].id,{'silent':true}));
                        this.props.changeForm('addAccessToken.accessKeyRole')(this.getUserRole(event.target.value),{'silent':true});
                        this.setState({ permissionTo: event.target.value })
                      }}
                    >
                    <option value="account" disabled hidden>
                      {toTTString("Account")}
                    </option>
                    <option value={"account"}>{toTTString("Account")}</option>
                    <option value={"group"}>{toTTString("Group")}</option>
                    <option value={"organization"}>{toTTString("Organization")}</option>
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="accessKeyEntity" md={3}>
                    <TT>Entity</TT> <span className="helpTooltip" data-tip data-for='entityTip'></span>
                    <ReactTooltip id='entityTip'>
                      <span>
                        <TT>The specific Account, Group or Organization name to assign the permission to</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".accessKeyEntity"
                      id="accessKeyEntity"
                      name="accessKeyEntity"
                      placeholder=""
                      className="form-control"
                      disabled={!this.props.members.options.length}
                    >
                    {this.props.members.options.length?
                    <option value={this.props.members.options[0].id} disabled hidden>
                      {this.props.members.options[0].value}
                    </option>:null}
                    {this.props.members.options.map(option => <option value={option.id} key={option.id}>{option.value}</option>)}
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="accessKeyRole" md={3}>
                    <TT>Role</TT> <span className="helpTooltip" data-tip data-for='roleTip'></span>
                    <ReactTooltip id='roleTip'>
                    <TT>The permission level</TT>.<br/>
                    <TT>Owner (for account only) - manage all organizations and the account info including billing</TT>.<br/>
                    <TT>Manager - manage the networks but not the account info and not billing</TT>.<br/>
                    <TT>Viewer - view only permission</TT>.
                    </ReactTooltip>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".accessKeyRole"
                      id="accessKeyRole"
                      name="accessKeyRole"
                      placeholder={toTTString("Owner")}
                      className="form-control"
                    >
                    <option
                      value={ this.getUserRole(this.state.permissionTo) }
                      disabled
                      hidden>
                      { this.state.permissionTo === "account" ? toTTString("Owner") : toTTString("Manager") }
                    </option>
                    {this.state.permissionTo === "account" ? <option value={"owner"}>{toTTString("Owner")}</option> : "" }
                    <option value={"manager"}>{toTTString("Manager")}</option>
                    <option value={"viewer"}>{toTTString("Viewer")}</option>
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="validity" md={3}>
                    <TT>Validity</TT>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".validityEntity"
                      id="validityEntity"
                      name="validityEntity"
                      placeholder={toTTString("Default")}
                      className="form-control"
                      disabled={true}
                    >
                      <option value={true} disabled selected>
                        {toTTString('Default')}
                        </option>
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={{ size: 10, offset: 3 }}>
                    <Control.button model="addAccessToken" disabled={{ valid: false }}
                      className="btn btn-primary"><TT>Add</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default AddAccessToken;
