import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import * as qs from 'query-string';
const { baseUrl } = window.SERVER_CONFIG;

export const Admin = (
  state = {
    info: null,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.ADMIN_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ADMIN_GET_SUCCESS:
      return { ...state, info: action.admin, isLoading: false };
    case Actions.ADMIN_GET_FAILURE:
      return { ...state, info: null, isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get admin
 **********************************************************************/
export const requestGetAdmin = () => dispatch => {
  dispatch({
    type: Actions.ADMIN_GET_REQUEST
  });
};

export const receiveGetAdmin = response => dispatch => {
  dispatch({
    type: Actions.ADMIN_GET_SUCCESS,
    admin: response
  });
};

export const failureGetAdmin = error => dispatch => {
  dispatch({
    type: Actions.ADMIN_GET_FAILURE
  });
};

export const getAdmin = (page = 0, size, sort = null, filters = {}, cb = null) => dispatch => {

  const params = { page, size };
  if (sort) {
    params['sort'] = JSON.stringify(sort);
  }
  if (Object.keys(filters).length) {
    params['filters'] = JSON.stringify(filters);
  }

  var encodedValues = qs.stringify(params);

  return dispatch(
    FWFetch({
      url: baseUrl + "admin?" + encodedValues,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAdmin());
      },
      successCB: response => {
        dispatch(receiveGetAdmin(response));
        if (cb) {
          cb();
        }
      },
      failureCB: error => {
        dispatch(failureGetAdmin(error));
      }
    })
  );
};

