import React, { Component } from "react";
import { Prompt } from 'react-router';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Label,
  Card,
  Row,
  Col
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import { FWTextInput, FWRange } from "../Common";
import {
  required,
  validatePolicyDesc,
  validatePolicyName,
  minLength,
  maxLength,
} from "../../utils/Validators";
import { omit, isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { store } from "../../App";
import { TT, LanguageContext } from '../../containers/Language'
import '../items/Checkbox.css';

const dscpOptions = [
  'CS0', 'CS1', 'CS2', 'CS3', 'CS4', 'CS5', 'CS6', 'CS7',
  'AF11', 'AF12', 'AF13', 'AF21', 'AF22', 'AF23', 'AF31',
  'AF32', 'AF33', 'AF41', 'AF42', 'AF43', 'EF', 'VA'
];

const TrafficClassesTable = ({ trafficClasses }) => {
  return trafficClasses && trafficClasses.length ?
  <table border='1'>
    <tbody>
      <tr className='text-center'>
        <td><TT>Service Class</TT></td>
        <td><TT>Importance</TT></td>
      </tr>
      {trafficClasses.map(({ serviceClass, importance }) => (
      <tr key={serviceClass + importance}>
        <td>{serviceClass}</td>
        <td>{importance}</td>
      </tr>
      ))}
    </tbody>
  </table> : ''
}

class QOSPolicy extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      classesByQueue : {},
      origData : {}
    };

    this.initalDirection = "";

    this.handleSavePolicy = this.handleSavePolicy.bind(this);
    this.warnOnLeavingPage = this.warnOnLeavingPage.bind(this);
    this.checkIfDataChanged = this.checkIfDataChanged.bind(this);
    this.handleRestoreDefaults = this.handleRestoreDefaults.bind(this);
  }

  componentDidMount() {
    const {
      id,
      newPolicy,
      getQOSPolicy,
      getQOSTrafficMap,
      changePolicyForm,
      resetPolicyForm
    } = this.props;

    getQOSTrafficMap(resp => {
      const classesByQueue = {};
      for (let serviceClass in resp) {
        for (let importance in resp[serviceClass]) {
          const queueName = resp[serviceClass][importance];
          if (!classesByQueue[queueName]) {
            classesByQueue[queueName] = [];
          };
          classesByQueue[queueName].push({ serviceClass, importance });
        }
      }
      this.setState({ classesByQueue });
    });

    if (!newPolicy) {
      getQOSPolicy(id, resp => {
        changePolicyForm(
          {
            name: resp.name,
            description: resp.description,
            advanced: resp.advanced,
            outbound: resp.outbound,
            inbound: resp.inbound
          },
          { silent: true }
        );
        this.setState({ origData: cloneDeep(resp) });
      });
    } else {
      resetPolicyForm();
      const emptyPolicy = store.getState().forms.qosPolicy.$form.initialValue;
      this.setState({ origData: cloneDeep(emptyPolicy) });
    }

    // Listen to page reload event, to warn the user
    // upon leaving the page with unsaved changes
    window.addEventListener('beforeunload', this.warnOnLeavingPage);
  }

  componentWillUnmount(){
    // Remove page reload listener
    window.removeEventListener('beforeunload', this.warnOnLeavingPage);
  }

  checkIfDataChanged() {
    return !isEqual(omit(this.state.origData, ['_id']), this.props.qosPolicy);
  }

  warnOnLeavingPage(event) {
    if (this.checkIfDataChanged()) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  handleSavePolicy(values) {
    const { name, description, advanced, outbound, inbound } = values;
    const policy = { name, description, advanced, outbound, inbound };

    const { newPolicy, resetPolicyForm, addQOSPolicy, updQOSPolicy } = this.props;
    if (newPolicy) {
      addQOSPolicy(policy, () => {
        resetPolicyForm();
        this.setState({ origData: cloneDeep(policy) });
      });
    } else {
      policy._id = this.props.qosPolicies.qosPolicy._id;
      updQOSPolicy(policy, () => {
        this.setState({ origData: cloneDeep(policy) });
      });
    }
  }

  handleRestoreDefaults() {
    const { name, description } = this.props.qosPolicies.qosPolicy;
    const { advanced, outbound, inbound } = store.getState().forms.qosPolicy.$form.initialValue;
    this.props.changePolicyForm({
      name, description, advanced, outbound, inbound
    });
  }

  render() {
    const toTTString = this.context.toTTString
    const { newPolicy, qosPolicy } = this.props;

    if (!qosPolicy) return '';

    const trafficClasses = {
      'Realtime': {
        color: '#fbb4ae',
        value: qosPolicy.outbound?.realtime?.bandwidthLimitPercent || 20
      },
      'Control-Signaling': {
        color: '#b3cde3',
        value: qosPolicy.outbound['control-signaling']?.weight || 40
      },
      'Prime-Select': {
        color: '#ccebc5',
        value: qosPolicy.outbound['prime-select']?.weight || 30
      },
      'Standard-Select': {
        color: '#decbe4',
        value: qosPolicy.outbound['standard-select']?.weight || 20
      },
      'Best-Effort': {
        color: '#fed9a6',
        value: qosPolicy.outbound['best-effort']?.weight || 10
      }
    };

    const dataClasses = ['Control-Signaling','Prime-Select','Standard-Select','Best-Effort'];
    const dataClassesTotal = dataClasses.reduce((total, queueName) => +trafficClasses[queueName].value + total, 0);

    return (
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Policies</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/qosPolicies"><TT>QOS Policies</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{newPolicy ? toTTString("add") : toTTString("update")}</BreadcrumbItem>
        </Breadcrumb>
        <Prompt
          message={(location, action) => {
            if (this.checkIfDataChanged()) {
              return toTTString('You have unsaved changes') + '. ' +
                toTTString('Are you sure you want to leave this page?')
            }
          }}
        />
        <h4><TT>QOS Policy</TT></h4>
        <div className="col-lg-10 p-0">
          <Link to="/qosPolicies">
            <Button color="info" className="back-btn policy-back-button">
              <FontAwesomeIcon icon="arrow-circle-left" fixedWidth />
            </Button>
          </Link>
          <Control.button
            className="btn btn-primary btn-ms upper-panel-button action-btn-top"
            size="sm"
            model="qosPolicy"
            form="qosPolicy"
            type="submit"
            disabled={values => !values.valid || dataClassesTotal !== 100}
          >
            {`${newPolicy ? toTTString("Save Policy") : toTTString("Save & Update Devices")}`}
          </Control.button>
          <Button
            className="btn btn-primary upper-panel-button action-btn-top"
            onClick={this.handleRestoreDefaults}
          >
            <TT>Restore Defaults</TT>
          </Button>
        </div>
        <Form id="qosPolicy" model="qosPolicy" onSubmit={this.handleSavePolicy}>
          <Card className="col-lg-10 card-compact">
            <Row className="form-group policy-name">
              <Col className="col-lg-4 col-12">
                <Row>
                  <Label htmlFor="name">
                    <span className="form-required-star">*</span>
                    <TT>Policy Name</TT>
                  </Label>
                  <Col>
                    <Control.text
                      model=".name"
                      id="name"
                      name="name"
                      placeholder={toTTString("Policy Name")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        minLength: minLength(3),
                        maxLength: maxLength(50),
                        name: validatePolicyName,
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".name"
                      show="touched"
                      messages={{
                        required: toTTString("Required Field"),
                        minLength: toTTString('Length must be at least 3'),
                        maxLength: toTTString('Length must be at most 50'),
                        name: toTTString("Invalid Policy Name Format"),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="col-lg-8 col-12">
                <Row>
                  <Label htmlFor="description"><TT>Description</TT></Label>
                  <Col>
                    <Control.text
                      model=".description"
                      id="description"
                      name="description"
                      placeholder={toTTString("Policy Description")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        maxLength: maxLength(50),
                        description: validatePolicyDesc,
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".description"
                      show="touched"
                      messages={{
                        maxLength: toTTString('Length must be at most 50'),
                        description: toTTString("Invalid Policy Description Format"),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col className="text-center">
                <b><TT>WAN Outbound QoS Plan</TT></b>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col className="text-center">
                <span style={{
                  height: '42px',
                  border: '1px solid lightgrey',
                  width: `${trafficClasses['Realtime'].value}%`
                }}>
                  <div style={{
                    backgroundColor: 'transparent',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    height: '20px',
                    textOverflow: 'ellipsis'
                  }}>
                    Realtime {trafficClasses['Realtime'].value} %
                  </div>
                  <span style={{
                    backgroundColor: trafficClasses['Realtime'].color,
                    width: '100%', height: '20px'
                  }} />
                </span>
                <span style={{
                  height: '42px',
                  border: '1px solid lightgrey',
                  width: `${100 - trafficClasses['Realtime'].value}%`
                }}>
                  <div style={{
                    backgroundColor: 'transparent',
                    height: '20px',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }} >
                    Data {100 - trafficClasses['Realtime'].value} %
                  </div>
                  {dataClasses.map(queueName =>
                    <span
                      key={queueName}
                      style={{
                        backgroundColor: trafficClasses[queueName].color,
                        width: 100 * trafficClasses[queueName].value / (dataClassesTotal || 100) + '%',
                        height: '20px'
                      }}
                    />
                  )}
                </span>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <table className="table table-hover table-bordered table-compact">
                  <thead>
                    <tr>
                      <th>
                        <TT>Realtime queue</TT>
                      </th>
                      <th>
                        <TT>Realtime Bandwidth Limit</TT>
                        <br/><small>(<TT>As percentage of WAN bandwidth</TT>, <TT>increments of 10 between 10%-70%</TT>)</small>
                      </th>
                      {qosPolicy.advanced &&
                      <th>
                        <TT>DSCP Rewrite</TT>
                        <span className="helpTooltip" data-tip data-for='dscpRealtimeTip'></span>
                        <ReactTooltip id='dscpRealtimeTip'>
                          <span style={{"fontSize": "0.8rem"}}>
                            <TT>
                              If configured, specified DSCP tag is set on the packets based on the scheduler class assigned to the packet.
                            </TT>
                            <br/>
                            <TT>
                              Specifically useful for QoS aware links like MPLS.
                            </TT>
                          </span>
                        </ReactTooltip>
                      </th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Real-Time
                        <span className="helpTooltip" data-tip data-for='realtimeTip'></span>
                        <ReactTooltip id='realtimeTip'>
                          <span style={{"fontSize": "0.8rem"}}>
                            <TrafficClassesTable trafficClasses={this.state.classesByQueue['realtime']}/>
                          </span>
                        </ReactTooltip>
                      </td>
                      <td>
                      <Control.custom
                          model=".outbound.realtime.bandwidthLimitPercent"
                          component={FWRange}
                          color={trafficClasses['Realtime'].color}
                          step={10}
                          min={0}
                          max={100}
                          mapProps={{
                            value: (props) => props.modelValue,
                            onChange: (props) => {
                              return (e) => {
                                // Allow only values from 10 to 90
                                if (e.target.value >= 10 && e.target.value <= 90) {
                                  props.onChange(e)
                                }
                              }
                            },
                          }}
                        />
                      </td>
                      {qosPolicy.advanced &&
                      <td>
                        <Control.select
                          model=".outbound.realtime.dscpRewrite"
                          className="form-control"
                        >
                        {dscpOptions.map(dscp =>
                          <option key={dscp} value={dscp}>{dscp}</option>
                        )}
                        </Control.select>
                      </td>
                      }
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>
                        <TT>Data queues</TT>
                      </th>
                      <th>
                        <TT>Weights</TT>
                        <br/>
                        <small className={(dataClassesTotal !== 100 ? ' text-danger' : '')}>
                          <TT>Values in increments of 10 between 10%-70%</TT>
                        </small>
                      </th>
                      {qosPolicy.advanced &&
                      <th>
                        <TT>DSCP Rewrite</TT>
                        <span className="helpTooltip" data-tip data-for='dscpRewriteTip'></span>
                        <ReactTooltip id='dscpRewriteTip'>
                          <span style={{"fontSize": "0.8rem"}}>
                            <TT>
                              If configured, specified DSCP tag is set on the packets based on the scheduler class assigned to the packet.
                            </TT>
                            <br/>
                            <TT>
                              Specifically useful for QoS aware links like MPLS.
                            </TT>
                          </span>
                        </ReactTooltip>
                      </th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {dataClasses.map(queueName =>
                    <tr key={queueName}>
                      <td>
                        {queueName}
                        <span className="helpTooltip" data-tip data-for={queueName+'Tip'}></span>
                        <ReactTooltip id={queueName+'Tip'}>
                          <span style={{"fontSize": "0.8rem"}}>
                            <TrafficClassesTable trafficClasses={this.state.classesByQueue[queueName.toLowerCase()]}/>
                          </span>
                        </ReactTooltip>
                      </td>
                      <td>
                      <Control.custom
                        model={`.outbound.${queueName.toLowerCase()}.weight`}
                        component={FWRange}
                        color={trafficClasses[queueName].color}
                        min={0}
                        max={70}
                        step={10}
                        mapProps={{
                          value: (props) => props.modelValue,
                          onChange: (props) => {
                            return (e) => {
                              // Allow only values eq or greater than 10
                              if (e.target.value >= 10) {
                                props.onChange(e)
                              }
                            }
                          },
                        }}
                      />
                      </td>
                      {qosPolicy.advanced &&
                      <td>
                        <Control.select
                          className="form-control"
                          model={`.outbound.${queueName.toLowerCase()}.dscpRewrite`}
                        >
                          {dscpOptions.map(dscp =>
                            <option key={dscp} value={dscp}>{dscp}</option>
                          )}
                        </Control.select>
                      </td>
                      }
                    </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr className='text-center font-weight-bold'>
                      <td></td>
                      <td className={dataClassesTotal !== 100 ? ' text-danger' : ''}>
                        <TT>Total</TT>{` ${dataClassesTotal}%${dataClassesTotal !== 100 ? '. Should be 100%' : ''}` }
                      </td>
                      {qosPolicy.advanced && <td/>}
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
            <hr/>
            <Row className="form-group">
              <Col className="text-right">
                <Control.checkbox
                  type="checkbox"
                  label="Advanced"
                  className="fwcheckbox"
                  model=".advanced"
                  id="advanced"
                  name="advanced"
                />
                <label htmlFor="advanced">
                  <span></span>Advanced
                </label>
              </Col>
            </Row>
          </Card>
        </Form>
      </>
    );
  }
}

export default QOSPolicy;
