import React from 'react';
import { connect } from 'react-redux';
import ConfirmationModal from './ConfirmationModal';
import { saveDHCP } from '../../redux/reducers/Devices';
import { getNewDhcpEntry } from '../devices/DHCP/Add';
import { ToastContainer, toast } from 'react-toastify';

const CreateDhcpAutomaticallyModal = props => {

  const createDhcpEntryForInterface = (ifc) => {
    const dhcpEntry = getNewDhcpEntry(ifc)
    const dhcpEntries = [...props.dhcp];
    dhcpEntries.push(dhcpEntry)
    props.saveDHCP(dhcpEntries);

    toast("DHCP Server created!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <ConfirmationModal
        isOpen={props.show}
        toggle={props.close}
        title={'Enable DHCP server on the interface'}
        question={'Do you want to enable DHCP server for this interface?'}
        color={'success'}
        onConfirm={() => {
          createDhcpEntryForInterface(props.ifc);
          props.close()
        }}
      />
      { props.hideToast ? null : <ToastContainer /> }
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    saveDHCP: (dhcpEntry) => dispatch(saveDHCP(dhcpEntry))
  }
}

const mapStateToProps = state => {
  return {
    dhcp: state.devices.dhcp
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDhcpAutomaticallyModal)

export const isSuggestInterfaceToHaveDhcpServer = (ifc, dhcpEntries) => {
  if (ifc.type === 'LAN' && ifc.dhcp === 'no'&& ifc.IPv4 && (ifc.isAssigned === true || ifc.isAssigned === 'Yes') && !dhcpEntries.some(d => d.interface === ifc.devId)) {
    return true
  }
  return false;
}
