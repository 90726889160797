import React, { useState, useImperativeHandle, useCallback } from "react";
import { Button, Card, CardBody, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { TT } from '../../containers/Language';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

const GroupItem = props => {
  const [status, setStatus] = useState('Closed');
  const onEntering = () => setStatus('entering');
  const onEntered = () => setStatus('entered');
  const onExiting = () => setStatus('exiting');
  const onExited = () => setStatus('exited');

  return (
    <>
      <ListGroupItem
        onClick={() => {
          props.onSelect();
        }
      }>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {props.title}
            {props.tip ? (
              <>
                <span className="ml-2 helpTooltip" data-tip data-for={props.title}></span>
                <ReactTooltip id={props.title}>
                  <span>{props.tip}</span>
                </ReactTooltip>
              </>
            ) : null}
          </div>
          <div className="d-flex">
            {props.indicator ? props.indicator : null}
            <Button type="button" color="primary"><TT>Edit</TT></Button>
          </div>
        </div>
      </ListGroupItem>
      <Collapse
        isOpen={props.isOpen}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        <Card style={{
          ...defaultStyle,
          ...transitionStyles[status]
        }}>
          <CardBody>
            {props.collapse}
          </CardBody>
        </Card>
      </Collapse>
    </>
  )
}

const FwAccordion = React.forwardRef((props, ref) => {
  // useCallback prevent creation new function on every render.
  // The function will recreated if one of the dependencies changed
  const calcState = useCallback(() => {
    return props.items.reduce((o, key, idx) => Object.assign(o, {[idx]: false}), {})
  }, [props.items]);

  const [openedIdx, setOpenedIdx] = useState(calcState());

  const updateIdx = (idx, val) => {
    const updated = {
      ...openedIdx,
      [idx]: val
    };
    setOpenedIdx(updated)
  }

  // this function can be called from parent component
  // to open all items
  useImperativeHandle(ref, () => ({
    openAll() {
      const updated = {};
      for (const key in openedIdx) updated[key] = true;
      setOpenedIdx(updated)
    }
  }))

  return (
    <ListGroup>
      {props.items.map((item, idx) => {
        return <GroupItem
          key={idx}
          onSelect={() => {
            // if currently open - close it
            if (openedIdx[idx]) {
              updateIdx(idx, false)
            } else {
            // if not - open it
              updateIdx(idx, true)
            }
          }}
          isOpen={openedIdx[idx]}
          {...item}
        />
      })}
    </ListGroup>
  )
});

export default FwAccordion;