import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import queryString from 'query-string';
const { baseUrl } = window.SERVER_CONFIG;

export const Invoices = (
  state = {
    invoice: null,
    invoices: {"invoices":[],"summary":{"current":0,"max":0,},"subscription":"active"},
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.INVOICE_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.INVOICE_GETALL_SUCCESS:
      const newState = { ...state, invoices: action.Invoices, isLoading: false };
      newState.invoices.invoices.unshift(...state.invoices?.invoices ?? []);
      return newState;
    case Actions.INVOICE_GETALL_FAILURE:
      return { ...state, invoices: [], isLoading: false };
    // case Actions.COUPON_GET_REQUEST:
    //   return { ...state, isLoading: true};
    case Actions.INVOICE_GETALL_RESET:
      return { ...state, invoices: [], isLoading: false };
    default:
      return state;
  }
};

export const Coupons = (
  state = {
    coupon: null,
    coupons: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.COUPON_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.COUPON_GETALL_SUCCESS:
      return { ...state, coupons: action.Coupons, isLoading: false };
    case Actions.COUPON_GETALL_FAILURE:
      return { ...state, coupons: [], isLoading: false };
    default:
      return state;
  }
};

/**********************************************************************
 *   Get all Invoices
 **********************************************************************/
export const requestGetAllInvoices = () => dispatch => {
  dispatch({
    type: Actions.INVOICE_GETALL_REQUEST
  });
};

export const receiveGetAllInvoices = (response) => dispatch => {
  dispatch({
    type: Actions.INVOICE_GETALL_SUCCESS,
    Invoices: response
  });
};

export const failureGetAllInvoices = error => dispatch => {
  dispatch({
    type: Actions.INVOICE_GETALL_FAILURE
  });
};

export const resetGetAllInvoices = () => dispatch => {
  dispatch({
    type: Actions.INVOICE_GETALL_RESET
  });
};

export const getAllInvoices = (params) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + "invoices" + (queryParams? ('?' + queryParams): ''),
      method: "GET",
      requestCB: () => {
        if (!params?.offsetPos) dispatch(resetGetAllInvoices());
        dispatch(requestGetAllInvoices());
      },
      successCB: response => {
        dispatch(receiveGetAllInvoices(response));
      },
      failureCB: error => {
        dispatch(failureGetAllInvoices(error));
      }
    })
  );
};

/**********************************************************************
 *   Get All Coupons
 **********************************************************************/
export const requestGetAllCoupons = () => dispatch => {
  dispatch({
    type: Actions.COUPON_GETALL_REQUEST
  });
};

export const receiveGetAllCoupons = response => dispatch => {
  dispatch({
    type: Actions.COUPON_GETALL_SUCCESS,
    Coupons: response
  });
};

export const failureGetAllCoupons = error => dispatch => {
  dispatch({
    type: Actions.COUPON_GETALL_FAILURE
  });
};

export const getAllCoupons = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "coupons",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllCoupons());
      },
      successCB: response => {
        dispatch(receiveGetAllCoupons(response));
      },
      failureCB: error => {
        dispatch(failureGetAllCoupons(error));
      }
    })
  )
}