import React, { Component } from "react";
import * as d3 from "d3";

/**
 * TimeSeriesGraph component class
 * Description:
 * Display a graphs for 2 hours backward and refresh every 1 min
 */
class TimeSeriesGraph extends Component {
  constructor(props) {
    super(props);

    window.d3 = d3;

    this.resizeTimer = null;
    this.svg = null;
    this.g = null;
    this.isComponentMounted = false;

    this.state = {
      width:0,
      height:0,
      disabledLines: {}    // lines to disable
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.trafficArrangeData = this.trafficArrangeData.bind(this);
    this.generateTrafficGraph = this.generateTrafficGraph.bind(this);
    this.getGraphParams = this.getGraphParams.bind(this);
    this.successGetTraffic = this.successGetTraffic.bind(this);
}

/**
 * Called when screen size changes, resize and update the graph dimensions
 * @param  {boolean} mount indicate if to create a new graph or update
 * @return {None}
 */
updateDimensions(mount) {
    clearTimeout(this.resizeTimer);
    const updateFunc = (mount) => (that) => {
        // The following code will be called after resize ends
        that.setState({width: document.getElementsByClassName('mainContent')[0].clientWidth,
            height: document.getElementsByClassName('mainContent')[0].offsetHeight});
        // Don't update when mounting, will be called when traffic received
        if (!mount) that.updateTrafficGraph(200);
    }
    if (mount) {
      // When mounting update dimensions immediately
      updateFunc(mount)(this);
    } else {
      // Call in delay to make sure the resize ended
      this.resizeTimer = setTimeout(updateFunc(mount), 250, this);
    }
}

/**
 * Read data and setup event handler for dimensions change
 * @return {None}
 */
componentDidMount() {
  this.updateDimensions(true);
  window.addEventListener("resize", () => this.updateDimensions(false));
  this.isComponentMounted = true;
}

/**
 * Generate or update graph on success
 * @param  {integer} duration duration to complete the update
 * @return {function}         called when new data received
 */
successGetTraffic(duration) {
  const that = this;
  return function() {
    // Return if the response from the server was received
    // after the component was already removed
    if(!that.isComponentMounted) return;
    // If graph already generated update it, else generate it
    if (that.svg && that.g) {
      that.updateTrafficGraph(duration);
    } else {
      that.generateTrafficGraph();
    }
  };
}

/**
 * Disable all handlers
 * @return {None}
 */
componentWillUnmount() {
  this.isComponentMounted = false;
  window.removeEventListener("resize", this.updateDimensions);
  if (this.readTimer != null) {
      console.log("Traffic: Clearing periodic timer");
      clearInterval(this.readTimer);
      this.readTimer = null;
  }
  window.removeEventListener('focus', this.updateOnFocus);
}

componentDidUpdate(prevProps) {
  if (this.props.lastUpdate !== prevProps.lastUpdate) {
    this.successGetTraffic((prevProps.lastUpdate === 0)? 0:200)();
  }
}

/**
 * Process and arrange the data to be preseted
 * It sets zero for points in the graph where no data exists and change the time to millisecods
 * @return {Array} data after processing
 */
trafficArrangeData() {
    const res = [];
    const timeDict = {};
    const SAMPLE_PERIOD = 300;  // Point every 5min
    const GRAPH_SPAN = 7200;   // Total graph time 2 hours
    // Get time 10 sec in delay to let the data be stored, then round the time
    var curTime = Math.floor((new Date().getTime()-10000)/(1000*SAMPLE_PERIOD))*SAMPLE_PERIOD;
    const data = this.props.traffic;
    data.forEach(function(d) {
        timeDict[d.time] = {...d};
    });
    for (var t=curTime; t>curTime-GRAPH_SPAN; t-=SAMPLE_PERIOD) {
        if (timeDict.hasOwnProperty(t)) {    // Data found
            timeDict[t].time *=1000;
            res.push(timeDict[t]);
        } else { // Data not found
            if (t!==curTime) res.push({"time":t*1000});
        }
    }
    return res;
}

/**
 * Generate graph params such as width, height, line functions
 * @param  {array} data after arrangement
 * @return {object}      graph parameters
 */
getGraphParams(data) {
    var w = Math.min(Math.max(this.state.width - 100, 400),1000);
    var h = Math.min(Math.max(this.state.height - 200, 300),(this.props.height || 400));
    var margin = {top: 50, right: 50, bottom: 50, left: 50};
    var width = w - margin.left - margin.right;
    var height = h - margin.top - margin.bottom;
    var xtime = d3.scaleUtc()
        .domain(d3.extent(data, function(d) { return d.time; }))
        .range([0, width]);

    // Copy props lineInfo for setting new params
    const lineInfo = { ...this.props.lineInfo };

    // Used to calculate the domain for all left and right axis based lines
    let max_left = 0;
    let max_right = 0;
    for (const line in lineInfo) {
      const y = d3.scaleLinear().rangeRound([height, 0]);
      const info = lineInfo[line];
      //y.domain([0, d3.max(data, function(d) { return info.yval(d); })]).nice();
      if (info.axisLocation === 'right') {
        max_right = Math.max(max_right, d3.max(data, function(d) { return info.yval(d); }));
      } else {
        max_left = Math.max(max_left, d3.max(data, function(d) { return info.yval(d); }));
      }
      info.y = y;
      info.line = d3.line().curve(d3.curveCatmullRom)
      .x(function(d) { return xtime(d.time); })
      .y(function(d) { return y(info.yval(d)); });
      if (info.axisLocation === 'right') {
        info.axis = d3.axisRight;
        info.axispos = width;
      } else {
        info.axis = d3.axisLeft;
        info.axispos = 0;
      }
      info.gridWidth = (info.grid && info.grid === 'width')? width:5;
    }
    // Set domain for all lines
    for (const line in lineInfo) {
      const info = lineInfo[line];
      if (info.axisLocation === 'right') {
        info.y.domain([0, max_right]).nice();
      } else {
        info.y.domain([0, max_left]).nice();
      }
    }

    return {

        w : w,
        h : h,
        width : width,
        height : height,
        margin : margin,
        xtime : xtime,

        lineInfo : lineInfo

    };
}

/**
 * Generate the traffic graph
 * @return {None}
 */
generateTrafficGraph() {

    console.log("Generate traffic graph");

    const data = this.trafficArrangeData();

    const {lineInfo, w, h, width, height, margin, xtime}
        = this.getGraphParams(data);

    if (!this.svg)
        this.svg = d3.select('#'+this.props.divId).append("svg");
    this.svg
        .attr("width", w)
        .attr("height", h);
    if (!this.g) this.g = this.svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let leftAxisAdded = false;
    let rightAxisAdded = false;
    Object.keys(lineInfo).forEach((l) => {
        this.g.append("path")
          .attr("class", "line" + l)
          .datum(this.state.disabledLines.hasOwnProperty(l)?[]:data)
          .attr("fill", "none")
          .attr("stroke", lineInfo[l].color)
          .attr("stroke-linejoin", "round")
          .attr("stroke-linecap", "round")
          .attr("stroke-width", 1.5)
          .attr("d", lineInfo[l].line);

        this.g.selectAll(".dot" + l)
          .data(data)
          .enter().append("circle")
          .attr("class", "dot" + l)
          .attr("fill", this.state.disabledLines.hasOwnProperty(l)?'transparent':lineInfo[l].color)
          .attr("r", 2)
          .attr("cx", function(d) { return xtime(d.time); })
          .attr("cy", function(d) {
              return lineInfo[l].y(lineInfo[l].yval(d));
          });

          if ((lineInfo[l].axisLocation === 'right' && !rightAxisAdded) ||
            (lineInfo[l].axisLocation !== 'right' && !leftAxisAdded)) {
          this.g.append("g")
            .attr("class", "axis" + l)
            .attr("transform", "translate(" + lineInfo[l].axispos + ",0)")
            .call(lineInfo[l].axis(lineInfo[l].y).ticks(null, "s").tickSize(-lineInfo[l].gridWidth))
            .append("text")
            .attr("x", 2)
            .attr("y", lineInfo[l].y(lineInfo[l].y.ticks().pop()) + -20)
            .attr("dy", "0.32em")
            .attr("fill", lineInfo[l].color)
            .attr("font-weight", "bold")
            .attr("text-anchor", "middle")
            .text((lineInfo[l].axisText || l) + (lineInfo[l].units || ''));
          if (lineInfo[l].axisLocation === 'right') rightAxisAdded = true;
          else leftAxisAdded = true;
        }
    });

    this.g.append("g")
      .attr("class", "timeAxis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xtime).tickFormat(d3.timeFormat("%I:%M %p")))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    this.g
      .append("rect")
      .attr("class","legend")
      .attr("transform", "translate(" + ((width-Object.keys(lineInfo).length*70)/2) + "," + (-35) + ")")
      .attr("width", Object.keys(lineInfo).length * 70 + 20)
      .attr("height", 30);

    const legend = this.g
      .append("g")
      .attr("font-size", 10)
      .attr("text-anchor", "start")
      .selectAll("g")
      .data(Object.keys(lineInfo))
      .enter()
      .append("g")
      .attr("class", "legend-data")
      .attr("transform", function(d, i) {
          return "translate(" + ((width-Object.keys(lineInfo).length*70)/2+20 + i*70) + "," + (-20) + ")";
      });

    //const that = this;
    legend.append("circle")
      .attr("x", 5)
      .attr("y", 5)
      .attr("r", 7)
      .style("fill", function(d) {return lineInfo[d].color;})
      .style("fill-opacity", 0.6)
      .style("stroke", "#212529")
      .style("stroke-width", 0.5)
      .on("mouseover", function(p) {d3.select(this).style("cursor", "pointer");})
      .on("mouseout", function(p) {d3.select(this).style("cursor", "default");})
      .on("click", ((that) => {
        return function(p) {
          that.setState(prevState => {
            if (prevState.disabledLines.hasOwnProperty(p)) {
              const {[p]:_, ...otherKeys} = prevState.disabledLines;
              return {disabledLines: otherKeys};
            } else {
              return {disabledLines: {...prevState.disabledLines, [p]:true}};
            }
          });
          const item = d3.select(this);
          item.style("fill-opacity", that.state.disabledLines.hasOwnProperty(p)? 0:0.6);
          that.updateTrafficGraph(0);
        }
      })(this));

    legend.append("text")
      .attr("x", 15)
      .attr("y", 5)
      .attr("font-weight", "bold")
      .style("fill", function(d) {return lineInfo[d].color;})
      .text(function(d) { return d; });
}

/**
 * Update an existing traffic graph
 * @param  {integer} duration transition time in milliseconds from old to new graph
 * @return {None}
 */
updateTrafficGraph(duration) {

    console.log("Update traffic graph");

    const data = this.trafficArrangeData();

    const {lineInfo, w, h, width, height, margin, xtime}
        = this.getGraphParams(data);

    if (!this.svg)
        this.svg = d3.select('#'+this.props.divId).append("svg");
    this.svg
        .attr("width", w)
        .attr("height", h);
    if (!this.g) this.g = this.svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let leftAxisAdded = false;
    let rightAxisAdded = false;

    // TBD: Remove not exist graphs such as
    // this.g.select(".line" + type)
    //         .datum(data)
    //         .remove();
    // this.g.selectAll(".dot" + type)
    //         .data(data)
    //         .remove();
    // Add new graphs if were not originally exist
    // Fix Legend and axis the same way

    Object.keys(lineInfo).forEach((l) => {

        this.g.select(".line" + l)
            .datum(this.state.disabledLines.hasOwnProperty(l)?[]:data)
            .transition()
            .duration(duration)
            .attr("d", lineInfo[l].line);

        this.g.selectAll(".dot" + l)
            .data(data)
            .transition()
            .duration(duration)
            .attr("fill", this.state.disabledLines.hasOwnProperty(l)?'transparent':lineInfo[l].color)
            .attr("cx", function(d) { return xtime(d.time); })
            .attr("cy", function(d) {
                return lineInfo[l].y(lineInfo[l].yval(d));
            });

        this.svg.select(".axis" + l)
            .transition()
            .duration(duration)
            .attr("transform", "translate(" + lineInfo[l].axispos + ",0)")
            .call(lineInfo[l].axis(lineInfo[l].y).ticks(null, "s").tickSize(-lineInfo[l].gridWidth));

        if ((lineInfo[l].axisLocation === 'right' && !rightAxisAdded) ||
          (lineInfo[l].axisLocation !== 'right' && !leftAxisAdded)) {
          this.svg.select(".axis" + l + " > text")
              .transition()
              .duration(duration)
              .attr("x", 2)
              .attr("y", lineInfo[l].y(lineInfo[l].y.ticks().pop()) + -20)
              .attr("dy", "0.32em")
              .attr("fill", lineInfo[l].color)
              .attr("font-weight", "bold")
              .attr("text-anchor", "middle")
              .text((lineInfo[l].axisText || l) + (lineInfo[l].units || ''));
          if (lineInfo[l].axisLocation === 'right') rightAxisAdded = true;
          else leftAxisAdded = true;
        }
    });

    this.svg.select(".timeAxis")
        .transition()
        .duration(duration)
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(xtime).tickFormat(d3.timeFormat("%I:%M %p")))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

    this.svg.select(".legend")
        .transition()
        .duration(duration)
        .attr("transform", "translate(" + ((width-Object.keys(lineInfo).length*70)/2) + "," + (-35) + ")");

    this.g.selectAll(".legend-data")
        .data(Object.keys(lineInfo))
        .transition()
        .duration(duration)
        .attr("transform", function(d, i) {
          return "translate(" + ((width-Object.keys(lineInfo).length*70)/2+20 + i*70) + "," + (-20) + ")";
        });

  }

  render() {
    return (
      <div id={this.props.divId}></div>
    );
  }
}

export default TimeSeriesGraph;
