import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Control, actions } from 'react-redux-form';
import { TT } from '../../containers/Language';
import { Row, Collapse, Col } from 'reactstrap';
import get from 'lodash.get';
import { isDeviceVersionGreaterEquals } from '../../utils/DevicesUtils';

const RoutingCommandsCli = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const {formData, model} = props;

  const customFormData = useMemo(() => {
    return get(formData, model, []);
  }, [formData, model])

  useEffect(() => {
    if (customFormData.length > 0) {
      setIsOpen(true)
    }
  }, [customFormData]);

  // do not allow advanced routing commands in a version lower than 6.3.30
  const deviceVersion = props?.device?.versions?.agent;
  if (deviceVersion && !isDeviceVersionGreaterEquals(deviceVersion, '6.3.30')) {
    return null;
  }

  return (
    <>
      <Row className="form-group">
        <Col className={["d-flex", props.buttonRight ? "justify-content-end": "justify-content-start"].join(' ')}>
          <button
            className="btn btn-outline-secondary mt-2 btn-sm"
            size="sm"
            type="button"
            onClick={toggle}
          >
            <TT>CLI Commands</TT>
          </button>
        </Col>
      </Row>

      <Collapse
        isOpen={isOpen}
      >
        <div className="alert alert-warning alert-small" role="alert">
          <div>This allows configuring advanced {props.advancedText} features via CLI.</div>
          <div>This section executed {props.executedText}.</div>
          <div><TT>Use with caution to avoid failures</TT>.</div>
        </div>
          <Control.textarea
            model={"." + props.model}
            id={props.id || props.model}
            name={props.name || props.model}
            style = {{'min-width': '100%'}}
            rows = {Math.max((customFormData?.split('\n') ?? []).length, 3)}
          />
      </Collapse>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    formData: state[ownProps.form],
    device: state.devices.device
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeForm: (formName, model, newValue) => dispatch(actions.change(formName, model, newValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutingCommandsCli);
