import * as Actions from "../Actions";
import { mainRedirectTo } from "./Main";
import { alertMsgMessage } from "./AlertMsg";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Peers = (
  state = {
    peers: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.PEER_GETALL_SUCCESS:
      return { ...state, peers: action.peers, isLoading: false };

    case Actions.PEER_DEL_SUCCESS:
      // once peer deleted from backend, we remove it from redux store as well
      const peers = [...state.peers];
      const idx = peers.findIndex(p => p._id === action.peer._id);
      peers.splice(idx, 1);
      return { ...state, peers: peers, isLoading: false };

    case Actions.PEER_GETALL_REQUEST:
    case Actions.PEER_SAVE_REQUEST:
    case Actions.PEER_DEL_REQUEST:
        return { ...state, isLoading: true };

    case Actions.PEER_SAVE_SUCCESS:
    case Actions.PEER_GETALL_FAILURE:
    case Actions.PEER_SAVE_FAILURE:
    case Actions.PEER_DEL_FAILURE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get all Peers
 **********************************************************************/
export const requestGetAllPeers = () => dispatch => {
  dispatch({
    type: Actions.PEER_GETALL_REQUEST
  });
};

export const receiveGetAllPeers = response => dispatch => {
  dispatch({
    type: Actions.PEER_GETALL_SUCCESS,
    peers: response
  });
};

export const failureGetAllPeers = error => dispatch => {
  dispatch({
    type: Actions.PEER_GETALL_FAILURE
  });
};

export const getAllPeers = (cb = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "peers",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllPeers());
      },
      successCB: response => {
        dispatch(receiveGetAllPeers(response));
        if (cb) {
          cb(response);
        }
      },
      failureCB: error => {
        dispatch(failureGetAllPeers(error));
      }
    })
  );
};



/**********************************************************************
 *   Save Peer
 **********************************************************************/
 export const requestSavePeer = () => dispatch => {
  dispatch({
    type: Actions.PEER_SAVE_REQUEST
  });
};

export const receiveSavePeer = (method, response) => dispatch => {
  dispatch({
    type: Actions.PEER_SAVE_SUCCESS,
    peer: response.peer
  });
  dispatch(mainRedirectTo("/peers"));
  let name = '';
  let action = '';
  if (method === 'POST') {
    name = response.name;
    action = 'created';
  } else {
    name = response.updatedPeer.name;
    action = 'updated';
  }
  let msg = `Peer ${name} ${action} successfully`;
  if (response.reconstructedTunnels > 0) {
    msg += `. The system recreated ${response.reconstructedTunnels} peer tunnel${response.reconstructedTunnels > 1 ? 's' : ''}`
  }
  dispatch(
    alertMsgMessage({
      color: "success",
      text: msg
    })
  );
};

export const failureSavePeer = error => dispatch => {
  dispatch({
    type: Actions.PEER_SAVE_FAILURE
  });
};

export const savePeer = (peer, cb = null) => dispatch => {
  let url = 'peers';
  let method = '';

  if (peer._id) {
    url += '/' + peer._id;
    method = 'PUT';
  } else {
    method = 'POST';
  }

  return dispatch(
    FWFetch({
      url: baseUrl + url,
      method: method,
      body: JSON.stringify(peer),
      requestCB: () => {
        dispatch(requestSavePeer());
      },
      successCB: response => {
        dispatch(receiveSavePeer(method, response));

        if (cb) {
          cb(response)
        }
      },
      failureCB: error => {
        dispatch(failureSavePeer(error));
      }
    })
  );
};


/**********************************************************************
 *   Delete Peer
 **********************************************************************/
export const requestDelPeer = () => dispatch => {
  dispatch({
    type: Actions.PEER_DEL_REQUEST
  });
};

export const receiveDelPeer = peer => dispatch => {
  dispatch({
    type: Actions.PEER_DEL_SUCCESS,
    peer
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Peer " + peer.name + " deleted successfully"
    })
  );
};

export const failureDelPeer = error => dispatch => {
  dispatch({
    type: Actions.PEER_DEL_FAILURE
  });
};

export const deletePeer = (peer, cb = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "peers/" + peer._id,
      method: "DELETE",
      body: JSON.stringify(peer),
      requestCB: () => {
        dispatch(requestDelPeer());
      },
      successCB: response => {
        dispatch(receiveDelPeer(peer));
        if (cb) {
          cb(response)
        }
      },
      failureCB: error => {
        dispatch(failureDelPeer(error));
      }
    })
  );
};
