import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { LanguageContext, TT } from '../../containers/Language'
class ApplicationTable extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.installCountMap = {
      installed: { color: "black", text: <TT>Installed</TT> },
      pending: { color: "black", text: <TT>Pending</TT> },
      failed: { color: "red", text: <TT>Failed</TT> },
      deleted: { color: "red", text: <TT>Job removed</TT> },
    }

    this.appsPaginationOptions = {
      paginationSize: 10,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "100", value: 100 },
      ],
    };

  }

  render() {
    const toTTString = this.context.toTTString;
    const appsColumns = [
      { text: "rowID", dataField: "_id", hidden: true },
      {
        text: toTTString("Application"),
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
      },
      {
        text: toTTString("Description"),
        dataField: "description",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
      },
      {
        text: this.props.type === "purchased" ? toTTString("Installed Version") : toTTString("Latest Version"),
        dataField: this.props.type === "purchased" ? "installedVersion" : "versions.0.version",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
      },
    ];

    if (this.props.type === "purchased") {
      appsColumns.push({
        text: toTTString("Status"),
        dataField: "stat",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex, formatExtraData) => {
          const status = [];
          Object.entries(row.statuses || {}).forEach(([field, value]) => {
            const { color, text } = this.installCountMap[field];
            if (value !== 0) {
              status.push(
                <div key={field}>
                  <span style={{color: color}}>
                    {text}: {value}
                  </span>
                </div>
              );
            }
          });
          return status;
        },
      });
    }

    if (this.props.actions) {
      appsColumns.push({
        text: toTTString("Actions"),
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex, formatExtraData) => {
          return this.props.actions(row, formatExtraData);
        },
        formatExtraData: this.props.formatExtraData || [],
      });
    }

    return (
      <React.Fragment>
        <BootstrapTable
          striped
          hover
          condensed
          keyField="_id"
          data={this.props.apps || []}
          columns={appsColumns}
          pagination={paginationFactory(this.appsPaginationOptions)}
          noDataIndication={
            this.props.noDataIndication || toTTString("No applications found")
          }
          defaultSorted={[{ dataField: "name", order: "asc" }]}
        />

      </React.Fragment>
    );
  }
}

export default ApplicationTable;
