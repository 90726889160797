import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Form, Errors, Control, actions } from 'react-redux-form';
import { Row, Col, Label } from 'reactstrap';
import { TT, LanguageContext } from '../../containers/Language';
import { FWTextInput } from '../Common';
import { required, isIP4WithMask, isIP4, isOverlap } from '../../utils/Validators';
import { getMajorVersion, getMinorVersion } from "../../utils/DevicesUtils"
import RoutingCommandsCli from './RoutingCommandsCli';

const RoutingFilterRule = props => {
  const { toTTString } = useContext(LanguageContext);
  const [isSupportsOverlapping, setIsSupportsOverlapping] = useState(null);

  useEffect(() => {
    if (props.rule) {
      props.changeForm(props.rule);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      props.resetForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const major = getMajorVersion(props.deviceVersion)
    const minor = getMinorVersion(props.deviceVersion)
    if (major > 6 || (major === 6 && minor >= 2)) {
      setIsSupportsOverlapping(true);
    } else {
      setIsSupportsOverlapping(false);
    }
  }, [props.deviceVersion])

  const handleClose = () => {
    props.onClose()
  }

  const handleSubmit = values => {
    props.onSubmit(values)
    handleClose()
  }

  return (
    <>
      <Form
        model="routingFilterRule"
        onSubmit={handleSubmit}
      >
        <Row className="form-group">
          <Label htmlFor="route" md={3}>
            <TT>Route</TT>
          </Label>
          <Col md={9}>
            <Control.text
              model=".route"
              id="route"
              name=".route"
              placeholder={toTTString("Route")}
              component={FWTextInput}
              disabled={props.rule?.route === '0.0.0.0/0'}
              withFieldValue
              validators={{
                required: required,
                isIP4WithMask: isIP4WithMask,
                duplicateRoute: val => {
                  let existingRules = props.existingRules ?? [];
                  if (props.rule) {
                    existingRules = existingRules.filter(r => r._id !== props.rule._id); // filter out current
                  }
                  return existingRules.find(r => r.route === val) === undefined
                },
                overlappingRoutes: val => {
                  if (isSupportsOverlapping) {
                    return true;
                  }
                  let existingRules = props.existingRules ?? [];
                  if (props.rule) {
                    existingRules = existingRules.filter(r => r._id !== props.rule._id); // filter out current
                  }
                  return existingRules.find(r => r.route !== '0.0.0.0/0' && isOverlap(r.route, val)) === undefined
                }
              }}
            />
            <Errors
              className="text-danger"
              model=".route"
              show="touched"
              messages={{
                required: toTTString('Required field'),
                isIP4WithMask: toTTString('Invalid IPv4 And Mask (0.0.0.0/0)'),
                duplicateRoute: toTTString('Route already exists'),
                overlappingRoutes: toTTString('Device version 6.1.X and below doesn\'t support route overlapping'),
              }}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Label htmlFor="action" md={3}>
            <TT>Action</TT>
          </Label>
          <Col md={9}>
            <Control.select
              model=".action"
              id="action"
              name="action"
              placeholder={toTTString("Action")}
              className="form-control"
              validators={{
                required: required
              }}
            >
              <option value="allow">{toTTString('Allow')}</option>
              <option value="deny">{toTTString('Deny')}</option>
            </Control.select>

            <Errors
              className="text-danger"
              model=".action"
              show="touched"
              messages={{
                required: toTTString('Required field')
              }}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Label htmlFor="nextHop" md={3}>
            <TT>Next Hop</TT>
          </Label>
          <Col md={9}>
            <Control.text
              model=".nextHop"
              id="nextHop"
              name=".nextHop"
              placeholder={toTTString("Next Hop IP")}
              component={FWTextInput}
              withFieldValue
              validators={{
                isIP4: val => val === '' || isIP4(val)
              }}
            />
            <Errors
              className="text-danger"
              model=".nextHop"
              show="touched"
              messages={{
                isIP4: toTTString('Invalid IPv4'),
              }}
            />
          </Col>
        </Row>

        <RoutingCommandsCli
          form="routingFilterRule"
          model="custom"
          executedText='after the "match" command under the "route-map"'
          advancedText="Routing Filters"
        />

        <Row className="form-group">
          <Col className="text-right">
            <Control.button
              model="routingFilterRule"
              className="btn btn-success btn-ok"
            >
              <TT>Save</TT>
            </Control.button>
            <button
              onClick={handleClose}
              type="button"
              className="btn ml-1 btn-outline-dark pull-right"
            >
              <TT>Cancel</TT>
            </button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetForm: () => dispatch(actions.reset('routingFilterRule')),
    changeForm: rule => dispatch(actions.change('routingFilterRule', rule))
  }
}



export default connect(null, mapDispatchToProps)(RoutingFilterRule);