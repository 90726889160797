import React, { useState, useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext } from '../../containers/Language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FwBootstrapTable from '../items/FwBootstrapTable';
import moment from 'moment';
import { getDeviceBgpStatus } from '../../redux/reducers/Devices';

const BgpNeighborsStatus = props => {
  const { toTTString } = useContext(LanguageContext);
  const [bgpStatus, setStatus] = useState({})
  const [bgpNeighborsStatus, setBgpNeighborsStatus] = useState([])
  const [loading, setLoading] = useState(false)

  const { id, deviceStatus, getDeviceBgpStatus } = props;

  const getStatus = useCallback((fromAgent = false) => {
    // don't call bgp status if device is not connected.
    // We can't get it from memory as we delete device status for disconnection devices
    if (deviceStatus.connection === 'disconnected') return;

    setLoading(true);
    getDeviceBgpStatus(
      id,
      fromAgent,
      // success callback
      response => {
        setStatus(response.status)
        const bgpNeighborsData = Object.keys(response.status?.peers ?? {}).map(key => ({ip: key, ...response.status?.peers[key]}));
        setBgpNeighborsStatus(bgpNeighborsData)
        setLoading(false);
      },
      // error callback
      error => {
        setLoading(false);
      }
    );
  }, [getDeviceBgpStatus, deviceStatus, id]);

  useEffect(() => {
    getStatus()
  }, [getStatus, deviceStatus]);

  return (
    <Row>
      <Col>
        <Card id='BGPCardStatus'>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <TT>BGP Neighbors Status</TT>
              </div>
              <div>
                <small className="text-muted"> {bgpStatus?.time ? 'Updated at ' + moment(bgpStatus.time).format('h:mm:ss a MMM DD, YYYY') : null}</small>
                <ReactTooltip id='refresh-lte-stats'><span><TT>Refresh</TT></span></ReactTooltip>
                <Button
                  data-tip data-for='refresh-lte-stats'
                  color="default"
                  size="sm"
                  className="ml-2"
                  onClick={() => getStatus(true)}
                >
                  <FontAwesomeIcon icon="sync-alt" spin={loading}/>
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <FwBootstrapTable
              keyField="ip"
              data={bgpNeighborsStatus}
              clientFilter={{
                options: [
                  {
                    category: toTTString("State"),
                    categorykey: "state",
                    type: "textoptions",
                    options: () => Array.from(new Set(bgpNeighborsStatus.map(r => r.state)))
                  },
                  {
                    category: toTTString("Remote IP"),
                    categorykey: "ip",
                    type: "text"
                  },
                  {
                    category: toTTString("Remote ASN"),
                    categorykey: "remoteAs",
                    type: "text"
                  }
                ]
              }}
              columns={[
                {
                  text: '',
                  dataField: "state",
                  headerStyle: (colum, colIndex) => {
                    return { textAlign: "left", verticalAlign: "top", width: '50px' };
                  },
                  formatter: (cell, row, index) => {
                    return <span className={`status-circle ${cell === 'Established' ? 'active' : 'inactive'}`}></span>
                  }
                },
                {
                  text: toTTString("Remote IP"),
                  dataField: "ip"
                },
                {
                  text: toTTString("Remote ASN"),
                  dataField: "remoteAs"
                },
                {
                  text: toTTString("UP Time"),
                  dataField: "peerUptime",
                },
                {
                  text: toTTString("Received routes"),
                  dataField: "pfxRcd",
                },
                {
                  text: toTTString("Sent routes"),
                  dataField: "pfxSnt",
                },
              ]}
              noDataIndication={
                props.deviceStatus.connection === 'disconnected'
                  ? toTTString('Device must be connected to view status information')
                  : toTTString("No BGP status available")
                }
              pagination={true}
              defaultSorted={[{ dataField: "name", order: "asc" }]}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getDeviceBgpStatus: (deviceId, fromAgent, scb, ecb) => dispatch(getDeviceBgpStatus(deviceId, fromAgent, scb, ecb))
  }
}

export default connect(null, mapDispatchToProps)(BgpNeighborsStatus);