import React from "react";

import ConfigureLTE from './ConfigureLte';
import ConfigureWifi from './ConfigureWifi';

const configureInterface = props => {
  return (
    <>
      {props.interface.deviceType === "lte" ? (
        <ConfigureLTE
          interface={props.interface}
          changeForm={props.changeForm}
          deviceStatus={props.deviceStatus}
          onSubmit={props.onSubmit}
          getInterfaceStatus={props.getInterfaceStatus}
          performInterfaceAction={props.performInterfaceAction}
          alertMsgMessage={props.alertMsgMessage}
          resetPinForm={props.resetPinForm}
          resetLteForm={props.resetLteForm}
        />
      ) : (
        <ConfigureWifi
          onSubmit={props.onSubmit}
          interface={props.interface}
          deviceStatus={props.deviceStatus}
          getInterfaceStatus={props.getInterfaceStatus}
          saveConfiguration={props.saveInterfaceConfiguration}
          alertMsgMessage={props.alertMsgMessage}
          performInterfaceAction={props.performInterfaceAction}
          changeForm={props.changeForm}
          resetForm={props.resetForm}
          setInitial={props.setInitial}
        />
      )}
    </>
  )
}


export default configureInterface;