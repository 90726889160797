import React, { Component, useContext } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactTooltip from "react-tooltip";
import PathLabelBadge from "../components/pathlabels/PathLabelBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import keyExchangeMethods from "../utils/keyExchangeMethods";
import { loadLocalSettings, saveLocalSettings } from "../utils/localSettings";
import { getTunnelIPs } from "../utils/TunnelsUtils";
import "./items/structured-filter/lib/react-structured-filter.css";
import StructuredFilter from "./items/structured-filter/lib/main";
import { TT, LanguageContext } from "../containers/Language";
import { getMajorVersion, getMinorVersion } from "../utils/DevicesUtils";
import { Control, Form, Errors } from "react-redux-form";
import { FWTextInput } from "./Common";
import { required, isNumber, validateNotificationField, validateNotificationsForm } from "../utils/Validators";
import { connect } from "react-redux";
import get from 'lodash/get';


const TunnelsFilter = ({
  filters,
  updateFilters,
  clearFilters,
  labels,
  peers,
}) => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <div className="container filter-bar">
      <StructuredFilter
        className={"search-query form-control"}
        placeholder={
          filters.length === 0
            ? toTTString("Filter by tunnel attributes")
            : toTTString("Add filter")
        }
        value={filters}
        options={[
          {
            category: toTTString("Device"),
            categorykey: "device?.name",
            type: "text",
          },
          {
            category: toTTString("Interface"),
            categorykey: "interface?Details.name",
            type: "text",
          },
          {
            category: toTTString("Path Label"),
            categorykey: "pathlabel.name",
            type: "textoptionsarray",
            options: () => {
              return labels.map((label) => label.name);
            },
          },
          {
            category: toTTString("Peer"),
            categorykey: "peer.name",
            type: "text",
          },
          {
            category: toTTString("Status"),
            categorykey: "tunnelStatus",
            type: "textoptions",
            options: () => {
              return [
                toTTString("Connected"),
                toTTString("Not Connected"),
                toTTString("Pending"),
                toTTString("N/A"),
              ];
            },
          },
          {
            category: toTTString("Device Port"),
            categorykey: "interface?Details.PublicPort",
            type: "text",
          },
        ]}
        customClasses={{
          input: "filter-tokenizer-text-input",
          results: "filter-tokenizer-list__container",
          listItem: "filter-tokenizer-list__item",
        }}
        onChange={updateFilters}
        operatorSigns={{
          textoptions: {
            "==": "==",
            "!=": "!=",
          },
          text: {
            "!empty": "!n",
            empty: "n",
            "==": "==",
            "!=": "!=",
            contains: "in",
            "!contains": "!in",
          },
          textoptionsarray: {
            contains: "contains",
            "!contains": "!contains",
          },
        }}
      />
      <FontAwesomeIcon icon="search" fixedWidth />
      <Button className="btn-primary filter-box-btn" onClick={clearFilters}>
        <TT>Clear Filters</TT>
      </Button>
    </div>
  );
};
class Tunnels extends Component {
  static contextType = LanguageContext;

  getOnlyTunnelNotifications = (notifications) => {
    const tunnelNotificationsDict = {};
    for (const notificationName in notifications) {
      const notification = notifications[notificationName];
      // Check if this is a tunnel notification with thresholds
      if (
        notification.type === "tunnel" &&
        notification.warningThreshold
      ) {
        tunnelNotificationsDict[notificationName] = {
          warningThreshold: notification.warningThreshold,
          criticalThreshold: notification.criticalThreshold,
        };
      }
    }
    return tunnelNotificationsDict;
  }

  componentDidMount () {
    console.log("Tunnels componentDidMount");
    this.props.getPathLabels((resp) => {
      this.setState({ labels: resp });
    });
    this.props.getAllPeers((resp) => {
      this.setState({ peers: resp });
    });
    if (this.statusTimer == null) {
      console.log("Tunnels: Setting periodic timer");
      const timer = setInterval(
        function (that) {
          console.log("Tunnels periodic update");
          // Only update when in focus
          if (document.hasFocus()) {
            that.getPaginatedTunnels();
          }
        },
        60000,
        this
      );
      this.statusTimer = timer;
    }
    // Load user's local settings
    const { filters, requestParams } = loadLocalSettings("tunnels");
    if (filters && this.state.filters.length === 0) this.setState({ filters });
    if (requestParams) {
      requestParams.offset = 0;
      this.setState({ requestParams });
    }
    this.props.getNotificationsConf(
      { org: this.props.auth.org },
      (notifications) => {
        this.setState({
          organizationNotifications: this.getOnlyTunnelNotifications(notifications),
        });
      }
    );
  }
  componentWillUnmount() {
    console.log("Tunnels componentWillUnmount");

    if (this.statusTimer != null) {
      console.log("Tunnels: Clearing periodic timer");
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  constructor(props) {
    super(props);

    this.statusTimer = null;

    this.state = {
      tunnels: [],
      isDelModalOpen: false,
      isModifyModalOpen: false,
      delEntity: null,
      delFiltered: false,
      isActionsDropDownOpen: false,
      selectedTunnels: [],
      requestParams: {
        sortField: "num",
        sortOrder: "asc",
        offset: 0,
        limit: 10,
      },
      labels: [],
      peers: [],
      filters: [],
      organizationNotifications: {},
      tunnelsToModifyIds: [],
    };
  }

  toggleActionsDropDown = () => {
    this.setState((prevState) => ({
      isActionsDropDownOpen: !prevState.isActionsDropDownOpen,
    }));
  }

  toggleDelModal = () => {
    this.setState((prevState) => ({
      isDelModalOpen: !prevState.isDelModalOpen,
    }));
  }

  toggleModifyModal = () => {
    this.setState((prevState) => ({
      isModifyModalOpen: !prevState.isModifyModalOpen,
    }));
  }

  handleDelete = (row) => {
    this.setState({ delEntity: row, delFiltered: false }, this.toggleDelModal);
  }

  handleDeleteMany = () => {
    this.setState({ delEntity: null, delFiltered: false }, this.toggleDelModal);
  }

  handleDeleteAll = () => {
    this.setState({ delEntity: null, delFiltered: true }, this.toggleDelModal);
  }

  getAggregatedNotificationsSettings = (tunnelsToModifyIds) => {
    this.getPaginatedTunnels();
    const tunnels = this.state.tunnels;
    const notificationsDict = {};
    tunnels.forEach((tunnel) => {
      let notifications =
      tunnel?.notificationsSettings ?? this.state.organizationNotifications;
      if (notifications && tunnelsToModifyIds.includes(tunnel._id)) {
        for (const eventName in notifications) {
          const { warningThreshold, criticalThreshold } =
            notifications[eventName];
          if (!notificationsDict[eventName]) {
            notificationsDict[eventName] = {
              warningThreshold,
              criticalThreshold,
            };
          } else {
            const existing = notificationsDict[eventName];
            if (warningThreshold !== existing.warningThreshold) {
              existing.warningThreshold = "varies";
            }
            if (criticalThreshold !== existing.criticalThreshold) {
              existing.criticalThreshold = "varies";
            }
          }
        }
      }
    });
    return notificationsDict;
  }

  handleModify = (row) => {
    const tunnel = this.state.tunnels.find((tunnel) => tunnel._id === row._id);
    const tunnelNotificationsConf =
    tunnel?.notificationsSettings ?? this.state.organizationNotifications;
    this.setState({
      tunnelsToModifyIds: [row._id]
    })
    this.props.changeModifyTunnelNotificationsForm(tunnelNotificationsConf);
    this.toggleModifyModal();
  }

  handleModifyMany = () => {
    const tunnelsNotificationsConf = this.getAggregatedNotificationsSettings(
      this.state.selectedTunnels
    );
    this.setState({
      tunnelsToModifyIds: this.state.selectedTunnels,
    });
    this.props.changeModifyTunnelNotificationsForm(tunnelsNotificationsConf);
    this.toggleModifyModal();
  }

  handleModifyAll = () => {
    const tunnelsToModifyIds = this.state.tunnels.map((tunnel) => tunnel._id);
    const tunnelsNotificationsConf =
      this.getAggregatedNotificationsSettings(tunnelsToModifyIds);
    this.setState({
      tunnelsToModifyIds: tunnelsToModifyIds,
    });
    this.props.changeModifyTunnelNotificationsForm(tunnelsNotificationsConf);
    this.toggleModifyModal();
  };

  approveModify = (notifications) => {
    let updatedNotifications = {};
    // avoid sending threshold numbers as strings to the tunnels
    for (let key in notifications) {
      updatedNotifications[key] = {};
      for (let innerKey in notifications[key]) {
        updatedNotifications[key][innerKey] = notifications[key][innerKey] !== 'varies'?
        Number(notifications[key][innerKey]): notifications[key][innerKey];
      }
    }
    this.props
      .modifyTunnelNotifications({
        org: this.props.auth.org,
        tunnelsIdList: this.state.tunnelsToModifyIds,
        notifications: updatedNotifications,
      })
      .then(() => {
        this.getPaginatedTunnels();
      });
    this.toggleModifyModal();
  }

  approveDelete = () => {
    this.toggleDelModal();
    if (this.state.delFiltered) {
      const filters = this.state.filters.map((f) => ({
        key: f.categorykey,
        op: f.operator,
        val: f.value,
      }));
      this.props.delTunnels(null, filters, () =>
        this.setState(
          {
            requestParams: { ...this.state.requestParams, offset: 0 },
            selectedTunnels: [],
          },
          this.viewParametersUpdated
        )
      );
    } else if (this.state.delEntity === null) {
      this.props.delTunnels(
        this.state.selectedTunnels.reduce(
          (o, _id) => ({ ...o, [_id]: true }),
          {}
        ),
        null,
        () =>
          this.setState(
            { selectedTunnels: [], delFiltered: false },
            this.getPaginatedTunnels
          )
      );
    } else {
      this.props.delTunnels({ [this.state.delEntity._id]: true }, null, () =>
        this.setState(
          {
            selectedTunnels: this.state.selectedTunnels.filter(
              (id) => id !== this.state.delEntity._id
            ),
          },
          this.getPaginatedTunnels
        )
      );
    }
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        selectedTunnels: [...this.state.selectedTunnels, row._id],
      });
    } else {
      this.setState({
        selectedTunnels: this.state.selectedTunnels.filter(
          (id) => id !== row._id
        ),
      });
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ selectedTunnels: rows.map((r) => r._id) });
    } else {
      this.setState({ selectedTunnels: [] });
    }
  };

  viewParametersUpdated = () => {
    // Save user's local settings
    const { filters, requestParams } = this.state;
    saveLocalSettings("tunnels", { filters, requestParams });
    this.getPaginatedTunnels();
  }

  onTableChange =  (type, newState) => {
    const { sizePerPage, page, sortField, sortOrder } = newState;
    if (sizePerPage !== undefined && page !== undefined) {
      this.setState(
        {
          requestParams: {
            offset: page * sizePerPage,
            limit: sizePerPage,
            sortField: sortField,
            sortOrder: sortOrder,
          },
        },
        this.viewParametersUpdated
      );
    }
  };

  getPaginatedTunnels = () => {
    this.props.getAllTunnels(
      {
        ...this.state.requestParams,
        filters: JSON.stringify(
          this.state.filters.map((f) => ({
            key: f.categorykey,
            op: f.operator,
            val: f.value,
          }))
        ),
      },
      (tunnels) => this.setState({ tunnels })
    );
  };

  updateFilters = (filters) => {
    // Clear checkboxes when the filter changes.
    this.setState(
      {
        requestParams: { ...this.state.requestParams, offset: 0 },
        filters: filters,
        selectedTunnels: [],
      },
      this.viewParametersUpdated
    );
  }

  getVxlanPort = (version, orgVxlanPort) => {
    const major = getMajorVersion(version);
    const minor = getMinorVersion(version);
    // devices from 6.2 support configurable source port
    if (major > 6 || (major === 6 && minor >= 2)) {
      return orgVxlanPort;
    }
    return "4789";
  };

  getValOrNA = (val) => {
    return val || "N/A";
  };

  getTunnelInterfaceInfo = (row, key, version) => {
    // normal tunnel
    const vxlanPort = this.getVxlanPort(version, row.org.vxlanPort);
    let ip = row[key].IPv4;
    let publicIp = row[key].PublicIP;

    // key is "interfaceADetails" or "interfaceBDetails".
    const otherSideKey =
      key === "interfaceADetails" ? "interfaceBDetails" : "interfaceADetails";

    let publicPort = null;
    if (!row.peer && publicIp === row[otherSideKey].PublicIP) {
      // if local tunnels, use the default vxlan port instead of interface public port
      publicPort = vxlanPort;
    } else if (row[key].useFixedPublicPort) {
      // if useFixedPublicPort is enabled, use the default vxlan port instead of interface public port
      publicPort = vxlanPort;
    } else {
      publicPort = row[key].PublicPort;
    }

    if (ip) {
      ip += ":" + vxlanPort;
    }

    if (publicIp) {
      publicIp += ":" + publicPort;
    }
    return [
      <span style={{ fontSize: "0.7rem", display: "block" }}>
        <TT>IP</TT>: {this.getValOrNA(ip)}
      </span>,
      <span style={{ fontSize: "0.7rem", display: "block" }}>
        <TT>Public</TT>: {this.getValOrNA(publicIp)}
      </span>,
    ];
  };

  handleResetToOrgDefault = () => {
    this.props.changeModifyTunnelNotificationsForm(this.state.organizationNotifications);
  }

  render() {
    const toTTString = this.context.toTTString;
    const renderThresholdInput = (eventType, label, modelPath, unit) => {
      const thresholdValue = get(this.props.form, modelPath);
      return (
        <Row className="form-group">
          <Label htmlFor={modelPath} md={6}>
            <TT>{label}</TT>:
          </Label>
          <span className="mt-2" md={1}>
            {">"}
          </span>
          <Col md={5}>
            <Control.text
              style={{
                border: thresholdValue === "varies" ? "1px solid orange" : "",
                backgroundImage: "none",
              }}
              model={`.${modelPath}`}
              id={modelPath}
              name={modelPath}
              placeholder={toTTString("Enter a number")}
              component={FWTextInput}
              withFieldValue
              validators={
                thresholdValue !== "varies"
                  ? {
                      required,
                      isNumber,
                      range: (value) => {
                        const isValid = validateNotificationField(
                          value,
                          unit
                        ).valid;
                        return isValid;
                      },
                    }
                  : null
              }
              data-tip={thresholdValue === "varies" ? true : undefined}
              data-for="not-equal"
            />
            <Errors
              className="text-danger"
              model={`.${modelPath}`}
              messages={{
                required: toTTString("Required field"),
                isNumber: toTTString("Enter a number"),
                range: () => {
                  const validationResult = validateNotificationField(
                    thresholdValue,
                    unit
                  );
                  return toTTString(validationResult.message);
                },
              }}
            />
          <Errors
            className="text-danger"
            model="."
            messages={{
              validateNotificationsForm: () => {
                const validationResult = validateNotificationsForm(this.props.form);
                const error = validationResult.errors.find(
                  (error) => error.eventType === eventType
                );
                return error ? toTTString(error.message) : "";
              },
            }}
          />
          </Col>
          <span className="mt-2">{unit}</span>
        </Row>
      );
    };
    const columns = [
      {
        text: <TT>ID</TT>,
        dataField: "num",
        sort: true,
        headerStyle: { width: "5%", textAlign: "left", minWidth: "50px" },
      },
      {
        text: <TT>Device A</TT>,
        dataField: "deviceA.name",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "11%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          const { ip1 } = getTunnelIPs(row.num, row.org.tunnelRange)
          return [
            <Link to={"/devices/deviceinfo/" + row.deviceA._id + '?tab=Interfaces'}>
              <span style={{color: 'steelblue'}}>{row.deviceA.name}</span>
            </Link>,
            <br />,
            <span style={{ fontSize: "0.7rem", display: "block" }}>
              (<TT>Loopback</TT>: {ip1})
            </span>,
          ];
        },
      },
      {
        text: <TT>Interface A</TT>,
        dataField: "interfaceADetails.name",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "12%", textAlign: "left", minWidth: "175px" };
        },
        formatter: (cellContent, row, rowIndex) => {
          let text = [row.interfaceADetails.name, <br />];

          if (row.peer) {
            text = text.concat([
              <span style={{ fontSize: "0.7rem", display: "block" }}>
                <TT>IP</TT>: {this.getValOrNA(row.interfaceADetails.IPv4)}
              </span>,
              <span style={{ fontSize: "0.7rem", display: "block" }}>
                <TT>Public</TT>:{" "}
                {this.getValOrNA(row.interfaceADetails.PublicIP)}
              </span>,
            ]);
          } else {
            // normal tunnel
            text = text.concat(
              this.getTunnelInterfaceInfo(
                row,
                "interfaceADetails",
                row.deviceA.versions.agent
              )
            );
          }
          return text;
        },
      },
      {
        text: <TT>Device B / Peer</TT>,
        dataField: "deviceB.name",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "11%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          if (row.peer) {
            return (
              <Link to={"/peers/configure/" + row.peer._id}>
                <span style={{ color: "steelblue" }}>{row.peer.name}</span>
              </Link>
            );
          } else {
            const { ip2 } = getTunnelIPs(row.num, row.org.tunnelRange);
            return [
              <Link to={"/devices/deviceinfo/" + row.deviceB._id + '?tab=Interfaces'}>
                <span style={{color: 'steelblue'}}>{row.deviceB.name}</span>
              </Link>,
              <br />,
              <span style={{ fontSize: "0.7rem", display: "block" }}>
                (<TT>Loopback</TT>: {ip2})
              </span>,
            ];
          }
        },
      },
      {
        text: <TT>Interface B</TT>,
        dataField: "interfaceBDetails.name",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "12%", textAlign: "left", minWidth: "175px" };
        },
        formatter: (cellContent, row, rowIndex) => {
          if (row.peer) {
            return [
              <TT>Peer</TT>,
              <br />,
              <span style={{ fontSize: "0.7rem", display: "block" }}>
                {"Remote:" + row.peer.remoteIP}
              </span>,
            ];
          } else {
            // normal tunnel
            let text = [row.interfaceBDetails.name, <br />];
            text = text.concat(
              this.getTunnelInterfaceInfo(
                row,
                "interfaceBDetails",
                row.deviceB.versions.agent
              )
            );
            return text;
          }
        },
      },
      {
        text: <TT>Path Label</TT>,
        dataField: "pathlabel",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "8%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          if (!row.pathlabel) return <TT>None</TT>;
          const { color, name } = row.pathlabel;
          return <PathLabelBadge name={name} color={color} />;
        },
      },
      {
        text: <TT>AVG Latency</TT>,
        dataField: "tunnelStatusA.rtt",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "6%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          let max = 0;
          if (row.peer) {
            if (
              row.isPending ||
              Object.keys(row.tunnelStatusA).length === 0 ||
              row.tunnelStatusA.status === "down"
            ) {
              return <TT>N/A</TT>;
            } else if (
              row.tunnelStatusA.rtt === 0 &&
              row.peer?.ips?.length === 0 &&
              row.peer?.urls?.length === 0
            ) {
              return (
                <small>
                  <TT>Monitor not provisioned</TT>
                </small>
              );
            }
            max = row.tunnelStatusA.rtt;
          } else {
            if (
              row.isPending ||
              Object.keys(row.tunnelStatusA).length === 0 ||
              Object.keys(row.tunnelStatusB).length === 0 ||
              row.tunnelStatusA.status === "down" ||
              row.tunnelStatusB.status === "down"
            ) {
              return <TT>N/A</TT>;
            }
            max = Math.max(row.tunnelStatusA.rtt, row.tunnelStatusB.rtt);
          }
          return max.toFixed(2) + toTTString("ms");
        },
      },
      {
        text: <TT>Drop Rate</TT>,
        dataField: "tunnelStatusA.drop_rate",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "7%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          const statusA = Object.keys(row.tunnelStatusA).length > 0;
          const statusB = row.peer
            ? false
            : Object.keys(row.tunnelStatusB).length > 0;
          let max = 0;
          if (row.peer) {
            if (
              row.isPending ||
              !statusA ||
              row.tunnelStatusA.status === "down"
            ) {
              return <TT>N/A</TT>;
            } else {
              max = row.tunnelStatusA.drop_rate;
            }
          } else {
            if (
              row.isPending ||
              !statusA ||
              !statusB ||
              row.tunnelStatus !== "Connected"
            ) {
              return <TT>N/A</TT>;
            } else {
              max = Math.max(
                row.tunnelStatusA.drop_rate,
                row.tunnelStatusB.drop_rate
              );
            }
          }

          return `${max.toFixed(2)} %`;
        },
      },
      {
        text: <TT>Encrypt</TT>,
        dataField: "encryptionMethod",
        sort: true,
        formatter: (value) => keyExchangeMethods[value],
        headerStyle: (colum, colIndex) => {
          return { width: "6%", textAlign: "left" };
        },
      },
      {
        text: <TT>Adv.Options</TT>,
        dataField: "advancedOptions",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "8%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          const { mtu, mssClamp, ospfCost, ospfArea, routing } = cellContent || {};
          return (
            <span style={{ fontSize: "0.7rem" }}>
              <div>
                <TT>MTU</TT>: {mtu || "auto"}
              </div>
              <div>
                <TT>MSS Clamp</TT>: {mssClamp || "yes"}
              </div>
              <div>
                <TT>Routing</TT>: {(routing || "ospf").toUpperCase()}
              </div>
              <div>
                <TT>OSPF Cost</TT>:{" "}
                {routing === "bgp" ? "N/A" : ospfCost || "default"}
              </div>
              <div>
                <TT>OSPF Area</TT>:{" "}
                {routing === "bgp" ? "N/A" : ospfArea || "0"}
              </div>
            </span>
          );
        },
      },
      {
        text: <TT>Status</TT>,
        dataField: "tunnelStatus",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "8%", textAlign: "left", minWidth: "100px" };
        },
        formatter: (status, row, rowIndex) => {
          let tipMsg = null;

          let color =
            status === "Connected"
              ? "success"
              : status === "Not Connected"
              ? "danger"
              : "secondary";

          if (status === "N/A") {
            tipMsg = (
              <TT>Devices should be connected to determine tunnel status</TT>
            );
          }

          if (row.isPending && status !== "N/A") {
            // don't show pending if status is N/A
            color = "warning";
            status = <TT>Pending</TT>;
            tipMsg = row.pendingReason.split("\n").map((s) => {
              return (
                <>
                  <TT>{s}</TT>
                  <br />
                </>
              );
            });
          }

          return (
            <React.Fragment>
              <Badge color={color} data-tip data-for={"stat-conn" + rowIndex}>
                {status}

                {tipMsg ? (
                  <>
                    <ReactTooltip place="left" id={"stat-conn" + rowIndex}>
                      <span style={{ fontWeight: "normal" }}>{tipMsg}</span>
                    </ReactTooltip>
                    <span
                      className="helpTooltip"
                      style={{
                        height: "12px",
                        width: "12px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        marginLeft: "3px",
                      }}
                    ></span>
                  </>
                ) : null}
              </Badge>
            </React.Fragment>
          );
        },
      },
      {
        text: <TT>Actions</TT>,
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "6%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div>
              <Button
                color="warning"
                className="action-btn"
                data-tip
                data-for={"modify-a-" + row.num}
                size="sm"
                onClick={() => this.handleModify(row)}
              >
                <FontAwesomeIcon icon="cog" fixedWidth />
              </Button>
              <ReactTooltip id={"modify-a-" + row.num}>
                <span>
                  <TT>Modify Tunnel</TT>
                </span>
              </ReactTooltip>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for={"delete-a" + row.num}
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id={"delete-a" + row.num}>
                <span>
                  <TT>Delete Tunnel</TT>
                </span>
              </ReactTooltip>
            </div>
          );
        },
      },
    ];

    const { limit, offset } = this.state.requestParams;
    const paginationOptions = {
      totalSize: +this.props.tunnels.total,
      page: limit > 0 ? offset / limit : 0,
      sizePerPage: limit,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("Next page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("First page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{ from: from, to: to, size: size }}>
            Showing #from# to #to# of #size# Results
          </TT>
        </span>
      ),
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.selectedTunnels,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };

    const selectedTunnelsInfo = this.state.delFiltered
      ? " " + toTTString("ALL Tunnels matching the filter")
      : this.state.selectedTunnels.length > 1 && this.state.delEntity === null
      ? this.state.selectedTunnels.length + " " + toTTString("Tunnels")
      : toTTString("Tunnel");

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home">
              <TT>Home</TT>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <TT>Inventory</TT>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <TT>Tunnels</TT>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4>
            <TT>Tunnels</TT>
          </h4>
          {this.props.tunnels.isLoading ? <div className="signal"></div> : null}
        </div>
        <div className="col-md-12">
          <div className="d-flex align-items-center screen-bar mb-3">
            <ReactTooltip id="refresh-a">
              <span>
                <TT>Refresh</TT>
              </span>
            </ReactTooltip>

            <Button
              color="info"
              className="refresh-btn"
              data-tip
              data-for="refresh-a"
              size="sm"
              onClick={this.getPaginatedTunnels}
            >
              <FontAwesomeIcon icon="sync-alt" />
            </Button>
            <div className="mr-1">
              <Dropdown
                isOpen={this.state.isActionsDropDownOpen}
                toggle={this.toggleActionsDropDown}
              >
                <DropdownToggle caret className="action-drop-down">
                  <TT>Actions</TT>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>
                    <TT>Tunnels Actions</TT>
                  </DropdownItem>
                  <DropdownItem divider />
                  {this.state.selectedTunnels.length >= 1 && (
                    <DropdownItem onClick={() => this.handleModifyMany()}>
                      <TT>Modify selected tunnels</TT>
                    </DropdownItem>
                  )}
                  {this.state.filters.length > 0 && (
                    <DropdownItem onClick={() => this.handleModifyAll()}>
                      <TT>Modify All tunnels matching the filter</TT>
                    </DropdownItem>
                  )}
                  {this.state.selectedTunnels.length >= 1 && (
                    <DropdownItem onClick={() => this.handleDeleteMany()}>
                      <TT>Delete Selected Tunnels</TT>
                    </DropdownItem>
                  )}
                  {this.state.filters.length > 0 && (
                    <DropdownItem onClick={() => this.handleDeleteAll()}>
                      <TT>Delete All Tunnels matching the filter</TT>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
            <TunnelsFilter
              filters={this.state.filters}
              labels={this.state.labels}
              peers={this.state.peers}
              updateFilters={this.updateFilters}
              clearFilters={() =>
                this.setState({ filters: [] }, this.getPaginatedTunnels)
              }
            />
          </div>
          <BootstrapTable
            striped
            hover
            condensed
            wrapperClasses={"scroll-x"}
            keyField="_id"
            remote={true}
            onTableChange={this.onTableChange}
            data={this.state.tunnels}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={toTTString("No tunnels available")}
            defaultSorted={[{ dataField: "deviceA", order: "asc" }]}
            selectRow={selectRow}
          />
        </div>
        <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
          <ModalHeader>
            <TT params={{ selectedTunnelsInfo: selectedTunnelsInfo }}>
              Delete #selectedTunnelsInfo#
            </TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT params={{ selectedTunnelsInfo: selectedTunnelsInfo }}>
                Are you sure to delete #selectedTunnelsInfo# ?
              </TT>
            </div>
            <Button color="danger" onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleDelModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          size="lg"
          style={{ maxWidth: "550px", width: "100%" }}
          isOpen={this.state.isModifyModalOpen}
          toggle={this.toggleModifyModal}
        >
          <Form
            model="modifyTunnelNotificationsForm"
            onSubmit={(values) => this.approveModify(values)}
            validators={{
              "": {
                validateNotificationsForm: (values) => {
                  return validateNotificationsForm(values).valid;
                },
              },
            }}
          >
            <ModalHeader>
              <TT>Modify notifications settings</TT>
            </ModalHeader>
            <ModalBody>
              {renderThresholdInput("Link/Tunnel round trip time", "RTT warning threshold", "['Link/Tunnel round trip time'].warningThreshold", "ms")}
              {renderThresholdInput("Link/Tunnel round trip time","RTT critical threshold", "['Link/Tunnel round trip time'].criticalThreshold", "ms")}
              {renderThresholdInput("Link/Tunnel default drop rate","Drop rate warning threshold", "['Link/Tunnel default drop rate'].warningThreshold", "%")}
              {renderThresholdInput("Link/Tunnel default drop rate","Drop rate critical threshold", "['Link/Tunnel default drop rate'].criticalThreshold", "%")}
              <ReactTooltip id="not-equal">
                <span>
                  <TT>
                    This value varies for the selected tunnels. Setting it
                    will set all selected tunnels
                  </TT>
                </span>
              </ReactTooltip>
              <Control.button
                model="modifyTunnelNotificationsForm"
                className="btn btn-primary no-border-on-click"
                color="outline-secondary"
                style={{ width: "70px" }}
                disabled={{ valid: false }}
                type="submit"
              >
                <TT>OK</TT>
              </Control.button>
              <Button
                className="float-right no-border-on-click custom-button-width"
                onClick={this.handleResetToOrgDefault}
                data-tip= 'true'
                data-for= 'reset'
              >
                Reset
              </Button>
              <ReactTooltip id="reset">
                <span>
                  <TT>
                    Reset to the organization default settings
                  </TT>
                </span>
              </ReactTooltip>
              <Button
                className="float-right no-border-on-click"
                color="black"
                onClick={this.toggleModifyModal}
                type="button"
              >
                <TT>Cancel</TT>
              </Button>
            </ModalBody>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.modifyTunnelNotificationsForm,
  };
};

export default connect(mapStateToProps)(Tunnels);