import React, { Component } from 'react';
import { Row, Col, Label, Progress } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Control, Form, Errors } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { minValue, maxValue } from '../../utils/Validators';
import './DeviceInfo.css';
import InfoNotAvailable from "../items/infoNotAvailable";
import { TT, LanguageContext } from '../../containers/Language'

class PacketTracesCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      tracesResponse: null,
      tracesFetched: false,
      currentStep: 0,
      display: 0,
      infoNotAvailable: false
    };

    this.stepInterval = 100;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
  }

  handleProgress() {
    const { currentStep, identity, timeout } = this.state;

    const nextStep = currentStep + this.stepInterval;

    if (nextStep > timeout) {
      this.setState({
        display: 100,
      });
      clearInterval(identity);
    }

    this.setState({
      currentStep: nextStep,
      display: (100 * nextStep) / timeout,
    });
  }

  handleSubmit(values) {
    this.setState({
      tracesFetched: false,
      traces: [],
      timeout: values.timeout * 1000,
      display: 0,
      currentStep: 0,
      identity: setInterval(this.handleProgress, this.stepInterval),
    });
    this.props.getDevicePacketTraces(
      this.props.id,
      {
        packets: values.packets,
        timeout: values.timeout,
      },
      (resp) => {
        if (resp.error === 'timeout') {
          this.setState({ infoNotAvailable: true });
        } else {
          this.setState({
            traces: resp.traces,
            status: resp.deviceStatus,
            tracesFetched: true,
            infoNotAvailable: false
          });
        }
      }
    );
  }

  render() {
    const toTTString = this.context.toTTString
    const { traces, status, tracesFetched, display } = this.state;
    return (
      <React.Fragment>
        <Form
          className="log-card-upper-panel"
          model="PacketTracesCard"
          onSubmit={(values) => this.handleSubmit(values)}
        >
          <Row className="form-group">
            <Label htmlFor="packets" md={1} xs={1}>
              <TT>Packets</TT>
            </Label>
            <Col md={2} xs={4}>
              <Control.text
                model=".packets"
                id="packets"
                name="packets"
                placeholder={toTTString("Number of packets to fetch")}
                component={FWTextInput}
                data-tip
                data-for="packets"
                withFieldValue
                validators={{
                  minValue: minValue(1),
                  maxValue: maxValue(500),
                }}
              />
              <Errors
                className="text-danger"
                model=".packets"
                messages={{
                  minValue: toTTString('Number of packets must be greater than 0'),
                  maxValue: toTTString('Number of packets cannot exceed 500'),
                }}
              />
              <ReactTooltip id="packets">
                <span><TT>Number of packets to fetch</TT></span>
              </ReactTooltip>
            </Col>
            <Label htmlFor="timeout" md={1} xs={1}>
              <TT>Timeout</TT>
            </Label>
            <Col md={2} xs={4}>
              <Control.text
                model=".timeout"
                id="timeout"
                name="timeout"
                placeholder={toTTString("Time (sec) to wait for packets")}
                component={FWTextInput}
                data-tip
                data-for="timeout"
                withFieldValue
                validators={{
                  minValue: minValue(1),
                  maxValue: maxValue(30),
                }}
              />
              <Errors
                className="text-danger"
                model=".timeout"
                messages={{
                  minValue: toTTString('Time must be greater than 1 sec'),
                  maxValue: toTTString('Time cannot exceed 30 sec'),
                }}
              />
              <ReactTooltip id="timeout">
                <span><TT>Time (sec) to wait for packets</TT></span>
              </ReactTooltip>
            </Col>
            <Col md={{ offset: 0 }} xs={{ offset: 0 }}>
              <Control.button
                model="PacketTracesCard"
                className="btn btn-primary action-btn-top"
              >
                <TT>Fetch Traces</TT>
              </Control.button>
            </Col>
          </Row>
        </Form>

        <InfoNotAvailable show={this.state.infoNotAvailable} />

        {!traces ? (
          ''
        ) : status === 'disconnected' ? (
          <TT>Device must be connected to view traces</TT>
        ) : (
          <div>
            {tracesFetched ? (
              <div>
                <h5><TT>Traces</TT>:</h5>
                <pre className="log-lines-wrapper">
                  {`${
                    traces.length === 0 ? <TT>No traces found</TT> : traces.join('\n')
                  }`}
                </pre>
              </div>
            ) : (
              <div>
                <h5><TT>Fetching traces</TT>...</h5>
                <Progress striped color='info' value={display}></Progress>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default PacketTracesCard;
