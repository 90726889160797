import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Card, CardBody } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import {
  required,
  maxLength,
  minLength,
  isEmail,
  validateName,
  validateJobTitle
} from "../../utils/Validators";
import { FWTextInput } from '../Common';
import { TT, LanguageContext  } from '../../containers/Language'

class AddMember extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      permissionTo: "account"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getMemberOptions('account',
      (resp) => {
        this.props.resetForm();
        this.props.changeFormEntity(this.props.members.options[0].id,{'silent':true});
      });
  }

  handleSubmit(values) {
    console.log('Adding Member: ' + JSON.stringify(values));
    this.props.addMember(values, this.props.resetForm);
  }

  getUserRole(permissionTo) {
    switch(permissionTo) {
      case 'account':
        return 'owner';
      case 'group':
      case 'organization':
        return 'manager';
      default:
        return 'owner';
    }
  }

  render() {
    const toTTString = this.context.toTTString
    return(
      <React.Fragment>
        <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/members"><TT>Users</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>Add User</TT></BreadcrumbItem>
            </Breadcrumb>
        <h4><TT>Add User</TT></h4>
        <div className="col-md-7">
          <Card id='addMemberCard'>
            <CardBody>
              <Form model="addMember" onSubmit={(values) => this.handleSubmit(values)}>
              <Row className="form-group">
                  <Label htmlFor="email" md={3}>
                    <TT>Email</TT>
                  </Label>
                  <Col md={9}>
                    <Control.text
                      type="email"
                      model=".email"
                      id="email"
                      name="email"
                      placeholder={toTTString("Email")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        maxLength: maxLength(50),
                        isEmail: isEmail
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".email"
                      show="touched"
                      messages={{
                        required: toTTString('Required field'),
                        maxLength: toTTString("Length must be at most 50"),
                        isEmail: toTTString("Must be a valid email")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userFirstName" md={3}>
                    <TT>First Name</TT>
                  </Label>
                  <Col md={9}>
                    <Control.text
                      model=".userFirstName"
                      id="userFirstName"
                      name="userFirstName"
                      placeholder={toTTString("First Name")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        minLength: minLength(2),
                        maxLength: maxLength(15),
                        validateName: validateName
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".userFirstName"
                      show="touched"
                      messages={{
                        required: toTTString('Required field'),
                        minLength: toTTString("Length must be at least 2"),
                        maxLength: toTTString("Length must be at most 15"),
                        validateName: toTTString("Special characters not allowed")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userLastName" md={3}>
                    <TT>Last Name</TT>
                  </Label>
                  <Col md={9}>
                    <Control.text
                      model=".userLastName"
                      id="userLastName"
                      name="userLastName"
                      placeholder={toTTString("Last Name")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        minLength: minLength(2),
                        maxLength: maxLength(15),
                        validateName: validateName
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".userLastName"
                      show="touched"
                      messages={{
                        required: toTTString('Required field'),
                        minLength: toTTString("Length must be at least 2"),
                        maxLength: toTTString("Length must be at most 15"),
                        validateName: toTTString("Special characters not allowed")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userJobTitle" md={3}>
                    <TT>Job Title</TT>
                  </Label>
                  <Col md={9}>
                    <Control.text
                      model=".userJobTitle"
                      id="userJobTitle"
                      name="userJobTitle"
                      placeholder={toTTString("Job Title")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        maxLength: maxLength(30),
                        minLength: minLength(2),
                        validateJobTitle: validateJobTitle
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".userJobTitle"
                      show="touched"
                      messages={{
                        maxLength: toTTString("Length must be at most 30"),
                        minLength: toTTString("Length must be at least 2"),
                        validateJobTitle: toTTString("Special characters not allowed")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userPermissionTo" md={3}>
                    <TT>Permission To</TT> <span className="helpTooltip" data-tip data-for='permissionTip'></span>
                    <ReactTooltip id='permissionTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                      <TT>The hierarchy level to which you want to give the User access to</TT>.<br/>
                      <TT>"Account" - the highest level allowing to manage or view all of the Organizations (networks, companies) in the Account</TT>.<br/>
                      <TT>"Group" - assign permission to a collection of Organizations that are part of a specific Group</TT>.<br/>
                      <TT>"Organization" - assign permission to a single Organization</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".userPermissionTo"
                      id="userPermissionTo"
                      name="userPermissionTo"
                      placeholder={toTTString("Account")}
                      className="form-control"
                      onChange={event => {
                        this.props.getMemberOptions(event.target.value,
                          (resp) => this.props.changeFormEntity(this.props.members.options[0].id,{'silent':true}));
                        this.props.changeFormRole(this.getUserRole(event.target.value),{'silent':true});
                        this.setState({ permissionTo: event.target.value })
                      }}
                    >
                    <option value="account" disabled hidden>
                      {toTTString("Account")}
                    </option>
                    <option value={"account"}>{toTTString("Account")}</option>
                    <option value={"group"}>{toTTString("Group")}</option>
                    <option value={"organization"}>{toTTString("Organization")}</option>
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userEntity" md={3}>
                    <TT>Entity</TT> <span className="helpTooltip" data-tip data-for='entityTip'></span>
                    <ReactTooltip id='entityTip'>
                      <span>
                        <TT>The specific Account, Group or Organization name to assign the permission to</TT>. 
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".userEntity"
                      id="userEntity"
                      name="userEntity"
                      placeholder=""
                      className="form-control"
                      disabled={!this.props.members.options.length}  
                    >
                    {this.props.members.options.length?
                    <option value={this.props.members.options[0].id} disabled hidden>
                      {this.props.members.options[0].value}
                    </option>:null}
                    {this.props.members.options.map(option => <option value={option.id} key={option.id}>{option.value}</option>)}
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userRole" md={3}>
                    <TT>Role</TT> <span className="helpTooltip" data-tip data-for='roleTip'></span>
                    <ReactTooltip id='roleTip'>
                    <TT>The permission level</TT>.<br/>
                    <TT>Owner (for account only) - manage all organizations and the account info including billing</TT>.<br/>
                    <TT>Manager - manage the networks but not the account info and not billing</TT>.<br/>
                    <TT>Viewer - view only permission</TT>.
                    </ReactTooltip>
                  </Label>
                  <Col md={5}>
                    <Control.select
                      model=".userRole"
                      id="userRole"
                      name="userRole"
                      placeholder={toTTString("Owner")}
                      className="form-control"
                    >
                    <option
                      value={ this.getUserRole(this.state.permissionTo) }
                      disabled
                      hidden>
                      { this.state.permissionTo === "account" ? toTTString("Owner") : toTTString("Manager") }
                    </option>
                    {this.state.permissionTo === "account" ? <option value={"owner"}>{toTTString("Owner")}</option> : "" }
                    <option value={"manager"}>{toTTString("Manager")}</option>
                    <option value={"viewer"}>{toTTString("Viewer")}</option>
                    </Control.select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col md={{size:10, offset: 3}}>
                    <Control.button model="addMember" disabled={{ valid: false }} 
                      className="btn btn-primary"><TT>Invite</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default AddMember;