import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalFooter, Breadcrumb, BreadcrumbItem, ModalBody } from "reactstrap";
import ApplicationTable from "./ApplicationsTable";
import { TT } from '../../containers/Language'
import ApplicationsTableFilter from "./ApplicationsTableFilter";

class AppStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedApp: {},
      isPurchaseModalOpen: false,
    };

    this.handlePurchase = this.handlePurchase.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.approvePurchaseApp = this.approvePurchaseApp.bind(this);
    this.getData = this.getData.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.getData()
  }

  getData(filters) {
    this.props.getRepositoryApplications(filters);
    this.props.getPurchasedApplications();
  }

  handlePurchase(app) {
    this.setState({
      selectedApp: app,
      isPurchaseModalOpen: true,
    });
  }

  approvePurchaseApp() {
    this.props.purchaseApplication({ ...this.state.selectedApp });
    this.closeModal();
  }

  closeModal() {
    this.setState({
      selectedApp: {},
      isPurchaseModalOpen: false,
    });
  }

  renderActions(row, formatExtraData) {
    const isPurchased = formatExtraData.find(p => p.appStoreApp._id === row._id);
    if (isPurchased) {
      return (
      <Link to={`/applications/${isPurchased._id}/configurations`}>
        <Button
          outline
          color="success"
          size="sm">
            <TT>Open</TT>
        </Button>
      </Link>)
    }
    return (
      <div>

        <Button
          color="success"
          className="action-btn"
          data-tip
          disabled={isPurchased}
          data-for="purchase-a"
          size="sm"
          onClick={(e) => this.handlePurchase(row)}
        >
          <FontAwesomeIcon icon="arrow-circle-down" fixedWidth />
        </Button>
        <ReactTooltip id="purchase-a">
          <span><TT>Get</TT></span>
        </ReactTooltip>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Applications</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Available</TT></BreadcrumbItem>
        </Breadcrumb>

        <div className="container">
          <h4><TT>Applications</TT></h4>
          {this.props.isLoading ? <div className="signal"></div> : null}
        </div>

        <div className="col-md-12">

          <ApplicationsTableFilter refreshHandler={this.getData} keyName="name"/>

          <ApplicationTable
            isLoading={this.props.isLoading}
            apps={this.props.repository}
            actions={this.renderActions}
            formatExtraData={this.props.purchasedApplications}
          />
        </div>

        <Modal
          centered
          isOpen={this.state.isPurchaseModalOpen}
          toggle={this.closeModal}
        >
          <ModalHeader toggle={this.closeModal}>
            <TT>Get</TT> {this.state.selectedApp.name} <TT>app</TT>?
          </ModalHeader>
          <ModalBody>
            {this.state.selectedApp.description}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.approvePurchaseApp}>
              <TT>Get</TT>
            </Button>
            <Button color="secondary" onClick={this.closeModal}>
              <TT>Close</TT>
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AppStore;
