import React, { Component } from 'react';
import { Row, Col, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { isInteger, minValue, maxValue, maxLength, isIP4, validateStringNoSpaces } from '../../utils/Validators';
import './DeviceInfo.css';
import { TT, LanguageContext } from '../../containers/Language'

class OSPFLinkModal extends Component {
  static contextType = LanguageContext
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.handleClose}
        >

        <ModalHeader
          toggle={this.handleClose}
        >
          {this.props.interface?.IPv4} <TT>Link Configuration</TT>
        </ModalHeader>

        <ModalBody>
          <Form
            model="updateInterface"
            onSubmit={this.props.osSubmit}
          >
            <Row className="form-group">
              <Label htmlFor="area" md={4}>
                <TT>Area Number</TT>
              </Label>
              <Col md={8}>
                <Control.text
                  type="text"
                  model=".ospf.area"
                  id="area"
                  name=".ospf.area"
                  placeholder={toTTString("Area Number")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    validArea: val => isInteger(val, false) || isIP4(val)
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".ospf.area"
                  show="touched"
                  messages={{
                    required: toTTString("Required field"),
                    validArea: toTTString("Invalid area number")
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group align-items-center">
              <Label htmlFor="keyId" md={4}>
                <TT>MD5 Key ID</TT>
              </Label>
              <Col md={8}>
                <Control.text
                  type="text"
                  model=".ospf.keyId"
                  id="keyId"
                  name="keyId"
                  placeholder={toTTString("Key ID")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    isInteger: val => isInteger(val, true)
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".ospf.keyId"
                  show="touched"
                  messages={{
                    isInteger: toTTString("Invalid Number"),
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group align-items-center">
              <Label htmlFor="key" md={4}>
                <TT>MD5 Key</TT>
              </Label>
              <Col md={8}>
                <Control.text
                  type="text"
                  model=".ospf.key"
                  id="key"
                  name="key"
                  placeholder={toTTString("Key")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    maxLength: maxLength(16),
                    validateStringNoSpaces: val => {
                      return val === '' || val === null || val === undefined || validateStringNoSpaces(val)
                    }
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".ospf.key"
                  show="touched"
                  messages={{
                    maxLength: toTTString('Length must be at most 16'),
                    validateStringNoSpaces: toTTString('Invalid key'),
                  }}
                />
              </Col>
            </Row>


            <Row className="form-group align-items-center">
              <Label htmlFor="cost" md={4}>
                <TT>Cost</TT>
              </Label>
              <Col md={8}>
                <Control.text
                  type="text"
                  model=".ospf.cost"
                  id="cost"
                  name="cost"
                  placeholder={toTTString("Automatically")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    isInteger: val => isInteger(val, true),
                    minValue: val => val === null || val === '' || val === undefined || minValue(1)(val),
                    maxValue: val => val === null || val === '' || val === undefined || maxValue(65535)(val)
                  }}
                />
                 <Errors
                  className="text-danger"
                  model=".ospf.cost"
                  show="touched"
                  messages={{
                    isInteger: toTTString("Invalid Number"),
                    minValue: toTTString("Cost Must be greater than or equal to 1"),
                    maxValue: toTTString("Cost Must be less than 65535")
                  }}
                />
              </Col>
            </Row>


            <Row className="form-group">
              <Col xs={7} md={{ size: 3, offset: 4 }}>
                <Control.button
                  model="updateInterface"
                  disabled={{ valid: false }}
                  className="btn btn-success btn-ok"
                >
                  <TT>OK</TT>
                </Control.button>
              </Col>
              <Col className="text-right">
                <button
                  onClick={this.handleClose}
                  type="button"
                  className="btn btn-outline-secondary pull-right"
                >
                  <TT>Cancel</TT>
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      </React.Fragment>
    );
  }
}

export default OSPFLinkModal;
