import { v4, v6 } from 'ip-regex';
import { overlap } from 'cidr-tools'
import email from 'isemail';
import validator from 'validator';
import { getStartEndIPv4 } from './ipCalculator';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const IPCidr = require('ip-cidr')

const validateDescription = (desc) => { return desc === '' || /^[a-z0-9-_ .!#%():@[\]]{1,50}$/i.test(desc || ''); };
const validateAppIdentProp = (name) => isEmpty(name) || /^[a-z0-9-_ .!#%():@[\]]/i.test(name || '');

export const isEmpty = (val) => { return val === null || val === undefined || val === ''; };
export const required = (val) => {
  if (typeof val === 'number') return true; // number has no "val.length" (below) so without this line the function will return false for any number.
  else return val && val.length
}
export const maxLength = (len) => (val) => !(val) || (val.length <= len);
export const minLength = (len) => (val) => val && (val.length >= len);
export const length = (len) => (val) => val && (val.length === len);
export const minValue = (minVal) => (val) => Number(val) >= minVal;
export const maxValue = (maxVal) => (val) => Number(val) <= maxVal;
export const isNumber = (val) => !isNaN(Number(val));
export const isOverlap = (networkA, networkB) => {
  try {
    return overlap(networkA, networkB)
  } catch (e) {
    return false;
  }
}
export const isLocalOrBroadcastAddress = (ip, mask) => {
  const [start, end] = getStartEndIPv4(ip, mask)
  return ip === start || ip === end;
}

export const isInteger = (val, allowEmpty=false) => {
  const isEmptyVal = val === '' || val === undefined || val === null;
  // allow empty string
  if (!allowEmpty && isEmptyVal) return false
  else if (allowEmpty && isEmptyVal) return true

  // prevent NaN (Not a Number)
  const num = parseInt(val);
  if (isNaN(num)) return false;

  // prevent leading zero e.g. 01234
  if (val.length > 1 && val[0] === '0') {
    return false
  }

  // prevent negative or dots in long string e.g. 1.2
  return num >= 0 && new RegExp(/^[0-9]*$/).test(val);
}
export const isColorValid = (color) => { return /^#[0-9A-F]{6}$/i.test(color); }
export const validateDeviceName = (name) => { return name === '' || /^[a-z0-9-_ .!#%():@[\]]{1,50}$/i.test(name || ''); };
export const validateDevDescription = (desc) => { return validateDescription(desc); };
export const validateTokenName = (name) => { return /^[a-z0-9-_ .!#%():@[\]]{3,15}$/i.test(name || ''); };
export const validateLabelName = (name) => { return /^[a-z0-9-_ .!#%():@[\]]/i.test(name || ''); };
export const validateLabelDescription = (desc) => { return validateDescription(desc); };
export const validateOrgGroupName = (name) => { return /^[a-z0-9-_ .!#%():@[\]]{2,30}$/i.test(name || ''); };
export const validateCompanyName = (name) => { return /^[a-z0-9-_ ]{2,30}$/i.test(name || ''); };
export const validateName = (name) => { return /^[a-z0-9-. ]{2,15}$/i.test(name || ''); };
export const validateJobTitle = (title) => { return /^[a-z0-9 -]{2,30}$/i.test(title || ''); };
export const validatePolicyName = (name) => { return /^[a-z0-9-_ .:]/i.test(name || ''); };
export const validatePolicyDesc = (desc) => { return validateDescription(desc); }
export const validateRuleName = (name) => { return /^[a-z0-9-_ .:]/i.test(name || ''); }
export const validateStringNoNumberNoSpaces = (str) => { return str === '' || /^[A-Za-z]+$/i.test(str); }
export const validateImportance = (val) => isNumber(val) && val>=1 && val <= 3;
export const validateAppIdentName = (name) => { return /^[a-z0-9-_:]{2,30}$/i.test(name || ''); };
export const validateStringNoSpaces = (str) => { return str === '' || /^\S+$/i.test(str || ''); };
export const validateHostName = (name) => { return /^[a-z0-9-_.]{1,253}$/i.test(name || ''); };
export const validateAppIdentCategory = validateAppIdentProp;
export const validateAppIdentServiceClass = validateAppIdentProp;
export const validateOrgDescription = (desc) => { return validateDescription(desc); };
export const validateTunnelRangeIP = (ip) => {
  if (!ip || ip === "") return true;
  try {
    const ipCidr = new IPCidr(ip + '/16');
    return (ipCidr.start() === ip);
  } catch (err) {
    return false;
  }
};
export const isIP4WithMask = (ip) => {
  if (!ip || ip === "") return true;
  const [addr, mask] = ip.split("/");
  return isIP4(addr) && isIP4Mask(mask);
}
export const isIP4 = (val) => v4({exact: true}).test(val);
export const isIP6 = (val) => v6({exact: true}).test(val);
export const isMAC = (val) => { return /^([0-9a-f]{2}:){5}[0-9a-f]{2}$/i.test(val); };
export const isIPList = (val) => {
  return !isEmpty(val) && val.split(/\s*,\s*/).every((ip) => {
    return (v4({exact: true}).test(ip) || v6({exact: true}).test(ip))
  });
}
export const isDomainList = (val, requireTld = true) => {
  return !isEmpty(val) && val.split(/\s*,\s*/).every(domain => {
    return validator.isFQDN(domain, { require_tld: requireTld})
  })
}
export const isDomain = (domain, requireTld = true) => {
  return validator.isFQDN(domain, { require_tld: requireTld})
}
export const isValidPrefixLength = (val) => {
  // if empty ip/mask is not allowed, 'required' can be added on the form
  if (isEmpty(val)) return true;
  const [ip, mask] = val.split('/');
  return getStartEndIPv4(ip,mask,0)[0] === ip;
}
export const isIP4Mask = (val) => { return !isEmpty(val) && val.length < 3 && !isNaN(Number(val)) && (val >=0 && val <= 32); };
export const isIP6Mask = (val) => { return !isEmpty(val) && val.length < 4 && !isNaN(Number(val)) && (val >=0 && val <= 128); };
export const isPort = (val) => isInteger(val) && val >= 0 && val <= 65535;

export const isPortValid = (val) => {
  // Port can be empty, a single port
  // or a range separated by a '-'
  if (!val) return true;
  if (!val.includes("-")) return isPort(val);
  const ports = val.split('-');
  if (ports.length > 2) return false;
  return isPort(ports[0]) && isPort(ports[1]) && (+ports[0] < +ports[1]);
}

export const isEmail = (mail) => { return !isEmpty(mail) && email.validate(mail); };

export const isPhoneNumber = (number) => {try {
  return phoneUtil.isValidNumber(phoneUtil.parse('+'+number));
} catch (err) {

}};

export const DHCPFieldValidator = (condition, row, dhcpEntries) => {
  const hasDHCPServer = dhcpEntries?.some(d => d.interface === row.devId);
  if ( condition && hasDHCPServer ) {
    return {
      valid: false,
      message: 'DHCP server is defined on the interface'
    };
  }
  return true;
};

export const IPv4FieldValidator = (newValue, row, column) => {
  if (newValue) {
    let ip, mask;
    [ip, mask] = newValue.split('/');
    if (!isIP4(ip) || !isIP4Mask(mask)) {
      return {
        valid: false,
        message: 'Must be a valid IPv4/mask address'
      };
    }
  }
  return true;
};

export const IPv6FieldValidator = (newValue, row, column) => {
  if(newValue !== '') {
    let ip, mask;
    [ip, mask] = newValue.split('/');
    if (!isIP6(ip) || !isIP6Mask(mask)) {
      return {
        valid: false,
        message: 'Must be a valid IPv6/mask address'
      };
    }
  }
  return true;
};

export const PublicIPValidator = (newValue, row, column) => {
  if(newValue !== '') {
    if (!isIP4(newValue) && !isIP6(newValue)) {
      return {
        valid: false,
        message: 'Must be a valid IPv4/IPv6 address'
      };
    }
  }
  return true;
};

export const metricValidator = (val) => {
  if(val !== '') {
    if (!isInteger(val)) {
      return {
        valid: false,
        message: 'Metric should be a number'
      };
    }
  }
  return true;
};

export const isMtu = val => {
  return isInteger(val) && val > 500 && val < 9999
}

export const mtuValidator = (val) => {
  if (!isMtu(val)) {
    return {
      valid: false,
      message: 'MTU should be a number between 500 and 9999'
    };
  }
  return true;
};

export const vlanTagValidator = (val) => {
  if (!isInteger(val) || val < 1 || val > 4094) {
    return {
      valid: false,
      message: 'VLAN tag should be a number between 1 and 4094'
    };
  }
  return true;
};

export const validateNotificationsForm = (form) => {
  let errors = [];

  for (const [eventType, eventSettings] of Object.entries(form)) {
    const warningVal = Number(eventSettings.warningThreshold);
    const criticalVal = Number(eventSettings.criticalThreshold);
    if (warningVal >= criticalVal) {
      errors.push({
        eventType: eventType,
        message: "The critical threshold must be greater than the warning",
      });
    }
  }

  return {
    valid: errors.length === 0,
    errors: errors,
  };
}

export const validateNotificationField = (value, unit) => {
  const numValue = Number(value);

  if (numValue < 1) {
    return {
      valid: false,
      message: "Please enter a value greater than 1",
    };
  }

  const unitLimits = {
    "ms": 2000,
    "%": 100,
  };

  if (unitLimits[unit] && numValue > unitLimits[unit]) {
    return {
      valid: false,
      message: `Please enter a value less than or equal to ${unitLimits[unit]}`,
    };
  }

  return {
    valid: true,
    message: "",
  };
};

export const isValidURL = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};
