import React, { useState, createContext, useContext } from 'react';
import { languageOptions, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: null,
  replaceableTree: null  // Handle special replaceable phrases
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const defaultLanguage = window.localStorage.getItem('rcml-lang');
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en');

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    replaceableTree: buildReplaceableTree(dictionaryList[userLanguage]),
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : 'en'
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    },
    toTTString: (prop) => {
      return execTranslation(prop, provider.dictionary, provider.replaceableTree, userLanguage)
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// get text according to tid & current language
export function TT(props) {
  const languageContext = useContext(LanguageContext);
  const chosenLanguage = languageContext.userLanguage;
  const dictionary = languageContext.dictionary;
  const replaceableTree = languageContext.replaceableTree;
  let convertedSentence = execTranslation(props, dictionary, replaceableTree, chosenLanguage)

  return convertedSentence
};

function isReplaceableSentence(sentence) {
  return /^.*#.*#.*$/.test(sentence);
}
function isReplaceableWord(word) {
  return /^#.*#$/.test(word);
}

function buildReplaceableTree(dictionary) {
  if (!dictionary) return null;
  const rt = new ReplaceableTree();

  for (let sentence in dictionary) {
    // if replaceable sentence, add to dictionary
    if (isReplaceableSentence(sentence)) {
      rt.insertPhrase(sentence);
    }
  }
  return rt;
}

function applyVars(text, vars) {
  if (!vars) {
    return text
  }
  for (const [key, value] of Object.entries(vars)) {
    const fixedKey = (key[0] === '#') ? key : ('#' + key + '#')
    text = text.replaceAll(fixedKey, value)
  }
  return text
}

function translate(sentence, dictionary) {
    if (dictionary[sentence]) {
      return dictionary[sentence]
    }
    else if(dictionary[sentence.charAt(0).toUpperCase() + sentence.slice(1)]){
      return dictionary[sentence.charAt(0).toUpperCase() + sentence.slice(1)]
    }
  return sentence;
}

export function execTranslation(props, dictionary, replaceableTree, chosenLanguage) {
  // children is when calling it with TT tag. When calling directly, props will be the text itself
  let defaultText = (props.children || props.children === '') ? props.children : props
  defaultText = Array.isArray(defaultText) ? defaultText.join('') : defaultText;
  if (defaultText === '') return '';

  const splitSentence = props.splitSentence;
  const tid = props.tid;
  const params = props.params;
  const convertValues = props.convertValues;

  if (chosenLanguage === "en") {
    return applyVars(defaultText, params);
  }

  if (tid) {
    if (dictionary[tid]) {
      return applyVars(dictionary[tid], params);
    }
    return applyVars(defaultText, params);
  }

  let sentences = splitSentence ? defaultText.split(/\.\s*/) : [defaultText];

  const resSentences = sentences.map((sentence) => {
    // Try first to apply fixed translation
    const fSentence = translate(sentence, dictionary);
    // If successful, return result
    if (fSentence !== sentence) {
      return (params)? applyVars(fSentence, params) : fSentence;
    }
    // Otherwise try to convert values
    if (convertValues) {
      const rSentence = replaceableTree.findPhrase(sentence);
      if (rSentence.sentence !== '') {
        // Translate var sentence
        const tSentence = translate(rSentence.sentence, dictionary);
        return applyVars(tSentence, rSentence.keys);
      }
    }
    // No translation found, return original sentence
    return sentence;
  });

  return resSentences.join(". ");
}
class TreeNode {
  constructor() {
    this.children = {};
    this.endOfSentence = '';
  }
}

/*
 * Replaceable Tree is used to search for sentences with variables
 * For example: 6 jobs created successfully or device NAME updated successfully
 * It will find the generic sentence in the dictionary that fit the sentence
 * For example: #num# jobs created successfully or device #name# updated successfully
 * It does that by adding the generic sentences into the tree, whenever there's a
 * variable, it adds a special node (##) that means any word can match it
 * When searching, it tries to find an exact word or skip word.
 * It keeps tracking both options in DFS kind search so if one is not found,
 * other sentences could be found.
 * If there are multiple sentences that matched, the first one is found
 * Example:
 * rt.insertPhrase("#name# Device registered");
 * rt.insertPhrase("Device #name# registered");
 * rt.insertPhrase("My name is #name#");
 * rt.insertPhrase("#num# of #of# succeeded")
 * console.log(rt.findPhrase("NY Device registered")); // keys:{#name#: 'NY'}, sentence:'#name# Device registered'
 * console.log(rt.findPhrase("Device NY registered")); // keys:{#name#: 'NY'}, sentence:'Device #name# registered'
 * console.log(rt.findPhrase("Device Device registered"));
 *            // (2 options are possible) keys:{#name#: 'Device'}, sentence:'#name# Device registered'
 * console.log(rt.findPhrase("My name is Nir")); // keys:{#name#: 'Nir'}, sentence:'My name is #name#'
 * console.log(rt.findPhrase("My name is Nir Ben Dvora")); // keys:{}, sentence:''
 * console.log(rt.findPhrase("6 jobs created yesterday")); // keys:{}, sentence:''
 * console.log(rt.findPhrase("7 of 14 succeeded"));
 *            // keys:{#num#: '7', #of#: '14'}, sentence:'#num# of #of# succeeded'
 */
class ReplaceableTree {
  constructor() {
    this.root = new TreeNode();
  }

  // insert a generic phrase into the tree, for example:
  // phrase = "My name is #name#"
  insertPhrase(phrase) {
    let cNode = this.root;
    const words = phrase.split(' ');
    for (let w of words) {
      let insWord = w;
      if (isReplaceableWord(w)) {
        // Add a special node
        insWord = '##';
      }
      if (!cNode.children.hasOwnProperty(insWord)) {
        cNode.children[insWord] = new TreeNode();
      }
      cNode = cNode.children[insWord];
    }
    cNode.endOfSentence = phrase;
  }

  // find generic phrase from the tree based on actual sentence, for example:
  // sentence = "My name is Nir"
  // phrase found = "My name is #name#"
  findPhrase(sentence) {
    let found = '';
    let foundValues = [];
    const words = sentence.split(' ');
    const stack = []; // hold trail of nodes to visit {node, word#, value}
    stack.push({node:this.root, word:0, values:[]});
    while (stack.length > 0) {
      const {node, word, values} = stack.pop();
      if (word === words.length && node.endOfSentence !== '') {
        // Found sentence match, break
        found = node.endOfSentence;
        foundValues = values;
        break;
      }
      const w = words[word];
      if (node.children.hasOwnProperty(w)) {
        const valuesCopy = [...values];
        stack.push({node: node.children[w], word: word+1, values:valuesCopy});
      }
      if (node.children.hasOwnProperty('##')) {
        const valuesCopy = [...values];
        valuesCopy.push(w);
        stack.push({node: node.children['##'], word: word+1, values:valuesCopy});
      }
    }
    if (found === '') return {sentence:'', keys:{}};
    const symbols = found.match(/(#.*?#)/g);
    if (!symbols || symbols.length !== foundValues.length) return {sentence:'', keys:{}};
    const keys = foundValues.reduce((r,v,idx) => {
      r[symbols[idx]]=v;
      return r;
    },{});
    return (found !=='')?{sentence:found, keys:keys}:{sentence:'', keys:{}};
  }
}