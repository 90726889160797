import React, {useContext} from "react";
import {
  Card,
  CardBody,
  Row,
  Label,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Control, Form, Errors } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { PublicIPValidator } from '../../utils/Validators';
import InternetAccessBadge from '../items/InternetAccessBadge';
import { TT, LanguageContext } from '../../containers/Language'

export default function GatewayModal ({isOpen, toggle, onSubmit, deviceStatus, row}) {
  const { toTTString } = useContext(LanguageContext);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <TT>Gateway and Internet Info</TT>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form
              model="updateInterface"
              onSubmit={onSubmit}
            >
              <Row className="form-group">
                <Label htmlFor="gateway" md={4}>
                  <TT>Gateway</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".gateway"
                    id="gateway"
                    name="gateway"
                    placeholder={toTTString("Gateway IP Address")}
                    disabled={row && (row.dhcp === 'yes' || row.isAssigned !== 'Yes')}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      PublicIP: PublicIPValidator
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".gateway"
                    show="touched"
                    messages={{
                      required: toTTString("Required field"),
                      gateway: toTTString("Invalid Gateway IP Address")
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group align-items-center">
                <Label htmlFor="monitorInternet" md={4}>
                  <TT>Internet Access</TT>
                </Label>
                <Col md={8}>
                  <InternetAccessBadge
                    deviceStatus={deviceStatus}
                    row={row}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="monitorInternet" md={4}>
                  <TT>Monitor Internet</TT>
                </Label>
                <Col md={8}>
                  <Label className="FWswitch">
                    <Control.checkbox
                      model=".monitorInternet"
                      id="monitorInternet"
                      name="monitorInternet"
                    />{" "}
                    <span className="FWslider round" />
                  </Label>
                </Col>
              </Row>
              <Row className="form-group">
                <Col xs={7} md={{ size: 3, offset: 4 }}>
                  <Control.button
                    model="updateInterface"
                    disabled={{ valid: false }}
                    className="btn btn-success btn-ok"
                  >
                    <TT>OK</TT>
                  </Control.button>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={toggle}
                    type="button"
                    className="btn btn-outline-secondary pull-right"
                  >
                    <TT>Cancel</TT>
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
    )
}