import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Label, Card, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { required, isInteger, minValue, maxValue, isIP4, validateStringNoSpaces } from '../../utils/Validators';
import { FWTextInput } from '../Common';
import BootstrapTable from 'react-bootstrap-table-next';
import isEqual from 'lodash/isEqual';
import _uniqueId from 'lodash/uniqueId';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext, TT } from '../../containers/Language';
import './DeviceInfo.css';
import { ToastContainer, toast } from 'react-toastify';
import { actions } from 'react-redux-form';
import RoutingCommandsCli from './RoutingCommandsCli';
import { getStorageToken } from '../../utils/Token';

const jwt = require("jsonwebtoken");

const initialPagination = 5;

const formName = "BGPConfiguration";

class BGPCard extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props)

    this.state = {
      isNeighborModalOpen: false,
      selectedNeighbor: {},
      disableFilters: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  toggleNeighborModal = () => {
    // If we about to close the modal - reset the form
    if (this.state.isNeighborModalOpen) {
      this.props.resetNeighborForm()
      this.setState({
        selectedNeighbor: {}
      });
    }

    this.setState({
      isNeighborModalOpen: !this.state.isNeighborModalOpen
    });
  }

  handleResetChanges = () => {
    this.props.changeForm(this.props.bgp, {silent: true})
  }

  handleSubmit = values => {
    const newObj = { ...values };
    this.props.saveBGP(newObj)
    toast("Saved!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  componentDidMount() {
    if (Object.keys(this.props.bgp).length) {
      this.props.changeForm(this.props.bgp, {silent: true})
    }
    const token = getStorageToken();
    const decodedToken = jwt.decode(token);
    this.props.getOrganization(decodedToken.org, org => {
      this.setState({ selectedOrg: org})
    })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.bgp, this.props.bgp)) {
      this.props.changeForm(this.props.bgp, {silent: true})
    }
  }

  handleAddNeighbor = neighbor => {
    const updated = { ...neighbor};
    // clear filter if they are not exists
    if (neighbor.outboundFilter !== '' && this.props.routingFilters.length === 0){
      updated.outboundFilter = '';
    }
    if (neighbor.inboundFilter !== '' && this.props.routingFilters.length === 0){
      updated.inboundFilter = '';
    }

    // handle add or update
    const _id = neighbor._id || _uniqueId('tempId-');
    const neighbors = [...this.props.form.neighbors];

    const idx = neighbors.findIndex(n => n._id === _id)
    if (idx > -1) {
      neighbors[idx] = updated;
    } else {
      neighbors.push({...updated, _id })
    }

    this.props.updateNeighbors(neighbors)

    this.toggleNeighborModal()
  }

  handleDeleteNeighbor = row => {
    const neighbors = [...this.props.form.neighbors];
    const index = neighbors.findIndex(n => {
      return n._id === row._id;
    });

    if (index > -1) {
      neighbors.splice(index, 1);
      this.props.updateNeighbors(neighbors)
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  isTunnelNeighbor = ip => {
    var start = this.state.selectedOrg?.tunnelRange.split('.')
    start = (start? [start[0], start[1]].join('.') : '10.100') + '.'
    return ip?.startsWith(start)
  }

  paginationOptions = () => {
    const toTTString = this.context.toTTString
    const self = this;
    return {
      paginationSize: initialPagination,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      totalSize:  self.props.info ? self.props.info.accounts.all : 0,
      paginationTotalRenderer: (from, to, size) => {
        return (
          <span className="react-bootstrap-table-pagination-total">
            <TT params={{from: from, to: to, size: self.props.info ? self.props.info.accounts.all : size}}>Showing #from# to #to# of #size# Results</TT>
          </span>
        )
      },
      sizePerPageList: [{text: initialPagination.toString(), value: initialPagination}, {text: '50', value: 50}, {text: '100', value: 100}]
    }
  };

  render() {
    const toTTString = this.context.toTTString;

    return (
      <React.Fragment>
        <h6><TT>BGP Configuration</TT></h6>
        <div className={"general-card-upper-panel d-flex justify-content-between"}>
          <Control.button
            form="BGPConfigurationForm"
            type="submit"
            model={formName}
            className={"btn btn-primary action-btn-top upper-panel-button"}
          >
            <TT>Save</TT>
          </Control.button>

          { isEqual(this.props.bgp, this.props.form) ? null : (
            <button
              onClick={this.handleResetChanges}
              type="button"
              className="btn btn-outline-secondary action-btn-top upper-panel-button"
            >
              <TT>Reset Changes</TT>
            </button>
          )}
        </div>

        <Card id='BGPCard'>
          <CardBody>
            <Form
              id="BGPConfigurationForm"
              model="BGPConfiguration"
              onSubmit={this.handleSubmit}
              validators={{
                '': {
                  asnRequired: values => {
                    if (values.enable && values.localASN === '') {
                      return false
                    }
                    return true;
                  }
                }
              }}
            >
              <Row>
                <Col md={4}>
                  <Row className="form-group">
                    <Label htmlFor="enable" md={3}>
                      <TT>Enable</TT>
                    </Label>
                    <Col md={6}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".enable"
                          id="enable"
                          name="enable"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="localASN" md={3}>
                      <TT>Local ASN</TT>
                    </Label>
                    <Col md={6}>
                      <Control.text
                        model=".localASN"
                        id="localASN"
                        name="localASN"
                        placeholder=""
                        className="form-control"
                        validators={{
                          minValue: val => val === "" || minValue(1)(val)
                        }}
                      >
                      </Control.text>
                      <Errors
                        className="text-danger"
                        model=".localASN"
                        show="touched"
                        messages={{
                          minValue: toTTString('ASN Must be greater than or equal to 1')
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model="BGPConfiguration"
                        show="touched"
                        messages={{
                          asnRequired: toTTString('Required field'),
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="routerId" md={3}>
                      <TT>Router ID</TT>
                    </Label>
                    <Col md={6}>
                      <Control.text
                        model=".routerId"
                        id="routerId"
                        name="routerId"
                        placeholder=""
                        className="form-control"
                        validators={{
                          isIP4: val => val === "" || isIP4(val)
                        }}
                      >
                      </Control.text>
                      <Errors
                        className="text-danger"
                        model=".routerId"
                        show="touched"
                        messages={{
                          isIP4: toTTString('Router Id should be a valid IPv4')
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={4}>
                  <Row className="form-group">
                    <Label htmlFor="enable" md={4}>
                      <TT>Redistribute OSPF Routes</TT>
                    </Label>
                    <Col md={6}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".redistributeOspf"
                          id="redistributeOspf"
                          name="redistributeOspf"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="keepaliveInterval" md={4}>
                      <TT>Keepalive Interval</TT> (<TT>seconds</TT>)
                    </Label>
                    <Col md={6}>
                      <Control.text
                        type="text"
                        model=".keepaliveInterval"
                        id="keepaliveInterval"
                        name="keepaliveInterval"
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          isInteger: val => isInteger(val, false),
                          minValue: minValue(0),
                          maxValue: maxValue(65535)
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".keepaliveInterval"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          isInteger: toTTString('Invalid number'),
                          minValue: toTTString("Interval must be greater than or equal to 0"),
                          maxValue: toTTString('Interval must be less than or equal to 65535'),
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="holdInterval" md={4}>
                      <TT>Hold Interval</TT> (<TT>seconds</TT>)
                    </Label>
                    <Col md={6}>
                      <Control.text
                        type="text"
                        model=".holdInterval"
                        id="holdInterval"
                        name="holdInterval"
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          isInteger: val => isInteger(val, false),
                          minValue: minValue(0),
                          maxValue: maxValue(65535)
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".holdInterval"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          isInteger: toTTString('Invalid number'),
                          minValue: toTTString("Interval must be greater than or equal to 0"),
                          maxValue: toTTString('Interval must be less than or equal to 65535'),
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={4}>
                  <RoutingCommandsCli
                    form="BGPConfiguration"
                    model="custom"
                    buttonRight={true}
                    executedText={'under "router bgp"'}
                    advancedText="BGP"
                  />
                </Col>
              </Row>

              <hr />

              <Row className="mb-2">
                <Col className="col-auto">
                  <TT>Neighbors</TT>
                </Col>
                <Col className="pl-0">
                  <Button md={9}
                    color="warning"
                    className="btn btn-primary btn btn-secondary ml-2"
                    data-tip
                    size="sm"
                    onClick={this.toggleNeighborModal}
                  >
                    <TT>Add</TT>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapTable
                    keyField="ip"
                    data={this.props.form?.neighbors ?? []}
                    columns={[
                      {
                        text: toTTString("Remote IP"),
                        dataField: "ip",
                      },
                      {
                        text: toTTString("Remote ASN"),
                        dataField: "remoteASN",
                      },
                      {
                        text: toTTString("Password"),
                        dataField: "password",
                      },
                      {
                        text: toTTString("Inbound Filter"),
                        dataField: "inboundFilter",
                      },
                      {
                        text: toTTString("Outbound Filter"),
                        dataField: "outboundFilter",
                      },
                      {
                        text: "Send Community",
                        dataField: "sendCommunity",
                      },
                      {
                        text: toTTString("Actions"),
                        dataField: "none",
                        formatter: (cellContent, row) => (
                          <>
                            {/* Update Neighbor */}
                            <Button
                              color="warning"
                              className="action-btn"
                              data-tip
                              data-for={"setup-bgp-neighbor-" + row._id}
                              size="sm"
                              onClick={e => {
                                this.props.changeNeighborForm(row);
                                this.setState({selectedNeighbor: row})
                                this.toggleNeighborModal()
                              }}
                            >
                              <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                              <ReactTooltip id={"setup-bgp-neighbor-"+row._id}>
                                <span><TT>Update Neighbor</TT></span>
                              </ReactTooltip>
                            </Button>

                            {/* Delete Neighbor */}
                            <Button
                              color="danger"
                              className="action-btn"
                              data-tip
                              data-for={"delete-bgp-neighbor-" + row._id}
                              size="sm"
                              onClick={() => this.handleDeleteNeighbor(row)}
                            >
                            <FontAwesomeIcon icon="trash-alt" fixedWidth />
                            </Button>
                            <ReactTooltip id={"delete-bgp-neighbor-" + row._id}>
                              <span><TT>Delete Neighbor</TT></span>
                            </ReactTooltip>
                          </>
                        )
                      },
                    ]}
                    noDataIndication={toTTString("No BGP neighbors configured")}
                    defaultSorted={[{ dataField: "name", order: "asc" }]}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>


        <Row>
          <Label md={12}>
            <TT>Networks</TT>
          </Label>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              keyField="IPv4"
              data={this.props.interfaces.filter(i => i.isAssigned === 'Yes' && (i.routing === 'BGP' || i.routing === 'OSPF,BGP'))}
              columns={[
                {
                  text: toTTString("Network"),
                  dataField: "IPv4",
                  editable: false,
                },
              ]}
              noDataIndication={toTTString("No BGP networks configured")}
              defaultSorted={[{ dataField: "name", order: "asc" }]}
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isNeighborModalOpen}
          toggle={this.toggleNeighborModal}
        >
          <ModalHeader toggle={this.toggleNeighborModal}>
            {this.state.selectedNeighbor._id ? toTTString("Update") : toTTString("Add")} <TT>Neighbor</TT>
          </ModalHeader>

          <ModalBody>
            <Form
              model="BGPNeighbor"
              onSubmit={this.handleAddNeighbor}
              onUpdate={values => {
                if (this.isTunnelNeighbor(values?.ip?.value)) {
                  this.setState( { disableFilters: true })
                } else {
                  this.setState( { disableFilters: false })
                }
              }}
              validators={{
                '': {
                  duplicate: values => {
                    if (values._id) {
                      if (this.props.form.neighbors.find(n => n._id !== values._id && n.ip === values.ip)) {
                        return false
                      }
                    } else {
                      if (this.props.form.neighbors.find(n => n.ip === values.ip)) {
                        return false
                      }
                    }
                    return true;
                  }
                }
              }}
            >
              <Row className="form-group">
                <Label htmlFor="ip" md={4}>
                  <TT>Remote IP</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="ip"
                    model=".ip"
                    id="ip"
                    name=".ip"
                    placeholder={toTTString("Neighbor IP")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      required: required,
                      isIP4: isIP4
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".ip"
                    show="touched"
                    messages={{
                      required: toTTString('Required field'),
                      isIP4: toTTString('Invalid IP. IP without mask expected'),
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model="BGPNeighbor"
                    show="touched"
                    messages={{
                      duplicate: toTTString('Duplicate neighbor IP')
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group align-items-center">
                <Label htmlFor="remoteASN" md={4}>
                  <TT>Remote ASN</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".remoteASN"
                    id="remoteASN"
                    name="remoteASN"
                    placeholder={toTTString("Remote ASN")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      required: required,
                      minValue: minValue(1)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".remoteASN"
                    show="touched"
                    messages={{
                      required: toTTString('Required field'),
                      minValue: toTTString('ASN Must be greater than or equal to 1')
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group align-items-center">
                <Label htmlFor="password" md={4}>
                  <TT>MD5 key</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".password"
                    id="password"
                    placeholder={toTTString("Password")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      validateStringNoSpaces: validateStringNoSpaces
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".password"
                    show="touched"
                    messages={{
                      validateStringNoSpaces: toTTString('Spaces are not allowed'),
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="inboundFilter" md={4}>
                  <TT>Inbound Filter</TT>
                </Label>
                <Col md={8}>
                  <Control.select
                    model=".inboundFilter"
                    id="inboundFilter"
                    name="inboundFilter"
                    placeholder=""
                    disabled={this.state.disableFilters}
                    className="form-control"
                  >
                    <option key={''} value={""}>{toTTString("Select Routing Filter")}</option>
                    { this.props.routingFilters.map((list, idx) => {
                      return (
                        <option key={idx} value={list.name}>{list.name}</option>
                      )
                    })}
                  </Control.select>
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="outboundFilter" md={4}>
                  <TT>Outbound Filter</TT>
                </Label>
                <Col md={8}>
                  <Control.select
                    model=".outboundFilter"
                    id="outboundFilter"
                    name="outboundFilter"
                    disabled={this.state.disableFilters}
                    placeholder=""
                    className="form-control"
                  >
                    <option key={this.props.routingFilters.length + 1} value={""}>{toTTString("Select Routing Filter")}</option>
                    { this.props.routingFilters.map((list, idx) => {
                      return (
                        <option key={idx} value={list.name}>{list.name}</option>
                      )
                    })}
                  </Control.select>
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="sendCommunity" md={4}>
                  <TT>Send Community</TT>
                </Label>
                <Col md={8}>
                  <Control.select
                    model=".sendCommunity"
                    id="sendCommunity"
                    name="sendCommunity"
                    placeholder=""
                    className="form-control"
                  >
                    <option key={1} value={''}>N/A</option>
                    <option key={2} value='all'>all</option>
                    <option key={3} value='both'>both</option>
                    <option key={4} value='extended'>extended</option>
                    <option key={5} value='large'>large</option>
                    <option key={6} value='standard'>standard</option>
                  </Control.select>
                </Col>
              </Row>

              <Row className="form-group align-items-center">
                <Label htmlFor="multiHop" md={4}>
                  <TT>Multi Hop</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".multiHop"
                    id="multiHop"
                    name="multiHop"
                    placeholder={toTTString("Multi Hop")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      required: required,
                      minValue: minValue(1),
                      maxValue: maxValue(255)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".multiHop"
                    show="touched"
                    messages={{
                      required: toTTString('Required field'),
                      minValue: toTTString('Multi Hop must be greater than or equal to 1'),
                      maxValue: toTTString('Multi Hop must be less than or equal to 255')
                    }}
                  />
                </Col>
              </Row>

              <RoutingCommandsCli
                form="BGPNeighbor"
                model="custom"
                executedText={`after "neighbor ${this.props.BGPNeighborForm.ip} remote-as ${this.props.BGPNeighborForm.remoteASN}" command`}
                advancedText="BGP Neighbor"
              />

              <Row className="form-group">
                <Col xs={7} md={{ size: 3, offset: 4 }}>
                  <Control.button
                    model="BGPNeighbor"
                    className="btn btn-success btn-ok"
                  >
                    <TT>OK</TT>
                  </Control.button>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={this.toggleNeighborModal}
                    type="button"
                    className="btn btn-outline-dark pull-right"
                  >
                    <TT>Cancel</TT>
                  </button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.BGPConfiguration,
    BGPNeighborForm: state.BGPNeighbor
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateNeighbors: neighbors => dispatch(actions.change(formName + '.neighbors', neighbors))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BGPCard);
