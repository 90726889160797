import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import tinycolor from 'tinycolor2';
import {Link} from 'react-router-dom';
import * as d3 from "d3";
import cloudImg from '../../assets/images/cloud.png';
import routerImg from '../../assets/images/router2.png';
import { TT, LanguageContext } from '../../containers/Language'

import './Network.css';

class Network extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.resizeTimer = null;

    this.state = {
      width: 0,
      height: 0,
      devicesUpdated:false,
      tunnelsUpdated:false,
      tunnel_status: {
        drop_rate: 0,
        state: "Down",
        rtt: 0
      }
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.generate_network_graph = this.generate_network_graph.bind(this);
    this.networkGetSuccess = this.networkGetSuccess.bind(this);
  }

  updateDimensions() {
    clearTimeout(this.resizeTimer);
    // Call in delay to make sure the resize ended
    this.resizeTimer = setTimeout(
      function(that) {
        // The following code will be called after resize ends
        d3.select("#network-chart").html("");
        that.setState({
          width: document.getElementsByClassName("mainContent")[0].clientWidth - 50,
          height: document.getElementsByClassName("mainContent")[0].offsetHeight - 100
        });
        const data = [{ name: "r1" }, { name: "r2" }, { name: "r3" }];
        that.generate_network_graph(data);
      },
      250,
      this
    );
  }

  componentDidMount() {
    this.props.getAllDevices({ response: 'summary' }, this.networkGetSuccess('devicesUpdated'));
    this.props.getAllTunnels({}, this.networkGetSuccess('tunnelsUpdated'));
    window.addEventListener("resize", this.updateDimensions);
    // Define the device info block
    d3.select("body").append("div")
      .attr("class", "device-info")
      .style("opacity", 0);
    // Define the div for the tooltip
    d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
  }

  networkGetSuccess(element) {
    const that = this;
    return function() {
      const state = {};
      state[element] = true;
      that.setState(state);
      if (that.state.devicesUpdated && that.state.tunnelsUpdated) {
        that.updateDimensions();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearDevices();
    this.props.clearTunnels();
    window.removeEventListener("resize", this.updateDimensions);
    d3.selectAll('.device-info').remove();
    d3.selectAll('.tooltip').remove();
  }

  network_graph_vis(nodes, links) {

    const width = document.getElementsByClassName("mainContent")[0].clientWidth -50;
    const height = document.getElementsByClassName("mainContent")[0].offsetHeight -100;

    // Select the device info and tooltip blocks
    const deviceInfoBlock = d3.select(".device-info");
    const tooltipBlock = d3.select(".tooltip");

    let showDeviceInfo = false;
    let deviceInfoTimer;

    const hideDeviceInfoBlock = () => {
      deviceInfoBlock.transition().duration(500).style('opacity', 0);
    }

    const displayDeviceInfoBlock = (interfaces) => {
      deviceInfoBlock.transition().duration(200).style('opacity', .8).style('display', 'block');
      deviceInfoBlock.html(`
        WAN: ${interfaces.WAN.join('<br/>')}<br/>
        LAN: ${interfaces.LAN.join('<br/>')}<br/>
      `)
        .style('left', (d3.event.pageX) + 'px')
        .style('top', (d3.event.pageY - 28) + 'px');
    }

    const setDeviceInfoTimer = () => {
      deviceInfoTimer = setTimeout(() => {
        clearTimeout(deviceInfoTimer);
        if (showDeviceInfo) {
          setDeviceInfoTimer();
        } else {
          hideDeviceInfoBlock()
        }
      }, 1000);
    }

    // parent svg
    var svgp = d3
      .select("#network-chart")
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    // child svg with group for zooming
    var svg = svgp.append("g");

    //var color = d3.scaleOrdinal(d3.schemeCategory10);
    var images = [
      { name: cloudImg, width: 100, height: 50 },
      { name: routerImg, width: 50, height: 50 }
    ];

    var simulation = d3
      .forceSimulation()
      .force(
        "link",
        d3
          .forceLink()
          .id(function(d) {
            return d.id;
          })
          .distance(function() {
            return 10;
          })
          .strength(0)
      )
      .force(
        "charge",
        d3.forceManyBody().strength(function() {
          return -200;
        })
      )
      .force("center", d3.forceCenter(width / 2, height / 2));

    // This is a thick transparent line used for the tooltip for easy hoover
    const toTTString = this.context.toTTString;
    var link = svg
      .append("g")
      .attr("fill", "none")
      .attr("stroke", "transparent")
      .attr("class", "links-invisible")
      .selectAll(".links-invisible path")
      .data(links)
      .enter()
      .append("path")
      .on("mouseover", function(d) {
        const drop_rate = d.connected ? Math.max(d.tunnelA.drop_rate, d.tunnelB ? d.tunnelB.drop_rate : 0).toFixed(2) : toTTString("N/A");
        const rtt = d.connected ? Math.max(d.tunnelA.rtt, d.tunnelB ? d.tunnelB.rtt : 0).toFixed(2) : toTTString("N/A");
        let pathLabelName = d.pathLabelName
        const textColor = tinycolor(d.color).isLight() ? "#000000" : "#ffffff";
        if (pathLabelName.length > 7) pathLabelName = pathLabelName.substring(0,7)+'...';

        tooltipBlock.transition()
          .duration(200)
          .style("opacity", .9);
        var html = `
        ${(pathLabelName !== "") ?
         `<span class="badge badge-pill" style="background-color: ${d.color};color: ${textColor};padding: 4px 8px">${pathLabelName}</span><br/>`
         :'' }
        <b> ${toTTString('AVG Latency')}:</b>
        <span style="line-height: 1.1rem">${rtt} ms</span>
        <br/>
        <b>${toTTString('Drop Rate')}:</b>
        <span style="line-height: 1.1rem">${drop_rate} %</span><br/>`
        tooltipBlock.html(html)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        tooltipBlock.transition()
          .duration(500)
          .style("opacity", 0);
      })
      .attr("stroke-width", 10)
      .attr("class", "line-solid");

      // This is the real line presented
      var link2 = svg
      .append("g")
      .attr("fill", "none")
      .attr("class", "links")
      .selectAll(".links path")
      .data(links)
      .enter()
      .append("path")
      .attr("stroke", function(d) {
        return (d.color);
      })
      .attr("stroke-width", function(d) {
        return (d.value);
      })
      .attr("stroke-dasharray", function(d) {
        if (d.connected) {
          return null;
        } else {
          return "3,3";
        }
      });

    var node = svg
      .append("g")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(nodes)
      .enter();

    var nodeimg = node
      //.append("circle")
      //.attr("r", 5)
      //.attr("fill", function(d) { return color(d.type); })
      .append("svg:image")
      .attr("width", function(d) {
        return images[d.type].width;
      })
      .attr("height", function(d) {
        return images[d.type].height;
      })
      .attr("xlink:href", function(d) {
        return images[d.type].name;
      })
      .on('click', ({ type, id}) => {
        if (type === 1) { // type 1 == devices
          this.props.history.push(`/devices/deviceinfo/${id}?tab=Interfaces`);
        }
      })
      .on('mouseover', ({ type, interfaces }) => {
        if (type ===1) { // type 1 == devices
          if (!this.state.showDeviceInfo) {
            showDeviceInfo = true;
            displayDeviceInfoBlock(interfaces);
            // set timeout to prevent blinking
            setDeviceInfoTimer();
          }
        }
      })
      .on('mouseout', () => {
        showDeviceInfo = false;
      })
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    var nodetxt = node
      .append("text")
      .attr("dx", 11)
      .attr("dy", ".15em")
      .attr("text-anchor", "middle")
      .text(function(d) {
        return d.name;
      });

    simulation.nodes(nodes).on("tick", ticked);

    simulation.force("link").links(links);

    // Add zoom capabilities
    var zoom_handler = d3.zoom()
    .on("zoom", zoomAction);
    zoom_handler(svgp);

    function ticked() {
      link
        .attr("d", linkArc);

      link2
        .attr("d", linkArc);

      nodeimg
        .attr("x", function(d) {
          return d.x - images[d.type].width / 2;
        })
        .attr("y", function(d) {
          return d.y - images[d.type].height / 2;
        });

      nodetxt
        .attr("x", function(d) {
          return d.x - images[d.type].width / 2 + 15;
        })
        .attr("y", function(d) {
          return d.y - images[d.type].height / 2 - 5;
        });
    }

    function linkArc(d) {
      const r = d.radius * Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
      if (r>=0) return `M${d.source.x},${d.source.y} A${r},${r} 0 0,1 ${d.target.x},${d.target.y}`;
      else return `M${d.target.x},${d.target.y} A${r},${r} 0 0,1 ${d.source.x},${d.source.y}`;
    }

    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
      hideDeviceInfoBlock();
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    function zoomAction() {
      svg.attr("transform", d3.event.transform)
      hideDeviceInfoBlock();
    }
  }

  generate_network_graph(data) {
    // start with a cloud node #0 and add other nodes after it
    // type=0=peer, type=1=router
    var nodes = [
      /*{"id": "Cloud", "type": 0}*/
    ];
    var links = [];
    this.props.devices.devices.forEach(device => {
      nodes.push({
        id: device._id,
        name: device.name,
        type: 1, // router is type 1
        interfaces: device.interfaces.reduce((res, ifc) => {
          if (ifc.isAssigned && ['WAN', 'LAN'].includes(ifc.type) ) {
            res[ifc.type].push(ifc.IPv4);
          }
          return res;
        }, {
          WAN: [],
          LAN: [],
        })
      });
    });
    const normalizedPairs = {};
    const peersNodes = {};
    this.props.tunnels.tunnels.forEach(tunnel => {
      let idTupple = [];
      let isConnected = false;
      if (tunnel.peer) {
        peersNodes[tunnel.peer._id] = { id: tunnel.peer._id, name: tunnel.peer.name, type: 0  }; // peer is type 0

        idTupple = [tunnel.deviceA._id, tunnel.peer._id].sort();
        isConnected = tunnel.deviceAconf && Object.keys(tunnel.tunnelStatusA).length!==0
          && tunnel.tunnelStatusA.status === "up";
      } else {
        idTupple = [tunnel.deviceA._id, tunnel.deviceB._id].sort();
        isConnected = tunnel.deviceAconf && tunnel.deviceBconf && Object.keys(tunnel.tunnelStatusA).length!==0
          && Object.keys(tunnel.tunnelStatusB).length!==0
          && tunnel.tunnelStatusA.status === "up" && tunnel.tunnelStatusB.status === "up";
      }
      const idKey = idTupple.join();
      if (!normalizedPairs.hasOwnProperty(idKey)) normalizedPairs[idKey] = 0;
      else if (normalizedPairs[idKey]===0) normalizedPairs[idKey] = 1;
      else if (normalizedPairs[idKey]>0) normalizedPairs[idKey] = -normalizedPairs[idKey];
      else normalizedPairs[idKey] = -normalizedPairs[idKey] * 0.7;

      const color = (tunnel.pathlabel && tunnel.pathlabel.color)? tunnel.pathlabel.color : '#aaa';
      const pathLabelName = (tunnel.pathlabel)? tunnel.pathlabel.name : "";

      links.push({
        source: idTupple[0],
        target: idTupple[1],
        value: 1,
        radius: normalizedPairs[idKey],
        connected: isConnected,
        tunnelA: tunnel.tunnelStatusA,
        tunnelB: tunnel.peer ? null : tunnel.tunnelStatusB,
        color: color,
        pathLabelName: pathLabelName
      });
    });

    // add peers as nodes
    for (const peerId in peersNodes){
      nodes.push(peersNodes[peerId])
    }

    this.updatetunnelStats(this.props.tunnels.tunnels);

    this.network_graph_vis(nodes, links);
  }

  updatetunnelStats(tunnels) {
    // tunnels.tunnelStatusA.drop_rate
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Dashboard</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Network</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Network</TT></h4>
        <div>{/*"Width:"+this.state.width+", Height:"+this.state.height*/}</div>
        <div id={"network-chart"} />
      </React.Fragment>
    );
  }
}

export default Network;