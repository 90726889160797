import React, { Component } from "react";
import { TT, LanguageContext  } from '../../containers/Language'
import {
  maxLength, minLength, isEmpty
} from "../../utils/Validators";
import {
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Card,
  CardBody,
  Row, Col,
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { FWTextInput } from "../Common";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { countryList } from '../../utils/Countries';

class UpdateAccount extends Component {
  static contextType = LanguageContext

  componentDidMount() {
    this.props.resetForm();
    this.props.getAccount(this.props.id, resp => {
      this.props.changeForm(
       { _id: resp._id, accountName: resp.name, country: resp.country, forceMfa: resp.forceMfa },
       { silent: true }
      )
    });
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    console.log("handleSubmit " + JSON.stringify(values));
    this.props.updAccount({
      _id: this.props.id,
      name: values.accountName,
      country: values.country,
      forceMfa: values.forceMfa
    }, ()=>{});
  }

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem><TT>Accounts</TT></BreadcrumbItem>
              <BreadcrumbItem active><TT>Account Profile</TT></BreadcrumbItem>
            </Breadcrumb>
        <h4><TT>Account Profile</TT></h4>
        <div className="col-md-7">
          <Card id="updateAccountCard">
            <CardBody>
              <Form 
                id="updateAccount"
                model="updateAccount"
                onSubmit={values => this.handleSubmit(values)}               
              >
                <Row className="form-group">
                  <Label htmlFor="accountName" md={5}>
                    <TT>Company Name</TT> <span className="helpTooltip" data-tip data-for='accountNameTip'></span>
                    <ReactTooltip id='accountNameTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>Your company name</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.text
                      model=".accountName"
                      id="accountName"
                      name="accountName"
                      placeholder={toTTString("Company Name")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        maxLength: maxLength(30),
                        minLength: minLength(2)
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".accountName"
                      show="touched"
                      messages={{
                        maxLength: toTTString("Length must be at most 30"),
                        minLength: toTTString("Length must be at least 2")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="country" md={5}>
                    <TT>Country</TT> <span className="helpTooltip" data-tip data-for='countryTip'></span>
                    <ReactTooltip id='countryTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>The country the account belongs to</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.select
                      model=".country"
                      id="country"
                      name="country"
                      placeholder={toTTString("Country")}
                      className="form-control"
                      validators={{
                        selected: (val) => { return !isEmpty(val); }
                      }}
                    >
                      <option value="" disabled hidden>
                        {toTTString("Select Country")}
                      </option>
                      {countryList.map(option => <option value={option.value} key={option.value}>{toTTString(option.name)}</option>)}
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="forceMfa" md={5}>
                    <TT>Force Two-Factor-authenticator</TT> <span className="helpTooltip" data-tip data-for='forceMfaTip'></span>
                    <ReactTooltip id='forceMfaTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>Force account's users to use Two-Factor-authenticator to login</TT>. <br/>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7} style={{'paddingLeft':'15px'}}>
                    <Label className="FWswitch">
                      <Control.checkbox
                        id="forceMfa"
                        name="forceMfa"
                        model=".forceMfa"
                      />{" "}
                      <span className="FWslider round" />
                    </Label>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col className="form-field" md={{size:10, offset:4}}>
                    <Control.button              
                      form={"updateAccount"}
                      type="submit"
                      model="updateAccount"
                      disabled={{ valid: false }} 
                      className="btn btn-primary" 
                    >
                      <TT>Update</TT>
                    </Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default UpdateAccount;
