import React, { Component, useContext } from 'react';
import {
  Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Badge,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StructuredFilter from './items/structured-filter/lib/main';
import { loadLocalSettings, saveLocalSettings } from '../utils/localSettings';
import { TT, LanguageContext } from '../containers/Language'
import './Jobs.css';
import FwCollapse from './items/FwCollapse';
import ReactJson from 'react-json-view';
import * as pako from 'pako';

const jobStates = {
  complete: { value: 'complete', color: 'success' },
  failed: { value: 'failed', color: 'danger' },
  waiting: { value: 'inactive', color: 'secondary' },
  retry: { value: 'delayed', color: 'warning' },
  running: { value: 'active', color: 'info' }
};

const getFilteredValue = (filter) => {
  switch (filter.categorykey) {
    case 'state':
      return jobStates[filter.value].value;
    case 'created_at':
      let dateVal = new Date(filter.value);
      if (['>', '<='].includes(filter.operator)) {
        dateVal.setDate(dateVal.getDate() + 1);
      }
      return (dateVal.getTime() + dateVal.getTimezoneOffset() * 60000).toString();
    default:
      return filter.value;
  }
}

const JobsFilter = ({ filters, updateFilters, clearFilters }) => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <div className="container filter-bar">
    <StructuredFilter
      className={"search-query form-control"}
      placeholder={
        filters.length === 0
          ? toTTString("Filter by job attributes")
          : toTTString("Add filter")
      }
      value={filters}
      options={[
        {
          category: toTTString("ID"),
          categorykey: "_id",
          type: "number",
        },
        {
          category: toTTString("Title"),
          categorykey: "data.message.title",
          type: "text",
        },
        {
          category: toTTString("Device ID"),
          categorykey: "type",
          type: "text",
        },
        {
          category: toTTString("Device Name"),
          categorykey: "device.name",
          type: "text",
        },
        {
          category: toTTString("Hostname"),
          categorykey: "device.hostname",
          type: "text",
        },
        {
          category: toTTString("Status"),
          categorykey: "state",
          type: "textoptions",
          options: () => {
            return Object.keys(jobStates)
          },
        },
        {
          category: toTTString("User"),
          categorykey: "data.metadata.username",
          type: "text",
        },
        {
          category: toTTString("Created At"),
          categorykey: "created_at",
          type: "date",
        }
      ]}
      onChange={updateFilters}
      operatorSigns={{
        textoptions: {
          "==": "==",
          "!=": "!=",
        },
        text: {
          "!empty": "!n",
          empty: "n",
          "==": "==",
          "!=": "!=",
          contains: "in",
          "!contains": "!in",
        },
        textoptionsarray: {
          "contains": "contains",
          "!contains": "!contains"
        },
        date: {
          "<": "<",
          ">": ">",
          "<=": "<=",
          ">=": ">=",
        }
      }}
    />
    <FontAwesomeIcon icon="search" fixedWidth />
    <Button
      className="btn-primary filter-box-btn"
      onClick={clearFilters}
    >
      <TT>Clear Filters</TT>
    </Button>
  </div>
)}

class Jobs extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.state = {
      isDelModalOpen: false,
      delEntity: null,
      jobState: 'all',
      jobsSelected: [],
      filters: [],
      requestParams: {
        offset: 0,
        limit: 50
      },
      isActionsDropDownOpen: false,
      isDeleteAllModalOpen: false,
      isOriginalAppsDisplayed: true,
      cachedAppsRowId: -1
    };   

    this.toggleDelModal = this.toggleDelModal.bind(this);   
    this.approveDelete = this.approveDelete.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.getPaginatedJobs = this.getPaginatedJobs.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleActionsDropDown = this.toggleActionsDropDown.bind(this);
    this.toggleDeleteAllModal = this.toggleDeleteAllModal.bind(this);
    this.approveDeleteAll = this.approveDeleteAll.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
  }

  componentDidMount() {
    // Load user's local settings
    const { requestParams } = loadLocalSettings('jobs');
    if (requestParams && requestParams.limit) {
      this.setState({ requestParams: { limit: requestParams.limit, offset : 0 } });
    }
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen
    });
  }

  handleDelete() {
    this.toggleDelModal();
  }
  approveDelete() {
    this.toggleDelModal();
    this.props.delJobs(this.state.jobState, this.state.jobsSelected, ()=> {
      this.setState(() => ({
        requestParams: { ...this.state.requestParams, offset : 0 },
        jobsSelected: []
      }), this.getPaginatedJobs);
    });
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        jobsSelected: [...this.state.jobsSelected, row._id]
      }));
    } else {
      this.setState(() => ({
        jobsSelected: this.state.jobsSelected.filter(id => id !== row._id)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const ids = rows.map(r => r._id);
      this.setState(() => ({
        jobsSelected: ids
      }));
    } else {
      this.setState(() => ({
        jobsSelected: []
      }));
    }
  }

  getPaginatedJobs = () => {
    this.props.getJobs(this.state.jobState, {
        ...this.state.requestParams,
      filters: JSON.stringify(
        this.state.filters.map(f => ({
          key: f.categorykey,
          op: f.operator,
          val: getFilteredValue(f)
        }))
      )
    })
  }

  updateFilters(filters) {
    // Clear checkboxes when the filter changes.
    this.setState(prev => ({
      requestParams: { ...prev.requestParams, offset: 0 },
      filters: filters,
      jobsSelected: []
    }), this.getPaginatedJobs);
  }

  toggleActionsDropDown() {
    this.setState({
      isActionsDropDownOpen: !this.state.isActionsDropDownOpen
    });
  }

  handleDeleteAll() {
    this.toggleDeleteAllModal();
  }

  toggleDeleteAllModal() {
    this.setState({
      isDeleteAllModalOpen: !this.state.isDeleteAllModalOpen
    });
  }

  approveDeleteAll() {
    this.toggleDeleteAllModal();
    const filters = this.state.filters.map(f => ({
      key: f.categorykey,
      op: f.operator,
      val: getFilteredValue(f)
    }));
    this.props.deleteAllJobs(filters, ()=> {
      this.setState(() => ({
        requestParams: { ...this.state.requestParams, offset : 0 },
        jobsSelected: []
      }), this.getPaginatedJobs);
    });
  }

  onTableChange = (type, newState) => {
    const { sizePerPage, page } = newState;
    if (sizePerPage !== undefined && page !== undefined) {
      saveLocalSettings('jobs', { requestParams: { limit: sizePerPage } });
      this.setState({
        requestParams: {
          offset: page * sizePerPage,
          limit: sizePerPage
        }
      }, this.getPaginatedJobs);
    }
  }

  renderErrors = (errors) => {
    return (
      <div className="job-error-details">
        {[...errors].reverse().map(function (err, idx) {
          const error = err?.error ?? err;
          return (
            <FwCollapse title={"Details:"} key={idx} level={idx} isOpen={false} noborder >
              <div><span className="text-danger">Error:&nbsp;</span>{error}</div>
            </FwCollapse>
          );
        })}
      </div>
    );
  };

  render() {
    const { jobs } = this.props.jobs;
    const toTTString = this.context.toTTString
    const { offset, limit } = this.state.requestParams;
    const page = limit > 0 ? offset / limit : 0;
    const columns = [
        {text: toTTString('Id'), dataField: '_id', sort: false,
            headerStyle: (colum, colIndex) => {
            return { minWidth: "100px", textAlign: 'left' };
          }
        }, 
        {text: toTTString('Title'), dataField: 'data.message.title', sort: false,
            headerStyle: (colum, colIndex) => {
            return { minWidth: "200px", textAlign: 'left' };
          }
        }, 
        {text: toTTString('Device ID'), dataField: 'type', sort: false,
          headerStyle: (colum, colIndex) => {
            return { minWidth: "270px", textAlign: 'left' };
          },
        },
        {text: toTTString('Device Name'), dataField: 'device.name', sort: false,
          headerStyle: (colum, colIndex) => {
            return { minWidth: "120px", textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            const deviceName = !row.device?._id ? <TT>Deleted device</TT> :
              <Link className='link-button' to={`/devices/deviceinfo/${row.device._id}?tab=Interfaces`}>
                {row.device.name}
              </Link>;
            return deviceName;
          }
        },
        {text: toTTString('Created At'), dataField: 'created_at', sort: false,
          headerStyle: (colum, colIndex) => {
            return { minWidth: "200px", textAlign: 'left' };
          },          
          formatter: (cellContent, row) => {
            return (new Date(+row.created_at)
              .toLocaleString('en-US', { year: 'numeric',month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'}));
          }
        },
        {text: toTTString('Status'), dataField: 'state', sort: false,
          headerStyle: (colum, colIndex) => {
            return { minWidth: "100px", textAlign: 'left' };
          },          
          formatter: (cellContent, row) => {
            const states_color = {'complete':'success', 'failed':'danger', 'inactive':'secondary', 
              'delayed':'warning', 'active':'primary'};
            const states_text = {'complete':toTTString('complete'), 'failed':toTTString('failed'), 'inactive':toTTString('waiting'),
              'delayed':toTTString('retry'), 'active':toTTString('running')};

            return (<div className="jobsContainer">
                        <Badge color={states_color[row.state || 'inactive']}>
                            <TT>{states_text[row.state || 'inactive']}</TT>
                        </Badge>
                        {row?.data?.metadata?.jobUpdated ? <span className='updated-text'>(Updated)</span> : null}
                    </div>);
          }
        }          
    ];

    const pageListRenderer = ({ onPageChange }) => {
      return (
        <div className='react-bootstrap-table-pagination-list react-bootstrap-table-pagination-list col-6'>
          <ul className="pagination react-bootstrap-table-page-btns-ul">
            {page > 0 &&
            <li className="page-item" title="First page">
              <button className="page-link" onClick={()=>onPageChange(0)}><TT>First</TT></button>
            </li>}
            {page > 1 &&
            <li className="page-item" title="Pre page">
              <button className="page-link" onClick={()=>onPageChange(page - 1)}><TT>Back</TT></button>
            </li>}
            {jobs.length >= limit &&
            <li className="page-item" title="Next page">
              <button className="page-link" onClick={()=>onPageChange(page + 1)}><TT>Next</TT></button>
            </li>}
          </ul>
        </div>
      );
    };

    const paginationOptions = {
      sizePerPage: limit,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      pageListRenderer,
      paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
                                                      { size > 0 && <TT params={{from: offset + 1, to: offset + size}}>Showing #from# to #to# Jobs</TT> }
                                                    </span>),
      sizePerPageList: [
        {text: '10', value: 10},
        {text: '20', value: 20},
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '200', value: 200}
      ]
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: true,
      selected: this.state.jobsSelected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };  

    const decodeApplications = (applications) => {
      const buf = Buffer.from(applications, 'base64');
      return `${pako.inflate(buf, { to: 'string' })}`;
    }

    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => {
        const { tasks } = row.data.message;
        return (
          <div className="jobsContainer">
            <pre>
              {/* row.data.metadata.username value can be "system", so need to translate it as well. */}
              <p>{ toTTString("User") + ": " + toTTString(row.data.metadata.username) || toTTString("Unknown")}</p>
              <p>{ toTTString("Organization") + ": " + row.data.metadata.org || toTTString("Unknown") }</p>
              <p>{ `${toTTString("Attempts")}: ${row.attempts.made} ${toTTString("of")} ${row.attempts.max}` }</p>
              {row.error && row.error.errors ? (
                <p className="text-danger">
                  <span className="error-text">{`${
                    row.error.errors?.[0]?.command?.descr ||
                    row.error.errors?.[0]?.command?.func ||
                    row.data.message.title
                  }`}</span>{" "}
                  command failed
                </p>
              ) : null}
              {row.error && row.error.errors? this.renderErrors(row.error.errors) : null}
              <hr></hr>
              <ReactJson
                  src={tasks}
                  name={"Tasks"}
                  enableClipboard={true}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  onSelect={(select)=>{
                    const {name, namespace, value} = select;
                    if(name !== 'applications') {
                      return;
                    }
                    // getSelectedObject function traverses the object and returns the sub-object identified by a namespace.
                    // The namespace is an array containing the hierarchy map starting from the top level object.
                    // For example, given the top object is formed like this:
                    // tasks: [
                    //  entity: agent,
                    //  message: aggregated,
                    //  params: {
                    //   requests: [
                    //    {
                    //     params: {
                    //      applications: eJy8vW2PXLeVrv1XGv7k...
                    //     }
                    //    }
                    //   ]
                    //  }
                    // ]
                    // and namespace array like this: [0, params, requests, 0, params], the function will return
                    // the reference to params object, the one which contains applications field in it.
                    // The motivation is to be able to replace the encoded applications field with their decoded version.
                    // Overriding the select.value, doesn't modify the original object (seems to be a copy).
                    const getSelectedObject = (startObj, namespace) => {
                      let obj = startObj;
                      namespace.forEach(element => {
                        obj = obj[element];
                      });
                      return obj;
                    }
                    const selectedField = getSelectedObject(tasks, namespace);
                    if(this.state.cachedAppsRowId !== row._id) {
                      // keep cached copies of both the original and decoded applications, for fast swap
                      const decodedApps = decodeApplications(value);
                      this.setState({ decodedApps: decodedApps, originalApps: value, cachedAppsRowId: row._id });
                    }
                    this.setState(prev => ({ isOriginalAppsDisplayed: !prev.isOriginalAppsDisplayed }), () => {
                      selectedField.applications = this.state.isOriginalAppsDisplayed
                        ? this.state.originalApps
                        : this.state.decodedApps;
                      this.forceUpdate();
                    });
                }}
              />
            </pre>
          </div>
        )
      }
    };  

  const addStateFilter = (state) => {
    this.setState(prev => ({
      requestParams: {
        ...prev.requestParams, offset: 0
      },
      filters: [{
        category: toTTString("Status"),
        categorykey: "state",
        operator: "==",
        value: state
      }]
    }), this.getPaginatedJobs);
  };

  const hasStateFilter = state => this.state.filters.some(f =>
    f.operator === '==' &&
    f.categorykey === 'state' &&
    f.value === state
  );

  return(
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>Troubleshoot</TT></BreadcrumbItem>
        <BreadcrumbItem active><TT>Jobs</TT></BreadcrumbItem>
      </Breadcrumb>
      <div className="container">
        <h4><TT>Jobs</TT><small> (<TT>7 days old</TT>)</small></h4>
        <div style={{width: '5rem'}}>{this.props.jobs.isLoading? <span className="signal"></span> : ''}</div>
        <div style={{marginTop: '0.5rem'}}>
          <small> <TT>Filter by state</TT> :</small>
          {Object.entries(jobStates).map(([state, { value, color }]) => (
            <Button
              className="ml-2 text-capitalize" size="sm" color={color} key={value}
              outline={!hasStateFilter(state)} onClick={()=>addStateFilter(state)}
            >
              <TT>{state}</TT>
            </Button>
          ))}
        </div>
      </div>
      <div className="col-md-12">
        <div className="d-flex align-items-center screen-bar mb-3">
          <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
          <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
            onClick = {this.getPaginatedJobs}>
            <FontAwesomeIcon icon="sync-alt" />
          </Button>
          <div className="mr-1">
          <Dropdown
            isOpen={this.state.isActionsDropDownOpen}
            toggle={this.toggleActionsDropDown}
          >
            <DropdownToggle caret className="action-drop-down">
              <TT>Actions</TT>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header><TT>Jobs Actions</TT></DropdownItem>
              <DropdownItem divider />
              {this.state.jobsSelected.length > 0 ?
              <DropdownItem
                onClick={() => this.handleDelete()}
              >
                <TT>Delete Selected Jobs</TT>
              </DropdownItem>
              : this.state.filters.length > 0 &&
              <DropdownItem onClick={() => this.handleDeleteAll()}>
                <TT>Delete All Jobs matching the filter</TT>
              </DropdownItem>
              }
            </DropdownMenu>
          </Dropdown>
          </div>
        <JobsFilter
          filters={this.state.filters}
          updateFilters={this.updateFilters}
          clearFilters={() => this.setState({
            requestParams: { ...this.state.requestParams, offset: 0 },
            filters: [],
            jobsSelected: []
          }, this.getPaginatedJobs)}
        />
        </div>
        <BootstrapTable striped hover condensed
          keyField='_id' 
          data={ jobs }
          wrapperClasses={'jobs-table scroll-x'}
          columns={ columns }
          remote={true}
          onTableChange={this.onTableChange}
          pagination={ paginationFactory(paginationOptions) }
          noDataIndication={toTTString("No jobs available")}
          selectRow={ selectRow } 
          expandRow={ expandRow } />
      </div>
      <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
        <ModalHeader toggle={this.toggleDelModal}><TT>Delete Job</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3"><TT>Are you sure to delete selected jobs?</TT></div>
          <Button color="danger" onClick={this.approveDelete}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={this.toggleDelModal}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>         
      <Modal isOpen={this.state.isDeleteAllModalOpen} toggle={this.toggleDeleteAllModal}>
        <ModalHeader toggle={this.toggleDeleteAllModal}><TT>Delete All Jobs</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3"><TT>Are you sure to delete all jobs matching the filter?</TT></div>
          <Button color="danger" onClick={this.approveDeleteAll}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={this.toggleDeleteAllModal}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>
    </React.Fragment>
    );
  }
}
export default Jobs;
