import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UsernamePassword from './UsernamePassword';
import MFA from "../MFA/MFA";
import AuthMethods from "./AuthMethods";
import UseRecoveryCode from "./UseRecoveryCode";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { setLoginProcessScreen } from '../../../redux/reducers/Auth';
import { Link, Redirect } from "react-router-dom";
import { TT } from '../../../containers/Language'
const { removeBranding } = window.SERVER_CONFIG;

const Login = props => {
  const { setLoginProcessScreen, loginToken } = props;
  const [redirectTo, setRedirectTo] = useState('/home'); // once user logged in, redirect from this component to "redirectTo"

  useEffect(() => {
    if (loginToken) {
      setLoginProcessScreen(1)
    }
  }, [loginToken, setLoginProcessScreen])

  const renderComp = () => {
    switch(props.loginScreen) {
      case 0:
        return <UsernamePassword />;
      case 1:
        return <MFA
          isWithinLoginCard={true}
          onVerified={(isFirstVerification) => {
            if (isFirstVerification) {
              setRedirectTo('/2fa/recoveryCodes')
            }
          }}
          onMaxAttempts={() => {
            props.setLoginProcessScreen(0)
          }}
        />;
      case 2:
        return <AuthMethods />;
      case 3:
        return <UseRecoveryCode />
      default:
        return <UsernamePassword />;
    }
  }

  if (props.isAuthenticated) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/home"><TT>Home</TT></Link>
        </BreadcrumbItem>
        <BreadcrumbItem active><TT>Login</TT></BreadcrumbItem>
      </Breadcrumb>
      <div>
        <Card id="loginCard">
          <CardBody>
            <CardTitle>
              {!removeBranding?
                <><TT>Login to flexi</TT><font style={{'color':'#4c9c90'}}>Manage</font></>
                :<TT>Login to the Management System</TT>}
            </CardTitle>
            <hr />

            { renderComp() }
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loginScreen: state.auth.loginScreen,
    loginToken: state.auth.loginToken
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginProcessScreen: screenNum => dispatch(setLoginProcessScreen(screenNum)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
