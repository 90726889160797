import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardTitle,
  Row, Col
} from "reactstrap";
import { isEmail } from "../../utils/Validators";
import { Link, Redirect } from "react-router-dom";
import querySearch from "stringquery";
import { TT, LanguageContext  } from '../../containers/Language'

class VerifyAccount extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      id: ""
    };

    this.email = React.createRef();
    this.handleVerify = this.handleVerify.bind(this);
  }

  handleVerify(event) {
    this.props.reverifyAccount({
      email: this.email.value
    });
    event.preventDefault();
  }

  componentDidMount() {
    const query = querySearch(this.props.query);
    if (this.email.isConnected) {
      this.email.value = (query && query.email)?query.email:'';
    }
    if (!this.props.isAuthenticated && query.id && query.t) {
      this.setState({id:query.id})
      this.props.verifyAccount({id:query.id, token:query.t});
    }
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />;
    }

    console.log("ID=" + this.state.id);

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Verify Account</TT></BreadcrumbItem>
        </Breadcrumb>
        {this.state.id === ''?
          <div>
            <Card id="verifyAccountCard">
              <CardBody>
                <CardTitle><TT>Verify Account</TT></CardTitle>
                <hr />
                <Form onSubmit={this.handleVerify}>
                  <FormGroup>
                    <Row>
                      <Label htmlFor="email" md={3}>
                        <TT>Email</TT>
                      </Label>
                      <Col md={9}>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          innerRef={input => (this.email = input)}
                          onChange={event => {
                            this.setState({ email: event.target.value });
                          }}
                        />
                        <div className="text-danger">
                          <span>
                            {this.state.email.length && !isEmail(this.state.email)
                              ? <TT>Must be a valid email</TT>
                              : ""}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <Button
                          type="submit"
                          value="submit"
                          color="primary"
                          disabled={!isEmail(this.state.email)}
                          style={{ float: "right", minWidth: "460px" }}
                        >
                          <TT>Re-Verify</TT>
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
                <hr />
                <div className="bottom-card-message">
                  <div>
                    <TT>Already verified?</TT> <Link to="/login"><TT>Log In</TT></Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        : <h5><br /><TT>Please wait while your account is being validated</TT> ...</h5>}
      </React.Fragment>
    );
  }
}
export default VerifyAccount;
