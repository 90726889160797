import React, { useState, useContext } from 'react';
import { TT, LanguageContext } from '../../containers/Language'
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StructuredFilter from '../items/structured-filter/lib/main';

const ApplicationsFilter = ({ filters, updateFilters, clearFilters, keyName }) => {
  const { toTTString } = useContext(LanguageContext);
  return (
  <div className="container filter-bar">
    <StructuredFilter
      className={"search-query form-control"}
      placeholder={
        filters.length === 0
          ? toTTString("Filter by application attributes")
          : toTTString("Add filter")
      }
      value={filters}
      options={[
        {
          category: toTTString("Name"),
          categorykey: keyName,
          type: "text",
        },
      ]}
      customClasses={{
        input: "filter-tokenizer-text-input",
        results: "filter-tokenizer-list__container",
        listItem: "filter-tokenizer-list__item",
      }}
      onChange={updateFilters}
      operatorSigns={{
        textoptions: {
          "==": "==",
          "!=": "!=",
        },
        text: {
          "!empty": "!n",
          empty: "n",
          "==": "==",
          "!=": "!=",
          contains: "in",
          "!contains": "!in",
        },
        textoptionsarray: {
          "contains": "contains",
          "!contains": "!contains"
        }
      }}
    />
    <FontAwesomeIcon icon="search" fixedWidth />
    <Button
      className="btn-primary filter-box-btn"
      onClick={clearFilters}
    >
      <TT>Clear Filters</TT>
    </Button>
  </div>
)}

const ApplicationsTableFilter = props => {
  const [filters, setFilters] = useState([]);

  return (
    <div className="d-flex align-items-center screen-bar mb-3">
      <ReactTooltip id='refresh-a'>
        <span><TT>Refresh</TT></span>
      </ReactTooltip>

      <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
        onClick={() => props.refreshHandler(filters)}>
        <FontAwesomeIcon icon="sync-alt" />
      </Button>

      <ApplicationsFilter
        filters={filters}
        keyName={props.keyName}
        updateFilters={vals => {
          if (filters.length !== vals.length) {
            setFilters(vals)
            props.refreshHandler(vals)
          }
        }}
        clearFilters={() => setFilters([])}
      />
    </div>
  )
}

export default ApplicationsTableFilter;