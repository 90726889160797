import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { FWFetch } from "../../utils/Network";
import queryString from 'query-string';
const { baseUrl } = window.SERVER_CONFIG;

export const Tunnels = (
  state = {
    tunnel: null,
    tunnels: [],
    total: 0,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.TUNNEL_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.TUNNEL_GETALL_SUCCESS:
      return { ...state, tunnels: action.tunnels, total: action.total, isLoading: false };
    case Actions.TUNNEL_GETALL_FAILURE:
      return { ...state, tunnels: [], total: 0, isLoading: false };
    case Actions.TUNNEL_GETALL_CLEAR:
      return { ...state, tunnels: [], total: 0 };
    case Actions.TUNNEL_DEL_REQUEST:
      return { ...state, tunnel: action.tunnels };
    case Actions.TUNNEL_DEL_SUCCESS:
      return { ...state };
    case Actions.TUNNEL_DEL_FAILURE:
      return { ...state, tunnel: null };
    case Actions.TUNNEL_PUT_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true };
    case Actions.TUNNEL_PUT_NOTIFICATIONS_SUCCESS:
    case Actions.TUNNEL_PUT_NOTIFICATIONS_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

/**********************************************************************
 *   Get all Tunnels
 **********************************************************************/
export const requestGetAllTunnels = () => dispatch => {
  dispatch({
    type: Actions.TUNNEL_GETALL_REQUEST
  });
};

export const receiveGetAllTunnels = (response, total) => dispatch => {
  dispatch({
    type: Actions.TUNNEL_GETALL_SUCCESS,
    tunnels: response,
    total
  });
};

export const failureGetAllTunnels = error => dispatch => {
  dispatch({
    type: Actions.TUNNEL_GETALL_FAILURE
  });
};

export const getAllTunnels = (params = {}, success_cb=()=>{}) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + 'tunnels?' + queryParams,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllTunnels());
      },
      successCB: (response, status, headers) => {
        dispatch(receiveGetAllTunnels(response, headers && headers.get('records-total')));
        if (typeof success_cb === "function") success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetAllTunnels(error));
      }
    })
  );
};

export const clearTunnels = () => dispatch => {
  dispatch({
    type: Actions.TUNNEL_GETALL_CLEAR
  });
};

/**********************************************************************
 *   Delete Tunnel
 **********************************************************************/
export const requestDelTunnels = tunnels => dispatch => {
  dispatch({
    type: Actions.TUNNEL_DEL_REQUEST,
    tunnels
  });
};

export const receiveDelTunnel = response => dispatch => {
  const message = response.status === 'completed'
    ? { color: "success", text: response.message }
    : { color: "danger", text: response.message };
  dispatch({
    type: Actions.TUNNEL_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage(message)
  );
};

export const failureDelTunnel = error => dispatch => {
  dispatch({
    type: Actions.TUNNEL_DEL_FAILURE
  });
};

export const delTunnels = (tunnels, filters, success_cb) => dispatch => {
  console.log("delTunnels. Tunnels=" + JSON.stringify(tunnels));
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/apply",
      method: "POST",
      body: JSON.stringify({ method: "deltunnels", tunnels, filters }),
      requestCB: () => {
        dispatch(requestDelTunnels(tunnels));
      },
      successCB: response => {
        dispatch(receiveDelTunnel(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelTunnel(error));
      }
    })
  );
};

/**********************************************************************
 *   Modify tunnel notifications
 **********************************************************************/
export const requestModifyTunnelNotifications = () => dispatch => {
  dispatch({
    type: Actions.TUNNEL_PUT_NOTIFICATIONS_REQUEST,
  });
};

export const receiveModifyTunnelNotifications = response => dispatch => {
  const message =  { color: "success", text: response.message }
  dispatch({
    type: Actions.TUNNEL_PUT_NOTIFICATIONS_SUCCESS
  });
  dispatch(
    alertMsgMessage(message)
  );
};

export const failureModifyTunnelNotifications = error => dispatch => {
  dispatch({
    type: Actions.TUNNEL_PUT_NOTIFICATIONS_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "An error occurred while updating the settings" })
  );
};

export const modifyTunnelsNotifications = (request) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "tunnels/notifications",
      method: "PUT",
      body: JSON.stringify(request),
      requestCB: () => {
        dispatch(requestModifyTunnelNotifications());
      },
      successCB: response => {
        dispatch(receiveModifyTunnelNotifications(response));
      },
      failureCB: error => {
        dispatch(failureModifyTunnelNotifications(error));
      }
    })
  );
};
