import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
const { baseUrl } = window.SERVER_CONFIG;

export const PathLabels = (
  state = {
    pathLabel: null,
    pathLabels: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.PATH_LABEL_ADD_REQUEST:
      return { ...state };
    case Actions.PATH_LABEL_ADD_SUCCESS:
      return { ...state, pathLabel: action.pathLabel };
    case Actions.PATH_LABEL_ADD_FAILURE:
      return { ...state };
    case Actions.PATH_LABEL_UPD_REQUEST:
      return { ...state, pathLabel: action.pathLabel };
    case Actions.PATH_LABEL_UPD_SUCCESS:
      return { ...state };
    case Actions.PATH_LABEL_UPD_FAILURE:
      return { ...state };
    case Actions.PATH_LABEL_DEL_REQUEST:
      return { ...state, pathLabel: action.pathLabel };
    case Actions.PATH_LABEL_DEL_SUCCESS:
      return { ...state };
    case Actions.PATH_LABEL_DEL_FAILURE:
      return { ...state };
    case Actions.PATH_LABEL_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.PATH_LABEL_GETALL_SUCCESS:
      return { ...state, pathLabels: action.pathLabels, isLoading: false };
    case Actions.PATH_LABEL_GETALL_FAILURE:
      return { ...state, pathLabels: [], isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add path label
 **********************************************************************/
export const requestAddPathLabel = pathLabel => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_ADD_REQUEST,
    pathLabel
  });
};

export const receiveAddPathLabel = response => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_ADD_SUCCESS,
    pathLabel: response.name
  });
  dispatch(mainRedirectTo("/pathlabels"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Path label " + response.name + " added successfully"
    })
  );
};

export const failureAddPathLabel = error => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const addPathLabel = (pathLabel, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "pathlabels",
      method: "POST",
      body: JSON.stringify(pathLabel),
      requestCB: () => {
        dispatch(requestAddPathLabel(pathLabel));
      },
      successCB: response => {
        dispatch(receiveAddPathLabel(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddPathLabel(error));
      }
    })
  );
};

/**********************************************************************
 *   Update path label
 **********************************************************************/
export const requestUpdPathLabel = pathLabel => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_UPD_REQUEST,
    pathLabel
  });
};

export const receiveUpdPathLabel = response => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_UPD_SUCCESS
  });
  dispatch(mainRedirectTo("/pathlabels"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Path label " + store.getState().pathLabel.name + " updated successfully"
    })
  );
};

export const failureUpdPathLabel = error => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const updPathLabel = (pathLabel, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "pathlabels/" + pathLabel._id,
      method: "PUT",
      body: JSON.stringify(pathLabel),
      requestCB: () => {
        dispatch(requestUpdPathLabel(pathLabel));
      },
      successCB: response => {
        dispatch(receiveUpdPathLabel(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdPathLabel(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete path label
 **********************************************************************/
export const requestDelPathLabel = pathLabel => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_DEL_REQUEST,
    pathLabel
  });
};

export const receiveDelPathLabel = response => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Path label " + store.getState().pathLabel.name + " deleted successfully"
    })
  );
};

export const failureDelPathLabel = error => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_DEL_FAILURE
  });
};

export const delPathLabel = (pathLabel, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "pathlabels/" + pathLabel._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelPathLabel(pathLabel));
      },
      successCB: response => {
        dispatch(receiveDelPathLabel(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelPathLabel(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all path labels
 **********************************************************************/
export const requestGetAllPathLabels = () => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_GETALL_REQUEST
  });
};

export const receiveGetAllPathLabels = response => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_GETALL_SUCCESS,
    pathLabels: response
  });
};

export const failureGetAllPathLabels = error => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_GETALL_FAILURE
  });
};

export const getAllPathLabels = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "pathlabels",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllPathLabels());
      },
      successCB: response => {
        dispatch(receiveGetAllPathLabels(response));
        success_cb(response)
      },
      failureCB: error => {
        dispatch(failureGetAllPathLabels(error));
      }
    })
  );
};

/**********************************************************************
 *   Get path label
 **********************************************************************/
export const requestGetPathLabel = () => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_GET_REQUEST
  });
};

export const receiveGetPathLabel = response => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_GET_SUCCESS,
    pathLabel: response
  });
};

export const failureGetPathLabel = error => dispatch => {
  dispatch({
    type: Actions.PATH_LABEL_GET_FAILURE
  });
};

export const getPathLabel = (pathLabelId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "pathlabels/" + pathLabelId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetPathLabel());
      },
      successCB: response => {
        dispatch(receiveGetPathLabel(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetPathLabel(error));
      }
    })
  );
};
