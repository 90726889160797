import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { FWFetch } from "../../utils/Network";
import { mainRedirectTo } from "./Main";
import { store } from "../../App";
import { clearLocalSettings } from '../../utils/localSettings';
const { baseUrl } = window.SERVER_CONFIG;

export const Accounts = (
  state = {
    account: null,
    accounts: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.ACCOUNT_SELECT_REQUEST:
      return { ...state };
    case Actions.ACCOUNT_SELECT_SUCCESS:
      return { ...state, account: action.account };
    case Actions.ACCOUNT_SELECT_FAILURE:
      return { ...state };
    case Actions.ACCOUNT_GETALL_REQUEST:
      return { ...state, accounts: [], isLoading: true };
    case Actions.ACCOUNT_GETALL_SUCCESS:
      return { ...state, accounts: action.accounts, isLoading: false };
    case Actions.ACCOUNT_GETALL_FAILURE:
      return { ...state, accounts: [], isLoading: false };
    // Get account
    case Actions.ACCOUNT_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ACCOUNT_GET_SUCCESS:
      return { ...state, account: action.account, isLoading: false };
    case Actions.ACCOUNT_GET_FAILURE:
      return { ...state, account: null, isLoading: false };
    // Get account subscription status
    case Actions.ACCOUNT_GET_SUBSCRIPTION_STATUS_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ACCOUNT_GET_SUBSCRIPTION_STATUS_SUCCESS:
      return { ...state, account: {...state.account, ...action.account}, isLoading: false };
    case Actions.ACCOUNT_GET_SUBSCRIPTION_STATUS_FAILURE:
      return { ...state, account: null, isLoading: false };
    case Actions.ACCOUNT_UPD_REQUEST:
      return { ...state, account: action.account };
    case Actions.ACCOUNT_UPD_SUCCESS:
      return { ...state };
    case Actions.ACCOUNT_UPD_FAILURE:
      return { ...state, account: null };      
    default:
      return state;
  }
};


/**********************************************************************
 *   Select Account
 **********************************************************************/
export const requestSelectAccount = account => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_SELECT_REQUEST,
    account
  });
};

export const receiveSelectAccount = response => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_SELECT_SUCCESS,
    account: response
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Account set"
    })
  );
};

export const failureSelectAccount = error => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_SELECT_FAILURE
  });
};

export const selectAccount = (account, success_cb) => dispatch => {
  clearLocalSettings();
  return dispatch(
    FWFetch({
      url: baseUrl + "accounts/select",
      method: "POST",
      body: JSON.stringify(account),
      requestCB: () => {
        dispatch(requestSelectAccount(account));
      },
      successCB: response => {
        dispatch(receiveSelectAccount(response));
        dispatch(mainRedirectTo("/home"));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureSelectAccount(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all accounts
 **********************************************************************/
export const requestGetAllAccounts = () => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GETALL_REQUEST
  });
};

export const receiveGetAllAccounts = response => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GETALL_SUCCESS,
    accounts: response
  });
};

export const failureGetAllAccounts = error => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GETALL_FAILURE
  });
};

export const getAllAccounts = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accounts",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllAccounts());
      },
      successCB: response => {
        dispatch(receiveGetAllAccounts(response));
      },
      failureCB: error => {
        dispatch(failureGetAllAccounts(error));
      }
    })
  );
};

/**********************************************************************
 *   Get account
 **********************************************************************/
export const requestGetAccount = () => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GET_REQUEST
  });
};

export const receiveGetAccount = response => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GET_SUCCESS,
    account: response
  });
};

export const failureGetAccount = error => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GET_FAILURE
  });
};

export const getAccount = (accountId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accounts/" + accountId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAccount());
      },
      successCB: response => {
        dispatch(receiveGetAccount(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetAccount(error));
      }
    })
  );
};

/**********************************************************************
 *   Get account Subscription Status
 **********************************************************************/
export const requestGetAccountSubscriptionStatus = () => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GET_SUBSCRIPTION_STATUS_REQUEST
  });
};

export const receiveGetAccountSubscriptionStatus = response => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GET_SUBSCRIPTION_STATUS_SUCCESS,
    account: response
  });
};

export const failureGetAccountSubscriptionStatus = error => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_GET_SUBSCRIPTION_STATUS_FAILURE
  });
};

export const getAccountSubscriptionStatus = (accountId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accounts/" + accountId + "/subscription-status",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAccountSubscriptionStatus());
      },
      successCB: response => {
        dispatch(receiveGetAccountSubscriptionStatus(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetAccountSubscriptionStatus(error));
      }
    })
  );
};

/**********************************************************************
 *   Update Account
 **********************************************************************/
export const requestUpdAccount = account => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_UPD_REQUEST,
    account
  });
};

export const receiveUpdAccount = response => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_UPD_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Account " + store.getState().accounts.account.name + " updated successfully"
    })
  );
};

export const failureUpdAccount = error => dispatch => {
  dispatch({
    type: Actions.ACCOUNT_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const updAccount = (account, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accounts/" + account._id,
      method: "PUT",
      body: JSON.stringify(account),
      requestCB: () => {
        dispatch(requestUpdAccount(account));
      },
      successCB: response => {
        dispatch(receiveUpdAccount(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdAccount(error));
      }
    })
  );
};
