import React from 'react';
import { 
  PiCellSignalNoneBold,
  PiCellSignalLowBold,
  PiCellSignalMediumBold,
  PiCellSignalHighBold,
  PiCellSignalFullBold,
  PiCellSignalXBold,
} from 'react-icons/pi';

const SignalStrength = ({ quality, size }) => {
  return (
    <div className='d-flex align-items-center'>
        { quality <= 0 ? (
          <PiCellSignalXBold size={size} />
        ) : quality <= 20 ? (
          <PiCellSignalNoneBold size={size} />
        ) : quality <= 40 ? (
          <PiCellSignalLowBold size={size} />
        ) : quality <= 65 ? (
          <PiCellSignalMediumBold size={size} />
        ): quality <= 80 ? (
          <PiCellSignalHighBold size={size} />
        ) : (
          <PiCellSignalFullBold size={size} />
        )}
    </div>
  );
};

export default SignalStrength;
