import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import FwBootstrapTable from '../items/FwBootstrapTable';
import { TT, LanguageContext } from '../../containers/Language';
import { getVrrpGroups } from '../../redux/reducers/HighAvailability';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

const VrrpCard = props => {
  const [vrrpGroups, setVrrpGroups] = useState([]);
  const { toTTString } = useContext(LanguageContext);

  const { id, getVrrpGroups } = props;
  useEffect(() => {
    getVrrpGroups((res, err) => {
      if (res) {
        setVrrpGroups(res)
      }
    }, id);
  }, [id, getVrrpGroups]);

  return (
    <FwBootstrapTable
      keyField="_id"
      data={vrrpGroups}
      columns={[
        {
          text: toTTString('Name'),
          dataField: 'name',
          sort:true,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          }
        },
        {
          text: toTTString('Virtual Router ID'),
          dataField: 'virtualRouterId',
          sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          },
          formatter: (content, row) => {
            return (
              <Link to={`/vrrp/configure/${row._id}`}>
                <span style={{color: 'steelblue'}}>{content}</span>
              </Link>
            )
          }
        },
        {
          text: toTTString('Virtual IP'),
          dataField: 'virtualIp',
          sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          }
        },
        {
          text: toTTString('Interface'),
          dataField: 'interface',
          isDummyInterface: true,
          sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          },
          formatter: (cellContent, row, rowIndex, extraData) => {
            const device = row.devices.find(d => d._id === id);
            const ifc = extraData.find(d => d.devId === device.interface);
            return ifc?.name;
          },
          formatExtraData: props?.interfaces ?? []
        },
        {
          text: toTTString('State'),
          dataField: 'state',
          isDummyInterface: true,
          sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          },
          formatter: (cellContent, row, rowIndex) => {
            const device = row.devices.find(d => d._id === id);
            return device?.status?.state ?? 'N/A';
          },
        },
        {
          text: toTTString('Installation status'),
          dataField: 'jobStatus',
          isDummyInterface: true,
          sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          },
          formatter: (cellContent, row, rowIndex) => {
            const device = row.devices.find(d => d._id === id);
            const jobStatus = device?.jobStatus;

            let color = null, message = null;
            if (jobStatus === 'failed') {
              color = "danger";
              message = "Failed";
            } else if(jobStatus === 'removed') {
              color = "danger";
              message = "Removed";
            } else if (jobStatus === 'installed') {
              color = "success";
              message = "Installed";
            } else if (jobStatus === 'pending') {
              color = "warning";
              message = "Pending";
            }

            if (message) {
              return (
                <>
                  <Badge color={color}>
                    <span data-tip data-for={`status-${row._id}`}><TT>{message}</TT></span>
                  </Badge>
                </>
              )
            }

            return device?.jobStatus;
          },
        },
      ]}
    />
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getVrrpGroups: (cb, id) => dispatch(getVrrpGroups(cb, id))
  }
}

export default connect(null, mapDispatchToProps)(VrrpCard);