import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Control, Form, Errors } from 'react-redux-form';
import VlansTable from '../items/VlansTable';
import { TT, LanguageContext } from '../../containers/Language'

const VlansModal = (props) => {
  const [tableValid, setTableValid] = useState(true);
  const { toTTString } = useContext(LanguageContext);

  if (!props.row) return '';
  const { name, isAssigned } = props.row;

  return (
  <Modal
    size='lg'
    scrollable='true'
    isOpen={props.isOpen}
  >
    <Form
      model="updateInterface"
      onSubmit={(res) => tableValid && props.onSubmit(res)}
      validators={{
        '': {
          duplicateTags: values => {
            const tags = values.vlans.map(vlan => vlan.tag);
            return tags.length === new Set(tags).size;
          }
        }
      }}
    >
      <ModalHeader
        style={{ padding: '16px' }}
        toggle={props.toggle}
      >
        <TT>{`${name} ${isAssigned !== 'Yes' ? '(not assigned)' : ''} - VLAN sub-interfaces`}</TT>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
         }}
      >
          <Row>
            <Col className="text-center">
              <VlansTable
                hideAddButton={true}
                onStartEdit={() => setTableValid(false)}
                afterSaveCell={() => setTableValid(true)}
                {...props}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-left">
              <button
                onClick={() => props.handleAddVlan()}
                type="button"
                className="btn btn-primary"
              >
                <TT>Add</TT>
              </button>
            </Col>
          </Row>
      </ModalBody>
      <Card>
        <CardBody
          style={{ padding: '16px' }}
        >
          <Row>
            <Col>
              <Errors
                className="text-danger"
                model="updateInterface"
                show="touched"
                messages={{
                  duplicateTags: toTTString("Duplicated VLAN tags are not allowed")
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Control.button
                model="updateInterface"
                disabled={!tableValid}
                className="btn btn-primary btn-ok"
              >
                <TT>Update</TT>
              </Control.button>
              <button
                onClick={props.toggle}
                type="button"
                className="btn btn-outline-secondary ml-1"
              >
                <TT>Cancel</TT>
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  </Modal>
  )
}

export default VlansModal;