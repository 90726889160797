import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TT } from '../../containers/Language'

const ColumnItem = ({ column, index }) => (
  <Draggable
    key={column.dataField}
    draggableId={column.dataField}
    index={index}
  >
    {provided => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div
          style={{
            width: '220px',
            border: '1px solid grey',
            marginBottom: '5px',
            backgroundColor: '#e9ecef',
            padding: '5px'
          }}
        >
          {column.text}
        </div>
      </div>
    )}
  </Draggable>
)

export default function ColumnsModal (props) {

  const [columns, setColumns] = useState({ shown: [], hidden: [] });

  const initColumns = useCallback((columns) => {
    const hidden = [];
    const shown = [];
    for(const column of columns) {
      const { dataField, order, text } = column;
      if (order !== undefined && order !== 0) {
        if (order > 0) {
          shown.push({ dataField, text });
        } else {
          hidden.push({ dataField, text });
        }
      }
    };
    setColumns({ hidden, shown });
  }, [])

  useEffect(() => {
    initColumns(props.allColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultOrder = useCallback(() => {
    initColumns(props.allColumns.map(
      c => ({ ...c, order: props.defaultColumnsOrder[c.dataField] ?? 0 })
    ).sort((a, b) => a.order === b.order ? 0 : a.order < b.order ? -1 : 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultColumnsOrder, props.allColumns])

  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    if ((destination.index === source.index) && (destination.droppableId === source.droppableId)) {
      return;
    }
    setColumns(prev => {
      const [removed] = prev[source.droppableId].splice(source.index, 1);
      if (!removed) return prev;
      prev[destination.droppableId].splice(destination.index, 0, removed);
      const reordered = destination.droppableId === source.droppableId ?
        {
          ...prev,
          [source.droppableId]: prev[source.droppableId]
        }
        :
        {
          [destination.droppableId]: prev[destination.droppableId],
          [source.droppableId]: prev[source.droppableId]
        }
      return reordered;
    });
  };
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>
        <TT>Modify Columns Order</TT>
      </ModalHeader>
      <ModalBody>
        <Row className="form-group">
          <Col className='text-center'>
            <TT>Drag columns to hide, show or order them</TT>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='text-center'>
            <FontAwesomeIcon icon="eye" size="1x" style={{ marginRight: '5px' }} fixedWidth />
            <Label>
              <TT>Shown Columns</TT>
            </Label>
          </Col>
          <Col md={6} className='text-center'>
            <FontAwesomeIcon icon="eye-slash" size="1x" style={{ marginRight: '5px' }} fixedWidth />
            <Label>
              <TT>Hidden Columns</TT>
            </Label>
          </Col>
        </Row>
        <Row className="form-group">
          <DragDropContext onDragEnd={onDragEnd}>
            {['shown', 'hidden'].map(type => (
              <div key={type} className={'col-md-6 columns-'+type}>
                <Droppable droppableId={type} key={type} className="columns-droppable">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {columns[type].map((column, index) => <ColumnItem key={index} column={column} index={index} />)}
                    {provided.placeholder}
                    <div style={{ minHeight: '20px' }}/>
                  </div>
                )}
                </Droppable>
              </div>
            ))}
          </DragDropContext>
        </Row>
        <Row className="form-group">
        <Col className="text-left">
            <button
              onClick={() => setDefaultOrder()}
              type="button"
              className="btn btn-outline-secondary ml-2"
            >
              <TT>Defaults</TT>
            </button>
          </Col>
          <Col className="text-right">
            <button
              onClick={() => {
                const { shown, hidden } = columns;
                const columnsOrder = {};
                shown.forEach((column, index) => columnsOrder[column.dataField] = index + 1);
                hidden.forEach((column, index) => columnsOrder[column.dataField] = index - hidden.length - 1);
                props.onSubmit(columnsOrder)
              }}
              className="btn btn-success btn-ok"
            >
              <TT>OK</TT>
            </button>
            <button
              onClick={props.toggle}
              type="button"
              className="btn btn-outline-secondary ml-2"
            >
              <TT>Cancel</TT>
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}