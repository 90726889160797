import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Version = (
  state = {
    version: '',
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.VERSION_GET_REQUEST:
      return { ...state, version: '', isLoading: true };
    case Actions.VERSION_GET_SUCCESS:
      return { ...state, version: state.version, isLoading: false };
    case Actions.VERSION_GET_FAILURE:
      return { ...state, version: 'unknown', isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get Version
 **********************************************************************/
export const requestGetVersion = () => dispatch => {
  dispatch({
    type: Actions.VERSION_GET_REQUEST
  });
};

export const receiveGetVersion = response => dispatch => {
  dispatch({
    type: Actions.VERSION_GET_SUCCESS,
    version: response.version
  });
};

export const failureGetVersion = error => dispatch => {
  dispatch({
    type: Actions.VERSION_GET_FAILURE
  });
};

export const getVersion = success_cb => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "version",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetVersion());
      },
      successCB: response => {
        dispatch(receiveGetVersion(response));
        success_cb(response.version);
      },
      failureCB: error => {
        dispatch(failureGetVersion(error));
      }
    })
  );
};
