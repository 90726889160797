import React, { useContext } from "react";
import { Label, Row, Col, Input, FormFeedback } from 'reactstrap';
import { TT, LanguageContext } from '../../containers/Language'
import ReactTooltip from 'react-tooltip';
import SearchableSelectBox from '../items/SearchableSelectBox';

const routingOptions = [
  { label: 'OSPF', value: 'ospf' },
  { label: 'BGP', value: 'bgp'}
]

export default function TunnelAdvancedOptions({ mtu, mssClamp, ospfCost, ospfArea, setValue, tunnelType, routing }) {
  const { toTTString } = useContext(LanguageContext);
  return (
    <>
      <Row className="form-group">
        <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
            <TT>MTU</TT>:
        </Label>
        <Col>
          <Input
            invalid={isNaN(mtu) || (mtu !== '' && (mtu < 500 || mtu > 1500))}
            className="col-md-8"
            type="text"
            placeholder={toTTString("Tunnel MTU")}
            value={mtu}
            onChange={e => setValue('mtu', e.target.value)}
          />
          <FormFeedback >
            <TT>MTU value must be between 500 and 1500</TT>
          </FormFeedback>
        </Col>
      </Row>
      <Row className="form-group">
        <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
          <TT>MSS Clamping</TT>:
        </Label>
        <Col>
          <Label className="FWswitch">
            <input
              type="checkbox"
              checked={mssClamp !== 'no'}
              onChange={e => setValue('mssClamp', e.target.checked ? 'yes' : 'no')}
            />
            {" "}
            <span className="FWslider round" />
          </Label>
        </Col>
      </Row>

      <Row  className="form-group">
        <Label
          className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
            <TT>Routing</TT>:
            <span className="helpTooltip" data-tip data-for='tunnelsRoutingTip'></span>
            <ReactTooltip id='tunnelsRoutingTip'>
              <span style={{"fontSize": "0.8rem"}}>
              <TT>Select the routing protocol that will be used to advertise the organization's network routes between the selected devices via this tunnel</TT>.
              </span>
            </ReactTooltip>
        </Label>
        <SearchableSelectBox
          className="col-md-8"
          id="routing"
          name="routing"
          value={routing}
          placeholder=""
          onChange={({ value, label }) => {
            setValue('routing', value)}
          }
          isClearable={false}
          options={routingOptions}
        />
        { routing === 'bgp' ? (
          <Col>
            <small className="text-muted">
              { tunnelType === 'peer'
                ? toTTString("Please create a BGP neighbor with the remote peer configuration")
                : tunnelType === 'site-to-site'
                  ? toTTString("BGP neighbors will be created automatically for the selected devices")
                  : null
              }
            </small>
          </Col>
        ) : null}
      </Row>

      { routing === 'ospf' ? (
        <>
          <Row className="form-group">
            <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
              <TT>OSPF Cost</TT>:
            </Label>
            <Col>
              <Input
                invalid={ospfCost !== '' && (isNaN(ospfCost) || ospfCost <= 0)}
                className="col-md-8"
                type="text"
                placeholder={toTTString("OSPF cost value")}
                value={ospfCost}
                onChange={e => setValue('ospfCost', e.target.value)}
              />
              <FormFeedback >
                <TT>OSPF cost must be a positive numeric value or empty</TT>
              </FormFeedback>
            </Col>
          </Row>

          <Row className="form-group">
            <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
              <TT>OSPF Area</TT>:
            </Label>
            <Col>
              <Input
                className="col-md-8"
                type="text"
                placeholder={toTTString("OSPF area value")}
                value={ospfArea}
                onChange={e => setValue('ospfArea', e.target.value)}
              />
              <FormFeedback >
                <TT>OSPF area must be a positive numeric value or empty</TT>
              </FormFeedback>
            </Col>
          </Row>
        </>

      ) : null}
    </>
  )
};
