import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
const { baseUrl } = window.SERVER_CONFIG;

export const QOSPolicies = (
  state = {
    qosPolicy: {},
    qosPolicies: [],
    qosPoliciesMeta: [],
    qosTrafficMap: {},
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.QOS_POLICIES_ADD_REQUEST:
      return { ...state };
    case Actions.QOS_POLICIES_ADD_SUCCESS:
      return { ...state };
    case Actions.QOS_POLICIES_ADD_FAILURE:
      return { ...state };
    case Actions.QOS_POLICIES_UPD_REQUEST:
      return { ...state, qosPolicy: action.qosPolicy };
    case Actions.QOS_POLICIES_UPD_SUCCESS:
      return { ...state };
    case Actions.QOS_POLICIES_UPD_FAILURE:
      return { ...state };
    case Actions.QOS_POLICIES_DEL_REQUEST:
      return { ...state, qosPolicy: action.qosPolicy };
    case Actions.QOS_POLICIES_DEL_SUCCESS:
      return { ...state };
    case Actions.QOS_POLICIES_DEL_FAILURE:
      return { ...state };
    case Actions.QOS_POLICIES_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.QOS_POLICIES_GET_SUCCESS:
      return { ...state, qosPolicy: action.qosPolicy, isLoading: false };
    case Actions.QOS_POLICIES_GET_FAILURE:
      return { ...state, qosPolicy: {}, isLoading: false };
    case Actions.QOS_POLICIES_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.QOS_POLICIES_GETALL_SUCCESS:
      return { ...state, qosPolicies: action.qosPolicies, isLoading: false };
    case Actions.QOS_POLICIES_GETALL_FAILURE:
      return { ...state, qosPolicies: [], isLoading: false };
    case Actions.QOS_POLICIES_LIST_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.QOS_POLICIES_LIST_GET_SUCCESS:
      return { ...state, qosPolicies: action.qosPolicies, isLoading: false };
    case Actions.QOS_POLICIES_LIST_GET_FAILURE:
      return { ...state, qosPolicies: [], isLoading: false };
    case Actions.QOS_POLICIES_GET_META_REQUEST:
      return { ...state, isLoading: true };
    case Actions.QOS_POLICIES_GET_META_SUCCESS:
      return { ...state, qosPoliciesMeta: action.qosPoliciesMeta, isLoading: false };
    case Actions.QOS_POLICIES_GET_META_FAILURE:
      return { ...state, qosPoliciesMeta: [], isLoading: false };
    case Actions.QOS_POLICIES_GET_TRAFFIC_MAP_REQUEST:
      return { ...state, isLoading: true };
    case Actions.QOS_POLICIES_GET_TRAFFIC_MAP_SUCCESS:
      return { ...state, qosTrafficMap: action.qosTrafficMap, isLoading: false };
    case Actions.QOS_POLICIES_GET_TRAFFIC_MAP_FAILURE:
      return { ...state, qosTrafficMap: {}, isLoading: false };
    case Actions.QOS_POLICIES_UPD_TRAFFIC_MAP_REQUEST:
      return { ...state, qosTrafficMap: action.qosTrafficMap };
    case Actions.QOS_POLICIES_UPD_TRAFFIC_MAP_SUCCESS:
      return { ...state, qosTrafficMap: action.qosTrafficMap };
    case Actions.QOS_POLICIES_UPD_TRAFFIC_MAP_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add a qos policy
 **********************************************************************/
export const requestAddQOSPolicy = qosPolicy => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_ADD_REQUEST,
  });
};

export const receiveAddQOSPolicy = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_ADD_SUCCESS
  });
  dispatch(mainRedirectTo("/qosPolicies"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "QOS policy " + response.name + " added successfully"
    })
  );
};

export const failureAddQOSPolicy = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const addQOSPolicy = (qosPolicy, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies",
      method: "POST",
      body: JSON.stringify(qosPolicy),
      requestCB: () => {
        dispatch(requestAddQOSPolicy(qosPolicy));
      },
      successCB: response => {
        dispatch(receiveAddQOSPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddQOSPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Update a qos policy
 **********************************************************************/
export const requestUpdQOSPolicy = qosPolicy => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_UPD_REQUEST,
    qosPolicy
  });
};

export const receiveUpdQOSPolicy = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_UPD_SUCCESS,
  });
  const { message, name } = response;
  dispatch(
    alertMsgMessage({
      color: "success",
      text: `QOS policy "${name}" saved successfully ${message ? '. ' + message : ''}`
    })
  );
};

export const failureUpdQOSPolicy = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const updQOSPolicy = (qosPolicy, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies/" + qosPolicy._id,
      method: "PUT",
      body: JSON.stringify(qosPolicy),
      requestCB: () => {
        dispatch(requestUpdQOSPolicy(qosPolicy));
      },
      successCB: response => {
        dispatch(receiveUpdQOSPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdQOSPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete a qos policy
 **********************************************************************/
export const requestDelQOSPolicy = qosPolicy => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_DEL_REQUEST,
    qosPolicy
  });
};

export const receiveDelQOSPolicy = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_DEL_SUCCESS
  });

  const { name } = store.getState().qosPolicies.qosPolicy;
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "QOS policy " + name + " deleted successfully"
    })
  );
};

export const failureDelQOSPolicy = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_DEL_FAILURE
  });
};

export const delQOSPolicy = (qosPolicy, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies/" + qosPolicy._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelQOSPolicy(qosPolicy));
      },
      successCB: response => {
        dispatch(receiveDelQOSPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelQOSPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all qos policies
 **********************************************************************/
export const requestGetAllQOSPolicies = () => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GETALL_REQUEST
  });
};

export const receiveGetAllQOSPolicies = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GETALL_SUCCESS,
    qosPolicies: response
  });
};

export const failureGetAllQOSPolicies = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GETALL_FAILURE
  });
};

export const getAllQOSPolicies = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllQOSPolicies());
      },
      successCB: response => {
        dispatch(receiveGetAllQOSPolicies(response));
        success_cb(response)
      },
      failureCB: error => {
        dispatch(failureGetAllQOSPolicies(error));
      }
    })
  );
};

/**********************************************************************
 *   Get a list of all qos policies
 **********************************************************************/
export const requestGetQOSPoliciesList = () => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_LIST_GET_REQUEST
  });
};

export const receiveGetQOSPoliciesList = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_LIST_GET_SUCCESS,
    qosPolicy: response
  });
};

export const failureGetQOSPoliciesList = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_LIST_GET_FAILURE
  });
};

export const getQOSPoliciesList = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies/list",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetQOSPoliciesList());
      },
      successCB: response => {
        dispatch(receiveGetQOSPoliciesList(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetQOSPoliciesList(error));
      }
    })
  );
};


/**********************************************************************
 *   Get a qos policy
 **********************************************************************/
export const requestGetQOSPolicy = () => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_REQUEST
  });
};

export const receiveGetQOSPolicy = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_SUCCESS,
    qosPolicy: response
  });
};

export const failureGetQOSPolicy = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_FAILURE
  });
};

export const getQOSPolicy = (qosPolicyId, success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies/" + qosPolicyId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetQOSPolicy());
      },
      successCB: response => {
        dispatch(receiveGetQOSPolicy(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetQOSPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Get qos policies meta data
 **********************************************************************/
export const requestGetQOSPoliciesMetaData = () => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_META_REQUEST
  });
};

export const receiveGetQOSPoliciesMetaData = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_META_SUCCESS,
    qosPoliciesMeta: response
  });
};

export const failureGetQOSPoliciesMetaData = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_META_FAILURE
  });
};

export const getQOSPoliciesMetaData = (success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosPolicies/meta",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetQOSPoliciesMetaData());
      },
      successCB: response => {
        dispatch(receiveGetQOSPoliciesMetaData(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetQOSPoliciesMetaData(error));
      }
    })
  );
};

/**********************************************************************
 *   Get QoS Traffic Map
 **********************************************************************/
 export const requestGetQOSTrafficMap = () => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_TRAFFIC_MAP_REQUEST
  });
};

export const receiveGetQOSTrafficMap = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_TRAFFIC_MAP_SUCCESS,
    qosTrafficMap: response
  });
};

export const failureGetQOSTrafficMap = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_GET_TRAFFIC_MAP_FAILURE
  });
};

export const getQOSTrafficMap = (success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosTrafficMap",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetQOSTrafficMap());
      },
      successCB: response => {
        dispatch(receiveGetQOSTrafficMap(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetQOSTrafficMap(error));
      }
    })
  );
};

/**********************************************************************
 *   Update the traffic map
 **********************************************************************/
 export const requestUpdQOSTrafficMap = qosTrafficMap => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_UPD_TRAFFIC_MAP_REQUEST,
    qosTrafficMap
  });
};

export const receiveUpdQOSTrafficMap = response => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_UPD_TRAFFIC_MAP_SUCCESS,
    qosTrafficMap: response
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "QOS Traffic Map updated successfully"
    })
  );
};

export const failureUpdQOSTrafficMap = error => dispatch => {
  dispatch({
    type: Actions.QOS_POLICIES_UPD_TRAFFIC_MAP_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const updQOSTrafficMap = (qosTrafficMap, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "qosTrafficMap",
      method: "PUT",
      body: JSON.stringify(qosTrafficMap),
      requestCB: () => {
        dispatch(requestUpdQOSTrafficMap(qosTrafficMap));
      },
      successCB: response => {
        dispatch(receiveUpdQOSTrafficMap(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureUpdQOSTrafficMap(error));
      }
    })
  );
};
