import React, { Component } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { Control, Form, Errors, actions } from 'react-redux-form';
import { required, isIP4, isIPList, minLength, maxLength, isMAC, validateHostName, isNumber, maxValue, minValue } from '../../../utils/Validators';
import { FWTextInput } from '../../Common';
import { getStartEndIPv4 } from '../../../utils/ipCalculator';
import { getBridgedInterface } from '../../../utils/DevicesUtils';
import { TT, LanguageContext  } from '../../../containers/Language'
import DhcpOptions from './Options';
import StaticAssignments from './StaticAssignments';
import { connect } from 'react-redux';
import { getMajorVersion, getMinorVersion } from '../../../utils/DevicesUtils';
import cidrTools from 'cidr-tools';
import _uniqueId from 'lodash/uniqueId';
import { saveDHCP } from '../../../redux/reducers/Devices';

class AddDHCP extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isDeviceSupportsDhcpOptions = this.isDeviceSupportsDhcpOptions.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getInterfaceName = this.getInterfaceName.bind(this);
    this.props.resetForm();
    this.state = {
      rangeStart: null,
      rangeEnd: null,
      selectedIfcNetwork: null
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  setIpState = ifc => {
    const [IPv4, IPv4Mask] = ifc.IPv4.split('/')
    const [networkAddr, broadcastAddr] = getStartEndIPv4(IPv4, IPv4Mask);
    this.setState({
      selectedIfcNetwork: ifc.IPv4,
      rangeStart:networkAddr,
      rangeEnd:broadcastAddr
    });
  }

  componentDidMount() {
    const { dhcpEntry } = this.props;
    if (!dhcpEntry) {
      return
    }

    const selectedInterface = this.props.interfaces.find(i => i.devId === dhcpEntry.interface);
    if (selectedInterface) {
      this.setIpState(selectedInterface)
    }

    this.props.changeForm({
      ...dhcpEntry,
      dns: dhcpEntry.dns.join(", ")
    }, { silent: true });
  }

  handleClose() {
    this.props.addDHCPEntry(null);
    this.props.resetForm();
  }

  handleSubmit(values) {
    const fixedValues = {...values};
    fixedValues.dns = fixedValues.dns.split(/\s*,\s*/);
    this.addDHCPEntry(fixedValues);
    this.props.resetForm();
  }

  handleAddDhcp(dhcp = null) {
    if (dhcp) {
      let dhcpEntries = [...this.props.dhcpEntries];

      // Remove the entry if we're called from update flow
      if (dhcp._id) {
        dhcpEntries = dhcpEntries.filter(entry => entry._id !== dhcp._id);
      }
      const _id = dhcp._id || _uniqueId('tempId-');
      this.props.saveDHCP([...dhcpEntries, { ...dhcp, _id }])
      this.props.updateUserInputState('interfaces', true);
    }
  }

  getInterfaceName() {
    const dhcpIfc = this.props.interfaces.find(i => i.devId === this.props.dhcpEntry.interface);
    if (!dhcpIfc) return 'N/A';

    const bridgedInterfaces = getBridgedInterface(dhcpIfc, this.props.interfaces);
    if (bridgedInterfaces.length > 0) {
      return bridgedInterfaces.map(b => b.name).join(' / ')
    }
    return dhcpIfc.name;
  }

  isDeviceSupportsDhcpOptions() {
    const major = getMajorVersion(this.props.device?.versions?.agent);
    const minor = getMinorVersion(this.props.device?.versions?.agent);
    return major > 6 || (major === 6 && minor >= 3);
  }

  render() {
    const toTTString = this.context.toTTString


    this.paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
        <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [{ text: '10', value: 10 }, { text: '50', value: 50 }, { text: '100', value: 100 }]
    };


    // DHCP definition example:
    //       "interface": "0000:00:08.00",
    //       "rangeStart": "20.20.20.2",
    //       "rangeEnd": "20.20.20.255",
    //       "dns": ["8.8.8.8", "8.8.8.4"],
    //       "macAssign":[{"host":"flexiwan-host2", "mac":"08:00:27:d0:d2:04", "ipv4":"20.20.20.20"},
    //                    {"host":"flexiwan-host3", "mac":"08:00:27:d0:d2:05", "ipv4":"20.20.20.21"}]

    return(
      <React.Fragment>
        <Form
          id="addDHCPForm"
          model="addDHCP"
          onSubmit={(values) => this.handleSubmit(values)}
          validators={{
            'macAssign[].host': {
              required: required,
              validateHostName: validateHostName,
              maxLength: maxLength(255),
              minLength: minLength(1),
              hostDuplication: val => {
                const used = new Set();
                for (const macAssign of this.props?.formData.macAssign ?? []) {
                  if (used.has(macAssign.host) && val === macAssign.host) {
                    return false;
                  }
                  used.add(macAssign.host)
                }
                return true;
              }
            },
            'macAssign[].mac': {
              required: required,
              isMac: isMAC,
              macDuplication: val => {
                const used = new Set();
                for (const macAssign of this.props?.formData.macAssign ?? []) {
                  if (used.has(macAssign.mac) && val === macAssign.mac) {
                    return false;
                  }
                  used.add(macAssign.mac)
                }
                return true;
              },
            },
            'macAssign[].ipv4': {
              required: required,
              isIpv4: isIP4,
              ipDuplication: val => {
                const used = new Set();
                for (const macAssign of this.props?.formData.macAssign?? []) {
                  if (used.has(macAssign.ipv4) && val === macAssign.ipv4) {
                    return false;
                  }
                  used.add(macAssign.ipv4)
                }
                return true;
              },
            },
            'options[].option': {
              required: required,
              duplication: val => {
                const used = new Set();
                for (const option of this.props?.formData.options ?? []) {
                  const optionName = option.option;
                  if (optionName && used.has(optionName) && val === optionName) {
                    return false;
                  }
                  used.add(optionName)
                }
                return true;
              }
            },
            'options[].value': {
              required: required
            }
          }}
        >
          <Row className="form-group">
            <Label htmlFor="" md={2}>
              <span className="form-required-star">*</span>
              <TT>Interface</TT>
            </Label>
            <Col md={4}>
              <Control.text
                model=".interface"
                id="interface"
                name="interface"
                placeholder=""
                className="form-control"
                disabled={true}
                value={this.getInterfaceName()}
                validators={{
                  required: required
                }}
              >
              </Control.text>
              <Errors
                className="text-danger"
                model=".interface"
                show="touched"
                messages={{
                  required: toTTString('Required field'),
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="rangeStart" md={2}>
              <span className="form-required-star">*</span>
              <TT>IP Range Start</TT>
            </Label>
            <Col md={4}>
              <Control.text
                type="text"
                model=".rangeStart"
                id="rangeStart"
                name="rangeStart"
                placeholder={toTTString("IP Range Start")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  required: required,
                  isNotNetworkBroadcastAddr: val => val !== this.state.rangeStart && val !== this.state.rangeEnd,
                  isOverlap: val =>  val && this.state.selectedIfcNetwork && cidrTools.overlap(`${val}/32`, this.state.selectedIfcNetwork),
                }}
              />
              <Errors
                className="text-danger"
                model=".rangeStart"
                show="touched"
                messages={
                  {
                    required: toTTString('Required field'),
                    ipStart: toTTString('Invalid IPv4 Address'),
                    isNotNetworkBroadcastAddr: toTTString('IP cannot be network or broadcast IP address'),
                    isOverlap: toTTString('IP is not in the interface subnet'),
                  }
                }
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="rangeEnd" md={2}>
              <span className="form-required-star">*</span>
              <TT>IP Range End</TT>
            </Label>
            <Col md={4}>
              <Control.text
                type="text"
                model=".rangeEnd"
                id="rangeEnd"
                name="rangeEnd"
                placeholder={toTTString("IP Range End")}
                component={FWTextInput}
                withFieldValue
                validators={
                  {
                    required: required,
                    ipEnd: isIP4,
                    isNotNetworkBroadcastAddr: val => val !== this.state.rangeStart && val !== this.state.rangeEnd,
                    isOverlap: val =>  val && this.state.selectedIfcNetwork && cidrTools.overlap(`${val}/32`, this.state.selectedIfcNetwork),
                  }
                }
              />
              <Errors
                className="text-danger"
                model=".rangeEnd"
                show="touched"
                messages={
                  {
                    required: toTTString('Required field'),
                    ipEnd: toTTString('Invalid IPv4 Address'),
                    isNotNetworkBroadcastAddr: toTTString('IP cannot be network or broadcast IP address'),
                    isOverlap: toTTString('IP is not in the interface subnet'),
                  }
                }
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="dns" md={2}><TT>DNS Servers</TT></Label>
            <Col md={4}>
              <Control.text
                type="text"
                model=".dns"
                id="dns"
                name="dns"
                placeholder={toTTString("DNS Servers")}
                component={FWTextInput}
                withFieldValue
                validators={
                  {
                    ipList: isIPList
                  }
                }
              />
              <Errors
                className="text-danger"
                model=".dns"
                show="touched"
                messages={
                  {
                    ipList: toTTString('Invalid IPv4 Addresses')
                  }
                }
              />
            </Col>
          </Row>

          <Row className="form-group">
            <Label htmlFor="dns" md={2}><TT>Default Lease time</TT></Label>
            <Col md={4}>
              <Control.text
                type="text"
                model=".defaultLeaseTime"
                id="defaultLeaseTime"
                name="defaultLeaseTime"
                placeholder={toTTString("Default Lease time")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  isNumber: isNumber,
                  maxValue: maxValue(31536000),
                  minValue: minValue(-1)
                }}
              />
              <Errors
                className="text-danger"
                model=".defaultLeaseTime"
                show="touched"
                messages={{
                  isNumber: toTTString("Value should be a number"),
                  minValue: toTTString("Lease time must be greater than or equal to -1"),
                  maxValue: toTTString('Lease time must be less than or equal to 31536000')
                }}
              />
            </Col>
          </Row>

          <Row className="form-group">
            <Label htmlFor="dns" md={2}><TT>Max Lease time</TT></Label>
            <Col md={4}>
              <Control.text
                type="text"
                model=".maxLeaseTime"
                id="maxLeaseTime"
                name="maxLeaseTime"
                placeholder={toTTString("Max Lease time")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  isNumber: isNumber,
                  maxValue: maxValue(31536000),
                  minValue: minValue(-1)
                }}
              />
              <Errors
                className="text-danger"
                model=".maxLeaseTime"
                show="touched"
                messages={{
                  isNumber: toTTString("Value should be a number"),
                  minValue: toTTString("Lease time must be greater than or equal to -1"),
                  maxValue: toTTString('Lease time must be less than or equal to 31536000')
                }}
              />
            </Col>
          </Row>

          <hr/>
          <StaticAssignments form="addDHCP" />

          <hr />

          { this.isDeviceSupportsDhcpOptions() ? <DhcpOptions form="addDHCP" /> : null}

        </Form>
      </React.Fragment>
    );
  }
}

export const getNewDhcpEntry = ifc => {
  const [IPv4, IPv4Mask] = ifc.IPv4.split('/')
  const [start, end] = getStartEndIPv4(IPv4, IPv4Mask, 1);

  return {
    _id: _uniqueId('tempId-'),
    interface: ifc.devId,
    rangeStart: start,
    rangeEnd: end,
    dns: ['8.8.8.8, 8.8.4.4'],
    macAssign: [],
    options: [],
    defaultLeaseTime: '',
    maxLeaseTime: ''
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveDHCP: dhcpEntries => dispatch(saveDHCP(dhcpEntries)),
    resetForm: () => dispatch(actions.reset('addDHCP')),
    changeForm: (val, options) => dispatch(actions.change('addDHCP', val, options)),
  }
}
const mapStateToProps = state => {
  return {
    formData: state.addDHCP,
    dhcpEntries: state.devices.dhcp,
    device: state.devices.device,
    interfaces: state.devices.tableInterfaces
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDHCP);