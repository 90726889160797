import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import queryString from 'query-string';
const { baseUrl } = window.SERVER_CONFIG;

export const Applications = (
  state = {
    repository: [],
    purchasedApplications: [],
    purchasedApplication: null,
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.APPLICATIONS_GET_REPOSITORY_REQUEST:
    case Actions.APPLICATIONS_GET_PURCHASED_REQUEST:
    case Actions.APPLICATIONS_PURCHASE_REQUEST:
    case Actions.APPLICATIONS_UNINSTALL_REQUEST:
    case Actions.APPLICATIONS_UPDATE_REQUEST:
    case Actions.APPLICATIONS_UPGRADE_REQUEST:
    case Actions.APPLICATIONS_STATUS_REQUEST:
      return { ...state, isLoading: true };
    case Actions.APPLICATIONS_PURCHASE_FAILURE:
    case Actions.APPLICATIONS_UNINSTALL_FAILURE:
    case Actions.APPLICATIONS_UPDATE_FAILURE:
    case Actions.APPLICATIONS_UPGRADE_FAILURE:
    case Actions.APPLICATIONS_STATUS_FAILURE:
          return { ...state, isLoading: false };
    case Actions.APPLICATIONS_GET_REPOSITORY_SUCCESS:
      return { ...state, repository: action.applications, isLoading: false };
    case Actions.APPLICATIONS_GET_REPOSITORY_FAILURE:
      return { ...state, repository: [], isLoading: false };
    case Actions.APPLICATIONS_GET_PURCHASED_SUCCESS:
      return {
        ...state,
        purchasedApplications: action.purchasedApplications,
        isLoading: false,
      };
    case Actions.APPLICATIONS_GET_PURCHASED_FAILURE:
      return { ...state, purchasedApplications: [], isLoading: false };
    case Actions.APPLICATIONS_GET_ONE_PURCHASED_REQUEST:
      return {...state, purchasedApplication: null}
    case Actions.APPLICATIONS_GET_ONE_PURCHASED_SUCCESS:
      return {...state, purchasedApplication: action.purchasedApplication}
    case Actions.APPLICATIONS_PURCHASE_SUCCESS:
      return {
        ...state,
        purchasedApplications: [
          ...state.purchasedApplications,
          action.purchasedApplication,
        ],
        isLoading: false,
      };
    case Actions.APPLICATIONS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, purchasedApplication: action.purchasedApplication };
    case Actions.APPLICATIONS_STATUS_SUCCESS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

// /* Action Creators */
/**********************************************************************
 *   Get all applications
 **********************************************************************/
export const requestGetRepositoryApplications = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_REPOSITORY_REQUEST,
  });
};

export const receiveGetRepositoryApplications = (response) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_REPOSITORY_SUCCESS,
    applications: response,
  });
};

export const failureGetRepositoryApplications = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_REPOSITORY_FAILURE,
  });
};

export const getRepositoryApplications = filters => (dispatch) => {
  const queryParams = filters && filters.length > 0 ? queryString.stringify({
    filters: JSON.stringify(filters.map(f => ({
      key: f.categorykey,
      op: f.operator,
      val: f.value
    })))
  }) : null;

  return dispatch(
		FWFetch({
    	url: baseUrl + `appstore${queryParams ? '?' + queryParams : ''}`,
    	method: "GET",
    	requestCB: () => {
    		dispatch(requestGetRepositoryApplications());
    	},
    	successCB: response => {
    		dispatch(receiveGetRepositoryApplications(response || []));
    	},
    	failureCB: error => {
    		dispatch(failureGetRepositoryApplications(error));
    	}
    })
	);
};


/**********************************************************************
 *   Get one purchased application
 **********************************************************************/
export const requestGetPurchasedApplication = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_ONE_PURCHASED_REQUEST,
  });
};

export const receiveGetPurchasedApplication = (response) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_ONE_PURCHASED_SUCCESS,
    purchasedApplication: response,
  });
};

export const getPurchasedApplication = id => dispatch => {
  return dispatch(
		FWFetch({
    	url: baseUrl + "appstore/purchased/" + id,
    	method: "GET",
    	requestCB: () => {
        dispatch(requestGetPurchasedApplication())
      },
    	successCB: response => {
    		dispatch(receiveGetPurchasedApplication(response || {}));
    	},
    	failureCB: error => {}
    })
	);
};


/**********************************************************************
 *   Get all purchased applications
 **********************************************************************/

export const requestGetPurchasedApplications = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_PURCHASED_REQUEST,
  });
};

export const receiveGetPurchasedApplications = (response) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_PURCHASED_SUCCESS,
    purchasedApplications: response,
  });
};

export const failureGetPurchasedApplications = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_GET_PURCHASED_FAILURE,
  });
};

export const getPurchasedApplications = (responseType, filters, cb) => dispatch =>  {
  const qParams = {};
  if (responseType) {
    qParams['responseType'] = responseType;
  }

  if (filters && filters.length > 0) {
    qParams['filters'] = JSON.stringify(filters.map(f => ({
      key: f.categorykey,
      op: f.operator,
      val: f.value
    })));
  }

  const queryParams = Object.keys(qParams).length > 0 ? queryString.stringify(qParams) : null;

  return dispatch(
		FWFetch({
    	url: baseUrl + `appstore/purchased${queryParams ? '?' + queryParams : ''}`,
    	method: "GET",
    	requestCB: () => {
    		dispatch(requestGetPurchasedApplications());
    	},
    	successCB: response => {
    		dispatch(receiveGetPurchasedApplications(response || []));
        if (cb) {
          cb(response)
        }
    	},
    	failureCB: error => {
    		dispatch(failureGetPurchasedApplications(error));
    	}
    })
	);
};

/**********************************************************************
 *   Purchase Application
 **********************************************************************/
export const requestPurchaseApplication = () => dispatch => {
  dispatch({
    type: Actions.APPLICATIONS_PURCHASE_REQUEST,
  });
};

export const receivePurchaseApplication = response => dispatch => {
  dispatch({
    type: Actions.APPLICATIONS_PURCHASE_SUCCESS,
    purchasedApplication: response,
  });
};

export const failurePurchaseApplication = error => dispatch => {
  dispatch({
    type: Actions.APPLICATIONS_PURCHASE_FAILURE,
  });
};

export const purchaseApplication = appToPurchased => dispatch => {
  return dispatch(
    FWFetch({
    	url: baseUrl + "appstore/purchase/" + appToPurchased._id,
    	method: "POST",
    	requestCB: () => {
    		dispatch(requestPurchaseApplication());
    	},
    	successCB: response => {
        dispatch(receivePurchaseApplication(response));
    	},
    	failureCB: error => {
    		dispatch(failurePurchaseApplication(error));
    	}
    })
  )
};

/**********************************************************************
 *   Remove application
 **********************************************************************/

export const requestRemoveApplication = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UNINSTALL_REQUEST,
  });
};

export const failureRemoveApplication = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UNINSTALL_FAILURE,
  });
};

export const removeApplication = removedApplication => dispatch => {
  return dispatch(
    FWFetch({
    	url: baseUrl + "appstore/purchased/" + removedApplication._id,
    	method: "DELETE",
    	requestCB: () => {
    		dispatch(requestRemoveApplication());
    	},
    	successCB: response => {
        dispatch(getPurchasedApplications());

        dispatch(
          alertMsgMessage({
            color: "success",
            text: `The app ${removedApplication.name} was successfully removed`,
          })
        );
    	},
    	failureCB: error => {
    		dispatch(failureRemoveApplication(error));
    	}
    })
  );
};

/**********************************************************************
 *   Update application
 **********************************************************************/

export const requestUpdateApplication = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UPDATE_REQUEST,
  });
};

export const receiveUpdateApplication = (response) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UPDATE_SUCCESS,
    purchasedApplication: response,
  });
};

export const failureUpdateApplication = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UPDATE_FAILURE,
  });
};

export const updateApplication = updatedApplication => dispatch => {
  return dispatch(
    FWFetch({
    	url: baseUrl + "appstore/purchased/" + updatedApplication._id,
      method: "PUT",
      body: JSON.stringify(updatedApplication.configuration),
    	requestCB: () => {
    		dispatch(requestUpdateApplication());
    	},
    	successCB: response => {
        dispatch(receiveUpdateApplication(response.application));

        dispatch(mainRedirectTo("/applications/installed"));

        dispatch(
          alertMsgMessage({
            color: "success",
            text: `Configuration updated`,
          })
        );
    	},
    	failureCB: error => {
    		dispatch(failureUpdateApplication(error));
    	}
    })
  );
};

/**********************************************************************
 *   Upgrade application
 **********************************************************************/

export const requestUpgradeApplicationOnDevice = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UPGRADE_REQUEST,
  });
};

export const receiveUpgradeApplicationOnDevice = (response) => (dispatch) => {
  dispatch(
    alertMsgMessage({
      color: "success",
      text: `Application upgrade job added`,
    })
  );
};

export const failureUpgradeApplicationOnDevice = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_UPGRADE_FAILURE,
  });
};

export const upgradeApplicationOnDevice = (app) => dispatch => {
  const { _id } = app;

  return dispatch(
    FWFetch({
      url: baseUrl + "appstore/purchased/" + _id + "/upgrade",
      method: "POST",
      requestCB: () => {
        dispatch(requestUpgradeApplicationOnDevice());
      },
      successCB: response => {
        dispatch(receiveUpgradeApplicationOnDevice(response));
        dispatch(getPurchasedApplications());
      },
      failureCB: error => {
        dispatch(failureUpgradeApplicationOnDevice(error));
      }
    })
  );
};


/**********************************************************************
 *   Get application status
 **********************************************************************/

export const requestGetApplicationStatus = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_STATUS_REQUEST,
  });
};

export const receiveGetApplicationStatus = (data) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_STATUS_SUCCESS,
  });
};

export const failureGetApplicationStatus = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_STATUS_FAILURE,
  });
};

export const getApplicationStatus = (app, cb) => dispatch => {
  const { _id } = app;

  return dispatch(
    FWFetch({
      url: baseUrl + "appstore/purchased/" + _id + "/status",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetApplicationStatus());
      },
      successCB: response => {
        dispatch(receiveGetApplicationStatus(response));
        if (cb) {
          cb(response, null)
        }
      },
      failureCB: error => {
        dispatch(failureGetApplicationStatus(error));
        if (cb) {
          cb(null, error)
        }
      }
    })
  );
};

/**********************************************************************
 *   Perform application action
 **********************************************************************/

export const requestPerformApplicationAction = () => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_ACTION_REQUEST,
  });
};

export const receivePerformApplicationAction = (data) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_ACTION_SUCCESS,
  });
};

export const failurePerformApplicationAction = (error) => (dispatch) => {
  dispatch({
    type: Actions.APPLICATIONS_ACTION_FAILURE,
  });
};

export const performApplicationAction = (app, action, params = {}, cb = null) => dispatch => {
  const { _id } = app;

  return dispatch(
    FWFetch({
      url: baseUrl + "appstore/purchased/" + _id + "/action",
      method: "POST",
      body: JSON.stringify({ action, params }),
      requestCB: () => {
        dispatch(requestPerformApplicationAction());
      },
      successCB: response => {
        dispatch(receivePerformApplicationAction(response));
        if (cb) {
          cb(response, null)
        }
      },
      failureCB: error => {
        dispatch(failurePerformApplicationAction(error));
        if (cb) {
          cb(null, error)
        }
      }
    })
  );
};