import React, { Component } from "react";
import { Prompt } from 'react-router';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Label,
  Card,
  Badge,
  Row,
  Col
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import paginationFactory from "react-bootstrap-table2-paginator";
import PathLabelBadge from "../pathlabels/PathLabelBadge";
import { FWTextInput } from "../Common";
import MultiLinkRuleCard from "./MultiLinkRuleCard";
import MultiLinkRuleOp from "./MultiLinkRuleOp";
import {
  required,
  validatePolicyDesc,
  validatePolicyName,
  minLength,
  maxLength,
  isEmpty
} from "../../utils/Validators";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { store } from "../../App";
import { emptyPolicy } from "../../redux/reducers/MLPolicies";
import { TT, LanguageContext } from '../../containers/Language'
import FwCollapse from '../items/FwCollapse';

class MultiLinkPolicy extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      applications: [],
      appsReverse: [],
      categories: [],
      serviceClasses: [],
      selectedRules: [],
      isRuleModalOpen: false,
      allExpanded: false,
      origData : {}
    };

    this.rulePriority = 0;
    this.ruleToUpdate = {};
    this.saveRuleFunction = {};
    this.ruleKey = this.getRuleRandomKey();

    this.rules = [
      { text: <TT>rowID</TT>, dataField: "_id", hidden: true },
      {
        text: <TT>Priority</TT>,
        dataField: "priority",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "8%", textAlign: "left" };
        }
      },
      {
        text: <TT>Name</TT>,
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return <TT>{row.name}</TT>
        }
      },
      {
        text: <TT>Category</TT>,
        dataField: "rule.classification.application.category",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          const { application } = row.classification;
          return application ? application.category : <TT>N/A</TT>;
        }
      },
      {
        text: <TT>Classification By</TT>,
        dataField: "rule.classification",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          const toTTString = this.context.toTTString;
          const { classification } = row;
          let classifiers = [];
          Object.keys(classification).forEach(key => {
            classifiers.push(toTTString(key));
          });
          return classifiers.join(", ");;
        }
      },
      {
        text: <TT>Action</TT>,
        dataField: "rule.action",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "25%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          const { links } = row.action;
          let labels = [];
          links[0].pathlabels.forEach(label => {
            labels.push(
              <PathLabelBadge
                key={label._id}
                name={label.name}
                color={label.color}
              />
            );
          });
          return (
            <div>
              {labels}
              <Button className="expand-rule-button"><TT>more</TT>...</Button>
            </div>
          );
        }
      },
      {
        text: <TT>Status</TT>,
        dataField: "rule",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "7%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          const { enabled } = row;
          return enabled ? (
            <Badge color="success"><TT>Enabled</TT></Badge>
          ) : (
            <Badge color="danger"><TT>Disabled</TT></Badge>
          );
        }
      },
      {
        text: <TT>Rule Actions</TT>,
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "15%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          const { priority } = row;
          const defaultRule = this.isDefaultRule(row);

          return (
            <div>
              <ReactTooltip id="update-rule">
                  <span><TT>Update rule</TT></span>
                </ReactTooltip>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for="update-rule"
                  size="sm"
                  onClick={() => this.handleUpdateRule(row)}
                >
                  <FontAwesomeIcon icon="cog" fixedWidth />
                </Button>
              <ReactTooltip id="add-above">
                <span><TT>Add rule above</TT></span>
              </ReactTooltip>
              <Button
                className="action-btn"
                data-tip
                data-for="add-above"
                color="success"
                size="sm"
                onClick={() => this.handleAddRule(priority)}
              >
                <FontAwesomeIcon icon={["fas", "level-up-alt"]} fixedWidth />
              </Button>
              {
                !defaultRule ?
                  <React.Fragment>
                    <ReactTooltip id="add-below">
                    <span><TT>Add rule below</TT></span>
                    </ReactTooltip>
                    <Button
                      className="action-btn"
                      color="success"
                      data-tip
                      data-for="add-below"
                      size="sm"
                      onClick={() => this.handleAddRule(priority + 1)}
                    >
                      <FontAwesomeIcon icon={["fas", "level-down-alt"]} fixedWidth />
                    </Button>
                    <ReactTooltip id="delete-rule">
                      <span><TT>Delete rule</TT></span>
                    </ReactTooltip>
                    <Button
                      color="danger"
                      className="action-btn"
                      data-tip
                      data-for="delete-rule"
                      size="sm"
                      onClick={() => this.handleDeleteRule(priority)}
                    >
                      <FontAwesomeIcon icon="trash-alt" fixedWidth />
                    </Button>
                  </React.Fragment>
                  :
                  ""
              }
            </div>
          );
        }
      }
    ];

    this.addRule = this.addRule.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleSwapRules = this.handleSwapRules.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
    this.handleUpdateRule = this.handleUpdateRule.bind(this);
    this.handleSavePolicy = this.handleSavePolicy.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.toggleAddRuleModal = this.toggleAddRuleModal.bind(this);
    this.isDefaultRule = this.isDefaultRule.bind(this);
    this.warnOnLeavingPage = this.warnOnLeavingPage.bind(this);
    this.checkIfDataChanged = this.checkIfDataChanged.bind(this);
  }

  componentDidMount() {
    const {
      id,
      newPolicy,
      getAllPathLabels,
      getAllAppIdentifications,
      getMLPolicy,
      changeForm
    } = this.props;

    getAllPathLabels(resp => {
      this.setState({ labels: resp });
    });

    getAllAppIdentifications(resp => {
      let [apps, appsReverse, categories, serviceClasses] = [
        new Map(),
        new Map(),
        new Map(),
        new Map(),
      ];

      resp.appIdentifications.forEach((ent) => {
        const { name, category, serviceClass, id } = ent;
        apps.set(name, { label: name, value: name, id: id });
        appsReverse.set(id.toString(), name);
        categories.set(category, { label: category, value: category });
        serviceClasses.set(serviceClass, { label: serviceClass, value: serviceClass});
      });

      this.setState({
        applications: apps,
        appsReverse: appsReverse,
        categories: [...categories.values()],
        serviceClasses: [...serviceClasses.values()]
      });

      if (!newPolicy) {
        getMLPolicy(id, resp => {
          // Convert application IDs to names
          const { rules } = resp;
          const { appsReverse } = this.state;
          rules.forEach(rule => {
            const { application } = rule.classification;
            // Convert application name to ID
            if (application) {
              const { appId } = application;
              application.name = !isEmpty(appId) ? appsReverse.get(appId) : "";
              delete application.appId;
            }
          });
          const { name, description, applyOnWan, overrideDefaultRoute } = resp;
          changeForm(
            { name, description, applyOnWan, overrideDefaultRoute },
            { silent: true }
          );
          this.setState({ origData: cloneDeep(resp) });
        });
      } else {
        changeForm(emptyPolicy);
        this.props.mlpolicy.rules = [ ...emptyPolicy.rules ];
        this.setState({ origData: cloneDeep(emptyPolicy) });
      }
    });

    // Listen to page reload event, to warn the user
    // upon leaving the page with unsaved changes
    window.addEventListener('beforeunload', this.warnOnLeavingPage);
  }

  componentWillUnmount(){
    // Remove page reload listener
    window.removeEventListener('beforeunload', this.warnOnLeavingPage);
  }

  checkIfDataChanged() {
    const currentData = this.props.mlpolicy;
    return (
      store.getState().forms.mlpolicy.name.value !== this.state.origData.name ||
      store.getState().forms.mlpolicy.description.value !== this.state.origData.description ||
      !isEqual(this.state.origData, currentData)
    );
  }

  warnOnLeavingPage(event) {
    if (this.checkIfDataChanged()) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  getRuleRandomKey() {
    return Math.floor(Math.random() * 10000);
  }

  toggleAddRuleModal() {
    this.ruleToUpdate = {};
    this.setState({ isRuleModalOpen: !this.state.isRuleModalOpen });
  }

  isDefaultRule(rule) {
    const rules = this.props.mlpolicy.rules;
    return rules && rules.length && rules[rules.length-1].priority === rule.priority
  }

  handleSavePolicy(vals) {
    const { name, description, applyOnWan, overrideDefaultRoute } = vals;
    const rules = [...this.props.mlpolicy.rules];

    const policy = { name, description, applyOnWan, overrideDefaultRoute, rules };

    const {
      newPolicy,
      resetPolicyForm,
      addMLPolicy,
      updMLPolicy
    } = this.props;
    if (newPolicy) {
      addMLPolicy(policy, this.state.applications, () => {
        resetPolicyForm();
        this.setState({ origData: cloneDeep(policy) });
      });
    } else {
      policy._id = this.props.mlpolicy._id;
      updMLPolicy(policy, this.state.applications, () => {
        this.setState({ origData: cloneDeep(policy) });
      });
    }
  }

  handleAddRule(priority) {
    this.saveRuleFunction = this.addRule;
    this.rulePriority = priority;
    this.ruleKey = this.getRuleRandomKey();
    this.setState({ isRuleModalOpen: true });
  }

  handleUpdateRule(row) {
    this.saveRuleFunction = this.updateRule;
    this.ruleToUpdate = { ...row };
    this.rulePriority = row.priority;
    this.ruleKey = this.getRuleRandomKey();
    this.setState({ isRuleModalOpen: true });
  }

  handleDeleteRule(priority) {
    let { rules } = this.props.mlpolicy;

    rules.splice(priority, 1);
    for (let idx = priority; idx < rules.length; idx++) {
      rules[idx].priority--;
    }

    // Clear selected rules to make sure
    // the deleted rule does not remain selected
    this.setState( { selectedRules: [] });
  }

  handleSwapRules(currentIdx, newIdx) {
    const { rules } = this.props.mlpolicy;

    // Update rules priority
    rules[currentIdx].priority = newIdx;
    rules[newIdx].priority = currentIdx;

    // Swap rules
    const tmpRule =  { ...rules[currentIdx] };
    rules[currentIdx] = { ...rules[newIdx] };
    rules[newIdx] = tmpRule;

    this.setState({ selectedRules: [newIdx] });
  }

  handleOnSelect(row, isSelect) {
    const selectedRules = isSelect
      ? [...this.state.selectedRules, row.priority]
      : this.state.selectedRules.filter(priority => priority !== row.priority);

    this.setState(() => ({ selectedRules: selectedRules }));
  }

  handleOnSelectAll(isSelect, rows) {
    const selectedRules = isSelect ? rows.map(rule => rule.priority) : [];
    this.setState({ selectedRules: selectedRules });
  }

  updateRule(rule) {
    const { rules } =  this.props.mlpolicy;
    if (rule.priority < rules.length) {
      rules[rule.priority] = { ...rule };
    }
  }

  addRule(rule) {
    let { rules } = this.props.mlpolicy;
    rule.priority = this.rulePriority;

    rules.splice(rule.priority, 0, rule);
    const updRules = rules.map((r,i) => (i > r.priority)? {...r, priority: r.priority + 1}:r);
    this.props.mlpolicy.rules = updRules;
  }

  MultiLinkAdvancedOptions(){
    return(
        <>
         <Row className="form-group policy-name">
            <Label className="col-md-3 col-form-label d-flex align-items-center justify-content-between" htmlFor="overrideDefaultRoute">
                <TT>Override default route</TT>
                <span className="helpTooltip" data-tip data-for='overrideDefaultRouteTip'></span>
                <ReactTooltip id='overrideDefaultRouteTip'>
                    <span style={{"fontSize": "0.8rem"}}>
                    <TT>When set, policy takes precedence over default route, else, policy will send default route traffic according to default route in the routing table.</TT><br />
                    <TT>Use override on Spoke/Branch sites, and otherwise on Datacenter/HUB site</TT>
                    </span>
                </ReactTooltip>
            </Label>
            <Col>
                <Label className="FWswitch">
                  <Control.checkbox
                    id="overrideDefaultRoute"
                    name="overrideDefaultRoute"
                    model=".overrideDefaultRoute"
                  />{" "}
                  <span className="FWslider round" />
                </Label>
            </Col>
         </Row>
         <Row className="form-group policy-name">
            <Label className="col-md-3 col-form-label d-flex align-items-center justify-content-between" htmlFor="applyOnWan">
                <TT>Apply on WAN inbound</TT>
                <span className="helpTooltip" data-tip data-for='applyOnWanTip'></span>
                <ReactTooltip id='applyOnWanTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    <TT>Apply policy on WAN inbound traffic</TT><br />
                    <TT>by default it is applied only to LAN outbound traffic</TT>
                  </span>
                </ReactTooltip>
            </Label>
            <Col>
                <Label className="FWswitch">
                <Control.checkbox
                    id="applyOnWan"
                    name="applyOnWan"
                    model=".applyOnWan"
                />{" "}
                <span className="FWslider round" />
                </Label>
            </Col>
         </Row>
        </>
    )
}

  render() {
    const toTTString = this.context.toTTString


    this.MLRulesOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("First page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("Next page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "100", value: 100 },
        { text: "200", value: 200 },
        { text: "300", value: 300 }
      ]
    };

    const { rules } = this.props.mlpolicy;
    const { allExpanded } = this.state;
    const selectedRule = this.state.selectedRules[0];
    const { isDefaultRule } = this;
    const {
      newPolicy,
      resetRuleForm,
      resetAppForm,
      resetPrefixForm,
      changeRuleForm,
    } = this.props;

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      clickToExpand: true,
      selected: this.state.selectedRules,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    const expandRow = {
      expanded: allExpanded ? (rules || []).map(rule => rule.priority) : [],
      renderer: row => {
        return (
          <MultiLinkRuleCard
            rule={row}
            isDefault={this.isDefaultRule(row)}
            pathlabels={this.state.labels}
          />
        );
      }
    };

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Policies</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/pathselectionpolicies"><TT>Path Selection Policies</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{newPolicy ? <TT>add</TT> : <TT>update</TT>}</BreadcrumbItem>
        </Breadcrumb>
        <Prompt
          message={(location, action) => {
            if (this.checkIfDataChanged()) {
              return toTTString('You have unsaved changes') + '. ' +
                toTTString('Are you sure you want to leave this page?')
            }
          }}
        />
        <h4><TT>Path Selection Policy</TT></h4>
        <Link to="/pathselectionpolicies">
          <Button color="info" className="back-btn policy-back-button">
            <FontAwesomeIcon icon="arrow-circle-left" fixedWidth />
          </Button>
        </Link>
        <Control.button
          className="btn btn-primary upper-panel-button action-btn-top"
          model="mlpolicy"
          form="mlpolicy"
          type="submit"
          disabled={{ valid: false }}
        >
          {newPolicy ? <TT>Save Policy</TT> : <TT>Save & Update Devices</TT>}
        </Control.button>
        <MultiLinkRuleOp
          key={this.ruleKey}
          addRule={this.saveRuleFunction}
          rule={this.ruleToUpdate}
          isDefault={isDefaultRule(this.ruleToUpdate)}
          apps={this.state.applications}
          categories={this.state.categories}
          serviceClasses={this.state.serviceClasses}
          isOpen={this.state.isRuleModalOpen}
          toggleAddRuleForm={this.toggleAddRuleModal}
          resetRuleForm={resetRuleForm}
          changeRuleForm={changeRuleForm}
          resetAppForm={resetAppForm}
          resetPrefixForm={resetPrefixForm}
          pathLabels={this.state.labels}
        />
        <Form id="mlpolicy" model="mlpolicy" onSubmit={this.handleSavePolicy}>
          <Card className="col-md-8" id="pathPolicyCard">
            <Row className="form-group policy-name">
              <Label md={3} htmlFor="name"><TT>Policy Name</TT></Label>
              <Col>
                <Control.text
                  model=".name"
                  id="name"
                  name="name"
                  placeholder={toTTString("Policy Name")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    required: required,
                    name: validatePolicyName,
                    minLength: minLength(3),
                    maxLength: maxLength(50),
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".name"
                  show="touched"
                  messages={{
                    required: toTTString("Required Field"),
                    name: toTTString("Invalid Policy Name Format"),
                    minLength: toTTString('Length must be at least 3'),
                    maxLength: toTTString('Length must be at most 50'),
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group policy-name">
              <Label md={3} htmlFor="description"><TT>Description</TT></Label>
              <Col>
                <Control.text
                  model=".description"
                  id="description"
                  name="description"
                  placeholder={toTTString("Policy Description")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    description: validatePolicyDesc,
                    maxLength: maxLength(50),
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".description"
                  show="touched"
                  messages={{
                    description: toTTString("Invalid Policy Description Format"),
                    maxLength: toTTString('Length must be at most 50'),
                  }}
                />
              </Col>
            </Row>
            <FwCollapse
                  title={toTTString("Advanced Options")}
                  color="outline-secondary"
                >
                  <this.MultiLinkAdvancedOptions>
                  </this.MultiLinkAdvancedOptions>
            </FwCollapse>
          </Card>
        </Form>
        <Label className="rules-label"><TT>Rules</TT>:</Label>
        <br />
        <ReactTooltip id="add-rule">
          <span><TT>Add rule at the bottom</TT></span>
        </ReactTooltip>
        <Button
          data-tip
          data-for="add-rule"
          className="btn btn-primary policy-rules-buttons"
          disabled={!rules || rules.length === 0}
          onClick={() =>
            // If policy contains a default rule, make
            // sure rules are always added above it.
            this.handleAddRule(
              rules.length > 0
                ? rules.length - 1
                : 0
            )
          }
        >
          <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
        </Button>
        <Button
          className="btn btn-primary policy-rules-buttons"
          disabled={!rules || rules.length === 0}
          onClick={() => this.setState({ allExpanded: !allExpanded })}
        >
          {allExpanded ? <TT>Collapse All</TT> : <TT>Expand All</TT>}
        </Button>
        {
          // Allow reordering of a single rule at a time.
          // Don't allow to changing the order of the default rule.
          this.state.selectedRules.length === 1 &&
          !this.isDefaultRule(rules[selectedRule]) ? (
            <React.Fragment>
              {
                // First rule can only by moved downwards
                selectedRule !== 0 ? (
                  <React.Fragment>
                    <ReactTooltip id="move-rule-up">
                      <span><TT>Move rule up</TT></span>
                    </ReactTooltip>
                    <Button
                      data-tip
                      data-for="move-rule-up"
                      className="btn btn-primary policy-rules-buttons"
                      onClick={() =>
                        this.handleSwapRules(selectedRule, selectedRule - 1)
                      }
                    >
                      <FontAwesomeIcon icon={["fas", "arrow-up"]} fixedWidth />
                    </Button>
                  </React.Fragment>
                ) : (
                  ""
                )
              }
              {
                // Last (non-default) rule can only by moved upwards
                (() => {
                  const lastRuleIdx = rules.length > 1
                    ? rules.length - 2
                    : rules.length - 1
                  return (selectedRule !== lastRuleIdx
                    ? (
                      <React.Fragment>
                        <ReactTooltip id="move-rule-down">
                          <span><TT>Move rule down</TT></span>
                        </ReactTooltip>
                        <Button
                          data-tip
                          data-for="move-rule-down"
                          className="btn btn-primary policy-rules-buttons"
                          onClick={() =>
                            this.handleSwapRules(selectedRule, selectedRule + 1)
                          }
                        >
                          <FontAwesomeIcon
                            icon={["fas", "arrow-down"]}
                            fixedWidth
                          />
                        </Button>
                      </React.Fragment>
                    ) : (
                      ""
                    )
                  )
                })()
              }
            </React.Fragment>
          ) : (
            ""
          )
        }
        <hr />
        <BootstrapTable
          striped
          hover
          condensed
          keyField="priority"
          data={rules || []}
          columns={this.rules}
          pagination={paginationFactory(this.MLRulesOptions)}
          noDataIndication={toTTString("No rules available")}
          defaultSorted={[{ dataField: "priority", order: "asc" }]}
          expandRow={expandRow}
          selectRow={selectRow}
        />
      </React.Fragment>
    );
  }
}

export default MultiLinkPolicy;
