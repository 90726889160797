import React from 'react';
import './Checkbox.css';

const Checkbox = ({label, uid, name, isSelected, onCheckboxChange, ...props}) => (
  <React.Fragment>
    <input
      className="fwcheckbox"
      type="checkbox"
      id={uid}
      name={name || uid}
      checked={isSelected}
      onChange={onCheckboxChange}
      {... props}
    /> 
    <label htmlFor={uid}>
      <span></span>
      {label}
    </label>
  </React.Fragment>
);

export default Checkbox;
