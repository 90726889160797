import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, Button, ModalHeader } from 'reactstrap';
import { closeOverlappingModal } from '../../redux/reducers/Devices';

const OverlappingConfirmationModal = props => {

  const handleStart = () => {
    props.onConfirmed(props.modal.devicesIds)
    props.closeOverlappingModal()
  }

  return (
    <>
      <Modal
        isOpen={props.modal.open}
        toggle={props.closeOverlappingModal}
        size={'lg'}
      >
        <ModalHeader>
          Overlapping Networks
        </ModalHeader>
        <ModalBody>
          <p className='alert alert-warning'>
            {props.modal.message}
          </p>

          <p className='fs-2'>Using overlapping networks makes sense in special cases such as if the routers are in the same site and the networks are configured for VRRP.</p>
          <div className='d-flex justify-content-between'>
            <Button color="success" onClick={handleStart}>Allow</Button>
            <Button color="default" onClick={props.closeOverlappingModal}>Close</Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    modal: state.devices.showOverlappingConfirmation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeOverlappingModal: () => dispatch(closeOverlappingModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlappingConfirmationModal);
