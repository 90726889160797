import React, { Component } from "react";
import TimeSeriesGraph from '../items/TimeSeriesGraph';

/**
 * NetworkTraffic component class
 * Description:
 * Display the network bps and pps graphs for 2 hours backward and refresh every 1 min
 */
class NetworkTraffic extends Component {

  render() {

    const colors = [
      '#4e79a7', '#f28e2c', '#e15759', '#76b7b2', '#59a14f',
      '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab'
    ]

    const lineInfoOptions = (param, type, units, colorNum) => {
      let sumRx = true;
      let sumTx = true;
      if (type && type === 'rx') {
        sumTx = false;
      }
      if (type && type === 'tx') {
        sumRx = false;
      }

      const options = {
        'BPS':{
          'color':colors[colorNum],
          'yval': (d)=> {
            const rx_bps = (d.rx_bps && sumRx)? d.rx_bps:0;
            const tx_bps = (d.tx_bps && sumTx)? d.tx_bps:0;
            return (rx_bps + tx_bps)*8
          },
          'axisLocation': 'right',
          'axisText': 'BPS',
          'grid': 'width',
          'units': units,
        },
        'PPS':{
          'color':colors[colorNum],
          'yval': (d)=> {
            const rx_pps = (d.rx_pps && sumRx)? d.rx_pps:0;
            const tx_pps = (d.tx_pps && sumTx)? d.tx_pps:0;
            return (rx_pps + tx_pps)
          },
          'axisLocation': 'left',
          'axisText': 'PPS',
          'units': units,
        }
      };

      return options[param];
    }

    const lineInfo = {};
    switch (this.props.graphType) {
      case 'rx':
        lineInfo['BPS'] = lineInfoOptions('BPS', 'rx', ' [RX]', 0);
        lineInfo['PPS'] = lineInfoOptions('PPS', 'rx', ' [RX]', 1);
        break;
      case 'tx':
        lineInfo['BPS'] = lineInfoOptions('BPS', 'tx', ' [TX]', 0);
        lineInfo['PPS'] = lineInfoOptions('PPS', 'tx', ' [TX]', 1);
        break;
      case 'rxtx':
      default:
        lineInfo['BPS'] = lineInfoOptions('BPS', 'rxtx', ' [RX+TX]', 0);
        lineInfo['PPS'] = lineInfoOptions('PPS', 'rxtx', ' [RX+TX]', 1);
        break;
      // case 'rx,tx':
      //   lineInfo['RXBPS'] = lineInfoOptions('BPS', 'rx', ' [RX,TX]', 0);
      //   lineInfo['TXBPS'] = lineInfoOptions('BPS', 'tx', ' [RX,TX]', 1);
      //   lineInfo['RXPPS'] = lineInfoOptions('PPS', 'rx', ' [RX,TX]', 2);
      //   lineInfo['TXPPS'] = lineInfoOptions('PPS', 'tx', ' [RX,TX]', 3);
      //   break;
    }

    return (
      <TimeSeriesGraph
        height={300}
        lastUpdate={this.props.lastUpdate}
        traffic={this.props.traffic}
        lineInfo={lineInfo}
        divId={this.props.divId}
      />
    );
  }
}

export default NetworkTraffic;
