import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
import queryString from 'query-string';
import cloneDeep from "lodash/cloneDeep";

const { baseUrl } = window.SERVER_CONFIG;

export const Devices = (
  state = {
    device: null,
    tableInterfaces: [],
    devices: [],
    conf: [],
    routes: [],
    logs: [],
    staticRoutes: [],
    dhcp: [],
    ospf: {},
    advancedRouting: {},
    total: 0,
    ids: [],
    isLoading: false,
    tunnels: [],
    bgp: {},
    routingFilters: [],
    recoveryInfo: null,
    showOverlappingConfirmation: { open: false, message: null, devicesIds: [] }
  },
  action
) => {
  switch (action.type) {
    // Update device
    case Actions.DEVICE_UPD_REQUEST:
      return { ...state };
    case Actions.DEVICE_UPD_SUCCESS:
      return { ...state, device: action.device };
    case Actions.DEVICE_UPD_FAILURE:
      return { ...state };
    // Delete device
    case Actions.DEVICE_DEL_REQUEST:
      return { ...state, device: action.device, isLoading: true };
    case Actions.DEVICE_DEL_SUCCESS:
      return { ...state, isLoading: false };
    case Actions.DEVICE_DEL_FAILURE:
      return { ...state, device: null, isLoading: false };
    // Send device
    case Actions.DEVICE_SND_REQUEST:
      return { ...state };
    case Actions.DEVICE_SND_SUCCESS:
      return { ...state };
    case Actions.DEVICE_SND_FAILURE:
      return { ...state };
    // Get all devices
    case Actions.DEVICE_GETALL_CLEAR:
      return { ...state, devices: [], ids: [], total:0 };
    case Actions.DEVICE_GETALL_IDS_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GETALL_IDS_SUCCESS:
      return { ...state, ids: action.ids, isLoading: false };
    case Actions.DEVICE_GETALL_IDS_FAILURE:
      return { ...state, ids: [], isLoading: false };
    case Actions.DEVICE_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GETALL_SUCCESS:
      return { ...state, devices: action.devices, total: action.total, isLoading: false };
    case Actions.DEVICE_GETALL_FAILURE:
      return { ...state, devices: [], total:0, isLoading: false };
    // Get device
    case Actions.DEVICE_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GET_SUCCESS:
      if (action.device) {
        return { ...state, device: action.device, isLoading: false };
      } else {
        return { ...state, isLoading: false };
      }
    case Actions.DEVICE_GET_FAILURE:
      return { ...state, device: null, isLoading: false };
    // Get device configuration
    case Actions.DEVICE_GET_CONF_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GET_CONF_SUCCESS:
      return { ...state, conf: action.device, isLoading: false };
    case Actions.DEVICE_GET_CONF_FAILURE:
      return { ...state, conf: [], isLoading: false };
    // Get device logs
    case Actions.DEVICE_GET_LOGS_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GET_LOGS_SUCCESS:
      return { ...state, logs: action.device, isLoading: false };
    case Actions.DEVICE_GET_LOGS_FAILURE:
      return { ...state, logs: [], isLoading: false };
    // Get device logs
    case Actions.DEVICE_GET_PACKET_TRACES_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GET_PACKET_TRACES_SUCCESS:
      return { ...state, traces: action.device, isLoading: false };
    case Actions.DEVICE_GET_PACKET_TRACES_FAILURE:
      return { ...state, traces: [], isLoading: false };
    // Get device tunnels
    case Actions.DEVICE_GET_TUNNELS_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GET_TUNNELS_SUCCESS:
      return { ...state, tunnels: action.tunnels, isLoading: false };
    case Actions.DEVICE_GET_TUNNELS_FAILURE:
      return { ...state, tunnels: [], isLoading: false };
    // Get device routes
    case Actions.DEVICE_GET_ROUTES_REQUEST:
      return { ...state, isLoading: true };
    case Actions.DEVICE_GET_ROUTES_SUCCESS:
      return { ...state, routes: action.device, isLoading: false };
    case Actions.DEVICE_GET_ROUTES_FAILURE:
      return { ...state, routes: [], isLoading: false };
    case Actions.SAVE_DEVICE_STATIC_ROUTES:
      return { ...state, staticRoutes: action.staticRoutes };
    case Actions.SAVE_DEVICE_INTERFACES:
      return { ...state, tableInterfaces: action.tableInterfaces };
    // Device DHCP Actions
    case Actions.DEVICE_SAVE_DHCP_REQUEST:
      return { ...state, dhcp: action.dhcp }
    case Actions.DEVICE_SAVE_OSPF_REQUEST:
      return { ...state, ospf: {...action.ospf} }
    case Actions.DEVICE_SAVE_ADVANCED_ROUTING_REQUEST:
      return { ...state, advancedRouting: {...action.advancedRouting} }
    case Actions.DEVICE_SAVE_FIREWALL_RULES:
      return { ...state, firewallRules: action.firewallRules }
    case Actions.DEVICE_SAVE_BGP_REQUEST:
      return { ...state, bgp: {...action.bgp} }
    case Actions.DEVICE_SAVE_ROUTING_FILTERS_REQUEST:
      return { ...state, routingFilters: [...action.routingFilters] }
    case Actions.DEVICE_RESET_CONF_REQUEST:
      return { ...state };
    case Actions.DEVICE_RESET_CONF_SUCCESS:
      return { ...state };
    case Actions.DEVICE_RESET_CONF_FAILURE:
      return { ...state };
    case Actions.DEVICE_SYNC_CONF_REQUEST:
      return { ...state };
    case Actions.DEVICE_SYNC_CONF_SUCCESS:
      return { ...state };
    case Actions.DEVICE_SYNC_CONF_FAILURE:
      return { ...state };
    // Device Generate IKEv2 Actions
    case Actions.DEVICE_GENERATE_IKEV2_REQUEST:
      return { ...state };
    case Actions.DEVICE_GENERATE_IKEV2_SUCCESS:
      return { ...state };
    case Actions.DEVICE_GENERATE_IKEV2_FAILURE:
      return { ...state };
    // Device update coordinates
    case Actions.DEVICE_UPD_COORDS_REQUEST:
      return { ...state, device: {...state.device, coords:action.coords.coords}, isLoading: true };
    case Actions.DEVICE_UPD_COORDS_SUCCESS:
      return { ...state, isLoading: false };
    case Actions.DEVICE_UPD_COORDS_FAILURE:
      return { ...state, isLoading: false };
    case Actions.DEVICE_MODIFY_HARDWARE_REQUEST:
      return { ...state, device: {...state.device, ...action.data}, isLoading: true };
    case Actions.DEVICE_MODIFY_HARDWARE_SUCCESS:
    case Actions.DEVICE_MODIFY_HARDWARE_FAILURE:
      return { ...state, isLoading: false };
    // Device Recovery Info Actions
    case Actions.DEVICE_RECOVERY_INFO_REQUEST:
      return { ...state, recoveryInfo: null, isLoading: true };
    case Actions.DEVICE_RECOVERY_INFO_SUCCESS:
      return { ...state, recoveryInfo:action.recoveryInfo, isLoading: false };
    case Actions.DEVICE_RECOVERY_INFO_FAILURE:
      return { ...state, recoveryInfo:null, isLoading: false };
    // Save Interface Configuration
    case Actions.DEVICE_SAVE_INTERFACE_CONFIGURATION_REQUEST:
      const updatedInterfaces = [...state.tableInterfaces];
      const index = updatedInterfaces.findIndex(i => i._id === action.interfaceId)

      let updated = {};
      if (updatedInterfaces[index].deviceType === 'lte') {
        // spacial update for lte
        updated = { ...updatedInterfaces[index] };
        const configuration = { ...action.configObj };
        if (action.configObj.hasOwnProperty('metric')) {
          updated.metric = action.configObj.metric;
          delete configuration.metric;
        }
        updated.configuration = configuration;
      } else {
        updated = { ...updatedInterfaces[index], configuration: { ...action.configObj } }
      }

      updatedInterfaces[index] = updated;
      return { ...state, tableInterfaces: updatedInterfaces };
    case Actions.DEVICE_SHOW_OVERLAPPING_CONFIRMATION:
      return { ...state, showOverlappingConfirmation: { open: true, message: action.message, devicesIds: action.devicesIds } };
    case Actions.DEVICE_HIDE_OVERLAPPING_CONFIRMATION:
      return { ...state, showOverlappingConfirmation: { open: false, message: null, devicesIds: [] } };
    // case Actions.DEVICE_WIFI_AVAILABLE_NETWORKS_REQUEST:
    // case Actions.DEVICE_WIFI_CONNECT_REQUEST:
    // case Actions.DEVICE_LTE_CONNECT_REQUEST:
      // return { ...state, isLoading: true };
    // case Actions.DEVICE_WIFI_AVAILABLE_NETWORKS_FAILURE:
    // case Actions.DEVICE_WIFI_AVAILABLE_NETWORKS_SUCCESS:
    // case Actions.DEVICE_WIFI_CONNECT_SUCCESS:
    // case Actions.DEVICE_WIFI_CONNECT_FAILURE:
    // case Actions.DEVICE_LTE_CONNECT_FAILURE:
    // case Actions.DEVICE_LTE_CONNECT_SUCCESS:
        // return { ...state, isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Update device
 **********************************************************************/
export const requestUpdDevice = device => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPD_REQUEST,
  });
};

export const receiveUpdDevice = (response, status) => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPD_SUCCESS,
    device: response
  });
  // 200 means that no new job has been added, otherwise 204
  const messageText =
    status === 200 ?
    `Device ${store.getState().deviceInfo.name} is updated, no new job added` :
    `Update device ${
      store.getState().deviceInfo.name
    } job added successfully`;
  dispatch(
    alertMsgMessage({
      color: "success",
      text: messageText
    })
  );
};

export const failureUpdDevice = error => dispatch => {
  if (error?.data?.errorCodes?.includes('LAN_OVERLAPPING')) {
    dispatch({
      type: Actions.DEVICE_SHOW_OVERLAPPING_CONFIRMATION,
      message: error.message,
      devicesIds: []
    });
  } else {
    dispatch({
      type: Actions.DEVICE_UPD_FAILURE
    });
    dispatch(alertMsgMessage({'color':'danger','text':error.message}));
  }
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const updDevice = (success_cb, failure_cb, allowOverlapping = false) => dispatch => {
  const {
    _id,
    name,
    description,
    isApproved,
    deviceSpecificRulesEnabled
  } = store.getState().deviceInfo;

  const interfaces = store.getState().devices.tableInterfaces;
  const prepareInterfaces = interfaces.map(ifcOrig => {
    const ifc = { ...ifcOrig };
    [ifc.IPv4, ifc.IPv4Mask] = ifc.IPv4.split("/");
    [ifc.IPv6, ifc.IPv6Mask] = ifc.IPv6.split("/");
    ifc.isAssigned = ifc.isAssigned === "Yes" ? true : false;
    ifc.type = ifc.type.toUpperCase();
    ifc.routing = ifc.routing.toUpperCase();
    ifc.metric = ifc.metric.toString();
    // No need to send interface parameters if they are not applied
    if (ifc.dhcp === 'yes') {
      delete ifc.IPv4;
      delete ifc.IPv4Mask;
      delete ifc.IPv6;
      delete ifc.IPv6Mask;
      delete ifc.gateway;
    }
    if (ifc.deviceType === 'pppoe') {
      delete ifc.metric;
      delete ifc.mtu;
    }
    // LAN interfaces are not allowed to have path labels
    if (ifc.type === "LAN") ifc.pathlabels = [];
    // WAN gateway is allowed only for WAN interfaces
    if (ifc.type !== "WAN") ifc.gateway = ""
    ifc.qosPolicy = ifc.qosPolicy || null;
    delete ifc.vlans;
    return ifc;
  });

  const staticroutes = store.getState().devices.staticRoutes;
  const dhcp = store.getState().devices.dhcp;

  const ospf = store.getState().devices.ospf;
  const bgp = store.getState().devices.bgp;
  const routingFilters = store.getState().devices.routingFilters;
  const advancedRouting = store.getState().devices.advancedRouting;

  const appIdentifications = store.getState().appIdentifications.appIdentifications;
  const rules = store.getState().devices.firewallRules.map(rule => {
    const { _id, description, priority, enabled, direction, action, interfaces, system } = rule;
    const newRule = { _id, description, priority, enabled, direction, action, interfaces, system };
    if (direction === 'inbound') {
      newRule.inbound = rule.inbound;
      newRule.internalIP = rule.internalIP;
      newRule.internalPortStart = rule.internalPortStart;
    }
    newRule.classification = {};
    const { source, destination, sourceType, destinationType } = rule.classification;
    if (source) {
      if (sourceType === 'trafficId') {
        const appItem = appIdentifications.find( app => app.name === source.appName);
        newRule.classification.source = {
          trafficId: appItem ? appItem.id : source.appName
        }
      } else if (['trafficTags', 'ipPort'].includes(sourceType)) {
        newRule.classification.source = {};
        newRule.classification.source[sourceType] = {};
        for (const attr of Object.keys(source[sourceType])) {
          if (source[sourceType][attr]) {
            newRule.classification.source[sourceType][attr] = source[sourceType][attr];
          }
        }
      }
    }
    if (destination) {
      if (destinationType === 'trafficId') {
        const appItem = appIdentifications.find( app => app.name === destination.appName);
        newRule.classification.destination = {
          trafficId: appItem ? appItem.id : destination.appName
        }
      } else if (['trafficTags', 'ipProtoPort'].includes(destinationType)) {
        newRule.classification.destination = {};
        newRule.classification.destination[destinationType] = {};
        for (const attr of Object.keys(destination[destinationType])) {
          if (destination[destinationType][attr]) {
            newRule.classification.destination[destinationType][attr] = destination[destinationType][attr];
          }
        }
      }
    }
    if (direction === 'lanNat') {
      newRule.classification.source = {
        lanNat: source.lanNat
      };
      newRule.classification.destination = {
        lanNat: destination.lanNat?.match || destination.lanNat?.action ? destination.lanNat : {}
      };
    }
    return newRule;
  });

  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + _id + (allowOverlapping ? '?allowOverlapping=true' : ''),
      method: "PUT",
      doNotShowError: true, // handle it in failureCB
      body: JSON.stringify({
        name: name,
        description: description,
        isApproved: isApproved,
        deviceSpecificRulesEnabled: deviceSpecificRulesEnabled,
        interfaces: prepareInterfaces,
        staticroutes: staticroutes.map(({ _id, ...items }) => items),
        ospf: ospf,
        firewall: { rules },
        dhcp: dhcp.map(({ _id, ...items }) => items),
        bgp: {
          ...bgp,
          neighbors: (bgp.neighbors ?? []).map(({ _id, ...items }) => items)
        },
        routingFilters: cloneDeep(routingFilters).map(l => { // clone to not remove _id in redux itself. only for sending to the server
          if (l?._id?.startsWith('routing_filter_tmp_id_')) {
            delete l._id;
          }

          l.rules = l.rules.map(r => {
            if (r?._id?.startsWith('routing_filter_tmp_id_')) {
              delete r._id;
            }
            return r;
          });

          return l;
        }),
        advancedRouting
      }),

      requestCB: () => {
        dispatch(requestUpdDevice());
      },
      successCB: (response, status) => {
        dispatch(receiveUpdDevice(response, status));
        if (typeof success_cb === 'function') success_cb(response);
      },
      failureCB: error => {
        dispatch(failureUpdDevice(error));
        if (typeof failure_cb === 'function') failure_cb(error);
      }
    })
  );
};

/**********************************************************************
 *   Delete device
 **********************************************************************/
export const requestDelDevice = device => dispatch => {
  dispatch({
    type: Actions.DEVICE_DEL_REQUEST,
    device
  });
};

export const receiveDelDevice = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_DEL_SUCCESS
  });
  //dispatch(getAllDevices());
  //dispatch(mainRedirectTo('/devices'));
  const { device } = store.getState().devices;
  const messageText = device ? `Device ${device.name} deleted successfully`
    : 'Devices deleted successfully';
  dispatch(
    alertMsgMessage({
      color: "success",
      text: messageText
    })
  );
};

export const failureDelDevice = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_DEL_FAILURE
  });

  // this error has specific handling, so no need to show the error message
  if (!error?.data?.errorCodes?.includes('VRRP_INSTALLED_ON_DEVICE')) {
    dispatch(alertMsgMessage({'color':'danger','text':error.message}));
  }
};

export const delDevice = (device, success_cb, error_cb, removeVrrp = null) => dispatch => {
  let fullUrl = baseUrl + "devices/" + device._id;
  if (removeVrrp) {
    fullUrl += '?removeVrrp=true'
  }
  return dispatch(
    FWFetch({
      url: fullUrl,
      method: "DELETE",
      doNotShowError: true, // handle it in failureCB
      requestCB: () => {
        dispatch(requestDelDevice(device));
      },
      successCB: response => {
        dispatch(receiveDelDevice(response));
        success_cb();
      },
      failureCB: error => {
        if (error_cb) {
          error_cb(error);
        }
        dispatch(failureDelDevice(error));
      }
    })
  );
};

export const deleteDevices = (params, success_cb, error_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices",
      method: "DELETE",
      body: JSON.stringify(params),
      doNotShowError: true, // handle it in failureCB
      requestCB: () => {
        dispatch(requestDelDevice(null));
      },
      successCB: response => {
        dispatch(receiveDelDevice(response));
        success_cb();
      },
      failureCB: error => {
        if (error_cb) {
          error_cb(error)
        }
        dispatch(failureDelDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Send message to device
 **********************************************************************/
export const requestSendDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_SND_REQUEST
  });
};

export const receiveSendDevice = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_SND_SUCCESS
  });
};

export const failureSendDevice = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_SND_FAILURE
  });
};

export const sendDevice = (device, success_cb, error_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + device._id + "/send",
      method: "POST",
      body: JSON.stringify({
        entity: device.entity,
        api: "exec_timeout",
        params: {cmd:device.command, timeout:60}
      }),
      requestCB: () => {
        dispatch(requestSendDevice());
      },
      successCB: response => {
        dispatch(receiveSendDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureSendDevice(error));
        error_cb(error);
      }
    })
  );
};

/**********************************************************************
 *   Get all devices
 **********************************************************************/
 export const clearDevices = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_CLEAR
  });
};

export const requestGetAllDevicesIds = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_IDS_REQUEST
  });
};

export const receiveGetAllDevicesIds = (response, total) => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_IDS_SUCCESS,
    ids: response,
    total: total
  });
};

export const failureGetAllDevicesIds = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_IDS_FAILURE
  });
};

export const getAllDevicesIds = (params = {}, success_cb=()=>{}) => dispatch => {
  const queryParams = queryString.stringify({ ...params, response: 'ids' });
  return dispatch(
    FWFetch({
      url: baseUrl + `devices?${queryParams}`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllDevicesIds());
      },
      successCB: (response, status, headers) => {
          dispatch(receiveGetAllDevicesIds(response, headers && headers.get('records-total')));
        if (typeof success_cb === "function") success_cb();
      },
      failureCB: error => {
        dispatch(failureGetAllDevicesIds(error));
      }
    })
  );
};

export const requestGetAllDevices = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_REQUEST
  });
};

export const receiveGetAllDevices = (response, total) => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_SUCCESS,
    devices: response,
    total: total
  });
};

export const failureGetAllDevices = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GETALL_FAILURE
  });
};

export const getAllDevices = (params = {}, success_cb=()=>{}) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + `devices?${queryParams}`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllDevices());
      },
      successCB: (response, status, headers) => {
          dispatch(receiveGetAllDevices(response, headers && headers.get('records-total')));
        if (typeof success_cb === "function") success_cb();
      },
      failureCB: error => {
        dispatch(failureGetAllDevices(error));
      }
    })
  );
};

/**********************************************************************
 *   Get device
 **********************************************************************/
export const requestGetDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_REQUEST
  });
};

export const receiveGetDevice = (response) => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_SUCCESS,
    device: response
  });
};

export const failureGetDevice = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_FAILURE
  });
};

export const getDevice = (deviceId, success_cb=()=>{}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + deviceId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDevice());
      },
      successCB: response => {
        dispatch(receiveGetDevice(response[0]));
        success_cb(response[0]);
      },
      failureCB: error => {
        dispatch(failureGetDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Get device configuration
 **********************************************************************/
export const requestGetDeviceConfig = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_CONF_REQUEST
  });
};

export const receiveGetDeviceConfig = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_CONF_SUCCESS,
    device: response
  });
};

export const failureGetDeviceConfig = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_CONF_FAILURE
  });
};

export const getDeviceConfig = (deviceId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + deviceId + '/configuration',
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDeviceConfig());
      },
      successCB: response => {
        dispatch(receiveGetDeviceConfig(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetDeviceConfig(error));
      }
    })
  );
};

/**********************************************************************
 *   Get device logs
 **********************************************************************/
export const requestGetDeviceLogs = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_LOGS_REQUEST
  });
};

export const receiveGetDeviceLogs = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_LOGS_SUCCESS,
    device: response
  });
};

export const failureGetDeviceLogs = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_LOGS_FAILURE
  });
};

export const getDeviceLogs = (deviceId, params, success_cb, error_cb) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + deviceId + '/logs?' + queryParams,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDeviceLogs());
      },
      successCB: response => {
        dispatch(receiveGetDeviceLogs(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetDeviceLogs(error));
        error_cb(error);
      }
    })
  );
};

/**********************************************************************
 *   Get device packet traces
 **********************************************************************/
export const requestGetDevicePacketTraces = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_PACKET_TRACES_REQUEST
  });
};

export const receiveGetDevicePacketTraces = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_PACKET_TRACES_SUCCESS,
    device: response
  });
};

export const failureGetDevicePacketTraces = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_PACKET_TRACES_FAILURE
  });
};

export const getDevicePacketTraces = (deviceId, params, success_cb) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + deviceId + '/traces?' + queryParams,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDevicePacketTraces());
      },
      successCB: response => {
        dispatch(receiveGetDevicePacketTraces(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetDevicePacketTraces(error));
      }
    })
  );
};

/**********************************************************************
 *   Get device tunnels
 **********************************************************************/
 export const requestGetDeviceTunnels = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_TUNNELS_REQUEST
  });
};

export const receiveGetDeviceTunnels= response => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_TUNNELS_SUCCESS,
    tunnels: response
  });
};

export const failureGetDeviceTunnels = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_TUNNELS_FAILURE
  });
};

export const getDeviceTunnels = (deviceId, success_cb=null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + deviceId + '/tunnels',
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDeviceTunnels());
      },
      successCB: response => {
        dispatch(receiveGetDeviceTunnels(response));
        if (success_cb) {
          success_cb(response);
        }
      },
      failureCB: error => {
        dispatch(failureGetDeviceTunnels(error));
      }
    })
  );
};

/**********************************************************************
 *   Get device routes
 **********************************************************************/
export const requestGetDeviceRoutes = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_ROUTES_REQUEST
  });
};

export const receiveGetDeviceRoutes= response => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_ROUTES_SUCCESS,
    device: response
  });
};

export const failureGetDeviceRoutes = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_ROUTES_FAILURE
  });
};

export const getDeviceRoutes = (deviceId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + deviceId + '/routes',
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDeviceRoutes());
      },
      successCB: response => {
        dispatch(receiveGetDeviceRoutes(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetDeviceRoutes(error));
      }
    })
  );
};

/**********************************************************************
 *   Save device static routes
 **********************************************************************/
export const saveDeviceStaticRoutes = (routes) => ({
  type: Actions.SAVE_DEVICE_STATIC_ROUTES,
  staticRoutes: routes
});

/**********************************************************************
 *   Modify DHCP
 **********************************************************************/
export const saveDHCP = (dhcp) => dispatch => {
  return dispatch({
    type: Actions.DEVICE_SAVE_DHCP_REQUEST,
    dhcp
  });
};

/**********************************************************************
 *   Modify OSPF
 **********************************************************************/
 export const saveOSPF = ospf => dispatch => {
  return dispatch({
    type: Actions.DEVICE_SAVE_OSPF_REQUEST,
    ospf
  });
};

/**********************************************************************
 *   Modify Advanced Routing
 **********************************************************************/
 export const saveAdvancedRouting = advancedRouting => dispatch => {
  return dispatch({
    type: Actions.DEVICE_SAVE_ADVANCED_ROUTING_REQUEST,
    advancedRouting
  });
};

/**********************************************************************
 *   Save device firewall rules
 **********************************************************************/
 export const saveDeviceFirewallRules = (rules) => {
  return ({
    type: Actions.DEVICE_SAVE_FIREWALL_RULES,
    firewallRules: rules
  })
}

/**********************************************************************
 *   Modify BGP
 **********************************************************************/
 export const saveBGP = bgp => dispatch => {
  return dispatch({
    type: Actions.DEVICE_SAVE_BGP_REQUEST,
    bgp
  });
};

/**********************************************************************
 *   Modify Routing filters
 **********************************************************************/
 export const saveRoutingFilters = routingFilters => dispatch => {
  return dispatch({
    type: Actions.DEVICE_SAVE_ROUTING_FILTERS_REQUEST,
    routingFilters
  });
};

/**********************************************************************
 *   Start device to latest config
 **********************************************************************/
export const requestStartDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_START_REQUEST
  });
};

export const receiveStartDevice = (response) => dispatch => {
  const message =  { text: response.message };
  message.color = response.status === 'completed' ? 'success' : 'danger';
  dispatch({
    type: Actions.DEVICE_START_SUCCESS
  });

  dispatch(
    alertMsgMessage(message)
  );
};

export const failureStartDevice = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_START_FAILURE
  });
};

export const startDevice = (device, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + device._id + "/apply",
      method: "POST",
      body: JSON.stringify({ method: "start" }),
      requestCB: () => {
        dispatch(requestStartDevice());
      },
      successCB: response => {
        dispatch(receiveStartDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureStartDevice(error));
      }
    })
  );
};

export const startDevices = (devices, success_cb, allowOverlapping=false) => dispatch => {
  const path = 'devices/apply';
  const body = { method: "start", devices: devices, meta: { } };
  if (allowOverlapping) {
    body.meta.allowOverlapping = allowOverlapping; // include it only if it true.
  }
  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestStartDevice());
      },
      successCB: response => {
        dispatch(receiveStartDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureStartDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Stop device
 **********************************************************************/
export const requestStopDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_STOP_REQUEST
  });
};

export const receiveStopDevice = response => dispatch => {
  const message =  { text: response.message };
  message.color = response.status === 'completed' ? 'success' : 'danger';
  dispatch({
    type: Actions.DEVICE_STOP_SUCCESS
  });
  dispatch(
    alertMsgMessage(message)
  );
};

export const failureStopDevice = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_STOP_FAILURE
  });
};

export const stopDevice = (device, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + device._id + "/apply",
      method: "POST",
      body: JSON.stringify({ method: "stop" }),
      requestCB: () => {
        dispatch(requestStopDevice());
      },
      successCB: response => {
        dispatch(receiveStopDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureStopDevice(error));
      }
    })
  );
};

export const stopDevices = (devices, success_cb) => dispatch => {
  const path = 'devices/apply';
  const body = { method: "stop", devices: devices };
  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestStopDevice());
      },
      successCB: response => {
        dispatch(receiveStopDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureStopDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Create tunnels between devices
 **********************************************************************/
export const requestTunnelDevices = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_TUNNEL_REQUEST
  });
};

export const receiveTunnelDevices = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_TUNNEL_SUCCESS
  });
  const { ids, message } = response;
  const color = `${ids.length > 0 ? "success" : "warning"}`;
  dispatch(alertMsgMessage({ color: color, text: message }));
};

export const failureTunnelDevices = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_TUNNEL_FAILURE
  });
};

export const tunnelDevices = (devices, pathLabels, peerIds = [], topology, hub, options, success_cb = null) => dispatch => {
  const body = {
    method: "tunnels",
    devices: devices,
    meta: {
      pathLabels: pathLabels,
      tunnelType: 'site-to-site',
      topology: topology,
      hub: hub,
      advancedOptions: options
    }
  }

  if (peerIds.length > 0) {
    body.meta.tunnelType = 'peer';
    body.meta.peers = peerIds;
  }
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/apply",
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestTunnelDevices());
      },
      successCB: response => {
        dispatch(receiveTunnelDevices(response));
        if (success_cb){
          success_cb(response);
        }
      },
      failureCB: error => {
        dispatch(failureTunnelDevices(error));
      }
    })
  );
};

/**********************************************************************
 *   Upgrade a device / a list of devices
 **********************************************************************/
export const requestUpgradeDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPGRADE_REQUEST
  });
};

export const receiveUpgradeDevice  = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPGRADE_SUCCESS
  });
  dispatch(alertMsgMessage({ color: "success", text: "Device upgrade job added" }));
};

export const failureUpgradeDevice  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPGRADE_FAILURE
  });
};

export const upgradeDevices = (devices, success_cb) => dispatch => {
  const singleDevice = Object.keys(devices).length === 1;
  const path = 'devices/' + (singleDevice ? `${Object.keys(devices)[0]}/` : '') + 'apply';
  const body = singleDevice ? {method: "upgrade"} : {method: "upgrade", devices: devices};

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestUpgradeDevice());
      },
      successCB: response => {
        dispatch(receiveUpgradeDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureUpgradeDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Schedule upgrade a device / a list of devices
 **********************************************************************/
export const requestSchedDeviceUpgrade = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_SCHED_UPGRADE_REQUEST
  });
};

export const receiveSchedDeviceUpgrade  = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_SCHED_UPGRADE_SUCCESS
  });
  dispatch(alertMsgMessage({ color: "success", text: "Devices upgrade scheduled" }));
};

export const failureSchedDeviceUpgrade  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_SCHED_UPGRADE_FAILURE
  });
};

export const schedDeviceUpgrade = (devices, sched, success_cb) => dispatch => {
  const singleDevice = Object.keys(devices).length === 1;
  const path = `devices/${singleDevice ? `${Object.keys(devices)[0]}/` : ''}upgdSched`;
  const body = singleDevice ? {date: sched} : {date: sched, devices: Object.keys(devices)};

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestSchedDeviceUpgrade());
      },
      successCB: response => {
        dispatch(receiveSchedDeviceUpgrade(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureSchedDeviceUpgrade(error));
      }
    })
  );
};

/**********************************************************************
 *   Upgrade Host OS on device / list of devices
 **********************************************************************/
export const requestUpgradeOsDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPGRADE_OS_REQUEST
  });
};

export const receiveUpgradeOsDevice  = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPGRADE_OS_SUCCESS
  });
  const { ids, message } = response;
  const color = `${ids.length > 0 ? "success" : "warning"}`;
  dispatch(alertMsgMessage({ color: color, text: message }));
};

export const failureUpgradeOsDevice  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPGRADE_OS_FAILURE
  });
};

export const upgradeOsDevices = (devices, success_cb) => dispatch => {
  const singleDevice = Object.keys(devices).length === 1;
  const path = 'devices/' + (singleDevice ? `${Object.keys(devices)[0]}/` : '') + 'apply';
  const body = singleDevice ? {method: "osupgrade"} : {method: "osupgrade", devices: devices};

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestUpgradeOsDevice());
      },
      successCB: response => {
        dispatch(receiveUpgradeOsDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureUpgradeOsDevice(error));
      }
    })
  );
};

/**********************************************************************
 *  Get device latest software version
 **********************************************************************/
export const requestDeviceLatestVer = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_LATEST_VER_REQUEST
  });
};

export const receiveDeviceLatestVer  = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_LATEST_VER_SUCCESS
  });
};

export const failureDeviceLatestVer  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_LATEST_VER_FAILURE
  });
};

export const getDeviceLatestVer = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/latestVersions",
      method: "GET",
      requestCB: () => {
        dispatch(requestDeviceLatestVer());
      },
      successCB: response => {
        dispatch(receiveDeviceLatestVer(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureDeviceLatestVer(error));
      }
    })
  );
};
/**********************************************************************
 *   Save device interfaces
 **********************************************************************/
export const saveDeviceInterfaces = (interfaces) => ({
  type: Actions.SAVE_DEVICE_INTERFACES,
  tableInterfaces: interfaces
});

/**********************************************************************
 *   Install/uninstall a policy on a device/list of devices
 **********************************************************************/
export const requestInstallPolicy = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_POLICY_REQUEST,
  });
};

export const receiveInstallPolicy  = (method, op, response) => dispatch => {
  const { ids } = response;
  dispatch({
    type: Actions.DEVICE_POLICY_SUCCESS
  });

  if (ids.length > 0) {
    dispatch(
      alertMsgMessage({
        color: "success",
        text: `Policy ${op} job${ids.length > 1 ? "s" : ""} added`,
      })
    );
  } else if (op === 'uninstall') {
    const policyType = method === 'mlpolicy' ? 'Path Selection' :
      method === 'qosPolicy' ? 'QoS' : 'Firewall';
    const message =  `${policyType} policy is being uninstalled, no new job added`;
    dispatch(
      alertMsgMessage({
        color: "success",
        text: message,
      })
    );
  }
};

export const failureInstallPolicy  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_POLICY_FAILURE
  });
};

export const installDevPolicy = (devices, policy) => dispatch => {
  const { id, method, op } = policy;
  const singleDevice = Object.keys(devices).length === 1;
  const path =
    `devices/${singleDevice ? `${Object.keys(devices)[0]}/` : ""}apply`;
  let body = {
    method: method,
    meta: {
      id: id,
      op: op
    }
  }

  if (!singleDevice) body.devices = devices;

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestInstallPolicy());
      },
      successCB: response => {
        dispatch(receiveInstallPolicy(method, op, response));
      },
      failureCB: error => {
        dispatch(failureInstallPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Reset device configuration
 **********************************************************************/
export const requestResetDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_RESET_CONF_REQUEST,
  });
};

export const receiveResetDevice  = (response) => dispatch => {
  const message =  { text: response.message };
  message.color = response.status === 'completed' ? 'success' : 'danger';
  dispatch({
    type: Actions.DEVICE_RESET_CONF_SUCCESS
  });
  dispatch(alertMsgMessage(message));
};

/**********************************************************************
 *   Request perform action for interface
 **********************************************************************/
export const performInterfaceAction = (deviceId, interfaceId, data, cb = null, success_msg = null, request_msg = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + `devices/${deviceId}/interfaces/${interfaceId}/action`,
      method: "POST",
      body: JSON.stringify(data),
      requestCB: () => {},
      successCB: (resp) => {
        if (request_msg) {
          dispatch(alertMsgMessage({
            color: "success",
            text: request_msg
          }))
        }

        if (success_msg) {
          dispatch(alertMsgMessage({
            color: "success",
            text: success_msg
          }))
        }
        if (cb) {
          cb(resp, null)
        }
      },
      failureCB: (err) => {
        if (cb) {
          cb(null, err)
        }
      },
      doNotShowError: true
    })
  )
}

/**********************************************************************
 *   Request get status for an interface
 **********************************************************************/
export const requestGetInterfaceStatus = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_INTERFACE_STATUS_REQUEST,
  });
};

export const receiveGetInterfaceStatus  = (response) => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_INTERFACE_STATUS_SUCCESS
  });
};

export const failureGetInterfaceStatus = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GET_INTERFACE_STATUS_FAILURE
  });
};

export const getInterfaceStatus = (deviceId, interfaceId, cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + `devices/${deviceId}/interfaces/${interfaceId}/status?getEdgeData=true`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetInterfaceStatus());
      },
      successCB: response => {
        dispatch(receiveGetInterfaceStatus(response));
        cb(response)
      },
      failureCB: error => {
        dispatch(failureGetInterfaceStatus(error));
        cb(null)
      },
      disableTopBar: true
    })
  )
}

export const failureResetDevice  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_RESET_CONF_FAILURE
  });
};

export const resetDevice = (deviceId) => dispatch => {
  const path = `devices/${deviceId}/apply`;
  const body = { method: "reset" }

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestResetDevice());
      },
      successCB: response => {
        dispatch(receiveResetDevice(response));
      },
      failureCB: error => {
        dispatch(failureResetDevice(error));
      }
    })
  );
};

export const resetDevices = devices => dispatch => {
  const path = 'devices/apply';
  const body = { method: "reset", devices: devices };
  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestResetDevice());
      },
      successCB: response => {
        dispatch(receiveResetDevice(response));
      },
      failureCB: error => {
        dispatch(failureResetDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Sync device configuration
 **********************************************************************/
export const requestSyncDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_SYNC_CONF_REQUEST,
  });
};

export const receiveSyncDevice  = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_SYNC_CONF_SUCCESS
  });

  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Sync device job added",
    })
  );
};

export const failureSyncDevice  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_SYNC_CONF_FAILURE
  });
};

export const syncDevice = (deviceId) => dispatch => {
  const path = `devices/${deviceId}/apply`;
  const body = { method: "sync" }

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestSyncDevice());
      },
      successCB: response => {
        dispatch(receiveSyncDevice());
      },
      failureCB: error => {
        dispatch(failureSyncDevice(error));
      }
    })
  );
};

/**********************************************************************
*  Save Interface Configuration
 **********************************************************************/

export const saveInterfaceConfiguration = (interfaceId, configObj, success_msg) => dispatch => {
  dispatch({
    type: Actions.DEVICE_SAVE_INTERFACE_CONFIGURATION_REQUEST,
    interfaceId,
    configObj
  });

  if (success_msg) {
    dispatch(
      alertMsgMessage({
        color: "success",
        text: success_msg,
      })
    );
  }
}

/**********************************************************************
 *   Get device status
 **********************************************************************/
export const getDeviceStatus = (deviceId, success_cb=()=>{}) => dispatch => {
  const path = `devices/${deviceId}/status`;

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "GET",
      requestCB: () => {},
      successCB: response => {
        success_cb(response);
      },
      failureCB: error => {}
    })
  );
};

/**********************************************************************
 *   Generate IKEv2 Key/Certificate
 **********************************************************************/
export const requestGenerateIKEv2 = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GENERATE_IKEV2_REQUEST,
  });
};

export const receiveGenerateIKEv2  = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_GENERATE_IKEV2_SUCCESS
  });

  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Generate IKEv2 device job added",
    })
  );
};

export const failureGenerateIKEv2  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_GENERATE_IKEV2_FAILURE
  });
};

export const generateIKEv2 = (deviceId) => dispatch => {
  const path = `devices/${deviceId}/apply`;
  const body = { method: "ikev2" }

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestGenerateIKEv2());
      },
      successCB: response => {
        dispatch(receiveGenerateIKEv2());
      },
      failureCB: error => {
        dispatch(failureGenerateIKEv2(error));
      }
    })
  );
};

/**********************************************************************
 *   Update Device Coordinates
 **********************************************************************/
 export const requestUpdCoords = coords => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPD_COORDS_REQUEST,
    coords
  });
};

export const receiveUpdCoords = response => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPD_COORDS_SUCCESS,
    response
  });
};

export const failureUpdCoords = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_UPD_COORDS_FAILURE
  });
};

export const updCoords = (id, coords) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "devices/" + id + '/coords',
      method: "PUT",
      body: JSON.stringify(coords),
      requestCB: () => {
        dispatch(requestUpdCoords(coords));
      },
      successCB: response => {
        dispatch(receiveUpdCoords(response));
      },
      failureCB: error => {
        dispatch(failureUpdCoords(error));
      }
    })
  );
}

/**********************************************************************
 *   Install an application on device / list of devices
 **********************************************************************/
export const requestInstallApplication = () => (dispatch) => {
  dispatch({
    type: Actions.DEVICE_APPLICATION_REQUEST,
  });
};

export const receiveInstallApplication = (response) => (dispatch) => {
  const { ids } = response;
  dispatch({
    type: Actions.DEVICE_APPLICATION_SUCCESS
  });

  if (ids.length > 0) {
    dispatch(
      alertMsgMessage({
        color: "success",
        text: `Application install job${ids.length > 1 ? "s" : ""} added`,
      })
    );
  }
};

export const failureInstallApplication = (error) => (dispatch) => {
  dispatch({
    type: Actions.DEVICE_APPLICATION_FAILURE,
  });
};

export const installApplication = (devices, app, deviceConfiguration) => dispatch => {
  const { id } = app;

  const singleDevice = Object.keys(devices).length === 1;
  const path =
    `devices/${singleDevice ? `${Object.keys(devices)[0]}/` : ""}apply`;

  let body = {
    method: "application",
    meta: {
      id: id,
      op: "install",
      deviceConfiguration
    }
  }

  if (!singleDevice) body.devices = devices;

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestInstallApplication());
      },
      successCB: response => {
        dispatch(receiveInstallApplication(response));
      },
      failureCB: error => {
        dispatch(failureInstallApplication(error));
      }
    })
  );
};

/**********************************************************************
 *   Uninstall an application from device / list of devices
 **********************************************************************/

export const requestUninstallApplicationFromDevice = () => (dispatch) => {
  dispatch({
    type: Actions.DEVICE_APPLICATION_UNINSTALL_REQUEST,
  });
};

export const receiveUninstallApplicationFromDevice = (response) => (dispatch) => {
  const { ids } = response;
  dispatch({
    type: Actions.DEVICE_APPLICATION_UNINSTALL_SUCCESS
  });

  if (ids.length > 0) {
    dispatch(
      alertMsgMessage({
        color: "success",
        text: `Application uninstall job${ids.length > 1 ? "s" : ""} added`,
      })
    );
  }
};

export const failureUninstallApplicationFromDevice = (error) => (dispatch) => {
  dispatch({
    type: Actions.DEVICE_APPLICATION_UNINSTALL_FAILURE,
  });
};

export const uninstallApplicationFromDevice = (devices, app) => dispatch => {
  const { id } = app;

  const singleDevice = Object.keys(devices).length === 1;
  const path =
    `devices/${singleDevice ? `${Object.keys(devices)[0]}/` : ""}apply`;

  let body = {
    method: "application",
    meta: {
      id: id,
      op: "uninstall"
    }
  }

  if (!singleDevice) body.devices = devices;

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestUninstallApplicationFromDevice());
      },
      successCB: response => {
        dispatch(receiveUninstallApplicationFromDevice(response));
      },
      failureCB: error => {
        dispatch(failureUninstallApplicationFromDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Replace devices
 **********************************************************************/
 export const requestReplaceDevice = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_REPLACE_REQUEST
  });
};

export const receiveReplaceDevice = response => dispatch => {
  const message =  { text: response.message };
  message.color = response.status === 'completed' ? 'success' : 'danger';
  dispatch({
    type: Actions.DEVICE_REPLACE_SUCCESS
  });
  dispatch(
    alertMsgMessage(message)
  );
};

export const failureReplaceDevice = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_REPLACE_FAILURE
  });
};

export const replaceDevice = (oldId, newId, success_cb) => dispatch => {
  const path = 'devices/apply';
  const body = {
    method: 'replace',
    devices: { [oldId]: true, [newId]: true },
    meta: { oldId, newId }
  };
  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestReplaceDevice());
      },
      successCB: response => {
        dispatch(receiveReplaceDevice(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureReplaceDevice(error));
      }
    })
  );
};

/**********************************************************************
 *   Modify device hardware
 **********************************************************************/
export const requestModifyDeviceHardware = (data) => dispatch => {
  dispatch({
    type: Actions.DEVICE_MODIFY_HARDWARE_REQUEST,
    data
  });
};

export const receiveModifyDeviceHardware  = (response) => dispatch => {
  dispatch({
    type: Actions.DEVICE_MODIFY_HARDWARE_SUCCESS
  });
  const { ids, message } = response;
  const color = `${ids.length > 0 ? "success" : "warning"}`;
  dispatch(alertMsgMessage({ color: color, text: message }));
};

export const failureModifyDeviceHardware  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_MODIFY_HARDWARE_FAILURE
  });
  const message =  { text: error.message };
  dispatch(alertMsgMessage(message));
};

export const modifyDeviceHardware = (deviceId, data) => dispatch => {
  const path = `devices/${deviceId}/apply`;
  const body = { method: "modifyHardware", meta: { ...data }  }

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "POST",
      body: JSON.stringify(body),
      requestCB: () => {
        dispatch(requestModifyDeviceHardware(data));
      },
      successCB: response => {
        dispatch(receiveModifyDeviceHardware(response));
      },
      failureCB: error => {
        dispatch(failureModifyDeviceHardware(error));
      }
    })
  );
};

/**********************************************************************
 *   Get Device Recovery Info
 **********************************************************************/
export const requestDeviceRecoveryInfo = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_RECOVERY_INFO_REQUEST,
  });
};

export const receiveDeviceRecoveryInfo  = (recoveryInfo) => dispatch => {
  dispatch({
    type: Actions.DEVICE_RECOVERY_INFO_SUCCESS,
    recoveryInfo: recoveryInfo ? JSON.stringify(recoveryInfo) : ''
  });
};

export const failureDeviceRecoveryInfo  = error => dispatch => {
  dispatch({
    type: Actions.DEVICE_RECOVERY_INFO_FAILURE
  });
};

export const getDeviceRecoveryInfo = (deviceId) => dispatch => {
  const path = `devices/${deviceId}/recoveryInfo`;

  return dispatch(
    FWFetch({
      url: baseUrl + path,
      method: "GET",
      requestCB: () => {
        dispatch(requestDeviceRecoveryInfo());
      },
      successCB: response => {
        dispatch(receiveDeviceRecoveryInfo(response));
      },
      failureCB: error => {
        dispatch(failureDeviceRecoveryInfo(error));
      }
    })
  );
};

/**********************************************************************/
/*   Get device BGP Status
/**********************************************************************/
export const getDeviceBgpStatus = (deviceId, fromAgent, success_cb, error_cb) => dispatch => {
  return dispatch(
    FWFetch({
      doNotShowError: true,
      url: baseUrl + "devices/" + deviceId + '/bgp/status' + (fromAgent ? '?getEdgeData=true' : ''),
      method: "GET",
      requestCB: () => {},
      successCB: response => {
        success_cb(response);
      },
      failureCB: error => {
        error_cb(error)
      }
    })
  );
};

/**********************************************************************/
/*  Overlapping modal
/**********************************************************************/
export const closeOverlappingModal = () => dispatch => {
  dispatch({
    type: Actions.DEVICE_HIDE_OVERLAPPING_CONFIRMATION
  })
}
