import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { TT } from '../../containers/Language';
import { generateIKEv2 } from '../../redux/reducers/Devices';

const GenerateIkeButton = props => {
  const [openModal, setOpenModal] = useState(false);


  const onApproved = () => {
    toggleModal()
    props.generateIKEv2(props.deviceId);
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <>
      <ReactTooltip id="generate-ikev2">
        <span><TT>Generate a new IKEv2 Private key and Certificate</TT></span>
      </ReactTooltip>
      <Button
        color="success"
        className="btn btn-primary"
        onClick={toggleModal}
        data-tip
        data-for="generate-ikev2"
      >
        <TT>Generate IKEv2</TT>
      </Button>


      <Modal
        isOpen={openModal}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>
          <TT>Generate IKEv2 Key/Certificate</TT>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <TT>Are you sure you want to generate a new IKEv2 Private Key and Certificate?</TT>
          </div>
          <Button color="danger" onClick={onApproved}>
            <TT>Yes</TT>
          </Button>
          <Button
            className="float-right"
            color="outline-secondary"
            onClick={toggleModal}
          >
            <TT>Cancel</TT>
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    generateIKEv2: deviceId => dispatch(generateIKEv2(deviceId))
  }
}

export default connect(null, mapDispatchToProps)(GenerateIkeButton);