import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Label, Card, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as qs from 'query-string';
import { Control, Form, Errors, actions } from 'react-redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { required, validateStringNoSpaces} from '../../utils/Validators';
import { FWTextInput } from '../Common';
import BootstrapTable from 'react-bootstrap-table-next';
import _uniqueId from 'lodash/uniqueId';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DeviceInfo.css';
import { TT, LanguageContext } from '../../containers/Language';
import RoutingFilterRule from './RoutingFilterRules';
import cloneDeep from 'lodash/cloneDeep';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const RoutingFilter = props => {
  const { toTTString } = useContext(LanguageContext);
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(false)
  const [ruleToUpdate, setRuleToUpdate] = useState(null)

  const { location, routingFilters, changeForm, resetForm, history } = props;

  const handleClose = useCallback(() => {
    resetForm()
    history.push({ search: '?tab=RoutingFilters' })
  }, [resetForm, history])

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    if (queryParams.id) {

      // get the routing filter from redux
      const routingFilter = routingFilters.find(r => r._id === queryParams.id);
      if (routingFilter) {
        setIsUpdateMode(true)
        changeForm(routingFilter);
      }
    }
  }, [location.search, routingFilters, changeForm])

  useEffect(() => {
    return () => {
      resetForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddFilter = routingFilter => {
    const filters = handleAddUpdate(props.routingFilters, routingFilter)
    props.saveRoutingFilters(cloneDeep(filters));
    handleClose()
  }

  const handleAddUpdate = (array, newItem) => {
    const _id = newItem._id || _uniqueId('routing_filter_tmp_id_');
    const arr = cloneDeep(array);
    const idx = arr.findIndex(n => n._id === _id)
    if (idx > -1) { // update
      arr[idx] = newItem;
    } else { // new
      arr.push({...newItem, _id })
    }

    return arr
  }

  const handleAddRule = rule => {
    rule = {...rule};
    const oldRules = props.reduxForm?.rules ?? [];
    if (!rule.priority) {
      const highestPriority = Math.max(...oldRules.map(o => o.priority));
      rule.priority = highestPriority + 1;
    }

    const rules = handleAddUpdate(oldRules, rule)
    props.updateRules(rules);

    // cleanup if it was in update mode
    if (ruleToUpdate) {
      setRuleToUpdate(null);
    }
  }

  const handleDeleteRule = rule => {
    const rules = [...(props.reduxForm?.rules ?? [])];
    const index = rules.findIndex(r => r._id === rule._id);
    if (index > -1) {
      rules.splice(index, 1);
      props.updateRules(rules);
    }
  }

  const onDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    handleSwapRules(source.index, destination.index, source.droppableId);
  }

  const handleSwapRules = (curIdx, newIdx, direction) => {
    const old = (props.reduxForm?.rules ?? []);
    const rules = old.map(rule => {
      if ([curIdx, newIdx].includes(rule.priority)) {
        return { ...rule, priority: rule.priority === newIdx ? curIdx : newIdx }
      } else {
        return { ...rule }
      }
    });
    props.updateRules(rules);
  }

  const tableData = cloneDeep(props.reduxForm?.rules ?? []).sort((r1, r2) => {
    if (r1.route === '0.0.0.0/0') return 1;
    if (r2.route === '0.0.0.0/0') return -1;
    return r1.priority - r2.priority}
  );

  return (
    <React.Fragment>
      <h6>{isUpdateMode ? <TT>Update</TT> : <TT>Add</TT>} <TT>Routing Filter</TT></h6>
      <div className={"general-card-upper-panel d-flex justify-content-between"}>
        <Control.button
          form="routingFilterForm"
          type="submit"
          model="routingFilter"
          disabled={{ valid: false }}
          className={"btn btn-primary action-btn-top upper-panel-button"}
        >
          <TT>Save</TT>
        </Control.button>

        <button
          onClick={handleClose}
          type="button"
          className="btn btn-outline-secondary action-btn-top upper-panel-button"
        >
          <TT>Cancel</TT>
        </button>
      </div>

      <Card>
        <CardBody>
          <Form
            id="routingFilterForm"
            model="routingFilter"
            onSubmit={handleAddFilter}
            validators={{
              '': {
                duplicate: values => {
                  if (values._id) {
                    if (routingFilters.find(n => n._id !== values._id && n.name === values.name)) {
                      return false
                    }
                  } else {
                    if (routingFilters.find(n => n.name === values.name)) {
                      return false
                    }
                  }
                  return true;
                }
              }
            }}
          >
            <Row className="form-group">
              <Label htmlFor="name" md={2}>
                <TT>Name</TT>
              </Label>
              <Col md={5}>
                <Control.text
                  model=".name"
                  id="name"
                  name=".name"
                  placeholder={toTTString("Name")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    required: required,
                    validateStringNoSpaces: validateStringNoSpaces
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".name"
                  show="touched"
                  messages={{
                    required: toTTString('Required field'),
                    validateStringNoSpaces: toTTString('Spaces are not allowed'),
                  }}
                />
                <Errors
                  className="text-danger"
                  model="routingFilter"
                  show="touched"
                  messages={{
                    duplicate: toTTString('Duplicate routing filter name')
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group align-items-center">
              <Label htmlFor="description" md={2}>
                <TT>Description</TT>
              </Label>
              <Col md={5}>
                <Control.text
                  type="text"
                  model=".description"
                  id="description"
                  name="description"
                  placeholder={toTTString("Description")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    required: required
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".description"
                  show="touched"
                  messages={{
                    required: toTTString('Required field')
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-auto">
                <TT>Rules</TT>
              </Col>

              <Col className="pl-0 col-auto">
                <Button
                  color="warning"
                  className="btn btn-primary btn btn-secondary ml-2"
                  data-tip
                  size="sm"
                  onClick={() => {
                    setRuleToUpdate(null);
                    setIsRuleModalOpen(true)
                  }}
                >
                  <TT>Add</TT>
                </Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <div
                  id="device-send-alert"
                  className="alert alert-warning col-md-12"
                  role="alert"
                >
                  <TT>Rules are processed in order, the action of the first matched rule is applied</TT>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="routingFilterRules">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <BootstrapTable
                          keyField="priority"
                          data={tableData}
                          columns={[
                            {
                              text: toTTString("Route"),
                              dataField: "route",
                              headerStyle: (colum, colIndex) => {
                                return { textAlign: "left", verticalAlign: "top", width: '20%' };
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <>
                                  <ReactTooltip id={"drag-and-drop-route-" + row.priority}>
                                    {row.route === '0.0.0.0/0' ? (
                                      <span><TT>0.0.0.0/0 is the default action for all unspecified routes and its order cannot be change</TT></span>
                                    ) : (
                                      <span><TT>Drag and Drop the route</TT></span>
                                    )}
                                  </ReactTooltip>

                                  <Draggable
                                    key={row.priority}
                                    draggableId={row.route}
                                    index={row.priority}
                                    isDragDisabled={row.route === '0.0.0.0/0'}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        data-tip
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        data-for={"drag-and-drop-route-" + row.priority}
                                        ref={provided.innerRef}
                                      >
                                        {cell}
                                      </div>
                                    )}
                                  </Draggable>
                                  </>
                                )
                              }
                            },
                            {
                              text: toTTString("Action"),
                              dataField: "action",
                              headerStyle: (colum, colIndex) => {
                                return { textAlign: "left", verticalAlign: "top", width: '20%' };
                              },
                              formatter: cell => {
                                return cell?.charAt(0).toUpperCase() + cell?.slice(1)
                              }
                            },
                            {
                              text: toTTString("Next Hop"),
                              dataField: "nextHop",
                              headerStyle: (colum, colIndex) => {
                                return { textAlign: "left", verticalAlign: "top", width: '20%' };
                              },
                            },
                            {
                              text: '',
                              dataField: "actions_dummy",
                              isDummyField: true,
                              headerStyle: (colum, colIndex) => {
                                return { textAlign: "left", verticalAlign: "top", width: '20%' };
                              },
                              formatter: (cell, row) => {
                                return (
                                  <>
                                    <Button
                                      color="warning"
                                      className="action-btn"
                                      data-tip
                                      data-for={"setup-rule-" + row._id}
                                      size="sm"
                                      onClick={() => {
                                        setRuleToUpdate(row);
                                        setIsRuleModalOpen(true);
                                      }}
                                    >
                                      <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                                      <ReactTooltip id={"setup-rule-" + row._id}>
                                        <span><TT>Update Rule</TT></span>
                                      </ReactTooltip>
                                    </Button>

                                    { row.route === '0.0.0.0/0' ? null : (
                                      <>
                                        <Button
                                          color="danger"
                                          className="action-btn"
                                          data-tip
                                          data-for={"delete-rule-" + row._id}
                                          size="sm"
                                          onClick={() => handleDeleteRule(row)}
                                        >
                                          <FontAwesomeIcon icon="trash-alt" fixedWidth />
                                        </Button>

                                        <ReactTooltip id={"delete-rule-" + row._id}>
                                          <span><TT>Delete Rule</TT></span>
                                        </ReactTooltip>
                                      </>
                                    )}
                                  </>
                                )
                              }
                            },
                          ]}
                          defaultSorted={[{ dataField: "priority", order: "asc" }]}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Modal isOpen={isRuleModalOpen} toggle={() => setIsRuleModalOpen(!isRuleModalOpen)}>
        <ModalHeader toggle={() => setIsRuleModalOpen(!isRuleModalOpen)}>
          <TT>Add Rule</TT>
        </ModalHeader>
        <ModalBody>
          <RoutingFilterRule
            existingRules={props.reduxForm?.rules ?? []}
            rule={ruleToUpdate}
            onSubmit={handleAddRule}
            onClose={() => setIsRuleModalOpen(false)}
            deviceVersion={props.deviceVersion}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    reduxForm: state.routingFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeForm: vals => dispatch(actions.change('routingFilter', vals)),
    updateRules: rules => dispatch(actions.change('routingFilter.rules', rules)),
    resetForm: () => dispatch(actions.reset('routingFilter'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoutingFilter));
