import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { Breadcrumb, BreadcrumbItem, Badge, Button, ButtonGroup, Modal, Label,
    ModalHeader, ModalBody, Card, CardBody, Input, CardHeader, Container, Row, Col  } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SearchableSelectBox from '../items/SearchableSelectBox';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { rateConversion } from '../../utils/Conversions';
import ConfirmationModal from '../items/ConfirmationModal';
import { loadLocalSettings, saveLocalSettings } from '../../utils/localSettings';
import PathLabelSelectBox from '../items/PathLabelSelectBox';
import Checkbox from '../items/Checkbox';
import Flatpickr from 'react-flatpickr';
import * as qs from 'query-string';
import '../items/structured-filter/lib/react-structured-filter.css'
import StructuredFilter from '../items/structured-filter/lib/main';
import FwCollapse from '../items/FwCollapse';
import TunnelAdvancedOptions from '../items/TunnelAdvancedOptions';

import fullMeshImg from '../../assets/images/TunnelTopologyFullMesh.gif';
import hubAndSpokeImg from '../../assets/images/TunnelTopologyHubAndSpoke.gif';
import peersImg from '../../assets/images/TunnelTopologyPeers.gif';
import DeviceVpnConfiguration from '../applications/apps/remoteVpn/deviceVpnConfigurations';
import './DevicesCards.css';
import 'flatpickr/dist/themes/light.css';
import { getMajorVersion } from "../../utils/DevicesUtils"

import { TT, LanguageContext } from '../../containers/Language'

import {
  clearDevices,
  getAllDevicesIds,
  getAllDevices,
  startDevice,
  startDevices,
  stopDevice,
  stopDevices,
  delDevice,
  deleteDevices,
  tunnelDevices,
  upgradeDevices,
  upgradeOsDevices,
  getDeviceLatestVer,
  schedDeviceUpgrade,
  installDevPolicy,
  resetDevice,
  resetDevices,
  replaceDevice,
  syncDevice,
  installApplication,
  uninstallApplicationFromDevice
} from "../../redux/reducers/Devices";

import {
  getPurchasedApplications,
} from '../../redux/reducers/Applications';

import { getAllPathLabels } from "../../redux/reducers/PathLabels";
import { getMLPoliciesList } from "../../redux/reducers/MLPolicies";
import { getFirewallPoliciesList } from "../../redux/reducers/FirewallPolicies";
import { getQOSPoliciesList } from "../../redux/reducers/QOSPolicies";
import { getAllTunnels, clearTunnels } from '../../redux/reducers/Tunnels';
import { getAllPeers } from '../../redux/reducers/Peers';
import Loading from '../items/Loading';
const { showDeviceLimitAlert, qualifiedAccountsURL } = window.SERVER_CONFIG;


// Prepare device state info
const prepareDeviceState = (device, toTTString) => {
  const approve_info=(device.isApproved)? {"color":"success","txt":"Approved", "print": toTTString('Approved')}:{"color":"danger","txt":"Not Approved", "print": toTTString('Not Approved')};
  const conn_info=(device.isConnected)? {"color":"success","txt":"Connected", "print": toTTString('Connected')}:{"color":"danger","txt":"Not Connected", "print": toTTString('Not Connected')};
  const state=(device.deviceStatus.state === 'running')? {"color":"green","txt":"Running", "print": toTTString('Running')}:
              (device.deviceStatus.state === 'stopped')? {"color":"red","txt":"Not Running", "print": toTTString('Not Running')}:
              (device.deviceStatus.state === 'failed')? {"color":"red","txt":"Failed", "print": toTTString('Failed')}:
              {"color":"inherit","txt":"Pending", "print": toTTString('Pending')};
  // Major version is needed for keeping compatibility with v1
  const agentMajorVersion = getMajorVersion(device.versions.agent);

  const syncStateMap = {
    "synced": { title: toTTString('Synced'), color: 'success' },
    "syncing": { title: toTTString('Syncing'), color: 'warning' },
    "not-synced": { title: toTTString('Not Synced'), color: 'danger' },
    "unknown": { title: toTTString('Sync Pending'), color: 'warning' }
  };
  const syncState = syncStateMap[device.sync.state];

  return {approve_info, conn_info, state, syncState, agentMajorVersion};
}

// Prepare info for device view
const prepareDeviceActionsButtons = (
  device, handleStart, handleStop, handleSyncDevice, handleDelete) => {

  const RConnected = () => {
    return (
      <React.Fragment>
        <ReactTooltip id='start-a'><span><TT>Start Device</TT></span></ReactTooltip>
        <Button
          disabled={
            !device.isConnected ||
            !device.deviceStatus.state === undefined ||
            device.deviceStatus.state === 'running' ||
            device.deviceStatus.state === 'pending'
          }
          color='outline-info' className="action-btn" data-tip data-for='start-a' size="sm"
          onClick={() => handleStart(device)}>
          <FontAwesomeIcon icon="play" fixedWidth />
        </Button>
      </React.Fragment>
    );
  }

  const RStarted = () => {
      return (
        <React.Fragment>
          <ReactTooltip id='stop-a'><span><TT>Stop Device</TT></span></ReactTooltip>
          <Button disabled={device.deviceStatus.state !== 'running'}
            color='outline-danger' className="action-btn" data-tip data-for='stop-a' size="sm"
            onClick={() => handleStop(device)}
          >
            <FontAwesomeIcon icon="stop" fixedWidth />
          </Button>
        </React.Fragment>
      );
  }

  const Sync = () => {
    return (
      <React.Fragment>
        <ReactTooltip id="sync-a">
          <span><TT>Sync Device Config</TT></span>
        </ReactTooltip>
        <Button
          color="outline-success"
          className="action-btn"
          data-tip
          data-for="sync-a"
          size="sm"
          onClick={() => handleSyncDevice(device)}
        >
          <FontAwesomeIcon icon="sync" fixedWidth />
        </Button>
      </React.Fragment>
    );
  };

  const RDelete = () => {
    return (
      <React.Fragment>
        <Button
          color="outline-danger"
          className="device-delete action-btn"
          data-tip
          data-for="delete-a"
          size="sm"
          onClick={() => handleDelete(device)}
        >
          <FontAwesomeIcon icon="trash-alt" fixedWidth />
        </Button>
        <ReactTooltip id="delete-a">
          <span><TT>Delete Device</TT></span>
        </ReactTooltip>
      </React.Fragment>
    );
  }

  return {RConnected, RStarted, Sync, RDelete};
}

const prepareWANIPs = (interfaces) => {
  const assignedWanIfcs = interfaces.filter(i => i.type === 'WAN' && i.isAssigned);
  return `${assignedWanIfcs.slice(0, 2).map(i => i.IPv4).join(';')}${assignedWanIfcs.length > 2 ? '...' : ''}`
}

// Devices filters box
const DevicesFilter = (props) => {
  const { toTTString } = useContext(LanguageContext);
  return (
  <div className="container filter-bar mr-2">
    <StructuredFilter
      className={"search-query form-control"}
      placeholder={
        props.filters.length === 0
          ? toTTString("Filter by device attributes")
          : toTTString("Add filter")
      }
      value={props.filters}
      options={[
        {
          category: toTTString("Device Name"),
          categorykey: "name",
          type: "text",
        },
        {
          category: toTTString("Machine ID"),
          categorykey: "machineId",
          type: "text",
        },
        {
          category: toTTString("Host Name"),
          categorykey: "hostname",
          type: "text",
        },
        {
          category: toTTString("Device Version"),
          categorykey: "versions.device",
          type: "text",
        },
        {
          category: toTTString("Device Status"),
          type: "textoptions",
          categorykey: "deviceStatus.state",
          options: () => {
            return ["running", "stopped", "failed", "pending"];
          },
        },
        {
          category: toTTString("Public IP"),
          categorykey: "PublicIP",
          type: "text",
        },
        {
          category: toTTString("Connection"),
          categorykey: "isConnected",
          type: "textoptions",
          options: () => {
            return ["Connected", "Not Connected"];
          },
        },
        {
          category: toTTString("Device Approval"),
          categorykey: "isApproved",
          type: "textoptions",
          options: () => {
            return ["Approved", "Not Approved"];
          },
        },
        {
          category: toTTString("Sync State"),
          categorykey: "sync.state",
          type: "textoptions",
          options: () => {
            return ['synced', 'syncing', 'not-synced', 'unknown'];
          },
        },
        {
          category: toTTString("Path Label"),
          categorykey: "pathlabels.name",
          type: "textoptionsarray",
          options: () => {
            return props.labels.map((label) => label.name);
          },
        },
        {
          category: toTTString("Policy: Path Selection"),
          categorykey: "policies.multilink.policy.name",
          type: "textoptions",
          options: () => {
            return props.multilinkPolicies.map((policy) => policy.name);
          },
        },
        {
          category: toTTString("Policy: Firewall"),
          categorykey: "policies.firewall.policy.name",
          type: "textoptions",
          options: () => {
            return props.firewallPolicies.map((policy) => policy.name);
          },
        },
        {
          category: toTTString("Policy: QoS"),
          categorykey: "policies.qos.policy.name",
          type: "textoptions",
          options: () => {
            return props.qosPolicies.map((policy) => policy.name);
          },
        },
        {
          category: "Applications",
          categorykey: "applications.app.appStoreApp.name",
          type: "textoptions",
          options: () => {
            return props.applications.map(p => p.appStoreApp.name);
          },
        },
        {
          category: toTTString("CPU cores"),
          categorykey: "cpuInfo.hwCores",
          type: "number",
        },
        {
          category: toTTString("vRouter cores"),
          categorykey: "cpuInfo.vppCores",
          type: "number",
        },
        {
          category: toTTString("Power Saving"),
          categorykey: "cpuInfo.powerSaving",
          type: "textoptions",
          options: () => {
            return ["On", "Off"];
          },
        },
        {
          category: toTTString("Distribution"),
          categorykey: "distro.codename",
          type: "text",
        },
        {
          category: toTTString("VRRP"),
          categorykey: "vrrp.name",
          type: "text",
        },
      ]}
      customClasses={{
        input: "filter-tokenizer-text-input",
        results: "filter-tokenizer-list__container",
        listItem: "filter-tokenizer-list__item",
      }}
      onChange={props.updateFilter}
      operatorSigns={{
        textoptions: {
          "==": "==",
          "!=": "!=",
        },
        text: {
          "!empty": "!n",
          empty: "n",
          "==": "==",
          "!=": "!=",
          contains: "in",
          "!contains": "!in",
        },
        textoptionsarray: {
          "contains": "contains",
          "!contains": "!contains"
        },
      }}
    />
    <FontAwesomeIcon icon="search" fixedWidth />
    <Button
      className="btn-primary filter-box-btn"
      onClick={props.clearFilters}
    >
      <TT>Clear Filters</TT>
    </Button>
  </div>
  )
}

// Device Card
const DeviceCard = (props) => {
  const { toTTString } = useContext(LanguageContext);
  const device = props.device;
  const {approve_info, conn_info, state, syncState, agentMajorVersion} = prepareDeviceState(device, toTTString);

  const {RConnected, RStarted, Sync, RDelete} = prepareDeviceActionsButtons(
    device,
    props.handleStart,
    props.handleStop,
    props.handleSyncDevice,
    props.handleDelete);

  const OpPending = () => {
    if (isDeviceOpPending()) {
      return <div className="ml-1"><Loading size="sm" /></div>;
    } else {
      return null;
    }
  };

  const isDeviceOpPending = () => {
    return device.isConnected && device.deviceStatus.state === "pending";
  };

  const PPS = () => {
    const { toTTString } = useContext(LanguageContext);
    if (device.deviceStatus && device.deviceStatus.ifStats && device.deviceStatus.state === 'running') {
      const reducer = (accumulator, currentValue) =>
        ({'rx_pps':accumulator.rx_pps + currentValue.rx_pps,
          'tx_pps':accumulator.tx_pps + currentValue.tx_pps,
          'rx_bps':accumulator.rx_bps + currentValue.rx_bps * 8,
          'tx_bps':accumulator.tx_bps + currentValue.tx_bps * 8});
      const sum_pkts = Object.values(device.deviceStatus.ifStats).reduce(reducer, {'rx_pps':0,'tx_pps':0,'rx_bps':0,'tx_bps':0});
      return (
        <div className="device-card-pps">
          {toTTString("PPS") + ": " + toTTString("RX") + ": " + rateConversion(sum_pkts.rx_pps, 1, "") + " TX:" + rateConversion(sum_pkts.tx_pps, 1, "")}<br/>
          {toTTString("BPS") + ": " + toTTString("RX") + rateConversion(sum_pkts.rx_bps, 1, "") + " TX:" + rateConversion(sum_pkts.tx_bps, 1, "")}
        </div>);
    } else {
      return (<div className="device-card-pps"><TT>PPS</TT>: <TT>N/A</TT><br/> <TT>BPS</TT>: <TT>N/A</TT></div>);
    }
  }

  return (
    <div className="device-card-div col-md-4">
      <Card className="device-card">
        <CardBody className="device-card-body">
          <CardHeader className="device-card-header">
            <Checkbox
              label={""}
              uid={device._id}
              name={device.name}
              isSelected={props.isSelected}
              onCheckboxChange={props.handleCheckboxChange}
              key={device._id}
            />
            {device.pendingNotifications ? (
              <div className="mr-1">
                <ReactTooltip id={`device-notifications-${device._id}`}>
                  <span>
                    {
                    (device.pendingNotifications === 1) ? <TT params={{notificationsNum: device.pendingNotifications}}>#notificationsNum# notification pending for device</TT> : <TT params={{notificationsNum: device.pendingNotifications}}>#notificationsNum# notifications pending for device</TT>
                    }
                    </span>
                </ReactTooltip>
                <Link to={`/notifications?name=${device.name}`}>
                  <FontAwesomeIcon
                    icon="exclamation-triangle"
                    size="xs"
                    fixedWidth
                    color="#ebc41b"
                    data-tip
                    data-for={`device-notifications-${device._id}`}
                  />
                </Link>
              </div>
            ) : (
              ""
            )}
            <Link className="device-card-name" to={"/devices/deviceinfo/" + device._id + `?tab=${approve_info.txt === "Not Approved" ? "General" : "Interfaces"}`}>
              {device.name === "" ? toTTString("Unknown") : device.name}
            </Link>
          </CardHeader>
          <Container fluid={true}>
            <Row>
              <Col className="device-card-badge-col col-md-8">
                <Badge className="device-card-badge" color={approve_info.color}>
                  {approve_info.print}
                </Badge>
                <Badge className="device-card-badge" color={conn_info.color}>
                  {conn_info.print}
                </Badge>
                {
                  (approve_info.txt !== "Not Approved"
                    && conn_info.txt !== "Not Connected") ?
                    <Badge className="device-card-badge" color={syncState.color} data-tip data-for="sync-a">
                    {syncState.title}
                    </Badge>
                    : null
                }
              </Col>
              <Col className="device-card-actions-col col-md-4">
                <div className="device-card-actions">
                  <RConnected />
                  <RStarted />
                  {
                    (agentMajorVersion >= 2)?<Sync />:null
                  }
                  <RDelete />
                </div>
              </Col>
            </Row>
          </Container>
          <PPS />
          <div className="device-card-status d-flex">
            <span style={{ fontWeight: "normal", marginRight: "0.2rem" }}>
              <TT>vRouter</TT>:
            </span>
            <span style={{ color: state.color }}>{state.print}</span>
            <OpPending />
          </div>
          <div className="device-card-hostname">
            {toTTString("Hostname") + ": " + device.hostname}
          </div>
          <div className="device-card-ips">
            {toTTString("WAN IPs") + `: ${prepareWANIPs(device.interfaces)}`}
          </div>
          <div className="device-card-uid">{toTTString("ID") + ": " + device.machineId}</div>
        </CardBody>
      </Card>
    </div>
  );
};

// Device Card List
const DeviceCardList = (props) => {
  const deviceCardsArray = props.devices
  .map(device => {
    return (
      <DeviceCard
        key={device._id}
        device={device}
        handleDelete={props.handleDelete}
        handleStart={props.handleStart}
        handleStop={props.handleStop}
        handleSyncDevice={props.handleSyncDevice}
        handleCheckboxChange={props.handleCheckboxChange}
        isSelected = {props.checkboxes.hasOwnProperty(device._id) || false}
        />
    )});
  return (
    <div className="device-card-list">
      {deviceCardsArray}
      {props.total > 0 &&
      <div>
        {(props.limit < props.total) ?
        <div>
          <TT params={{limit: props.limit, total: props.total}}>Showing #limit# of #total# devices</TT>
          <Button className='ml-3 link-button' color='link' onClick={props.handleLoadMore}><TT>Load More</TT>...</Button>
        </div>
        :
        <div>
          <TT params={{total: props.total}}>#total# devices in total</TT>
        </div>
        }
      </div>
      }
    </div>
  );
};

// Helper functions to map checkboxes and devices to IDs object {id:true}
const checkboxesToIds = (checkboxes) => {
  return Object.keys(checkboxes).reduce((r, i)=> ({...r, [i]: true}),{});
}
const devicesToIds = (devices) => {
  return devices.reduce((r, d)=> ({...r, [d._id]: true}),{});
}

// Devices component
class Devices extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.clearDevices();
    this.props.getDeviceLatestVer(resp => {
      this.setState({
        deviceLatestVer: resp.versions,
        versionDeadline: resp.versionDeadline,
        distros: resp.distros
      });
    });
    this.setState({ checkboxes: {} });
    this.props.getAllTunnels({ response: 'summary' });
    this.props.getAllPathLabels(resp => {
      this.setState({ labels: resp });
    });
    this.props.getMLPoliciesList(resp =>{
      this.setState({ multilinkPolicies: resp })
    });
    this.props.getFirewallPoliciesList(resp =>{
      this.setState({ firewallPolicies: resp })
    });
    this.props.getQOSPoliciesList(resp =>{
      this.setState({ qosPolicies: resp })
    });

    this.props.getPurchasedApplications('summary', null, resp => {
      // show only applications that they have agent component
      const deviceApps = resp.filter(a => {
        const usedVersion = a.appStoreApp.versions.find(v => v.version === a.installedVersion);
        if ('agent' in (usedVersion?.components ?? {})) {
          return true;
        } else {
          return false;
        }
      })

      this.setState({ purchasedApplications: deviceApps })
    });


    this.props.getAllPeers(resp => {
      this.setState({ peersList: resp })
    })
    if (this.statusTimer === null) {
      console.log("Devices: Setting periodic timer");
      const timer = setInterval(
        function(that) {
          console.log("Devices periodic update");
          // Only update when in focus
          if (document.hasFocus()) {
            that.refreshDevicesPage();
          }
        },
        10000,
        this
      );
      this.statusTimer = timer;
    }
    window.addEventListener("focus", this.updateOnFocus);

    // Load user's local settings
    const { filters, viewType, requestParams } = loadLocalSettings('devices');
    const queryFilters = this.parseQueryFilters(qs.parse(this.props.qparams));
    if (queryFilters.length > 0) {
      this.setState({ filters: queryFilters });
    } else if (filters) {
      this.setState({ filters });
    }
    if (viewType) this.setState({ viewType });
    if (requestParams) {
      requestParams.table.offset = 0;
      this.setState({ requestParams });
    }
    this.setState({ isMounted: true });
  }
  componentWillUnmount() {
    this.props.clearDevices();
    this.props.clearTunnels();
    if (this.statusTimer != null) {
      console.log("Devices: Clearing periodic timer");
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
    window.removeEventListener("focus", this.updateOnFocus);
  }
  shouldComponentUpdate(nextProps, nextState) {
    /*
    console.log("This Props=" + JSON.stringify(this.props));
    console.log("Next Props=" + JSON.stringify(nextProps));
    console.log("This Props Len="+this.props.devices.devices.length + ", Next Props Len="+nextProps.devices.devices.length);
    if (nextProps.devices.devices.length !== this.props.devices.devices.length) return true;
    else return false;
    */
    const changed = !isEqual(nextState, this.state)
      || !isEqual(nextProps.devices, this.props.devices);
    return changed;
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewType } = this.state;
    // Update only for cards, table will update automatically
    if (prevState.viewType !== viewType && viewType === 'cards') {
      this.getFilteredDevices();
    }
  }

  updateOnFocus() {
    console.log("Window got focus, updating");
    if (Date.now() - this.state.lastUpdateTime < 10000) {
      return;
    }
    this.refreshDevicesPage();
    this.setState({ lastUpdateTime: Date.now() });
  }

  // Handle table row selection
  handleOnSelect(row, isSelect) {
    const id = row._id;
    const name = row.name;
    this.setSelectionState(id, name);
  }

  // Handle card selection
  handleCheckboxChange(event) {
    const { id, name } = event.target;
    this.setSelectionState(id, name);
  }

  // Handle load more ...
  handleLoadMore() {
    this.setState(prev => ({
      requestParams: {
        ...prev.requestParams,
        cards: {
          ...prev.requestParams.cards,
          limit: prev.requestParams.cards.limit + 9
        }
      }
    }), this.getFilteredDevices)
  }

  setSelectionState(id, name) {
    let { activeAction } = this.state;
    this.setState(prevState => {
      if (prevState.checkboxes.hasOwnProperty(id)) {
        const { [id]: _, ...otherKeys } = prevState.checkboxes;

        // Clear the device operation if no devices are selected, or
        // not enough devices are selected for the "tunnels" operation
        if (Object.keys(otherKeys).length === 0 ||
           (Object.keys(otherKeys).length < 2 && activeAction === "tunnels")) {
          activeAction = "";
        }

        return {
          checkboxes: otherKeys,
          selectAll: false,
          activeAction: activeAction
        };
      } else {
        const allSelected =
          Object.keys(prevState.checkboxes).length + 1 ===
          +this.props.devices.total
            ? true
            : false;

        // Reset action if all devices were unselected
        if (!allSelected && activeAction === "tunnels") {
          activeAction = ""
        }
        return {
          checkboxes: { ...prevState.checkboxes, [id]: name },
          activeAction: activeAction,
          selectAll: allSelected
        };
      }
    });
  }

  selectAllDevices() {
    if (this.state.selectAll) {
      this.setState({
        checkboxes: {},
        selectAll: false,
        activeAction: ""
      });
    } else {
      this.props.getAllDevicesIds({
        filters: JSON.stringify(
          this.state.filters.map(f => ({
            key: f.categorykey,
            op: f.operator,
            val: this.getFilteredValue(f)
          }))
        )
      }, () => this.setState({
          checkboxes: this.props.devices.ids.reduce(
            (devices, device) => ({ ...devices, [device._id]: device.name }),
            {}
          ),
          selectAll: true
        })
      );
    }
  }

  constructor(props) {
    super(props);

    this.statusTimer = null;
    this.tunnelPathLabels = [];
    this.csvDownload = React.createRef();
    this.downloadURL = null;

    this.state = {
      devices: [],
      isDelModalOpen: false,
      isStopModalOpen: false,
      isUpgradeModalOpen: false,
      isOsUpgradeModalOpen: false,
      isAppConfirmationModalOpen: false,
      isUpValidationModalOpen: false,
      isDevActionsOpen: false,
      activeAction: "",
      delEntity: null,
      stopEntity: null,
      checkboxes: {},
      selectAll: false,
      deviceLatestVer: null,
      upgradeSchedDate: null,
      versionDeadline: null,
      distros: null,
      labels: [],
      filters: [],
      requestParams: {
        cards: {
          sortField: 'name',
          sortOrder: 'asc',
          offset: 0,
          limit: 18
        },
        table: {
          sortField: 'name',
          sortOrder: 'asc',
          offset: 0,
          limit: 10
        }
      },
      isConfirmationModalOpen: false,
      confirmationModalTitle: '',
      confirmationModalQuestion: '',
      confirmationButtonColor: '',
      confirmationModalAction: () => {},
      multilinkPolicies: [],
      firewallPolicies: [],
      qosPolicies: [],
      peersList: [],
      selectedPeers: [],
      selectedPolicy: "",
      selectedPolicyType: "firewallPolicy",
      isLabelListEmpty: true,
      allLabelSelected: false,
      selectedApplication: "",
      purchasedApplications: [],
      allPeersSelected: false,
      tunnelTopology: {label:'Full-Mesh', value:'fullMesh'},
      hubDevice: {},
      sendTunnelOptions: false,
      tunnelOptions: {},
      isReplaceModalOpen: false,
      replaceConfirmation: false,
      oldDevice: {},
      newDevice: {},
      filter: [],
      viewType: 'cards',
      shouldDownloadCsv: false,
      lastUpdateTime: Date.now(),
      isMounted: false  // Render may be called before mount. Make sure mounted is finished
    };

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.toggleStopModal = this.toggleStopModal.bind(this);
    this.toggleTunnelOpen = this.toggleTunnelOpen.bind(this);
    this.toggleUpgradeModal = this.toggleUpgradeModal.bind(this);
    this.toggleOsUpgradeModal = this.toggleOsUpgradeModal.bind(this);
    this.toggleUpgradeValidationModal = this.toggleUpgradeValidationModal.bind(
      this
    );
    this.toggleDevActionsDropDown = this.toggleDevActionsDropDown.bind(this);
    this.changeViewType = this.changeViewType.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.approveStop = this.approveStop.bind(this);
    this.approveUpgrade = this.approveUpgrade.bind(this);
    this.performUpgrade = this.performUpgrade.bind(this);
    this.performOsUpgrade = this.performOsUpgrade.bind(this);
    this.shouldWarnPartialUpgrade = this.shouldWarnPartialUpgrade.bind(this);
    this.handlePolicyInstall = this.handlePolicyInstall.bind(this);
    this.handleApplicationInstall = this.handleApplicationInstall.bind(this);
    this.handleApplicationUninstall = this.handleApplicationUninstall.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.setActiveDevAction = this.setActiveDevAction.bind(this);
    this.handleSyncDevice = this.handleSyncDevice.bind(this);
    this.updateOnFocus = this.updateOnFocus.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.setSelectionState = this.setSelectionState.bind(this);
    this.selectAllDevices = this.selectAllDevices.bind(this);
    this.refreshDevicesPage = this.refreshDevicesPage.bind(this);
    this.handleLabelsChange = this.handleLabelsChange.bind(this);
    this.handlePeersChange = this.handlePeersChange.bind(this);
    this.prepareTunnelLabels = this.prepareTunnelLabels.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.prepareTunnelPeers = this.prepareTunnelPeers.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.parseQueryFilters = this.parseQueryFilters.bind(this);
    this.prepareCSVFile = this.prepareCSVFile.bind(this);
    this.downloadCsvClicked = this.downloadCsvClicked.bind(this);
    this.selectedPolicyTypeModified = this.selectedPolicyTypeModified.bind(this);
    this.handleTunnelTopologyChange = this.handleTunnelTopologyChange.bind(this);
    this.handleHubDeviceSelect = this.handleHubDeviceSelect.bind(this);
    this.toggleApplicationConfirmationModal = this.toggleApplicationConfirmationModal.bind(this);
    this.toggleReplaceModal = this.toggleReplaceModal.bind(this);
    this.handleOldDeviceSelect = this.handleOldDeviceSelect.bind(this);
    this.handleNewDeviceSelect = this.handleNewDeviceSelect.bind(this);
    this.handleReplaceDevice = this.handleReplaceDevice.bind(this);
  }

  toggleTunnelOpen(state, action = 'tunnels') {
    this.setState({
      activeAction: state ? action : "",
      allLabelSelected: false,
      isLabelListEmpty: true,
      tunnelPathLabels: [],
      tunnelOptions: { mtu: '', mssClamp: 'yes', ospfCost: '', ospfArea: '0', routing: 'ospf' },
      selectedPeers: [],
      allPeersSelected: false,
      tunnelTopology: {label:'Full-Mesh', value:'fullMesh'},
      hubDevice: {}
    });
  }

  selectedPolicyTypeModified(selectedPolicyType, activeAction) {
    if (activeAction === 'install-policy' ) {
      this.setState({ selectedPolicy:
        selectedPolicyType === 'firewallPolicy' && this.state.firewallPolicies.length ? this.state.firewallPolicies[0]._id
        : selectedPolicyType === 'qosPolicy' && this.state.qosPolicies.length ? this.state.qosPolicies[0]._id
        : selectedPolicyType === 'mlpolicy' && this.state.multilinkPolicies.length ? this.state.multilinkPolicies[0]._id
        : ''
      });
    } else {
      this.setState({ selectedPolicy: '' });
    }
  }

  setActiveDevAction(action) {
    this.setState({ activeAction: action });
    this.selectedPolicyTypeModified(this.state.selectedPolicyType, action);
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen
    });
  }

  toggleStopModal() {
    this.setState({
      isStopModalOpen: !this.state.isStopModalOpen
    });
  }

  toggleReplaceModal() {
    if (!this.state.isReplaceModalOpen) {
      let [oldId, newId] = Object.keys(this.state.checkboxes);
      if (this.state.checkboxes[oldId] === oldId) {
        [oldId, newId] = [newId, oldId];
      }
      this.setState({
        oldDevice: { label: this.state.checkboxes[oldId], id: oldId },
        newDevice: { label: this.state.checkboxes[newId], id: newId }
      });
    }
    this.setState({
      isReplaceModalOpen: !this.state.isReplaceModalOpen
    });
  }

  handleOldDeviceSelect({ value, label }) {
    if (value !== this.state.oldDevice.id) {
      // swap devices
      this.setState(prev => ({
        oldDevice: { label: label, id: value },
        newDevice: { label: prev.oldDevice.label, id: prev.oldDevice.id }
      }));
    }
  }

  handleNewDeviceSelect({ value, label }) {
    if (value !== this.state.newDevice.id) {
      // swap devices
      this.setState(prev => ({
        newDevice: { label: label, id: value },
        oldDevice: { label: prev.newDevice.label, id: prev.newDevice.id }
      }));
    }
    this.setState({ newDevice: { label: label, id: value } });
  }

  handleReplaceDevice() {
    this.toggleReplaceModal();
    const { oldDevice, newDevice } = this.state;
    this.props.replaceDevice(oldDevice.id, newDevice.id, this.getFilteredDevices);
    this.setState({ checkboxes: {} });
  }

  toggleUpgradeModal() {
    this.setState({
      isUpgradeModalOpen: !this.state.isUpgradeModalOpen
    });
  }

  toggleOsUpgradeModal() {
    this.setState({
      isOsUpgradeModalOpen: !this.state.isOsUpgradeModalOpen
    });
  }

  toggleUpgradeValidationModal() {
    this.setState({
      isUpValidationModalOpen: !this.state.isUpValidationModalOpen
    });
  }

  toggleDevActionsDropDown() {
    this.setState({
      isDevActionsOpen: !this.state.isDevActionsOpen
    });
  }

  viewParametersUpdated() {
    // Save user's local settings
    const { filters, viewType, requestParams } = this.state;
    saveLocalSettings('devices', { filters, viewType, requestParams });
    this.getFilteredDevices();
  }

  changeViewType(newViewType) {
    this.setState({ viewType: newViewType }, () => {
      const { filters, viewType, requestParams } = this.state;
      saveLocalSettings('devices', { filters, viewType, requestParams });
    });
  }

  // Delete and Approve device
  handleDelete(row) {
    this.setState({ delEntity: row, isDelModalOpen: true });
  }
  approveDelete() {
    const deviceId = this.state.delEntity._id;
    const checkboxes = this.state.checkboxes;

    this.toggleDelModal();
    console.log(`Delete clicked for deviceID: ${deviceId}`);

    // Remove device id from checkboxes
    if (checkboxes.hasOwnProperty(deviceId)) {
      delete checkboxes[deviceId];
      if (!Object.getOwnPropertyNames(checkboxes).length) {
        this.setState({ selectAll: false });
      }
    }

    this.props.delDevice(this.state.delEntity, this.getFilteredDevices, (error) => {
      this.handleDeleteDeviceError(error, () => {
        this.props.delDevice(this.state.delEntity, this.getFilteredDevices, null, true);
      });
    });
  }

  handleStart(row) {
    this.props.startDevice(row, this.getFilteredDevices);
  }

  handleStop(row) {
    this.setState({ stopEntity: row, isStopModalOpen: true });
  }

  approveStop(row) {
    this.toggleStopModal();
    this.props.stopDevice(this.state.stopEntity, this.getFilteredDevices);
  }

  handleSyncDevice(row) {
    const { _id, name } = row
    console.log(`Syncing device ${name} (id=${_id})`);
    this.props.syncDevice(_id);
  }

  openConfirmationModal(title, color) {
    this.setState({
      isConfirmationModalOpen: true,
      confirmationModalTitle: title,
      confirmationModalQuestion: 'Please wait...',
      confirmationButtonDisabled: true,
      confirmationButtonColor: color,
      confirmationModalAction: () => {}
    });
  }

  handleStartMany() {
    const toTTString = this.context.toTTString;
    const selectedIds = Object.keys(this.state.checkboxes);
    const numSelected = selectedIds.length;
    this.openConfirmationModal(toTTString('Start devices'), 'success');
    this.props.getAllDevicesIds({
      filters: JSON.stringify([
        { key: '_id', op: 'in', val: selectedIds },
        { key: 'isConnected', op: '==', val: true },
        { key: 'deviceStatus.state', op: '!=', val: 'running' }
      ])
    }, () => {
      if (!this.state.isConfirmationModalOpen) {
        return;
      };
      let question;
      const numFiltered = this.props.devices.ids.length;
      if (numFiltered === 0) {
        this.setState({
          confirmationModalQuestion: <><TT>The selected devices are running already or not connected</TT>.</>
        });
        return;
      } else if (numFiltered < numSelected) {
        question = <><TT>Some devices are running already or not connected</TT>.
          <TT params={{numFiltered: numFiltered, numSelected: numSelected}}>Are you sure to start #numFiltered# of #numSelected# selected devices?</TT></>
      } else if (numFiltered >= numSelected) {
        question = ((numSelected > 1) ? <TT params={{numSelected: numSelected}}>Are you sure to start the selected #numSelected# devices?</TT> : <TT>Are you sure to start the selected device?</TT>)
      };
      this.setState({
        confirmationModalQuestion: question,
        confirmationButtonDisabled: false,
        confirmationModalAction: () => {
          this.props.startDevices(
            devicesToIds(this.props.devices.ids),
            this.getFilteredDevices
          );
          this.setState({
            isConfirmationModalOpen: false,
          })
        }
      });
    });
  }

  handleStopMany() {
    const toTTString = this.context.toTTString;
    const selectedIds = Object.keys(this.state.checkboxes);
    const numSelected = selectedIds.length;
    this.openConfirmationModal(toTTString('Stop devices'), 'danger');
    this.props.getAllDevicesIds({
      filters: JSON.stringify([
        { key: '_id', op: 'in', val: selectedIds },
        { key: 'isConnected', op: '==', val: true },
        { key: 'deviceStatus.state', op: '==', val: 'running' }
      ])
    }, () => {
      if (!this.state.isConfirmationModalOpen) {
        return;
      };
      let question;
      const numFiltered = this.props.devices.ids.length;
      if (numFiltered === 0) {
        this.setState({
          confirmationModalQuestion: <><TT>The selected devices are not running or not connected</TT>.</>
        });
        return;
      } else if (numFiltered < numSelected) {
        question = <><TT>Some devices are running already or not connected</TT>.
          <TT params={{numFiltered: numFiltered, numSelected: numSelected}}>Are you sure to stop #numFiltered# of #numSelected# selected devices?</TT></>
      } else if (numFiltered >= numSelected) {
        question = ((numSelected > 1) ? <TT params={{numSelected: numSelected}}>Are you sure to stop the selected #numSelected# devices?</TT> : <TT>Are you sure to stop the selected device?</TT>)
      };
      this.setState({
        confirmationModalQuestion: question,
        confirmationButtonDisabled: false,
        confirmationModalAction: () => {
          this.props.stopDevices(
            devicesToIds(this.props.devices.ids),
            this.getFilteredDevices
          );
          this.setState({
            isConfirmationModalOpen: false,
          })
        }
      });
    });
  }

  handleResetMany() {
    const toTTString = this.context.toTTString;
    const selectedIds = Object.keys(this.state.checkboxes);
    const numSelected = selectedIds.length;
    this.openConfirmationModal(toTTString('Reset devices'), 'danger');
    this.props.getAllDevicesIds({
      filters: JSON.stringify([
        { key: '_id', op: 'in', val: selectedIds },
        { key: 'isConnected', op: '==', val: true }
      ])
    }, () => {
      if (!this.state.isConfirmationModalOpen) {
        return;
      };
      let question;
      const numFiltered = this.props.devices.ids.length;
      if (numFiltered === 0) {
        this.setState({
          confirmationModalQuestion: <><TT>The selected devices are not connected</TT></>
        });
        return;
      } else if (numFiltered < numSelected) {
        question = <><TT>Some devices are running already or not connected</TT>.
          <TT params={{numFiltered: numFiltered, numSelected: numSelected}}>Are you sure to reset #numFiltered# of #numSelected# selected devices?</TT></>
      } else if (numFiltered >= numSelected) {
        question = ((numSelected > 1) ? <TT params={{numSelected: numSelected}}>Are you sure to reset the selected #numSelected# devices?</TT> : <TT>Are you sure to reset the selected device?</TT>)
      };
      this.setState({
        confirmationModalQuestion: question,
        confirmationButtonDisabled: false,
        confirmationModalAction: () => {
          this.props.resetDevices(
            devicesToIds(this.props.devices.ids),
            this.getFilteredDevices
          );
          this.setState({
            isConfirmationModalOpen: false,
          })
        }
      });
    });
  }

  handleDeleteByIds() {
    const ids = Object.keys(this.state.checkboxes);
    const num = ids.length;
    this.setState({
      isConfirmationModalOpen: true,
      confirmationModalTitle: <TT>Delete devices</TT>,
      confirmationModalQuestion: (num > 1) ? <TT params={{num: num}}>Are you sure to delete the selected #num# devices?</TT> : <TT>Are you sure to delete the selected device?</TT>,
      confirmationButtonColor: 'danger',
      confirmationModalAction: () => {
        this.props.deleteDevices({ ids, removeVrrp: false }, this.getFilteredDevices, (error) => {
          this.handleDeleteDeviceError(error, () => {
            this.props.deleteDevices({ ids, removeVrrp: true }, this.getFilteredDevices)
          });
        });
        this.setState({ isConfirmationModalOpen: false })
      }
    });
  }

  handleDeleteByFilters() {
    const filters = this.state.filters.map(f => ({
      key: f.categorykey,
      op: f.operator,
      val: this.getFilteredValue(f)
    }));
    this.setState({
      isConfirmationModalOpen: true,
      confirmationModalTitle: <TT>Delete devices</TT>,
      confirmationModalQuestion: <TT>Are you sure to delete all devices matching the filter?</TT>,
      confirmationButtonColor: 'danger',
      confirmationModalAction: () => {
        this.props.deleteDevices({ filters, removeVrrp: false }, this.getFilteredDevices, (error) => {
          this.handleDeleteDeviceError(error, () => {
            this.props.deleteDevices({ filters, removeVrrp: true }, this.getFilteredDevices)
          });
        });
        this.setState({ isConfirmationModalOpen: false })
      }
    });
  }

  handleDeleteDeviceError = (error, onConfirm) => {
    if (error?.data?.errorCodes?.includes('VRRP_INSTALLED_ON_DEVICE')) {
      this.setState({
        isConfirmationModalOpen: true,
        confirmationModalTitle: <TT>VRRP Installed</TT>,
        confirmationModalQuestion: (
          <>
              <div className='alert alert-warning'>
                {error.message}
              </div>
              <div className='pl-1 mt-1'>
                Are you sure you want to continue?
              </div>
          </>
        ),
        confirmationButtonColor: 'danger',
        confirmationModalAction: () => {
          onConfirm();
          this.setState({ isConfirmationModalOpen: false })
        }
      });
    }
  }

  handleTunnels() {
    this.toggleTunnelOpen(false);
    this.props.tunnelDevices(
      checkboxesToIds(this.state.checkboxes),
      [...this.state.tunnelPathLabels],
      this.state.selectedPeers,
      this.state.tunnelTopology.value,
      this.state.hubDevice.id || '',
      this.state.sendTunnelOptions ? this.state.tunnelOptions : {}
    );
  }

  handleUpgrade() {
    this.toggleUpgradeModal();
  }

  handleOsUpgrade() {
    this.toggleOsUpgradeModal();
  }

  handlePolicyInstall(op) {
    const policy = {
      id: this.state.selectedPolicy,
      method: this.state.selectedPolicyType,
      op: op
    };

    this.setActiveDevAction("");
    this.props.installDevPolicy(
      checkboxesToIds(this.state.checkboxes),
      policy
    );
  }

  toggleApplicationConfirmationModal() {
    if (this.state.isAppConfirmationModalOpen) {
      this.props.resetForm("remoteVpnDeviceConfiguration")
    }

    this.setState({
      isAppConfirmationModalOpen: !this.state.isAppConfirmationModalOpen
    });
  }

  performInstallApplication = (vals) => {
    const application = {
      id: this.state.selectedApplication
    };

    const devices = this.state.checkboxes;

    this.setActiveDevAction("");
    this.props.installApplication(devices, application, vals)
  }

  handleApplicationInstall() {
    const app = this.state.purchasedApplications.find(a => a._id === this.state.selectedApplication);

    if (app?.appStoreApp?.identifier === 'com.flexiwan.remotevpn') {
      this.toggleApplicationConfirmationModal()
    } else {
      this.performInstallApplication()
    }
  }

  getApplicationModalBody = () => {
    const app = this.state.purchasedApplications.find(a => a._id === this.state.selectedApplication);
    let items = [];

    const getVal = val => {
      // variable is text within ${}, e.g. ${serverPort}
      // we are taking this text and replace it with the same key in the app configuration object
      const matches = val.match(/\${.+?}/g);
      if (matches) {
        for (const match of matches) {
          let confKey = match.substring(2);
          confKey = confKey.substring(0, confKey.length - 1);
          if (confKey in app.configuration) {
            val = val.replace(match, app.configuration[confKey]);
          } else {
            val = 'N/A'
          }
        }
      }
      return val;
    }

    if (app?.appStoreApp?.versions?.[0]?.installWith) {
      const installWith = app?.appStoreApp?.versions?.[0]?.installWith

      if (installWith.hasOwnProperty('linuxApplications')) {
        items.push(
          <>
            <li><b><TT>Linux Applications</TT>:</b></li>
            <ul>
              { installWith.linuxApplications.map(a => <li>{a}</li>) }
            </ul>
            <br />
          </>
        )
      }

      if (installWith.hasOwnProperty('firewallRules')) {
        items.push(<li><b><TT>Firewall Rules</TT>:</b></li>);
        const rules = installWith.firewallRules.map((rule, idx) => {
          return (
            <>
              { idx >= 1 ? <hr /> : null}
              <ul>
                <li><TT>Direction</TT>: {getVal(rule.direction)}</li>
                <li><TT>Destination</TT> Ports: {getVal(rule.destination.ports)}</li>
                <li><TT>Protocols</TT>: {rule.destination.protocols.join(', ')}</li>
                <li><TT>Interfaces</TT>: {rule.interfaces.length === 0 ? 'all' : [...rule.interfaces]}</li>
              </ul>
            </>
          )
        })

        items = items.concat(rules);
      }
    }

    if (app?.appStoreApp?.identifier === 'com.flexiwan.remotevpn') {
      return (
        <>
          {items.length ? (
            <>
              <p><TT>The application will add the following configurations to your device</TT>:</p>
              <ul>{items}</ul>
              <hr />
            </>
          ) : null}

          <DeviceVpnConfiguration
            handleSubmit={vals => {
              this.toggleApplicationConfirmationModal();
              this.performInstallApplication(vals)
              this.setState({ selectedApplication: "" });
            }}
            devicesCount={Object.keys(this.state.checkboxes).length}
            appId={app._id}
          />
        </>
      )
    }

    return <ul>{items}</ul>;
  }

  handleApplicationUninstall() {
    const application = {
      id: this.state.selectedApplication
    };

    const devices = this.state.checkboxes;

    this.setActiveDevAction("");

    this.props.uninstallApplicationFromDevice(devices, application)
  }

  shouldWarnPartialUpgrade() {
    // Check if there are tunnels connecting devices, where not both of the devices
    // where selected/scheduled to be upgraded at the same time. As devices running different
    // routing versions might not work together properly, we should inform the user of such cases.
    if (this.state.selectAll) return false;

    // This check is relevant only if the routing version has been
    // changed in the new software release.
    // In order to check this, we loop over all the device
    // and check wether there is at least one device that is running
    // a router version that is lover that the latest routing version.
    let routerVersionChanged = false;
    for (let device of this.props.devices.devices) {
      if (device.versions.router !== this.state.deviceLatestVer.router) {
        routerVersionChanged = true;
        break;
      }
    }

    // This validation is necessary only if the router version has changed.
    if (!routerVersionChanged) return false;

    // 'selected' is a set of all device IDs of the devices selected in the UI.
    // 'deviceIDs' is an array of pairs of devices connected by each tunnel.
    // Go over all the device pairs and check wether both the devices connected
    // via the tunnel are selected to be upgraded.
    const selected = new Set(Object.keys(this.state.checkboxes));

    let showWarning = false;
    for (const tunnel of this.props.tunnels.tunnels) {
      if (tunnel.peer) {
        continue;
      }

      const edge1 = tunnel.deviceA._id;
      const edge2 = tunnel.deviceB._id;

      if (
        (selected.has(edge1) && !selected.has(edge2)) ||
        (selected.has(edge2) && !selected.has(edge1))
      ) {
        showWarning = true;
        break;
      }
    }

    return showWarning;
  }

  updateFilter(filter) {
    // Clear checkboxes when the filter changes.
    // This is done to make sure operations are
    // done only on selected devices.
    this.setState(prev => ({
      filters: filter,
      requestParams: {
        ...prev.requestParams,
        cards: {
          ...prev.requestParams.cards,
          limit: 18
        },
        table: {
          ...prev.requestParams.table,
          offset: 0
        }
      },
      selectAll: false,
      checkboxes: {}
    }), this.viewParametersUpdated);
  }

  clearFilters() {
    this.setState(prev => ({
      filters: [],
      requestParams: {
        ...prev.requestParams,
        cards: {
          ...prev.requestParams.cards,
          limit: 18
        },
        table: {
          ...prev.requestParams.table,
          offset: 0
        }
      },
      selectAll: false,
      checkboxes: {}
    }), this.viewParametersUpdated);
  }

  onTableChange = (type, newState) => {
    const { sizePerPage, page, sortField, sortOrder } = newState;
    if (sizePerPage !== undefined && page !== undefined) {
      this.setState(prev => ({
        requestParams: {
          ...prev.requestParams,
          table: {
            offset: page * sizePerPage,
            limit: sizePerPage,
            sortField: sortField,
            sortOrder: sortOrder
          }
        }
      }), this.viewParametersUpdated);
    }
  }

  approveUpgrade() {
    this.toggleUpgradeModal();
    if (this.shouldWarnPartialUpgrade()) {
      this.toggleUpgradeValidationModal();
    } else {
      this.performUpgrade();
    }
  }

  performUpgrade() {
    const { isUpValidationModalOpen, activeAction } = this.state;
    if (isUpValidationModalOpen) {
      this.toggleUpgradeValidationModal();
    };

    if (activeAction === "sched-upgrade") {
      this.setActiveDevAction("");
      this.props.schedDeviceUpgrade(
        checkboxesToIds(this.state.checkboxes),
        this.state.upgradeSchedDate,
        this.getFilteredDevices
      );
    } else {
      this.setActiveDevAction("");
      this.props.upgradeDevices(
        checkboxesToIds(this.state.checkboxes),
        this.getFilteredDevices
      );
    }
  }

  performOsUpgrade() {
    this.setActiveDevAction("");
    this.toggleOsUpgradeModal();
    this.props.upgradeOsDevices(
      checkboxesToIds(this.state.checkboxes),
      this.getFilteredDevices
    );
  }

  refreshDevicesPage() {
    this.props.getDeviceLatestVer(resp => {
      this.setState({
        deviceLatestVer: resp.versions,
        versionDeadline: resp.versionDeadline,
        distros: resp.distros
      }, this.getFilteredDevices);
    });
    this.setState({ lastUpdateTime: Date.now() });
  }

  getStartDate() {
    const now = new Date();
    now.setHours(now.getHours() + 1);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  }

  formatDateString(date) {
    if (!date) return "";
    let timeZoneOffset = date.getTimezoneOffset();
    const dateStr = date.toString().split("GMT")[0];
    if (timeZoneOffset === 0) return `${dateStr}(GMT)`;

    // Create the timezone string
    const timeZoneSign = timeZoneOffset < 0 ? "+" : "-";
    timeZoneOffset = Math.abs(timeZoneOffset);
    const timeZone = `${Math.floor(timeZoneOffset / 60)}${
      timeZoneOffset % 60 !== 0 ? `:${timeZoneOffset % 60}` : ""
    }`;
    return `${dateStr}(GMT${timeZoneSign}${timeZone})`;
  }

  handlePeersChange(selectedPeers) {
    let peers = (selectedPeers || []).map(peer => {
      return peer.value;
    });

    // An empty peers list indicates that peers should
    // be created across all possible interfaces
    const allPeersSelected = peers.includes("FFFFFF");
    if (allPeersSelected) {
      peers = this.state.peersList.map(p => p._id)
    }
    this.setState({
      selectedPeers: peers,
      allPeersSelected: allPeersSelected
    })
  }

  handleLabelsChange(selectedOptions) {
    const labels = (selectedOptions || []).map(label => {
      return label.value;
    });
    // An empty labels list indicates that tunnels should
    // be created across all possible path labels
    const allLabelSelected = labels.includes("FFFFFF");
    const tunnelLabels = allLabelSelected ? ["FFFFFF"] : labels;
    this.setState({
      tunnelPathLabels: tunnelLabels,
      isLabelListEmpty: selectedOptions === null,
      allLabelSelected: allLabelSelected
    });
  }

  handleTunnelTopologyChange(opt) {
    if (this.state.tunnelTopology.value !== opt.value) {
      const hubDevice = {};
      this.setState({tunnelTopology:{label:opt.label, value:opt.value}, hubDevice:hubDevice});
    }
  }

  handleHubDeviceSelect(opt) {
    this.setState({hubDevice:{label:opt.label, id:opt.value}});
  }

  prepareTunnelPeers() {
    if (this.state.allPeersSelected) return [];

    const peers = this.state.peersList.map(peer => {
      return {
        _id: peer._id,
        color: "#7a7a7a",
        name: peer.name
      }
    });

    peers.unshift({
      _id: "FFFFFF",
      color: "#7a7a7a",
      name: "ALL"
    });

    return peers;
  }

  prepareTunnelLabels() {
    // Don't present other labels if "ALL"
    // label is selected
    if (this.state.allLabelSelected) return [];

    // DIA labels cannot be used in tunnels
    // so we filter them out from the list of labels
    const labels = this.state.labels.filter(label => {
      return label.type !== "DIA";
    });

    // Add the "All labels" label. This label is used
    // for creating tunnels between all interfaces
    // across all possible path labels
    const allLabel = {
      _id: "FFFFFF",
      color: "#7a7a7a",
      name: "ALL"
    };
    labels.unshift(allLabel);

    return labels;
  }

  parseQueryFilters(queryFilters) {
    const toTTString = this.context.toTTString;
    let filtersArray = [];
    if ("mlpolicy" in queryFilters) {
      filtersArray.push({
        category: toTTString('Policy: Path Selection'),
        operator: "==",
        categorykey: "policies.multilink.policy.name",
        value: queryFilters.mlpolicy
      });
    }
    if ("application" in queryFilters) {
      filtersArray.push({
        category: toTTString("Applications"),
        operator: "==",
        value: queryFilters.application,
        categorykey: 'applications.app.appStoreApp.name'
      });
    }
    if ("firewallpolicy" in queryFilters) {
      filtersArray.push({
        category: toTTString('Policy: Firewall'),
        operator: "==",
        categorykey: "policies.firewall.policy.name",
        value: queryFilters.firewallpolicy
      });
    }
    if ("qosPolicy" in queryFilters) {
      filtersArray.push({
        category: toTTString('Policy: QoS'),
        operator: "==",
        categorykey: "policies.qos.policy.name",
        value: queryFilters.qosPolicy
      });
    }
    if ("vrrp" in queryFilters) {
      filtersArray.push({
        category: toTTString('VRRP'),
        operator: "==",
        categorykey: "vrrp.name",
        value: queryFilters.vrrp
      });
    }
    return filtersArray;
  }

  getFilteredValue = (filter) => {
    switch (filter.categorykey) {
      case 'isApproved':
        return filter.value === 'Approved';
      case 'isConnected':
        return filter.value === 'Connected';
      case 'cpuInfo.powerSaving':
        return filter.value === 'On';
      case 'cpuInfo.hwCores':
      case 'cpuInfo.vppCores':
        return +filter.value;
      default:
        return filter.value;
    }
  }

  getFilteredDevices = () => {
    this.props.getAllDevices({
      ...this.state.requestParams[this.state.viewType],
      response: 'summary',
      filters: JSON.stringify(
        this.state.filters.map(f => ({
          key: f.categorykey,
          op: f.operator,
          val: this.getFilteredValue(f)
        }))
      )
    })
  }

  getFilteredIds = (cb) => {
    this.props.getAllDevices({
      response: 'ids',
      filters: JSON.stringify(
        this.state.filters.map(f => ({
          key: f.categorykey,
          op: f.operator,
          val: this.getFilteredValue(f)
        }))
      )
    }, cb)
  }

  prepareCSVFile(devices) {
    const toTTString = this.context.toTTString
    const header = "Name,Status,Approved,Connected, Sync Status,Version,Description,Hostname,WAN IPs,ID"
    const devData = devices.map((d) => {
      const {approve_info, conn_info, state, syncState} = prepareDeviceState(d, toTTString);
      const wanIPs = prepareWANIPs(d.interfaces);
      return `${d.name || toTTString('Unknown')},${state.print},${approve_info.print},${conn_info.print},${syncState.title},${
        d.versions.device},${d.description},${d.hostname},${wanIPs},${d.machineId}`;
    })
    devData.unshift(header);
    const data = [devData.join('\n')];
    const properties = { type: 'text/plain' };
    let file;
    // Try using File but fallback to Blob
    try {
      file = new File(data, 'flexiWAN-Devices.csv', properties);
    } catch (e) {
      file = new Blob(data, properties);
    }
    this.downloadURL = URL.createObjectURL(file);
    return this.downloadURL;
  }
  // Called when clicking on csv download
  // This function set state to generate the hidden button with the file to download
  // Then it calls to click on it to download the file
  // This is done to prevent creating the csv file on every render
  downloadCsvClicked() {
    this.setState({shouldDownloadCsv:true});
    setTimeout(()=>{
      this.csvDownload.current.click();
      this.setState({shouldDownloadCsv:false});
      if (this.downloadURL) URL.revokeObjectURL(this.downloadURL);
    },500);
  }

  // Return date string in format ddMMYYYY-hhmm
  getDateString = () => {
    const date = new Date();
    const year = date.toLocaleString('en-US',{ year:'numeric' });
    const month = date.toLocaleString('en-US',{ month:'2-digit' });
    const day = date.toLocaleString('en-US',{ day:'2-digit' });
    const hour = date.toLocaleString('en-GB',{ hour:'2-digit', hour12:false });
    const min = date.toLocaleString('en-US',{ minute:'2-digit' });
    return `${day}${month}${year}-${hour}${min}`;
  }

  setTunnelOption = (option, value) => this.setState(prev => ({
    ...prev,
    tunnelOptions: {
      ...prev.tunnelOptions,
      [option]: value
    }
  }));

  tunnelOptionsValid = () => {
    const { mtu, ospfCost } = this.state.tunnelOptions;
    return ((mtu === '' || (!isNaN(mtu) && mtu >= 500 && mtu <= 1500)) && !isNaN(ospfCost));
  }

  render() {
    const toTTString = this.context.toTTString
    const numSelected = Object.keys(this.state.checkboxes).length;
    const tunnelsPathLabels = this.prepareTunnelLabels();
    const devices = this.props.devices.devices || [];
    const totalSize = +this.props.devices.total;
    const tunnelsPeers = this.prepareTunnelPeers();

    let columns, paginationOptions, selectRow;
    if (this.state.viewType === 'table') {
      columns = [
        {text: toTTString("rowID"), dataField: "_id", hidden: true},
        {
          text: toTTString("Name"),
          dataField: "name",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "130px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            return(
              <div className="device-card-name" style={{marginTop:'0px'}}>
                <Link to={"/devices/deviceinfo/" + row._id + '?tab=General'}>
                  {row.name === "" ? toTTString("Unknown") : row.name}
                </Link>
              </div>
            )
          }
        },
        {
          text: toTTString("Status"),
          dataField: "deviceStatus.state",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "130px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            const {state} = prepareDeviceState(row, toTTString);
            return(
              <span style={{ color: state.color }}>{state.print}</span>
            )
          }
        },
        {
          text: toTTString("Approved"),
          dataField: "isApproved",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "130px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            const {approve_info} = prepareDeviceState(row, toTTString);
            return (
              <Badge className="device-card-badge" color={approve_info.color}>
              {approve_info.print}
            </Badge>);
          }
        },
        {
          text: toTTString("Connected"),
          dataField: "isConnected",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "140px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            const {conn_info} = prepareDeviceState(row, toTTString);
            return (
              <Badge className="device-card-badge" color={conn_info.color}>
              {conn_info.print}
              </Badge>);
          }
        },
        {
          text: toTTString("Sync"),
          dataField: "sync.state",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "120px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            const {approve_info, conn_info, syncState} = prepareDeviceState(row, toTTString);
            return (
              (approve_info.txt !== "Not Approved"
                && conn_info.txt !== "Not Connected") ?
                  <Badge className="device-card-badge" color={syncState.color}>
                  {syncState.title}
                  </Badge>
                  : null
            );
          }
        },
        {
          text: toTTString('Actions'),
          dataField: 'none',
          sort:false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "bottom", minWidth: "150px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            const {agentMajorVersion} = prepareDeviceState(row, toTTString);
            const {RConnected, RStarted, Sync, RDelete} = prepareDeviceActionsButtons(
              row,
              this.handleStart,
              this.handleStop,
              this.handleSyncDevice,
              this.handleDelete);
            return (
              <div className="device-card-actions-table">
                <RConnected />
                <RStarted />
                {
                  (agentMajorVersion >= 2)?<Sync />:null
                }
                <RDelete />
              </div>
            );
          },
        },
        {
          text: toTTString("Version"),
          dataField: "versions.device",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "110px" };
          }
        },
        {
          text: toTTString("Hardware"),
          dataField: "hardware",
          sort: false,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "150px" };
          },
          formatter: (cellContent, { versions, cpuInfo }, rowIndex) => {
            const agentMajorVersion = getMajorVersion(versions.agent);
            if (!cpuInfo || agentMajorVersion < 6) {
              return 'N/A';
            }
            return<small>
              {`CPU cores: ${cpuInfo.hwCores}, vRouter cores: ${cpuInfo.vppCores}, Power Saving: ${cpuInfo.powerSaving ? 'On' : 'Off'}`}
            </small>
          }
        },
        {
          text: toTTString("Desc") + ".",
          dataField: "description",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "110px" };
          }
        },
        {
          text: toTTString("Hostname"),
          dataField: "hostname",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "130px" };
          }
        },
        {
          text: toTTString("WAN IPs"),
          dataField: "ipList",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "95px" };
          },
          formatter: (cellContent, row, rowIndex) => {
            return (
              <React.Fragment>
                {prepareWANIPs(row.interfaces)}
              </React.Fragment>
            );
          },
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            const valA = prepareWANIPs(rowA.interfaces);
            const valB = prepareWANIPs(rowB.interfaces);
            if (order === 'asc') {
              return valA.localeCompare(valB);
            }
            return valB.localeCompare(valA); // desc
          }
        },
        {
          text: toTTString("ID"),
          dataField: "machineId",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "200px" };
          }
        },
        {
          text: toTTString("S/N"),
          dataField: "serial",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "150px" };
          }
        }
      ];

      const { limit, offset } = this.state.requestParams.table;
      paginationOptions = {
        totalSize: totalSize,
        page: limit > 0 ? offset / limit : 0,
        sizePerPage: limit,
        pageStartIndex: 0,
        firstPageText: toTTString('First'),
        prePageText: toTTString('Back'),
        nextPageText: toTTString('Next'),
        lastPageText: toTTString('Last'),
        nextPageTitle: toTTString('Next page'),
        prePageTitle: toTTString('Pre page'),
        firstPageTitle: toTTString('First page'),
        lastPageTitle: toTTString('Last page'),
        showTotal: true,
        paginationTotalRenderer: (from, to, size) => (
          <span
            className="react-bootstrap-table-pagination-total">
            <TT params={{from: from, to: to, of: size}}>Showing #from# to #to# of #of# devices</TT>
          </span>
        ),
        sizePerPageList: [{text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}]
      };

      selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selected: Object.keys(this.state.checkboxes),
        onSelect: this.handleOnSelect,
        onSelectAll: (isSelect, rows) => this.selectAllDevices()
      };
    }

    const showDistoAlert = this.state?.distros &&
      (Object.keys(this.state.distros).length !== 1 ||
      (Object.keys(this.state.distros)[0] !== 'focal' && Object.values(this.state.distros)[0] > 0));

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Devices</TT></BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4><TT>Devices</TT></h4>
          {numSelected > 0 ? (
            <span className="title-small">
              (<TT params={{numSelected: numSelected}}>#numSelected# selected</TT>)
            </span>
          ) : null}
          {this.props.devices.isLoading ? <div className="signal"></div> : null}
        </div>
        {showDeviceLimitAlert ?
          <div
            id="device-limit-alert"
            className="alert alert-warning col-md-12"
            role="alert"
          >
            <TT tid="freeDeviceSelection">
              We offer 3 instances and 1 Account per company for free to qualified Accounts
            </TT>{'. '}
            <a href={qualifiedAccountsURL} target="_blank" rel="noopener noreferrer">
            <TT>Read more</TT>...</a>
          </div>: null }
        {showDistoAlert ?
          <div
            id="device-distro-alert"
            className="alert alert-warning col-md-12"
            role="alert"
          >
            <Button onClick={()=>this.updateFilter(
              [{"category":"Distribution","categorykey":"distro.codename","operator":"!=","value":"focal"}],
              '/devices'
              )}><TT>Some</TT></Button>&nbsp;
            <TT tid="deviceDistroWarning">
              devices may be running an older Linux distribution which is not supported from July 2023. Make sure to upgrade the OS by selecting the devices and run Actions&rarr;Host OS Upgrade
            </TT>{'. '}
          </div>: null }
        <div className="col-md-12">
          <div className="d-flex align-items-center screen-bar">
            <div className="mr-2">
              <Checkbox
                label={""}
                uid="select-all"
                isSelected={this.state.selectAll}
                onCheckboxChange={this.selectAllDevices}
                key="select-all"
              />
            </div>
            <div className="mr-2 no-margin">
              <ReactTooltip id="refresh-a">
                <span><TT>Refresh</TT></span>
              </ReactTooltip>
              <Button
                color="info"
                className="refresh-btn"
                data-tip
                data-for="refresh-a"
                size="sm"
                onClick={this.refreshDevicesPage}
              >
                <FontAwesomeIcon icon="sync-alt" />
              </Button>
              <Link to={`/devices`}>
                <ReactTooltip id="clear-filter-a">
                  <span><TT>Clear Filters</TT></span>
                </ReactTooltip>
              </Link>
            </div>

            <div className="mr-2">
                <React.Fragment>
                  <Dropdown
                    isOpen={this.state.isDevActionsOpen}
                    toggle={this.toggleDevActionsDropDown}
                  >
                    <DropdownToggle caret className="action-drop-down">
                      <TT>Actions</TT>
                    </DropdownToggle>
                    <DropdownMenu>
                    <DropdownItem
                      disabled={numSelected > 0 ? false : true}
                      onClick={() => {
                        this.handleStartMany();
                      }}
                    >
                      {numSelected > 1 ? <TT>Start Devices</TT> : <TT>Start Device</TT>}
                    </DropdownItem>
                    <DropdownItem
                      disabled={numSelected > 0 ? false : true}
                      onClick={() => {
                        this.handleStopMany();
                      }}
                    >
                      {numSelected > 1 ? <TT>Stop Devices</TT> : <TT>Stop Device</TT>}
                    </DropdownItem>
                    <DropdownItem
                      disabled={numSelected > 0 ? false : true}
                      onClick={() => {
                        this.handleResetMany();
                      }}
                    >
                      {numSelected > 1 ? <TT>Reset Devices</TT> : <TT>Reset Device</TT>}
                    </DropdownItem>
                    {numSelected > 0 ?
                    <DropdownItem
                      onClick={() => {
                        this.handleDeleteByIds();
                      }}
                    >
                      {numSelected > 1 ? <TT>Delete Selected Devices</TT> : <TT>Delete Selected Device</TT>}
                    </DropdownItem>
                    : this.state.filters.length > 0 &&
                    <DropdownItem
                      onClick={() => {
                        this.handleDeleteByFilters();
                      }}
                    >
                      <TT>Delete All Devices matching the filters</TT>
                    </DropdownItem>
                    }
                    <DropdownItem divider />
                      <DropdownItem
                        disabled={numSelected > 1 ? false : true}
                        onClick={() => {
                          this.toggleTunnelOpen(true);
                        }}
                      >
                        <TT>Create Tunnels</TT>
                      </DropdownItem>
                      <DropdownItem
                        disabled={numSelected === 0}
                        onClick={() => {
                          this.toggleTunnelOpen(true, 'peers');
                        }}
                      >
                        <TT>Create Peer Connection</TT>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        disabled={numSelected > 0 ? false : true}
                        onClick={() => {
                          this.setState({ selectedPolicy: "" });
                          this.setActiveDevAction("install-policy");
                        }}
                      >
                        <TT>Install Policy</TT>
                      </DropdownItem>
                      <DropdownItem
                        disabled={numSelected > 0 ? false : true}
                        onClick={() =>
                          this.setActiveDevAction("uninstall-policy")
                        }
                      >
                        <TT>Uninstall Policy</TT>
                      </DropdownItem>

                      <DropdownItem divider />
                      <DropdownItem
                        disabled={this.state.purchasedApplications.length === 0 || numSelected === 0}
                        onClick={() => {
                          this.setActiveDevAction("install-application");
                        }}
                      >
                        <TT>Install Application</TT>
                      </DropdownItem>
                      <DropdownItem
                        disabled={this.state.purchasedApplications.length === 0 || numSelected === 0}
                        onClick={() => this.setActiveDevAction("uninstall-application")}
                      >
                        <TT>Uninstall Application</TT>
                      </DropdownItem>

                      <DropdownItem divider />
                      <DropdownItem
                        disabled={numSelected !== 2}
                        onClick={this.toggleReplaceModal}
                      >
                        <TT>Replace Device</TT>
                      </DropdownItem>
                      <DropdownItem
                        disabled={numSelected > 0 ? false : true}
                        onClick={() => {
                          this.setActiveDevAction("upgrade");
                          this.handleUpgrade();
                        }}
                      >
                        <TT>{numSelected > 1 ? "Upgrade Devices" : "Upgrade Device"}</TT>
                      </DropdownItem>
                      <DropdownItem
                        disabled={
                          new Date(this.state.versionDeadline).getTime() <
                          Date.now() || numSelected < 1
                            ? true
                            : false
                        }
                        onClick={() => {
                          this.setActiveDevAction("sched-upgrade");
                        }}
                      >
                        <TT>Schedule Device Upgrade</TT>
                      </DropdownItem>
                      <DropdownItem
                        disabled={numSelected > 0 ? false : true}
                        onClick={() => {
                          this.setActiveDevAction("osUpgrade");
                          this.handleOsUpgrade();
                        }}
                      >
                        <TT>Host OS Upgrade</TT>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </React.Fragment>
            </div>
            <DevicesFilter
              filters={this.state.filters}
              labels={this.state.labels}
              firewallPolicies={this.state.firewallPolicies}
              qosPolicies={this.state.qosPolicies}
              multilinkPolicies={this.state.multilinkPolicies}
              updateFilter={this.updateFilter}
              applications={this.state.purchasedApplications}
              clearFilters={this.clearFilters}
            />
            <div>
              <ButtonGroup>
                <Button
                  className="transp-btn"
                  data-tip
                  data-for="card-a"
                  onClick={() => {this.changeViewType('cards');}}
                  active={this.state.viewType === 'cards'}
                >
                  <FontAwesomeIcon icon="border-all" fixedWidth />
                </Button>
                <Button
                  className="transp-btn"
                  data-tip
                  data-for="table-a"
                  onClick={() => {this.changeViewType('table');}}
                  active={this.state.viewType === 'table'}
                >
                  <FontAwesomeIcon icon="list" fixedWidth />
                </Button>
                <Button
                  className="transp-btn ml-2"
                  data-tip
                  data-for="export-a"
                  disabled={devices.length === 0}
                  onClick={() => this.downloadCsvClicked()}
                >
                  <FontAwesomeIcon icon="file-csv" fixedWidth />
                </Button>
              </ButtonGroup>
              <ReactTooltip id="card-a">
                <span><TT>Cards View</TT></span>
              </ReactTooltip>
              <ReactTooltip id="table-a">
                <span><TT>Table View</TT></span>
              </ReactTooltip>
              {/* The next hidden button is created when clicking on the downloadCSV button,
                  It generates the file, clicked and then removed. This is in order to generate the
                  download on button click and not on every render */}
              {this.state.shouldDownloadCsv?
                <Button
                  hidden={true}
                  download={`flexiWAN-Devices-${this.getDateString()}.csv`}
                  href={this.prepareCSVFile(devices)}
                  innerRef={this.csvDownload}
                >
                </Button>:null}
              <ReactTooltip id="export-a">
                <span><TT>Export to CSV</TT></span>
              </ReactTooltip>
            </div>
          </div>

          <div className="mt-2 mb-2">
            {this.state.activeAction === "sched-upgrade" ? (
              <div className="d-flex">
              <h6 style={{ "margin-top": "4px" }}><TT>Date</TT>: </h6>
              <Flatpickr
                className="calendar"
                ref={(fp) => {
                  this.fp = fp;
                }}
                data-enable-time
                options={{
                  defaultDate: this.getStartDate(),
                  formatDate: (date, format) => {
                    return this.formatDateString(date);
                  },
                  altInput: true,
                  altInputClass: "calendar-textbox",
                  minDate: this.getStartDate(),
                  maxDate: this.state.versionDeadline,
                  minuteIncrement: 15,
                  wrap: true,
                }}
                onChange={(date) => {
                  if (date.length > 0) {
                    this.setState({
                      upgradeSchedDate: new Date(date[0].setSeconds(0)),
                    });
                  }
                }}
              >
                <input type="text" data-input />
                  {
                    <Button
                      className="calendar-select-button"
                      onClick={() => {
                        this.handleUpgrade();
                      }}
                    >
                      <TT>Schedule Upgrade</TT>
                    </Button>
                  }
              </Flatpickr>
              </div>
            ) : (
              ""
            )}

            {
              this.state.activeAction.includes("application") ? (
                <div className="d-flex">
                  <Input
                    type="select"
                    defaultValue=""
                    className="col-md-3 application-selector"
                    onChange={event =>
                      this.setState({ selectedApplication: event.target.value })
                    }
                  >
                    {[{ _id: "", appStoreApp: {name: ""}}, ...this.state.purchasedApplications].map(purchased => {
                    return (
                        <option
                          disabled={purchased.appStoreApp.name === ""}
                          key={purchased.appStoreApp.name}
                          value={purchased._id}
                        >
                          {`${purchased.appStoreApp.name === "" ? toTTString("Select application...") : purchased.appStoreApp.name}`}
                        </option>
                    )
                  })}
                  </Input>

                  {this.state.activeAction.includes("uninstall") ? (
                    <Button
                      className="policy-install-button"
                      disabled={this.state.selectedApplication === ""}
                      onClick={() => this.handleApplicationUninstall()}
                    >
                      <TT>Uninstall</TT>
                    </Button>
                  ) : (
                    <Button
                      className="policy-install-button"
                      disabled={this.state.selectedApplication === ""}
                      onClick={() => this.handleApplicationInstall()}
                    >
                      <TT>Install</TT>
                    </Button>
                  ) }
                </div>
              ) : null
            }

            {this.state.activeAction.includes("policy") ? (
              <div className="d-flex">
                <h6 style={{ marginTop: "6px" }}><TT>Policy</TT>: </h6>
                <Input
                  type="select"
                  value={this.state.selectedPolicyType}
                  className="col-md-2 policy-selector"
                  onChange={(event) => {
                    this.setState({ selectedPolicyType: event.target.value })
                    this.selectedPolicyTypeModified(event.target.value, this.state.activeAction);
                  }}
                >
                  {[{ method: 'firewallPolicy', description: toTTString('Firewall') },
                    { method: 'mlpolicy', description: toTTString('Path Selection') },
                    { method: 'qosPolicy', description: toTTString('QoS') }].map((type) => (
                    <option
                      key={type.method}
                      value={type.method}
                    >
                      {type.description}
                    </option>
                  ))}
                </Input>
                {this.state.activeAction.includes("uninstall") ? <>
                  {this.state.selectedPolicyType === 'qosPolicy' && (
                  <Input
                    type="select"
                    defaultValue=""
                    className="col-md-3 policy-selector"
                    onChange={(event) =>
                      this.setState({ selectedPolicy: event.target.value })
                    }
                  >
                    {[{ _id: '', name: toTTString("Uninstall All") },
                      ...this.state.qosPolicies].map(policy => (
                      <option
                        key={policy._id}
                        value={policy._id}
                      >
                        {policy.name}
                      </option>
                    ))}
                  </Input>
                  )}
                  <Button
                    className="policy-install-button"
                    onClick={() => this.handlePolicyInstall("uninstall")}
                  >
                    <TT>Uninstall</TT>
                  </Button>
                </> : (
                  <React.Fragment>
                    <h6 style={{ marginTop: "6px", marginLeft: "10px" }}><TT>Name</TT>: </h6>
                    <Input
                      type="select"
                      defaultValue=""
                      className="col-md-3 policy-selector"
                      onChange={(event) =>
                        this.setState({ selectedPolicy: event.target.value })
                      }
                    >
                      {(this.state.selectedPolicyType === 'mlpolicy' ? [...this.state.multilinkPolicies]
                        : this.state.selectedPolicyType === 'firewallPolicy' ? [...this.state.firewallPolicies]
                        : this.state.selectedPolicyType === 'qosPolicy' ? [...this.state.qosPolicies]
                        : []).map(policy => (
                          <option
                            disabled={policy.name === ""}
                            key={policy._id}
                            value={policy._id}
                          >
                            {`${
                              policy.name === ""
                                ? toTTString("Select policy") + "..."
                                : policy.name
                            }`}
                          </option>
                        )
                      )}
                    </Input>
                    <Button
                      className="policy-install-button"
                      disabled={this.state.selectedPolicy === ""}
                      onClick={() => this.handlePolicyInstall("install")}
                    >
                      <TT>Install</TT>
                    </Button>
                  </React.Fragment>
                )}
              </div>
            ) : (
              ""
            )}

          </div>
          {(() => {
            if (this.state.isMounted) {
              if (this.state.viewType === 'cards') {
                return <DeviceCardList
            devices={devices}
            deviceLatestVer={this.state.deviceLatestVer}
            handleDelete={this.handleDelete}
            handleStart={this.handleStart}
            handleStop={this.handleStop}
            handleSyncDevice={this.handleSyncDevice}
            checkboxes={this.state.checkboxes}
            handleCheckboxChange={this.handleCheckboxChange}
            total={totalSize}
            limit={this.state.requestParams.cards.limit}
            handleLoadMore={this.handleLoadMore}
                />
              } else {
                return <div className="device-card-list" style={{marginRight:"15px"}}>
            <BootstrapTable striped hover condensed
            wrapperClasses={'scroll-x'}
            keyField='_id'
            data={ devices }
            columns={ columns }
            remote={true}
            onTableChange={this.onTableChange}
            pagination={ paginationFactory(paginationOptions) }
            noDataIndication={toTTString("No devices to show")}
            defaultSorted={[{dataField: 'name',order: 'asc'}] }
                  selectRow={ selectRow }/>
                </div>
              }
            } else return null;
          })()}
        </div>
        <ConfirmationModal
          isOpen={this.state.isConfirmationModalOpen}
          toggle={() => this.setState(prev => ({ isConfirmationModalOpen: !prev.isConfirmationModalOpen }))}
          title={this.state.confirmationModalTitle}
          question={this.state.confirmationModalQuestion}
          disabled={this.state.confirmationButtonDisabled}
          color={this.state.confirmationButtonColor}
          onConfirm={this.state.confirmationModalAction}
        />
        <Modal isOpen={this.state.isDelModalOpen}>
          <ModalHeader toggle={this.toggleDelModal}><TT>Delete Device</TT></ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>Are you sure to delete the selected device?</TT>
            </div>
            <Button color="danger" onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleDelModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isStopModalOpen}
        >
          <ModalHeader toggle={this.toggleStopModal}><TT>Stop Device</TT></ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>Are you sure to stop the selected device?</TT>
            </div>
            <Button color="danger" onClick={this.approveStop}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleStopModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isReplaceModalOpen}
          toggle={this.toggleReplaceModal}
        >
          <ModalHeader toggle={this.toggleReplaceModal}><TT>Replace Device</TT></ModalHeader>
          <ModalBody>
            <Row  className="form-group">
              <Label
                className={"col-md-4"}
                style={{ marginTop: "5px" }}>
                  <TT>Old Device</TT>
                  <span className="helpTooltip" data-tip data-for='oldDeviceTip'></span>
                <ReactTooltip id='oldDeviceTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    <TT>All configuration from this device will be copied to the new one.</TT><br/>
                    <TT>This device will be removed from the management.</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <SearchableSelectBox
                className={"col-md-8"}
                id="oldDevice"
                name="oldDevice"
                value={this.state.oldDevice.id}
                placeholder=""
                onChange={this.handleOldDeviceSelect}
                isClearable={false}
                options={Object.keys(this.state.checkboxes).map((i)=>({
                  label:this.state.checkboxes[i], value:i})
                )}
              />
            </Row>
            <Row  className="form-group">
              <Label
                className={"col-md-4"}
                style={{ marginTop: "5px" }}>
                  <TT>New Device</TT>
                  <span className="helpTooltip" data-tip data-for='newDeviceTip'></span>
                <ReactTooltip id='newDeviceTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    <TT>This device must not have any configuration.</TT><br/>
                    <TT>All configuration to this device will be copied from the old one.</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <SearchableSelectBox
                className={"col-md-8"}
                id="newDevice"
                name="newDevice"
                value={this.state.newDevice.id}
                placeholder=""
                onChange={this.handleNewDeviceSelect}
                isClearable={false}
                options={Object.keys(this.state.checkboxes).map((i)=>({
                  label:this.state.checkboxes[i], value:i})
                )}
              />
            </Row>
            <Row>
              <Col>
                <Checkbox
                  label={` ${toTTString('please approve the deletion of')} "${this.state.oldDevice.label}" ${toTTString('and replacing it with')} "${this.state.newDevice.label}"`}
                  uid={"replaceConfirmation"}
                  isSelected={this.state.replaceConfirmation}
                  onCheckboxChange={() => this.setState({ replaceConfirmation: !this.state.replaceConfirmation })}
                />
              </Col>
            </Row>
            <Row>
              <Col className="align-self-end">
                <Button
                  className="btn-danger btn-right"
                  disabled={!this.state.replaceConfirmation}
                  onClick={this.handleReplaceDevice}
                >
                  <TT>Replace Device</TT>
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isUpgradeModalOpen}
          toggle={this.toggleUpgradeModal}
        >
          <ModalHeader toggle={this.toggleUpgradeModal}>
            <TT>Upgrade Devices</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              {
                (this.state.activeAction === "sched-upgrade") ? (<><TT>Schedule upgrade for selected devices</TT>.<br/></>) : (<><TT>Upgrade the selected devices</TT><br/></>)
              }
              <TT params={{numSelected: numSelected}}>Num of selected devices: #numSelected#</TT>
              {
               (this.state.deviceLatestVer && this.state.deviceLatestVer.device) ? (<><br/><TT params={{version: this.state.deviceLatestVer.device}}>Upgrade to version: #version#</TT></>): ""
              }
              {
                (this.state.activeAction === "sched-upgrade") ? (<><br/><TT>Time of upgrade</TT>: {this.formatDateString(this.state.upgradeSchedDate)} </>) : ""
              }?
            </div>
            <Button color="success" onClick={this.approveUpgrade}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={() => {
                this.toggleUpgradeModal();
                this.setActiveDevAction("");
              }}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          size='lg'
          isOpen={this.state.isOsUpgradeModalOpen}
          toggle={this.toggleOsUpgradeModal}
        >
          <ModalHeader toggle={this.toggleOsUpgradeModal}>
            <TT>Host OS Upgrade</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <div
                className="alert alert-danger col-md-12"
                role="alert"
              >
                <TT>Clicking Yes, will upgrade your selected devices with the latest supported Linux version</TT>
              </div>
              <ul>
                <li><TT>This process can take up to an hour and requires internet access, please do not manually turn off or reboot devices</TT></li>
                <li><TT>The devices will disconnect from the management and re-connect during the process</TT></li>
                <li><TT>The devices will reboot automatically upon upgrade completion and be in a stop state</TT></li>
                <li><TT>There is no impact on the device and network configuration</TT></li>
              </ul>
              <TT>
                If any issue happens follow the "Recovery Info" steps in the device configuration tab
              </TT>.<br/>
              <TT>For any other issue contact support</TT>.
            </div>
            <Button color="success" onClick={this.performOsUpgrade}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={() => {
                this.toggleOsUpgradeModal();
                this.setActiveDevAction("");
              }}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isUpValidationModalOpen}
          toggle={this.toggleUpgradeValidationModal}
        >
          <ModalHeader toggle={this.toggleUpgradeValidationModal}>
            <TT>Upgrade Devices</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>WARNING: All devices connected via tunnels must be upgraded together</TT>.<br/>
              <TT>Tunnels may not work properly if devices run different versions. Continue?</TT>
            </div>
            <Button color="danger" onClick={this.performUpgrade}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={() => {
                this.toggleUpgradeValidationModal();
                this.setActiveDevAction("");
              }}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.activeAction === "tunnels"}
          toggle={() => {this.toggleTunnelOpen(false);}}
        >
          <ModalHeader toggle={() => {this.toggleTunnelOpen(false);}}>Create Tunnels</ModalHeader>
          <ModalBody>
            {this.state.activeAction === "tunnels" ? (
              <div>
                <Row  className="form-group">
                  <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
                      Path Labels:
                      <span className="helpTooltip" data-tip data-for='pathLabelsTip'></span>
                    <ReactTooltip id='pathLabelsTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                      <TT>Tunnels will be created between all interfaces with the selected Path Labels</TT>.<br />
                      <TT>Keep empty for interfaces with no path labels</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <PathLabelSelectBox
                    className="col-md-8"
                    placeholder={toTTString("Select path labels") + '...'}
                    id={"1"}
                    values={[]}
                    options={tunnelsPathLabels}
                    onChange={this.handleLabelsChange}
                  />
                </Row>
                <Row  className="form-group">
                  <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
                      Topology:
                      <span className="helpTooltip" data-tip data-for='tunnelsTopologyTip'></span>
                    <ReactTooltip id='tunnelsTopologyTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                      <TT>Select hub and spoke or full mesh topology</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <SearchableSelectBox
                    className="col-md-8"
                    id="tunnelTopology"
                    name="tunnelTopology"
                    value={this.state.tunnelTopology.value}
                    placeholder=""
                    onChange={this.handleTunnelTopologyChange}
                    isClearable={false}
                    options={[
                      {label:toTTString('Full Mesh'), value:'fullMesh'},
                      {label:toTTString('Hub and Spoke'), value:'hubAndSpoke'}]}
                  />
                </Row>
                <Row  className="form-group">
                  <Label className="col-md-4 col-form-label d-flex align-items-center justify-content-between">
                      Hub Device:
                      <span className="helpTooltip" data-tip data-for='hubDeviceTip'></span>
                    <ReactTooltip id='hubDeviceTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>In Hub and Spoke topology</TT>,<br/>
                        <TT>Selects the hub device to connect tunnels with</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <SearchableSelectBox
                    className={"col-md-8"}
                    isDisabled={this.state.tunnelTopology.value === 'fullMesh'}
                    id="hubDevice"
                    name="hubDevice"
                    value={this.state.hubDevice.id}
                    placeholder=""
                    onChange={this.handleHubDeviceSelect}
                    isClearable={false}
                    options={Object.keys(this.state.checkboxes).map((i)=>({
                      label:this.state.checkboxes[i], value:i})
                    )}
                  />
                </Row>
                <FwCollapse
                  title={toTTString("Advanced Options")}
                  color="outline-secondary"
                  changed={val => this.setState({ sendTunnelOptions: val})}
                >
                  <TunnelAdvancedOptions
                    {...this.state.tunnelOptions}
                    setValue={this.setTunnelOption}
                    tunnelType={'site-to-site'}
                  />
                </FwCollapse>
                <Row>
                  <Col className="col-md-6">
                    {this.state.tunnelTopology.value === 'fullMesh'?
                      <img src={fullMeshImg} height="130" alt="Full-Mesh" />:
                      <img src={hubAndSpokeImg} height="130" alt="Hub & Spoke" />
                    }
                  </Col>
                  <Col className="col-md-6 align-self-end">
                    <Button
                      className="btn-primary btn-right"
                      style={{ marginTop: "10px" }}
                      onClick={() => this.handleTunnels()}
                      disabled={!this.tunnelOptionsValid() || (this.state.tunnelTopology.value === 'hubAndSpoke'
                        && !this.state.hubDevice.id)}
                    >
                      <TT>Create Tunnels</TT>
                    </Button>
                  </Col>
                </Row>
              </div>) : null}
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.activeAction === "peers"}
          toggle={() => {this.toggleTunnelOpen(false, 'peers');}}
        >
          <ModalHeader toggle={() => {this.toggleTunnelOpen(false, 'peers');}}>Create Peers</ModalHeader>
          <ModalBody>
            {this.state.activeAction === "peers" ? (
              <div>
                <Row  className="form-group">
                  <Label
                    className={"col-md-4"}
                    style={{ marginTop: "5px" }}>
                      Path Labels:
                      <span className="helpTooltip" data-tip data-for='pathLabelsTip'></span>
                    <ReactTooltip id='pathLabelsTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                      <TT>Peers will be created between all interfaces with the selected Path Labels</TT>.<br />
                      <TT>Keep empty for interfaces with no path labels</TT>.
                      </span>
                    </ReactTooltip>
                  </Label>
                  <PathLabelSelectBox
                    className="col-md-8"
                    placeholder={toTTString("Select path labels") + '...'}
                    id={"1"}
                    values={[]}
                    options={tunnelsPathLabels}
                    onChange={this.handleLabelsChange}
                  />
                </Row>
                <Row  className="form-group">
                  <Label
                    className={"col-md-4"}
                    style={{ marginTop: "5px" }}>
                      Peers:
                      <span className="helpTooltip" data-tip data-for='peersTip'></span>
                    <ReactTooltip id='peersTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                      <TT>Select peers to connect with</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <PathLabelSelectBox
                    className="col-md-8"
                    placeholder={toTTString("Select peers") + '...'}
                    id={"1"}
                    values={[]}
                    options={tunnelsPeers}
                    badgeLabels={false}
                    onChange={this.handlePeersChange}
                  />
                </Row>
                <FwCollapse
                  title={toTTString("Advanced Options")}
                  color="outline-secondary"
                  changed={val => this.setState({ sendTunnelOptions: val})}
                >
                  <TunnelAdvancedOptions
                    {...this.state.tunnelOptions}
                    setValue={this.setTunnelOption}
                    tunnelType={'peer'}
                  />
                </FwCollapse>
                <Row>
                  <Col className="col-md-6">
                    <img src={peersImg} height="130" alt="Peers" />
                  </Col>
                  <Col className="col-md-6 align-self-end">
                    <Button
                      className="btn-primary btn-right"
                      // disable the button if no peer selected
                      disabled={!this.tunnelOptionsValid() ||
                        (this.state.activeAction === "peers" && (this.state.selectedPeers.length === 0))}
                      onClick={() => this.handleTunnels()}
                    >
                      {
                        (numSelected > 1) ? <TT>Create Peers</TT>: <TT>Create Peer</TT>
                      }
                    </Button>
                  </Col>
                </Row>
              </div>
              ) : null}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isAppConfirmationModalOpen}
          toggle={this.toggleApplicationConfirmationModal}
        >
          <ModalHeader toggle={this.toggleApplicationConfirmationModal}><TT>Configure Application</TT></ModalHeader>
          <ModalBody>
            {this.state.selectedApplication && this.state.isAppConfirmationModalOpen ? this.getApplicationModalBody() : null}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    tunnels: state.tunnels,
    purchasedApplications: state.applications.purchasedApplications
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearDevices: () => dispatch(clearDevices()),
  getAllDevicesIds: (params, success_cb) => dispatch(getAllDevicesIds(params, success_cb)),
  getAllDevices: (params, success_cb) => dispatch(getAllDevices(params, success_cb)),
  startDevice: (device, success_cb) => dispatch(startDevice(device, success_cb)),
  startDevices: (devices, success_cb, allowOverlapping = false) => dispatch(startDevices(devices, success_cb, allowOverlapping)),
  stopDevice: (device, success_cb) => dispatch(stopDevice(device, success_cb)),
  stopDevices: (devices, success_cb) => dispatch(stopDevices(devices, success_cb)),
  delDevice: (device, success_cb, error_cb, removeVrrp = null) => dispatch(delDevice(device, success_cb, error_cb, removeVrrp)),
  deleteDevices: (params, success_cb, error_cb = null) => dispatch(deleteDevices(params, success_cb, error_cb)),
  tunnelDevices: (devices, pathLabels, peerIds, topology, hub, options,notificationsSettings, success_cb) =>
    dispatch(tunnelDevices(devices, pathLabels, peerIds, topology, hub, options, notificationsSettings, success_cb)),
  upgradeDevices: (devices, success_cb) => dispatch(upgradeDevices(devices, success_cb)),
  upgradeOsDevices: (devices, success_cb) => dispatch(upgradeOsDevices(devices, success_cb)),
  schedDeviceUpgrade: (devices, sched, success_cb) => dispatch(schedDeviceUpgrade(devices, sched, success_cb)),
  replaceDevice: (oldId, newId, success_cb) => dispatch(replaceDevice(oldId, newId, success_cb)),
  getDeviceLatestVer: (devices, success_cb) => dispatch(getDeviceLatestVer(devices, success_cb)),
  getAllPathLabels: (success_cb) => dispatch(getAllPathLabels(success_cb)),
  getMLPoliciesList: (success_cb) => dispatch(getMLPoliciesList(success_cb)),
  getFirewallPoliciesList: (success_cb) => dispatch(getFirewallPoliciesList(success_cb)),
  getQOSPoliciesList: (success_cb) => dispatch(getQOSPoliciesList(success_cb)),
  installDevPolicy: (devices, policy) => dispatch(installDevPolicy(devices, policy)),
  resetDevice: (deviceId) => dispatch(resetDevice(deviceId)),
  resetDevices: (devices) => dispatch(resetDevices(devices)),
  syncDevice: (deviceId) => dispatch(syncDevice(deviceId)),
  clearTunnels: () => dispatch(clearTunnels()),
  getAllPeers: cb => dispatch(getAllPeers(cb)),
  getAllTunnels: (params, success_cb) => dispatch(getAllTunnels(params, success_cb)),
  getPurchasedApplications: (responseType, filters, cb) => dispatch(getPurchasedApplications(responseType, filters, cb)),
  installApplication: (devices, app, deviceConfiguration) => dispatch(installApplication(devices, app, deviceConfiguration)),
  uninstallApplicationFromDevice: (devices, application) => dispatch(uninstallApplicationFromDevice(devices, application)),
  resetForm: (model) => dispatch(actions.reset(model))
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);