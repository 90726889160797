import React, {useContext} from "react";
import {
  Card,
  CardBody,
  Row,
  Label,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Control, Form, Errors, actions } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { isNumber, minValue } from "../../utils/Validators";
import { TT, LanguageContext } from '../../containers/Language'

const changeToNumber = (model, value) => {
  return (dispatch) => {
    dispatch(actions.change(model, +value));
  };
}

export default function BandwidthModal ({isOpen, toggle, onSubmit, qosPolicies}) {
  const { toTTString } = useContext(LanguageContext);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <TT>QoS</TT>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form
              model="updateInterface"
              onSubmit={onSubmit}
            >
              <Row className="form-group">
                <Label htmlFor=".bandwidthMbps.tx" md={4}>
                  <TT>Bandwidth Tx, Mbps</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".bandwidthMbps.tx"
                    id=".bandwidthMbps.tx"
                    name=".bandwidthMbps.tx"
                    placeholder={toTTString("Bandwidth Tx, Mbps")}
                    component={FWTextInput}
                    changeAction={changeToNumber}
                    withFieldValue
                    validators={{
                      isNumber: isNumber,
                      minValue: val => val === "" || minValue(0.5)(val)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".bandwidthMbps.tx"
                    show="touched"
                    messages={{
                      isNumber: toTTString("Invalid bandwidth value"),
                      minValue: toTTString('Bandwidth value must be greater than 0.5 Mbps')
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor=".bandwidthMbps.rx" md={4}>
                  <TT>Bandwidth Rx, Mbps</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".bandwidthMbps.rx"
                    id=".bandwidthMbps.rx"
                    name=".bandwidthMbps.rx"
                    placeholder={toTTString("Bandwidth Rx, Mbps")}
                    component={FWTextInput}
                    changeAction={changeToNumber}
                    withFieldValue
                    validators={{
                      isNumber: isNumber,
                      minValue: val => val === "" || minValue(0.5)(val)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".bandwidthMbps.rx"
                    show="touched"
                    messages={{
                      isNumber: toTTString("Invalid bandwidth value"),
                      minValue: toTTString('Bandwidth value must be greater than 0.5 Mbps')
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="qosPolicy" md={4}>
                  <TT>QoS Policy</TT>
                </Label>
                <Col md={8}>
                  <Control.select
                    model=".qosPolicy"
                    className="form-control"
                  >
                    <option key='' value='' >{toTTString('None')}</option>
                    {qosPolicies.map(p =>
                      <option key={p.value} value={p.value}>{p.label}</option>
                    )}
                  </Control.select>
                </Col>
              </Row>
              <Row className="form-group">
                <Col xs={7} md={{ size: 3, offset: 4 }}>
                  <Control.button
                    model="updateInterface"
                    disabled={{ valid: false }}
                    className="btn btn-success btn-ok"
                  >
                    <TT>OK</TT>
                  </Control.button>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={toggle}
                    type="button"
                    className="btn btn-outline-secondary pull-right"
                  >
                    <TT>Cancel</TT>
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}