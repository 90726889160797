/**
 * Get tunnel loopback addresses
 *
 * @param {number} num Tunnel number
 * @param {string} tunnelRange Tunnel range
 * @return {{ip1: string, ip2: string}} Object with two sides loopback addresses
 */
 export const getTunnelIPs = (num, tunnelRange) => {
    const h = (num % 127 + 1) *2;
    const l = Math.floor(num / 127);

    const splitIp = tunnelRange.split('.');
    const ipStart = `${splitIp[0]}.${splitIp[1]}.`;

    const ip1 = ipStart + (+l).toString(10) + '.' + (+h).toString(10);
    const ip2 = ipStart + (+l).toString(10) + '.' + (+(h + 1)).toString(10);

    return { ip1, ip2}
};