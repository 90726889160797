import React, { Component } from "react";
import { Control, Form, Errors } from "react-redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactTooltipWithLink from '../../../items/ReactTooltipWithLink';
import FwAccordion from '../../../items/FwAccordion';
import './remoteVpnConfiguration.css';
import { TT, LanguageContext } from '../../../../containers/Language';
import Loading from "../../../items/Loading";
import omit from 'lodash/omit';
import { performApplicationAction } from "../../../../redux/reducers/Applications";
import {
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import {
  isPortValid,
  required,
  maxLength,
  minLength,
  isDomainList,
  isDomain,
  isIPList,
  isNumber,
  isEmail,
  minValue
} from "../../../../utils/Validators";

import withSaveBeforeLeaving from '../../../../HOC/SaveBeforeLeaving';
import { FWTextInput, FWArrayEditableTable } from "../../../Common";
const { vpnBaseUrl } = window.SERVER_CONFIG;

class RemoteVpnConfigurations extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.state = {
      directLink: null,
      isFlexiManageAuthEnabled: false,
      certificatesModalOpen: false,
      formToSubmit: null,
      certificatesProcess: 0 // 0 = not created, 1 = created, 2 = in process, 3 = error
    }

    this.accordion = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const configs = omit(this.props.app.configuration, 'keysExist');
    this.setState({ certificatesProcess: this.props.app.configuration.keysExist ? 1 : 0 })

    if (Object.keys(configs).length) {
      this.props.setOrig(this.props.app.configuration);
      this.props.changeForm('remoteVpnConfiguration')(this.props.app.configuration);
      this.setDirectLinkState(this.props.app.configuration.networkId)
    }
  }

  componentWillUnmount() {
    this.props.resetForm('remoteVpnConfiguration')()
  }

  setDirectLinkState = (networkId) => {
    this.setState({ directLink: vpnBaseUrl + 'login?workspace=' + networkId })
  }

  handleCreateCertificates = () => {
    this.setState({ certificatesProcess: 2 });

    this.props.performApplicationAction(
      this.props.app,
      'createCertificates',
      {},
      (res, err) => {
        if (err) {
          this.setState({ certificatesProcess: 3 });
          return
        }

        this.setState({certificatesProcess: 1, certificatesModalOpen: false });
        this.sendSubmit(this.state.formToSubmit);
      }
    )
  }

  sendSubmit = configuration => {
    this.props.updateApplication({
      ...this.props.app,
      configuration: { ...configuration }
    });
  }

  handleSubmit(values) {
    if (!this.props.app.configuration.keysExist && this.state.certificatesProcess !== 1) {
      this.setState({ certificatesModalOpen: true, formToSubmit: values })
      this.handleCreateCertificates()
      return;
    }

    this.sendSubmit(values)
  }

  showToast = () => {
    toast(this.context.toTTString("Direct Link Copied!"), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const toTTString = this.context.toTTString;
    return (
      <React.Fragment>
        <Form
            id="remoteVpnConfiguration"
            model="remoteVpnConfiguration"
            onSubmit={this.handleSubmit}
            onSubmitFailed={vals => {
              // open all collapse if submit failed due to invalid form in order to show the validation errors
              this.accordion.current.openAll()
            }}
            onChange={vals => {
              const newStateValue = vals.authentications?.flexiManage?.enabled;
              if (this.state.isFlexiManageAuthEnabled !== newStateValue) {
                this.setState({isFlexiManageAuthEnabled: newStateValue })
              }
              this.props.setUpdated(vals);
            }}
            validators={{
              "": {
                azureEnabledWithoutDomain: vals => {
                  const office365 = vals.authentications?.office365;
                  if (office365?.enabled && office365?.domains?.length === 0) {
                    return false
                  } else {
                    return true
                  }
                },
                gsuiteEnabledWithoutDomain: vals => {
                  const gsuite = vals.authentications?.gsuite;
                  if (gsuite?.enabled && gsuite?.domains.length === 0) {
                    return false
                  } else {
                    return true
                  }
                },
              },
            }}
          >
            <Card>
              <CardBody>
                <Row className="form-group">
                  <Label htmlFor="networkId" md={4}>
                    <span className="form-required-star">*</span>
                    <TT>Workspace name</TT>
                    <ReactTooltip id={"networkIdTip"}>
                      <span style={{ fontSize: "0.8rem" }}>
                      <TT>A globally unique identifier of your organization. The end-user enters this identifier when logging in from his device to the VPN client</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for={"networkIdTip"} />
                  </Label>

                  <Col md={3}>
                    <Control.text
                      model=".networkId"
                      id="networkId"
                      name="networkId"
                      placeholder={toTTString("Workspace name")}
                      component={FWTextInput}
                      withFieldValue
                      onChange={e => this.setDirectLinkState(e.target.value)}
                      validators={{
                        required: required,
                        minLength: minLength(3),
                        maxLength: maxLength(20),
                        isMatchRegex: val => val && val.match(/^[A-Za-z0-9]+$/)
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".networkId"
                      show="touched"
                      messages={{
                        required: toTTString("Required field"),
                        minLength: toTTString("Length must be at least 3"),
                        maxLength: toTTString("Length must be at most 20"),
                        isMatchRegex: toTTString("Invalid Workspace Name. Only letters and numbers allowed")
                      }}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <Label md={4}>
                    <TT>Portal access link</TT>
                    <ReactTooltip id="directLinkTip">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>A direct link to the Remote Worker Portal. Admin may provide this link to users for logging in to the VPN client without the need to enter the Workspace name</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for="directLinkTip" />
                  </Label>

                  <Col md={6}>
                    <Control.text
                      model=".directLink"
                      id="directlink"
                      name="directLink"
                      value={this.state.directLink ?? ''}
                      autoComplete="off"
                      component={FWTextInput}
                      disabled
                      withFieldValue
                      data-lpignore="true"
                    ></Control.text>
                  </Col>
                  <Col md={{ size: 2 }} className="pr-1 align-self-center">
                    <CopyToClipboard text={this.state.directLink}
                      onCopy={() => this.showToast()}>
                      <Button size="sm" color="primary" data-tip data-for='copy-a'>
                        <FontAwesomeIcon icon="clone" fixedWidth />
                      </Button>
                    </CopyToClipboard>
                    <ReactTooltip id='copy-a'><span><TT>Copy Direct Link To Clipboard</TT></span></ReactTooltip>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Label md={4}>
                    <span className="form-required-star">*</span>
                    <TT>Max Remote Worker Users</TT>
                    <ReactTooltip id="allowedPortalUsers">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>The max number of unique remote worker users allowed in current billing month</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for="allowedPortalUsers" />
                  </Label>

                  <Col md={2}>
                    <Control.text
                      model=".allowedPortalUsers"
                      id="allowedPortalUsers"
                      name="allowedPortalUsers"
                      placeholder={toTTString("Max Remote Worker Users")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        isNumber: isNumber,
                        minValue: minValue(1),
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".allowedPortalUsers"
                      show="touched"
                      messages={{
                        required: toTTString("Required field"),
                        minValue: toTTString("Portal users must be set to 1 or greater"),
                        isNumber: toTTString("Invalid number")
                      }}
                    />
                  </Col>
                </Row>

                <hr className="mb-3" />

                <Row className="form-group">
                  <Label htmlFor="serverPort" md={4}>
                    <span className="form-required-star">*</span>
                    <TT>Server port</TT>
                    <ReactTooltip id={"serverPortTip"}>
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>VPN Server port number. Default is 1194. A Firewall rule will be added automatically by the system to allow access through this port.</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for={"serverPortTip"} />
                  </Label>

                  <Col md={2}>
                    <Control.text
                      model=".serverPort"
                      id="serverPort"
                      name="serverPort"
                      placeholder={toTTString("Server port")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        isPort: isPortValid,
                        minValue: minValue(20)
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".serverPort"
                      show="touched"
                      messages={{
                        required: toTTString('Required Field'),
                        isPort: toTTString("Invalid Port"),
                        minValue: toTTString("Port number must be set to 20 or greater"),
                      }}
                    />
                  </Col>
                </Row>

                <hr className="mb-3" />

                <Row className="form-group">
                  <Label md={4}>
                    <span className="form-required-star">*</span>
                    <TT>Route all client traffic over VPN</TT>
                    <ReactTooltip id="routeAllTrafficOverVpnTip">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>If disabled, only traffic destined to IPs in the organization will be sent over VPN. If enabled, all traffic will be sent over VPN</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for="routeAllTrafficOverVpnTip" />
                  </Label>

                  <Col md={6}>
                    <Label className="FWswitch">
                      <Control.checkbox
                        model=".routeAllTrafficOverVpn"
                        id="routeAllTrafficOverVpn"
                        name="routeAllTrafficOverVpn"
                      />{" "}
                      <span className="FWslider round" />
                    </Label>
                  </Col>
                </Row>

                <hr className="mb-3" />

                <Row className="form-group">
                  <Label htmlFor="dnsIps" md={4}>
                    <TT>DNS IPs</TT>
                    <ReactTooltip id="dnsIpsTip">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>The DNS IPs (comma separated) will override VPN client DNS settings. Keep it empty to use the client local DNS</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for="dnsIpsTip" />
                  </Label>

                  <Col md={3}>
                    <Control.text
                      model=".dnsIps"
                      id="dnsIps"
                      name="dnsIps"
                      placeholder={toTTString("DNS IPs")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        ipList: val => val === '' || isIPList(val)
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".dnsIps"
                      show="touched"
                      messages={{
                        ipList: toTTString('Invalid IPv4 Addresses')
                      }}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <Label htmlFor="dnsDomains" md={4}>
                    <TT>DNS domains</TT>
                    <ReactTooltip id="dnsDomainsTip">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>DNS domain names that will assign to the clients (comma separated)</TT>
                      </span>
                    </ReactTooltip>
                    <span className="helpTooltip" data-tip data-for="dnsDomainsTip" />
                  </Label>

                  <Col md={3}>
                    <Control.text
                      model=".dnsDomains"
                      id="dnsDomains"
                      name="dnsDomains"
                      placeholder={toTTString("DNS domains")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        minLength: val => {
                          if (val) {
                            return minLength(3)(val);
                          } else {
                            return true;
                          }
                        },
                        maxLength: maxLength(50),
                        isDomainList: val => val === '' || isDomainList(val)
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".dnsDomains"
                      show="touched"
                      messages={{
                        minLength: toTTString("Length must be at least 3"),
                        maxLength: toTTString("Length must be at most 50"),
                        isDomainList: toTTString('Invalid domain list')
                      }}
                    />
                  </Col>
                </Row>

                <hr />

                <Row className="form-group">
                  <Col md={12}>
                    <Label htmlFor="">
                      <b><TT>Authentication Methods</TT></b>
                    </Label>
                  </Col>
                </Row>

                <FwAccordion
                  ref={this.accordion}
                  items={[
                    {
                      title: toTTString('Google Workspace'),
                      indicator: (
                        <Label className="FWswitch mr-4">
                          <Control.checkbox
                            model=".authentications.gsuite.enabled"
                            id="authentications.gsuite.enabled"
                            name="authentications.gsuite.enabled"
                          />{" "}
                          <span className="FWslider round" />
                        </Label>
                      ),
                      collapse: (
                        <>
                          <Row className="form-group align-items-center">
                            <Label md={2}>
                              <span className="form-required-star">*</span>
                              <TT>Enable</TT>
                            </Label>

                            <Col md={3}>
                              <Label className="FWswitch">
                                <Control.checkbox
                                  model=".authentications.gsuite.enabled"
                                  id="authentications.gsuite.enabled"
                                  name="authentications.gsuite.enabled"
                                />{" "}
                                <span className="FWslider round" />
                              </Label>
                            </Col>
                          </Row>

                          <Row className="form-group align-items-center">
                            <Label htmlFor="authentications.gsuite.domains" md={2}>
                              <TT>Domains</TT>
                              <ReactTooltip id="gsuiteDomainTip">
                                <span style={{ fontSize: "0.8rem" }}>
                                <TT>Domain names used for user authentication.</TT>
                                </span>
                              </ReactTooltip>
                              <span className="helpTooltip" data-tip data-for="gsuiteDomainTip" />
                            </Label>

                            <Col md={10}>
                              <Control
                                model=".authentications.gsuite.domains"
                                id="authentications.gsuite.domains"
                                name="authentications.gsuite.domains"
                                component={FWArrayEditableTable}
                                form="remoteVpnConfiguration"
                                withFieldValue
                                showRemove={true}
                                validators={{
                                  domainsIsEmpty: val => {
                                    const some = val.some(v => v.domain === '');
                                    return !some
                                  },
                                  isValidDomain: val => {
                                    const some = val.some(v => !isDomain(v.domain))
                                    return !some;
                                  },
                                  domainsWithWhitespace: val => {
                                    const some = val.some(v => v.domain.indexOf(' ') >= 0);
                                    return !some
                                  },
                                  clientEmailIsEmpty: val => {
                                    const some = val.some(v => v.groups !== '' && v.clientEmail === '');
                                    return !some
                                  },
                                  clientEmailIsInvalid: val => {
                                    const some = val.some(v => v.groups !== '' && v.clientEmail !== '' && !isEmail(v.clientEmail));
                                    return !some
                                  },
                                  impersonateEmailIsEmpty: val => {
                                    const some = val.some(v => v.groups !== '' && v.impersonateEmail === '');
                                    return !some
                                  },
                                  impersonateEmailIsInvalid: val => {
                                    const some = val.some(v => v.groups !== '' && v.impersonateEmail !== '' && !isEmail(v.impersonateEmail));
                                    return !some
                                  },
                                  privateKeyIsEmpty: val => {
                                    const some = val.some(v => v.groups !== '' && v.privateKey === '');
                                    return !some
                                  },
                                }}
                                columns={[
                                  {
                                    text: toTTString("Domain name"),
                                    dataField: "domain",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <div>
                                            <span className="form-required-star">*</span>
                                            <span><TT>Domain name</TT></span>
                                          </div>
                                          <span className="ml-2 helpTooltip" data-tip data-for="domains-header"></span>
                                          <ReactTooltip id="domains-header">
                                            <span className="font-weight-normal">
                                              <TT>Domain name used with Google Workspace. Eg. flexiwan.com</TT>
                                            </span>
                                          </ReactTooltip>
                                        </div>
                                      )
                                    }
                                  },
                                  {
                                    text: toTTString("Group membership"),
                                    dataField: "groups",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <span><TT>Group membership</TT></span>
                                          <span className="ml-2 helpTooltip" data-tip data-for="groups-header"></span>
                                          <ReactTooltip id="groups-header" place="left">
                                            <span className="font-weight-normal">
                                            <TT>Optional. Only members of specified Groups will be able to authenticate (multiple comma separated groups allowed)</TT>.
                                            </span>
                                          </ReactTooltip>
                                        </div>
                                      )
                                    }
                                  },
                                  {
                                    text: toTTString("Service Account"),
                                    dataField: "clientEmail",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <span><TT>Service Account</TT></span>
                                          <ReactTooltipWithLink id="clientEmailTip">
                                            <span className="font-weight-normal" style={{ fontSize: "0.8rem" }}>
                                              <TT>Service account email. Required when Group membership is used</TT>.
                                              <br />
                                              <b><a target="_blank" rel="noreferrer" href="https://docs.flexiwan.com/applications/remoteworkervpn.html#authentication-methods">
                                                <TT>Learn more...</TT>
                                              </a></b>
                                            </span>
                                          </ReactTooltipWithLink>
                                        </div>
                                      )
                                    }
                                  },
                                  {
                                    text: toTTString("Private key"),
                                    dataField: "privateKey",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <span><TT>Private key</TT></span>
                                          <ReactTooltipWithLink id="privateKeyTip">
                                            <span className="font-weight-normal" style={{ fontSize: "0.8rem" }}>
                                              <TT>User-managed key from Google Workspace to authenticate with service account</TT><br />
                                              <b><a target="_blank" rel="noreferrer" href="https://docs.flexiwan.com/applications/remoteworkervpn.html#authentication-methods">
                                                <TT>Learn more...</TT>
                                              </a></b>
                                            </span>
                                          </ReactTooltipWithLink>
                                        </div>
                                      )
                                    },
                                    formatter: (content, row, rowIndex, formatExtraData) => {
                                      return (
                                        <div style={{
                                          'whiteSpace': 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis'
                                        }}>
                                          {new Array(content.length +1).join('●')}
                                        </div>
                                      )
                                    }
                                  },
                                  {
                                    text: toTTString("Admin email"),
                                    dataField: "impersonateEmail",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <span><TT>Admin email</TT></span>
                                          <ReactTooltipWithLink id="impersonateEmailTip" place="left">
                                            <span className="font-weight-normal" style={{ fontSize: "0.8rem" }}>
                                              <TT>In order for service account to access group information</TT>,
                                              <br />
                                              <TT>Google Workspace admin account must be specified</TT>.
                                              <br/>
                                              <TT>Note, not used for authentication, only identification</TT>.
                                              <br />
                                              <b>
                                                <a target="_blank" rel="noreferrer" href="https://docs.flexiwan.com/applications/remoteworkervpn.html#authentication-methods">
                                                  <TT>Learn more...</TT>
                                                </a>
                                              </b>
                                            </span>
                                          </ReactTooltipWithLink>
                                        </div>
                                      )
                                    }
                                  },
                                ]}
                              />
                              <Errors
                                className="text-danger"
                                model=".authentications.gsuite.domains"
                                show="touched"
                                messages={{
                                  domainsIsEmpty: toTTString('Domain cannot be empty'),
                                  isValidDomain: toTTString("Invalid domain"),
                                  privateKeyIsEmpty: toTTString('Private key is required'),
                                  domainsWithWhitespace: toTTString('Domain cannot contains whitespace'),
                                  clientEmailIsEmpty: toTTString('Client email is required'),
                                  clientEmailIsInvalid: toTTString('Invalid client email'),
                                  impersonateEmailIsEmpty: toTTString('Impersonate email is required'),
                                  impersonateEmailIsInvalid: toTTString('Invalid impersonate email')
                                }}
                              />

                              <Errors
                                className="text-danger"
                                model="."
                                show="touched"
                                messages={{
                                  gsuiteEnabledWithoutDomain: toTTString('At least one domain is required'),
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      )
                    },
                    {
                      title: toTTString('Azure AD'),
                      indicator: (
                        <Label className="FWswitch mr-4">
                          <Control.checkbox
                            model=".authentications.office365.enabled"
                            id="authentications.office365.enabled"
                            name="authentications.office365.enabled"
                          />{" "}
                          <span className="FWslider round" />
                        </Label>
                      ),
                      collapse: (
                        <>
                          <Row className="form-group align-items-center">
                            <Label md={2}>
                              <span className="form-required-star">*</span>
                              <TT>Enable</TT>
                            </Label>
                            <Col md={3} className="vpn-checkbox">
                              <Label className="FWswitch">
                                <Control.checkbox
                                  model=".authentications.office365.enabled"
                                  id="authentications.office365.enabled"
                                  name="authentications.office365.enabled"
                                />{" "}
                                <span className="FWslider round" />
                              </Label>
                            </Col>
                          </Row>

                          <Row className="form-group align-items-center">
                            <Label htmlFor="authentications.office365.domains" md={2}>
                              <TT>Domains</TT>
                              <ReactTooltip id="office365DomainTip">
                                <span style={{ fontSize: "0.8rem" }}>
                                <TT>Domain names used for user authentication.</TT>
                                </span>
                              </ReactTooltip>
                              <span className="helpTooltip" data-tip data-for="office365DomainTip" />
                            </Label>

                            <Col md={7}>
                              <Control
                                model=".authentications.office365.domains"
                                id="authentications.office365.domains"
                                name="authentications.office365.domains"
                                placeholder={toTTString("Office 365 Domain names")}
                                component={FWArrayEditableTable}
                                form="remoteVpnConfiguration"
                                withFieldValue
                                showRemove={true}
                                validators={{
                                  domainsIsEmpty: val => {
                                    const some = val.some(v => v.domain === '');
                                    return !some
                                  },
                                  isValidDomain: val => {
                                    const some = val.some(v => !isDomain(v.domain))
                                    return !some;
                                  },
                                  domainsWithWhitespace: val => {
                                    const some = val.some(v => v.domain.indexOf(' ') >= 0);
                                    return !some
                                  }
                                }}
                                columns={[
                                  {
                                    text: toTTString("Domain name"),
                                    dataField: "domain",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <div>
                                            <span className="form-required-star">*</span>
                                            <span><TT>Domain name</TT></span>
                                          </div>
                                          <span className="ml-2 helpTooltip" data-tip data-for="domains-header"></span>
                                          <ReactTooltip id="domains-header">
                                            <span className="font-weight-normal">
                                              <TT>Only users who have email address in this domain can access the network.</TT>
                                            </span>
                                          </ReactTooltip>
                                        </div>
                                      )
                                    }
                                  },
                                  {
                                    text: toTTString("Groups"),
                                    dataField: "groups",
                                    sort: false,
                                    editable: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", verticalAlign: "top" };
                                    },
                                    headerFormatter: (column, index, components) => {
                                      return (
                                        <div className="d-flex justify-content-between align-items-center text-nowrap">
                                          <span><TT>Group membership</TT></span>
                                          <span className="ml-2 helpTooltip" data-tip data-for="groups-header"></span>
                                          <ReactTooltip id="groups-header" place="left">
                                            <span className="font-weight-normal">
                                            <TT>Optional. Only members of specified Groups will be able to authenticate (multiple comma separated groups allowed)</TT>.
                                            </span>
                                          </ReactTooltip>
                                        </div>
                                      )
                                    }
                                  },
                                ]}
                              />
                              <Errors
                                className="text-danger"
                                model=".authentications.office365.domains"
                                show="touched"
                                messages={{
                                  domainsIsEmpty: toTTString('Domain cannot be empty'),
                                  isValidDomain: toTTString("Invalid domain"),
                                  domainsWithWhitespace: toTTString('Domain cannot contains whitespace')
                                }}
                              />

                              <Errors
                                className="text-danger"
                                model="."
                                show="touched"
                                messages={{
                                  azureEnabledWithoutDomain: toTTString('At least one domain is required')
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      )
                    },
                    {
                      title: toTTString('flexiManage'),
                      indicator: (
                        <Label className="FWswitch mr-4">
                          <Control.checkbox
                            model=".authentications.flexiManage.enabled"
                            id="authentications.flexiManage.enabled"
                            name="authentications.flexiManage.enabled"
                          />{" "}
                          <span className="FWslider round" />
                        </Label>
                      ),
                      tip: (
                        <span>
                          <TT>Use your flexiManage user that has permissions for this Organization</TT><br />
                          <TT>in order to login to the VPN server</TT> <br/>
                          <TT>instead of using OAuth Credentials</TT> <br/>
                          <TT>Recommended only for IT staff testing</TT>.
                        </span>
                      ),
                      collapse: (
                        <>
                          <small className="form-text text-muted mt-0"><TT>For IT testing purposes</TT></small>
                          <Row className="form-group align-items-center">
                            <Label md={2}>
                              <span className="form-required-star">*</span>
                              <TT>Enable</TT>
                            </Label>
                            <Col md={3} className="vpn-checkbox">
                              <Label className="FWswitch">
                                <Control.checkbox
                                  model=".authentications.flexiManage.enabled"
                                  id="authentications.flexiManage.enabled"
                                  name="authentications.flexiManage.enabled"
                                />{" "}
                                <span className="FWslider round" />
                              </Label>
                            </Col>
                          </Row>

                          { this.state.isFlexiManageAuthEnabled ? (
                            <Row className="form-group align-items-center">
                              <Label htmlFor="authentications.office365.domains" md={2}>
                                <TT>Login with flexiManage link</TT>
                              </Label>

                              <Col md={6}>
                                <Control.text
                                  model=".flexiwanLogicLink"
                                  id="flexiwanLogicLink"
                                  name="flexiwanLogicLink"
                                  value={this.state.directLink + '&authMethod=flexiwan'}
                                  autoComplete="off"
                                  component={FWTextInput}
                                  disabled
                                  withFieldValue
                                  data-lpignore="true"
                                ></Control.text>
                              </Col>
                              <Col md={{ size: 2 }} className="pr-1 align-self-center">
                                <CopyToClipboard text={this.state.directLink + '&authMethod=flexiwan'}
                                  onCopy={() => this.showToast()}>
                                  <Button size="sm" color="primary" data-tip data-for='copy-a'>
                                    <FontAwesomeIcon icon="clone" fixedWidth />
                                  </Button>
                                </CopyToClipboard>
                                <ReactTooltip id='copy-a'><span><TT>Copy Login with flexiManage Direct Link To Clipboard</TT></span></ReactTooltip>
                              </Col>
                            </Row>
                          ) : null}
                        </>
                      )
                    }
                  ]}
                />

                <hr className="mb-3" />

                <Row>
                  <Col md={3}>
                    <Control.button
                      form={"remoteVpnConfiguration"}
                      model="remoteVpnConfiguration"
                      type="submit"
                      className={"btn btn-primary action-btn-top upper-panel-button"}
                    >
                      <TT>Save Configurations</TT>
                    </Control.button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        <ToastContainer />

        <Modal size='lg' isOpen={this.state.certificatesModalOpen}>
          <ModalHeader toggle={() => this.setState({certificatesModalOpen : false})}>
            <TT>Preparing Organization Configuration</TT>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <TT>Please wait a few seconds while preparing your organization info</TT>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div className="d-flex">
                  {this.state.certificatesProcess === 1 ? (
                    <div className="text-success">
                      <TT>Done</TT>
                    </div>
                  ) : this.state.certificatesProcess === 2 ? (
                    <>
                      <div className="text-nowrap d-flex">
                        <div className="mr-2">
                          <Loading size="sm"/>
                        </div>
                        <div>
                          <TT>In progress.. Please wait</TT>
                        </div>
                      </div>
                    </>
                  ) :  this.state.certificatesProcess === 3 ? (
                    <>
                      <div className="d-flex text-danger text-nowrap align-items-center">
                        <div>
                          <TT>Error occurred.. Please try again</TT>
                        </div>
                        <div>
                        <Button
                          color={"danger"}
                          className="ml-2"
                          disabled={[1,2].includes(this.state.certificatesProcess)}
                          onClick={this.handleCreateCertificates}
                        >
                          <TT>Try Again</TT>
                        </Button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    performApplicationAction: (app, action, params, cb) => dispatch(performApplicationAction(app, action, params, cb))
  };
};

const mapStateToProps = (state) => {
  return {
    configuration: state.configuration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSaveBeforeLeaving(RemoteVpnConfigurations));
