import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import RemoteVpnDeviceStatus from '../applications/apps/remoteVpn/RemoteVpnAppStatus';
import { LanguageContext, TT } from "../../containers/Language";

class AppsCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
    };
    this.statusTimer = null;
  }

  updateDevice = () => {
    const applicationsList = [];
    this.props.getDevice(this.props.id, resp => {
      this.setState(() => {
        const { applications } = resp;
        applications.forEach(application => {
          const { app, status, configuration } = application;
          if (app) {
            const { name, identifier } = app.appStoreApp;
            applicationsList.push({
              _id: app._id,
              name: name,
              identifier: identifier,
              installedVersion: app.installedVersion,
              status: status,
              ...configuration
            });
          }
        })

        return { apps: applicationsList }
      });
    });
  }

  componentDidMount() {
    this.updateDevice()

    // Set timer for refreshing device status
    if (!this.statusTimer) {
      this.statusTimer = setInterval(that => {
        // Only update when in focus
        if (document.hasFocus()) {
          this.updateDevice()
        }
      },5000, this);
    }
  }

  componentWillUnmount() {
    // Remove device status periodic check
    if (this.statusTimer !== null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  render() {
    const toTTString = this.context.toTTString;
    const appsColumns = [
      { text: "rowID", dataField: "_id", hidden: true },
      {
        text: toTTString("Application"),
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
      },
      {
        text: toTTString("Status"),
        dataField: "status",
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex, formatExtraData) => {
          const running = <span className="text-success "><TT>Running</TT></span>;
          const stopped = <span className="text-danger "><TT>Stopped</TT></span>
          if (!formatExtraData) {
              return ['job queue failed', 'job deleted'].includes(row.status)
                ? `Application not installed, ${row.status}` : row.status
          }

          if (formatExtraData && formatExtraData[row.identifier]) {
            const app = formatExtraData[row.identifier];
            return app.running ? running : stopped
          } else {
            return stopped
          }
        },
        formatExtraData: this.props.device?.deviceStatus?.applicationStatus
      },
      {
        text: toTTString("Installed Version"),
        dataField: "installedVersion",
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "14%", textAlign: "left" };
        },
      },
      {
        text: toTTString("Actions"),
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex, formatExtraData) => {
          return (
            <div>
              <Button
                color="info"
                className="action-btn"
                data-tip
                data-for="monitoring-a"
                size="sm"
              >
                <FontAwesomeIcon icon="info" fixedWidth />
              </Button>
              <ReactTooltip id="monitoring-a">
                <span><TT>Monitoring</TT></span>
              </ReactTooltip>
            </div>
          )
        }
      }
    ];

    const expandRow = {
      renderer: row => {
        const data = this.props.device?.deviceStatus?.applicationStatus;

        let component = null;
        switch (row.identifier) {
          case 'com.flexiwan.remotevpn':
            component = (
              <>
                <Col>
                  <b><TT>Configurations</TT>:</b>
                </Col>
                <Col><TT>Subnet</TT>: {row.subnet}</Col>
                <Col><TT>Client Connections</TT>: {row.connections}</Col>
                { data && data[row.identifier] ? (
                  <>
                  <Col className="mt-4">
                    <b><TT>Monitoring</TT>:</b>
                  </Col>
                  <Col>
                    <RemoteVpnDeviceStatus data={data[row.identifier]} />
                  </Col>
                  </>
                ) : null }
              </>
            );
            break;
          default:
            component = null;
            break;
        }
        return component;
      }
    };

    return (
      <React.Fragment>
        <BootstrapTable
          striped
          hover
          condensed
          keyField="_id"
          data={this.state.apps || []}
          columns={appsColumns}
          noDataIndication={toTTString("No applications installed on this device")}
          defaultSorted={[{ dataField: "name", order: "asc" }]}
          expandRow={expandRow}
        />
      </React.Fragment>
    );
  }
}

export default AppsCard;
