import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Members = (
  state = {
    member: null,
    members: [],
    options: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.MEMBER_ADD_REQUEST:
      return { ...state, member: null };
    case Actions.MEMBER_ADD_SUCCESS:
      return { ...state, member: action.member };
    case Actions.MEMBER_ADD_FAILURE:
      return { ...state, member: null };
    case Actions.MEMBER_UPD_REQUEST:
      return { ...state, member: action.member };
    case Actions.MEMBER_UPD_SUCCESS:
      return { ...state };
    case Actions.MEMBER_UPD_FAILURE:
      return { ...state, member: null };
    case Actions.MEMBER_DEL_REQUEST:
      return { ...state, member: action.member };
    case Actions.MEMBER_DEL_SUCCESS:
      return { ...state };
    case Actions.MEMBER_DEL_FAILURE:
      return { ...state, member: null };
    case Actions.MEMBER_SELECT_REQUEST:
      return { ...state };
    case Actions.MEMBER_SELECT_SUCCESS:
      return { ...state, member: action.member };
    case Actions.MEMBER_SELECT_FAILURE:
      return { ...state };
    case Actions.MEMBER_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.MEMBER_GETALL_SUCCESS:
      return { ...state, members: action.members, isLoading: false };
    case Actions.MEMBER_GETALL_FAILURE:
      return { ...state, members: [], isLoading: false };
    case Actions.MEMBER_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.MEMBER_GET_SUCCESS:
      return { ...state, member: action.member, isLoading: false };
    case Actions.MEMBER_GET_FAILURE:
      return { ...state, member: null, isLoading: false };            
    case Actions.MEMBER_GETOPT_REQUEST:
      return { ...state, isLoading: true };
    case Actions.MEMBER_GETOPT_SUCCESS:
      return { ...state, options: action.options, isLoading: false };
    case Actions.MEMBER_GETOPT_FAILURE:
      return { ...state, options: [], isLoading: false };      
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add Member
 **********************************************************************/
export const requestAddMember = member => dispatch => {
  dispatch({
    type: Actions.MEMBER_ADD_REQUEST,
    member
  });
};

export const receiveAddMember = response => dispatch => {
  dispatch({
    type: Actions.MEMBER_ADD_SUCCESS,
    member: response
  });
  dispatch(mainRedirectTo("/members"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "User " + response.user_name + " added successfully, invitation mail sent"
    })
  );
};

export const failureAddMember = error => dispatch => {
  dispatch({
    type: Actions.MEMBER_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const addMember = (member, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members",
      method: "POST",
      body: JSON.stringify(member),
      requestCB: () => {
        dispatch(requestAddMember(member));
      },
      successCB: response => {
        dispatch(receiveAddMember(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddMember(error));
      }
    })
  );
};

/**********************************************************************
 *   Update Member
 **********************************************************************/
export const requestUpdMember = member => dispatch => {
  dispatch({
    type: Actions.MEMBER_UPD_REQUEST,
    member
  });
};

export const receiveUpdMember = response => dispatch => {
  dispatch({
    type: Actions.MEMBER_UPD_SUCCESS
  });
  dispatch(mainRedirectTo("/members"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "User " + response.user_name + " updated successfully"
    })
  );
};

export const failureUpdMember = error => dispatch => {
  dispatch({
    type: Actions.MEMBER_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const updMember = (member, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members/" + member._id,
      method: "PUT",
      body: JSON.stringify(member),
      requestCB: () => {
        dispatch(requestUpdMember(member));
      },
      successCB: response => {
        dispatch(receiveUpdMember(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdMember(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete Member
 **********************************************************************/
export const requestDelMember = member => dispatch => {
  dispatch({
    type: Actions.MEMBER_DEL_REQUEST,
    member
  });
};

export const receiveDelMember = response => dispatch => {
  dispatch({
    type: Actions.MEMBER_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "User deleted successfully"
    })
  );
};

export const failureDelMember = error => dispatch => {
  dispatch({
    type: Actions.MEMBER_DEL_FAILURE
  });
};

export const delMember = (member, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members/" + member._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelMember(member));
      },
      successCB: response => {
        dispatch(receiveDelMember(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelMember(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all members
 **********************************************************************/
export const requestGetAllMembers = () => dispatch => {
  dispatch({
    type: Actions.MEMBER_GETALL_REQUEST
  });
};

export const receiveGetAllMembers = response => dispatch => {
  dispatch({
    type: Actions.MEMBER_GETALL_SUCCESS,
    members: response
  });
};

export const failureGetAllMembers = error => dispatch => {
  dispatch({
    type: Actions.MEMBER_GETALL_FAILURE
  });
};

export const getAllMembers = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllMembers());
      },
      successCB: response => {
        dispatch(receiveGetAllMembers(response));
      },
      failureCB: error => {
        dispatch(failureGetAllMembers(error));
      }
    })
  );
};

/**********************************************************************
 *   Get member
 **********************************************************************/
export const requestGetMember = () => dispatch => {
  dispatch({
    type: Actions.MEMBER_GET_REQUEST
  });
};

export const receiveGetMember = response => dispatch => {
  dispatch({
    type: Actions.MEMBER_GET_SUCCESS,
    member: response
  });
};

export const failureGetMember = error => dispatch => {
  dispatch({
    type: Actions.MEMBER_GET_FAILURE
  });
};

export const getMember = (memberId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members/" + memberId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetMember());
      },
      successCB: response => {
        dispatch(receiveGetMember(response[0]));
        success_cb(response[0]);
      },
      failureCB: error => {
        dispatch(failureGetMember(error));
      }
    })
  );
};

/**********************************************************************
 *   Get members group, organization options
 **********************************************************************/
export const requestGetOptions = () => dispatch => {
  dispatch({
    type: Actions.MEMBER_GETOPT_REQUEST
  });
};

export const receiveGetOptions = response => dispatch => {
  dispatch({
    type: Actions.MEMBER_GETOPT_SUCCESS,
    options: response
  });
};

export const failureGetOptions = error => dispatch => {
  dispatch({
    type: Actions.MEMBER_GETOPT_FAILURE
  });
};

// Type can be 'account' 'group' or 'organization'
export const getMemberOptions = (type, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members/options/" + type,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetOptions());
      },
      successCB: response => {
        dispatch(receiveGetOptions(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetOptions(error));
      }
    })
  );
};

/**********************************************************************
 *   Reset member MFA
 **********************************************************************/
export const resetMemberMfa = (memberId, cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "members/" + memberId + '/resetMfa',
      method: "GET",
      requestCB: () => {},
      successCB: response => {
        dispatch(
          alertMsgMessage({
            color: "success",
            text: "Two-Factor Authentication removed successfully"
          })
        );

        if (cb) {
          cb();
        }
      },
      failureCB: error => {}
    })
  );
};