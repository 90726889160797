import React, { Component } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { required, maxLength, minLength, validateOrgGroupName,
  validateOrgDescription, validateTunnelRangeIP} from '../../utils/Validators';
import keyExchangeMethods from '../../utils/keyExchangeMethods';
import { FWTextInput } from '../Common';
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext  } from '../../containers/Language'
import withSaveBeforeLeaving from '../../HOC/SaveBeforeLeaving';
import { VxlanPortField } from './AddOrganization';

class UpdOrganization extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.getAllTunnels();
    this.props.getOrganization(this.props.id, resp => {
      if (resp === null) this.setState({orgFound:false});
      else {
        const { _id, name, description, group, encryptionMethod, vxlanPort, tunnelRange } = resp;
        this.props.changeForm(
          { _id, name, description, group, encryptionMethod, vxlanPort, tunnelRange },
          { silent: true }
        );
        this.props.setOrig({ _id, name, description, group, encryptionMethod, vxlanPort, tunnelRange });
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      orgFound: true,
      showEncryptMethodWarning: false,
      showVxlanWarning: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleStateKey = this.toggleStateKey.bind(this);
  }

  handleSubmit(values) {
    this.props.updOrganization(values, this.props.resetForm);
  }

  toggleStateKey(key) {
    this.setState({
      [key]: !this.state[key]
    })
  }

  render() {
    const toTTString = this.context.toTTString
    return(
      <React.Fragment>
        <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/organizations"><TT>Organizations</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>Update Organization</TT></BreadcrumbItem>
            </Breadcrumb>
        <h4><TT>Update Organization</TT></h4>
        {this.state.orgFound===false?
          <div className="alert alert-warning col-md-7" role="alert">
            <TT>Organization not found</TT>
          </div>:      
        <div className="col-lg-12 col-xl-9">
          <Card id='updOrganizationCard'>
            <CardBody>
              <Form
                model="updOrganization"
                onSubmit={(values) => this.handleSubmit(values)}
                onChange={(values) => this.props.setUpdated(values)}
              >
                <Row className="form-group">
                  <Label htmlFor="name" md={5}><TT>Organization Name</TT> <span className="helpTooltip" data-tip data-for='orgNameTip'></span>
                    <ReactTooltip id='orgNameTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>The name of the organization</TT><br />
                        <TT>Organizations belong to the account</TT><br />
                        <TT>Any network inventory is part of an organization</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.text model=".name" id="name" name="name" placeholder={toTTString("Organization Name")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {	
                          required: required,
                          minLength: minLength(2),
                          maxLength: maxLength(30),
                          name: validateOrgGroupName
                        }
                      }
                    />
                    <Errors className="text-danger" model=".name" show="touched" 
                      messages={
                        {	
                          required: toTTString('Required field'),
                          minLength: toTTString('Length must be at least 2'),
                          maxLength: toTTString('Length must be at most 30'),
                          name: toTTString('Invalid organization name format')
                        }
                      }
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="description" md={5}><TT>Description</TT> <span className="helpTooltip" data-tip data-for='orgDescTip'></span>
                    <ReactTooltip id='orgDescTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>Organization Description</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.text model=".description" id="description" name="description"
                      placeholder={toTTString("Description")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {
                          maxLength: maxLength(50),
                          description: validateOrgDescription
                        }
                      }
                    />
                    <Errors className="text-danger" model=".name" show="touched"
                      messages={
                        {
                          maxLength: toTTString('Length must be at most 50'),
                          description: toTTString('Invalid description format')
                        }
                      }
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="group" md={5}><TT>Organization Group</TT> <span className="helpTooltip" data-tip data-for='groupTip'></span>
                    <ReactTooltip id='groupTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>A name for a group of organizations</TT><br />
                        <TT>Some operations such as permissions
                        can be associated per group</TT><br />
                        <TT>If you don't know the group, keep it as Default</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.text model=".group" id="group" name="group" placeholder={toTTString("Organization Group")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {	
                          required: required,
                          minLength: minLength(2),
                          maxLength: maxLength(15),
                          name: validateOrgGroupName
                        }
                      }
                    />
                    <Errors className="text-danger" model=".group" show="touched" 
                      messages={
                        {	
                          required: toTTString('Required field'),
                          minLength: toTTString('Length must be at least 3'),
                          maxLength: toTTString('Length must be at most 15'),
                          name: toTTString('Invalid organization group format')
                        }
                      }
                    />
                  </Col>
                </Row>                
                <Row className="form-group">
                  <Label htmlFor="encryptionMethod" md={5}><TT>Tunnels Key Exchange Method</TT> 
                    <span className="helpTooltip" data-tip data-for='encryptionMethodTip'></span>
                    <ReactTooltip id='encryptionMethodTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>Tunnels Key Exchange Method
                        used in tunnels of the Organization</TT><br />
                        <TT>PSK by default</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={6}>
                    <Control.select
                      model=".encryptionMethod"
                      id="encryptionMethod"
                      name="encryptionMethod"
                      onChange={event => {
                        const self = this;
                        this.setState({ showEncryptMethodWarning: self.props.orig?.encryptionMethod !== event.target.value })
                      }}
                      placeholder={toTTString("Tunnels Key Exchange Method")}
                      className="form-control"
                    >
                      {Object.entries(keyExchangeMethods).map(([value, name]) =>
                        <option key={value} value={value}>{name}</option>
                      )}
                    </Control.select>
                    { this.state.showEncryptMethodWarning ? (
                      <div style={{ color: 'red' }}>
                        <small><TT>Changing the key exchange method does not modify existing tunnels</TT>.</small>
                        <small><TT>Tunnels should be reestablished to use the new mode</TT>.</small>
                      </div>
                    ) : null}
                  </Col>
                </Row>

                <VxlanPortField
                  onChange={event => {
                    const self = this;
                    this.setState({ showVxlanWarning: self.props.orig?.vxlanPort !== event.target.value })
                  }}
                  showEncryptMethodWarning={this.state.showVxlanWarning}
                />

                <Row className="form-group">
                  <Label htmlFor="tunnelRange" md={5}> <TT>Tunnel Range</TT> 
                    <span className="helpTooltip" data-tip data-for="tunnelRangeTip"></span>
                    <ReactTooltip id="tunnelRangeTip">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>IP range (/16) used only for tunnel loopbacks</TT>.<br/>
                        <TT>IPs on this range are not allowed elsewhere in the network</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.text model=".tunnelRange" id="tunnelRange" name='tunnelRange' 
                      placeholder="10.100.0.0"
                      component={FWTextInput} withFieldValue 
                      validators={{
                        required: required,
                        ip: validateTunnelRangeIP
                      }}/>
                    <Errors className="text-danger" model=".tunnelRange" show="touched"
                    messages={{
                      ip: toTTString("Invalid IP address"),
                      required: toTTString('Required field')
                    }}/>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col className="form-field" md={{size:10, offset: 5}}>
                    <Control.button model="updOrganization" disabled={{ valid: false }} 
                      className="btn btn-primary"><TT>Update</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>}
      </React.Fragment>
    );
  }
}

export default withSaveBeforeLeaving(UpdOrganization);