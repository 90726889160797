import React, { Component } from "react";
import { Prompt } from 'react-router';
import {
  Col,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";
import { Control, Form } from "react-redux-form";
import { Link } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import { TT, LanguageContext } from '../../containers/Language'

const QoSExplanationTable = () =>
<table border='1' className='qos-explanation-table'>
  <tbody style={{ fontSize: '0.75rem', fontWeight: 'bold', maxWidth: '600px' }}>
    <tr className='text-center' style={{ backgroundColor: '#d3d3d3', color: '#212529' }}>
      <td colspan='3'>flexiWAN QoS Queues - Explanation </td>
    </tr>
    <tr style={{ color: '#212529' }}>
      <td colspan='3'>
      <TT>The flexiWAN QoS scheduler has 2 traffic classes (Realtime and Data) and 5 service queues - </TT>
      <TT>One queue for Real time and four queues for Data.</TT>
      <TT>Each traffic category is mapped one of the queues.</TT>
      </td>
    </tr>
    <tr className='text-center' style={{ backgroundColor: '#cfe2f3' }}>
      <td><TT>Traffic Class</TT></td>
      <td><TT>Queue Name</TT></td>
      <td><TT>Description</TT></td>
    </tr>
    <tr style={{ backgroundColor: '#ffdfdf' }}>
      <td colspan='2' className='text-center'><TT>Real-Time</TT></td>
      <td><TT>To carry important conversational (Low Latency) Audio/Video</TT></td>
    </tr>
    <tr style={{ backgroundColor: '#d9ead3' }}>
      <td rowspan='4' className='text-center'><TT>Data</TT></td>
      <td><TT>Control-Signaling</TT></td>
      <td><TT>To carry control plane and important application's signaling traffic</TT></td>
    </tr>
    <tr style={{ backgroundColor: '#d9ead3' }}>
      <td><TT>Prime-Select</TT></td>
      <td><TT>To carry important application traffic</TT></td>
    </tr>
    <tr style={{ backgroundColor: '#d9ead3' }}>
      <td><TT>Standard-Select</TT></td>
      <td><TT>To carry second-level important application traffic</TT></td>
    </tr>
    <tr style={{ backgroundColor: '#d9ead3' }}>
      <td><TT>Best-Effort</TT></td>
      <td><TT>To carry other low importance traffic</TT></td>
    </tr>
  </tbody>
</table>

class QOSTrafficMap extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      origData : {}
    };
    this.handleSaveQOSTrafficMap = this.handleSaveQOSTrafficMap.bind(this);
    this.handleRestoreDefaults = this.handleRestoreDefaults.bind(this);
    this.warnOnLeavingPage = this.warnOnLeavingPage.bind(this);
    this.checkIfDataChanged = this.checkIfDataChanged.bind(this);
  }

  componentDidMount() {
    const { getQOSTrafficMap, changeQOSTrafficMapForm } = this.props;
    getQOSTrafficMap(resp => {
      changeQOSTrafficMapForm(resp, { silent: true });
      this.setState({ origData: cloneDeep(resp) });
    });
    // Listen to page reload event, to warn the user
    // upon leaving the page with unsaved changes
    window.addEventListener('beforeunload', this.warnOnLeavingPage);
  }

  componentWillUnmount(){
    // Remove page reload listener
    window.removeEventListener('beforeunload', this.warnOnLeavingPage);
  }

  checkIfDataChanged() {
    return !isEqual(this.state.origData, this.props.qosTrafficMap);
  }

  warnOnLeavingPage(event) {
    if (this.checkIfDataChanged()) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  handleSaveQOSTrafficMap(values) {
    const { updQOSTrafficMap, changeQOSTrafficMapForm } = this.props;
    updQOSTrafficMap(values, resp => {
      changeQOSTrafficMapForm(resp, { silent: true });
      this.setState({ origData: cloneDeep(resp) });
    });
  }

  handleRestoreDefaults() {
    const { updQOSTrafficMap, changeQOSTrafficMapForm } = this.props;
    updQOSTrafficMap({}, resp => {
      changeQOSTrafficMapForm(resp, { silent: true });
      this.setState({ origData: cloneDeep(resp), restoreDefaultsModalOpen: false });
    });
  }

  render() {
    const toTTString = this.context.toTTString
    const { qosTrafficMap } = this.props.qosPolicies || {};
    return (
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Policies</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/qosPolicies"><TT>QOS Policies</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>QOS Traffic Map</TT></BreadcrumbItem>
        </Breadcrumb>
        <Prompt
          message={(location, action) => {
            if (this.checkIfDataChanged()) {
              return toTTString('You have unsaved changes') + '. ' +
                toTTString('Are you sure you want to leave this page?')
            }
          }}
        />
        <h4><TT>QOS Traffic Map</TT></h4>
        <Row>
          <Col className="ml-4">
            <Control.button
              className="btn btn-primary upper-panel-button action-btn-top"
              model=".submit"
              form="qosTrafficMap"
              type="submit"
            >
              <TT>Update</TT>
            </Control.button>
            <Button
              className="btn btn-primary upper-panel-button action-btn-top"
              onClick={() => this.setState({ restoreDefaultsModalOpen: true })}
            >
              <TT>Restore Defaults</TT>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-7 col-12 ml-4 border-right">
            <Form id="qosTrafficMap" model="qosTrafficMap" onSubmit={this.handleSaveQOSTrafficMap}>
              <table className="table table-hover table-bordered table-compact">
                <thead>
                  <tr>
                    <th>
                      <TT>Service Class</TT>
                    </th>
                    <th>
                      <TT>Importance</TT>
                    </th>
                    <th>
                      <TT>Mapped QoS Service Queues</TT>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {qosTrafficMap && Object.keys(qosTrafficMap).map(serviceClass =>
                    Object.keys(qosTrafficMap[serviceClass]).map(importance =>
                    <tr key={`${serviceClass}-${importance}`}>
                      <td>
                        {serviceClass}
                      </td>
                      <td>
                        {importance}
                      </td>
                      <td>
                        <Control.select
                          model={`.${serviceClass}.${importance}`}
                          className="form-control"
                        >
                        {['Realtime', 'Control-Signaling','Prime-Select','Standard-Select','Best-Effort'].map(queueName =>
                          <option key={queueName} value={queueName.toLowerCase()}>{queueName}</option>
                        )}
                        </Control.select>
                      </td>
                    </tr>
                    )
                  )}
                </tbody>
              </table>
            </Form>
          </Col>
          <Col>
            <div className='fixed-block'>
              <QoSExplanationTable/>
            </div>
          </Col>
        </Row>
        <Modal isOpen={this.state.restoreDefaultsModalOpen} toggle={() => this.setState({ restoreDefaultsModalOpen: false })}>
          <ModalHeader toggle={() => this.setState({ restoreDefaultsModalOpen: false })}>
            <TT>Restore Defaults</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>Are you sure to restore the default QoS Map values?</TT>
            </div>
            <Button color="danger" onClick={this.handleRestoreDefaults}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={() => this.setState({ restoreDefaultsModalOpen: false })}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default QOSTrafficMap;
