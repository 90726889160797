import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// FWRoute checks:
// 1) If message was displayed for more than 10 seconds, it's removed in the next render
// 2) Allow the route only if user authenticated or route doesn't require auth,
//    otherwise, redirect to the login page
// 3) If user authenticated but no organization was set, redirect to add-organization
const FWRoute = ({ render, parentProps, ...rest }) => {
  // Allow at least 10 sec to see the message open. Route after that will cause message to disappear
  const curTime = new Date();
  if (parentProps.alertmsg.isOpen && curTime.getTime()>parentProps.alertmsg.time+10000)
    parentProps.alertMsgMessage({'color':null,'text':null});
  // If redirection set, use it. Otherwise use null = render normally
  const splitPath = parentProps.main.redirectTo? parentProps.main.redirectTo.split('?'):null
  let redirectTo = splitPath? splitPath[0]:null;
  let search = (splitPath && splitPath.length>1)? '?' + splitPath[1]:'';
  // Override redirection in the following cases:
  // 1. If route requires auth and not authenticated - redirect to login
  if (!rest.allowedNoAuth && !parentProps.auth.isAuthenticated)
    redirectTo = "/login";
  // 2. If is authenticated but no organization is set = redirect to add organization
  if (!rest.allowedNoAuth && parentProps.auth.isAuthenticated && parentProps.auth.org === null) {
    redirectTo = "/organizations/add";
  }

  return (<Route {...rest}
    render={
      // Full page redirect, replace page
      (redirectTo && redirectTo.startsWith('http'))?
      (props) => {
        window.location.replace(redirectTo);
        return null;
      }:
      // Internal system redirect
      ((redirectTo && redirectTo !== parentProps.location.pathname) ||
       (search && search !== parentProps.location.search))?
      (props) => <Redirect to={{
        pathname: redirectTo,
        search: search,
        state: { from: props.location }
      }} />:
      // Original rendering
      render} />
  )
};
export default FWRoute;