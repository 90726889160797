import React, { useState, useEffect, useRef, useContext } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { Button } from 'reactstrap';
import merge from 'lodash/merge';
import { TT, LanguageContext } from '../containers/Language';

// This JSX add the class is-valid/is-invalid to the input based on the field validity check
export const FWTextInput = ({withFieldValue, fieldValue, ...props}) => {
  const textClassesNames = ["form-control"];
  if (fieldValue.touched) {
    if (fieldValue.valid) textClassesNames.push("is-valid");
    else textClassesNames.push("is-invalid");
  }
  return(
    <input className={textClassesNames.join(" ")} {...props} />
  )
};

export const FWRange = ({ ...props }) =>
<center>
  <input
    style={{ background: props.color || 'lightgrey' }}
    type='range'
    {...props}
  />
  <span>{ props.value } %</span>
</center>

const FWArrayEditableTableComponent = ({withFieldValue, fieldValue, ...props}) => {
  const [editIndex, setEditIndex] = useState(null);
  const { toTTString } = useContext(LanguageContext);

  const textClassesNames = props.classes ? props.classes.split(" ") : ["mb-0", "layout-auto"];
  if (fieldValue.touched) {
    if (fieldValue.valid) textClassesNames.push("is-valid");
    else textClassesNames.push("is-invalid");
  }

  const model = `${props.form}.${props.name}`;

  const updateModel = newArray => {
    const updated = newArray.map(({ idx, ...v}) => v)
    props.dispatch(actions.change(model, updated))
  }

  const removeItem = (array, rowIndex) => {
    const updated = cloneDeep(array)
    updated.splice(rowIndex, 1);
    updateModel(updated)
  }

  const getEmptyRowObject = () => {
    return props.columns.reduce(function(obj,item){
      if (item.isDummyField) {
        return obj;
      }
      obj[item.dataField] = item.initialValue ?? ''
      return obj;
    }, {});
  }
  return(
    <>
      <BootstrapTable
        wrapperClasses={`mb-0 ${props.scrollXClass ? "scroll-x" : ""}`}
        striped
        hover
        condensed
        expandRow={props.expandRow ?? {}}
        rowStyle={props.rowStyle}
        classes={textClassesNames.join(' ')}
        keyField={props.keyField ?? "idx"}
        data={cloneDeep(fieldValue?.value ?? []).map((v, idx) => {
          // if incoming row doesn't have some field, we create it by merging the default empty object that has all the keys.
          const empty = getEmptyRowObject();
          const merged = merge(empty, v);
          if (!props.keyField) {
            return { ...merged, idx: idx }
          } else {
            return merged;
          }
        })}
        noDataIndication={props.noDataIndication ? props.noDataIndication : null}
        columns={(() => { // self calling function
          const res = [...props.columns];
          if (props.showRemove) {
            res.push({
              text: toTTString("Actions"),
              dataField: 'null',
              sort: false,
              editable: false,
              headerStyle: (colum, colIndex) => {
                return { textAlign: "left", verticalAlign: "top" };
              },
              formatter: (content, row, rowIndex, formatExtraData) => {
                return (
                  <>
                    <Button
                      color="danger"
                      type="button"
                      className="action-btn"
                      size="sm"
                      onClick={() => removeItem(formatExtraData, rowIndex)}
                    >
                      <FontAwesomeIcon icon="trash-alt" fixedWidth />
                    </Button>
                  </>
                )
              },
              formatExtraData: props.value
          });
          }
          return res;
        })()}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          timeToCloseMessage: 30000,
          onStartEdit: (row, column, rowIndex, columnIndex) => {
            if (typeof props.onStartEdit === 'function') props.onStartEdit(row, column, rowIndex, columnIndex);
            setEditIndex(rowIndex)
          },
          afterSaveCell: (oldValue, newValue, row, column) => {
            if (typeof props.afterSaveCell === 'function') props.afterSaveCell(oldValue, newValue, row, column);
            const updated = cloneDeep(props.value)
            updated[editIndex] = row;
            setEditIndex(null)
            updateModel(updated)
          }
        })}
      />

      {!props.hideAddButton &&
      <Button md={9}
        color="warning"
        className="btn btn-primary btn mt-2 btn-secondary"
        data-tip
        size="sm"
        onClick={() => {
          let obj = {};

          if (props.onAddClicked) {
            obj = props.onAddClicked();
          } else {
            // if no function passed, create it based on the columns dataFields
            obj = getEmptyRowObject();
          }

          props.dispatch(actions.push(model, obj))
        }}
      >
        <TT>Add</TT>
      </Button>}
    </>
  )
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export const FWArrayEditableTable = connect(null, mapDispatchToProps)(FWArrayEditableTableComponent)

export const FWPasswordInput = ({withFieldValue, fieldValue, value, ...props}) => {
  const [type, setType] = useState("text");
  const [pwValue, setPwValue] = useState('')
  const [pwValueCopy, setPwValueCopy] = useState('')

  useEffect(() => {
    setPwValueCopy(value);
  }, [value])

  const textClassesNames = ["form-control"];
  if (fieldValue.touched) {
    if (fieldValue.valid) textClassesNames.push("is-valid");
    else textClassesNames.push("is-invalid");
  }
  return(
    <div className="d-flex align-items-center">
      <input
        maxlength={props.maxLength ? props.maxLength : "30"}
        {...props}
        onBlur={() => {
          setType("text");
          setPwValue("")
        }}
        onFocus={() => {
          setType("password");
          setPwValue(pwValueCopy)
        }}
        value={pwValue}
        onChange={(e) => {
          setPwValue(e.target.value);
          setPwValueCopy(e.target.value)
          props.onPasswordChange(e.target.value)
        }}
        placeholder={pwValueCopy === '' ? (props.placeholder ? props.placeholder : "Password") : new Array(pwValueCopy.length +1).join('●')}
        type={type}
        className={textClassesNames.join(" ")}
        autocomplete="new-password"
      />
      <i className="ml-2 input-password-eye">
        <FontAwesomeIcon
          icon="eye"
          fixedWidth
          onMouseDown={() => {
            setPwValue(pwValueCopy);
          }}
          onMouseUp={() => {
            setPwValue("")
          }}
        />
      </i>
    </div>
  )
};

export const FWTextPhone = ({withFieldValue, fieldValue, ...props}) => {
  const textClassesNames = ["form-control"];
  if (fieldValue.touched) {
    if (fieldValue.valid) textClassesNames.push("is-valid");
    else textClassesNames.push("is-invalid");
  }
  return(
    <PhoneInput country={'us'} className={textClassesNames.join(" ")} {...props} />
  )
};
export const FWDataList = ({withFieldValue, fieldValue, listOptions, ...props}) => {
  const textClassesNames = ["form-control", "flexiwan-datalist"];
  if (fieldValue.touched) {
    if (fieldValue.valid) textClassesNames.push("is-valid");
    else textClassesNames.push("is-invalid");
  }
  const inputRef = useRef();
  let options = listOptions;

  // Some browsers do not show datalist options on click because of the list size
  if (options.length > 7) {
    options = options.slice(0, 7);
  }
  return(
    <React.Fragment>
      <input className={textClassesNames.join(" ")} autoComplete='off' ref={inputRef} list={"data" + props.id} {...props} />
      <datalist id={"data" + props.id}>
        {options.map(option => <option value={option.value} key={option.value}>{option.name}</option>)}
      </datalist>
    </React.Fragment>
  )
};

