import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Row, Col, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FwBootstrapTable from '../../items/FwBootstrapTable';
import { TT, LanguageContext } from '../../../containers/Language';
import ConfirmationModal from '../../items/ConfirmationModal';
import { connect } from 'react-redux';
import { getVrrpGroups, deleteVrrpGroup } from '../../../redux/reducers/HighAvailability';

const installCountMap = {
  installed: { color: "black", text: 'Installed' },
  pending: { color: "black", text: 'Pending' },
  failed: { color: "red", text: 'Failed' },
  removed: { color: "red", text: 'Job removed' },
}

const VrrpGroups = props => {
  const { toTTString } = useContext(LanguageContext);
  const { getVrrpGroups } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [vrrpGroups, setVrrpGroups] = useState([]);
  const [vrrpGroupToDelete, setVrrpGroupToDelete] = useState(null);

  const fetchVrrpGroups = useCallback(() => {
    setIsLoading(true)
    getVrrpGroups((res, err) => {
      if (res) {
        setVrrpGroups(res)
      }
      setIsLoading(false)
    });
  }, [getVrrpGroups])

  useEffect(() => {
    fetchVrrpGroups();
  }, [fetchVrrpGroups]);

  const deleteVrrpGroup = () => {
    if (!vrrpGroupToDelete) return;

    props.deleteVrrpGroup(vrrpGroupToDelete, (res, err) => {
      if (!err) {
        fetchVrrpGroups();
      }
      setVrrpGroupToDelete(null)
    })
  }

  return (
    <div className="col-md-12">
    <Row className="mt-2">
      <Col md={12} className="mb-3 d-flex align-items-center">
        <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
        <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
          onClick = {() => fetchVrrpGroups()}>
          <FontAwesomeIcon icon="sync-alt" />
        </Button>
        <Link to="/vrrp/configure">
          <Button color="success" className="action-btn-top" size="sm"><TT>New VRRP Group</TT></Button>
        </Link>
        <div>
          {isLoading ? <div className="signal m-0"></div> : null}
        </div>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <FwBootstrapTable
          data={vrrpGroups}
          columns={[
            {
              text: toTTString("Name"),
              dataField: 'name',
              sort:true,
              headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'left' };
              }
            },
            {
              text: toTTString("Virtual Router ID"),
              dataField: 'virtualRouterId',
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'left' };
              }
            },
            {
              text: toTTString("Virtual IP"),
              dataField: 'virtualIp',
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'left' };
              }
            },
            {
              text: toTTString("Devices"),
              dataField: 'devices',
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '5%', textAlign: 'left' };
              },
              formatter: (cellContent, row, rowIndex) => {
                const count = (cellContent ?? []).length;
                if (count) {
                  return (
                  <>
                      <span data-tip data-for={`${row._id}-devices`}>{count}</span>
                      <ReactTooltip id={`${row._id}-devices`}>
                        {(cellContent ?? []).map(d => d.name).join(<br />)}
                      </ReactTooltip>
                  </>
                  );
                }
                return '';
              }
            },
            {
              text: toTTString("Installation Status"),
              dataField: 'jobStatus',
              isDummyField: true,
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '8%', textAlign: 'left' };
              },
              formatter: (cellContent, row, rowIndex) => {
                const devicesCount = row.devices?.length ?? 0;
                if (!devicesCount) return '';

                const { installed, removed, failed, pending } = row.devices.reduce((prev, current) => {
                  prev[current.jobStatus]++;
                  return prev;
                }, {installed: 0, removed: 0, failed: 0, pending: 0});

                let color = null, message = null, tip = null;
                if (failed === devicesCount) {
                  color = "danger";
                  message = "Failed";
                  tip = 'Jobs were failed to install the VRRP on the selected devices'
                } else if(removed === devicesCount) {
                  color = "danger";
                  message = "Removed";
                } else if (installed === devicesCount) {
                  color = "success";
                  message = "Installed";
                } else if (pending === devicesCount) {
                  color = "warning";
                  message = "Pending";
                  tip = 'Jobs are waiting or in process to install the VRRP group on the selected devices'
                }

                if (message) {
                  return (
                    <>
                      <Badge color={color}>
                        <span data-tip data-for={`status-${row._id}`}><TT>{message}</TT></span>
                      </Badge>
                      { tip ? (
                        <ReactTooltip id={`status-${row._id}`}>
                          {tip}
                        </ReactTooltip>
                      ) : null}
                    </>
                  )
                }

                const status = [];
                Object.entries({ installed, removed, failed, pending }).forEach(([field, value]) => {
                  const { color, text } = installCountMap[field];
                  if (value !== 0) {
                    status.push(
                      <div key={field}>
                        <span style={{color: color}}>
                          <TT>{text}</TT>{`: ${value}`}
                        </span>
                      </div>
                    );
                  }
                });
                return status
              }
            },
            {
              text: toTTString("Actions"),
              dataField: 'actions',
              isDummyField: true,
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'left' };
              },
              formatter: (cellContent, row, rowIndex) => {
                return (
                  <div>
                    <Link to={"/vrrp/configure/" + row._id}>
                      <Button color="warning" className="action-btn" data-tip data-for='update-a' size="sm">
                        <FontAwesomeIcon icon="cog" fixedWidth />
                      </Button>
                    </Link>
                    <ReactTooltip id='update-a'><span><TT>Configure VRRP Group</TT></span></ReactTooltip>

                    <Link to={`/devices?vrrp=${row.name}`}>
                      <Button
                        className="action-btn"
                        data-tip
                        data-for="targets-a"
                        color="success"
                        size="sm"
                      >
                        <FontAwesomeIcon icon={["fas", "filter"]} fixedWidth/>
                      </Button>
                      <ReactTooltip id="targets-a">
                        <span><TT>Show all devices running this VRRP Group</TT></span>
                      </ReactTooltip>
                    </Link>

                    <Button color="danger" className="action-btn" data-tip data-for='delete-a' size="sm"
                      onClick={() => setVrrpGroupToDelete(row)}>
                      <FontAwesomeIcon icon="trash-alt" fixedWidth />
                    </Button>
                    <ReactTooltip id='delete-a'><span><TT>Delete VRRP Group</TT></span></ReactTooltip>
                  </div>
                );
              }
            }
          ]}
          pagination={true}
          keyField="_id"
        />

        <ConfirmationModal
          isOpen={vrrpGroupToDelete !== null}
          toggle={() => setVrrpGroupToDelete(null)}
          title={(
            <><TT>Delete</TT> {vrrpGroupToDelete?.name} <TT>VRRP Group</TT></>
          )}
          successText={vrrpGroupToDelete?.devices?.length > 0 ? "Delete" : null}
          question={(
            <div>
              {vrrpGroupToDelete?.devices?.length > 0 ? (
                <>
                  <div><TT>VRRP Group is used by the following devices:</TT></div>
                  <ol>
                    {vrrpGroupToDelete?.devices.map(d => {
                      return <li>{d.name}</li>
                    })}
                  </ol>
                  <div><TT>Please confirm deletion of the HA group</TT></div>
                </>
              ) : (
                <div className=''>
                  <TT>Are you sure you want to delete VRRP Group</TT> - "{vrrpGroupToDelete?.name}"?
                </div>
              )}
            </div>
          )}
          onConfirm={deleteVrrpGroup}
        />
      </Col>
    </Row>
  </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getVrrpGroups: cb => dispatch(getVrrpGroups(cb)),
    deleteVrrpGroup: (id, cb) => dispatch(deleteVrrpGroup(id, cb))
  }
}


export default connect(null, mapDispatchToProps)(VrrpGroups);