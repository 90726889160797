import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Label
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  convertFirewallRules,
  DestinationFormatter,
  SourceFormatter
} from "../../utils/FirewallUtils"
import { LanguageContext, TT } from '../../containers/Language'

const ruleColumns = [
  { text: <TT>ID</TT>, dataField: "_id", hidden: true },
  {
    text: <TT>Priority</TT>,
    dataField: "priority",
    hidden: true,
  },
  {
    text: <TT>Destination</TT>,
    dataField: "classification.destination",
    editable: false,
    headerStyle: (colum, colIndex) => {
      return { width: "30%", textAlign: "left" };
    },
    formatter: (cellContent, row) => <DestinationFormatter rule={row} />
  },
  {
    text: <TT>Source</TT>,
    dataField: "classification.source",
    editable: false,
    headerStyle: (colum, colIndex) => {
      return { width: "30%", textAlign: "left" };
    },
    formatter: (cellContent, row) => <SourceFormatter rule={row} />
  },
  {
    text: <TT>Action</TT>,
    dataField: "action",
    headerStyle: (colum, colIndex) => {
      return {
        width: "8%",
        textAlign: "left"
      };
    },
    formatter: (value) => {
      return value === 'allow' ? (<>
        <FontAwesomeIcon icon="check" color="green" fixedWidth/>
        <TT>Allow</TT>
      </>) : (<>
        <FontAwesomeIcon icon='ban' color="red" fixedWidth/>
        <TT>Deny</TT>
      </>);
    }
  },
  {
    text: <TT>Description</TT>,
    dataField: "description",
    editable: false,
    formatter: val => val && val.length > 20 ? val.slice(0, 20)+'...' : val,
    headerStyle: (colum, colIndex) => {
      return { width: "15%", textAlign: "left" };
    }
  },
  {
    text: "",
    dataField: "enabled",
    headerStyle: (colum, colIndex) => {
      return {
        width: "5%",
        textAlign: "left"
      };
    },
    formatter: enabled => enabled ? <>
      <FontAwesomeIcon icon="check-circle" color="green" data-tip data-for='ruleEnabled' fixedWidth/>
      <ReactTooltip id='ruleEnabled'>
        <span style={{"fontSize": "0.8rem"}}>
          <TT>The rule is enabled</TT>
        </span>
      </ReactTooltip>
    </> : <>
      <FontAwesomeIcon icon='stop-circle' color="red" data-tip data-for='ruleDisabled' fixedWidth/>
      <ReactTooltip id='ruleDisabled'>
        <span style={{"fontSize": "0.8rem"}}>
          <TT>The rule is disabled</TT>
        </span>
      </ReactTooltip>
    </>
  }
];


const FirewallRulesCard = ({ policy, appIdentifications }) => {
  const { toTTString } = useContext(LanguageContext);
  const rules = (policy.rules && appIdentifications ?
    [...(convertFirewallRules(policy.rules, appIdentifications))] : [])
    .sort((r1, r2) => r1.priority - r2.priority)
    .reduce((result, rule) => {
      result[rule.direction].push(rule);
      return result;
  }, { inbound: [], outbound: [] });

  return (
    <Card>
      <CardBody>
        <Label className="ml-3">
          <TT>Outbound rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='outboundRulesTip'></span>
          <ReactTooltip id='outboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Allowed by default</TT>
            </span>
          </ReactTooltip>
        </Label>
        <BootstrapTable
          striped
          hover
          condensed
          classes="firewall-rules-table"
          keyField="priority"
          data={rules.outbound}
          columns={ruleColumns}
          noDataIndication={toTTString("No outbound rules available")}
          defaultSorted={[{ dataField: "priority", order: "asc" }]}
          rowStyle={row => !row.enabled ? { color: 'grey' } : {}}
        />
        <Label className="ml-3">
          <TT>Inbound rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='inboundRulesTip'></span>
          <ReactTooltip id='inboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Denied by default</TT>
            </span>
          </ReactTooltip>
        </Label>
        <BootstrapTable
          striped
          hover
          condensed
          classes="firewall-rules-table"
          keyField="priority"
          data={rules.inbound}
          columns={ruleColumns}
          noDataIndication={toTTString("No outbound rules available")}
          defaultSorted={[{ dataField: "priority", order: "asc" }]}
          rowStyle={row => !row.enabled ? { color: 'grey' } : {}}
        />
      </CardBody>
    </Card>
  );
}

export default FirewallRulesCard;
