import React, { Component } from "react";
import { Badge } from "reactstrap";
import tinycolor from "tinycolor2";

class PathLabelBadge extends Component {
  render() {
    const { color, name } = this.props;
    const textColor = tinycolor(color).isLight() ? "#000000" : "#ffffff";
    return (
      <Badge
        style={{
          backgroundColor: color,
          color: textColor,
          marginRight: "5px",
          marginLeft: "5px"
        }}
        pill
      >
        {name}
      </Badge>
    );
  }
}

export default PathLabelBadge;
