import React, { useEffect, useState, useRef, useContext } from 'react';
import ReactDOMServer from "react-dom/server";
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMapEvents, useMap } from 'react-leaflet';
import { Badge, Container, Row, Col  } from 'reactstrap';
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import useSupercluster from 'use-supercluster';
import routerImg from '../../assets/images/router2-filled.png';
import { TT, LanguageContext } from '../../containers/Language';
import './FWMap.css';
const MIN_LAT = -85;
const MAX_LAT = 85;
const MIN_LNG = -180;
const MAX_LNG = 180;

function MapHandler({setZoomLevel, mapBounds, boundInit, setBound, mapView}) {
  const map = useMap();

  // Set max map bounds
  const southWest = L.latLng(MIN_LAT, MIN_LNG);
  const northEast = L.latLng(MAX_LAT, MAX_LNG);
  const bounds = L.latLngBounds(southWest, northEast);
  map.setMaxBounds(bounds);

  useEffect(() => {
    if (Object.keys(mapView).length) {
      map.setView(mapView.coords, mapView.zoom, {animate:true});
      setZoomLevel(map.getZoom());
    }
  }, [mapView, map, setZoomLevel]);

  // Track zoom level
  const mapEvents = useMapEvents({
      zoomend: () => {
        setZoomLevel(mapEvents.getZoom());
      }
  });

  // Initialize map bounds fit
  if (boundInit && mapBounds.length > 0) {
    setBound(false);
    // TBD: Generates an error but works
    // For single point, artificially open the bounds
    if (mapBounds[0][0] === mapBounds[1][0] && mapBounds[0][1] === mapBounds[1][1]) {
      mapBounds[0][0] -= 0.001;
      mapBounds[1][0] += 0.001;
    }
    map.fitBounds(mapBounds);
    setZoomLevel(map.getZoom());
  }

  return null
}

export default function FWMap ({devices, tunnels, minHeight, checkBounds=false, updCoords, ...props}) {
  const { toTTString } = useContext(LanguageContext);
  const [devicesState, setDevicesState] = useState({});
  const [tunnelsState, setTunnelsState] = useState([]);
  const [devicesPoints, setDevicesPoints] = useState([]); // Points used for supercluster

  const [zoomLevel, setZoomLevel] = useState(6);
  const [mapBounds, setMapBounds] = useState([]);
  const [boundInit, setBound] = useState(true);
  const [mapView, setMapView] = useState({});
  const prevIdsRef = useRef([]);

  useEffect(() => {
      const devicesMap = {};
      const deviceIds = [];
      const points = [];
      let minLat=MAX_LAT, maxLat=MIN_LAT, minLng=MAX_LNG, maxLng=MIN_LNG;
      const arrangeDevice = (d) => {
        const lat = Math.max(Math.min(d.coords[0],MAX_LAT),MIN_LAT);
        const lng = Math.max(Math.min(d.coords[1],MAX_LNG),MIN_LNG);
        const device = {
          ...d,
          position:{lat, lng},
          color:(d.isConnected &&
            (d.deviceState==="running" ||
            (d.deviceStatus && d.deviceStatus.state && d.deviceStatus.state === "running")))?
          'rgba(43, 121, 91, 0.884)':
          'rgba(201, 39, 11, 0.842)'
        }
        minLat = Math.min(minLat, lat);
        maxLat = Math.max(maxLat, lat);
        minLng = Math.min(minLng, lng);
        maxLng = Math.max(maxLng, lng);
        devicesMap[device._id] = device;
        deviceIds.push(d._id);
        points.push({
          type: 'Feature',
          properties: {cluster:false, deviceId: d._id},
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          }
        })
      }
      devices.forEach((d) => {arrangeDevice(d)});
      if (checkBounds) {
        // if old states device ids are different, update bounds
        const prevDeviceIds = prevIdsRef.current;
        const unionKeys = new Set(deviceIds.concat(prevDeviceIds));
        let isEq = (deviceIds.length === prevDeviceIds.length && deviceIds.length === unionKeys.size);
        if (!isEq) setBound(true);
      }
      prevIdsRef.current = deviceIds;
      setDevicesState(devicesMap);
      setDevicesPoints(points);
      if (devices.length) setMapBounds([[minLat,minLng],[maxLat,maxLng]]);
  }, [devices, tunnels, checkBounds]);

  useEffect(() => {
    setTunnelsState(tunnels);
  }, [tunnels]);

  const deviceZoom = Math.min(50, 30*(zoomLevel+1)/7);
  const deviceTranslate = `translate(0,${zoomLevel-10-deviceZoom}px)`;

  const DeviceInfo = (device) => {
    if (!device) return (<TT>Device info not found</TT>);
    const approve_info=(device.isApproved)? {"color":"success","txt":"Approved", "print": <TT>Approved</TT>}:{"color":"danger",
      "txt":"Not Approved", "print": <TT>Not Approved</TT>};
    const conn_info=(device.isConnected)? {"color":"success","txt":"Connected", "print": <TT>Connected</TT>}:{"color":"danger",
      "txt":"Not Connected", "print": <TT>Not Connected</TT>};
    const state=(device.deviceStatus?.state === 'running')? {"color":"green","txt":"Running", "print": <TT>Running</TT>}:
      (device.deviceStatus?.state === 'stopped')? {"color":"red","txt":"Not Running", "print": <TT>Not Running</TT>}:
      (device.deviceStatus?.state === 'failed')? {"color":"red","txt":"Failed", "print": <TT>Failed</TT>}:
      {"color":"inherit","txt":"Pending", "print": <TT>Pending</TT>};
    const prepareWANIPs = (interfaces) => {
      const assignedWanIfcs = interfaces.filter(i => i.type === 'WAN' && i.isAssigned);
      return `${assignedWanIfcs.slice(0, 2).map(i => i.IPv4).join(';')}${assignedWanIfcs.length > 2 ? '...' : ''}`
    }

    return(
      <>
        <Link className="device-card-name" to={"/devices/deviceinfo/" + device._id + '?tab=General'}>
          {device.name === "" ? toTTString("Unknown") : device.name}
        </Link>
        <hr style={{marginTop:0, marginBottom:0}}/>
        <Container fluid={true}>
        <Row>
          <Col className="device-card-badge-col col-md-8">
            <Badge className="device-card-badge" color={approve_info.color}>
              {approve_info.print}
            </Badge>
            <Badge className="device-card-badge" color={conn_info.color}>
              {conn_info.print}
            </Badge>
          </Col>
        </Row>
        </Container>
        <div className="device-card-status">
          <span style={{ fontWeight: "normal", marginRight: "0.2rem" }}>
            <TT>vRouter</TT>:
          </span>
          <span style={{ color: state.color }}>{state.print}</span>
        </div>
        <div className="device-card-hostname">
          {toTTString("Hostname") + ": " + device.hostname}
        </div>
        <div className="device-card-ips">
          {toTTString("WAN IPs") + `: ${prepareWANIPs(device.interfaces)}`}
        </div>
        <div className="device-card-uid">{toTTString("ID") + ": " + device.machineId}</div>
      </>
    )
  }

  // Generate clusters using supercluster
  const GetDevicesClusters = () => {
    const points = devicesPoints;
    const zoom = zoomLevel;
    let bounds = [];
    if (mapBounds.length > 0) {
      bounds = [MIN_LNG, MIN_LAT, MAX_LNG, MAX_LAT];
    }
    const { clusters, supercluster } = useSupercluster({
      points,
      bounds,
      zoom,
      options: { radius: 75, maxZoom: 18 }
    });
    // In maximum zoom - don't cluster
    let mapClusters = [];
    if (zoom === 18) mapClusters = points;
    else mapClusters = clusters;
    return {clusters:mapClusters, supercluster};
  }

  // Generate a cluster marker
  // c = the cluster object
  // sc = supercluster handler
  // devicesEqPos = keep the device equivalent position
  const generateClusterIcon = (c, sc, devicesEqPos) => {
        // Set a cluster marker
        const count = c.properties.point_count;
        const size = 40;
        // Update devices with cluster position for tunnels creation
        sc.getLeaves(c.id, Infinity, 0).forEach(d => {
          devicesEqPos[d.properties.deviceId] = {
            lat: c.geometry.coordinates[1],
            lng: c.geometry.coordinates[0]
          };
        })
        const clusterIcon = new L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'leaflet-marker-cluster leaflet-marker-cluster-medium',
          iconSize: new L.Point(size, size)
        });
        return (
          <Marker
            key={`cluster-${c.id}`}
            position={{lat: c.geometry.coordinates[1], lng:c.geometry.coordinates[0]}}
            icon={clusterIcon}
            eventHandlers={{
              click: (e) => {
                const expansionZoom = Math.min(
                  sc.getClusterExpansionZoom(c.id),
                  18
                );
                setMapView({coords:[c.geometry.coordinates[1], c.geometry.coordinates[0]], zoom:expansionZoom});
              },
            }}
          />
        )
  }

  // Generate a device marker
  // c = the cluster objectr
  // devicesEqPos = keep the device equivalent position
  const generateDeviceIcon = (c, devicesEqPos) => {
    const d = devicesState[c.properties.deviceId];
    devicesEqPos[d._id] = d.position;
    const icon = new L.DivIcon({
      className: 'leaflet-div-icon',
      popupAnchor: new L.Point(0, -5),
      html: ReactDOMServer.renderToStaticMarkup(
        <div className="leaflet-div-icon-div" style={{transform:deviceTranslate}}>
          <span
            className="leaflet-div-icon-span"
            style={{'backgroundColor': d.color}}
          >{d.name}</span>
          <image
            src={routerImg}
            style={{width:deviceZoom, height:deviceZoom}}
          />
        </div>)
    });
    return(
      <Marker
        draggable={true}
        position={d.position}
        icon={icon}
        key={d._id}
        eventHandlers={{
          dragend: (marker) => {
            const {[d._id] : curDevice, ...rest} = devicesState;
            const device = Object.assign({}, curDevice);
            const latLng = marker.target.getLatLng();
            // Adjust to map limit if dragged too far
            latLng.lat = Math.max(Math.min(latLng.lat,MAX_LAT),MIN_LAT);
            latLng.lng = Math.max(Math.min(latLng.lng,MAX_LNG),MIN_LNG);
            device.position = latLng;
            setDevicesState({...rest, [d._id]:device});
            setDevicesPoints(devicesPoints.map((p) =>
              (p.properties.deviceId === d._id)
              ?{...p, geometry:{...p.geometry, coordinates:[latLng.lng,latLng.lat]}}
              :{...p}
            ));
            updCoords(device._id, {coords:[device.position.lat, device.position.lng]});
          },
        }}
      >
        <Popup minWidth={90}>
          <span>{DeviceInfo(devicesState[d._id])}</span>
        </Popup>
      </Marker>
    )
  }

  const generateTunnelLines = (devicesEqPos) => {
    const tunnels = [];
    const tunnelPositions = {};

    const normalizePositions = (aLat, aLng, bLat, bLng) => {
      return (aLat > bLat)? [[aLat, aLng], [bLat, bLng]]:
             (aLat < bLat)? [[bLat, bLng], [aLat, aLng]]:
             (aLng < bLng)? [[bLat, bLng], [aLat, aLng]]:
             [[aLat, aLng], [bLat, bLng]];
    };
    if (Object.keys(devicesEqPos).length) {
      tunnelsState.forEach((t) => {
        const deviceA = devicesState[t.deviceA._id];
        const deviceB = (t.deviceB)? devicesState[t.deviceB._id]: null;
        if (deviceA && deviceB && devicesEqPos[deviceA._id] && devicesEqPos[deviceB._id]) {
          const positions = normalizePositions(
            devicesEqPos[deviceA._id].lat, devicesEqPos[deviceA._id].lng,
            devicesEqPos[deviceB._id].lat, devicesEqPos[deviceB._id].lng);
          const isConnected = t.deviceAconf && t.deviceBconf
            && Object.keys(t.tunnelStatusA).length!==0
            && Object.keys(t.tunnelStatusB).length!==0
            && t.tunnelStatusA.status === "up" && t.tunnelStatusB.status === "up";
          const posKey = JSON.stringify(positions);
          if (tunnelPositions.hasOwnProperty(posKey)) {
            // if prev tunnel not connected and new tunnel is connected, add it on top
            if (!tunnelPositions[posKey] && isConnected) {
              tunnels.push(
                <Polyline
                  key={t._id}
                  pathOptions={isConnected? { color: 'green'}:{ color: 'red'}}
                  positions={positions}
                />
              );
            }
          } else {
            // new tunnel positions, add it
            tunnels.push(
              <Polyline
                key={t._id}
                pathOptions={isConnected? { color: 'green'}:{ color: 'red'}}
                positions={positions}
              />
            );
          }
          tunnelPositions[posKey] = isConnected;
        }
      });
    }
    return (tunnels);
  }

  const DeviceMarkersAndTunnels = () => {
    const { clusters, supercluster } = GetDevicesClusters();
    const devicesEqPos = {};
    const markers = clusters.map(c => {
      if (c.properties.cluster) {
        return generateClusterIcon(c, supercluster, devicesEqPos);
      }
      // Set to device icon
      return generateDeviceIcon(c, devicesEqPos);
    });
    // Create Tunnel Lines
    markers.push(generateTunnelLines(devicesEqPos));
    return markers;
  }

  return (
      <MapContainer
        className="map-container"
        center={[50.2216975625543, 8.033202588558199]}
        zoom={6}
        minZoom={3}
        scrollWheelZoom={true}
        style={{minHeight:minHeight || '300px'}}
        {...props}
        //bounds={mapBounds}
      >
        <MapHandler
          setZoomLevel={setZoomLevel}
          mapBounds={mapBounds}
          boundInit={boundInit}
          setBound={setBound}
          mapView={mapView}/>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        />
        <DeviceMarkersAndTunnels/>
      </MapContainer>
  );
}