import React, {useContext, useEffect} from "react";
import { Card, CardBody, Row, Label, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Control, Form, Errors } from 'react-redux-form';
import { connect } from 'react-redux';
import { FWTextInput } from '../Common';
import { isIPList, isDomainList } from '../../utils/Validators';
import { TT, LanguageContext } from '../../containers/Language'

const StaticDnsModal = props => {
  const { toTTString } = useContext(LanguageContext);

  useEffect(() => { // run it once component loaded
    props.changeForm({
      dnsServers: props.ifc.dnsServers.join(', '),
      dnsDomains: props.ifc.dnsDomains.join(', '),
      useDhcpDnsServers: props.ifc.useDhcpDnsServers
    })

    return () => { // cleanup function
      props.resetForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>
        <TT>DNS settings</TT>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form
              model="staticDns"
              onSubmit={props.onSubmit}
              validators={{
                '': {
                  serversRequired: vals => {
                    if (props.ifc.dhcp === 'yes' && vals.useDhcpDnsServers === false && vals.dnsServers === '') {
                      return false;
                    }

                    if (props.ifc.dhcp === 'no' && vals.dnsServers === '') {
                      return false;
                    }

                    return true;
                  }
                }
              }}
            >
              {props.ifc.dhcp === 'yes'? (
                <Row className="form-group">
                  <Label htmlFor="useDhcpDnsServers" md={5}>
                    <TT>Get DNS via DHCP</TT>
                  </Label>
                  <Col md={6}>
                    <Label className="FWswitch">
                      <Control.checkbox
                        model=".useDhcpDnsServers"
                        id="useDhcpDnsServers"
                        name="useDhcpDnsServers"
                      />{" "}
                      <span className="FWslider round" />
                    </Label>
                  </Col>
                </Row>
              ) : null}

              <Row className="form-group">
                <Label htmlFor="dns" md={5}>
                  {
                    props.form.useDhcpDnsServers === false || props.ifc.dhcp === 'no'? (
                      <span className="form-required-star">*</span>
                    ) : null
                  }
                  <TT>DNS Servers</TT></Label>
                <Col md={7}>
                  <Control.text
                    type="text"
                    model=".dnsServers"
                    id="dnsServers"
                    name="dnsServers"
                    placeholder={toTTString("DNS servers")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      ipList: val => val === '' || isIPList(val)
                    }}
                    disabled={props.form.useDhcpDnsServers === true && props.ifc.dhcp === 'yes'}
                  />
                  <Errors
                    className="text-danger"
                    model=".dnsServers"
                    show="touched"
                    messages={{ ipList: toTTString('Invalid IPv4 Addresses')}}
                  />
                  <Errors
                    className="text-danger"
                    model="staticDns"
                    show="touched"
                    messages={{ serversRequired: toTTString('Required field')}}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="dnsDomains" md={5}>
                  <TT>Search Domains</TT>
                </Label>
                <Col md={7}>
                  <Control.text
                    type="text"
                    model=".dnsDomains"
                    id="dnsDomains"
                    name="dnsDomains"
                    placeholder={toTTString("Search domains list")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{ isDomainList: val => val === '' || isDomainList(val, false) }}
                  />
                  <Errors
                    className="text-danger"
                    model=".dnsDomains"
                    show="touched"
                    messages={{ isDomainList: toTTString('Invalid domains list')}}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col xs={6} md={{ size: 4, offset: 5 }}>
                  <Control.button
                    model="staticDns"
                    disabled={{ valid: false }}
                    className="btn btn-success btn-ok"
                  >
                    <TT>OK</TT>
                  </Control.button>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={props.toggle}
                    type="button"
                    className="btn btn-outline-secondary pull-right"
                  >
                    <TT>Cancel</TT>
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    form: state.staticDns
  }
}

export default connect(mapStateToProps, null)(StaticDnsModal)