import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import logoImg from "../assets/images/logot.png";
import './About.css';
import { TT } from '../containers/Language';
const { companyName, aboutContent } = window.SERVER_CONFIG;

function About({getVersion, getDeviceLatestVer}) {
  const [managementVersion, setVersion] = React.useState('');
  const [managementLatestVersions, setLatestVersions] = React.useState(null);
  React.useEffect(() => {
    getVersion(setVersion);
    getDeviceLatestVer(setLatestVersions);
  }, [getVersion, getDeviceLatestVer]);

  return(
        <React.Fragment>
          <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>About</TT></BreadcrumbItem>
            </Breadcrumb>
          <h4><TT>About</TT> {companyName}</h4>
          <div className="aboutMainDiv col-md-8">
            <Card className="aboutCardTitleVersion">
              <CardBody>
                <div>
                  <img src={logoImg} height="30" alt={companyName} />
                  <p><TT>Management version</TT> : {managementVersion}</p>
                  <p><TT>Latest device version</TT>: {managementLatestVersions?.versions?.device}</p>
                  <p><TT>Automatic upgrade deadline</TT>: {(managementLatestVersions && managementLatestVersions.versionDeadline) ? new Date(managementLatestVersions.versionDeadline).toLocaleString('en-US', { year: 'numeric',month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'}) : new Date().toLocaleString('en-US', { year: 'numeric',month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'})}</p>
                </div>
              </CardBody>
            </Card>
            <Card className="aboutCardText">
              <CardBody>
                {
                  // show the about content if it is defined
                  aboutContent ? <div dangerouslySetInnerHTML= {{__html: aboutContent}} ></div> :
                  <>
                    <div className="aboutText">
                      <p><TT tid="aboutText">flexiWAN changes the closeness and vendor lock paradigm of
                      SD-WAN by offering an open source SD-WAN infrastructure that includes
                      the vRouter, management, orchestration and automation
                      as well as core SD-WAN baseline functionality.</TT></p>
                    </div>
                    <div>
                      <p><TT>Product documentation</TT> <a href="https://docs.flexiwan.com/" target="_blank" rel="noopener noreferrer">
                        https://docs.flexiwan.com/</a></p>
                      <p><TT tid="contactUsMail">To contact us please drop us an email at</TT> <a href="mailto:yourfriends@flexiwan.com">yourfriends@flexiwan.com</a></p>
                      <p><TT>Learn more at</TT> <a href="https://flexiwan.com/" target="_blank" rel="noopener noreferrer">
                        https://flexiwan.com/</a>
                      </p>
                    </div>
                  </>
                }
              </CardBody>
            </Card>
            <Card className="aboutCardText">
              <CardBody>
                {
                  <>
                    <b>Administrators (Requires Permission):</b>
                      <ul>
                        <li><Link to={`/admin`}>Admin Page</Link> - System Management</li>
                        <li><Link to={`/tickets`}>Tickets</Link> - System tickets</li>
                      </ul>
                  </>
                }
              </CardBody>
            </Card>
          </div>
        </React.Fragment>
        );
}
export default About;