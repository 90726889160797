import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Row, Label, Col } from "reactstrap";
import { Control, Form, actions, Errors } from "react-redux-form";
import { TT, LanguageContext } from '../../containers/Language'
import { modifyDeviceHardware, getDevice } from '../../redux/reducers/Devices'
import ReactTooltip from 'react-tooltip';
import { isInteger, minValue, maxValue } from '../../utils/Validators';

const HardwareConfiguration = props => {
  const { toTTString } = useContext(LanguageContext);
  const { changeForm, resetForm, onFinished, getDevice } = props;

  const [isRequiredReboot, setIsRequiredReboot] = useState(false);
  const [cpuInfo, setCpuInfo] = useState({});

  useEffect(() => {
    getDevice(props.deviceId, device => {
      setCpuInfo(device.cpuInfo)
      changeForm({ cpuInfo: device.cpuInfo }, { silent: true });
    });

    return () => {
      resetForm()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    if (onFinished) {
      onFinished();
    }
  }

  const handleSubmit = vals => {
    props.modifyDeviceHardware(props.deviceId, vals)
    if (onFinished) {
      onFinished();
    }
  }

  return (
    <>
      <Form
        id="hardwareConfigurationForm"
        model="hardwareConfiguration"
        onChange={vals => {
          const newVppCors = vals?.cpuInfo?.configuredVppCores;
          if (newVppCors > cpuInfo?.grubCores) {
            setIsRequiredReboot(true);
          } else {
            setIsRequiredReboot(false);
          }
        }}
        onSubmit={handleSubmit}
      >
        <Row className="form-group">
          <Label htmlFor="hwCores" md={5}>
            <TT>HW CPU cores</TT>
          </Label>
          <Col md={7}>
            <Control.text
              model=".cpuInfo.hwCores"
              className="form-control"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Label htmlFor="vppCores" md={5}>
            <TT>Current vRouter cores</TT>
          </Label>
          <Col md={7}>
            <Control.text
              model=".cpuInfo.vppCores"
              className="form-control"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Label htmlFor="configuredVppCores" md={5}>
            <TT>vRouter cores</TT>
            <span className="helpTooltip ml-3 mr-3" data-tip data-for='configuredVppCores'></span>
            <ReactTooltip id='configuredVppCores'>
              <span style={{"fontSize": "0.8rem"}}>
                <TT>vRouter cores can be set to number of HW core - 1</TT>
              </span>
            </ReactTooltip>
          </Label>
          <Col md={7}>
            <Control.text
              className="form-control"
              type="number"
              min={1}
              max={cpuInfo.hwCores && cpuInfo.hwCores > 1 ? cpuInfo.hwCores - 1 : 1}
              model=".cpuInfo.configuredVppCores"
              onChange={e => {
                const newCpuInfo = {
                  ...cpuInfo,
                  configuredVppCores: e.target.value,
                  powerSaving: e.target.value > 1 ? false : cpuInfo.powerSaving
                };
                setCpuInfo(newCpuInfo);
                changeForm({ cpuInfo: newCpuInfo }, { silent: true });
              }}
              validators={{
                isInteger,
                minValue: val => val === "" || minValue(1)(val),
                maxValue: val => val === "" || maxValue(cpuInfo?.hwCores-1)(val)
              }}
            />
            <Errors
              className="text-danger"
              model=".cpuInfo.configuredVppCores"
              show="touched"
              messages={{
                required: toTTString('Required field'),
                isInteger: toTTString('Invalid number'),
                minValue: toTTString("vRouter cores value must be greater than or equal to 1"),
                maxValue: toTTString('vRouter cores can be set to number of HW core - 1'),
              }}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Label htmlFor="powerSaving" md={5}>
            <TT>Power Saving</TT>
            <span className="helpTooltip ml-3 mr-3" data-tip data-for='powerSavingTooltip'></span>
            <ReactTooltip id='powerSavingTooltip'>
              <span style={{"fontSize": "0.8rem"}}>
                <TT>Power saving should be disabled if multicore is used</TT>
              </span>
            </ReactTooltip>
          </Label>
          <Col md={7}>
            <Label className="FWswitch">
              <Control.checkbox
                model=".cpuInfo.powerSaving"
                disabled={cpuInfo.configuredVppCores > 1}
              />{" "}
              <span className="FWslider round" />
            </Label>
          </Col>
        </Row>

        { isRequiredReboot ? (
          <Row className="form-group">
            <Col>
            <div
              id="device-send-alert"
              className="alert alert-warning col-md-12 mb-0"
              role="alert"
            >
              <span><TT>Warning! This Change will reboot the device! Do you want to proceed?</TT></span>
            </div>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col md={{ offset: 5, size: 7 }}>
            <Control.button
              form="hardwareConfigurationForm"
              type="submit"
              model="hardwareConfiguration"
              disabled={{ valid: false }}
              className={"btn btn-primary action-btn-top upper-panel-button"}
            >
              <TT>Apply Configuration</TT>
            </Control.button>

            <button
              onClick={onCancel}
              type="button"
              className="btn btn-outline-secondary action-btn-top upper-panel-button"
            >
              <TT>Cancel</TT>
            </button>
          </Col>
        </Row>

      </Form>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    changeForm: vals => dispatch(actions.change('hardwareConfiguration', vals)),
    resetForm: () => dispatch(actions.reset('hardwareConfiguration')),
    modifyDeviceHardware: (deviceId, data) => dispatch(modifyDeviceHardware(deviceId, data)),
    getDevice: (deviceId, cb) => dispatch(getDevice(deviceId, cb))
  }
}

export default connect(null, mapDispatchToProps)(HardwareConfiguration);