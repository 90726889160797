import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import { mainSidebarToggle, mainRedirectTo } from '../redux/reducers/Main';
import {
  sidebarConfigDropdownToggle,
  sidebarDashboardDropdownToggle,
  sidebarTroubleshootDropdownToggle,
  sidebarAccountsDropdownToggle,
  sidebarUsersDropdownToggle,
  sidebarTrafficOptDropdownToggle,
  sidebarApplicationsDropdownToggle,
  sidebarSecurityDropdownToggle,
  sidebarHighAvailabilityDropdownToggle,
} from "../redux/reducers/SideBar";
import { alertMsgMessage } from '../redux/reducers/AlertMsg';

import {
  logoutUser,
  createUser,
  verifyAccount,
  reverifyAccount,
  resetPasswordAccount
} from "../redux/reducers/Auth";
import {
  getDevice,
  updDevice,
  delDevice,
  sendDevice,
  getAllDevices,
  clearDevices,
  startDevice,
  stopDevice,
  tunnelDevices,
  upgradeDevices,
  getDeviceLatestVer,
  schedDeviceUpgrade,
  getDeviceConfig,
  getDeviceLogs,
  getDevicePacketTraces,
  getDeviceRoutes,
  getDeviceTunnels,
  saveDeviceStaticRoutes,
  saveDeviceInterfaces,
  saveDHCP,
  saveDeviceFirewallRules,
  installDevPolicy,
  resetDevice,
  syncDevice,
  getDeviceStatus,
  getInterfaceStatus,
  performInterfaceAction,
  saveInterfaceConfiguration,
  saveOSPF,
  updCoords,
  saveBGP,
  saveRoutingFilters,
  saveAdvancedRouting,
} from "../redux/reducers/Devices";

import { getToken, addToken, updToken, delToken, getAllTokens } from '../redux/reducers/Tokens';
import {
  getAllAppIdentifications,
  getInstalledAppIdentifications,
  appIdentificationUpdateDevices,
  addAppIdentification,
  getAppIdentification,
  getCustomAppIdentification,
  delAppIdentification,
  getAllImportedAppIdentifications,
  addAppIdentificationRule,
  deleteAppIdentificationRule,
  updateAppIdentification,
  updateCustomAppIdentification,
  resetAppIdentification
} from '../redux/reducers/AppIdentifications';
import {
  getAllPathLabels,
  getPathLabel,
  addPathLabel,
  updPathLabel,
  delPathLabel
} from "../redux/reducers/PathLabels";
import {
  getAllMLPolicies,
  getMLPoliciesList,
  getMLPoliciesMetaData,
  getMLPolicy,
  addMLPolicy,
  updMLPolicy,
  delMLPolicy
} from "../redux/reducers/MLPolicies";
import {
  getAllFirewallPolicies,
  getFirewallPoliciesList,
  getFirewallPoliciesMetaData,
  getFirewallPolicy,
  addFirewallPolicy,
  updFirewallPolicy,
  delFirewallPolicy
} from "../redux/reducers/FirewallPolicies";
import {
  getAllQOSPolicies,
  getQOSPoliciesList,
  getQOSPoliciesMetaData,
  getQOSPolicy,
  addQOSPolicy,
  updQOSPolicy,
  delQOSPolicy,
  getQOSTrafficMap,
  updQOSTrafficMap
} from "../redux/reducers/QOSPolicies";
import { getOrganization, addOrganization, updOrganization, delOrganization, selectOrganization,
  getAllOrganizations, getOrganizationSummary } from '../redux/reducers/Organizations';
import { getAccount, selectAccount, getAllAccounts, updAccount, getAccountSubscriptionStatus
} from '../redux/reducers/Accounts';
import {
  getMember, addMember, updMember, delMember, getAllMembers, getMemberOptions, resetMemberMfa
} from '../redux/reducers/Members';

// billing
import { getAllInvoices } from '../redux/reducers/Invoices';
import { addCoupon, delCoupon } from '../redux/reducers/Coupons';
import { openPortal } from "../redux/reducers/Portals";

// billing
import { getAllPeers, savePeer, deletePeer } from '../redux/reducers/Peers';

import { getAccessToken, addAccessToken, delAccessToken, getAllAccessTokens } from '../redux/reducers/AccessTokens';
import { getAllTunnels, delTunnels, clearTunnels, modifyTunnelsNotifications } from '../redux/reducers/Tunnels';
import { getTraffic, getTunnelTraffic, setSelectOptions } from '../redux/reducers/Traffic';
import { getHealth } from '../redux/reducers/Health';
import { getJobs, delJobs, deleteAllJobs } from '../redux/reducers/Jobs';
import {
  getNotifications,
  updateNotifications,
  deleteNotifications,
  getNotificationsMetadata,
} from "../redux/reducers/Notifications";
import { getNotificationsConf, updateNotificationsConf, getEmailNotificationsConf,updateEmailNotificationsConf,getDefaultNotificationsConf, updateDefaultNotificationsConf, getWebHookSettings, modifyWebHookSettings} from "../redux/reducers/NotificationsConf"
import {
  getRepositoryApplications,
  getPurchasedApplications,
  purchaseApplication,
  removeApplication,
  updateApplication,
  getPurchasedApplication,
  upgradeApplicationOnDevice,
  getApplicationStatus,
} from '../redux/reducers/Applications';

import { getAdmin } from '../redux/reducers/Admin';
import { getTickets, getTicketComments } from '../redux/reducers/Tickets';
import { getVersion } from '../redux/reducers/Version';
import Header from './Header';
import SideBar from './SideBar';
import MainContent from './MainContent';
import { getRestServersList } from '../redux/reducers/Configuration'

const mapStateToProps = (state) => {
  return {
    main: state.main,
    sidebar: state.sidebar,
    auth: state.auth,
    alertmsg: state.alertmsg,
    devices: state.devices,
    tokens: state.tokens,
    tunnels: state.tunnels,
    peers: state.peers,
    traffic: state.traffic,
    health: state.health,
    jobs: state.jobs,
    notifications: state.notifications,
    notificationsConf: state.notificationsConf,
    admin: state.admin,
    tickets: state.tickets,
    organizations: state.organizations,
    accounts: state.accounts,
    members: state.members,
    invoices: state.invoices,
    coupons: state.coupons,
    portal: state.portal,
    accesstokens: state.accesstokens,
    pathlabels: state.pathlabels,
    subscriptionStatus: state.subscriptionStatus,
    mlpolicies: state.mlpolicies,
    firewallPolicies: state.firewallPolicies,
    firewallPolicy: state.firewallPolicy,
    firewallRule: state.firewallRule,
    qosPolicies: state.qosPolicies,
    qosPolicy: state.qosPolicy,
    qosTrafficMap: state.qosTrafficMap,
    appIdentifications: state.appIdentifications,
    importedAppIdentifications: state.importedAppIdentifications,
    newRules: state.newRules,
    deviceInfo: state.deviceInfo,
    applications: state.applications,
  };
};

const mapDispatchToProps = (dispatch) => ({
  mainSidebarToggle: (isAuthenticated) => dispatch(mainSidebarToggle(isAuthenticated)),
  mainRedirectTo: (redirectTo) => dispatch(mainRedirectTo(redirectTo)),
  sidebarConfigDropdownToggle: () => dispatch(sidebarConfigDropdownToggle()),
  sidebarDashboardDropdownToggle: () => dispatch(sidebarDashboardDropdownToggle()),
  sidebarTroubleshootDropdownToggle: () => dispatch(sidebarTroubleshootDropdownToggle()),
  sidebarAccountsDropdownToggle: () => dispatch(sidebarAccountsDropdownToggle()),
  sidebarUsersDropdownToggle: () => dispatch(sidebarUsersDropdownToggle()),
  sidebarTrafficOptDropdownToggle: () => dispatch(sidebarTrafficOptDropdownToggle()),
  sidebarApplicationsDropdownToggle: () => dispatch(sidebarApplicationsDropdownToggle()),
  sidebarSecurityDropdownToggle: () => dispatch(sidebarSecurityDropdownToggle()),
  sidebarHighAvailabilityDropdownToggle: () => dispatch(sidebarHighAvailabilityDropdownToggle()),
  logoutUser: () => dispatch(logoutUser()),
  createUser: (values, success_cb, failure_cb) => dispatch(createUser(values, success_cb, failure_cb)),
  verifyAccount: (values) => dispatch(verifyAccount(values)),
  reverifyAccount: (values) => dispatch(reverifyAccount(values)),
  resetPasswordAccount: (values) => dispatch(resetPasswordAccount(values)),
  updDevice: (success_cb, failure_cb, allowOverlapping = false) => dispatch(updDevice(success_cb, failure_cb, allowOverlapping)),
  delDevice: (device, success_cb) => dispatch(delDevice(device, success_cb)),
  sendDevice: (device, success_cb, error_cb) => dispatch(sendDevice(device, success_cb, error_cb)),
  startDevice: (device, success_cb) => dispatch(startDevice(device, success_cb)),
  stopDevice: (device, success_cb) => dispatch(stopDevice(device, success_cb)),
  tunnelDevices: (devices, pathLabels, peerIds, topology, hub, options, success_cb) =>
    dispatch(tunnelDevices(devices, pathLabels, peerIds, topology, hub, options, success_cb)),
  upgradeDevices: (devices, success_cb) => dispatch(upgradeDevices(devices, success_cb)),
  schedDeviceUpgrade: (devices, sched, success_cb) => dispatch(schedDeviceUpgrade(devices, sched, success_cb)),
  getDeviceLatestVer: (devices, success_cb) => dispatch(getDeviceLatestVer(devices, success_cb)),
  getDevice: (deviceId, success_cb) => dispatch(getDevice(deviceId, success_cb)),
  getDeviceConfig: (deviceId, success_cb) => dispatch(getDeviceConfig(deviceId, success_cb)),
  getDeviceLogs: (deviceId, params, success_cb, error_cb) => dispatch(getDeviceLogs(deviceId, params, success_cb, error_cb)),
  getDevicePacketTraces: (deviceId, params, success_cb) => dispatch(getDevicePacketTraces(deviceId, params, success_cb)),
  getDeviceRoutes: (deviceId, success_cb) => dispatch(getDeviceRoutes(deviceId, success_cb)),
  getDeviceTunnels: (deviceId, success_cb=null) => dispatch(getDeviceTunnels(deviceId, success_cb)),
  saveDeviceStaticRoutes: (routes) => dispatch(saveDeviceStaticRoutes(routes)),
  saveDeviceFirewallRules: (rules) => dispatch(saveDeviceFirewallRules(rules)),
  getInterfaceStatus: (devId, intId, cb) => dispatch(getInterfaceStatus(devId, intId, cb)),
  performInterfaceAction: (devId, intId, data, cb=null, success_msg = null, req_msg = null) => dispatch(performInterfaceAction(devId, intId, data, cb, success_msg, req_msg)),
  saveInterfaceConfiguration: (intId, config, success_msg) => dispatch(saveInterfaceConfiguration(intId, config, success_msg)),
  getAllDevices: (params, success_cb) => dispatch(getAllDevices(params, success_cb)),
  clearDevices: () => dispatch(clearDevices()),
  saveDeviceInterfaces: (interfaces) => dispatch(saveDeviceInterfaces(interfaces)),
  getToken: (tokenId, success_cb) => dispatch(getToken(tokenId, success_cb)),
  addToken: (token, success_cb) => dispatch(addToken(token, success_cb)),
  updToken: (token, success_cb) => dispatch(updToken(token, success_cb)),
  delToken: (token, success_cb) => dispatch(delToken(token, success_cb)),
  saveDHCP: (dhcp) => dispatch(saveDHCP(dhcp)),
  saveOSPF: (ospf) => dispatch(saveOSPF(ospf)),
  updCoords: (id, coords) => dispatch(updCoords(id, coords)),
  saveBGP: (bgp) => dispatch(saveBGP(bgp)),
  saveAdvancedRouting: advancedRouting => dispatch(saveAdvancedRouting(advancedRouting)),
  saveRoutingFilters: (routingFilters) => dispatch(saveRoutingFilters(routingFilters)),
  getAllPathLabels: (success_cb) => dispatch(getAllPathLabels(success_cb)),
  getPathLabel: (pathLabelId, success_cb) => dispatch(getPathLabel(pathLabelId, success_cb)),
  addPathLabel: (pathLabel, success_cb) => dispatch(addPathLabel(pathLabel, success_cb)),
  updPathLabel: (pathLabel, success_cb) => dispatch(updPathLabel(pathLabel, success_cb)),
  delPathLabel: (pathLabel, success_cb) => dispatch(delPathLabel(pathLabel, success_cb)),
  getAllMLPolicies: (success_cb) => dispatch(getAllMLPolicies(success_cb)),
  getMLPoliciesList: (success_cb) => dispatch(getMLPoliciesList(success_cb)),
  getMLPolicy: (mlPolicyId, success_cb) => dispatch(getMLPolicy(mlPolicyId, success_cb)),
  addMLPolicy: (mlPolicy, appsMap, success_cb) => dispatch(addMLPolicy(mlPolicy, appsMap, success_cb)),
  updMLPolicy: (mlPolicy, appsMap, success_cb) => dispatch(updMLPolicy(mlPolicy, appsMap, success_cb)),
  delMLPolicy: (mlPolicy, success_cb) => dispatch(delMLPolicy(mlPolicy, success_cb)),
  getMLPoliciesMetaData: (success_cb) => dispatch(getMLPoliciesMetaData(success_cb)),
  installDevPolicy: (devices, policy) => dispatch(installDevPolicy(devices, policy)),
  getAllFirewallPolicies: (success_cb) => dispatch(getAllFirewallPolicies(success_cb)),
  getFirewallPoliciesList: (success_cb) => dispatch(getFirewallPoliciesList(success_cb)),
  getFirewallPolicy: (policyId, success_cb) => dispatch(getFirewallPolicy(policyId, success_cb)),
  addFirewallPolicy: (policy, appsMap, success_cb) => dispatch(addFirewallPolicy(policy, appsMap, success_cb)),
  updFirewallPolicy: (policy, appsMap, success_cb) => dispatch(updFirewallPolicy(policy, appsMap, success_cb)),
  delFirewallPolicy: (policy, success_cb) => dispatch(delFirewallPolicy(policy, success_cb)),
  getFirewallPoliciesMetaData: (success_cb) => dispatch(getFirewallPoliciesMetaData(success_cb)),
  getAllQOSPolicies: (success_cb) => dispatch(getAllQOSPolicies(success_cb)),
  getQOSPoliciesList: (success_cb) => dispatch(getQOSPoliciesList(success_cb)),
  getQOSPolicy: (policyId, success_cb) => dispatch(getQOSPolicy(policyId, success_cb)),
  addQOSPolicy: (policy, success_cb) => dispatch(addQOSPolicy(policy, success_cb)),
  updQOSPolicy: (policy, success_cb) => dispatch(updQOSPolicy(policy, success_cb)),
  delQOSPolicy: (policy, success_cb) => dispatch(delQOSPolicy(policy, success_cb)),
  getQOSPoliciesMetaData: (success_cb) => dispatch(getQOSPoliciesMetaData(success_cb)),
  getQOSTrafficMap: (success_cb) => dispatch(getQOSTrafficMap(success_cb)),
  updQOSTrafficMap: (qosTrafficMap, success_cb) => dispatch(updQOSTrafficMap(qosTrafficMap, success_cb)),
  resetDevice: (deviceId) => dispatch(resetDevice(deviceId)),
  syncDevice: (deviceId) => dispatch(syncDevice(deviceId)),
  getDeviceStatus: (deviceId, success_cb) => dispatch(getDeviceStatus(deviceId, success_cb)),
  getAllTokens: () => dispatch(getAllTokens()),
  getOrganization: (organizationId, success_cb) => dispatch(getOrganization(organizationId, success_cb)),
  addOrganization: (organization, success_cb) => dispatch(addOrganization(organization, success_cb)),
  updOrganization: (organization, success_cb) => dispatch(updOrganization(organization, success_cb)),
  delOrganization: (organization, success_cb) => dispatch(delOrganization(organization, success_cb)),
  selectOrganization: (organization, success_cb) => dispatch(selectOrganization(organization, success_cb)),
  getAllOrganizations: () => dispatch(getAllOrganizations()),
  getOrganizationSummary: () => dispatch(getOrganizationSummary()),
  getAccount: (accountId, success_cb) => dispatch(getAccount(accountId, success_cb)),
  getAccountSubscriptionStatus: (accountId, success_cb) =>
    dispatch(getAccountSubscriptionStatus(accountId, success_cb)),
  selectAccount: (account, success_cb) => dispatch(selectAccount(account, success_cb)),
  getAllAccounts: () => dispatch(getAllAccounts()),
  updAccount: (account, success_cb) => dispatch(updAccount(account, success_cb)),
  getMember: (memberId, success_cb) => dispatch(getMember(memberId, success_cb)),
  resetMemberMfa: (memberId, cb) => dispatch(resetMemberMfa(memberId, cb)),
  addMember: (member, success_cb) => dispatch(addMember(member, success_cb)),
  getMemberOptions: (type, success_cb) => dispatch(getMemberOptions(type, success_cb)),
  updMember: (member, success_cb) => dispatch(updMember(member, success_cb)),
  delMember: (member, success_cb) => dispatch(delMember(member, success_cb)),
  getAllMembers: () => dispatch(getAllMembers()),
  getAllInvoices: (params) => dispatch(getAllInvoices(params)),

  // billing related routes
  openPortal: (success_cb) => dispatch(openPortal(success_cb)),
  addCoupon: (coupon, success_cb) => dispatch(addCoupon(coupon, success_cb)),
  delCoupon: (coupon, success_cb) => dispatch(delCoupon(coupon, success_cb)),

  // access tokens
  getAccessToken: (accesstokenId, success_cb) => dispatch(getAccessToken(accesstokenId, success_cb)),
  addAccessToken: (accesstoken, success_cb) => dispatch(addAccessToken(accesstoken, success_cb)),
  delAccessToken: (accesstoken, success_cb) => dispatch(delAccessToken(accesstoken, success_cb)),
  getAllAccessTokens: () => dispatch(getAllAccessTokens()),

  // App Identifications
  getAllAppIdentifications: (success_cb) => dispatch(getAllAppIdentifications(success_cb)),
  getInstalledAppIdentifications: () => dispatch(getInstalledAppIdentifications()),
  appIdentificationUpdateDevices: (devices, isInstall, success_cb) =>
    dispatch(appIdentificationUpdateDevices(devices, isInstall, success_cb)),
  getAppIdentification: (appIdentificationId, success_cb) => dispatch(getAppIdentification(appIdentificationId, success_cb)),
  getCustomAppIdentification: (appIdentificationId, success_cb) => dispatch(getCustomAppIdentification(appIdentificationId, success_cb)),
  updateAppIdentification: (appIdentification, success_cb) => dispatch(updateAppIdentification(appIdentification, success_cb)),
  updateCustomAppIdentification: (appIdentification, success_cb) => dispatch(updateCustomAppIdentification(appIdentification, success_cb)),
  getAllImportedAppIdentifications: () => dispatch(getAllImportedAppIdentifications()),
  addAppIdentification: (appIdentification, success_cb) => dispatch(addAppIdentification(appIdentification, success_cb)),
  delAppIdentification: (appIdentification, success_cb) => dispatch(delAppIdentification(appIdentification, success_cb)),
  resetAppIdentification: (appIdentification, success_cb) => dispatch(resetAppIdentification(appIdentification, success_cb)),
  addAppIdentificationRule: (rule) => dispatch(addAppIdentificationRule(rule)),
  deleteAppIdentificationRule: (rule) => dispatch(deleteAppIdentificationRule(rule)),

  // tunnels
  getAllTunnels: (params, success_cb) => dispatch(getAllTunnels(params, success_cb)),
  clearTunnels: () => dispatch(clearTunnels()),
  getTraffic: (deviceId, ifNum, success_cb) => dispatch(getTraffic(deviceId, ifNum, success_cb)),
  getHealth: (deviceId, success_cb) => dispatch(getHealth(deviceId, success_cb)),
  getTunnelTraffic: (deviceId, tunnelnum, success_cb) => dispatch(getTunnelTraffic(deviceId, tunnelnum, success_cb)),
  setSelectOptions: (options) => dispatch(setSelectOptions(options)),
  delTunnels: (tunnels, filters, success_cb) => dispatch(delTunnels(tunnels, filters, success_cb)),
  alertMsgMessage: (msg) => dispatch(alertMsgMessage(msg)),
  resetForm: (model) => () => { dispatch(actions.reset(model))},
  changeForm: (model) => (value, options) => { dispatch(actions.change(model, value, options))},
  setInitial: (model) => model => { dispatch(actions.setInitial(model))},
  setFormErrors: (model, errors) => { dispatch(actions.setErrors(model, errors))},
  validateForm: (model) => (validator) => {dispatch(actions.validate(model, validator))},
  resetFormValidity: (model) => () => {dispatch(actions.resetValidity(model))},
  getJobs: (state, params) => dispatch(getJobs(state, params)),
  delJobs: (state, jobIds, success_cb) => dispatch(delJobs(state, jobIds, success_cb)),
  deleteAllJobs: (filters, success_cb) => dispatch(deleteAllJobs(filters, success_cb)),
  getNotifications: (params) => dispatch(getNotifications(params)),
  getNotificationsMetadata: (params) => dispatch(getNotificationsMetadata(params)),
  getNotificationsConf: (params, onSuccess, onError) => dispatch(getNotificationsConf(params, onSuccess, onError)),
  getDefaultNotificationsConf: (params, onSuccess) => dispatch(getDefaultNotificationsConf(params, onSuccess)),
  updateDefaultNotificationsConf: (requestBody) => dispatch(updateDefaultNotificationsConf(requestBody)),
  getWebHookSettings:  (params, onSuccess, onError) => dispatch(getWebHookSettings(params, onSuccess, onError)),
  modifyWebHookSettings:  (params, onSuccess) => dispatch(modifyWebHookSettings(params, onSuccess)),
  getEmailNotificationsConf: (params, onSuccess, onError) => dispatch(getEmailNotificationsConf(params, onSuccess, onError)),
  updateEmailNotificationsConf: (requestBody) => dispatch(updateEmailNotificationsConf(requestBody)),
  updateNotificationsConf: (requestBody, onSuccess) => dispatch(updateNotificationsConf(requestBody, onSuccess)),
  updateNotifications: (notifications, status, resolve, success_cb) => dispatch(updateNotifications(notifications, status, resolve, success_cb)),
  deleteNotifications: (filters, success_cb) => dispatch(deleteNotifications(filters, success_cb)),
  getAdmin: (page, size, sort, filters, cb) => dispatch(getAdmin(page, size, sort, filters, cb)),
  getTickets: (page, size, cb) => dispatch(getTickets(page, size, cb)),
  getTicketComments: (ticketId, cb) => dispatch(getTicketComments(ticketId, cb)),
  modifyTunnelsNotifications: (requestBody) => dispatch(modifyTunnelsNotifications(requestBody)),

  // applications
  getRepositoryApplications: filters => dispatch(getRepositoryApplications(filters)),
  getPurchasedApplications: (responseType, filters, cb) => dispatch(getPurchasedApplications(responseType, filters, cb)),
  purchaseApplication: purchasedApp => dispatch(purchaseApplication(purchasedApp)),
  removeApplication: removedApp => dispatch(removeApplication(removedApp)),
  updateApplication: updatedApp => dispatch(updateApplication(updatedApp)),
  getPurchasedApplication: appId => dispatch(getPurchasedApplication(appId)),
  upgradeApplicationOnDevice: app => dispatch(upgradeApplicationOnDevice(app)),
  getApplicationStatus: (app, cb) => dispatch(getApplicationStatus(app, cb)),

  // Peers
  getAllPeers: cb => dispatch(getAllPeers(cb)),
  savePeer: (peer, cb) => dispatch(savePeer(peer, cb)),
  deletePeer: (peer, cb) => dispatch(deletePeer(peer, cb)),

  getVersion: (success_cb) => dispatch(getVersion(success_cb)),
  getServersList: (success_cb) => dispatch(getRestServersList(success_cb))
});

class Main extends Component {

  render() {
    return (
      <div>
        <Header
          mainSidebarToggle={this.props.mainSidebarToggle}
          isLoading={this.props.main.isLoading}
          auth={this.props.auth}
          history={this.props.history}
          logoutUser={this.props.logoutUser}
          getAllAccounts={this.props.getAllAccounts}
          selectAccount={this.props.selectAccount}
          accounts={this.props.accounts}
          alertMsgMessage = {this.props.alertMsgMessage}
        />
        <div id="mainContainer" className="container">
          <SideBar
            sidebarConfigDropdownToggle={this.props.sidebarConfigDropdownToggle}
            sidebarDashboardDropdownToggle={this.props.sidebarDashboardDropdownToggle}
            sidebarTroubleshootDropdownToggle={this.props.sidebarTroubleshootDropdownToggle}
            sidebarAccountsDropdownToggle={this.props.sidebarAccountsDropdownToggle}
            sidebarUsersDropdownToggle={this.props.sidebarUsersDropdownToggle}
            sidebarTrafficOptDropdownToggle={this.props.sidebarTrafficOptDropdownToggle}
            sidebarApplicationsDropdownToggle={this.props.sidebarApplicationsDropdownToggle}
            sidebarSecurityDropdownToggle={this.props.sidebarSecurityDropdownToggle}
            sidebarHighAvailabilityDropdownToggle={this.props.sidebarHighAvailabilityDropdownToggle}
            sideBarCollapsed={this.props.main.sideBarCollapsed}
            isAuthenticated = {this.props.auth.isAuthenticated}
            orgName = {this.props.auth.orgName}
            sidebar = {this.props.sidebar}
          />
          <MainContent
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
