import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import FWRoute from './FWRoute';
import AlertMsg from './AlertMsg';
import TopMessages from './TopMessages';
import Home from './Home';
import Devices from './devices/DevicesCards';
import DeviceInfo from './devices/DeviceInfo';
import Tokens from './tokens/Tokens';
import AddToken from './tokens/AddToken';
import UpdToken from './tokens/UpdToken';
import Tunnels from './Tunnels';
import Peers from './peers/Peers';
import ConfigurePeer from './peers/ConfigurePeer';
import Network from './dashboards/Network';
import Traffic from './dashboards/Traffic';
import NetworkMap from './dashboards/NetworkMap';
import Login from './users/Login/Login';
import CreateAccount from './users/CreateAccount';
import VerifyAccount from './users/VerifyAccount';
import ResetPassword from './users/ResetPasswd';
import AddOrganization from './organizations/AddOrganization';
import Organizations from './organizations/Organizations';
import UpdOrganization from './organizations/UpdOrganization';
import UpdAccount from './users/UpdAccount';
import AddMember from './members/AddMember';
import Members from './members/Members';
import UpdMember from './members/UpdMember';
import AddAccessToken from './accesstokens/AddAccessToken';
import AccessTokens from './accesstokens/AccessTokens';
import Jobs from './Jobs';
import About from './About';
import Admin from './Admin';
import Tickets from './Tickets';
import Notifications from './Notifications';
import NotificationsConf from './NotificationsConf';

// billing support
import Invoices from "./billings/Invoices";
import AddCoupon from './billings/AddCoupon';
import ManageAccount from './billings/ManageAccount';
import PathLabels from './pathlabels/PathLabels';
import PathLabelOp from './pathlabels/PathLabelOp';
import MultiLinkPolicy from './policies/MultiLinkPolicy';
import MultiLinkPolicies from './policies/MLPolicies';
import AppIdentifications from './appidentifications/AppIdentifications';
import AddAppIdentification from './appidentifications/AddAppIdentification';
import FirewallPolicy from './policies/FirewallPolicy';
import FirewallPolicies from './policies/FirewallPolicies';
import QOSPolicy from './policies/QOSPolicy';
import QOSPolicies from './policies/QOSPolicies';
import QOSTrafficMap from './policies/QOSTrafficMap';
import AppStore from './applications/AppStore';
import MyApplications from './applications/MyApplications';
import AppConfigurations from "./applications/AppConfigurations";
import AppStatus from "./applications/AppStatus";

import VRRP from './highAvailability/VRRP/VRRP'
import ConfigureVRRP from './highAvailability/VRRP/ConfigureVRRP';

import { TT } from '../containers/Language';
import RecoveryCodes from './users/MFA/RecoveryCodes';
const { removeBranding } = window.SERVER_CONFIG;

const MainContent = props => (
  <div className={`mainContent
    ${props.main.sideBarCollapsed || !props.auth.isAuthenticated ? ' collapsed' : ''}`
  }>
    <AlertMsg 
      alertmsg = {props.alertmsg}
      alertMsgMessage = {props.alertMsgMessage}/>
    <TopMessages
      accountId={props.auth.account}
      isAuthenticated = {props.auth.isAuthenticated}
      accounts={props.accounts}
      getAccountSubscriptionStatus = {props.getAccountSubscriptionStatus}
    />
    <Switch>
      <FWRoute parentProps = {props}
        allowedNoAuth
        path='/login'
        render={() => <Login />}
      />
      <FWRoute parentProps = {props}
        allowedNoAuth
        path='/create-account'
        render={() => <CreateAccount
          search={props.location.search}
          createUser={props.createUser}
          resetForm1={props.resetForm('userCreateAccount1')}
          resetForm2={props.resetForm('userCreateAccount2')}
          setForm2CaptchaToken={props.changeForm('userCreateAccount2.captcha')}
          isAuthenticated = {props.auth.isAuthenticated}
        />}
      />
      <FWRoute parentProps = {props}
        allowedNoAuth
        path='/verify-account'
        render={() => <VerifyAccount
          query = {props.location.search}
          verifyAccount = {props.verifyAccount}
          reverifyAccount = {props.reverifyAccount}
          isAuthenticated = {props.auth.isAuthenticated}
        />}
      />
      <FWRoute parentProps = {props}
        allowedNoAuth
        path='/reset-password'
        render={() => <ResetPassword
          query = {props.location.search}
          resetPasswordAccount = {props.resetPasswordAccount}
          isAuthenticated = {props.auth.isAuthenticated}
        />}
      />
      <FWRoute parentProps = {props}
        path='/home'
        render={() => <Home
          devices = {props.devices}
          tunnels = {props.tunnels}
          organizations = {props.organizations}
          clearDevices = {props.clearDevices}
          getAllDevices = {props.getAllDevices}
          clearTunnels = {props.clearTunnels}
          getAllTunnels = {props.getAllTunnels}
          getOrganizationSummary = {props.getOrganizationSummary}
          updCoords = {props.updCoords}
          mainRedirectTo = {props.mainRedirectTo}
          main = {props.main}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/devices'
        render={() => <Devices
          alertMsgMessage={props.alertMsgMessage}
          qparams={props.location.search}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/devices/deviceinfo/:id'
        render={(localProps) => <DeviceInfo
        id={localProps.match.params.id}
        pathname={props.location.pathname}
        history={localProps.history}
        search={props.location.search}
        device={props.devices.device}
        deviceTunnels={props.devices.tunnels}
        interfaces={props.devices.tableInterfaces}
        staticroutes={props.devices.staticRoutes}
        getDevice={props.getDevice}
        getDeviceStatus={props.getDeviceStatus}
        syncDevice={props.syncDevice}
        getDeviceConfig={props.getDeviceConfig}
        getDeviceLogs={props.getDeviceLogs}
        getDevicePacketTraces={props.getDevicePacketTraces}
        getDeviceRoutes={props.getDeviceRoutes}
        getDeviceTunnels={props.getDeviceTunnels}
        getDeviceStaticRoutes={props.getDeviceStaticRoutes}
        addDeviceStaticRoute={props.addDeviceStaticRoute}
        updDeviceStaticRoute={props.updDeviceStaticRoute}
        delDeviceStaticRoute={props.delDeviceStaticRoute}
        saveDeviceStaticRoutes={props.saveDeviceStaticRoutes}
        getAllAppIdentifications={props.getAllAppIdentifications}
        getOrganization = {props.getOrganization}
        changeRuleForm={props.changeForm('firewallRule')}
        firewallRule={props.firewallRule}
        firewallRules={props.devices.firewallRules}
        saveDeviceFirewallRules={props.saveDeviceFirewallRules}
        changeForm={props.changeForm('deviceInfo')}
        updDevice={props.updDevice}
        resetForm={props.resetForm('deviceInfo')}
        traffic={props.traffic}
        getTraffic={props.getTraffic}
        health={props.health}
        getHealth={props.getHealth}
        saveDeviceInterfaces={props.saveDeviceInterfaces}
        getPathLabels={props.getAllPathLabels}
        saveDHCP={props.saveDHCP}
        dhcp={props.devices.dhcp}
        dhcpEntries={props.devices.dhcp}
        changeDHCPForm={props.changeForm('addDHCP')}
        changUpdateInterfaceForm={props.changeForm('updateInterface')}
        saveInterfaceConfiguration={props.saveInterfaceConfiguration}
        getInterfaceStatus={props.getInterfaceStatus}
        performInterfaceAction={props.performInterfaceAction}
        resetDevice={props.resetDevice}
        alertMsgMessage={props.alertMsgMessage}
        resetPinForm={props.resetForm('updatePinConfiguration')}
        resetLteForm={props.resetForm('configureLteInterface')}
        changeGeneralForm={props.changeForm}
        resetGeneralForm={props.resetForm}
        setInitial={props.setInitial}
        sendDevice={props.sendDevice}
        ospf={props.devices.ospf}
        bgp={props.devices.bgp}
        saveOSPF={props.saveOSPF}
        deviceInfo={props.deviceInfo}
        updCoords={props.updCoords}
        saveBGP={props.saveBGP}
        saveAdvancedRouting={props.saveAdvancedRouting}
        routingFilters={props.devices.routingFilters}
        saveRoutingFilters={props.saveRoutingFilters}
        qosPolicies={props.qosPolicies}
        getQOSPoliciesList={props.getQOSPoliciesList}
      />}
      />
      <FWRoute parentProps = {props}
        exact path='/pathlabels'
        render={() => <PathLabels
          pathlabels={props.pathlabels}
          getAllPathLabels={props.getAllPathLabels}
          delPathLabel={props.delPathLabel}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/pathlabels/add'
        render={() => <PathLabelOp
          op="Add"
          getPathLabel={props.getPathLabel}
          addPathLabel={props.addPathLabel}
          updPathLabel={props.updPathLabel}
          updateColor={props.changeForm('pathLabel.color')}
          changeForm={props.changeForm('pathLabel')}
          resetForm={props.resetForm('pathLabel')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/pathlabels/update/:id'
        render={localProps => <PathLabelOp
          op="Update"
          id={localProps.match.params.id}
          getPathLabel={props.getPathLabel}
          addPathLabel={props.addPathLabel}
          updPathLabel={props.updPathLabel}
          updateColor={props.changeForm('pathLabel.color')}
          changeForm={props.changeForm('pathLabel')}
          resetForm={props.resetForm('pathLabel')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/pathselectionpolicies'
        render={() => <MultiLinkPolicies
          getAllMLPolicies={props.getAllMLPolicies}
          getMLPoliciesMetaData={props.getMLPoliciesMetaData}
          delMLPolicy={props.delMLPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/pathselectionpolicies/add'
        render={() => <MultiLinkPolicy
          newPolicy={true}
          mlpolicy={props.mlpolicies.mlPolicy}
          resetAppForm={props.resetForm('mlRule.classification.application')}
          resetPrefixForm={props.resetForm('mlRule.classification.prefix')}
          resetPolicyForm={props.resetForm('mlpolicy')}
          changeForm = {props.changeForm('mlpolicy')}
          resetRuleForm={props.resetForm('mlRule')}
          changeRuleForm={props.changeForm('mlRule')}
          getAllPathLabels={props.getAllPathLabels}
          getAllAppIdentifications={props.getAllAppIdentifications}
          addMLPolicy={props.addMLPolicy}
          updMLPolicy={props.updMLPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/pathselectionpolicies/:id'
        render={localProps => <MultiLinkPolicy
          id={localProps.match.params.id}
          mlpolicy={props.mlpolicies.mlPolicy}
          resetAppForm={props.resetForm('mlRule.classification.application')}
          resetPrefixForm={props.resetForm('mlRule.classification.prefix')}
          resetPolicyForm={props.resetForm('mlpolicy')}
          changeForm = {props.changeForm('mlpolicy')}
          changeRuleForm = {props.changeForm('mlRule')}
          resetRuleForm={props.resetForm('mlRule')}
          getMLPolicy={props.getMLPolicy}
          getAllPathLabels={props.getAllPathLabels}
          getAllAppIdentifications={props.getAllAppIdentifications}
          addMLPolicy={props.addMLPolicy}
          updMLPolicy={props.updMLPolicy}
          installDevPolicy={props.installDevPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/firewallpolicies'
        render={() => <FirewallPolicies
          getAllFirewallPolicies={props.getAllFirewallPolicies}
          getFirewallPoliciesMetaData={props.getFirewallPoliciesMetaData}
          getAllAppIdentifications={props.getAllAppIdentifications}
          delFirewallPolicy={props.delFirewallPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/firewallpolicies/add'
        render={() => <FirewallPolicy
          newPolicy={true}
          firewallPolicy={props.firewallPolicy}
          firewallRule={props.firewallRule}
          changePolicyForm = {props.changeForm('firewallPolicy')}
          resetPolicyForm={props.resetForm('firewallPolicy')}
          changeRuleForm={props.changeForm('firewallRule')}
          getAllAppIdentifications={props.getAllAppIdentifications}
          addFirewallPolicy={props.addFirewallPolicy}
          updFirewallPolicy={props.updFirewallPolicy}
          getOrganization = {props.getOrganization}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/firewallpolicies/:id'
        render={localProps => <FirewallPolicy
          id={localProps.match.params.id}
          firewallPolicies={props.firewallPolicies}
          firewallPolicy={props.firewallPolicy}
          firewallRule={props.firewallRule}
          changePolicyForm = {props.changeForm('firewallPolicy')}
          resetPolicyForm={props.resetForm('firewallPolicy')}
          changeRuleForm={props.changeForm('firewallRule')}
          getAllAppIdentifications={props.getAllAppIdentifications}
          getFirewallPolicy={props.getFirewallPolicy}
          addFirewallPolicy={props.addFirewallPolicy}
          updFirewallPolicy={props.updFirewallPolicy}
          installDevPolicy={props.installDevPolicy}
          getOrganization = {props.getOrganization}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/qosPolicies'
        render={() => <QOSPolicies
          history={props.history}
          getAllQOSPolicies={props.getAllQOSPolicies}
          getQOSPoliciesMetaData={props.getQOSPoliciesMetaData}
          delQOSPolicy={props.delQOSPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/qosPolicies/add'
        render={() => <QOSPolicy
          newPolicy={true}
          qosPolicy={props.qosPolicy}
          getQOSTrafficMap={props.getQOSTrafficMap}
          changePolicyForm = {props.changeForm('qosPolicy')}
          resetPolicyForm={props.resetForm('qosPolicy')}
          addQOSPolicy={props.addQOSPolicy}
          updQOSPolicy={props.updQOSPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/qosPolicies/:id'
        render={localProps => <QOSPolicy
          id={localProps.match.params.id}
          qosPolicies={props.qosPolicies}
          qosPolicy={props.qosPolicy}
          getQOSPolicy={props.getQOSPolicy}
          getQOSTrafficMap={props.getQOSTrafficMap}
          changePolicyForm = {props.changeForm('qosPolicy')}
          resetPolicyForm={props.resetForm('qosPolicy')}
          addQOSPolicy={props.addQOSPolicy}
          updQOSPolicy={props.updQOSPolicy}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/qosTrafficMap'
        render={() => <QOSTrafficMap
          qosPolicies={props.qosPolicies}
          qosTrafficMap={props.qosTrafficMap}
          getQOSTrafficMap={props.getQOSTrafficMap}
          updQOSTrafficMap={props.updQOSTrafficMap}
          changeQOSTrafficMapForm = {props.changeForm('qosTrafficMap')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/tokens'
        render={() => <Tokens
          tokens = {props.tokens}
          delToken={props.delToken}
          getAllTokens = {props.getAllTokens}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/tokens/add' 
        render={() => <AddToken
          addToken={props.addToken}
          getServersList = {props.getServersList}
          resetForm={props.resetForm('addToken')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/tokens/update/:id'
        render={localProps => <UpdToken
          id = {localProps.match.params.id}
          updToken={props.updToken}
          getToken = {props.getToken}
          changeForm = {props.changeForm('updToken')}
          resetForm={props.resetForm('updToken')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/tunnels'
        render={() => <Tunnels
          tunnels = {props.tunnels}
          peers = {props.peers.peers}
          delTunnels={props.delTunnels}
          getAllTunnels = {props.getAllTunnels}
          getAllPeers = {props.getAllPeers}
          getPathLabels={props.getAllPathLabels}
          getNotificationsConf={props.getNotificationsConf}
          modifyTunnelNotifications={props.modifyTunnelsNotifications}
          changeModifyTunnelNotificationsForm={props.changeForm('modifyTunnelNotificationsForm')}
          auth={props.auth}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/peers'
        render={() => <Peers
          peers = {props.peers.peers}
          isLoading = {props.peers.isLoading}
          deletePeer={props.deletePeer}
          getAllPeers = {props.getAllPeers}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/peers/configure/:id?'
        render={localProps => <ConfigurePeer
          id = {localProps.match.params.id}
          savePeer={props.savePeer}
          peers = {props.peers.peers}
          getAllPeers = {props.getAllPeers}
          resetForm={props.resetForm}
          changeForm={props.changeForm}
          setFormErrors={props.setFormErrors}
        />}
      />
      <FWRoute parentProps = {props}
        path='/network'
        render={() => <Network
          history={props.history}
          devices = {props.devices}
          tunnels = {props.tunnels}
          clearDevices = {props.clearDevices}
          getAllDevices = {props.getAllDevices}
          clearTunnels = {props.clearTunnels}
          getAllTunnels = {props.getAllTunnels}
        />}
      />
      <FWRoute parentProps = {props}
        path='/networkmap'
        render={() => <NetworkMap
          devices = {props.devices}
          tunnels = {props.tunnels}
          clearDevices = {props.clearDevices}
          getAllDevices = {props.getAllDevices}
          clearTunnels = {props.clearTunnels}
          getAllTunnels = {props.getAllTunnels}
          updCoords = {props.updCoords}
        />}
      />
      <FWRoute parentProps = {props}
        path='/traffic'
        render={() => <Traffic
          traffic = {props.traffic}
          devices = {props.devices}
          tunnels = {props.tunnels}
          peers= {props.peers.peers}
          getTraffic = {props.getTraffic}
          getTunnelTraffic = {props.getTunnelTraffic}
          clearDevices = {props.clearDevices}
          getAllDevices = {props.getAllDevices}
          clearTunnels = {props.clearTunnels}
          getAllTunnels = {props.getAllTunnels}
          getAllPeers = {props.getAllPeers}
          setSelectOptions = {props.setSelectOptions}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/jobs'
        render={() => <Jobs
          jobs = {props.jobs}
          getJobs = {props.getJobs}
          delJobs = {props.delJobs}
          deleteAllJobs = {props.deleteAllJobs}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/notifications'
        render={() => <Notifications
          queryParams={props.location.search}
          notifications={props.notifications}
          getNotifications={props.getNotifications}
          updateNotifications={props.updateNotifications}
          deleteNotifications={props.deleteNotifications}
        />}
      />
      <FWRoute parentProps = {props}
        path='/about'
        render={() => <About
          getVersion={props.getVersion}
          getDeviceLatestVer={props.getDeviceLatestVer}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/admin'
        render={() => <Admin
          info = {props.admin.info}
          getInfo = {props.getAdmin}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/tickets'
        render={() => <Tickets
          tickets = {props.tickets}
          getInfo = {props.getTickets}
          getComments = {props.getTicketComments}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/organizations/add'
        render={() => <AddOrganization
          currentOrg = {props.auth.org}
          addOrganization={props.addOrganization}
          resetForm={props.resetForm('addOrganization')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/organizations/update/:id'
        render={localProps => <UpdOrganization
          id = {localProps.match.params.id}
          updOrganization={props.updOrganization}
          getOrganization = {props.getOrganization}
          tunnels={props.tunnels}
          getAllTunnels = {props.getAllTunnels}
          changeForm = {props.changeForm('updOrganization')}
          resetForm={props.resetForm('updOrganization')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/organizations'
        render={() => <Organizations
          auth = {props.auth}
          organizations = {props.organizations}
          delOrganization={props.delOrganization}
          selectOrganization={props.selectOrganization}
          getAllOrganizations = {props.getAllOrganizations}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/accounts/update'
        render={() => <UpdAccount
          id = {props.auth.account}
          updAccount={props.updAccount}
          getAccount = {props.getAccount}
          changeForm = {props.changeForm('updateAccount')}
          resetForm={props.resetForm('updateAccount')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/members/add'
        render={() => <AddMember
          members={props.members}
          addMember={props.addMember}
          getMemberOptions={props.getMemberOptions}
          accountName={props.auth.accountName}
          resetForm={props.resetForm('addMember')}
          changeFormEntity={props.changeForm('addMember.userEntity')}
          changeFormRole={props.changeForm('addMember.userRole')}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/members'
        render={() => <Members
          auth = {props.auth}
          members = {props.members}
          delMember={props.delMember}
          getAllMembers = {props.getAllMembers}
          resetMemberMfa = {props.resetMemberMfa}
        />}
      />
      <FWRoute parentProps = {props}
        exact path='/members/update/:id'
        render={localProps => <UpdMember
          id = {localProps.match.params.id}
          members={props.members}
          getMember = {props.getMember}
          updMember={props.updMember}
          getMemberOptions={props.getMemberOptions}
          accountName={props.auth.accountName}
          resetForm={props.resetForm('updMember')}
          changeForm={props.changeForm('updMember')}
          changeFormEntity={props.changeForm('updMember.userEntity')}
          changeFormRole={props.changeForm('updMember.userRole')}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/accesstokens/add'
        render={() => <AddAccessToken
          accesstokens={props.accesstokens}
          currentOrg = {props.auth.org}
          addAccessToken={props.addAccessToken}
          getMemberOptions={props.getMemberOptions}
          resetForm={props.resetForm('addAccessToken')}
          members={props.members}
          changeForm={props.changeForm}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/accesstokens'
        render={() => <AccessTokens
          auth={props.auth}
          accesstokens={props.accesstokens}
          delAccessToken={props.delAccessToken}
          getAllAccessTokens={props.getAllAccessTokens}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/billing'
        render={() => <Invoices
          auth={props.auth}
          invoices={props.invoices}
          getAllInvoices={props.getAllInvoices}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/billing/manage'
        render={() => <ManageAccount
          auth={props.auth}
          portal={props.portal.portal}
          isLoading={props.portal.isLoading}
          openPortal= {props.openPortal}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/coupons/add'
        render={() => <AddCoupon
          addCoupon={props.addCoupon}
          resetForm={props.resetForm('addCoupon')}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/appidentifications'
        render={() => <AppIdentifications
          appIdentifications = {props.appIdentifications}
          getAllAppIdentifications = {props.getAllAppIdentifications}
          getInstalledAppIdentifications = {props.getInstalledAppIdentifications}
          appIdentificationUpdateDevices = {props.appIdentificationUpdateDevices}
          delAppIdentification = {props.delAppIdentification}
          resetAppIdentification = {props.resetAppIdentification}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/appidentifications/add'
        render={() => <AddAppIdentification
          addAppIdentification={props.addAppIdentification}
          resetForm={props.resetForm('addAppIdentification')}
          resetRulesForm={props.resetForm('addAppIdentificationRule')}
          addAppIdentificationRule={props.addAppIdentificationRule}
          deleteAppIdentificationRule={props.deleteAppIdentificationRule}
          newRules={props.appIdentifications.newRules}
          appIdentifications = {props.appIdentifications.appIdentifications}
          getAllAppIdentifications = {props.getAllAppIdentifications}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/appidentifications/customize/:id'
        render={localProps => <AddAppIdentification
          option = "customize"
          id = {localProps.match.params.id}
          addAppIdentification={props.updateAppIdentification}
          changeForm={props.changeForm('addAppIdentification')}
          resetForm={props.resetForm('addAppIdentification')}
          resetRulesForm={props.resetForm('addAppIdentificationRule')}
          addAppIdentificationRule={props.addAppIdentificationRule}
          deleteAppIdentificationRule={props.deleteAppIdentificationRule}
          newRules={props.appIdentifications.newRules}
          appIdentifications = {props.appIdentifications.appIdentifications}
          getAllAppIdentifications = {props.getAllAppIdentifications}
          getAppIdentification = {props.getAppIdentification}
        />}
      />
      <FWRoute parentProps={props}
        exact path='/appidentifications/update/:id'
        render={localProps => <AddAppIdentification
          option = "update"
          id = {localProps.match.params.id}
          addAppIdentification={props.updateCustomAppIdentification}
          changeForm={props.changeForm('addAppIdentification')}
          resetForm={props.resetForm('addAppIdentification')}
          resetRulesForm={props.resetForm('addAppIdentificationRule')}
          addAppIdentificationRule={props.addAppIdentificationRule}
          deleteAppIdentificationRule={props.deleteAppIdentificationRule}
          newRules={props.appIdentifications.newRules}
          appIdentifications = {props.appIdentifications.appIdentifications}
          getAllAppIdentifications = {props.getAllAppIdentifications}
          getAppIdentification = {props.getCustomAppIdentification}
        />}
      />

      <FWRoute
        parentProps={props}
        exact
        path='/applications/available'
        render={(localProps) => (
          <AppStore
            getRepositoryApplications={props.getRepositoryApplications}
            repository={props.applications.repository}
            getPurchasedApplications={props.getPurchasedApplications}
            purchasedApplications={props.applications.purchasedApplications}
            purchaseApplication={props.purchaseApplication}
          />
        )}
      />

      <FWRoute
        parentProps={props}
        exact
        path='/applications/installed'
        render={(localProps) => (
          <MyApplications
            getPurchasedApplications={props.getPurchasedApplications}
            purchasedApplications={props.applications.purchasedApplications}
            removeApplication={props.removeApplication}
            upgradeApplicationOnDevice={props.upgradeApplicationOnDevice}
          />
        )}
      />

      <FWRoute
        parentProps={props}
        exact
        path='/applications/:id/configurations'
        render={(localProps) => (
          <AppConfigurations
            id={localProps.match.params.id}
            getPurchasedApplication={props.getPurchasedApplication}
            purchasedApplication={props.applications.purchasedApplication}
            changeForm={props.changeForm}
            resetForm={props.resetForm}
            updateApplication={props.updateApplication}
          />
        )}
      />

      <FWRoute
        parentProps={props}
        exact
        path='/applications/:id/status'
        render={(localProps) => (
          <AppStatus
            id={localProps.match.params.id}
            getPurchasedApplication={props.getPurchasedApplication}
            purchasedApplication={props.applications.purchasedApplication}
            getApplicationStatus={props.getApplicationStatus}
          />
        )}
      />

      <FWRoute parentProps = {props}
        exact path='/notifications-config'
        render={() => <NotificationsConf
          getNotificationsConf={props.getNotificationsConf}
          updateNotificationsConf={props.updateNotificationsConf}
          getEmailNotificationsConf={props.getEmailNotificationsConf}
          updateEmailNotificationsConf={props.updateEmailNotificationsConf}
          getMemberOptions={props.getMemberOptions}
          getDefaultNotificationsConf={props.getDefaultNotificationsConf}
          updateDefaultNotificationsConf={props.updateDefaultNotificationsConf}
          changeModifyNotificationForm={props.changeForm('modifyNotificationForm')}
          auth={props.auth}
          getAllOrganizations = {props.getAllOrganizations}
          members = {props.members}
          getAllMembers = {props.getAllMembers}
          getWebHookSettings = {props.getWebHookSettings}
          modifyWebHookSettings = {props.modifyWebHookSettings}
          changeModifyWebhookSettingsForm={props.changeForm('modifyWebhookSettingsForm')}
        />}
      />

      <FWRoute
        parentProps={props}
        exact
        path='/2fa/recoveryCodes'
        render={(localProps) => (
          <RecoveryCodes />
        )}
      />

      <FWRoute
        parentProps={props}
        exact
        path='/vrrp'
        render={(localProps) => (
          <VRRP />
        )}
      />

      <FWRoute parentProps = {props}
        exact path='/vrrp/configure/:id?'
        render={localProps => <ConfigureVRRP
          id = {localProps.match.params.id}
          changeForm={props.changeForm}
          resetForm={props.resetForm}
        />}
      />

      <Redirect to='/login' />
    </Switch>
    <div className='footer'>
      <hr />
      <span style={{ marginLeft: '15px'}}>
        {!removeBranding
        ?'© flexiWAN Ltd. 2024, All Rights Reserved'
        :'All Rights Reserved - Not to be copied in part or as a whole'}
      </span>
      {!removeBranding
        ?(<span style={{ marginLeft: '20px', color: '#41a393', fontStyle: 'italic'}}>
          <a href="https://docs.flexiwan.com/" target="_blank" rel="noopener noreferrer">
            <TT>Documentation</TT>
          </a>
        </span>)
        :null
      }
    </div>
  </div>
);

export default MainContent;
