import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
const { baseUrl } = window.SERVER_CONFIG;

export const Coupons = (
  state = {
    coupon: null,
    coupons: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.COUPON_ADD_REQUEST:
      return { ...state, coupon: null };
    case Actions.COUPON_ADD_SUCCESS:
      return { ...state, coupon: action.coupon };
    case Actions.COUPON_ADD_FAILURE:
      return { ...state, coupon: null };
    case Actions.COUPON_DEL_REQUEST:
      return { ...state, coupon: action.coupon };
    case Actions.COUPON_DEL_SUCCESS:
      return { ...state };
    case Actions.COUPON_DEL_FAILURE:
      return { ...state, coupon: null };
    case Actions.COUPON_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.COUPON_GETALL_SUCCESS:
      return { ...state, coupons: action.coupons, isLoading: false };
    case Actions.COUPON_GETALL_FAILURE:
      return { ...state, coupons: [], isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add Coupon
 **********************************************************************/
export const requestAddCoupon = coupon => dispatch => {
  dispatch({
    type: Actions.COUPON_ADD_REQUEST,
    coupon
  });
};

export const receiveAddCoupon = response => dispatch => {
  dispatch({
    type: Actions.COUPON_ADD_SUCCESS,
    coupon: response.name
  });
  dispatch(mainRedirectTo("/billing"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Coupon " + response.name + " added successfully"
    })
  );
};

export const failureAddCoupon = error => dispatch => {
  dispatch({
    type: Actions.COUPON_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const addCoupon = (coupon, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "coupons",
      method: "POST",
      body: JSON.stringify(coupon),
      requestCB: () => {
        dispatch(requestAddCoupon(coupon));
      },
      successCB: response => {
        dispatch(receiveAddCoupon(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddCoupon(error));
      }
    })
  );
};


/**********************************************************************
 *   Delete Coupon
 **********************************************************************/
export const requestDelCoupon = coupon => dispatch => {
  dispatch({
    type: Actions.COUPON_DEL_REQUEST,
    coupon
  });
};

export const receiveDelCoupon = response => dispatch => {
  dispatch({
    type: Actions.COUPON_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Coupon " + store.getState().coupons.coupon.name + " deleted successfully"
    })
  );
};

export const failureDelCoupon = error => dispatch => {
  dispatch({
    type: Actions.COUPON_DEL_FAILURE
  });
};

export const delCoupon = (coupon, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "coupons/" + coupon._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelCoupon(coupon));
      },
      successCB: response => {
        dispatch(receiveDelCoupon(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelCoupon(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all coupons
 **********************************************************************/
export const requestGetAllCoupons = () => dispatch => {
  dispatch({
    type: Actions.COUPON_GETALL_REQUEST
  });
};

export const receiveGetAllCoupons = response => dispatch => {
  dispatch({
    type: Actions.COUPON_GETALL_SUCCESS,
    coupons: response
  });
};

export const failureGetAllCoupons = error => dispatch => {
  dispatch({
    type: Actions.COUPON_GETALL_FAILURE
  });
};

export const getAllCoupons = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "coupons",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllCoupons());
      },
      successCB: response => {
        dispatch(receiveGetAllCoupons(response));
      },
      failureCB: error => {
        dispatch(failureGetAllCoupons(error));
      }
    })
  );
};
