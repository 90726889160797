import React, { useMemo, useState, useEffect } from "react";
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import isEqual from "lodash/isEqual";

const Input = (props) => {
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <components.Input
      {...props}
      onBlur={() => {
        props.selectProps.setUpdated(false)
        props.selectProps.onMenuClose();
      }}
    />
  );
};

const ValueContainer = (props) => {
  const { setValue } = props;
  const { inputValue, updated, setUpdated } = props.selectProps;

  useEffect(() => {
    if (!updated && inputValue) {
      setValue(inputValue);
      setUpdated(true);
    }
  }, [inputValue, updated, setUpdated, setValue])

  return (
    <components.ValueContainer
      {...props}
    />
  );
};

const CreatableSelectAdvanced = props => {
  const [updated, setUpdated] = useState(true);
  return <CreatableSelect
    {...props}
    updated={updated}
    setUpdated={setUpdated}
    components={{ Input, ValueContainer }}
  />
}

const SearchableSelectBox = props => {
  let {
    value,
    isClearable = true,
    creatable = false,
    formatCreateLabel = 'Create',
    ...rest
  } = props;


  // useMemo to prevent loop on array with no need
  const valueInOptions = useMemo(() => {
    return (props?.options ?? []).find(o => isEqual(o.value, value)); // use "isEqual" to support any type of "value" (strings, objects, etc)
  }, [props?.options, value]);

  let newValue = value;
  if (value && typeof value === "object" && value.hasOwnProperty('value')) {
    newValue = value;
  } else if (valueInOptions) {
    newValue = valueInOptions;
  } else if (creatable) {
    newValue = { label: value, value: value } // creatable expect string to be converted to object
  }

  const newProps = {
    value: newValue,
    isClearable: isClearable,
    hideSelectedOptions: true,
    formatCreateLabel: (newLabel) => `${formatCreateLabel} "${newLabel}"`,
    ...rest
  };

  // in order to show the placeholder, if the value is empty no need to pass it
  if (value === '') {
    delete newProps.value;
  }

  if (creatable) {
    return (
      <CreatableSelectAdvanced {...newProps} />
    );
  }

  return (
    <Select {...newProps}
      filterOption={(option, input) =>
        (option.label.toString().toLowerCase().match(input.toLowerCase()) || []).length > 0}
    />
  );
}

export default SearchableSelectBox;
