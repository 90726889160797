import React, { useEffect, useState, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { TT, LanguageContext } from '../../containers/Language'

const Peers = ({ isLoading, getAllPeers, peers, deletePeer }) => {
  const { toTTString } = useContext(LanguageContext);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false)
  const [selectedPeer, setSelectedPeer] = useState(null)

  useEffect(() => {
    getAllPeers()
  }, [getAllPeers]);

  const paginationOptions = {
    paginationSize: 5,
    alwaysShowAllBtns: true,
    pageStartIndex: 0,
    firstPageText: <TT>First</TT>,
    prePageText: <TT>Back</TT>,
    nextPageText: <TT>Next</TT>,
    lastPageText: <TT>Last</TT>,
    nextPageTitle: <TT>First page</TT>,
    prePageTitle: <TT>Pre page</TT>,
    firstPageTitle: <TT>Next page</TT>,
    lastPageTitle: <TT>Last page</TT>,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
                                                    <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                  </span>),
    sizePerPageList: [{text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}]
  };

  const handleDelete = peer => {
    setSelectedPeer(peer)
    setIsDelModalOpen(true)
  }

  const handleDeletionApproval = isApproved => {
    setIsDelModalOpen(false)

    if (isApproved) {
      deletePeer(selectedPeer)
    }
    setSelectedPeer(null)
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
        <BreadcrumbItem active><TT>Peers</TT></BreadcrumbItem>
      </Breadcrumb>

      <div className="container">
        <h4><TT>Peers</TT></h4>
        {isLoading ? <div className="signal"></div> : null}
      </div>
      <div className="col-md-12">
        <Row>
          <Col md={12} className="mb-3">
            <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
            <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
              onClick = {() => getAllPeers()}>
              <FontAwesomeIcon icon="sync-alt" />
            </Button>
            <Link to="/peers/configure">
              <Button color="success" className="action-btn-top" size="sm"><TT>New Peer</TT></Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <BootstrapTable
              striped
              hover
              condensed
              keyField='_id'
              data={peers}
              columns={ [
                {
                  text: <TT>Name</TT>,
                  dataField: 'name',
                  sort:true,
                  headerStyle: (colum, colIndex) => {
                    return { width: '10%', textAlign: 'left' };
                  }
                },
                {
                  text: <TT>Local ID</TT>,
                  dataField: 'localId',
                  sort:true,
                  headerStyle: (colum, colIndex) => {
                    return { width: '10%', textAlign: 'left' };
                  }
                },
                {
                  text: <TT>Remote ID</TT>,
                  dataField: 'remoteId',
                  sort:true,
                  headerStyle: (colum, colIndex) => {
                    return { width: '10%', textAlign: 'left' };
                  }
                },
                {
                  text: <TT>Remote IP</TT>,
                  dataField: 'remoteIP',
                  sort:true,
                  headerStyle: (colum, colIndex) => {
                    return { width: '10%', textAlign: 'left' };
                  }
                },
                {
                  text: <TT>Actions</TT>,
                  dataField: 'none',
                  sort:false,
                  headerStyle: (colum, colIndex) => {
                    return { width: '9%', textAlign: 'left' };
                  },
                  formatter: (cellContent, row, rowIndex) => {
                    return (
                      <div>
                        <Link to={"/peers/configure/" + row._id}>
                          <Button color="warning" className="action-btn" data-tip data-for='update-a' size="sm">
                            <FontAwesomeIcon icon="cog" fixedWidth />
                          </Button>
                        </Link>
                        <ReactTooltip id='update-a'><span><TT>Configure Peer</TT></span></ReactTooltip>


                        <Button color="danger" className="action-btn" data-tip data-for='delete-a' size="sm"
                          onClick={() => handleDelete(row)}>
                          <FontAwesomeIcon icon="trash-alt" fixedWidth />
                        </Button>
                        <ReactTooltip id='delete-a'><span><TT>Delete Peer</TT></span></ReactTooltip>
                      </div>
                    );
                  }
                }
              ] }
              pagination={ paginationFactory(paginationOptions) }
              noDataIndication={toTTString("No Peers available")}
              defaultSorted={[{dataField: 'name',order: 'asc'}] }
            />
          </Col>
        </Row>
      </div>
      {/* Delete Peer Modal */}
      <Modal size={"lg"} isOpen={isDelModalOpen} toggle={() => handleDeletionApproval(false)}>
        <ModalHeader><TT params={{deleteThis: selectedPeer?.name}}>Delete #deleteThis# Configuration</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <TT params={{deleteThis: selectedPeer?.name}}>Are you sure to delete #deleteThis# configuration?</TT>
          </div>
          <div
            className="mb-3 alert alert-warning"
            role="alert"
          >
            <TT>You will not be able to remove a peer configuration if there are tunnels created based on it</TT>
          </div>
          <Button color="danger" onClick={() => handleDeletionApproval(true)}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={() => handleDeletionApproval(false)}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Peers;