import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem, Label, Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import SearchableSelectBox from '../items/SearchableSelectBox';
import PathLabelBadge from '../pathlabels/PathLabelBadge';
import "./Traffic.css";
import NetworkTraffic from "./NetworkTraffic";
import TunnelPerformance from "./TunnelPerformance";
// import { rateConversion } from '../../utils/Conversions';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TT, LanguageContext } from '../../containers/Language'

class Traffic extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.readTimer = null;

    this.state = {
      lastUpdate: 0
    }

    this.handleChangeDeviceAction = this.handleChangeDeviceAction.bind(this);
    this.handleChangeInterfaceAction = this.handleChangeInterfaceAction.bind(this);
    this.handleChangeDirectionAction = this.handleChangeDirectionAction.bind(this);
    this.updateTraffic = this.updateTraffic.bind(this);
    this.updateOnFocus = this.updateOnFocus.bind(this);
  }

  componentDidMount() {
    this.props.getAllDevices({ response: 'summary' });
    this.props.getAllTunnels();
    this.props.getAllPeers();
    this.updateTraffic(this.props.traffic.selectOptions.deviceId, this.props.traffic.selectOptions.interfaceNum);

    if (this.readTimer === null) {
      console.log("Traffic: Setting periodic timer");
      const timer = setInterval(function(that) {
          console.log("Traffic periodic update");
          // Only update when in focus
          if (document.hasFocus()) {
              that.updateTraffic(that.props.traffic.selectOptions.deviceId,
                that.props.traffic.selectOptions.interfaceNum);
          }
      },60000, this);
      this.readTimer = timer;
    }
    window.addEventListener('focus', this.updateOnFocus);
  }

  componentWillUnmount() {
    this.props.clearDevices();
    this.props.clearTunnels();
    if (this.readTimer != null) {
      clearInterval(this.readTimer);
      this.readTimer = null;
    }
    window.removeEventListener("focus", this.updateOnFocus);
  }

  updateTraffic(deviceId, interfaceNum) {
    const isTunnel = interfaceNum && interfaceNum.startsWith('tunnel');
    const interfaceNumAdj = (isTunnel)?
      interfaceNum.replace('tunnel', ''): interfaceNum;

    const cb = ()=>this.setState({lastUpdate:new Date().getTime()});
    if (isTunnel) {
      this.props.getTunnelTraffic(deviceId, interfaceNumAdj, cb);
    } else {
      this.props.getTraffic(deviceId, interfaceNumAdj, cb)
    }
  }

  /**
   * Read and update graph when UI in focus
   * @return {None}
   */
  updateOnFocus() {
    this.updateTraffic(this.props.traffic.selectOptions.deviceId,
      this.props.traffic.selectOptions.interfaceNum);
  }

  handleChangeDeviceAction(option, action) {
    const newState = {};
    if (action && action.name && option && option.label) {
      newState[action.name] = option.value;
      newState.deviceId = option.value;
      newState.interface = 'FFFF'; // ALL
      newState.interfaceNum = undefined;
    }
    this.props.setSelectOptions(newState);
    this.updateTraffic(newState.deviceId, newState.interfaceNum);
  };

  handleChangeInterfaceAction(option, action) {
    const newState = {};
    if (action && action.name && option && option.label) {
      newState[action.name] = option.value;
      newState.interfaceNum = option.value;
    }
    this.props.setSelectOptions(newState);
    this.updateTraffic(this.props.traffic.selectOptions.deviceId, newState.interfaceNum);
  };

  handleChangeDirectionAction(option, action) {
    const newState = {
      directionValue:option.value,
      direction:option.value,
      lastUpdate:new Date().getTime()
    };
    this.props.setSelectOptions(newState);
    this.updateTraffic(this.props.traffic.selectOptions.deviceId,
      this.props.traffic.selectOptions.interfaceNum);
  };

  render() {
    let deviceOpts = this.props.devices.devices.map((d) => { return {label:d.name, value:d._id}});
    const peers = this.props.peers.map(p => { return {label: p.name, value: p._id}});
    deviceOpts = deviceOpts.concat(peers);
    deviceOpts.unshift({label:'All', value:'FFFF'});

    let interfaceOpts = [{label:'All', value:'FFFF'}];
    if (this.props.traffic.selectOptions.deviceId) {
      // Fill in interfaces
      const device = this.props.devices.devices.filter((d) => {
        return (d._id === this.props.traffic.selectOptions.deviceId)
      });
      if (device.length && device[0].interfaces) {
        const interfaces = device[0].interfaces
          .filter((i) => { return i.isAssigned })
          .map((i) => { return {label:`${i.name} (${i.type}) - ${i.IPv4}`, value:i.devId}});
        interfaceOpts = interfaceOpts.concat(interfaces);
      }
      // Fill in tunnels
      const tunnels = this.props.tunnels.tunnels.filter(t => {
        if (!t.isActive) {
          return false;
        }

        const selectedDevice = this.props.traffic.selectOptions.deviceId;

        if (t.deviceA._id === selectedDevice) {
          return true;
        }

        if (!t.peer && t.deviceB._id === selectedDevice) {
          return true;
        }

        if (t.peer && t.peer._id === selectedDevice) {
          return true;
        }

        return false;
      });

      if (tunnels.length) {
        const tunnelOpts = tunnels
          .map((t) => {
            const toDevice = t.peer ? t.peer.name : (t.deviceA._id === this.props.traffic.selectOptions.deviceId)? t.deviceB.name: t.deviceA.name;
            const pathLabelJSX = (t.pathlabel)?
              <PathLabelBadge name={t.pathlabel.name} key={t.pathlabel.name} color={t.pathlabel.color}/>:(<TT>No Label</TT>);
            return {label:[(t.peer) ? <TT key={t.num} params={{tNum: t.num, toDevice: toDevice}}>Peer Tunnel #tNum# to #toDevice#</TT> : <TT key={t.num} params={{tNum: t.num, toDevice: toDevice}}>Tunnel #tNum# to #toDevice#</TT> ,pathLabelJSX], value:'tunnel'+t.num}
          });
        interfaceOpts = interfaceOpts.concat(tunnelOpts);
      }
    }

    const isTunnel = this.props.traffic.selectOptions.interfaceNum &&
      this.props.traffic.selectOptions.interfaceNum.startsWith('tunnel');
    const traffic = (isTunnel)? this.props.traffic.tunnelTraffic : this.props.traffic.traffic;
    /*
    let sum_pkts = {rx_bps:'', tx_bps:'', rx_pps:'', tx_pps:''};
    if (traffic && traffic.length) sum_pkts = {
      rx_bps: rateConversion(traffic[0].rx_bps *8, 1, ""),
      tx_bps: rateConversion(traffic[0].tx_bps *8, 1, ""),
      rx_pps: rateConversion(traffic[0].rx_pps, 1, ""),
      tx_pps: rateConversion(traffic[0].tx_pps, 1, "")
    }
    */

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Dashboard</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Traffic</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Network Traffic</TT></h4>
        <Row className='col-md-12 mr-5'>
          <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
          <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
            onClick = {() => this.updateTraffic(this.props.traffic.selectOptions.deviceId,
              this.props.traffic.selectOptions.interfaceNum)}>
            <FontAwesomeIcon icon="sync-alt" />
          </Button>
          <Label htmlFor="device" className="col-form-label ml-3">
            <TT>Device</TT>
          </Label>
          <Col md={3}>
            <SearchableSelectBox
              id="device"
              name="device"
              isClearable={false}
              value={this.props.traffic.selectOptions.device}
              placeholder=""
              onChange={this.handleChangeDeviceAction}
              options={deviceOpts}
            />
          </Col>
          <Label htmlFor="interface" className="col-form-label ml-3">
            <TT>Interface</TT>
          </Label>
          <Col md={4}>
            <SearchableSelectBox
              id="interface"
              name="interface"
              isClearable={false}
              value={this.props.traffic.selectOptions.interface}
              placeholder=""
              onChange={this.handleChangeInterfaceAction}
              options={interfaceOpts}
            />
          </Col>
          <Label htmlFor="direction" className="col-form-label ml-3">
            <TT>Direction</TT>
          </Label>
          <Col md={2}>
            <SearchableSelectBox
              id="direction"
              name="direction"
              value={this.props.traffic.selectOptions.direction}
              placeholder=""
              onChange={this.handleChangeDirectionAction}
              isClearable={false}
              options={[
                //{label:'RX,TX', value:'rx,tx'},
                {label:'RX+TX', value:'rxtx'},
                {label:'RX', value:'rx'},
                {label:'TX', value:'tx'}]}
            />
          </Col>
        </Row>
        <hr />
        {/*<Row style={{paddingLeft:'15px'}}>
          <Col md={2}>BPS RX: {sum_pkts.rx_bps}</Col><Col md={2}>BPS TX: {sum_pkts.tx_bps}</Col>
          <Col md={2}>PPS RX: {sum_pkts.rx_pps}</Col><Col md={2}>PPS TX: {sum_pkts.tx_pps}</Col>
          </Row>*/}
        <NetworkTraffic
          graphType={this.props.traffic.selectOptions.directionValue}
          lastUpdate={this.state.lastUpdate}
          traffic={traffic}
          divId='network-chart'
        />
        {(isTunnel)?
        <TunnelPerformance
        graphType={'Status'}
        lastUpdate={this.state.lastUpdate}
        traffic={this.props.traffic.tunnelTraffic}
        divId='tunnel-perf-status'
        />:null}
        {(isTunnel)?
        <TunnelPerformance
        graphType={'RTT'}
        lastUpdate={this.state.lastUpdate}
        traffic={this.props.traffic.tunnelTraffic}
        divId='tunnel-perf-rtt'
        />:null}
        {(isTunnel)?
        <TunnelPerformance
        graphType={'DropRate'}
        lastUpdate={this.state.lastUpdate}
        traffic={this.props.traffic.tunnelTraffic}
        divId='tunnel-perf-droprate'
        />:null}
    </React.Fragment>
    );
  }
}

export default Traffic;
