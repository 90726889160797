import React, { useContext } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col } from "reactstrap";
import { rateConversion } from '../../../../utils/Conversions';
import moment from 'moment';
import { TT, LanguageContext } from '../../../../containers/Language';

const RemoteVpnDeviceStatus = props => {
  const { toTTString } = useContext(LanguageContext)

  const clientsColumns = [
    {
      text: toTTString("Name"),
      dataField: "Common Name",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      },
    },
    {
      text: toTTString("Real Address"),
      dataField: "Real Address",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      },
    },
    {
      text: toTTString("Virtual Address"),
      dataField: "Virtual Address",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      },
    },
    {
      text: toTTString("Bytes"),
      dataField: null,
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      },
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        return (
          <>
          <div><b><TT>Received</TT>:</b> {rateConversion(row['Bytes Received'], 2)}</div>
          <div><b><TT>Sent</TT>:</b> {rateConversion(row['Bytes Sent'], 2)}</div>
          </>
        )
      }
    },
    {
      text: toTTString("Connected Since"),
      dataField: 'Connected Since',
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      },
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        const startTime =  moment.utc(cellContent).local();
        const now = moment();
        const duration = moment.duration(now.diff(startTime));
        const durationTime = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
        // const durationDays = Math.round(duration.asDays()) + 2;
        return (
          <>
            <div>{startTime.format('HH:mm:ss MMM DD, YYYY')}</div>
            <div><small className="text-muted"><TT>Duration</TT>: {durationTime}</small></div>
          </>
        )
      }
    },
  ];

  return (
    <>
      <Row><Col><TT>Clients</TT></Col></Row>
      <Row>
        <Col md={12}>
          <BootstrapTable
             striped
             hover
             condensed
             keyField="Common Name"
             data={Object.values((props.data?.monitoring?.clients ?? []))}
             columns={clientsColumns}
             noDataIndication={toTTString("No clients connected to this device")}
             defaultSorted={[{ dataField: "Common Name", order: "asc" }]}
          />
        </Col>
      </Row>
    </>
  )
}

export default RemoteVpnDeviceStatus;
