import React, { Component, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Card, CardBody } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { required, maxLength, minLength, validateOrgGroupName, isPortValid,
  validateOrgDescription, validateTunnelRangeIP} from '../../utils/Validators';
import keyExchangeMethods from '../../utils/keyExchangeMethods';
import { FWTextInput } from '../Common';
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext  } from '../../containers/Language'

class AddOrganization extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }	

  handleSubmit(values) {
    console.log('Adding Organization: ' + JSON.stringify(values));
    this.props.addOrganization(values, this.props.resetForm);
  }

  render() {
    const toTTString = this.context.toTTString
    return(
      <React.Fragment>
        <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/organizations"><TT>Organizations</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>Add Organization</TT></BreadcrumbItem>
            </Breadcrumb>
        <h4><TT>Add Organization</TT></h4>
        {this.props.currentOrg===null?
          <div className="alert alert-warning col-md-12" role="alert">
            <TT>Any network configuration is done as part of an organization</TT>. <TT>Create your first organization to manage your network</TT>
          </div>:null}
        <div className="col-lg-8 col-md-12">
          <Card id='addOrganizationCard'>
            <CardBody>
              <Form model="addOrganization" onSubmit={(values) => this.handleSubmit(values)}>
                <Row className="form-group">
                  <Label htmlFor="name" lg={5} md={6}>
                    <span className="form-required-star">*</span>
                     <TT>Organization Name</TT> <span className="helpTooltip" data-tip data-for='orgNameTip'></span>
                    <ReactTooltip id='orgNameTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>The name of the organization</TT><br />
                        <TT>Organizations belong to the account</TT><br />
                        <TT>Any network inventory is part of an organization</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.text model=".name" id="name" name="name" placeholder={toTTString("Organization Name")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {	
                          required: required,
                          minLength: minLength(2),
                          maxLength: maxLength(30),
                          name: validateOrgGroupName
                        }
                      }
                    />
                    <Errors className="text-danger" model=".name" show="touched" 
                      messages={
                        {	
                          required: toTTString('Required field'),
                          minLength: toTTString('Length must be at least 2'),
                          maxLength: toTTString('Length must be at most 30'),
                          name: toTTString('Invalid organization name format')
                        }
                      }
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <Label htmlFor="description" lg={5} md={6}><TT>Description</TT> <span className="helpTooltip" data-tip data-for='orgDescTip'></span>
                    <ReactTooltip id='orgDescTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>Organization Description</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.text model=".description" id="description" name="description"
                      placeholder={toTTString("Description")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {
                          maxLength: maxLength(50),
                          description: validateOrgDescription
                        }
                      }
                    />
                    <Errors className="text-danger" model=".name" show="touched"
                      messages={
                        {
                          maxLength: toTTString('Length must be at most 50'),
                          description: toTTString('Invalid description format')
                        }
                      }
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <Label htmlFor="group" lg={5} md={6}>
                    <span className="form-required-star">*</span>
                    <TT>Organization Group</TT> <span className="helpTooltip" data-tip data-for='groupTip'></span>
                    <ReactTooltip id='groupTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>A name for a group of organizations</TT><br />
                        <TT>Some operations such as permissions
                        can be associated per group</TT><br />
                        <TT>If you don't know the group, keep it as Default</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.text model=".group" id="group" name="group" placeholder={toTTString("Organization Group")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {	
                          required:  required,
                          minLength: minLength(2),
                          maxLength: maxLength(30),
                          name: validateOrgGroupName
                        }
                      }
                    />
                    <Errors className="text-danger" model=".group" show="touched" 
                      messages={
                        {	
                          required: toTTString('Required field'),
                          minLength: toTTString('Length must be at least 3'),
                          maxLength: toTTString('Length must be at most 30'),
                          name: toTTString('Invalid organization group format')
                        }
                      }
                    />
                  </Col>
                </Row>                
                <Row className="form-group">
                  <Label htmlFor="encryptionMethod"  lg={5} md={6}><TT>Tunnels Key Exchange Method</TT> <span className="helpTooltip" data-tip data-for='encryptionMethodTip'></span>
                    <ReactTooltip id='encryptionMethodTip'>
                      <span style={{"fontSize": "0.8rem"}}>
                        <TT>Tunnels Key Exchange Method
                        used in tunnels of the Organization</TT><br />
                        <TT>PSK by default</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.select
                      model=".encryptionMethod"
                      id="encryptionMethod"
                      name="encryptionMethod"
                      className="form-control"
                    >
                      {Object.entries(keyExchangeMethods).map(([value, name]) =>
                        <option value={value}>{toTTString(name)}</option>
                      )}
                    </Control.select>
                  </Col>
                </Row>

                <VxlanPortField />
                
                <Row className="form-group">
                  <Label htmlFor="tunnelRange" md={5}> <TT>Tunnel Range</TT> 
                    <span className="helpTooltip" data-tip data-for="tunnelRangeTip"></span>
                    <ReactTooltip id="tunnelRangeTip">
                      <span style={{ fontSize: "0.8rem" }}>
                        <TT>IP range (/16) used only for tunnel loopbacks</TT>.<br/>
                        <TT>IPs on this range are not allowed elsewhere in the network</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" md={7}>
                    <Control.text model=".tunnelRange" id="tunnelRange" name='tunnelRange' 
                      placeholder="10.100.0.0"
                      component={FWTextInput} withFieldValue 
                      validators={{
                        required: required,
                        ip: validateTunnelRangeIP
                      }}/>
                    <Errors className="text-danger" model=".tunnelRange" show="touched"
                    messages={{
                      ip: toTTString("Invalid IP address"),
                      required: toTTString('Required field')
                    }}/>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col className="form-field" lg={{size:10, offset: 5}} md={{size:10, offset: 6}}>
                    <Control.button model="addOrganization" disabled={{ valid: false }} 
                      className="btn btn-primary"><TT>Add</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export const VxlanPortField = props => {
  const { toTTString } = useContext(LanguageContext);

  return (
    <Row className="form-group">
        <Label htmlFor="vxlanPort"  lg={5} md={6}>
          <TT>VXLAN port</TT>
          <span className="helpTooltip" data-tip data-for='vxlanPortTip'></span>
          <ReactTooltip id='vxlanPortTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>The UDP port for VXLAN tunnels. The common port is 4789</TT>
            </span>
          </ReactTooltip>
        </Label>
        <Col className="form-field" lg={7} md={6}>
          <Control.text
            {...props}
            model=".vxlanPort"
            id="vxlanPort"
            name="vxlanPort"
            placeholder={toTTString("Port")}
            component={FWTextInput}
            withFieldValue
            validators={{
              required:  required,
              isPort: isPortValid,
              reservedPorts: val => val !== '500' && val !== '4500' && val !== '0'
            }}
          />
          <Errors
            className="text-danger"
            model=".vxlanPort"
            show="touched"
            messages={{
              required: toTTString('Required field'),
              isPort: toTTString("Invalid Port"),
              reservedPorts: toTTString("Ports 0, 500, 4500 are reserved and cannot be used as vxlan port"),
            }}
          />
          { props.showEncryptMethodWarning ? (
            <div style={{ color: 'red' }}>
              <small><TT>Changing the VXLAN port may require re-establishment of all tunnels</TT>.</small>
              <small><TT>If there are tunnels defined, it may take a few minutes for tunnels to be reconnected</TT>.</small>
            </div>
          ) : null}
        </Col>
      </Row>
  )
}

export default AddOrganization;