import React from 'react';
import { TT } from '../../containers/Language'

const infoNotAvailable = props => {
  if (props.show) {
    return (
      <div
        id="device-send-alert"
        className="alert alert-warning col-md-12"
        role="alert"
      >
        <TT>Information not available</TT>, <TT>Please check that the device is connected and try again</TT>
      </div>
    )
  }
  return null;
}

export default infoNotAvailable;
