import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import RoutingCommandsCli from './RoutingCommandsCli';
import { Form, Control, actions } from 'react-redux-form';
import { TT } from '../../containers/Language';
import isEqual from 'lodash.isequal';
import { saveAdvancedRouting } from '../../redux/reducers/Devices';
import { ToastContainer, toast } from 'react-toastify';

const AdvancedRouting = props => {

  useEffect(() => {
    if (props.advancedRouting?.custom) {
      props.changeForm(props.advancedRouting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.advancedRouting])

  useEffect(() => {
    return () => {
      props.resetForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateAdvancedRoutingForm = () => {
    props.changeForm(props.advancedRouting, {silent: true})
  }

  const handleResetChanges = () => {
    initiateAdvancedRoutingForm()
  }

  const handleSubmit = values => {
    props.saveAdvancedRouting(values);
    toast("Saved!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <h6><TT>Advanced Routing Configuration</TT></h6>

      <div className={"general-card-upper-panel d-flex justify-content-between"}>
        <Control.button
          form="AdvancedRoutingForm"
          type="submit"
          model="AdvancedRoutingForm"
          className={"btn btn-primary action-btn-top upper-panel-button"}
        >
          <TT>Save</TT>
        </Control.button>

        { isEqual(props.advancedRouting, props.form) ? null : (
          <button
            onClick={handleResetChanges}
            type="button"
            className="btn btn-outline-secondary action-btn-top upper-panel-button"
          >
            <TT>Reset Changes</TT>
          </button>
        )}
      </div>

      <Row>
        <Col>
          <Card id='AdvancedRoutingCard'>
            <CardBody>
              <Form
                id="AdvancedRoutingForm"
                model="AdvancedRoutingForm"
                onSubmit={handleSubmit}
              >
                <Row className="form-group d-flex">
                  <Col>
                    <RoutingCommandsCli
                      form="AdvancedRoutingForm"
                      model="custom"
                      executedText='before any routing command'
                      advancedText="routing"
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  )
}

const mapStateToProps = state => {
  return {
    form: state.AdvancedRoutingForm,
    advancedRouting: state.devices.advancedRouting
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeForm: vals => dispatch(actions.change('AdvancedRoutingForm', vals)),
    resetForm: () => dispatch(actions.reset('AdvancedRoutingForm')),
    saveAdvancedRouting: vals => dispatch(saveAdvancedRouting(vals))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedRouting);