import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { TT, LanguageContext } from '../../../containers/Language';
import { Form, Control, Errors } from 'react-redux-form';
import { ModalBody, Modal, ModalHeader, Button, Row, Col, Label } from 'reactstrap';
import { FWTextInput } from '../../Common';
import { minValue, maxValue, required } from '../../../utils/Validators';
import Select from 'react-select';
import cidrTools from 'cidr-tools';
import { actions } from 'react-redux-form';
import ReactTooltip from 'react-tooltip';
import keyBy from 'lodash/keyBy';

const formName = 'vrrpGroupDevice';

const VrrpDevicesModal = props => {
  const { resetForm, deviceToUpdate, dispatch } = props;
  const { toTTString } = useContext(LanguageContext);

  // cleanup
  useEffect(() => {
    return () => {
      resetForm(formName)();
    }
  }, [resetForm]);

  useEffect(() => {
    if (deviceToUpdate) {
      dispatch(actions.change(formName, deviceToUpdate));
    }
  }, [deviceToUpdate, dispatch])

  const getOverlappingDevices = () => {
    if (!props.devices || !props.virtualIp) {
      return []
    }

    const devices = Object.values(props.devices).filter(d => {
      // exclude selected devices. Allow it when modifying device.
      if (!deviceToUpdate && props.selectedDevices.includes(d._id)) {
        return false;
      }

      const interfaces = Object.values(d.interfaces).filter(di => {
        if (di.IPv4 === '/') return false; // server can return interface without ip (WAN when cable connected)
        return cidrTools.overlap(di.IPv4, props.virtualIp + '/32')
      })
      return interfaces.length > 0;
    });

    return devices;
  }

  const getSelectedDeviceOverlappingInterfaces = () => {
    if (!props.devices || !props.form.device) {
      return []
    }

    const selectedDevice = props.devices[props.form.device];
    return Object.values(selectedDevice.interfaces).filter(di => {
      if (di.IPv4 === '/')  return false;
      return cidrTools.overlap(di.IPv4, props.virtualIp + '/32')
    });
  }

  const getDeviceTrackInterfacesOptions = (formKey) => {
    if (!props.devices || !props.form.device) {
      return []
    }

    const secondFormKey = formKey === 'trackInterfacesOptional' ? 'trackInterfacesMandatory' : 'trackInterfacesOptional';
    const selectedBySecondList = keyBy(props.form[secondFormKey] ?? []);
    const selectedDevice = props.devices[props.form.device];
    return Object.values(selectedDevice.interfaces).filter(di => {
      if (di.IPv4 === '/' && di.dhcp === 'yes')  return true; // allow DHCP interfaces without ip
      if (di.IPv4 === '/') return false; // do not allow other interfaces without ip
      if (di.devId in selectedBySecondList) return false; // do not show item that selected by the other list
      return !cidrTools.overlap(di.IPv4, props.virtualIp + '/32')
    });
  }

  const getTrackInterfaceValue = (formKey) => {
    if (!props.devices || !props.form.device || !props.form[formKey] || props.form[formKey].length === 0) {
      return []
    }

    const selectedDevice = props.devices[props.form.device];
    return props.form[formKey].map(ifc => {
      return selectedDevice.interfaces[ifc];
    })
  }

  const changeAndResetFields = (model, value, fields) => {
    return (dispatch) => {
      dispatch(actions.change(model, value));
      for (const field of fields) {
        dispatch(actions.reset(formName + '.' + field));
      }
    };
  }

  return (
    <>
      <Modal isOpen={true} size="lg">
        <ModalHeader>{props.deviceToUpdate ? toTTString("Update") : toTTString("Add")} <TT>Device</TT></ModalHeader>
        <ModalBody>
          <>
          <Form
            model={formName}
            onSubmit={props.onSubmit}
          >
            <Row className="form-group">
              <Label htmlFor="device" md={4}>
                <span className="form-required-star">*</span>
                <TT>Device</TT>
                <span className="helpTooltip" data-tip data-for='deviceVrrpTip'></span>
                <ReactTooltip id='deviceVrrpTip'>
                  <span>
                    <TT>All available devices in your network that overlapping with the configured Virtual IP</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <Col md={8}>
                <Control.select
                  model=".device"
                  id="device"
                  name="device"
                  disabled={deviceToUpdate !== null}
                  className="form-control"
                  changeAction= {(model, value) => changeAndResetFields(model, value, ['interface', 'trackInterfacesMandatory', 'trackInterfacesOptional']) }
                  validators={{
                    required: required
                  }}
                >
                  <option key="" value="">{toTTString('Select a device')}</option>,
                  {getOverlappingDevices().map((dev) => (
                    <option key={dev._id} value={dev._id}>
                      {dev.name}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  className="text-danger"
                  model=".device"
                  show="touched"
                  messages={{
                    required: toTTString('Required field'),
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="interface" md={4}>
                <span className="form-required-star">*</span>
                <TT>Interface</TT>
                <span className="helpTooltip" data-tip data-for='interfaceVrrpTip'></span>
                <ReactTooltip id='interfaceVrrpTip'>
                  <span>
                    <TT>Overlapping interfaces list of the selected device</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <Col md={8}>
                <Control.select
                  model=".interface"
                  id="interface"
                  name="interface"
                  changeAction= {(model, value) => changeAndResetFields(model, value, ['trackInterfacesOptional', 'trackInterfacesMandatory']) }
                  disabled={!props.form.device}
                  className="form-control"
                  validators={{
                    required: required
                  }}
                >
                  <option key="" value="">{toTTString('Select an interface')}</option>,
                  {getSelectedDeviceOverlappingInterfaces().map((ifc) => (
                    <option key={ifc.devId} value={ifc.devId} disabled={ifc.type === 'WAN'}>
                      {ifc.name} - {ifc.IPv4}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  className="text-danger"
                  model=".interface"
                  show="touched"
                  messages={{
                    required: toTTString('Required field'),
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="priority" md={4}>
                <span className="form-required-star">*</span>
                <TT>Priority</TT>
                <span className="helpTooltip" data-tip data-for='priorityVrrpTip'></span>
                <ReactTooltip id='priorityVrrpTip'>
                  <span>
                    <TT>Device with the highest number will be elected as Master (e.g. 100 - Backup, 200 - Master)</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <Col md={8}>
                <Control
                  id="priority"
                  model=".priority"
                  name="priority"
                  placeholder={toTTString("Priority")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    required: required,
                    minValue: minValue(1),
                    maxValue: maxValue(255),
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".priority"
                  show="touched"
                  messages={{
                    required: toTTString('Required field'),
                    minValue: toTTString('Priority must be greater than or equal to 1'),
                    maxValue: toTTString('Priority must be less than or equal to 255')
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="trackInterfacesMandatory" md={4}>
                <TT>Mandatory Track Interfaces</TT>
                <span className="helpTooltip" data-tip data-for='trackInterfacesMandatoryVrrpTip'></span>
                <ReactTooltip id='trackInterfacesMandatoryVrrpTip'>
                  <span>
                    <TT>Track other interfaces' states and set the device as Backup if one of those interfaces are inaccessible</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <Col md={8}>
                <Select
                  options={getDeviceTrackInterfacesOptions('trackInterfacesMandatory')}
                  getOptionLabel={option => {
                    return option.name
                  }}
                  getOptionValue={option => {
                    return option.devId
                  }}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={result => {
                    props.dispatch(changeAndResetFields(formName+".trackInterfacesMandatory", (result ?? []).map(i => i.devId), []))
                  }}
                  isDisabled={!props.form.device || !props.form.interface}
                  value={getTrackInterfaceValue('trackInterfacesMandatory')}
                  model={formName+".trackInterfacesMandatory"}
                  name={formName+".trackInterfacesMandatory"}
                  id={formName+".trackInterfacesMandatory"}
                  placeholder={toTTString("Select Mandatory Track Interfaces") + "..."}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="trackInterfacesOptional" md={4}>
                <TT>Optional Track Interfaces</TT>
                <span className="helpTooltip" data-tip data-for='trackInterfacesOptionalVrrpTip'></span>
                <ReactTooltip id='trackInterfacesOptionalVrrpTip'>
                  <span>
                    <TT>Track other interfaces' states and set the device as Backup if</TT> <b>All</b> <TT>of those interfaces are inaccessible</TT>
                  </span>
                </ReactTooltip>
              </Label>
              <Col md={8}>
                <Select
                  options={getDeviceTrackInterfacesOptions('trackInterfacesOptional')}
                  getOptionLabel={option => {
                    return option.name
                  }}
                  getOptionValue={option => {
                    return option.devId
                  }}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={result => {
                    props.dispatch(changeAndResetFields(formName+".trackInterfacesOptional", (result ?? []).map(i => i.devId), []))
                  }}
                  isDisabled={!props.form.device || !props.form.interface}
                  value={getTrackInterfaceValue('trackInterfacesOptional')}
                  model={formName+".trackInterfacesOptional"}
                  name={formName+".trackInterfacesOptional"}
                  id={formName+".trackInterfacesOptional"}
                  placeholder={toTTString("Select Optional Track Interfaces") + "..."}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <Col>
                <Control.button
                  model={formName}
                  className="btn btn-primary"
                >
                  <TT>Save</TT>
                </Control.button>

                <Button
                  className="float-right"
                  color="outline-secondary"
                  type="button"
                  onClick={props.close}
                >
                  <TT>Cancel</TT>
                </Button>
              </Col>
            </Row>
          </Form>
          </>
        </ModalBody>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    form: state[formName]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VrrpDevicesModal);