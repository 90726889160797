import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TT } from '../../containers/Language'

class OpenPortal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
    this.hideLoading = this.hideLoading.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.loadPortal = this.loadPortal.bind(this);
  }

  componentDidMount() {
    this.loadPortal();

    this.setState({ isLoading: true });
  }

  componentWillUnmount() {
  }

  loadPortal() {
    this.showLoading();
    this.props.openPortal();
  }

  showLoading() {
    this.setState({ isLoading: true });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem><Link to="/billing"><TT>Billing</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Manage Account</TT></BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4><TT>Manage Account</TT></h4>
          {this.props.isLoading ? <div className="signal"></div> : null}
        </div>
        <Link to="/billing">
          <Button color="info" className="back-btn"          >
            <FontAwesomeIcon icon="arrow-circle-left" fixedWidth />
          </Button>
        </Link>
        {this.state.isLoading ?
          <div className={"jumbotron"} style={{ background: "none transparent" }}>
            <div className="container" style={{ background: "none transparent" }}>
              <h4><TT>Please wait</TT>. <TT>Customer Portal is loading</TT>.</h4>
            </div>
          </div> : null}
        <div className="col-lg-12 col-md-12 col-sm-12">
          <IframeResizer
            hidden={this.state.isLoading}
            onLoad={this.hideLoading}
            src={this.props.portal ? this.props.portal.access_url : null}
            style={{ background: "none transparent", width: '1px', minWidth: '100%', height: '1px', minHeight: '640px', border: 'none' }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default OpenPortal;