import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TT, LanguageContext } from '../../containers/Language'

const jwt = require("jsonwebtoken");
class Tokens extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    console.log("Tokens componentDidMount");
    this.props.getAllTokens();
    if (this.statusTimer == null) {
      console.log("Tokens: Setting periodic timer");
      const timer = setInterval(function(that) {
        console.log("Tokens periodic update");
        // Only update when in focus
        if (document.hasFocus()) {
          that.props.getAllTokens();
        }
      },60000, this);
      this.statusTimer = timer;
    }
  }
  componentWillUnmount() {
    console.log("Tokens componentWillUnmount");
    if (this.statusTimer != null) {
      console.log("Tokens: Clearing periodic timer");
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  constructor(props) {
    super(props);

    this.statusTimer = null;

    this.state = {
      isDelModalOpen: false,
      delEntity: null
    };

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen
    });
  }

  handleDelete(row) {
    console.log("handleDelete: " + JSON.stringify(row));
    this.setState({delEntity: row});
    this.toggleDelModal();
  }
  approveDelete() {
    console.log("Delete clicked for tokenID: " + this.state.delEntity._id);
    this.toggleDelModal();
    this.props.delToken(this.state.delEntity, this.props.getAllTokens);
  }

  showToast = () => {
    toast(<TT>Token Copied!</TT>, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  render() {
    const toTTString = this.context.toTTString

    const columns = [
        {text: <TT>Name</TT>, dataField: 'name', sort:true,
            headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          }
        },
        {text: <TT>Token</TT>, dataField: 'token', sort:true,
            headerStyle: (colum, colIndex) => {
            return { width: '65%', textAlign: 'left' };
          }
        },
        {
          text: <TT>Server</TT>,
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            const decodedToken = jwt.decode(row.token);
            return decodedToken.server;
          }
        },
        {text: <TT>Created At</TT>, dataField: 'createdAt', sort:true,
          headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            return (new Date(row.createdAt)
              .toLocaleString('en-US', { year: 'numeric',month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'}));
          }
        },
        {text: <TT>Actions</TT>, dataField: 'none', sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            var data = [row.token];
            var properties = {type: 'text/plain'};
            var file;
            // Try using File but fallback to Blob
            try {
              file = new File(data, "token.txt", properties);
            } catch (e) {
              file = new Blob(data, properties);
            }
            var url = URL.createObjectURL(file);
            return (<div>
                      <CopyToClipboard text={row.token}
                        onCopy={() => this.showToast()}>
                        <Button color="primary" className="action-btn" data-tip data-for='copy-a' size="sm">
                          <FontAwesomeIcon icon="clone" fixedWidth />
                        </Button>
                      </CopyToClipboard>
                      <ReactTooltip id='copy-a'><span><TT>Copy To Clipboard</TT></span></ReactTooltip>
                      <a download="token.txt" data-tip data-for='download-a' href={url}>
                        <Button color="info" className="action-btn" size="sm">
                          <FontAwesomeIcon icon="download" fixedWidth />
                        </Button>
                      </a>
                      <ReactTooltip id='download-a'><span><TT>Download Token</TT></span></ReactTooltip>
                      <Link to={"/tokens/update/" + row._id}>
                        <Button color="warning" className="action-btn" data-tip data-for='update-a' size="sm">
                          <FontAwesomeIcon icon="cog" fixedWidth />
                        </Button>
                      </Link>
                      <ReactTooltip id='update-a'><span><TT>Update Token</TT></span></ReactTooltip>
                      <Button color="danger" className="action-btn" data-tip data-for='delete-a' size="sm"
                        onClick={() => this.handleDelete(row)}>
                        <FontAwesomeIcon icon="trash-alt" fixedWidth />
                      </Button>
                      <ReactTooltip id='delete-a'><span><TT>Delete Token</TT></span></ReactTooltip>
                    </div>);
          }
        }
    ];

    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
                                                      <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                    </span>),
      sizePerPageList: [{text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}]
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      selected: [],
      onSelect: (row, isSelect) => {
        console.log("onSelect: " + JSON.stringify(row) + ", " + isSelect);
      },
      onSelectAll: (isSelect, rows) => {
        console.log("onSelectAll: " + JSON.stringify(rows) + ", " + isSelect);
      }
    };

  return(
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
        <BreadcrumbItem active><TT>Tokens</TT></BreadcrumbItem>
      </Breadcrumb>
      <div className="container">
        <h4><TT>Tokens</TT></h4>
        {this.props.tokens.isLoading? <div className="signal"></div> : null}
      </div>
      <div className="col-md-12">
        <div className="mb-3">
        <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
          <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
            onClick = {this.props.getAllTokens}>
            <FontAwesomeIcon icon="sync-alt" />
          </Button>
        <Link to="/tokens/add">
          <Button color="success" className="action-btn-top" size="sm"><TT>New Token</TT></Button>
        </Link>
        </div>
        <BootstrapTable striped hover condensed
          keyField='_id'
          data={ this.props.tokens.tokens }
          columns={ columns }
          pagination={ paginationFactory(paginationOptions) }
          noDataIndication={toTTString("No tokens available")}
          defaultSorted={[{dataField: 'name',order: 'asc'}] }
          selectRow={ selectRow } />
      </div>
      <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
        <ModalHeader toggle={this.toggleDelModal}><TT>Delete Token</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3"><TT>Are you sure to delete token?</TT></div>
          <Button color="danger" onClick={this.approveDelete}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={this.toggleDelModal}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
    );
  }
}
export default Tokens;