import React, { Component, useState } from "react";
import { Prompt } from 'react-router'
import {
  Breadcrumb,
  BreadcrumbItem,
  Nav,
  NavItem,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  createIpField,
  convertIsAssignedField,
  convertIfcTypeField,
  convertRoutingField
} from "./InterfacesCard";
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import DeviceGeneralCard from "./DeviceGeneralCard";
import InterfacesCard from "./InterfacesCard";
import StatisticsCard from "./StatisticsCard";
import EdgeSettingsCard from "./EdgeSettingsCard";
import LogsCard from "./LogsCard";
import PacketTracesCard from "./PacketTracesCard";
import RoutingTableCard from "./RoutingTableCard";
import OSPFCard from "./OSPFCard";
import BGPCard from "./BGPCard";
import RoutingFilters from "./RoutingFilters";
import RoutingFilter from "./RoutingFilter";
import StaticRoutesCard from "./StaticRoutesCard";
import DHCPCard from "./DHCP/DHCPCard";
import PoliciesCard from "./PoliciesCard";
import AppsCard from './AppsCard';
import VrrpCard from "./VrrpCard";
import RecoveryInfoCard from "./RecoveryInfoCard";
import * as qs from 'query-string';

import DeviceFirewallCard from "./DeviceFirewallCard"
import SendDevice from "./SendDevice"
import { store } from "../../App";
import { convertFirewallRules } from "../../utils/FirewallUtils"
import { isDeviceVersionGreaterEquals } from "../../utils/DevicesUtils"
import { TT, LanguageContext } from '../../containers/Language'
import OverlappingConfirmationModal from "./OverlappingConfirmationModal";
import BGPNeighborsStatus from "./BGPNeighborsStatus";
import AdvancedRouting from "./AdvancedRouting";

const tabsTitles = {
  Firewall: 'Firewall and NAT',
  BGPNeighbors: 'BGP Neighbors'
};

class DeviceInfo extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    const query = qs.parse(props.search);

    this.state = {
      currentTab: query.tab || "General",
      appIdentifications: [],
      apps: [],
      categories: [],
      serviceClasses: [],
      valid: {
        general: true,
        interfaces: true,
        staticRoutes: true
      },
      origData: null,
      deviceStatus: {},
    };
    this.statusTimer = null;

    this.shouldDisableUpdButton = this.shouldDisableUpdButton.bind(this);
    this.updateUserInputState = this.updateUserInputState.bind(this);
    this.getSyncStatus = this.getSyncStatus.bind(this);
    this.warnOnLeavingPage = this.warnOnLeavingPage.bind(this);
    this.checkIfDataChanged = this.checkIfDataChanged.bind(this);
    this.updateDeviceStore = this.updateDeviceStore.bind(this);
    this.updateDevice = this.updateDevice.bind(this);
    this.getDeviceBadges = this.getDeviceBadges.bind(this);
  }

  componentDidMount() {
    this.props.getDevice(this.props.id, this.updateDeviceStore);
    this.props.getDeviceTunnels(this.props.id);
    // Set timer for refreshing device status
    if (!this.statusTimer) {
      this.statusTimer = setInterval(that => {
        const { id, getDeviceStatus } = that.props;
        // Only update when in focus
        if (document.hasFocus()) {
          getDeviceStatus(id, resp => {
            that.setState((prevState) => ({ deviceStatus: { ...prevState.deviceStatus, ...resp } }));
          });
        }
      },10000, this);
    }

    // Listen to page reload event, to warn the user
    // upon leaving the page with unsaved changes
    window.addEventListener('beforeunload', this.warnOnLeavingPage);
  }

  componentWillUnmount(){
    // Remove device status periodic check
    if (this.statusTimer !== null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }

    // Remove page reload listener
    window.removeEventListener('beforeunload', this.warnOnLeavingPage);
  }

  componentWillReceiveProps(nextProps) {
    const query = qs.parse(nextProps.search);
    this.setState({
      currentTab: query.tab || "General"
    });
  }

  navToggle(tab) {
    this.setState({ currentTab: tab });
  }

  updateDeviceStore(resp) {
    // Save device general information
    this.props.changeForm(
      {
        _id: resp._id,
        name: resp.name,
        description: resp.description,
        machineId: resp.machineId,
        serial: resp.serial,
        hostname: resp.hostname,
        isApproved: resp.isApproved,
        cpuInfo: resp.cpuInfo,
        distro: resp.distro,
        deviceSpecificRulesEnabled: resp.deviceSpecificRulesEnabled,
        deviceVersion: resp.versions.device
      },
      { silent: true }
    );

    // Update device status
    this.setState({ deviceStatus: {
      sync: resp.sync,
      isApproved: resp.isApproved,
      connection: `${resp.isConnected ? '' : 'dis'}connected`,
      agentVersion: resp.versions.agent,
      lteStatus: resp.deviceStatus.lteStatus,
      wifiStatus: resp.deviceStatus.wifiStatus
    } });

    // Save device interfaces
    this.props.saveDeviceInterfaces(resp.interfaces.map(ifcOrig => {
      const ifc = { ...ifcOrig };
      ifc.IPv4 = createIpField(ifc.IPv4, ifc.IPv4Mask);
      ifc.IPv6 = createIpField(ifc.IPv6, ifc.IPv6Mask);
      ifc.isAssigned = convertIsAssignedField(ifc.isAssigned);
      ifc.type = convertIfcTypeField(ifc.type);
      ifc.routing = convertRoutingField(ifc.routing);
      // ensure that ospf area is string and not a number
      if (Number.isInteger(ifc?.ospf?.area)) {
        ifc.ospf.area = ifc?.ospf?.area.toString();
      }
      return ifc;
    }));

    // Save routing configuration
    this.props.saveOSPF({...resp.ospf})
    this.props.saveBGP({...resp.bgp})
    this.props.saveRoutingFilters([...resp.routingFilters ?? []])
    this.props.saveAdvancedRouting({...resp.advancedRouting})

    // Save device static routes
    this.props.saveDeviceStaticRoutes(resp.staticroutes)

    // Save device DHCP entries
    this.props.saveDHCP(resp.dhcp)

    this.props.getAllAppIdentifications(appsResp => {
      let [apps, categories, serviceClasses] = [
        new Map(),
        new Map(),
        new Map(),
      ];

      appsResp.appIdentifications.forEach((ent) => {
        const { name, category, serviceClass, id } = ent;
        apps.set(name, { label: name, value: name, id: id });
        categories.set(category, { label: category, value: category });
        serviceClasses.set(serviceClass, { label: serviceClass, value: serviceClass});
      });

      this.setState({
        appIdentifications: appsResp.appIdentifications,
        apps: apps,
        categories: [...categories.values()],
        serviceClasses: [...serviceClasses.values()]
      });

      // Save device firewall rules
      const convertedRules = convertFirewallRules(resp.firewall.rules, appsResp.appIdentifications);
      this.props.saveDeviceFirewallRules(convertedRules);

      // Save a copy of the original data that will be
      // used for warning the user on unsaved changes
      const { deviceInfo, devices } = store.getState();
      this.setState({
        origData: {
          general: this.getObjectData(deviceInfo),
          interfaces: this.getObjectData(devices.tableInterfaces),
          staticRoutes: this.getObjectData(devices.staticRoutes),
          dhcp: this.getObjectData(devices.dhcp),
          ospf: this.getObjectData(devices.ospf),
          firewallRules: this.getObjectData(convertedRules),
          bgp: this.getObjectData(devices.bgp),
          routingFilters: this.getObjectData(devices.routingFilters),
          advancedRouting: this.getObjectData(devices.advancedRouting),
        }
      });
    });

  }

  checkIfDataChanged() {
    if (!this.state.origData) {
      return false;
    }
    const { deviceInfo, devices } = store.getState();
    const currentData = {
      general: this.getObjectData(deviceInfo),
      interfaces: this.getObjectData(devices.tableInterfaces),
      staticRoutes: this.getObjectData(devices.staticRoutes),
      ospf: this.getObjectData(devices.ospf),
      firewallRules: this.getObjectData(devices.firewallRules),
      dhcp: this.getObjectData(devices.dhcp),
      bgp: this.getObjectData(store.getState().devices.bgp),
      routingFilters: this.getObjectData(store.getState().devices.routingFilters),
      advancedRouting: this.getObjectData(store.getState().devices.advancedRouting)
    };
    return !isEqual(this.state.origData, currentData);
  }

  warnOnLeavingPage(event) {
    if (this.checkIfDataChanged()) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  updateUserInputState(tab, isValid) {
    // Disable update button if any of
    // the tab validations has failed
    // render the component on every call to update the dataChanged value
    this.setState({ valid: { ...this.state.valid, [tab]: isValid} });
  }

  getSyncStatus() {
    return this.state.deviceStatus.sync || {};
  }

  shouldDisableUpdButton() {
    for (let state of Object.values(this.state.valid)) {
      if (state === false) return true;
    }
    return false;
  }

  updateDevice(allowOverlapping = false) {
    this.props.updDevice((resp) => {
      this.updateDeviceStore(resp);
    }, () => {
      // the reason of the failure can be in modified unassigned interfaces
      // so need to refresh the UI with the updated data from server
      this.props.getDevice(this.props.id, (resp) => {
        const updInterfaces = this.props.interfaces.map(ifc => {
          if (ifc.isAssigned !== 'Yes') {
            const updIfc = resp.interfaces.find(_ => _.devId === ifc.devId);
            if (updIfc) {
              ifc.IPv4 = createIpField(updIfc.IPv4, updIfc.IPv4Mask);
              ifc.IPv6 = createIpField(updIfc.IPv6, updIfc.IPv6Mask);
              ifc.type = convertIfcTypeField(updIfc.type);
              ifc.routing = convertRoutingField(updIfc.routing);
              ifc.gateway = updIfc.gateway;
              ifc.dhcp = updIfc.dhcp;
              ifc.metric = updIfc.metric;
              ifc.mtu = updIfc.mtu;
            }
          }
          return ifc;
        });
        this.props.saveDeviceInterfaces(updInterfaces);
      });
    }, allowOverlapping);
  }

  getObjectData(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  getSyncButton() {
    return (
      <React.Fragment>
        <ReactTooltip id="sync-a">
          <span><TT>Sync Device Config</TT></span>
        </ReactTooltip>
        <Button
          color="outline-success"
          className="action-btn"
          data-tip
          data-for="sync-a"
          size="sm"
          style={{float: "right"}}
          // onClick={() => props.handleSyncDevice(device)}
        >
          <FontAwesomeIcon icon="sync" fixedWidth />
        </Button>
      </React.Fragment>
    );
  };

  getDeviceBadges() {
    const { deviceStatus } = this.state;
    const { syncDevice, id } = this.props;
    if (isEqual(deviceStatus, {})) return '';

    const { isApproved, connection, sync } = deviceStatus;
    const [approveColor, approveText, approvePrint] = isApproved
      ? ["success", "Approved", <TT>Approved</TT>]
      : ["danger", "Not Approved", <TT>Not Approved</TT>];

    const [connectColor, connectText, connectPrint] = connection === 'connected'
      ? ["success", "Connected", <TT>Connected</TT>]
      : ["danger", "Not Connected", <TT>Not Connected</TT>];

    const syncStateMap = {
      "synced": { title: <TT>Synced</TT>, color: 'success' },
      "syncing": { title: <TT>Syncing</TT>, color: 'warning' },
      "not-synced": { title: <TT>Not Synced</TT>, color: 'danger' },
      "unknown": { title: <TT>Sync Pending</TT>, color: 'warning' }
    };
    const { state } = sync;
    const [stateColor, stateText] = [
      syncStateMap[state].color,
      syncStateMap[state].title,
    ];

    return (
      <React.Fragment>
        {
          state === "not-synced" ?
            <React.Fragment>
              <ReactTooltip id="sync-a">
                <span>Sync Device Config</span>
              </ReactTooltip>
              <Button
                color="outline-success"
                className="action-btn"
                data-tip
                data-for="sync-a"
                size="sm"
                style={{float: "right", marginRight: "10px", marginTop: "5px"}}
                onClick={() => syncDevice(id)}
              >
                <FontAwesomeIcon icon="sync" fixedWidth />
              </Button>
            </React.Fragment>
            :
            ""
        }
        {
          (approveText !== "Not Approved" && connectText !== "Not Connected") ?
            <Badge
              className="device-card-badge"
              color={stateColor}
              style={{ float: "right" }}
            >
            {stateText}
            </Badge>
            : null
        }
        <Badge
          className="device-card-badge"
          color={connectColor}
          style={{ float: "right" }}
        >
          {connectPrint}
        </Badge>
        <Badge
          className="device-card-badge"
          color={approveColor}
          style={{ float: "right" }}
        >
          {approvePrint}
        </Badge>
      </React.Fragment>
    );
  }

  isSupportBgpStatus = () => {
    const { agentVersion } = this.state.deviceStatus || {};
    return agentVersion && isDeviceVersionGreaterEquals(agentVersion, '6.3.0');
  }

  isSupportAdvancedRouting = () => {
    const { agentVersion } = this.state.deviceStatus || {};
    return agentVersion && isDeviceVersionGreaterEquals(agentVersion, '6.3.30');
  }

  getTabsMap() {
    return {
      General:
        <React.Fragment>
          <DeviceGeneralCard
             id={this.props.id}
             device={this.props.device}
             updateUserInputState={this.updateUserInputState}
             getSyncStatus={this.getSyncStatus}
             updCoords={this.props.updCoords}
          />
        </React.Fragment>,
      Interfaces:
        <React.Fragment>
          <InterfacesCard
            device={this.props.device}
            dhcp={this.props.dhcp}
            interfaces={this.props.interfaces}
            saveDeviceInterfaces={this.props.saveDeviceInterfaces}
            saveDeviceStaticRoutes={this.props.saveDeviceStaticRoutes}
            saveDHCP={this.props.saveDHCP}
            saveDeviceFirewallRules={this.props.saveDeviceFirewallRules}
            deviceStatus={this.state.deviceStatus}
            updateUserInputState={this.updateUserInputState}
            id={this.props.id}
            deviceTunnels={this.props.deviceTunnels}
            getPathLabels={this.props.getPathLabels}
            qosPolicies={this.props.qosPolicies}
            getQOSPoliciesList={this.props.getQOSPoliciesList}
            changUpdateInterfaceForm={this.props.changUpdateInterfaceForm}
            saveInterfaceConfiguration={this.props.saveInterfaceConfiguration}
            getInterfaceStatus={this.props.getInterfaceStatus}
            performInterfaceAction={this.props.performInterfaceAction}
            alertMsgMessage={this.props.alertMsgMessage}
            resetPinForm={this.props.resetPinForm}
            resetLteForm={this.props.resetLteForm}
            changeForm={this.props.changeGeneralForm}
            resetForm={this.props.resetGeneralForm}
            setInitial={this.props.setInitial}
            history={this.props.history}
          />
        </React.Fragment>,
      DHCP:
        <React.Fragment>
          <DHCPCard
            id={this.props.id}
            device={this.props.device}
            interfaces={this.props.interfaces}
            saveDHCP={this.props.saveDHCP}
            dhcp={this.props.dhcp}
            dhcpEntries={this.props.dhcpEntries}
            changeForm={this.props.changeDHCPForm}
            updateUserInputState={this.updateUserInputState}
          />
        </React.Fragment>,
      Statistics:
        <React.Fragment>
          <StatisticsCard
            traffic = {this.props.traffic}
            health = {this.props.health}
            id = {this.props.id}
            getTraffic = {this.props.getTraffic}
            getHealth = {this.props.getHealth}
          />
        </React.Fragment>,
      RoutingTable:
        <React.Fragment>
          <RoutingTableCard
            id={this.props.id}
            device={this.props.device}
            getDeviceRoutes={this.props.getDeviceRoutes}
            history={this.props.history}
          />
        </React.Fragment>,
      OSPF:
        <React.Fragment>
          <OSPFCard
            id={this.props.id}
            interfaces={this.props.interfaces}
            resetForm={this.props.resetGeneralForm("OSPFConfiguration")}
            changUpdateInterfaceForm={this.props.changUpdateInterfaceForm}
            history={this.props.history}
            ospf={this.props.ospf}
            changeForm={this.props.changeGeneralForm("OSPFConfiguration")}
            saveOSPF={this.props.saveOSPF}
          />
        </React.Fragment>,
      AdvancedRouting:
        <>
         <AdvancedRouting />
        </>,
      BGP:
        <React.Fragment>
          <BGPCard
            id={this.props.id}
            getOrganization = {this.props.getOrganization}
            deviceStatus={this.state.deviceStatus}
            interfaces={this.props.interfaces}
            resetForm={this.props.resetGeneralForm("BGPConfiguration")}
            changeForm={this.props.changeGeneralForm("BGPConfiguration")}
            history={this.props.history}
            bgp={this.props.bgp}
            saveBGP={this.props.saveBGP}
            resetNeighborForm={this.props.resetGeneralForm("BGPNeighbor")}
            changeNeighborForm={this.props.changeGeneralForm("BGPNeighbor")}
            routingFilters={this.props.routingFilters}
          />
        </React.Fragment>,
      BGPNeighbors:
        <>
          <BGPNeighborsStatus
            id={this.props.id}
            deviceStatus={this.state.deviceStatus}
          />
        </>,
      RoutingFilters:
        <React.Fragment>
          <RoutingFilters
            id={this.props.id}
            history={this.props.history}
            routingFilters={this.props.routingFilters}
            saveRoutingFilters={this.props.saveRoutingFilters}
          />
        </React.Fragment>,
      RoutingFilter:
      <React.Fragment>
        <RoutingFilter
          id={this.props.id}
          history={this.props.history}
          routingFilters={this.props.routingFilters}
          saveRoutingFilters={this.props.saveRoutingFilters}
          deviceVersion={this.props.device?.versions?.agent}
        />
      </React.Fragment>,
      // Static Routes
      StaticRoutes:
        <React.Fragment>
          <StaticRoutesCard
            id={this.props.id}
            interfaces={this.props.interfaces}
            staticroutes={this.props.staticroutes}
            deviceTunnels={this.props.deviceTunnels}
            saveDeviceStaticRoutes={this.props.saveDeviceStaticRoutes}
            ospf={this.props.ospf}
            resetForm={this.props.resetGeneralForm}
            updateUserInputState={this.updateUserInputState}
          />
        </React.Fragment>,
      Policies:
        <React.Fragment>
          <PoliciesCard
            id={this.props.id}
            device={this.props.device}
            getDevice={this.props.getDevice}
            getQOSPoliciesList={this.props.getQOSPoliciesList}
            includeLink
          />
        </React.Fragment>,
      Apps:
        <React.Fragment>
          <AppsCard
            id={this.props.id}
            getDevice={this.props.getDevice}
            device={this.props.device}
          />
        </React.Fragment>,
      Firewall:
        <DeviceFirewallCard
          id={this.props.id}
          device={this.props.device}
          getDevice={this.props.getDevice}
          interfaces={this.props.interfaces}
          appIdentifications={this.state.appIdentifications}
          apps={this.state.apps}
          categories={this.state.categories}
          serviceClasses={this.state.serviceClasses}
          changeRuleForm={this.props.changeRuleForm}
          firewallRule={this.props.firewallRule}
          firewallRules={this.props.firewallRules}
          updateUserInputState={this.updateUserInputState}
          saveDeviceFirewallRules={this.props.saveDeviceFirewallRules}
          deviceInfo={this.props.deviceInfo}
        />,
      Logs:
        <React.Fragment>
          <LogsCard
            id={this.props.id}
            device={this.props.device}
            getDevice={this.props.getDevice}
            getDeviceLogs={this.props.getDeviceLogs}
          />
        </React.Fragment>,
      PacketTraces:
      <React.Fragment>
        <PacketTracesCard
          id={this.props.id}
          device={this.props.device}
          getDevicePacketTraces={this.props.getDevicePacketTraces}
        />
      </React.Fragment>,
      RecoveryInfo:
        <React.Fragment>
          <RecoveryInfoCard
            id={this.props.id}
          />
        </React.Fragment>,
      EdgeSettings:
        <React.Fragment>
            <EdgeSettingsCard
              id={this.props.id}
              device={this.props.device}
              getDeviceConfig={this.props.getDeviceConfig}
              resetDevice={this.props.resetDevice}
            />
        </React.Fragment>,
      Command:
        <React.Fragment>
            <SendDevice
              id={this.props.id}
              sendDevice={this.props.sendDevice}
              changeGeneralForm = {this.props.changeGeneralForm}
            />
        </React.Fragment>,
      VRRP:
        <>
          <VrrpCard
            id={this.props.id}
            interfaces={this.props.interfaces}
          />
        </>
    }
  };

  render() {
    const toTTString = this.context.toTTString
    const dataChanged = this.checkIfDataChanged();
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/devices"><TT>Devices</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Device Info</TT></BreadcrumbItem>
        </Breadcrumb>
        <Prompt
          message={(location, action) => {
            const { pathname } = location;
            // Don't check for changes when moving between tabs
            if (!pathname.includes("/devices/deviceinfo")
                && dataChanged) {
                return toTTString('You have unsaved changes') + '. ' +
                  toTTString('Are you sure you want to leave this page?')
              }
          }}
        />
        <h4>
        <Link to="/devices">
          <Button
            color="info"
            className="back-btn"
          >
            <FontAwesomeIcon icon="arrow-circle-left" fixedWidth />
          </Button>
        </Link>
        <span>
        {`${
            this.props.device ?
              `${this.props.device.name || this.props.device.machineId}`
              :
              ``
          }`
        }
        {
          dataChanged && <>
            <ReactTooltip id="updated-tip">
              <span>
                <TT>Device configuration modified but not updated</TT>,
                <br/><TT>click "Update Device" to update</TT>
              </span>
            </ReactTooltip>
            <FontAwesomeIcon
              icon='asterisk'
              className="position-relative ml-2"
              style={{fontSize: '0.7rem', marginBottom: '0.7rem'}}
              data-tip
              data-for="updated-tip"
            />
          </>
        }
        </span>
        </h4>
        <Button
            disabled={this.shouldDisableUpdButton() || !dataChanged}
            className={"btn btn-primary action-btn-top"}
            onClick={() => this.updateDevice()}
          >
            <TT>Update Device</TT>
        </Button>
        {this.getDeviceBadges()}
        <div className="container">
          <Nav className="nav-tabs">
            <NavItem>
              <Link to={this.props.pathname + "?tab=General"}
                className={
                  this.state.currentTab === "General" ? "active nav-link" : "nav-link"
                }
              >
                <TT>General</TT>
              </Link>
            </NavItem>

            <FwDropdownTab
              currentTab={this.state.currentTab}
              pathname={this.props.pathname}
              title={toTTString("Configuration")}
              tabs={[
                "Interfaces",
                "Firewall",
                "StaticRoutes",
                "OSPF",
                "BGP",
                "RoutingFilters",
                "RoutingFilter",
                this.isSupportAdvancedRouting() ? "AdvancedRouting" : ''
              ]}
              hide={new Set(["RoutingFilter"])}
            />

            <FwDropdownTab
              currentTab={this.state.currentTab}
              pathname={this.props.pathname}
              title={toTTString("Status")}
              tabs={[
                "Apps",
                this.isSupportBgpStatus() ? "BGPNeighbors" : '',
                "DHCP",
                "Policies",
                "RoutingTable",
                "Statistics",
                "VRRP"
              ]}
            />

            <FwDropdownTab
              currentTab={this.state.currentTab}
              pathname={this.props.pathname}
              title={toTTString("Troubleshooting")}
              tabs={["Command", "EdgeSettings", "Logs", "PacketTraces", "RecoveryInfo"]}
            />

          </Nav>
        </div>
        {this.getTabsMap()[this.state.currentTab]}

        <OverlappingConfirmationModal onConfirmed={devicesIds => {
          this.updateDevice(true)
        }}/>
      </React.Fragment>
    );
  }
}

const FwDropdownTab = props => {
  const [isOpen, setIsOpen] = useState(false);

  let items = (props.tabs ?? []).filter(i => {
    if (!i) return false;
    if (props.hide && props.hide.has(i)) return false;
    return true;
  });

  return (
    <Dropdown
      nav
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="nav-link fw-drop-down-device-info"
    >
      <DropdownToggle
        nav
        caret
        className={props.tabs.includes(props.currentTab) ? "active nav-link" : "nav-link"}
      >
        <TT>{props.title}</TT>
      </DropdownToggle>

      <DropdownMenu>
        { items.map((tab, idx) => {
          if (tab === '|') {
            return (
              <DropdownItem divider/>
            )
          } else if (tab.startsWith('<')) {
            return (
              <DropdownItem header>{tab.slice(1, tab.length -1)}</DropdownItem>
            )
          } else if (tab === '') {
            return null;
          }

          let title = tab;
          if (tabsTitles.hasOwnProperty(title)) {
            title = tabsTitles[title];
          } else if (title !== title.toUpperCase()) {
            title = tab.split(/(?=[A-Z])/).join(' ');
          }
          return (
            <DropdownItem key={idx}>
              <Link to={props.pathname + `?tab=${tab}`}
                className={
                  props.currentTab === tab ? "active nav-link" : "nav-link"
                }
              >
                <TT>{title}</TT>
              </Link>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DeviceInfo;
