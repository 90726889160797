import { logoutUser, updateJWT } from '../redux/reducers/Auth';
import { mainTopbarLoading } from '../redux/reducers/Main';
import { alertMsgMessage } from '../redux/reducers/AlertMsg';
import { getStorageRefreshToken, getStorageToken, saveStorageRefreshToken } from './Token';
/*
 * Fetch common code
 * Config include:
 *  url: The request url
 *  method: 'POST', 'GET', etc
 *  headers: Object with headers, default headers will be used as 
 *          'Content-Type':'application/json', 
 *          'Authorization': 'Bearer ' + localStorage.getItem('token')
 *  body: the message body if exists
 *  requestCB: called upon request
 *  successCB: called upon successful response, called with the response body
 *  failureCB: called upon failed response, called with the error
 *  forbiddenCB: called upon forbidden response
 *
 */
export const FWFetch = (config) => (dispatch, getState) => {

  if (!config.disableTopBar === true) {
    dispatch(mainTopbarLoading(true));
  }

  config.requestCB();

  let { url, method, body, headers = {} } = config;

    headers['Content-Type'] = 'application/json';

    const jwtToken = getStorageToken();
    if (jwtToken) {
        headers['Authorization'] = 'Bearer ' + jwtToken;
    } else if (config.allowLoginToken) {
        headers['Authorization'] = 'Bearer ' + getState().auth.loginToken;
    }

    // If the access token is about to expire, attach the refresh token
    // to the request in order to get a new access token in the response.
    // We give a grace period of 10 seconds to prevent cases where the
    // token expires when it is waiting to be processed by the server.
    const now = (new Date()).getTime();
    const expiration = getState().auth.expiration * 1000;
    if (expiration < now + 10000) {
        headers['refresh-token'] = getStorageRefreshToken();
    }

    return fetch(url, {
        method,
        headers,
        body
    })
    .then(response => {
        if (response.status === 204) // No-Content
            return response;
        return response.json()
        .then(json => {
            response.message = json;
            return response;
        },
        error => {
            throw(new Error('Bad Response, Please try again'));
        });
    },
    error => {
        throw error;
    })
    .then(response => {
        if (response.ok) {
            //console.log("*** response=" + JSON.stringify(response.message));
            dispatch(mainTopbarLoading(false));
            const newJWT = response.headers.get('Refresh-JWT');
            if (newJWT!=="") dispatch(updateJWT(newJWT));
            const refreshToken = response.headers.get('refresh-token');
            if (refreshToken) saveStorageRefreshToken(refreshToken);
            config.successCB(response.message, response.status, response.headers);
        }
        else {
            console.log("*** response.status=" + response.status);
            // User no longer authenticated (token has expired).
            if (response.status === 401) dispatch(logoutUser());
            let errMsg = '';
            if (response.message.error && config.doNotShowError !== true) {
                errMsg += response.message.error;
            } else {
                errMsg += response.message.error;
            }
            throw(new ErrorWithData(errMsg, response?.message?.data ?? {}));
        }
    })
    .catch((error) => {
        dispatch(mainTopbarLoading(false));
        if (error.message === 'Failed to fetch') error.message = 'Connection failure';
        config.failureCB(error);
        if (!config.doNotShowError) {
            dispatch(alertMsgMessage({'color':'danger','text':error.message}));
        }
    });
};

class ErrorWithData extends Error {
    constructor(message, data) {
        super(message);
        this.data = data;
    }
}

