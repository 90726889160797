import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { TT, LanguageContext } from '../../containers/Language'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip';
import { getMajorVersion } from "../../utils/DevicesUtils"

class PoliciesCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      policies: []
    };
    this.policyColumns = [
      { text: <TT>rowID</TT>, dataField: "_id", hidden: true },
      {
        text: <TT>Name</TT>,
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          if (!this.props.includeLink) return row.name
          let path
          switch(row.type){
            case 'Firewall Policy':
               path = '/firewallpolicies/'
               break;
            case 'Path Selection Policy':
               path = '/pathselectionpolicies/'
               break;
            case 'QoS Policy':
               path = '/qosPolicies/'
               break;
            default:
              path = ''
          }
          return (
            path === ''? row.name :
            <Link to={`${path + row._id}`}>
            <span style={{color: 'steelblue'}}>{row.name}</span>
            </Link>
        )}
      },
      {
        text: <TT>Type</TT>,
        dataField: "type",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        }
      },
      {
        text: <TT>Description</TT>,
        dataField: "description",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "50%", textAlign: "left" };
        }
      },
      {
        text: <TT>Status</TT>,
        dataField: "status",
        sort: true,
        editable: false,
        formatter: (status, row) => {
          const { versions } = this.props.device;
          const agentMajorVersion = getMajorVersion(versions.agent);
          return ['job queue failed', 'job deleted'].includes(status)
            ? `Policy not installed, ${status}`
            : row.type === 'QoS Policy' && agentMajorVersion < 6
            ? <>
              Unable to install
              <FontAwesomeIcon
                className="ml-2"
                icon="exclamation-triangle"
                size="1x"
                fixedWidth
                data-tip
                data-for={"status-warning" + row._id}
                color="#ebbc02"
              />
              <ReactTooltip id={"status-warning" + row._id}>
                <span>
                  <TT>QoS requires device version 6.1.X or grater</TT>.
                </span>
              </ReactTooltip>
            </>
            : status
        },
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        }
      },
    ]
  }

  convertPolicyType(type) {
    const toTTString = this.context.toTTString
    switch (type) {
      case "multilink":
        return toTTString("Path Selection Policy")
      case "firewall":
        return toTTString("Firewall Policy")
      case "qos":
        return toTTString("QoS Policy")
      default:
        return ""
    }
  }

  componentDidMount(){
    const { id, getDevice, getQOSPoliciesList } = this.props;
    let devQosPolicy;
    const policyList = [];
    getDevice(id, resp => {
      getQOSPoliciesList(qosResp => {
        const qosPolicies = {};
        for (const policy of qosResp) {
          qosPolicies[policy._id] = policy.name;
        };
        this.setState(() => {
          const { policies, interfaces } = resp;
          Object.entries(policies).forEach(([type, policyData]) => {
            if (policyData) {
              const { policy, status } = policyData;
              const { _id, name, description } = policy || {};
              if (type === 'qos') {
                devQosPolicy = { _id, name, description, status };
              }
              if (policy) {
                policyList.push({
                  _id: _id,
                  name: name,
                  description: description,
                  type: this.convertPolicyType(type),
                  status: status
                });
              }
            }
          });
          for (const { name, isAssigned, type, qosPolicy } of interfaces) {
            // check if there is any interface specific QoS policy
            if (isAssigned && type === 'WAN' && qosPolicy) {
              if (!devQosPolicy || qosPolicy !== devQosPolicy._id) {
                policyList.push({
                  _id: qosPolicy,
                  name: qosPolicies[qosPolicy],
                  description: `Interface specific: [${name}]`,
                  type: this.convertPolicyType('qos'),
                  status: devQosPolicy?.status || 'unknown'
                });
              }
            }
          }
          return { policies: policyList }
        });
      });
    });
  }

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <h5><TT>Policies</TT></h5>
        <BootstrapTable
          striped
          hover
          condensed
          keyField="_id"
          data={this.state.policies}
          columns={this.policyColumns}
          noDataIndication={toTTString("No policies available")}
          defaultSorted={[{ dataField: "name", order: "asc" }]}
      />
      </React.Fragment>
    );
  }
}

export default PoliciesCard;