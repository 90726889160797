import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import FWMap from './FWMap';
import { TT } from '../../containers/Language';

class NetworkMap extends Component {

  constructor(props) {
    super(props);

    this.readTimer = null;

    this.updateDevicesTunnels = this.updateDevicesTunnels.bind(this);
  }

  componentDidMount() {
    this.updateDevicesTunnels();
    if (this.readTimer === null) {
      const timer = setInterval(function(that) {
          // Only update when in focus
          if (document.hasFocus()) {
            that.updateDevicesTunnels();
          }
      },60000, this);
      this.readTimer = timer;
    }
    window.addEventListener('focus', this.updateDevicesTunnels);
  }

  componentWillUnmount() {
    this.props.clearDevices();
    this.props.clearTunnels();
    if (this.readTimer != null) {
      clearInterval(this.readTimer);
      this.readTimer = null;
    }
    window.removeEventListener("focus", this.updateDevicesTunnels);
  }

  updateDevicesTunnels() {
    this.props.getAllDevices({ response: 'summary' });
    this.props.getAllTunnels({ response: 'summary' });
  }

  // TBD: Add a button to change location of devices. During that time remove tunnels until update is clicked
  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Dashboard</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>World Map</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>World Map</TT></h4>
        <FWMap
          devices={this.props.devices.devices}
          tunnels={this.props.tunnels.tunnels}
          minHeight='calc(100% - 70px)'
          updCoords={this.props.updCoords}
          checkBounds={false}
        />
      </React.Fragment>
    );
  }
}

export default NetworkMap;