import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Label, Card, CardBody, Button } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { required, isInteger, minValue, isIP4, maxValue } from '../../utils/Validators';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import './DeviceInfo.css';
import OSPFLinkModal from './OSPFLinkModal';
import isEqual from 'lodash/isEqual';
import { TT, LanguageContext } from '../../containers/Language';
import { ToastContainer, toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { saveDeviceInterfaces } from '../../redux/reducers/Devices';
import RoutingCommandsCli from './RoutingCommandsCli';

class OSPFCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props)
    this.state = {
      selectedInterface: null,
      interfaces: []
    }
  }

  getOspfInterfaces = () => {
    return this.state.interfaces.filter(i => i.isAssigned === 'Yes' && (i.routing === 'OSPF' || i.routing === 'OSPF,BGP'))
  }

  initiateOspfForm = () => {
    this.props.changeForm(this.props.ospf, {silent: true})
    this.setState({ interfaces: this.props.interfaces })
  }

  componentDidMount() {
    if (Object.keys(this.props.ospf).length) {
      this.initiateOspfForm()
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.ospf, this.props.ospf)) {
      this.initiateOspfForm()
    }
  }

  handleResetChanges = () => {
    this.initiateOspfForm()
  }

  handleOSPFSettingsChange = (result, _id, cb) => {
    const ifcIdx = this.state.interfaces.findIndex(ifcEntry => ifcEntry._id === _id)
    const interfaces = cloneDeep(this.state.interfaces);
    interfaces[ifcIdx].ospf = result.ospf
    this.setState({ interfaces }, () => cb ? cb() : null)
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  handleSubmit = values => {
    this.props.saveOSPF({ ...values })
    this.props.saveDeviceInterfaces(this.state.interfaces);

    toast("Saved!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  OSPFInterfacesColumn = [
    {
      text: <TT>Interface</TT>,
      dataField: "name",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      }
    },
    {
      text: <TT>Network</TT>,
      dataField: "IPv4",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      }
    },
    {
      text: <TT>Area</TT>,
      dataField: "ospf.area",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", textAlign: "left" };
      }
    },
    {
      text: <TT>Authentication key ID</TT>,
      dataField: "ospf.keyId",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "left" };
      }
    },
    {
      text: <TT>Authentication key</TT>,
      dataField: "ospf.key",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "left" };
      }
    },
    {
      text: <TT>Cost</TT>,
      dataField: "ospf.cost",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", textAlign: "left" };
      }
    },
    {
      text: <TT>Actions</TT>, dataField: 'none', sort: false,
      headerStyle: (colum, colIndex) => {
        return { textAlign: 'left' };
      },
      formatter: (cellContent, row) => {
        return (<div>
             <Button
                color="warning"
                className="action-btn"
                data-tip
                data-for={"setup-ospf-if-" + row.name}
                size="sm"
                onClick={e => {
                  this.props.changUpdateInterfaceForm(row);
                  this.setState({selectedInterface: row})
                }
              }>
                <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                <ReactTooltip id={"setup-ospf-if-"+row.name}>
                  <span><TT>Link OSPF Configuration</TT></span>
                </ReactTooltip>
              </Button>
        </div>);
      }
    }
  ];

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <h6><TT>OSPF Configuration</TT></h6>
        <div className={"general-card-upper-panel d-flex justify-content-between"}>
          <Control.button
            form="OSPFConfigurationForm"
            type="submit"
            model="OSPFConfiguration"
            disabled={(fieldValue) => !fieldValue.valid || (isEqual(this.props.ospf, this.props.form) && isEqual(this.state.interfaces, this.props.interfaces))}
            className={"btn btn-primary action-btn-top upper-panel-button"}
          >
            <TT>Save</TT>
          </Control.button>

          { isEqual(this.props.ospf, this.props.form) && isEqual(this.state.interfaces, this.props.interfaces) ? null : (
            <button
              onClick={this.handleResetChanges}
              type="button"
              className="btn btn-outline-secondary action-btn-top upper-panel-button"
            >
              <TT>Reset Changes</TT>
            </button>
          )}
        </div>

        <Form
          id="OSPFConfigurationForm"
          model="OSPFConfiguration"
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col md={12}>
              <Card id='OSPFCard'>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <Row className="form-group">
                        <Label htmlFor="routerId" md={5}>
                          <TT>Router ID</TT>
                        </Label>
                        <Col md={5}>
                          <Control.text
                            model=".routerId"
                            id="routerId"
                            name="routerId"
                            placeholder=""
                            className="form-control"
                            validators={{
                              isIP4: val => !val || val === "" || isIP4(val)
                            }}
                          >
                          </Control.text>
                          <Errors
                            className="text-danger"
                            model=".routerId"
                            show="touched"
                            messages={{
                              isIP4: toTTString('Router Id should be a valid IPv4')
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="form-group">
                        <Label htmlFor="enable" md={5}>
                          <TT>Redistribute BGP Routes</TT>
                        </Label>
                        <Col md={5}>
                          <Label className="FWswitch">
                            <Control.checkbox
                              model=".redistributeBgp"
                              id="redistributeBgp"
                              name="redistributeBgp"
                            />{" "}
                            <span className="FWslider round" />
                          </Label>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={4}>
                      <Row className="form-group">
                        <Label htmlFor="helloInterval" md={5}>
                          <TT>Hello Interval (seconds)</TT>
                        </Label>
                        <Col md={4}>
                          <Control.text
                            type="text"
                            model=".helloInterval"
                            id="helloInterval"
                            name="helloInterval"
                            component={FWTextInput}
                            withFieldValue
                            validators={{
                              required: val => required(val.toString()),
                              isInteger: val => isInteger(val, false),
                              minValue: minValue(1),
                              maxValue: maxValue(65535)
                            }}
                          />
                          <Errors
                            className="text-danger"
                            model=".helloInterval"
                            show="touched"
                            messages={{
                              required: toTTString('Required field'),
                              isInteger: toTTString('Invalid number'),
                              minValue: toTTString("Interval must be greater than or equal to 1"),
                              maxValue: toTTString('Interval must be less than or equal to 65535')
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="form-group">
                        <Label htmlFor="deadInterval" md={5}>
                          <TT>Dead Interval (seconds)</TT>
                        </Label>
                        <Col md={4}>
                          <Control.text
                            type="text"
                            model=".deadInterval"
                            id="deadInterval"
                            name="deadInterval"
                            component={FWTextInput}
                            withFieldValue
                            validators={{
                              required: val => required(val.toString()),
                              isInteger: val => isInteger(val, false),
                              minValue: minValue(1),
                              maxValue: maxValue(65535)
                            }}
                          />
                          <Errors
                            className="text-danger"
                            model=".deadInterval"
                            show="touched"
                            messages={{
                              required: toTTString('Required field'),
                              isInteger: toTTString('Invalid number'),
                              minValue: toTTString("Interval must be greater than or equal to 1"),
                              maxValue: toTTString('Interval must be less than or equal to 65535')
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col md={4}>
                      <RoutingCommandsCli
                        form="OSPFConfiguration"
                        model="custom"
                        buttonRight={true}
                        executedText='under "router ospf"'
                        advancedText="OSPF"
                      />
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>

        <Row>
          <Label md={12}>
            <TT>Networks</TT>
          </Label>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              keyField="devId"
              data={this.getOspfInterfaces()}
              columns={this.OSPFInterfacesColumn}
              noDataIndication={toTTString("No OSPF networks available")}
              defaultSorted={[{ dataField: "name", order: "asc" }]}
            />
          </Col>
        </Row>

        <OSPFLinkModal
          isOpen={this.state.selectedInterface !== null}
          interface={this.state.selectedInterface}
          osSubmit={result => {
            this.handleOSPFSettingsChange(result, this.state.selectedInterface._id, () => {
              // If the interface is inside a bridge, we copy the configuration to all interfaces in this bridge
              const bridgeIfcs = this.props.interfaces.filter(
                i => i.isAssigned === 'Yes' && i._id !== this.state.selectedInterface._id && i.IPv4 === this.state.selectedInterface.IPv4);

              bridgeIfcs.forEach(ifc => {
                this.handleOSPFSettingsChange(result, ifc._id)
              })

              this.setState({selectedInterface:  null})
            })

          }}
          onClose={() => {
            this.setState({selectedInterface:  null})
          }}
      />
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.OSPFConfiguration
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveDeviceInterfaces: interfaces => dispatch(saveDeviceInterfaces(interfaces))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OSPFCard);
