import React, { Component } from "react";
import {
  required,
  maxLength,
  minLength,
  validateDeviceName,
  validateDevDescription
} from "../../utils/Validators";
import {
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Alert
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { FWTextInput } from "../Common";
import FWMap from '../dashboards/FWMap';
import './DeviceInfo.css';
import { TT, LanguageContext  } from '../../containers/Language'
import { store } from "../../App";
import { getMajorVersion } from "../../utils/DevicesUtils"
import GenerateIkeButton from "./GenerateIkeButton";
import HardwareConfigurationButton from "./HardwareConfigurationButton";
class DeviceGeneralCard extends Component {
  static contextType = LanguageContext
  render() {
    const toTTString = this.context.toTTString
    const { cpuInfo, distro } = store.getState().deviceInfo;

    return (
      <div className="col-md-12 general-device-card">
        {
        this.props.getSyncStatus().state === "unknown" ?
        <Alert color="warning" >
          <TT>Device is in unknown sync state. Make sure to sync your configuration for a proper device operation</TT>
        </Alert>
        : null
        }
        <Card id="deviceInfoCard" className={"device-inner-card"}>
          <CardBody>
            <Form
              id="General"
              model="deviceInfo"
              onUpdate={(formValue) => {
                this.props.updateUserInputState(
                  "general",
                  formValue.$form.valid);
              }}
            >
              <Row className="form-group">
                <Label htmlFor="name" md={3}>
                  <TT>Device Name</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".name"
                    id="name"
                    name="name"
                    placeholder={toTTString("Device Name")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      maxLength: maxLength(50),
                      minLength: minLength(1),
                      name: validateDeviceName
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".name"
                    show="touched"
                    messages={{
                      minLength: toTTString("Device name must be set"),
                      maxLength: toTTString("Length must be at most 50"),
                      name: toTTString("Invalid device name format")
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="description" md={3}>
                  <TT>Description</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".description"
                    id="description"
                    name="description"
                    placeholder={toTTString("Device Description")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      maxLength: maxLength(50),
                      description: validateDevDescription
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".description"
                    show="touched"
                    messages={{
                      maxLength: toTTString("Length must be at most 50"),
                      description: toTTString("Invalid description format")
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="isApproved" md={3}>
                  <TT>Approved</TT>
                </Label>
                <Col md={9}>
                  <Label className="FWswitch">
                    <Control.checkbox
                      model=".isApproved"
                      id="isApproved"
                      name="isApproved"
                    />{" "}
                    <span className="FWslider round" />
                  </Label>
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="hostname" md={3}>
                  <TT>Host Name</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".hostname"
                    id="hostname"
                    name="hostname"
                    placeholder={toTTString("Host Name")}
                    component={FWTextInput}
                    withFieldValue
                    disabled={true}
                    validators={{
                      required: required,
                      minLength: minLength(1),
                      maxLength: maxLength(255)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".hostname"
                    show="touched"
                    messages={{
                      required: toTTString("Required field"),
                      minLength: toTTString("Length must be at least 1"),
                      maxLength: toTTString("Length must be at most 255")
                    }}
                  />
                </Col>
              </Row>
              {cpuInfo && this.props.device && getMajorVersion(this.props.device.versions.agent) >= 6 && <>
              <Row className="form-group">
                <Label htmlFor="hardware" md={3}>
                  <TT>Hardware</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model="hardware"
                    id="hardware"
                    name="hardware"
                    value={`CPU cores: ${cpuInfo.hwCores}, vRouter cores: ${cpuInfo.vppCores}, Power Saving: ${cpuInfo.powerSaving ? 'On' : 'Off'}`}
                    component={FWTextInput}
                    withFieldValue
                    disabled={true}
                  />
                </Col>
              </Row>
              </>}
              <Row className="form-group">
                <Label htmlFor="serial" md={3}>
                  <TT>S/N</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".serial"
                    id="serial"
                    name="serial"
                    placeholder={toTTString("Serial Number")}
                    component={FWTextInput}
                    withFieldValue
                    disabled={true}
                    validators={{
                      maxLength: maxLength(250)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".serial"
                    show="touched"
                    messages={{
                      maxLength: toTTString("Length must be at most 200")
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="distro" md={3}>
                  <TT>Host OS</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".distro"
                    id="distro"
                    name="distro"
                    value={distro?.codename?`${distro?.codename || ''} (${distro?.version || 'N/A'})`:'N/A'}
                    component={FWTextInput}
                    withFieldValue
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="machineId" md={3}>
                  <TT>Machine ID</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".machineId"
                    id="machineId"
                    name="machineId"
                    placeholder={toTTString("Device ID")}
                    component={FWTextInput}
                    withFieldValue
                    disabled={true}
                    validators={{
                      required: required,
                      maxLength: maxLength(50)
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".machineId"
                    show="touched"
                    messages={{
                      required: toTTString("Required field"),
                      maxLength: toTTString("Length must be at most 50")
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Label htmlFor="deviceVersion" md={3}>
                  <TT>Device Version</TT>
                </Label>
                <Col md={9}>
                  <Control.text
                    model=".deviceVersion"
                    id="deviceVersion"
                    name="deviceVersion"
                    placeholder={toTTString("Update on connection")}
                    component={FWTextInput}
                    withFieldValue
                    disabled={true}
                  />
                </Col>
              </Row>
            </Form>
            <hr />
            <div className="d-flex">
              <div>
                <GenerateIkeButton deviceId={this.props.id} />
              </div>
              <div className="ml-2">
                <HardwareConfigurationButton deviceId={this.props.id} deviceVersion={this.props.device?.versions?.agent}/>
              </div>
            </div>
            <hr />
            <FWMap
              devices={this.props.device? [this.props.device] : []}
              tunnels={[]}
              updCoords={this.props.updCoords}
              checkBounds={true}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default DeviceGeneralCard;
