import React, { Component } from 'react';
import { Nav, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SideBar.css';
import { TT, LanguageContext } from '../containers/Language';

class SideBar extends Component {
  static contextType = LanguageContext
  render() {
    const toTTString = this.context.toTTString;
    const classesNames = [];
    if (this.props.sideBarCollapsed) {
      classesNames.push("slideOut");
    } else {
      classesNames.push("slideIn");
    }
    if (!this.props.isAuthenticated) {
      classesNames.push("hide");
    }

    return (
      <div id="sidebarCollapse" className={classesNames.join(" ")}>
        <Nav className="col d-block sidebar">
          <hr />
          <div className="orgTitle">
            <TT>Organization</TT>: <span className="orgSelection">{this.props.orgName || toTTString("Undefined")}</span>
          </div>
          <hr />
          <ul className="nav flex-column">
            <NavLink to="/home">
              <FontAwesomeIcon icon="home" style={{ height: "1.15em" }} fixedWidth />
              <TT>Home</TT>
          </NavLink>
            <Dropdown
              setActiveFromChild
              inNavbar
              isOpen={this.props.sidebar.dropdownAccounts}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarAccountsDropdownToggle}>
                <FontAwesomeIcon icon={['fas', 'network-wired']} style={{ height: "1.15em" }} fixedWidth />
                <TT>Account</TT>
            </DropdownToggle>
            <DropdownMenu>
              <NavLink exact to="/accounts/update">
                <TT>Profile</TT>
              </NavLink>
              <NavLink exact to="/organizations">
                <TT>Organizations</TT>
              </NavLink>
              <NavLink exact to="/notifications-config">
                <TT>Notifications Settings</TT>
              </NavLink>
              <NavLink exact to="/billing">
                <TT>Billing</TT>
              </NavLink>
              <NavLink exact to="/accesstokens">
                  <TT>Access Keys</TT>
              </NavLink>
            </DropdownMenu>
          </Dropdown>
          <NavLink to="/members">
              <FontAwesomeIcon icon="user" fixedWidth />
              <TT>Users</TT>
            </NavLink>
            <Dropdown
              setActiveFromChild
              inNavbar
              isOpen={this.props.sidebar.dropdownConfig}
              toggle={() => {}}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarConfigDropdownToggle}>
                <FontAwesomeIcon icon={['far', 'list-alt']} style={{height:"1.15em"}} fixedWidth />
                <TT>Inventory</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink exact to="/devices">
                  <TT>Devices</TT>
                </NavLink>
                <NavLink exact to="/tunnels">
                  <TT>Tunnels</TT>
                </NavLink>
                <NavLink exact to="/peers">
                  <TT>Peers</TT>
                </NavLink>
                <NavLink exact to="/tokens">
                  <TT>Tokens</TT>
                </NavLink>
                <NavLink exact to="/pathlabels">
                  <TT>Path Labels</TT>
                </NavLink>
                <NavLink exact to="/appidentifications">
                  <TT>Traffic & App Identifications</TT>
                </NavLink>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              inNavbar
              isOpen={this.props.sidebar.dropdownTrafficOptimization}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarTrafficOptDropdownToggle}>
                <FontAwesomeIcon icon="arrows-alt" fixedWidth />
                <TT>Traffic Optimization</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink to="/pathselectionpolicies"><TT>Path Selection</TT></NavLink>
              </DropdownMenu>
              <DropdownMenu>
                <NavLink to="/qosPolicies"><TT>QoS</TT></NavLink>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              inNavbar
              isOpen={this.props.sidebar.dropdownSecurity}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarSecurityDropdownToggle}>
                <FontAwesomeIcon icon="shield-alt" fixedWidth />
                <TT>Security</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink to="/firewallpolicies"><TT>Firewall</TT></NavLink>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              inNavbar
              isOpen={this.props.sidebar.highAvailability}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarHighAvailabilityDropdownToggle}>
                <FontAwesomeIcon icon="bolt" fixedWidth />
                <TT>High-Availability</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink to="/vrrp"><TT>VRRP</TT></NavLink>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              inNavbar
              isOpen={this.props.sidebar.dropdownAppStore}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarApplicationsDropdownToggle}>
                <FontAwesomeIcon icon="puzzle-piece" fixedWidth />
                <TT>App Store</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink exact to="/applications/installed"><TT>Installed</TT></NavLink>
                <NavLink exact to="/applications/available"><TT>Available</TT></NavLink>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              inNavbar
              isOpen={this.props.sidebar.dropdownDashboard}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarDashboardDropdownToggle}>
                <FontAwesomeIcon icon="signal" fixedWidth />
                <TT>Dashboards</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink to="/network"><TT>Network</TT></NavLink>
                <NavLink to="/networkmap"><TT>World Map</TT></NavLink>
                <NavLink to="/traffic"><TT>Traffic</TT></NavLink>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              inNavbar
              isOpen={this.props.sidebar.dropdownTroubleshoot}
              toggle={() => { }}
            >
              <DropdownToggle nav caret onClick={this.props.sidebarTroubleshootDropdownToggle}>
                <FontAwesomeIcon icon="glasses" fixedWidth />
                <TT>Troubleshoot</TT>
              </DropdownToggle>
              <DropdownMenu>
                <NavLink to="/jobs"><TT>Jobs</TT></NavLink>
              </DropdownMenu>
              <DropdownMenu>
                <NavLink to="/notifications"><TT>Notifications</TT></NavLink>
              </DropdownMenu>
            </Dropdown>
            <NavLink to="/about">
              <FontAwesomeIcon icon="info" fixedWidth />
              <TT>About</TT>
            </NavLink>
          </ul>
        </Nav>
      </div>
    );
  }
}

export default SideBar;
