import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row, Col
} from "reactstrap";
import ReactTooltip from 'react-tooltip';
import ReactJson from 'react-json-view';
import InfoNotAvailable from "../items/infoNotAvailable";
import { TT, LanguageContext  } from '../../containers/Language'

class EdgeSettingsCard extends Component {
  static contextType = LanguageContext

  constructor(props) {
    super(props);
    this.state = {
      confResponse: null,
      isResetConfigModalOpen: false,
      infoNotAvailable: false
    };

    this.handleResetConfig = this.handleResetConfig.bind(this);
    this.toggleResetConfigModal = this.toggleResetConfigModal.bind(this);
    this.approveResetConfig = this.approveResetConfig.bind(this);
  }

  toggleResetConfigModal() {
    this.setState({
      isResetConfigModalOpen: !this.state.isResetConfigModalOpen
    });
  }

  handleFetchSettings = () => {
    this.props.getDeviceConfig(this.props.id, resp => {
      if (resp.error === 'timeout') {
        this.setState({ infoNotAvailable: true, confResponse: null });
      } else {
        this.setState({ confResponse: resp, infoNotAvailable: false });
      }
    });
  }

  handleResetConfig() {
    this.toggleResetConfigModal();
  }

  approveResetConfig() {
    this.toggleResetConfigModal();
    this.props.resetDevice(this.props.id);
  }

  render() {
    const toTTString = this.context.toTTString

    return (
      <div>
        <React.Fragment>

          <InfoNotAvailable show={this.state.infoNotAvailable} />

          <div className="log-card-upper-panel">
            <Row className="form-group">
              <Col>
                <div className="d-flex justify-content-between">
                  <div>
                  <Button
                      color="primary"
                      onClick={() => this.handleFetchSettings()}
                    >
                      <TT>Fetch Settings</TT>
                    </Button>
                  </div>
                  <div>
                    <ReactTooltip id="reset-a">
                      <span><TT>Reset Device Configuration</TT></span>
                    </ReactTooltip>
                    <Button
                      color="danger"
                      onClick={() => this.handleResetConfig()}
                      data-tip
                      data-for="reset-a"
                    >
                      <TT>Reset </TT>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Modal
            isOpen={this.state.isResetConfigModalOpen}
            toggle={this.toggleResetConfigModal}
          >
            <ModalHeader toggle={this.toggleResetConfigModal}>
              <TT>Reset Device</TT>
            </ModalHeader>
            <ModalBody>
              <div className="mb-3">
                <TT>Are you sure you want to reset the device configuration?</TT>
              </div>
              <Button color="danger" onClick={this.approveResetConfig}>
                <TT>Yes</TT>
              </Button>
              <Button
                className="float-right"
                color="outline-secondary"
                onClick={this.toggleResetConfigModal}
              >
                <TT>Cancel</TT>
              </Button>
            </ModalBody>
          </Modal>
        </React.Fragment>

        { !this.state?.confResponse?.deviceStatus
          ? null
          : this.state?.confResponse?.deviceStatus === "disconnected"
          ? toTTString("Device must be connected to view the edge settings")
          : (
            <ReactJson
              src={this.state.confResponse.configuration}
              name={"Configurations"}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={2}
            />
          )
        }
      </div>
    );
  }
}

export default EdgeSettingsCard;
