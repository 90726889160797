import React, { Component } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Control, Form, Errors, actions } from 'react-redux-form';
import { Link } from 'react-router-dom';
import {
  required,
  maxLength,
  minLength,
  validateAppIdentName,
  IPv4FieldValidator,
  isValidPrefixLength,
  isPortValid,
  validateAppIdentCategory,
  validateAppIdentServiceClass,
} from '../../utils/Validators';
import { FWTextInput } from '../Common';
import SearchableSelectBox from '../items/SearchableSelectBox';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isEqual, uniq } from 'underscore';
import { TT, LanguageContext  } from '../../containers/Language'
const predefinedServiceClasses = [
  'default',
  'real-time',
  'signaling',
  'telephony',
  'broadcast-video',
  'network-control',
  'oam',
  'high-throughput',
  'multimedia-conferencing',
  'multimedia-streaming',
  'low-latency'
];
class AddAppIdentification extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.resetForm();
    if (this.option === 'customize' || this.option === 'update') {
      this.props.getAppIdentification(this.props.id, (response) => {
        this.props.changeForm(response);
        this.props.addAppIdentificationRule(response.rules);
        this.originalAppIdentification = response;
      });
    } else {
      this.props.getAllAppIdentifications();
    }
  }

  constructor(props) {
    super(props);

    this.customPrefix = 'custom:';
    this.option = this.props.option;
    this.originalAppIdentification = {};

    this.state = {
      isRuleAdditionModalOpen: false,
      isActionsDropDownOpen: false,
      inputValue: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleRuleAddition = this.toggleRuleAddition.bind(this);
    this.approveRuleAddition = this.approveRuleAddition.bind(this);
    this.handleRuleDeletion = this.handleRuleDeletion.bind(this);
    this.toggleActionsDropDown = this.toggleActionsDropDown.bind(this);

    this.RuleColumnAdd = [
      {
        text: <TT>IP</TT>,
        dataField: 'ip',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '40%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Ports</TT>,
        dataField: 'ports',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '25%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Protocol</TT>,
        dataField: 'protocol',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Actions</TT>,
        dataField: 'none',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
        formatter: (_cellContent, row) => {
          return (
            <div>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="delete-a"
                size="sm"
                onClick={() => this.handleRuleDeletion(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id="delete-a">
                <span><TT>Delete Entry</TT></span>
              </ReactTooltip>
            </div>
          );
        },
      },
    ];

    this.RuleColumnCustomize = [
      {
        text: <TT>IP</TT>,
        dataField: 'ip',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '40%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Ports</TT>,
        dataField: 'ports',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '30%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Protocol</TT>,
        dataField: 'protocol',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '30%', textAlign: 'left' };
        },
      },
    ];

    this.RuleColumn =
      this.option === 'customize'
        ? this.RuleColumnCustomize
        : this.RuleColumnAdd;

    this.handleRuleDeletion();
  }

  toggleActionsDropDown() {
    this.setState({
      isActionsDropDownOpen: !this.state.isActionsDropDownOpen,
    });
  }

  handleSubmit(values) {
    // add rules
    const newRules = this.props.newRules.map((item) => {
      return {
        ip: item.ip === '' ? undefined : item.ip,
        ports: item.ports === '' ? undefined : item.ports,
        protocol: item.protocol === '' ? undefined : item.protocol,
      };
    });

    let newAppIdentification = { ...values, rules: newRules };
    let { name, category } = values;
    // check whether name needs to be marked as customized. For customize option,
    // name remains unchanged anyway.
    if (this.option !== 'customize' && !name.startsWith(this.customPrefix)) {
      newAppIdentification = {
        ...newAppIdentification,
        name: `${this.customPrefix}${name}`,
      };
    }
    // check whether category needs to be marked as customized
    if (
      !this.categories.find((item) => item.value === category) &&
      !category.startsWith(this.customPrefix)
    ) {
      newAppIdentification = {
        ...newAppIdentification,
        category: `${this.customPrefix}${category}`,
      };
    }
    this.props.addAppIdentification(newAppIdentification, () => {
      this.props.resetForm();
      this.handleRuleDeletion();
    });
  }

  approveRuleAddition(values) {
    this.props.addAppIdentificationRule([values]);
    this.props.resetRulesForm();
    this.toggleRuleAddition();
  }

  toggleRuleAddition() {
    this.setState({
      isRuleAdditionModalOpen: !this.state.isRuleAdditionModalOpen,
    });
  }

  handleRuleDeletion(row = null) {
    this.props.deleteAppIdentificationRule([row]);
  }

  /**
   * Checks whether similar rule already exists
   *
   * @param {*} vals current form values
   * @returns true if rule does not exist, otherwise false
   * @memberof AddAppIdentification
   */
  validateDuplicateRule(vals) {
    return !this.props.newRules.some(
      (rule) =>
        rule.ip === vals.ip &&
        rule.ports === vals.ports &&
        rule.protocol === vals.protocol
    );
  }

  /**
   * Checks whether form values are different from the
   * original ones.
   *
   * @param {*} vals current form values
   * @returns true if any of the allowed fields is modified,
   * otherwise false
   * @memberof AddAppIdentification
   */
  validateOriginalFormValues(vals) {
    if (
      isEmpty(this.originalAppIdentification) ||
      isEqual(this.originalAppIdentification, vals)
    ) {
      return false;
    }
    return true;
  }

  /**
   * Checks for valid combination of rules.
   * The rule can have:
   * a) Only IP/Mask
   * b) Only port
   * c) Any other combination of two or three fields
   *
   * @param {*} vals current form values
   * @returns true if rule satisfies either one of the conditions a, b or c, otherwise false
   * @memberof AddAppIdentification
   */
  validateRulesCombination(vals) {
    if (vals.ip !== '' || vals.ports !== '') {
      return true;
    }

    return false;
  }

  handleChangeSelectAction(model, value) {
    return (dispatch) => dispatch(actions.change(model, value?.value || value));
  }

  render() {
    const toTTString = this.context.toTTString
    // build set of categories
    this.categories = uniq(
      this.props.appIdentifications,
      (appIdentification) => appIdentification.category
    ).map((uniqueAppIdentification) => ({
      label: uniqueAppIdentification.category,
      value: uniqueAppIdentification.category,
    }));

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/appidentifications"><TT>App Identifications</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{
            this.option === 'customize' || this.option === 'update'
              ? <TT>Update App Identification</TT>
              : <TT>Add App Identification</TT>
          }</BreadcrumbItem>
        </Breadcrumb>
        <h4>{
          this.option === 'customize' || this.option === 'update'
            ? <TT>Update App Identification</TT>
            : <TT>Add App Identification</TT>
        }</h4>
        <div className="col-lg-8 col-md-12">
          <Form
            model="addAppIdentification"
            onSubmit={(values) => this.handleSubmit(values)}
            validators={
              this.option === 'customize'
                ? {
                    '': {
                      original: (vals) => this.validateOriginalFormValues(vals),
                    },
                  }
                : {}
            }
          >
            <Control.button
              model="addAppIdentification"
              disabled={this.props.newRules.length === 0 || { valid: false }}
              className="btn btn-primary action-btn-top upper-panel-button"
            >
              {this.option === 'customize' || this.option === 'update'
                ? <TT>Update</TT>
                : <TT>Add</TT>}
            </Control.button>
            <Card id="addAppIdentificationCard">
              <CardBody>
                <Row className="form-group">
                  <Label htmlFor="name" lg={5} md={6}>
                    <span className="form-required-star">*</span>
                    <TT>App Identification Name</TT>
                    <span
                      className="helpTooltip"
                      data-tip
                      data-for="appNameTip"
                    ></span>
                    <ReactTooltip id="appNameTip">
                      <span style={{ fontSize: '0.8rem' }}>
                        <TT>The name of the app identification</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.text
                      model=".name"
                      id="name"
                      name="name"
                      placeholder={toTTString("App Identification Name")}
                      component={FWTextInput}
                      withFieldValue
                      disabled={this.option === 'customize'}
                      validators={{
                        required: required,
                        minLength: minLength(2),
                        maxLength: maxLength(30),
                        name: validateAppIdentName,
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".name"
                      show="touched"
                      messages={{
                        required: toTTString('Required field'),
                        minLength: toTTString('Length must be at least 2'),
                        maxLength: toTTString('Length must be at most 30'),
                        name: toTTString('Invalid app identification name format'),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="description" lg={5} md={6}>
                    <TT>Description</TT>
                    <span
                      className="helpTooltip"
                      data-tip
                      data-for="descriptionTip"
                    ></span>
                  </Label>
                  <ReactTooltip id="descriptionTip">
                    <span style={{ fontSize: '0.8rem' }}>
                      <TT>Description of the app identification</TT>
                    </span>
                  </ReactTooltip>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.text
                      model=".description"
                      id="description"
                      name="description"
                      placeholder={toTTString("Description")}
                      component={FWTextInput}
                      withFieldValue
                      disabled={this.option === 'customize'}
                      validators={{
                        maxLength: maxLength(128),
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".name"
                      show="touched"
                      messages={{
                        maxLength: toTTString('Length must be at most 128'),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="category" lg={5} md={6}>
                    <span className="form-required-star">*</span>
                    <TT>Category</TT>
                    <span
                      className="helpTooltip"
                      data-tip
                      data-for="categoryTip"
                    ></span>
                    <ReactTooltip id="categoryTip">
                      <span style={{ fontSize: '0.8rem' }}><TT>Category type</TT></span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control
                      model=".category"
                      id="category"
                      name="category"
                      placeholder={toTTString("Category")}
                      changeAction={this.handleChangeSelectAction}
                      component={SearchableSelectBox}
                      creatable={true}
                      options={this.categories}
                      formatCreateLabel=""
                      validators={{
                        minLength: minLength(2),
                        maxLength: maxLength(30),
                        name: validateAppIdentCategory,
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".category"
                      show="touched"
                      messages={{
                        required: toTTString('Required field'),
                        minLength: toTTString('Length must be at least 2'),
                        maxLength: toTTString('Length must be at most 30'),
                        name: toTTString('Invalid category format'),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="serviceClass" lg={5} md={6}>
                    <span className="form-required-star">*</span>
                    <TT>Service Class</TT>
                    <span
                      className="helpTooltip"
                      data-tip
                      data-for="serviceClassTip"
                    ></span>
                    <ReactTooltip id="serviceClassTip">
                      <span style={{ fontSize: '0.8rem' }}>
                        <TT>Service Class type</TT>
                      </span>
                    </ReactTooltip>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                  <Control.select
                      model=".serviceClass"
                      id="serviceClass"
                      name="serviceClass"
                      className="form-control"
                      validators={{
                        required: required,
                        minLength: minLength(2),
                        maxLength: maxLength(30),
                        name: validateAppIdentServiceClass,
                      }}
                    >
                      <option key="">{toTTString('<not selected>')}</option>,
                      {predefinedServiceClasses.map(sc => (
                        <option key={sc} value={sc}>{sc}</option>
                      ))}
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="importance" lg={5} md={6}>
                    <span className="form-required-star">*</span>
                    <TT>Importance</TT>
                  </Label>
                  <Col className="form-field" lg={7} md={6}>
                    <Control.select
                      model=".importance"
                      id="importance"
                      name="importance"
                      className="form-control"
                      validators={{
                        required: required,
                        minLength: minLength(1),
                      }}
                    >
                      {[
                        <option key="">{toTTString('<not selected>')}</option>,
                        <option key="high" value={'high'}>
                          {toTTString('High')}
                        </option>,
                        <option key="medium" value={'medium'}>
                          {toTTString('Medium')}
                        </option>,
                        <option key="low" value={'low'}>
                          {toTTString('Low')}
                        </option>,
                      ]}
                    </Control.select>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Errors
              className="text-danger"
              model="addAppIdentification"
              show="touched"
              messages={{
                original:
                  toTTString('At least one of the properties needs to be modified to perform an update'),
              }}
            />
          </Form>
        </div>
        <hr />
        <div className="col-md-12">
          <div>
            <h5><TT>App Identification Rules</TT></h5>
            <div className={'general-card-upper-panel'}>
              {this.option !== 'customize' ? (
                <Button
                  color="success"
                  onClick={() => {
                    this.toggleRuleAddition();
                  }}
                  className={'btn btn-info action-btn-top upper-panel-button'}
                >
                  +
                </Button>
              ) : null}
            </div>
            <div style={{ width: '80%' }}>
              <BootstrapTable
                striped
                hover
                condensed
                keyField="id"
                data={Object.values(this.props.newRules)}
                columns={this.RuleColumn}
                pagination={paginationFactory(this.paginationOptions)}
                noDataIndication={toTTString('No Rules Defined')}
                defaultSorted={[{ dataField: 'host', order: 'asc' }]}
              />
            </div>
          </div>
        </div>
        <Modal
          style={{ width: '40%', maxWidth: 'none' }}
          id="RuleAdditionModal"
          isOpen={this.state.isRuleAdditionModalOpen}
          toggle={this.toggleRuleAddition}
        >
          <ModalHeader toggle={this.toggleRuleAddition}>
            <TT>Add App Identification Rule</TT>
          </ModalHeader>
          <ModalBody>
            <Card id="addRuleCard">
              <CardBody>
                <Form
                  id="addRuleForm"
                  model="addAppIdentificationRule"
                  onSubmit={(values) => {
                    this.approveRuleAddition(values);
                  }}
                  validators={{
                    '': {
                      duplicateRule: (vals) => this.validateDuplicateRule(vals),
                      rulesCombination: (vals) =>
                        this.validateRulesCombination(vals),
                    },
                  }}
                >
                  <Row className="form-group">
                    <Label htmlFor="ip" md={5}>
                      <TT>IP Address</TT>{' '}
                      <span
                        className="helpTooltip"
                        data-tip
                        data-for="ipAddressTip"
                      ></span>
                      <ReactTooltip id="ipAddressTip">
                        <span style={{ fontSize: '0.8rem' }}>
                          <TT>IPv4 Address And Mask (192.168.1.0/24)</TT>
                        </span>
                      </ReactTooltip>
                    </Label>
                    <Col md={7}>
                      <Control.text
                        type="text"
                        model=".ip"
                        id="ip"
                        name="ip"
                        placeholder={toTTString("IP Address/Mask")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          ipv4: IPv4FieldValidator,
                          prefixLen: isValidPrefixLength
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".ip"
                        show="touched"
                        messages={{
                          ipv4: toTTString('Invalid IP/Mask'),
                          prefixLen: toTTString("Invalid prefix for given mask"),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Label htmlFor="ports" md={5}>
                      <TT>Ports</TT>{' '}
                      <span
                        className="helpTooltip"
                        data-tip
                        data-for="portsTip"
                      ></span>
                      <ReactTooltip id="portsTip">
                        <span style={{ fontSize: '0.8rem' }}>
                          <TT>Single port (54) or range (53-54)</TT>
                        </span>
                      </ReactTooltip>
                    </Label>
                    <Col md={7}>
                      <Control.text
                        type="text"
                        model=".ports"
                        id="ports"
                        name="ports"
                        placeholder={toTTString("Ports")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          ports: isPortValid
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".ports"
                        show="touched"
                        messages={{
                          maxLength: toTTString('Length must be at most 11'),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Label htmlFor="protocol" md={5}>
                      <TT>Protocol</TT>{' '}
                      <span
                        className="helpTooltip"
                        data-tip
                        data-for="protocolTip"
                      ></span>
                      <ReactTooltip id="protocolTip">
                        <span style={{ fontSize: '0.8rem' }}>
                          <TT>Transport Protocol</TT>
                        </span>
                      </ReactTooltip>
                    </Label>
                    <Col md={7}>
                      <Control.select
                        model=".protocol"
                        id="protocol"
                        name="protocol"
                        placeholder=""
                        className="form-control"
                      >
                        {[
                          <option key="" value={''}>
                            {toTTString('Select Protocol')}
                          </option>,
                          <option key="tcp" value={'tcp'}>
                            {toTTString('TCP')}
                          </option>,
                          <option key="udp" value={'udp'}>
                            {toTTString('UDP')}
                          </option>,
                        ]}
                      </Control.select>
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={{ size: 10, offset: 3 }}>
                      <Control.button
                        model="addAppIdentificationRule"
                        disabled={{ valid: false }}
                        className="btn btn-primary"
                      >
                        <TT>Add Rule</TT>
                      </Control.button>
                    </Col>
                  </Row>
                  <Errors
                    className="text-danger"
                    model="addAppIdentificationRule"
                    show="touched"
                    messages={{
                      duplicateRule:
                        toTTString('Similar rule already exists, please choose another'),
                      rulesCombination:
                        toTTString('Please choose valid combination') + '. ' +
                        toTTString('The rule can be either only IP/Mask, ' +
                        'only port, or any other combination of two or three values') + '.',
                    }}
                  />
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AddAppIdentification;
