import React, { Component, useContext } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StructuredFilter from './items/structured-filter/lib/main';
import { loadLocalSettings, saveLocalSettings } from '../utils/localSettings';
import { TT, LanguageContext } from '../containers/Language'
import * as qs from 'query-string';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';

const getFilteredValue = (filter) => {
  switch (filter.categorykey) {
    case 'lastResolvedStatusChange':
      const dateVal = new Date(filter.value);
      return dateVal.getTime() + dateVal.getTimezoneOffset() * 60000;
    case 'resolved':
      return resolvedStates[filter.value].value
    case 'isInfo':
      return true
    default:
      return filter.value;
  }
}

const resolvedStates = {
  Active: { value: false, color: 'danger' },
  Resolved: { value: true, color: 'success' },
  Info: { value: true, color: 'info'}
};


const NotificationsFilter = ({ filters, updateFilters, clearFilters }) => {
  const { toTTString } = useContext(LanguageContext);
  return (
  <div className="container filter-bar">
    <StructuredFilter
      className={"search-query form-control"}
      placeholder={
        filters.length === 0
          ? toTTString("Filter by notification attributes")
          : toTTString("Add filter")
      }
      value={filters}
      options={[
        {
          category: toTTString("Date"),
          categorykey: "lastResolvedStatusChange",
          type: "date",
        },
        {
          category: toTTString("Notification"),
          categorykey: "title",
          type: "text",
        },
        {
          category: toTTString("Status"),
          categorykey: "resolved",
          type: "textoptions",
          options: () => {
            return [toTTString('Active'),toTTString('Resolved'), toTTString('Info')]
          },
        },
        {
          category: toTTString("Severity"),
          categorykey: "severity",
          type: "textoptions",
          options: () => {
            return [toTTString('critical'),toTTString('warning')]
          },
        },
        {
          category: toTTString("State"),
          categorykey: "status",
          type: "textoptions",
          options: () => {
            return [toTTString('Read'),toTTString('Unread')]
          },
        },
        {
          category: toTTString("Device Name"),
          categorykey: "targets.deviceId.name",
          type: "text",
        },{
          category: toTTString("Tunnel Number"),
          categorykey: "targets.tunnelId",
          type: "text",
        }
      ]}
      onChange={updateFilters}
      operatorSigns={{
        textoptions: {
          "==": "==",
          "!=": "!=",
        },
        date: {
          "<=": "<=",
          ">=": ">=",
        },
        text: {
          "!empty": "!n",
          empty: "n",
          "==": "==",
          "!=": "!=",
          contains: "in",
          "!contains": "!in",
        },
        textoptionsarray: {
          "contains": "contains",
          "!contains": "!contains"
        },
      }}
    />
    <FontAwesomeIcon icon="search" fixedWidth />
    <Button
      className="btn-primary filter-box-btn"
      onClick={clearFilters}
    >
      <TT>Clear Filters</TT>
    </Button>
  </div>
)}

class Notifications extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    // Load user's local settings
    const { filters, requestParams } = loadLocalSettings('notifications');
    const toTTString = this.context.toTTString
    const queryParams = qs.parse(this.props.queryParams);
    if (queryParams && 'name' in queryParams) {
      this.setState({
        filters: [{
          category: toTTString('Device Name'),
          operator: '==',
          categorykey: 'targets.deviceId.name',
          value: queryParams.name
        }, {
          category: 'State',
          operator: '==',
          categorykey: 'status',
          value: 'Unread'
        }]
      });
    } else {
      if (filters && this.state.filters.length === 0) this.setState({ filters });
    }

    if (requestParams) {
      requestParams.offset = 0;
      this.setState({ requestParams });
    }
  }

  viewParametersUpdated() {
    // Save user's local settings
    const { filters, requestParams } = this.state;
    saveLocalSettings('notifications', { filters, requestParams });
    this.getPaginatedNotifications();
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedNotifications: [],
      filters: [],
      requestParams: {
        sortField: 'lastResolvedStatusChange',
        sortOrder: 'desc',
        offset: 0,
        limit: 10
      },
      isActionsDropDownOpen: false,
      isDeleteAllModalOpen: false
    };

    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleNotificationsAction = this.handleNotificationsAction.bind(this);
    this.toggleActionsDropDown = this.toggleActionsDropDown.bind(this);
    this.getPaginatedNotifications = this.getPaginatedNotifications.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleDeleteAllModal = this.toggleDeleteAllModal.bind(this);
    this.approveDeleteAll = this.approveDeleteAll.bind(this);
  }

  handleOnSelect = (row, isSelect) => {
    const ids = isSelect
      ? [...this.state.selectedNotifications, row._id]
      : this.state.selectedNotifications.filter(id => id !== row._id);
    this.setState({ selectedNotifications: ids });
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = isSelect ? rows.map(row => row._id) : [];
    this.setState({ selectedNotifications: ids });
  }

  handleNotificationsAction(notificationIds, status, resolve = null) {
    this.props.updateNotifications(notificationIds, status, resolve, () => {
      this.getPaginatedNotifications();
    });
    this.setState(() => ({ selectedNotifications: [] }));
  }

  toggleActionsDropDown() {
    this.setState({
      isActionsDropDownOpen: !this.state.isActionsDropDownOpen
    });
  }

  handleDeleteAll() {
    this.toggleDeleteAllModal();
  }

  toggleDeleteAllModal() {
    this.setState({
      isDeleteAllModalOpen: !this.state.isDeleteAllModalOpen
    });
  }

  approveDeleteAll() {
    this.toggleDeleteAllModal();
    const filters = this.state.filters.map(f => ({
      key: f.categorykey,
      op: f.operator,
      val: getFilteredValue(f)
    }));
    this.props.deleteNotifications(filters, ()=> {
      this.setState(() => ({
        requestParams: { ...this.state.requestParams, offset : 0 },
        selectedNotifications: []
      }), this.viewParametersUpdated);
    });
  }

  onTableChange = (type, newState) => {
    const { sizePerPage, page, sortField, sortOrder } = newState;
    if (sizePerPage !== undefined && page !== undefined) {
      this.setState({
        requestParams: {
          offset: page * sizePerPage,
          limit: sizePerPage,
          sortField: sortField,
          sortOrder: sortOrder
        }
      }, this.viewParametersUpdated);
    }
  }

  getPaginatedNotifications = () => {
    this.props.getNotifications({
      ...this.state.requestParams,
      filters: JSON.stringify(
        this.state.filters.map(f => ({
          key: f.categorykey,
          op: f.operator,
          val: getFilteredValue(f)
        }))
      )
    })
  }

  updateFilters(filters) {
    const updatedFilters =
      filters.map(filter => ({
        category: filter.category,
        categorykey: filter.value === 'Info'? 'isInfo' : filter.categorykey,
        operator: filter.operator,
        value: filter.value === 'Info'? 'Info': filter.value
      })
    )

    // Clear checkboxes when the filter changes.
    this.setState({
      requestParams: { ...this.state.requestParams, offset: 0 },
      filters: updatedFilters,
      selectedNotifications: []
    }, this.viewParametersUpdated);
  }

  render() {
    const toTTString = this.context.toTTString
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: true,
      selected: this.state.selectedNotifications,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    const badgeColorByType = {
      'Tunnel': '#4287f5',
      'Device': '#42f598',
      'Interface':'#ff825c'
    }

    this.notificationColumns = [
      { text: toTTString("rowID"), dataField: "_id", hidden: true },
      {
        text: toTTString("Time"),
        dataField: "lastResolvedStatusChange",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return <div>
            <span>
              { new Date(row.lastResolvedStatusChange).toLocaleString("en-US", {
                year: "numeric",
                month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit"
                })}
              </span>
          </div>
        }
      },
      {
        text: toTTString("Notification"),
        dataField: "title",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "31%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
            <TT>{row.title}</TT>
            <div className="d-flex align-items-center">
              <span
                className="helpTooltip mr-2"
                data-tip
                data-for={`detailsTooltip-${row._id}`}
              ></span>
              <ReactTooltip id={`detailsTooltip-${row._id}`}>
                <span><TT>{row.details}</TT></span>
              </ReactTooltip>
              <span
                style={{
                  backgroundColor: '#151f33',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  paddingTop: '1px',
                  color: 'white',
                  fontSize: '12px',
                  marginLeft: '5px'
                }}
                data-tip
                data-for={`countTooltip-${row._id}`}
              >
                {'  '+ row.count}
              </span>
              <ReactTooltip id={`countTooltip-${row._id}`}>
                <span>
                  <TT>This event occurred</TT> {row.count > 1 ? <span>{row.count} <TT>times</TT></span> : <TT>once</TT>}
                </span>
              </ReactTooltip>
            </div>
          </div>
          )
        }
      },
      {
        text: toTTString("Target"),
        dataField: "targets",
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          const targetsObject = row.targets;
          let target;
          if (targetsObject.interfaceId) {
            target = { type: 'Interface', name: targetsObject.interfaceId?.name ?? 'N/A', color: badgeColorByType['Interface']};
          } else if (targetsObject.tunnelId) {
            target = { type: 'Tunnel', name: targetsObject.tunnelId, color: badgeColorByType['Tunnel']};
          } else {
            target = { type: 'Device', name: targetsObject.deviceId?.name ?? 'N/A', color: badgeColorByType['Device']};
          }

          const renderBadge = (badgeInfo) => (
            <span className="badge" style={{border: `1px solid ${badgeInfo.color}`, padding: '0 0', borderRadius: '1em', backgroundColor: `${badgeInfo.color}`, margin: '0 5px 5px 0'}}>
              <span style={{padding: '0.5em 0.4em', borderTopLeftRadius:'1.5em', borderBottomLeftRadius:'1.5em'}}>
                {badgeInfo.type}
              </span>
              <span style={{padding:'0.5em 0.4em', color: 'black', backgroundColor: 'white', borderTopRightRadius:'1.5em', borderBottomRightRadius:'1.5em'}}>
                {badgeInfo.name}
              </span>
            </span>
          );

          return (
            <div style={{ maxWidth: '100%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {renderBadge(target)}
                {/* If there's a deviceId and it's not the main target, render its badge */}
                {targetsObject.deviceId?._id && target.type !== 'Device' && renderBadge({ type: 'Device', name: targetsObject.deviceId?.name, color: badgeColorByType['Device']})}
              </div>
            </div>
          );
        }
      },
      {
        text: toTTString("Value"),
        dataField: "agentAlertsInfo.value",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          return <div>
            <span style={{
              borderRadius: '3px',
              textAlign: 'center',
              padding: '5px'
            }}>
              {
              row.agentAlertsInfo?.value? `${row.agentAlertsInfo.value}${row.agentAlertsInfo?.unit || ''}`
              : row.agentAlertsInfo?.threshold ? `under ${row.agentAlertsInfo.threshold}${row.agentAlertsInfo?.unit || ''}`
              : 'N/A'
              }
            </span>
          </div>
       },
      },
      {
        text: toTTString("Severity"),
        dataField: "severity",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "12%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          return <div>
            <span style={{
              borderRadius: '3px',
              textAlign: 'center',
              width: '70px',
              padding: '5px'
            }}>
              <TT>{row.severity}</TT>
            </span>
          </div>
       },
      },
      {
        text: toTTString("Status"),
        dataField: "resolved",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "12%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          return <div>
            <Badge color={row.isInfo? resolvedStates['Info'].color : resolvedStates[row.resolved ? 'Resolved': 'Active'].color} style={{ width: '70%'}}>
              <TT>{row.isInfo? 'Info' : row.resolved? 'Resolved': 'Active'}</TT>
            </Badge>
          </div>
        }
      },
      {
        text: toTTString("Actions"),
        dataField: "none",
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          const isRead = row.status === 'read';
          const newStatus = isRead ? "unread" : "read";
          return (
            <div style={{ display: "flex", justifyContent: "space-between", width: '70px'}}>
              <div>
                <Button
                  color="info"
                  className="notification-action-btn"
                  data-tip
                  data-for={isRead ? "status-read" : "status-unread"}
                  size="sm"
                  onClick={() => this.handleNotificationsAction([row._id], newStatus)}
                >
                  <FontAwesomeIcon icon={isRead ? "eye-slash" : "eye"} fixedWidth />
                </Button>
                <ReactTooltip id={isRead ? "status-read" : "status-unread"}>
                  <span>{isRead ? <TT>Mark as unread</TT> : <TT>Mark as read</TT>}</span>
                </ReactTooltip>
              </div>
              {!row.resolved && <div>
              <Button
                color="info"
                className="notification-action-btn"
                data-tip
                data-for={"status-Inactive"}
                size="sm"
                onClick={() => this.handleNotificationsAction([row._id], null, true)}
              >
                <FontAwesomeIcon icon={faBellSlash} fixedWidth />
              </Button>
              <ReactTooltip id={"status-Inactive"}>
                <span><TT>Mark as resolved</TT></span>
              </ReactTooltip>
              </div>}
            </div>
          );
        }
      }
    ];

    const rowStyle = (row, rowIndex) => {
      return row.status === 'read' ?  {} : { backgroundColor: '#d6ebfd' };
    };
    const { limit, offset } = this.state.requestParams;
    const paginationOptions = {
      totalSize: this.props.notifications.total,
      page: limit > 0 ? offset / limit : 0,
      sizePerPage: limit,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("Next page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("First page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Notifications</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ]
    };

    const addStateFilter = (state, categorykey) => {
      this.setState(prev => ({
        requestParams: {
          ...prev.requestParams, offset: 0
        },
        filters: [{
          category: toTTString("Status"),
          categorykey: categorykey,
          operator: "==",
          value: state
        }]
      }), this.getPaginatedNotifications);
    };

    const hasStateFilter = state => this.state.filters.some(f =>
      f.operator === '==' &&
      (f.categorykey === 'resolved' || f.categorykey === 'isInfo') &&
      f.value === state
    );

  <div>
  </div>


    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Troubleshoot</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Notifications</TT></BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4><TT>Notifications</TT></h4>
          {this.props.notifications.isLoading ? (
            <div className="signal"></div>
          ) : null}
          <div style={{marginTop: '0.5rem', marginLeft: '5rem'}}>
            <small> <TT>Filter by state</TT> :</small>
            {Object.entries(resolvedStates).map(([state, { value, color }]) => (
              <Button
                className="ml-2 text-capitalize" size="sm" color={color} key={value}
                outline={!hasStateFilter(state)} onClick={()=>addStateFilter(state, state === 'Info'? 'isInfo' : 'resolved')}
              >
                <TT>{state}</TT>
              </Button>
            ))}
          </div>
        </div>
        <div className="col-md-12">
          <div className="d-flex align-items-center screen-bar mb-3">
            <ReactTooltip id="refresh-a">
              <span><TT>Refresh</TT></span>
            </ReactTooltip>
            <Button
              color="info"
              className="refresh-btn"
              data-tip
              data-for="refresh-a"
              size="sm"
              onClick={this.getPaginatedNotifications}
            >
              <FontAwesomeIcon icon="sync-alt" />
            </Button>
            <div className="mr-1">
                <Dropdown
                  isOpen={this.state.isActionsDropDownOpen}
                  toggle={this.toggleActionsDropDown}
                >
                  <DropdownToggle
                    caret
                    className="action-drop-down"
                  >
                    <TT>Actions</TT>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header><TT>Notifications Actions</TT></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      disabled={this.state.selectedNotifications.length < 1}
                      onClick={() =>
                        this.handleNotificationsAction(
                          this.state.selectedNotifications,
                          "read"
                        )
                      }
                    >
                      <TT>Mark as read</TT>
                    </DropdownItem>
                    <DropdownItem
                      disabled={this.state.selectedNotifications.length < 1}
                      onClick={() =>
                        this.handleNotificationsAction(
                          this.state.selectedNotifications,
                          "unread"
                        )
                      }
                    >
                      <TT>Mark as unread</TT>
                    </DropdownItem>
                    <DropdownItem
                      disabled={this.state.selectedNotifications.length < 1}
                      onClick={() =>
                        this.handleNotificationsAction(
                          this.state.selectedNotifications,
                          null,
                          true
                        )
                      }
                    >
                      <TT>Mark as resolved</TT>
                    </DropdownItem>
                    {this.state.filters.length > 0 &&
                    <DropdownItem onClick={() => this.handleDeleteAll()}>
                      <TT>Delete All Notifications matching the filter</TT>
                    </DropdownItem>
                    }
                  </DropdownMenu>
                </Dropdown>
            </div>
            <NotificationsFilter
              filters={this.state.filters}
              labels={this.state.labels}
              updateFilters={this.updateFilters}
              clearFilters={() => this.setState({ filters: [] }, this.viewParametersUpdated)}
            />
          </div>
          <BootstrapTable
            keyField="_id"
            data={this.props.notifications.notifications}
            columns={this.notificationColumns}
            remote={true}
            onTableChange={this.onTableChange}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={toTTString("No Notifications available")}
            defaultSorted={[{ dataField: "lastResolvedStatusChange", order: "desc" }]}
            selectRow={selectRow}
            rowStyle={rowStyle}
          />
        </div>
        <Modal isOpen={this.state.isDeleteAllModalOpen} toggle={this.toggleDeleteAllModal}>
          <ModalHeader toggle={this.toggleDeleteAllModal}><TT>Delete Notifications</TT></ModalHeader>
          <ModalBody>
            <div className="mb-3"><TT>Are you sure to delete all notifications matching the filter?</TT></div>
            <Button color="danger" onClick={this.approveDeleteAll}><TT>Yes</TT></Button>
            <Button className="float-right" color="outline-secondary" onClick={this.toggleDeleteAllModal}><TT>Cancel</TT></Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Notifications;
