import * as Action from '../Actions';

export const Sidebar = (state={
  dropdownConfig: false,
  dropdownDashboard: false,
  dropdownTroubleshoot: false,
  dropdownAccounts: false,
  dropdownUsers: false,
  dropdownTrafficOptimization: false,
  dropdownAppStore: false,
  dropdownSecurity: false,
  highAvailability: false
}, action) => {
  switch (action.type) {
    case Action.SIDEBAR_CONFIG_DROPDOWN_TOGGLE:
      return {...state, dropdownConfig:!state.dropdownConfig};

    case Action.SIDEBAR_DASHBOARD_DROPDOWN_TOGGLE:
      return {...state, dropdownDashboard:!state.dropdownDashboard};

    case Action.SIDEBAR_TROUBLESHOOT_DROPDOWN_TOGGLE:
      return {...state, dropdownTroubleshoot:!state.dropdownTroubleshoot};

    case Action.SIDEBAR_ACCOUNTS_DROPDOWN_TOGGLE:
        return {...state, dropdownAccounts:!state.dropdownAccounts};

    case Action.SIDEBAR_USERS_DROPDOWN_TOGGLE:
        return {...state, dropdownUsers:!state.dropdownUsers};

    case Action.SIDEBAR_TRAFFIC_OPT_DROPDOWN_TOGGLE:
      return {...state, dropdownTrafficOptimization:!state.dropdownTrafficOptimization};

    case Action.SIDEBAR_SECURITY_DROPDOWN_TOGGLE:
      return {...state, dropdownSecurity:!state.dropdownSecurity};

    case Action.SIDEBAR_HA_DROPDOWN_TOGGLE:
      return {...state, highAvailability:!state.highAvailability};

    case Action.SIDEBAR_APPLICATIONS_DROPDOWN_TOGGLE:
      return {...state, dropdownAppStore:!state.dropdownAppStore};

    default:
      return state;
  }
};

/* Action creators */
export const sidebarConfigDropdownToggle = () => ({
  type: Action.SIDEBAR_CONFIG_DROPDOWN_TOGGLE
});

export const sidebarDashboardDropdownToggle = () => ({
  type: Action.SIDEBAR_DASHBOARD_DROPDOWN_TOGGLE
});

export const sidebarTroubleshootDropdownToggle = () => ({
  type: Action.SIDEBAR_TROUBLESHOOT_DROPDOWN_TOGGLE
});

export const sidebarAccountsDropdownToggle = () => ({
  type: Action.SIDEBAR_ACCOUNTS_DROPDOWN_TOGGLE
});

export const sidebarUsersDropdownToggle = () => ({
  type: Action.SIDEBAR_USERS_DROPDOWN_TOGGLE
});

export const sidebarTrafficOptDropdownToggle = () => ({
  type: Action.SIDEBAR_TRAFFIC_OPT_DROPDOWN_TOGGLE
});

export const sidebarSecurityDropdownToggle = () => ({
  type: Action.SIDEBAR_SECURITY_DROPDOWN_TOGGLE
});

export const sidebarHighAvailabilityDropdownToggle = () => ({
  type: Action.SIDEBAR_HA_DROPDOWN_TOGGLE
});

export const sidebarApplicationsDropdownToggle = () => ({
  type: Action.SIDEBAR_APPLICATIONS_DROPDOWN_TOGGLE
});