import React, { useState, useEffect, useContext, useCallback } from 'react';
import FwBootstrapTable from '../../items/FwBootstrapTable';
import { Button, Col, Row, Table } from 'reactstrap';
import { TT, LanguageContext } from '../../../containers/Language';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVrrpStatus } from '../../../redux/reducers/HighAvailability';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const VrrpStatus = props => {
  const { toTTString } = useContext(LanguageContext);

  const { getStatus } = props;
  const [status, setStatus] = useState([]);

  const fetchStatus = useCallback(() => {
    getStatus((res, err) => {
      if (res) {
        setStatus(res);
      }
    });
  }, [getStatus]);

  useEffect(() => {
    fetchStatus();
    const timer = setInterval(() => {
      if (document.hasFocus()) {
        fetchStatus();
      }
    }, 8000);

    return function() {
      clearInterval(timer);
    }
  }, [fetchStatus]);

  const valOrNA = (val, tipId) => {
    if (val) {
      return val;
    } else {
      return (
        <>
        <span data-tip data-for={tipId}>N/A</span>
        <ReactTooltip id={tipId}>
          <TT>The router may not be connected</TT>
        </ReactTooltip>
        </>
      )
    }
  }
  const expandRow = {
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div style={{width: '10%', textAlign: 'left'}}></div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <Button color="success">{expanded ? "Show Less": "Show More"}</Button>
    ),
    expandColumnPosition: 'right',
    renderer: row => {
      const data = row?.devices ?? [];
      return (
        <>
          <Row>
            <Col>Devices</Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Table borderless={true} className={'reactstrap-table'}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>State</th>
                    <th>Priority</th>
                    <th>Adjusted Priority</th>
                    <th>Last Update Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, idx) => (
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Link to={`/devices/deviceinfo/${d._id}?tab=Interfaces`}>
                          <span style={{color: 'steelblue'}}>{d.name}</span>
                        </Link>
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>{valOrNA(d.status?.state, `state-${idx}`)}</td>
                      <td style={{ verticalAlign: 'middle' }}>{d.priority}</td>
                      <td style={{ verticalAlign: 'middle' }}>{valOrNA(d.status?.adjusted_priority, `adjusted_priority-${idx}`)}</td>
                      <td style={{ verticalAlign: 'middle' }}>{valOrNA(d.status?.time ? moment(d.status.time).format('h:mm:ss a MMM DD, YYYY') : null, `time-${idx}`)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )
    }
  };

  return (
    <>
      <Col md={12}>
        <FwBootstrapTable
          keyField="virtualRouterId"
          data={status}
          hover={false}
          expandRow={expandRow}
          columns={[
            {
              text: toTTString('VRRP Group Name'),
              dataField: 'name',
              sort:true,
              headerStyle: (colum, colIndex) => {
                return { width: '30%', textAlign: 'left' };
              },
              style: { verticalAlign: 'middle' }
            },
            {
              text: toTTString('Virtual Router ID'),
              dataField: 'virtualRouterId',
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '30%', textAlign: 'left' };
              },
              formatter: (content, row) => {
                return (
                  <Link to={`/vrrp/configure/${row._id}`}>
                    <span style={{color: 'steelblue'}}>{content}</span>
                  </Link>
                )
              },
              style: { verticalAlign: 'middle' }
            },
            {
              text: toTTString('Virtual IP'),
              dataField: 'virtualIp',
              sort:false,
              headerStyle: (colum, colIndex) => {
                return { width: '30%', textAlign: 'left' };
              },
              style: { verticalAlign: 'middle' }
            }
          ]}
        />
      </Col>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getStatus: (cb) => dispatch(getVrrpStatus(cb))
  }
}

export default connect(null, mapDispatchToProps)(VrrpStatus);
