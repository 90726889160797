import React from 'react';
import { connect } from 'react-redux';
import { setLoginProcessScreen } from '../../../redux/reducers/Auth';
import { TT } from '../../../containers/Language';
const { contactUsUrl } = window.SERVER_CONFIG;

const BottomCard = props => {
  return (
    <>
      <hr />
      <div className="bottom-card-message">
        <div className="d-flex justify-content-between">
          <span onClick={() => props.setLoginScreen(0)} style={{ cursor: 'pointer' }}><TT>Return to login page</TT></span>

          {props.showTryAnotherMethod !== false ? (
            <span onClick={() => props.setLoginScreen(2)} style={{ cursor: 'pointer' }}><TT>Try another method</TT></span>
          ): null }

          {props.showContactUs ? (
            <span><a href={contactUsUrl}><TT>Contact us</TT></a></span>
          ): null}
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginScreen: screenNum => dispatch(setLoginProcessScreen(screenNum)),
  }
}

export default connect(null, mapDispatchToProps)(BottomCard);