import React, { Component } from 'react';
import {  Nav, NavItem, Row, Col, Card, CardHeader, CardBody, Label, Badge } from 'reactstrap';

import { Control, Form, Errors } from 'react-redux-form';
import * as qs from 'query-string';

import ReactTooltip from 'react-tooltip';
import { FWTextInput, FWPasswordInput } from '../../Common';
import { required, maxLength, minLength } from '../../../utils/Validators';
import { channelList } from '../../../utils/wifi-channels';
import { store } from "../../../App";
import InfoNotAvailable from "../../items/infoNotAvailable";
import moment from 'moment';
import { TT, LanguageContext  } from '../../../containers/Language'
import Loading from '../../items/Loading';
import FwBootstrapTable from '../../items/FwBootstrapTable';

class ConfigureWifi extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    const query = qs.parse(props.search);
    this.statusTimer = null;
    this.isSupport5GHz = this.isSupport5GHz.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getFormModel = this.getFormModel.bind(this);
    this.changeLocalForm = this.changeLocalForm.bind(this);

    this.state = {
      loading: true,
      monitorLoading: false,
      currentTab: query.tab || "2.4GHz",
      deviceStatus: 'loading',
      info: {},
      updatedDataBeforeSave: {},
      channels: Array.from({length: 13}, (_, i) => i + 1), // initial value for 2.4tab
      infoNotAvailable: false
    }
  }

  componentDidMount() {
    this.changeLocalForm()
    const useCache = true;

    if (this.statusTimer === null) {
      const timer = setInterval(() => {
        if (document.hasFocus()) {
          this.getWifiMonitorInfo(!useCache)
        }
      }, useCache ? 2000 : 15000)
      this.statusTimer = timer;
    }

    this.getWifiMonitorInfo(!useCache)
  }

  componentWillUnmount() {
    if (this.statusTimer != null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
    this.props.resetForm("configureWifi24")()
    this.props.resetForm("configureWifi5")()
  }

  getFormModel() {
    return this.state.currentTab === "2.4GHz" ? "configureWifi24" : "configureWifi5";
  }

  handleChange(values) {
    const region = values.region;
    if (region && region !== "") {

      if (this.state.currentTab === "2.4GHz") {
        let number = 13;

        if (region === 'US' || region === 'TW') {
          number = 11;
        }

        this.setState({
          channels: Array.from({length: number}, (_, i) => i + 1)
        });
      }
      else if (this.state.currentTab === "5GHz") {
        const country = Object.values(channelList).find(i => i.code === region)
        if (country) {
          // if selected country channels has no already selected channel, set to null
          const currentChannel = parseInt(values.channel);
          if (currentChannel > 0 && country.channels.findIndex(c => c === currentChannel) === -1) {
            this.props.changeForm(this.getFormModel() + '.channel')('')
          }

          this.setState({
            channels: country.channels
          });
        }
      }
    }

    const configs = {
      ...this.state.updatedDataBeforeSave,
      [this.state.currentTab]: {...values}
    }
    this.setState({ updatedDataBeforeSave: configs })
  }

  handleClose() {
    if (typeof this.props.onSubmit === 'function')
      this.props.onSubmit(null)
  }

  handleSubmit(values) {
    const form2 = store.getState().forms.configureWifi24.$form.valid;
    const form5 = store.getState().forms.configureWifi5.$form.valid;

    const configs = {
      ...this.props.interface.configuration,
      ...this.state.updatedDataBeforeSave
    }

    if (!form2) {
      delete configs['2.4GHz']
    }

    if (!form5) {
      delete configs['5GHz']
    }

    if (typeof this.props.onSubmit === 'function')
      this.props.onSubmit(configs, true)
  }

  getWifiMonitorInfo(fromServer=false) {
    // lte monitoring info is sent with device status and saved in the server memory.
    // we can fetch the monitoring info from the server cache of from the device directly.
    // Fetching the data from the device might take time.
    // The "fromServer" parameter indicates if to fetch data from cache or from the device.
    if (!fromServer && !this.state.monitorLoading) {
      const devId = this?.props?.interface?.devId;
      const wifiStatus = this?.props?.deviceStatus?.wifiStatus;
      if (devId && wifiStatus && devId in wifiStatus) {
        this.setState({ info: wifiStatus[devId] })
      }
      return;
    }

    // fetch from server only if there is already pending requests
    if (!this.state.monitorLoading) {
      this.setState({monitorLoading: true})
      this.props.getInterfaceStatus(this.props.interface._id, resp => {
        if (resp === null) {
          this.setState({ monitorLoading: false });
        } else if (resp.error === 'timeout' || resp.deviceStatus === 'disconnected') {
          this.setState({ infoNotAvailable: true, monitorLoading: false });
        } else {
          this.setState({
            monitorLoading: false,
            info: resp.status,
            infoNotAvailable: false
          })
        }
      });
    }
  }

  changeLocalForm() {
    if (this.state.updatedDataBeforeSave[this.state.currentTab]) {
      this.props.changeForm(this.getFormModel())(this.state.updatedDataBeforeSave[this.state.currentTab])
      return;
    }

    const existsConfigs = this.props.interface && this.props.interface.configuration[this.state.currentTab];

    if (existsConfigs) {
      this.props.changeForm(this.getFormModel())(this.props.interface.configuration[this.state.currentTab], {silent: true})
      this.setState({ updatedDataBeforeSave: {...this.props.interface.configuration }})
    }
  }

  handleTabChange(clicked) {
    this.setState({
      currentTab: clicked
    }, () => {
      this.changeLocalForm();
    });
  }

  isSupport5GHz() {
    if (this.props.interface && this.props.interface.deviceParams) {
      return this.props.interface.deviceParams['Band 2'].Exists;
    }
    return false;
  }

  render() {
    const toTTString = this.context.toTTString
    // the form will be used inside of interface modal if 'onSubmit' is not defined
    const inModal = typeof this.props.onSubmit !== 'function';
    return (
      <>
        {!inModal && <h4>{this.props.interface ? this.props.interface.name + ' - ' + toTTString('WiFi') : null}</h4>}

        <InfoNotAvailable show={this.state.infoNotAvailable} />

        <div className="container">
          <Nav className="nav-tabs">
            <NavItem onClick={() => this.handleTabChange('2.4GHz')} className={this.state.currentTab === "2.4GHz" ? "active nav-link" : "nav-link"}>
              <TT>2.4 GHz</TT>
            </NavItem>
            { this.isSupport5GHz() ? (
              <NavItem onClick={() => this.handleTabChange('5GHz')} className={this.state.currentTab === "5GHz" ? "active nav-link" : "nav-link"}>
                <TT>5 GHz</TT>
              </NavItem>
            ) : (
              <>
                <ReactTooltip id='not-supported'><span><TT>Your hardware doesn't support this option</TT></span></ReactTooltip>
                <NavItem  data-tip data-for='not-supported' className={this.state.currentTab === "5GHz" ? "active nav-link" : "nav-link"}>
                  <TT>5 GHz</TT>
                </NavItem>
              </>
            )}
          </Nav>
        </div>

        <Row>
          <Col md={7}>
            <Card id='configureWifiInterfaceCard'>
              <CardHeader style={{'height': '45px'}}>
                <Row>
                  <Col><span><TT>Configuration</TT></span></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  id={this.getFormModel() + "Form"}
                  model={this.getFormModel()}
                  onSubmit={(values) => this.handleSubmit(values)}
                  onChange={(values) => this.handleChange(values)}
                  validators={{
                    '': {
                      ssidRequired: vals => !vals.enable ? true : required(vals.ssid),
                      ssidMaxLength: vals => !vals.enable ? true : maxLength(32)(vals.ssid),
                      operationModeRequired: vals => !vals.enable ? true : required(vals.operationMode),
                      regionRequired: vals => !vals.enable ? true : required(vals.region),
                      channelRequired: vals => !vals.enable ? true : required(vals.channel),
                      bandwidthRequired: vals => !vals.enable ? true : required(vals.bandwidth),
                      securityModeRequired: vals => !vals.enable ? true : required(vals.securityMode),
                      encryptionRequired: vals => !vals.enable ? true : required(vals.encryption),
                      passwordRequired: vals => !vals.enable || vals.securityMode === 'open' ? true : required(vals.password),
                      passwordMinLength: vals => !vals.enable || vals.securityMode === 'open' ? true : minLength(8)(vals.password),
                      passwordMaxLength: vals => !vals.enable ? true : maxLength(63)(vals.password)
                    }
                  }}
                >
                  {!inModal &&
                  <Row className="form-group">
                    <Label htmlFor="interface" md={3}>
                      <TT>Interface</TT>
                    </Label>
                    <Col md={9}>
                      <Control.text
                        model=".interface"
                        id="interface"
                        name=".interface"
                        placeholder=""
                        disabled
                        className="form-control"
                        value= {this.props.interface.name}
                      />
                    </Col>
                  </Row>}

                  <Row className="form-group">
                    <Label htmlFor="enable" md={3}>
                      <TT>Enable</TT>
                    </Label>
                    <Col md={9}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".enable"
                          id="enable"
                          name="enable"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="ssid" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>SSID</TT>
                    </Label>
                    <Col md={9}>
                      <Control.text
                        type="text"
                        model=".ssid"
                        id="ssid"
                        name="ssid"
                        placeholder={toTTString("Network Name")}
                        data-lpignore="true"
                        component={FWTextInput}
                        withFieldValue
                      />
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ ssidRequired: toTTString('Required field'), ssidMaxLength: toTTString('Length must be at most 32')}}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="hideSsid" md={3}>
                      <TT>Hide SSID</TT>
                    </Label>
                    <Col md={9}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".hideSsid"
                          id="hideSsid"
                          name="hideSsid"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="operationMode" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>Operation Mode</TT>
                    </Label>
                    <Col md={9}>
                      <Control.select
                        type="text"
                        model=".operationMode"
                        id="operationMode"
                        name="operationMode"
                        data-lpignore="true"
                        className="form-control"
                      >
                        <option key="0" value={""}>{toTTString("Select")}</option>
                        {this.state.currentTab === "2.4GHz" ? (
                          <>
                            <option key="1" value={"g"}>{toTTString('802.11 b/g/n')}</option>
                            <option key="2" value={"n"}>{toTTString('802.11 n only')}</option>
                          </>
                        ) : (
                          <>
                            <option key="1" value={"a"}>{toTTString('802.11 a')}</option>
                            <option key="2" value={"ac"}>{toTTString('802.11 ac')}</option>
                            <option key="3" value={"n"}>{toTTString('802.11 n only')}</option>
                          </>
                        )}
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ operationModeRequired: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="region" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>Region</TT>
                    </Label>

                    <Col md={9}>
                      <Control.select
                        type="text"
                        model=".region"
                        id="region"
                        name="region"
                        placeholder=""
                        data-lpignore="true"
                        className="form-control"
                      >
                        {/* <option key="0" value={""}>{"Select"}</option> */}
                        {this.state.currentTab === "2.4GHz" ? (
                          <>
                            <option key="5" value={"AU"}>{toTTString('Australia')}</option>
                            <option key="3" value={"CN"}>{toTTString('China')}</option>
                            <option key="6" value={"DE"}>{toTTString('Europe') + '/' + toTTString('Israel')}</option>
                            <option key="4" value={"JP"}>{toTTString('Japan')}</option>
                            <option key="7" value={"RU"}>{toTTString('Russia')}</option>
                            <option key="2" value={"TW"}>{toTTString('Taiwan')}</option>
                            <option key="1" value={"US"}>{toTTString('USA')}</option>
                          </>
                        ) : (
                          <>
                            {channelList ? Object.keys(channelList).sort().map((c, idx) => {
                              return <option key={idx} value={channelList[c].code}>{c}</option>
                            }) : null}
                          </>
                        )}
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ regionRequired: toTTString('Required field') }}
                      />
                    </Col>

                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="channel" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>Channel</TT>
                    </Label>
                    <Col md={9}>
                      <Control.select
                        type="text"
                        model=".channel"
                        id="channel"
                        name="channel"
                        placeholder=""
                        data-lpignore="true"
                        className="form-control"
                      >
                        <option key="0" value={""}>{"Select"}</option>
                        {this.state.channels.map(c => (
                          <option key={c} value={c}>{c}</option>
                        ))}
                        <option key="acs" value={"0"}>{toTTString('Auto')} ({toTTString('Note: This option might not be supported by your hardware')})</option>
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ channelRequired: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="bandwidth" md={3}>
                      <TT>Bandwidth</TT>
                    </Label>
                    <Col md={9}>
                      <Control.select
                        type="text"
                        model=".bandwidth"
                        id="bandwidth"
                        name="bandwidth"
                        placeholder=""
                        data-lpignore="true"
                        className="form-control"
                        disabled={true}
                      >
                        <option key="1" value={"20"}>{toTTString('20MHz')}</option>
                        <option key="2" value={"40"}>{toTTString('40MHz')}</option>
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ bandwidthRequired: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="securityMode" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>Security Mode</TT>
                    </Label>
                    <Col md={9}>
                      <Control.select
                        type="text"
                        model=".securityMode"
                        id="securityMode"
                        name="securityMode"
                        placeholder=""
                        data-lpignore="true"
                        className="form-control"
                      >
                        <option key="0" value={""}>{toTTString("Select")}</option>
                        <option key="1" value={"open"}>{toTTString('Open')}</option>
                        <option key="3" value={"wpa-psk"}>{toTTString('WPA-PSK')}</option>
                        <option key="4" value={"wpa2-psk"}>{toTTString('WPA2-PSK')}</option>
                        {/* <option key="5" value={"wpa-eap"}>WPA-EAP</option> */}
                        {/* <option key="6" value={"wpa2-eap"}>WPA2-EAP</option> */}
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ securityModeRequired: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="encryption" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>Encryption</TT>
                    </Label>
                    <Col md={9}>
                      <Control.select
                        type="text"
                        model=".encryption"
                        id="encryption"
                        name="encryption"
                        placeholder=""
                        disabled={true}
                        data-lpignore="true"
                        className="form-control"
                      >
                        <option key="1" value={"aes-ccmp"}>{toTTString('AES') + '/' + toTTString('CCMP')}</option>
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{ encryptionRequired: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="password" md={3}>
                      <span className="form-required-star">*</span>
                      <TT>Password</TT>
                    </Label>
                    <Col md={9}>
                      <Control.text
                        model=".password"
                        id="password"
                        name="password"
                        placeholder={toTTString("Password")}
                        data-lpignore="true"
                        component={FWPasswordInput}
                        onPasswordChange={val => {
                          this.props.changeForm(this.getFormModel() + '.password')(val)
                        }}
                        withFieldValue
                      />
                      <Errors
                        className="text-danger"
                        model={this.getFormModel()}
                        show="touched"
                        messages={{
                          passwordRequired: toTTString('Required field'),
                          passwordMinLength: toTTString('Length must be at least 8'),
                          passwordMaxLength: toTTString('Length must be at most 63'),
                        }}
                      />
                    </Col>
                  </Row>
                  {!inModal &&
                  <Row>
                    <Col md={12} className="d-flex">
                      <Control.button
                        form={this.getFormModel() + "Form"}
                        type="submit"
                        model={this.getFormModel()}
                        disabled={{valid: false}}
                        className={"btn btn-primary mr-2"}
                      >
                        <TT>Save</TT>
                      </Control.button>

                      <button
                        onClick={this.handleClose}
                        type="button"
                        className="btn btn-outline-secondary"
                      >
                        <TT>Cancel</TT>
                      </button>
                    </Col>
                  </Row>}
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col md={5}>
            <Card className="small-hr-margin">
              <CardHeader style={{'height': '45px'}}>
                <Row>
                  <Col className="d-flex justify-content-between align-items-center">
                    <span><TT>Information</TT></span>

                    { this.state.monitorLoading ? (
                      <Loading size="sm" />
                      ) : (
                        this.props.deviceStatus.connection === 'disconnected' ? null : (
                          <small className="text-muted"> {this.state.info?.time ? 'Updated at ' + moment(this.state.info.time).format('h:mm:ss a MMM DD, YYYY') : null}</small>
                        )
                      ) }
                    </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {!this.props.deviceStatus.isApproved ? (
                  <span><TT>The device must be approved to view this information</TT></span>
                ) : this.props.deviceStatus.connection === 'disconnected' ? (
                  <span><TT>Device must be connected to view this information</TT></span>
                ) : (
                  <>
                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>AP Status</TT>
                    </Label>
                    <Col md={5}>
                        {this.props.deviceStatus.connection === 'disconnected' ? null : (
                          <Badge
                          className="device-card-badge"
                          color={Object.keys(this.state.info).length === 0 ? "warning" : this.state.info.accessPointStatus && this.state.info.accessPointStatus === true ? "success" : "danger"}
                          >
                            { Object.keys(this.state.info).length === 0 ? <TT>Pending</TT> : this.state.info.accessPointStatus && this.state.info.accessPointStatus === true ? <TT>Running</TT> : <TT>Stopped</TT>}
                          </Badge>
                        )}
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row className="form-group mb-0">
                    <Label md={12} className="col-form-label-sm">
                      <b><TT>Clients</TT></b>
                    </Label>
                    <Col>
                      { this.state.info.clients ? (
                        <FwBootstrapTable
                          pagination={true}
                          data={this.state.info.clients}
                          noDataIndication={toTTString('No  Clients found')}
                          keyField="mac"
                          columns={[
                            {
                              text: <TT>Mac Address</TT>,
                              dataField: 'mac',
                              sort: false,
                              editable: false,
                            },
                            {
                              text: <TT>Signal</TT>,
                              dataField: 'signal',
                              sort: false,
                              editable: false
                            },
                            {
                              text: <TT>IP</TT>,
                              dataField: 'ip',
                              sort: false,
                              editable: false,
                            }
                          ]}
                          size="sm"
                          rowStyle={{ height: '10px'}}
                        />
                      ) : null }
                    </Col>
                  </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default ConfigureWifi;
