import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
import { isEmpty } from "../../utils/Validators"
const { baseUrl } = window.SERVER_CONFIG;

export const emptyPolicy = {
  name: "",
  description: "",
  applyOnWan: false,
  overrideDefaultRoute: true,
  rules: [{
    name: "Default",
    enabled: false,
    priority: 0,
    classification: {
      prefix: {
        ip: "0.0.0.0/0",
        ports: "",
        protocol: ""
      }
    },
    action: {
      links: [{
        pathlabels: [],
        order: "priority"
      }],
      order: "priority",
      fallback: "by-destination"
    }
  }]
};

export const MLpolicies = (
  state = {
    mlPolicy: {},
    mlPolicies: [],
    mlPoliciesMeta: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.ML_POLICIES_ADD_REQUEST:
      return { ...state };
    case Actions.ML_POLICIES_ADD_SUCCESS:
      return { ...state };
    case Actions.ML_POLICIES_ADD_FAILURE:
      return { ...state };
    case Actions.ML_POLICIES_UPD_REQUEST:
      return { ...state, mlPolicy: action.mlPolicy };
    case Actions.ML_POLICIES_UPD_SUCCESS:
      return { ...state };
    case Actions.ML_POLICIES_UPD_FAILURE:
      return { ...state };
    case Actions.ML_POLICIES_DEL_REQUEST:
      return { ...state, mlPolicy: action.mlPolicy };
    case Actions.ML_POLICIES_DEL_SUCCESS:
      return { ...state };
    case Actions.ML_POLICIES_DEL_FAILURE:
      return { ...state };
    case Actions.ML_POLICIES_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ML_POLICIES_GET_SUCCESS:
      return { ...state, mlPolicy: action.mlPolicy, isLoading: false };
    case Actions.ML_POLICIES_GET_FAILURE:
      return { ...state, mlPolicy: {}, isLoading: false };
    case Actions.ML_POLICIES_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ML_POLICIES_GETALL_SUCCESS:
      return { ...state, mlPolicies: action.mlPolicies, isLoading: false };
    case Actions.ML_POLICIES_GETALL_FAILURE:
      return { ...state, mlPolicies: [], isLoading: false };
    case Actions.ML_POLICIES_LIST_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ML_POLICIES_LIST_GET_SUCCESS:
      return { ...state, mlPolicies: action.mlPolicies, isLoading: false };
    case Actions.ML_POLICIES_LIST_GET_FAILURE:
      return { ...state, mlPolicies: [], isLoading: false };
    case Actions.ML_POLICIES_GET_META_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ML_POLICIES_GET_META_SUCCESS:
      return { ...state, mlPoliciesMeta: action.mlPoliciesMeta, isLoading: false };
    case Actions.ML_POLICIES_GET_META_FAILURE:
      return { ...state, mlPoliciesMeta: [], isLoading: false };
    default:
      return state;
  }
};

/* General functions */
const preparePolicy = (mlPolicy, appsMap) => {
  // Convert app name to app ID in all relevant rules
  const newPolicy = JSON.parse(JSON.stringify(mlPolicy));
  newPolicy.rules = mlPolicy.rules.map(rule => {
    const newRule = JSON.parse(JSON.stringify(rule));
    // const apps = this.state.applications;
    let { application } = newRule.classification;

    // Convert application name to ID
    if (application) {
      application.appId = "";
      if (!isEmpty(application.name)) {
        const { id } = appsMap.get(application.name);
        const { category, serviceClass, importance } = application;
        application = {
          appId: id || "",
          category,
          serviceClass,
          importance,
        };
      }
      newRule.classification.application = application;
    }
    return newRule;
  });
  return newPolicy;
}

/* Action Creators */
/**********************************************************************
 *   Add a multi link policy
 **********************************************************************/
export const requestAddMLPolicy = mlPolicy => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_ADD_REQUEST,
  });
};

export const receiveAddMLPolicy = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_ADD_SUCCESS,
    emptyPolicy: JSON.parse(JSON.stringify(emptyPolicy))
  });
  dispatch(mainRedirectTo("/pathselectionpolicies"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Path Selection policy " + response.name + " added successfully"
    })
  );
};

export const failureAddMLPolicy = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const addMLPolicy = (mlPolicy, appsMap, success_cb) => dispatch => {
  const newPolicy = preparePolicy(mlPolicy, appsMap);
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies",
      method: "POST",
      body: JSON.stringify(newPolicy),
      requestCB: () => {
        dispatch(requestAddMLPolicy(mlPolicy));
      },
      successCB: response => {
        dispatch(receiveAddMLPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddMLPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Update a multi link policy
 **********************************************************************/
export const requestUpdMLPolicy = mlPolicy => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_UPD_REQUEST,
    mlPolicy
  });
};

export const receiveUpdMLPolicy = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_UPD_SUCCESS,
  });
  const { message, name } = response;
  dispatch(
    alertMsgMessage({
      color: "success",
      text: `Path Selection policy "${name}" saved successfully ${message ? '. ' + message : ''}`
    })
  );
};

export const failureUpdMLPolicy = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const updMLPolicy = (mlPolicy, appsMap, success_cb) => dispatch => {
  const newPolicy = preparePolicy(mlPolicy, appsMap);
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies/" + newPolicy._id,
      method: "PUT",
      body: JSON.stringify(newPolicy),
      requestCB: () => {
        dispatch(requestUpdMLPolicy(mlPolicy));
      },
      successCB: response => {
        dispatch(receiveUpdMLPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdMLPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete a multi link policy
 **********************************************************************/
export const requestDelMLPolicy = mlPolicy => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_DEL_REQUEST,
    mlPolicy
  });
};

export const receiveDelMLPolicy = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_DEL_SUCCESS
  });

  const { name } = store.getState().mlpolicies.mlPolicy;
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Path Selection policy " + name + " deleted successfully"
    })
  );
};

export const failureDelMLPolicy = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_DEL_FAILURE
  });
};

export const delMLPolicy = (mlPolicy, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies/" + mlPolicy._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelMLPolicy(mlPolicy));
      },
      successCB: response => {
        dispatch(receiveDelMLPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelMLPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all multi link policies
 **********************************************************************/
export const requestGetAllMLPolicies = () => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GETALL_REQUEST
  });
};

export const receiveGetAllMLPolicies = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GETALL_SUCCESS,
    mlPolicies: response
  });
};

export const failureGetAllMLPolicies = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GETALL_FAILURE
  });
};

export const getAllMLPolicies = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllMLPolicies());
      },
      successCB: response => {
        dispatch(receiveGetAllMLPolicies(response));
        success_cb(response)
      },
      failureCB: error => {
        dispatch(failureGetAllMLPolicies(error));
      }
    })
  );
};

/**********************************************************************
 *   Get a list of all multi link policies
 **********************************************************************/
export const requestGetMLPoliciesList = () => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_LIST_GET_REQUEST
  });
};

export const receiveGetMLPoliciesList = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_LIST_GET_SUCCESS,
    mlPolicy: response
  });
};

export const failureGetMLPoliciesList = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_LIST_GET_FAILURE
  });
};

export const getMLPoliciesList = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies/list",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetMLPoliciesList());
      },
      successCB: response => {
        dispatch(receiveGetMLPoliciesList(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetMLPoliciesList(error));
      }
    })
  );
};


/**********************************************************************
 *   Get a multi link policy
 **********************************************************************/
export const requestGetMLPolicy = () => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GET_REQUEST
  });
};

export const receiveGetMLPolicy = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GET_SUCCESS,
    mlPolicy: response
  });
};

export const failureGetMLPolicy = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GET_FAILURE
  });
};

export const getMLPolicy = (mlPolicyId, success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies/" + mlPolicyId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetMLPolicy());
      },
      successCB: response => {
        dispatch(receiveGetMLPolicy(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetMLPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Get multi link policies meta data
 **********************************************************************/
export const requestGetMLPoliciesMetaData = () => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GET_META_REQUEST
  });
};

export const receiveGetMLPoliciesMetaData = response => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GET_META_SUCCESS,
    mlPoliciesMeta: response
  });
};

export const failureGetMLPoliciesMetaData = error => dispatch => {
  dispatch({
    type: Actions.ML_POLICIES_GET_META_FAILURE
  });
};

export const getMLPoliciesMetaData = (success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "mlpolicies/meta",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetMLPoliciesMetaData());
      },
      successCB: response => {
        dispatch(receiveGetMLPoliciesMetaData(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetMLPoliciesMetaData(error));
      }
    })
  );
};
