import React from "react";
import { useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Label
} from "reactstrap";
import { Control, Form, Errors, actions } from "react-redux-form";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import {
  maxLength,
  isIP4WithMask,
  isValidPrefixLength,
  required
} from "../../utils/Validators";
import { getStartEndIPv4 } from '../../utils/ipCalculator';
import { FWTextInput } from '../Common';
import { TT, LanguageContext } from '../../containers/Language'
import { selectInterfacesConvert } from '../../utils/DevicesUtils';

const IpAddressWithMask = ({ model, label, validators={}, messages }) => {
  const { toTTString } = useContext(LanguageContext);
  return <>
    <Row className="form-group">
      <Label htmlFor="ip" md={4} className="col-form-label-sm">
        <TT>{label}</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={model}
          name={model}
          id={model}
          placeholder={toTTString("IP/mask")}
          component={FWTextInput}
          withFieldValue
          validators={{
            ...validators,
            isIP4WithMask,
            isValidPrefixLength
          }}
        />
        <Errors
          className="text-danger"
          model={model}
          show="touched"
          messages={{
            ...messages,
            isIP4WithMask: toTTString("Invalid IP Network Format"),
            isValidPrefixLength: toTTString("Invalid prefix for given mask")
          }}
        />
      </Col>
    </Row>
  </>
}

const DestinationCard = () => {
  const { toTTString } = useContext(LanguageContext);
  return (
<Card>
  <CardHeader>
    <Row>
      <Label htmlFor="destinationType" md={4} className="col-form-label-sm font-weight-bold">
        <TT>Destination</TT>
      </Label>
    </Row>
  </CardHeader>
  <CardBody>
    <Row className="form-group">
      <Col>
        <IpAddressWithMask
          label={'Match IP/Mask'}
          model='.classification.destination.lanNat.match'
          messages={{
            samePrefixDestination: toTTString("The prefix length of Destination Match and Action has to be the same")
          }}
        />
      </Col>
    </Row>
    <Row className="form-group">
      <Col>
        <IpAddressWithMask
          label={'Action IP/Mask'}
          model='.classification.destination.lanNat.action'
          messages={{
            samePrefixDestination: toTTString("The prefix length of Destination Match and Action has to be the same")
          }}
        />
      </Col>
    </Row>
  </CardBody>
</Card>
)}

const SourceCard = () => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <Card className='mb-2'>
      <CardHeader>
        <Row>
          <Label htmlFor="sourceType" md={4} className="col-form-label-sm font-weight-bold">
            <TT>Source</TT>
          </Label>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="form-group">
          <Col>
            <IpAddressWithMask
              label={'Match IP/Mask'}
              model='.classification.source.lanNat.match'
              validators={{
                required: required
              }}
              messages={{
                required: toTTString("Required field"),
                samePrefixSource: toTTString("The prefix length of Source Match and Action has to be the same")
              }}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col>
            <IpAddressWithMask
              label={'Action IP/Mask'}
              model='.classification.source.lanNat.action'
              validators={{
                required: required
              }}
              messages={{
                required: toTTString("Required field"),
                samePrefixSource: toTTString("The prefix length of Source Match and Action has to be the same")
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const LanNatRuleModal = (props) => {
  const { toTTString } = useContext(LanguageContext);
  const { enabled, isNewRule } = props.firewallRule;
  const lanInterfaces = {};
  for (const ifc of props.interfaces) {
    if (ifc.isAssigned === 'Yes' && ifc.type === 'LAN') {
      lanInterfaces[ifc.devId] = selectInterfacesConvert(ifc)
    }
  }
  return (
  <Modal
    size='xl'
    isOpen={props.isOpen}
  >
    <ModalHeader toggle={props.toggle}>
      <TT>LAN NAT rule</TT>
    </ModalHeader>
    <ModalBody>
      <Card>
        <CardBody>
          <Form
            model="firewallRule"
            validators={{
              '': {
                samePrefixDestination: vals => {
                  const { match, action } = vals.classification?.destination?.lanNat || {};
                  const [, matchMask] = match ? match.split('/') : ['', ''];
                  const [, actionMask] = action ? action.split('/') : ['', ''];
                  if ((matchMask || actionMask) && actionMask !== matchMask) {
                    return false;
                  }
                  return true;
                },
                samePrefixSource: vals => {
                  const { match, action } = vals.classification?.source?.lanNat || {};
                  const [, matchMask] = match ? match.split('/') : ['', ''];
                  const [, actionMask] = action ? action.split('/') : ['', ''];
                  if ((matchMask || actionMask) && actionMask !== matchMask) {
                    return false;
                  }
                  return true;
                }
              }
            }}
            onSubmit={result => {
              props.saveRule(result)
              props.toggle();
            }}
          >
            <Row>
              <Col>
                <Row className="form-group">
                  <Col md={6}>
                    <Card className='border-0'>
                      <CardBody className='pb-0'>
                        <Row className="form-group">
                          <Label htmlFor="action" md={4} className="col-form-label-sm font-weight-bold">
                            {enabled ? <TT>Enabled</TT> : <TT>Disabled</TT>}
                          </Label>
                          <Col>
                            <Label className="FWswitch" data-tip data-for='ruleEnableTip' >
                              <Control.checkbox
                                model=".enabled"
                                id="ruleEnabled"
                                name="enabled"
                              />{" "}
                              <span className="FWslider round" />
                            </Label>
                            <ReactTooltip id='ruleEnableTip'>
                              <span style={{"fontSize": "0.8rem"}}>
                                {enabled ? <TT>Disable the rule</TT> : <TT>Enable the rule</TT>}
                              </span>
                            </ReactTooltip>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Label md={4} className="col-form-label-sm">
                            <TT>LAN Interface</TT>
                          </Label>
                          <Col>
                          <Control
                              component={Select}
                              options={Object.values(lanInterfaces)}
                              isMulti={false}
                              value={lanInterfaces[props.firewallRule.classification.source.lanNat.interface]}
                              validators={{
                                required: required
                              }}
                              changeAction={(model, value) => dispatch => {
                                const lanIPv4 = lanInterfaces[value?.value]?.IPv4;
                                if (lanIPv4) {
                                  const [ip, mask] = lanIPv4.split('/');
                                  const sourceMatchValue = getStartEndIPv4(ip, mask, 0)[0] + '/' + mask;
                                  const sourceMatchModel = 'firewallRule.classification.source.lanNat.match';
                                  dispatch(actions.change(sourceMatchModel, sourceMatchValue));
                                }
                                return dispatch(actions.change(model, value?.value))
                              }}
                              model=".classification.source.lanNat.interface"
                              name="classification.source.lanNat.interface"
                              id="classification.source.lanNat.interface"
                              placeholder={toTTString("Select LAN interface")}
                            />
                            <Errors
                              className="text-danger"
                              model=".classification.source.lanNat.interface"
                              show="touched"
                              messages={{
                                required: toTTString("Required field")
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className='border-0'>
                      <CardBody className='pb-0'>
                        <Row>
                          <Label htmlFor="ruleDescription" md={4} className="col-form-label-sm font-weight-bold">
                            <TT>Description</TT>
                          </Label>
                          <Col>
                            <Control.textarea
                              model=".description"
                              id="ruleDescription"
                              name="description"
                              className="form-control"
                              placeholder={toTTString("Rule Description")}
                              validators={{
                                maxLength: maxLength(100)
                              }}
                            />
                            <Errors
                              className="text-danger"
                              model=".name"
                              show="touched"
                              messages={{
                                maxLength: toTTString("Rule description length must be at most 100")
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="form-group">
              <Col>
                <DestinationCard />
              </Col>
              <Col>
                <SourceCard />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <Errors
                  className="text-danger"
                  model='firewallRule'
                  show="touched"
                  messages={{
                    samePrefixDestination: toTTString("The prefix length of Destination Match and Action has to be the same"),
                    samePrefixSource: toTTString("The prefix length of Source Match and Action has to be the same")
                  }}
                />
              </Col>
              <Col className="text-right align-bottom">
                <Control.button
                  model="updateRule"
                  disabled={{ valid: false }}
                  className="btn btn-primary"
                >
                  {isNewRule ? toTTString('Add Rule') : toTTString('Update Rule')}
                </Control.button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </ModalBody>
  </Modal>
  )};

  export default LanNatRuleModal;