import * as Action from '../Actions';

export const Main = (state={sideBarCollapsed:false, isLoading:false, redirectTo: null}, action) => {
  switch (action.type) {
    case Action.MAIN_SIDEBAR_TOGGLE:
      if (action.isAuthenticated)
        return {...state, sideBarCollapsed:!state.sideBarCollapsed};
      else
        return {...state, sideBarCollapsed:false};

    case Action.MAIN_REDIRECT_TO:
      return {...state, redirectTo:action.redirectTo};

    case Action.MAIN_TOPBAR_LOADING:
      return {...state, isLoading:action.isLoading};

    default:
      return state;
  }
};

/* Action creators */
export const mainSidebarToggle = (isAuthenticated) => ({
  type: Action.MAIN_SIDEBAR_TOGGLE,
  isAuthenticated: isAuthenticated
});

export const mainRedirectTo = (redirectTo) => (dispatch) => {
  dispatch({
    type: Action.MAIN_REDIRECT_TO,
    redirectTo: redirectTo
  });
  // Remvoe redirection
  setTimeout(function(){
    dispatch({
      type: Action.MAIN_REDIRECT_TO,
      redirectTo: null
    });
  }, 200);
};

export const mainTopbarLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: Action.MAIN_TOPBAR_LOADING,
    isLoading: isLoading
  });
};
