import React, { useContext } from "react";
import { Type } from "react-bootstrap-table2-editor";
import { Control } from 'react-redux-form';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from "../../App";
import { FWArrayEditableTable } from "../Common";
import { TT, LanguageContext } from '../../containers/Language'
import {
  vlanTagValidator,
  DHCPFieldValidator,
  IPv4FieldValidator,
  PublicIPValidator,
  isOverlap
} from "../../utils/Validators";

const VlansTable = (props) => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <Control
      model=".vlans"
      id="vlans"
      name="vlans"
      classes="table-compact"
      component={FWArrayEditableTable}
      scrollXClass
      form="updateInterface"
      hideAddButton={props.hideAddButton}
      withFieldValue
      showRemove={false}
      rowStyle={row => {
        if (row.isAssigned !== 'Yes') {
          return { color : 'grey' };
        }
      }}
      columns={[
        { dataField: "_id", hidden: true },
        { dataField: "devId", hidden: true },
        { dataField: "isAssigned", hidden: true },
        { dataField: "lockReasons", hidden: true },
        {
          text: toTTString("Tag"),
          dataField: "tag",
          sort: false,
          editable: (cellContent, row) => !row.lockReasons?.hasTunnel && !row.lockReasons?.locked,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "bottom", minWidth: '70px' };
          },
          formatter: (cellContent, row) => {
            if (!row.lockReasons?.hasTunnel && !row.lockReasons?.locked) {
              return cellContent;
            };
            return (
              <div data-tip data-for={"vlan-tag-" + row._id}>
                {cellContent}
                <ReactTooltip id={"vlan-tag-" + row._id}>
                  {[row.lockReasons?.locked, row.lockReasons?.hasTunnel]
                    .filter(v => v).map(toTTString).join('. ')}
                </ReactTooltip>
              </div>
            )
          },
          validator: vlanTagValidator
        },
        {
          text: toTTString("Type"),
          dataField: "type",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "120px" };
          },
          editable: (value, row) => row.isAssigned === 'Yes',
          editor: {
            type: Type.SELECT,
            options: ['LAN', 'WAN'].map(t => ({ value: t, label: toTTString(t)}))
          }
        },
        {
          text: toTTString("DHCP/Static"),
          dataField: "dhcp",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "145px" };
          },
          editable: (value, row) => row.isAssigned === 'Yes',
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
          }},
          formatter: (val, row) => {
            return <>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  {val === "yes" ? toTTString("DHCP") : toTTString("Static")}
                </div>
              </div>
            </>
          },
          editor: {
            type: Type.SELECT,
            options: [
              { value: "no", label: toTTString("Static") },
              { value: "yes", label: toTTString("DHCP") }
            ]
          },
          validator: (newVal, row) => DHCPFieldValidator(newVal === 'yes', row, props.dhcp)
        },
        {
          text: toTTString("IPv4"),
          dataField: "IPv4",
          sort: true,
          editable: (value, row) => row.isAssigned === 'Yes' && row.dhcp !== 'yes',
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
          }},
          formatter: (cellContent, row, rowIndex) => {
            if (!row.IPv4 && row.dhcp === 'yes') {
              return toTTString('n/a');
            };
            return row.IPv4;
          },
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "200px" };
          },
          validator: (newValue, row, column) => {
            const isValid = IPv4FieldValidator(newValue, row, column);
            if (isValid !== true) {
              return isValid;
            }

            const dhcpEntry = props.dhcp.find(d => d.interface === row.devId);
            if (!dhcpEntry) {
              return true;
            };

            if (!isOverlap(newValue, dhcpEntry.rangeStart + '/32')) {
              return { valid: false, message: 'DHCP server range on this interface is not matched'}
            }
            return true;
          }
        },
        {
          text: toTTString("GW"),
          dataField: "gateway",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "top", minWidth: "145px" };
          },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
          }},
          editable: (value, row) => row.isAssigned === 'Yes' && row.dhcp !== 'yes' && row.type === 'WAN',
          validator: PublicIPValidator
        },
        {
          text: toTTString("Actions"),
          dataField: 'actions',
          sort: false,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "left", verticalAlign: "bottom" };
          },
          formatter: (content, row, rowIndex, formatExtraData) => {
            return (
              <span data-tip data-for={"delete-vlan" + row._id}>
                <Button
                  color="danger"
                  type="button"
                  style={{ marginTop: '5px' }}
                  className="action-btn"
                  size="sm"
                  disabled={row.lockReasons && Object.keys(row.lockReasons).length > 0}
                  onClick={() => {
                    const { updateInterface } = store.getState();
                    const vlans = Array.from(updateInterface.vlans);
                    vlans.splice(rowIndex, 1);
                    props.changeForm('updateInterface')({ ...updateInterface, vlans });
                  }}
                >
                  <FontAwesomeIcon icon="trash-alt" fixedWidth />
                  <ReactTooltip id={"delete-vlan" + row._id}>
                    <span>
                    {row.lockReasons && Object.keys(row.lockReasons).length > 0
                      ? Object.values(row.lockReasons).map(toTTString).join('. ')
                      : <TT>Delete VLAN sub-interface</TT>
                    }
                    </span>
                  </ReactTooltip>
                </Button>
              </span>
            )
          },
        },
      ]}
      onStartEdit={(oldValue, newValue, row, column) => {
        if (typeof props.onStartEdit === 'function') props.onStartEdit(oldValue, newValue, row, column);
      }}
      afterSaveCell={(oldValue, newValue, row, column) => {
        if (typeof props.afterSaveCell === 'function') props.afterSaveCell(oldValue, newValue, row, column);
        // Reset relevant fields upon changing the interface type
        if (column.dataField === 'type') {
          // set 'routing' field to 'None' if interface type is not 'LAN'
          row.routing = newValue !== 'LAN' ? 'None' : row.routing;
          // Clear WAN default GW when switching from WAN interface
          row.gateway = newValue === 'WAN' ? row.gateway : '';
          row.metric = newValue === 'WAN' ? row.metric : '';
        }
      }}
    />
  )
}

export default VlansTable;