import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import './Home.css';
import { TT, LanguageContext } from '../containers/Language';
import FWMap from './dashboards/FWMap';
import { NavLink } from 'react-router-dom';
import { StatusSteps, StatusStep } from './items/StatusSteps';
import { loadLocalSettings, saveLocalSettings } from '../utils/localSettings';
import { rateConversion } from '../utils/Conversions';
class Home extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);

    this.readTimer = null;

    this.getOrganizationInfo = this.getOrganizationInfo.bind(this);
  }

  componentDidMount() {
    this.getOrganizationInfo();
    if (this.readTimer === null) {
      const timer = setInterval(function(that) {
          // Only update when in focus
          if (document.hasFocus()) {
            that.getOrganizationInfo();
          }
      },60000, this);
      this.readTimer = timer;
    }
    window.addEventListener('focus', this.getOrganizationInfo);
  }

  componentWillUnmount() {
    this.props.clearDevices();
    this.props.clearTunnels();
    if (this.readTimer != null) {
      clearInterval(this.readTimer);
      this.readTimer = null;
    }
    window.removeEventListener("focus", this.getOrganizationInfo);
  }

  componentDidUpdate(prevProps) {
    const redirectedToHome = (prevProps.main.redirectTo !== this.props.main.redirectTo)
      && this.props.main.redirectTo === '/home';
    if (redirectedToHome) this.getOrganizationInfo();
  }

  getOrganizationInfo() {
    this.props.getAllDevices({ response: 'summary' });
    this.props.getAllTunnels({ response: 'summary' });
    this.props.getOrganizationSummary();
  }

  filterAndRedirect(page, setFilters, redirectTo) {
    return () => {
      const { filters, ...otherParams } = loadLocalSettings(page);
      saveLocalSettings(page, { filters:setFilters, otherParams });
      this.props.mainRedirectTo(redirectTo);
    }
  }

  render() {
    const toTTString = this.context.toTTString;
    const summary = this.props.organizations.summary;
    return(
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem active><TT>Home</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Home</TT></h4>
        <Row>
          <Col>
            <Card style={{width:'80%', marginBottom:'10px'}}>
            <CardHeader style={{padding: '0.1rem 0.5rem'}}>
                <TT>Network Status</TT>
            </CardHeader>
              <CardBody style={{padding: '0.5rem'}}>
                <Row>
                  <Col md={1}>
                    <span style={{bottom:'0.8rem', position:'absolute'}}><TT>Devices</TT></span>
                  </Col>
                  <Col md={11}>
                    <StatusSteps style={{float:'left'}}>
                      <StatusStep title={toTTString("Running")} color='success'
                        click={this.filterAndRedirect(
                          'devices',
                          [{"category":"Device Status","categorykey":"deviceStatus.state","operator":"==","value":"running"}],
                          '/devices'
                          )}>
                        {summary.devices?.running}
                      </StatusStep>
                      <StatusStep title={toTTString("Not Running/NA")} color='danger'
                        click={this.filterAndRedirect(
                          'devices',
                          [{"category":"Device Status","categorykey":"deviceStatus.state","operator":"!=","value":"running"}],
                          '/devices'
                          )}>
                        {summary.devices?.total - summary.devices?.running}
                      </StatusStep>
                    </StatusSteps>
                    <StatusSteps style={{float:'left'}}>
                      <StatusStep title={toTTString("Connected")} color='success'
                        click={this.filterAndRedirect(
                          'devices',
                          [{"category":"Connection","categorykey":"isConnected","operator":"==","value":"Connected"}],
                          '/devices'
                          )}>
                        {summary.devices?.connected}
                      </StatusStep>
                      <StatusStep title={toTTString("Not Connected")} color='danger'
                        click={this.filterAndRedirect(
                          'devices',
                          [{"category":"Connection","categorykey":"isConnected","operator":"!=","value":"Connected"}],
                          '/devices'
                          )}>
                        {summary.devices?.total - summary.devices?.connected}
                      </StatusStep>
                    </StatusSteps>
                    <StatusSteps style={{float:'left'}}>
                      <StatusStep title={toTTString("Not Approved")} color='secondary'
                        click={this.filterAndRedirect(
                          'devices',
                          [{"category":"Device Approval","categorykey":"isApproved","operator":"==","value":"Not Approved"}],
                          '/devices'
                          )}>
                        {summary.devices?.total - summary.devices?.approved}
                      </StatusStep>
                    </StatusSteps>
                    <StatusSteps style={{float:'left'}}>
                      <StatusStep title={toTTString("Warning")} color='warning'>
                        {summary.devices?.warning}
                      </StatusStep>
                    </StatusSteps>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={1}>
                    <span style={{bottom:'0.8rem', position:'absolute'}}><TT>Tunnels</TT></span>
                  </Col>
                  <Col md={11}>
                    <StatusSteps style={{float:'left'}}>
                      <StatusStep title={toTTString("Connected")} color='success'
                        click={this.filterAndRedirect(
                          'tunnels',
                          [{category: "Status", categorykey: "tunnelStatus", operator: "==", value: "Connected"}],
                          '/tunnels'
                          )}>
                        {summary.tunnels?.connected}
                      </StatusStep>
                      <StatusStep title={toTTString("Not Connected")} color='danger'
                        click={this.filterAndRedirect(
                          'tunnels',
                          [{category: "Status", categorykey: "tunnelStatus", operator: "==", value: "Not Connected"}],
                          '/tunnels'
                          )}>
                        {summary.tunnels?.total
                        -summary.tunnels?.unknown
                        -summary.tunnels?.connected
                        -summary.tunnels?.warning}
                      </StatusStep>
                      <StatusStep
                        title={toTTString("Pending")}
                        color='warning'
                        click={this.filterAndRedirect(
                          'tunnels',
                          [{category: "Status", categorykey: "tunnelStatus", operator: "==", value: "Pending"}],
                          '/tunnels'
                          )}>
                        {summary.tunnels?.warning}
                      </StatusStep>
                      <StatusStep
                        title={toTTString("Unknown")}
                        color='secondary'
                        click={this.filterAndRedirect(
                          'tunnels',
                          [{category: "Status", categorykey: "tunnelStatus", operator: "==", value: "N/A"}],
                          '/tunnels'
                          )}>
                        {summary.tunnels?.unknown}
                      </StatusStep>
                    </StatusSteps>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{width:'80%', marginBottom:'10px'}}>
              <CardHeader style={{padding: '0.1rem 0.5rem'}}>
                  <TT>Traffic this month</TT>
              </CardHeader>
              <CardBody style={{padding: '0.5rem'}}>
                <Row>
                  <Col md={1}>
                    <span><TT>Total</TT></span>
                  </Col>
                  <Col md={9}>
                    <div>
                      {(summary.traffic?.[0]?.bytes)
                        ?rateConversion(summary.traffic?.[0]?.bytes, 2) + 'Bytes'
                        :'0 B'
                      }
                      <NavLink style={{color: '#41a393', fontStyle: 'italic', marginLeft:'20px'}} to="/traffic"><TT>Check traffic dashboard for more</TT></NavLink>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{width:'80%', marginBottom:'10px'}}>
              <CardHeader style={{padding: '0.1rem 0.5rem'}}>
                  <TT>World Map</TT>
                  <NavLink style={{color: '#41a393', fontStyle: 'italic', marginLeft:'20px'}} to="/networkmap"><TT>Larger map in World Map dashboard</TT></NavLink>
              </CardHeader>
              <CardBody style={{padding: '0.5rem'}}>
                <Row>
                  <FWMap
                    style={{minWidth:'98%', marginLeft:'1%'}}
                    devices={this.props.devices.devices}
                    tunnels={this.props.tunnels.tunnels}
                    updCoords={this.props.updCoords}
                    checkBounds={false}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
      );
    }
}
export default Home;