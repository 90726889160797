import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button, Modal, ModalHeader, ModalBody, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApplicationTable from "./ApplicationsTable";
import { TT } from '../../containers/Language'
import ApplicationsTableFilter from "./ApplicationsTableFilter";

class MyApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      isUpgradeModalOpen: false,
      selectedDeleteApp: {},
      selectedUpgradeApp: {}
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpgrade = this.handleUpgrade.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.toggleUpgradeModal = this.toggleUpgradeModal.bind(this);
    this.approveUpgrade = this.approveUpgrade.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData()
  }

  getData(filters) {
    this.props.getPurchasedApplications(null, filters);
  }

  handleDelete(row) {
    this.setState({ selectedDeleteApp: row });
    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  }

  handleUpgrade(row) {
    this.setState({ selectedUpgradeApp: row });
    this.toggleUpgradeModal();
  }

  toggleUpgradeModal() {
    this.setState({
      isUpgradeModalOpen: !this.state.isUpgradeModalOpen,
    });
  }

  approveDelete() {
    this.props.removeApplication(this.state.selectedDeleteApp);
    this.toggleDeleteModal();
  }

  approveUpgrade() {
    this.props.upgradeApplicationOnDevice(this.state.selectedUpgradeApp);
    this.toggleUpgradeModal();
  }

  render() {

    let upgradeAlert = null;
    const needsToUpgrade = this.props.purchasedApplications.filter(a => a.pendingToUpgrade);
    if (needsToUpgrade.length) {
      upgradeAlert = (
        <div
          className="alert alert-warning col-md-12"
          style={{
            marginTop: '1rem',
            paddingBottom: '1.2rem',
            paddingTop: '1rem',
          }}
        >
          <FontAwesomeIcon
            icon="exclamation-triangle"
            size="1x"
            fixedWidth
            style={{ paddingRight: '3px' }}
            color="#ebbc02"
          />
            <TT>Application{needsToUpgrade.length > 1 ? 's' : ''}</TT> {needsToUpgrade.map(app => app.appStoreApp.name).join(', ')} <TT>is not updated, click to update</TT>
        </div>
      )
    }

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Applications</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Installed</TT></BreadcrumbItem>
        </Breadcrumb>

        <div className="container">
          <h4><TT>Installed Applications</TT></h4>
          {this.props.isLoading ? <div className="signal"></div> : null}
        </div>

        <div className="col-md-12">

          <ApplicationsTableFilter refreshHandler={this.getData} keyName="appStoreApp.name" />

          {upgradeAlert}

          <ApplicationTable
            type="purchased"
            apps={this.props.purchasedApplications.map(p => {
              return {
                ...p.appStoreApp,
                installedVersion: p.installedVersion,
                _id: p._id,
                statuses: p.statuses
              }
            })}
            noDataIndication={
              <span>
                <TT>No Applications Installed. Click on</TT>
                <Link exact to='/applications/available'>
                  <b> <TT>App Store</TT> </b>
                </Link>
                <TT>and purchase apps</TT>
              </span>
            }
            actions={(row) => (
              <div>
                <Link to={`/applications/${row._id}/configurations`}>
                  <Button
                    color="warning"
                    className="action-btn"
                    data-tip
                    data-for="configure-a"
                    size="sm"
                  >
                    <FontAwesomeIcon icon="cog" fixedWidth />
                  </Button>
                </Link>
                <ReactTooltip id="configure-a">
                  <span><TT>Configure application</TT></span>
                </ReactTooltip>

                <Link to={`/applications/${row._id}/status`}>
                  <Button
                    color="info"
                    className="action-btn"
                    data-tip
                    data-for="show-a"
                    size="sm"
                  >
                    <FontAwesomeIcon icon="info" fixedWidth />
                  </Button>
                </Link>
                <ReactTooltip id="show-a">
                  <span><TT>Application usage data</TT></span>
                </ReactTooltip>

                <Link to={`/devices?application=${row.name}`}>
                  <Button
                    className="action-btn"
                    data-tip
                    data-for="targets-a"
                    color="success"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={["fas", "filter"]} fixedWidth/>
                  </Button>
                  <ReactTooltip id="targets-a">
                    <span><TT>Show all devices running this application</TT></span>
                  </ReactTooltip>
                </Link>

                {needsToUpgrade.length ? (
                  <React.Fragment>
                    <Button
                      color="info"
                      className="action-btn"
                      data-tip
                      data-for="upgrade-a"
                      size="sm"
                      onClick={() => this.handleUpgrade(row)}
                    >
                      <FontAwesomeIcon icon="sync-alt" fixedWidth />
                    </Button>
                    <ReactTooltip id="upgrade-a">
                      <span><TT>Upgrade App</TT></span>
                    </ReactTooltip>
                  </React.Fragment>
                ) : null}

                <Button
                  color="danger"
                  className="action-btn"
                  data-tip
                  data-for="delete-a"
                  size="sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <FontAwesomeIcon icon="trash-alt" fixedWidth />
                </Button>
                <ReactTooltip id="delete-a">
                  <span><TT>Remove app</TT></span>
                </ReactTooltip>
              </div>
            )}
          />
        </div>

        <Modal isOpen={this.state.isDeleteModalOpen} toggle={this.toggleDeleteModal}>
          <ModalHeader toggle={this.toggleModal}>
            <TT>Remove</TT> <b>{this.state.selectedDeleteApp.name}</b>?
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>
                Are you sure you want to remove this app from your organization?
                This operation will uninstall the app from all the devices
              </TT>
            </div>
            <Button color="danger" onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="default"
              onClick={this.toggleDeleteModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.isUpgradeModalOpen} toggle={this.toggleUpgradeModal}>
          <ModalHeader toggle={this.toggleUpgradeModal}>
            <TT>Upgrade</TT> <b>{this.state.selectedUpgradeApp.name}</b>?
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>Are you sure you want to upgrade this app?</TT>
            </div>
            <Button color="success" onClick={this.approveUpgrade}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="default"
              onClick={this.toggleUpgradeModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default MyApplications;
