import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DeviceInfo.css';
import { TT, LanguageContext } from '../../containers/Language';

class RoutingFilters extends Component {
  static contextType = LanguageContext;

  addUpdateRoutingFilter = (id) => {
    let search = '?tab=RoutingFilter';
    if (id) {
      search += `&id=${id}`
    }
    this.props.history.push({ search })
  }

  handleDeleteRoutingFilter = row => {
    const filters = [...this.props.routingFilters];
    const index = filters.findIndex(n => {
      return n._id === row._id;
    });

    if (index > -1) {
      filters.splice(index, 1);
      this.props.saveRoutingFilters(filters)
    }
  }

  render() {
    const toTTString = this.context.toTTString;
    return (
      <React.Fragment>
        <h6><TT>Routing Filters</TT></h6>
        <div className={"general-card-upper-panel d-flex justify-content-between"}>
          <Button
            className="btn btn-primary action-btn-top upper-panel-button"
            onClick={() => this.addUpdateRoutingFilter()}
          >
            <TT>Add Routing Filter</TT>
          </Button>
        </div>

        <Card id='RoutingFiltersCard'>
          <CardBody>
              <Row className="mb-2">
                <Col className="col-auto">
                  <TT>Routing Filters</TT>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapTable
                    keyField="_id"
                    data={this.props.routingFilters}
                    // expandRow={expandRow}
                    columns={[
                      {
                        text: toTTString("Name"),
                        dataField: "name",
                        headerStyle: (colum, colIndex) => {
                          return { textAlign: "left", verticalAlign: "top", width: '20%' };
                        },
                      },
                      {
                        text: toTTString("Description"),
                        dataField: "description",
                        headerStyle: (colum, colIndex) => {
                          return { textAlign: "left", verticalAlign: "top", width: '50%' };
                        },
                      },
                      {
                        text: toTTString("Actions"),
                        dataField: "none",
                        headerStyle: (colum, colIndex) => {
                          return { textAlign: "left", verticalAlign: "top", width: '15%' };
                        },
                        formatter: (cellContent, row, idx) => (
                          <>
                            <Button
                              color="warning"
                              className="action-btn"
                              data-tip
                              data-for={"setup-routing-filter-" + row._id}
                              size="sm"
                              onClick={() => this.addUpdateRoutingFilter(row._id)}
                            >
                              <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                              <ReactTooltip id={"setup-routing-filter-"+row._id}>
                                <span><TT>Update Routing Filter</TT></span>
                              </ReactTooltip>
                            </Button>

                            <Button
                              color="danger"
                              className="action-btn"
                              data-tip
                              data-for={"delete-routing-filter-" + row._id}
                              size="sm"
                              onClick={() => this.handleDeleteRoutingFilter(row)}
                            >
                              <FontAwesomeIcon icon="trash-alt" fixedWidth />
                            </Button>

                            <ReactTooltip id={"delete-routing-filter-" + row._id}>
                              <span><TT>Delete Routing Filter</TT></span>
                            </ReactTooltip>
                          </>
                        )
                      },
                    ]}
                    noDataIndication={toTTString("No Routing Filter configured")}
                    defaultSorted={[{ dataField: "name", order: "asc" }]}
                  />
                </Col>
              </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default RoutingFilters;
