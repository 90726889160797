import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = props => {
  return (
    <FontAwesomeIcon
      className="spinner-icon"
      icon="circle-notch"
      size={props.size}
      fixedWidth
      spin
      color={props.color || "#4c9c8f"}
    />
  )
}

export default Loading;