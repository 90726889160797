import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Card, CardBody } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { required } from '../../utils/Validators';
import { FWTextInput } from '../Common';
import { TT, LanguageContext  } from '../../containers/Language'

class AddCoupon extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    console.log('Adding Coupon: ' + JSON.stringify(values));
    this.props.addCoupon(values, this.props.resetForm);
  }

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem><Link to="/billing"><TT>Billing</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Add Coupon</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Add Coupon</TT></h4>
        <div className="col-md-9">
          <Card id='addCouponCard'>
            <CardBody>
              <Form model="addCoupon" onSubmit={(values) => this.handleSubmit(values)}>
                <Row className="form-group">
                  <Label htmlFor="name" md={2}><TT>Coupon Code</TT></Label>
                  <Col md={9}>
                    <Control.text model=".name" id="name" name="name" placeholder={toTTString("Coupon Code")}
                      component={FWTextInput} withFieldValue
                      autoComplete={"off"}
                      validators={
                        {
                          required: required
                        }
                      }
                    />
                    <Errors className="text-danger" model=".name" show="touched"
                      messages={
                        {
                          required: toTTString('Required field'),
                          name: toTTString('Invalid coupon code format')
                        }
                      }
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={{ size: 10, offset: 2 }}>
                    <Control.button model="addCoupon" disabled={{ valid: false }}
                      className="btn btn-primary"><TT>Add</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCoupon;