import _uniqueId from 'lodash/uniqueId';

export const InitialDevice = {
  name: '',
  description: '',
  defaultGW: '',
  isApproved: false,
  cpuInfo: {},
  distro: '',
  hostname: '',
  serial: '',
  machineId: '',
  deviceVersion: '',
  deviceSpecificRulesEnabled: true
};

export const InitialStaticRoute = {
  destination: '',
  gateway: '',
  ifname: '',
  metric: '',
  redistributeViaOSPF: false,
  redistributeViaBGP: false,
  onLink: false,
  conditions: []
};

export const InitialDeviceMsg = {
  message: '',
  machineId: '',
  output: ''
};

export const InitialToken = {
  name: '',
  server: ''
};

export const InitialDHCP = {
  interface: '',
  rangeStart: '',
  rangeEnd: '',
  dns: '8.8.8.8, 8.8.4.4',
  macAssign: [],
  options: [],
  defaultLeaseTime: '',
  maxLeaseTime: ''
};


export const InitialCoupon = {
  name: ''
};

export const InitialUpdToken = {
  name: ''
};

export const InitialLogsCard = {
  limit: 100,
  filter: 'fwagent'
};

export const InitialPacketTracesCard = {
  packets: 10,
  timeout: '5'
};

export const InitialOrganization = {
  name: '',
  description: '',
  group: 'Default',
  encryptionMethod: 'psk',
  vxlanPort: '4789',
  tunnelRange: '10.100.0.0'
};

export const InitialUpdOrganization = {
  name: '',
  description: '',
  group: '',
  encryptionMethod: '',
};

export const InitialUserCreateAccount1 = {
  accountName: '',
  email: '',
  userFirstName: '',
  userLastName: '',
  userJobTitle: '',
  userPhoneNumber: '',
  password: ''
};

export const InitialUserCreateAccount2 = {
  country: '',
  companySize: '',
  serviceType: '',
  numberSites: '',
  acceptTerms: false,
  captcha: ''
};

export const InitialUpdateAccount = {
  accountName: '',
  country: '',
  forceMfa: false
};

export const InitialAddMember = {
  email: '',
  userFirstName: '',
  userLastName: '',
  userPermissionTo: 'account',
  userEntity: '',
  userRole: 'owner'
};

export const InitialAddAccessToken = {
  name: '',
  accessKeyPermissionTo: 'account',
  accessKeyEntity: '',
  accessKeyRole: 'owner',
  validityEntity: 'Default'
};

export const InitialUpdateMember = {
  _id: null,
  userId: '',
  userPermissionTo: '',
  userEntity: '',
  userRole: ''
};

export const InitialPathLabel = {
  name: '',
  description: '',
  color: '#44ad8e',
  type: false
};

export const InitialMLPolicy = {
  name: "",
  description: ""
}

export const InitialMlRule = {
  name: "",
  enabled: "enabled",
  classification: {
    prefix: {
      ip: "",
      ports: "",
      protocol: ""
    },
    application: {
      name: "",
      category: "",
      serviceClass: "",
      importance: ""
    }
  },
  action: {
    links: [{
      pathlabels: [],
      order: "priority"
    }],
    order: "priority",
    fallback: "by-destination"
  },
}

export const InitialFirewallPolicy = {
  name: "",
  description: ""
}

export const InitialFirewallRule = {
  description: "",
  enabled: true,
  direction: "inbound",
  inbound: "edgeAccess",
  classification:{
    destinationType: 'ipProtoPort',
    destination: {
      trafficId: {},
      trafficTags: {},
      ipProtoPort: {
        ip: '',
        protocols: [],
        ports: ''
      },
      lanNat: {}
    },
    sourceType: 'any',
    source: {
      trafficId: {},
      ipPort: {},
      lanNat: {}
    },
  },
  interfaces: [],
  internalIP: '',
  internalPortStart: '',
  action: 'allow'
}

export const InitialQOSPolicy = {
  name: "",
  description: "",
  advanced: false,
  outbound: {
    'realtime': {
      bandwidthLimitPercent: 30,
      dscpRewrite: 'CS0'
    },
    'control-signaling': {
      weight: 40,
      dscpRewrite: 'CS0'
    },
    'prime-select': {
      weight: 30,
      dscpRewrite: 'CS0'
    },
    'standard-select': {
      weight: 20,
      dscpRewrite: 'CS0'
    },
    'best-effort': {
      weight: 10,
      dscpRewrite: 'CS0'
    }
  },
  inbound: {
    bandwidthReservation: 100,
    policerBandwidthLimitPercent: {
      high: 100,
      medium: 80,
      low: 65
    }
  }
}

export const InitialAppIdentification = {
  name: '',
  group: 'Default'
};

export const InitialAddAppIdentification = {
  app: 'app',
  serviceClass: 'video'
};

export const InitialAddAppIdentificationRule = {
  ip: '',
  ports: '',
  protocol: ''
};

export const InitialRemoteVpnConfiguration = {
  networkId: '',
  routeAllTrafficOverVpn: false,
  serverPort: '1194',
  dnsIps: '8.8.8.8, 1.1.1.1',
  dnsDomains: '',
  authentications: {
    gsuite: {
      enabled: false,
      domains: []
    },
    office365: {
      enabled: false,
      domains: []
    },
    flexiManage: {
      enabled: false
    }
  }
}

export const InitialRemoteVpnDeviceConfiguration = {
  networkStartIp: '',
  connectionsNumber: '',
}

export const InitialConfigureLteInterface = {
  apn: '',
  enable: false,
  metric: null,
  pin: ''
}

const wifiSharedKeys = {
  enable: false,
  bandwidth: '20',
  encryption: 'aes-ccmp',
  channel: '0',
  region: '',
  securityMode: 'wpa2-psk',
  hideSsid: false
}

export const InitialConfigureWifi24 = {
  ...wifiSharedKeys,
  operationMode: 'g',
  region: 'DE',
  ssid: '',
  password: ''
}

export const InitialConfigureWifi5 = {
  ...wifiSharedKeys,
  operationMode: 'ac',
  region: 'DE',
  ssid: '',
  password: ''
}

export const InitialUpdatePin = {
  enable: false,
  currentPin: '',
  newPin: '',
  puk: ''
}

export const InitialInterface = {
  dnsDomains: [],
  dnsServers: [],
  vlans: [],
  bandwidthMbps: {},
  ospf: {} // In order to use `resetForm` functions we need to set this nested object
};

export const InitialDashboardStatsOptions = {
  device: 'All',
  interface: 'All',
  direction: 'RX+TX'
};

export const InitialOSPFConfiguration = {
  helloInterval: 10,
  deadInterval: 40,
  redistributeBgp: true,
  custom: ''
};

export const InitialAdvancedRoutingConfiguration = {
  custom: ''
};


export const InitialBGPConfiguration = {
  enable: false,
  routerId: '',
  localASN: '',
  keepaliveInterval: '',
  holdInterval: '',
  redistributeOspf: true,
  custom: ''
};

export const InitialBGPNeighbor = {
  ip: '',
  remoteASN: '',
  password: '',
  inboundFilter: '',
  outboundFilter: '',
  sendCommunity: 'all',
  multiHop: '1',
  custom: ''
}

export const InitialRoutingFilterRule = {
  route: '',
  action: 'allow',
  nextHop: '',
  custom: ''
};

export const InitialFrrConfig = {
  custom: ''
};


export const InitialRoutingFilter = {
  name: '',
  description: '',
  rules: [
    {
      ...InitialRoutingFilterRule,
      route: '0.0.0.0/0',
      priority: 0,
      _id: _uniqueId('routing_filter_tmp_id_')
    }
  ]
};

export const InitialPeer = {
  name: '',
  localId: '',
  // profile: '',
  idType: 'fqdn',
  remoteIdType: 'fqdn',
  remoteId: '',
  psk: '',
  remoteIP: '',
  urls: '',
  ips: '',
  ikeDhGroup: 'modp-2048',
  ikeCryptoAlg: 'aes-cbc',
  ikeKeySize: '256',
  ikeIntegAlg: 'hmac-sha2-256-128',
  espCryptoAlg: 'aes-cbc',
  espKeySize: '256',
  espIntegAlg: 'hmac-sha2-256-128',
  espDhGroup: 'ecp-256',
  pfs: false,
  ikeLifeTime: '86400', // phase 1 lifetime
  sessionLifeTime: '3600', // phase 2 lifetime
  localIpRangeStart: '0.0.0.0',
  localIpRangeEnd: '255.255.255.255',
  localPortRangeStart: '0',
  localPortRangeEnd: '65535',
  localProtocol: 'any',
  remoteIpRangeStart: '0.0.0.0',
  remoteIpRangeEnd: '255.255.255.255',
  remotePortRangeStart: '0',
  remotePortRangeEnd: '65535',
  remoteProtocol: 'any',
};

// MFA
export const initialMfaVerifyForm = {
  token: '',
}

export const initialUseRecoveryCode = {
  recoveryCode: '',
}

export const InitialHardwareConfiguration = {
  cpuInfo: {}
}

export const InitialStaticDns = {
  dnsServers: '',
  dnsDomains: '',
  useDhcpDnsServers: true,
};

export const InitialVrrpGroup = {
  name: '',
  virtualRouterId: '',
  virtualIp: '',
  preemption: true,
  acceptMode: false,
  devices: []
};

export const InitialVrrpGroupDevice = {
  device: '',
  interface: '',
  priority: '100',
  trackInterfacesOptional: [],
  trackInterfacesMandatory: [],
};

export const InitialDhcpOption = {
  name: '',
  value: '',
};

export const InitialModifyNotificationForm = {
  'warningThreshold': null,
  'criticalThreshold': null,
  'thresholdUnit': null,
  'severity': null,
  'immediateEmail': null,
  'resolvedAlert': null,
  'type': null,
  'sendWebHook': null
};

export const InitialModifyTunnelNotificationsForm = {
  'Link/Tunnel round trip time': {
    'warningThreshold': null,
    'criticalThreshold': null
  },
  'Link/Tunnel default drop rate': {
    'warningThreshold': null,
    'criticalThreshold': null
  },
}

export const InitialWebhookSettingsForm = {
  webhookURL: '',
  sendCriticalAlerts: false,
  sendWarningAlerts: true
}
