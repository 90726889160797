import React from "react";
import ReactTooltip from 'react-tooltip';

export default function ReactTooltipWithLink(props) {
  return (
    <>
      <ReactTooltip place={props.place || 'top'} id={props.id} globalEventOff='mouseout' className="react-tooltip-clickable-link">
        {props.children}
      </ReactTooltip>
      <span className="helpTooltip" data-delay-hide="80" data-tip data-for={props.id} />
    </>
  )
};