import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row, Col
} from "reactstrap";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { TT } from '../../../containers/Language'
import {
  loginUser,
} from "../../../redux/reducers/Auth";
const { captchaSiteKey, allowUsersRegistration, contactUsUrl } = window.SERVER_CONFIG;

class Login extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      captchaToken: ''
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  handleLogin(event) {
    this.props.loginUser({
      username: this.username.value,
      password: this.password.value,
      captcha: this.state.captchaToken
    },
    ()=> {  // failure callback
      if (captchaSiteKey !== "") this.recaptchaRef.current.reset();
    });
    event.preventDefault();
  }

  verifyCaptcha(token) {
    this.setState({captchaToken: token});
  }

  render() {
    return (
      <>
        <Form onSubmit={this.handleLogin}>
          <FormGroup>
            <Row>
              <Label htmlFor="username" md={3}><TT>Email</TT></Label>
              <Col md={9}>
                <Input
                  type="text"
                  id="username"
                  name="username"
                  innerRef={input => (this.username = input)}
                />
              </Col>
            </Row>
            <div className="card-field-link">
              <Link to="/verify-account"><TT>Resend verification email</TT></Link>
            </div>
          </FormGroup>
          <FormGroup>
            <Row>
              <Label htmlFor="password" md={3}><TT>Password</TT></Label>
              <Col md={9}>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  innerRef={input => (this.password = input)}
                />
              </Col>
            </Row>
            <div className="card-field-link">
              <Link to="/reset-password"><TT>Reset Password</TT></Link>
          </div>
          </FormGroup>
          {captchaSiteKey !== ''?
          <Row className="form-group">
            <Col md={12}>
              <ReCAPTCHA style={{'float':'right'}}
                ref={this.recaptchaRef}
                sitekey={captchaSiteKey}
                onChange={value => this.verifyCaptcha(value)}
              />
            </Col>
          </Row>:null}
          <Row className="form-group">
            <Col md={12}>
              <Button type="submit" value="submit" color="primary" style={{'float':'right', 'minWidth':'460px'}}>
                <TT>Login</TT>
              </Button>
            </Col>
          </Row>
        </Form>

        <hr />
        <div className="bottom-card-message">
          <div>
            {allowUsersRegistration ?
              <><TT>Don't have an account yet?</TT>
                <Link to="/create-account"> <TT>Create account</TT></Link>
              </> : ''}
            <span style={{'float':'right'}}><a href={contactUsUrl}><TT>Contact us</TT></a></span>
          </div>
          <div>
          </div>

        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (creds, failure_cb) => dispatch(loginUser(creds, failure_cb)),
  }
}

export default connect(null, mapDispatchToProps)(Login);
