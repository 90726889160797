import React, { Component } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import RemoteVpnDeviceStatus from './RemoteVpnAppStatus';
import { Card, CardBody, Row, Col } from "reactstrap";
import { TT, LanguageContext } from '../../../../containers/Language'
import ReactTooltipWithLink from '../../../items/ReactTooltipWithLink';

class RemoteVpnStatus extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.state = {
      status: {}
    }

    this.statusTimer = null;
  }

  componentDidMount = () => {
    this.props.getApplicationStatus(this.props.app, vpnStatus => {
      this.setState({status: vpnStatus});
    });

    if (this.statusTimer === null) {
      const timer = setInterval(that => {
        // Only update when in focus
        if (document.hasFocus()) {
          this.props.getApplicationStatus(this.props.app, vpnStatus => {
            this.setState({status: vpnStatus});
          });
        }
      }, 5000);
      this.statusTimer = timer;
    }
  }

  componentWillUnmount() {
    if (this.statusTimer != null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  getRunningServers = () => {
    const runningCount = (this.state.status?.devices ?? []).reduce((val, currentElement) => {
      if (currentElement?.monitoring?.running === true) {
        val = val + 1;
      }
      return val;
    }, 0);
    return runningCount;
  }

  getConnectedClients = () => {
    const  connectedCount = (this.state.status?.devices ?? []).reduce((val, currentElement) => {
      val += Object.keys(currentElement?.monitoring?.monitoring?.clients ?? {}).length;
      return val;
    }, 0);
    return connectedCount;
  }

  render() {
    const toTTString = this.context.toTTString;
    const appsColumns = [
      { text: "rowID", dataField: "_id", hidden: true },
      {
        text: toTTString("Device Name"),
        dataField: "name",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
      },
      {
        text: toTTString("App Status"),
        dataField: "monitoring.running",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatter: (cellContent, row, rowIndex) => {
          const running = <span className="text-success "><TT>Running</TT></span>;
          const stopped = <span className="text-danger "><TT>Stopped</TT></span>
          const vpnIsRunning = row?.monitoring?.running ?? false;
          return vpnIsRunning ? running : stopped
        },
      },
      {
        text: toTTString("Network/Netmask"),
        dataField: 'applications.configuration.subnet',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        }
      },
      {
        text: toTTString("Max Client Connections"),
        dataField: 'applications.configuration.connections',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        }
      },
      {
        text: toTTString("Connected Clients"),
        dataField: null,
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return Object.keys(row?.monitoring?.monitoring?.clients ?? {}).length;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          const aKeys = Object.keys(rowA?.monitoring?.monitoring?.clients ?? {}).length;
          const bKeys = Object.keys(rowB?.monitoring?.monitoring?.clients ?? {}).length;
          if (order === 'asc') return aKeys - bKeys;
          else return bKeys - aKeys;
        }
      }
    ];

    const expandRow = {
      expanded: (this.state.status?.devices ?? []).map(d => d._id),
      renderer: row => {
        const data = row?.monitoring ?? {};
        return <RemoteVpnDeviceStatus data={data} />;
      }
    };

    return (
      <React.Fragment>
        <Card id="remoteVpnCard">
          <CardBody>
            <Row>
              <Col>
                <Row>
                  <Col md={4}>
                    <Row>
                      <Col><TT>Installed devices</TT>: {this.state.status?.devices?.length ?? 0}</Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col><TT>Running servers</TT>: {this.getRunningServers()}</Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col><TT>Connected clients</TT>: {this.getConnectedClients()}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <Row>
                  <Col md={4}>
                    <Row>
                      <Col><TT>Allowed portal users</TT>: {this.state.status?.orgConnections}</Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col className="d-flex">
                        <TT>Actual portal users</TT>:
                        <div className='d-flex align-items-center'>
                          <span className='mr-1 ml-1'>
                            {this.state.status?.actualConnections?.length}
                          </span>
                          <ReactTooltipWithLink place='bottom' id="networkStartIpTip">
                            <span style={{ fontSize: "0.8rem" }}>
                              <b>Actual portal users in the current billing period</b><br />
                              {this.state.status?.actualConnections?.slice(0, 25).map(e => <>{e}<br/></>)}
                              {this.state.status?.actualConnections?.length > 25 ? '...' : null }
                            </span>
                          </ReactTooltipWithLink>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />

            <Row className="mt-2">
              <Col>
                <Row>
                  <Col>
                    <b><TT>Devices</TT></b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BootstrapTable
                      hover
                      keyField="_id"
                      rowStyle={{ cursor: 'pointer' }}
                      data={this.state.status?.devices ?? []}
                      columns={appsColumns}
                      noDataIndication={toTTString("No Devices installed this application")}
                      expandRow={expandRow}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default RemoteVpnStatus;
