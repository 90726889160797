import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Store } from './redux/Store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { LanguageProvider } from './containers/Language';
import backgroundImg from './assets/images/bg_logo.png';
import {
  faHome,
  faCog,
  faSignal,
  faAddressCard,
  faInfo,
  faDownload,
  faTrashAlt,
  faPencilAlt,
  faSignOutAlt,
  faSyncAlt,
  faPaperPlane,
  faCheckSquare,
  faPlay,
  faStop,
  faUndo,
  faCircleNotch,
  faGlasses,
  faFileAlt,
  faListUl,
  faExclamationTriangle,
  faUser,
  faNetworkWired,
  faArrowCircleLeft,
  faEye,
  faEyeSlash,
  faLayerGroup,
  faPlus,
  faTimes,
  faLevelUpAlt,
  faLevelDownAlt,
  faArrowUp,
  faArrowDown,
  faArrowCircleDown,
  faEllipsisV,
  faEllipsisH,
  faClone,
  faFilter,
  faRedo,
  faSearch,
  faSync,
  faClipboard,
  faList,
  faSortAmountUp,
  faSortAmountDown,
  faReceipt,
  faFileCsv,
  faBorderAll,
  faShieldAlt,
  faCheck,
  faCheckCircle,
  faStopCircle,
  faBan,
  faExchangeAlt,
  faRandom,
  faArrowsAlt,
  faGripVertical,
  faBezierCurve,
  faQrcode,
  faSitemap,
  faHashtag,
  faStream,
  faAsterisk,
  faWifi,
  faCaretDown,
  faCaretUp,
  faPuzzlePiece,
  faLanguage,
  faKey,
  faTable,
  faColumns,
  faBars,
  faProjectDiagram,
  faAt,
  faBolt,
  faEthernet,
  faBellSlash
} from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import Main from './components/Main';
import './App.css';

// add fontawesome icons to library
library.add(
  faHome,
  faCog,
  faSignal,
  faAddressCard,
  faInfo,
  faDownload,
  faTrashAlt,
  faPencilAlt,
  faSignOutAlt,
  faSyncAlt,
  faPaperPlane,
  faCheckSquare,
  faPlay,
  faStop,
  faUndo,
  faCircleNotch,
  faGlasses,
  faListAlt,
  faFileAlt,
  faListUl,
  faExclamationTriangle,
  faUser,
  faNetworkWired,
  faArrowCircleLeft,
  faEye,
  faEyeSlash,
  faLayerGroup,
  faPlus,
  faTimes,
  faLevelUpAlt,
  faLevelDownAlt,
  faArrowUp,
  faArrowDown,
  faArrowCircleDown,
  faEllipsisV,
  faEllipsisH,
  faClone,
  faFilter,
  faRedo,
  faSearch,
  faSync,
  faClipboard,
  faList,
  faSortAmountUp,
  faSortAmountDown,
  faReceipt,
  faFileCsv,
  faBorderAll,
  faShieldAlt,
  faCheck,
  faCheckCircle,
  faStopCircle,
  faBan,
  faExchangeAlt,
  faRandom,
  faArrowsAlt,
  faGripVertical,
  faBezierCurve,
  faQrcode,
  faSitemap,
  faHashtag,
  faStream,
  faAsterisk,
  faWifi,
  faCaretDown,
  faLanguage,
  faCaretUp,
  faPuzzlePiece,
  faKey,
  faTable,
  faColumns,
  faBars,
  faProjectDiagram,
  faAt,
  faBolt,
  faEthernet,
  faBellSlash
);
const { removeBranding }  = window.SERVER_CONFIG;

export const store = Store();

class App extends Component {
  render() {
    return (
      <LanguageProvider>
        <Provider store={store}>
          <BrowserRouter>
            <div
              className="App"
              style={!removeBranding?{
                backgroundImage: `url(${backgroundImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center 80px'
              }:{}}>
              <Main />
            </div>
          </BrowserRouter>
        </Provider>
      </LanguageProvider>
    );
  }
}

export default App;
