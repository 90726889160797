import React, { Component } from 'react'
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { TT } from '../../containers/Language'
import RemoteVpnStatus from "./apps/remoteVpn/RemoteVpnStatus";

class AppStatus extends Component {

  componentDidMount() {
    this.props.getPurchasedApplication(this.props.id);
  }

  render() {
    const { purchasedApplication } = this.props;

    if (!purchasedApplication) {
      return null;
    }

    const { appStoreApp } = purchasedApplication;

    let appStatusComponent = null;

    switch (appStoreApp.identifier) {
      case "com.flexiwan.remotevpn":
        appStatusComponent = (
          <RemoteVpnStatus
            app={this.props.purchasedApplication}
            getApplicationStatus={this.props.getApplicationStatus}
          />
        );
        break;
      default:
        break;
    }
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link exact to="/applications/installed"><TT>Installed Applications</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{appStoreApp.name}</BreadcrumbItem>
          <BreadcrumbItem active><TT>Status</TT></BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4>{appStoreApp.name}</h4>
        </div>
        {appStatusComponent}
      </React.Fragment>
    )
  }
}

export default AppStatus;