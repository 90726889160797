import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TT, LanguageContext  } from '../../containers/Language'

class Invoices extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.getAllInvoices();
  }

  constructor(props) {
    super(props);

    this.state = {
      page:0,
    };

    this.onTableChange = this.onTableChange.bind(this);
  }

  toggleBillingModal() {
    this.setState({
      isBillingModalOpen: !this.state.isBillingModalOpen
    });
  }

  onTableChange = (type, newState) => {
    const newPage = newState.page;
    if (newPage > this.state.page) {
      this.setState({page:newState.page});
      this.props.getAllInvoices({ offsetPos: this.props.invoices.invoices.nextOffset });
    }
  }

  render() {
    const toTTString = this.context.toTTString;
    const page = this.state.page;
    const isLoading = this.props.invoices.isLoading;
    const showNextPage = this.props.invoices.invoices.hasOwnProperty('nextOffset');
    const columns = [
      {
        text: toTTString('Date'), dataField: 'date', sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
        formatter: (cellContent, _) => {
          return new Date(cellContent * 1000).toLocaleString();
        }
      },
      {
        text: toTTString('Amount'), dataField: 'amount', sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
        formatter: (value, row) => {
          const currency = row['base_currency_code'];
          const sum = (value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          return `${currency} ${sum}`
        }
      },
      {
        text: toTTString('Payment Method'), dataField: 'payment_method', sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
        formatter: (cellContent, row) => {
          switch (cellContent) {
            case "bank_transfer":
              return toTTString("Bank Transfer");
            case "check":
              return toTTString("Check");
            case "cash":
              return toTTString("Offline");
            case "card":
              return toTTString("Card");
            default:
              return toTTString("Other");
          }
        }
      },
      {
        text: toTTString('Status'), dataField: 'status', sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
        formatter: (cellContent, row) => {
          switch (cellContent) {
            case "paid":
              return toTTString("Paid");
            case "not_paid":
              return toTTString("Unpaid");
            case "payment_due":
              return toTTString("Payment Due");
            default:
              return toTTString("Failed");
          }
        }
      },
      {
        text: toTTString('Actions'), dataField: 'none', sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'left' };
        },
        formatter: (_, row) => {
          return (
            <div>
              <ReactTooltip id='download-a'><span><TT>Download Invoice</TT></span></ReactTooltip>
              <a href={row.download_url}>
                <ReactTooltip id='download-a'><span><TT>Download Invoice</TT></span></ReactTooltip>
                <Button color="warning" className="action-btn" data-tip data-for='download-a' size="sm">
                  <FontAwesomeIcon icon="download" fixedWidth />
                </Button>
              </a>
            </div>
          )
        }
      }
    ];

    const pageListRenderer = ({ onPageChange }) => {
      return (
        <div className='react-bootstrap-table-pagination-list react-bootstrap-table-pagination-list col-6'>
          <ul className="pagination react-bootstrap-table-page-btns-ul">
            <li className="page-item" title="Next page">
              {showNextPage?
              <button
                className="page-link"
                disabled={isLoading}
                onClick={()=>{onPageChange(page+1)}}><TT>Load More</TT>
              </button>:null}
            </li>
          </ul>
        </div>
      );
    };

    const InvoicePaginationOptions = {
      hideSizePerPage: true,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      pageListRenderer
    };

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Account</TT></BreadcrumbItem>
          <BreadcrumbItem active><Link to="/billing"><TT>Billing</TT></Link></BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4><TT>Invoice History</TT></h4>
          {this.props.invoices.isLoading ? <div className="signal"></div> : null}
        </div>
        <div className="billing-summary">
          <b><TT>Account Current Devices</TT>: </b>{(this.props.invoices.invoices.summary)?this.props.invoices.invoices.summary.current:0}
          , <b><TT>Monthly Max</TT>: </b>{(this.props.invoices.invoices.summary)?this.props.invoices.invoices.summary.max:0}
        </div>
        <hr />
        <div className="col-md-12">
          <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
          <div className="mb-3 mt-3">
          {/* <Button data-cb-type="portal" data-cb-redirect-mode-enabled="true" color="success" className="action-btn-top" size="sm">Manage Account</Button> */}
          <ReactTooltip id='manageaccount-a'><span><TT>Manage Account</TT></span></ReactTooltip>
          <Link to="/billing/manage">
            <Button color="success" className="action-btn-top" data-tip data-for="manageaccount-a" size="sm"><TT>Manage Account</TT></Button>
          </Link>
          <ReactTooltip id='addcoupon-a'><span><TT>Apply Coupon</TT></span></ReactTooltip>
          <Link to="/coupons/add">
            <Button color="success" className="action-btn-top" data-tip data-for="addcoupon-a" size="sm"><TT>Apply Coupon</TT></Button>
          </Link>
          </div>
          <BootstrapTable striped hover condensed
            keyField='id'
            data={this.props.invoices.invoices.invoices ? this.props.invoices.invoices.invoices : []}
            columns={columns}
            remote={true}
            onTableChange={this.onTableChange}
            pagination={paginationFactory(InvoicePaginationOptions)}
            noDataIndication={toTTString("No Invoices available")}
            defaultSorted={[{ dataField: 'date', order: 'desc' }]}
            rowStyle={this.rowStyle} />
        </div>
      </React.Fragment>
    );
  }
}
export default Invoices;