import React, { Component } from "react";
import { Prompt } from 'react-router';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Label,
  Card,
  Row,
  Col
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FWTextInput } from "../Common";
import FirewallRuleModal from '../items/FirewallRuleModal';
import {
  required,
  validatePolicyDesc,
  validatePolicyName,
  minLength,
  maxLength,
} from "../../utils/Validators";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { store } from "../../App";
import {
  convertFirewallRules,
  DestinationFormatter,
  SourceFormatter
} from "../../utils/FirewallUtils"
import { TT, LanguageContext } from '../../containers/Language'
import { getStorageToken } from "../../utils/Token";
const jwt = require("jsonwebtoken");

class FirewallPolicy extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      apps: [],
      appsReverse: [],
      categories: [],
      serviceClasses: [],
      selectedInboundRules: [],
      selectedOutboundRules: [],
      isRuleModalOpen: false,
      origData : {},
      selectedOrg: null
    };

    this.rulePriority = 0;
    this.initalDirection = "";
    this.saveRuleFunction = {};

    this.addRule = this.addRule.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleSwapRules = this.handleSwapRules.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
    this.handleUpdateRule = this.handleUpdateRule.bind(this);
    this.handleDuplicateRule = this.handleDuplicateRule.bind(this);
    this.handleSavePolicy = this.handleSavePolicy.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleEnableSelected = this.handleEnableSelected.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
    this.toggleRuleModal = this.toggleRuleModal.bind(this);
    this.warnOnLeavingPage = this.warnOnLeavingPage.bind(this);
    this.checkIfDataChanged = this.checkIfDataChanged.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const {
      id,
      newPolicy,
      getAllAppIdentifications,
      getFirewallPolicy,
      changePolicyForm,
      resetPolicyForm
    } = this.props;

    getAllAppIdentifications(appsResp => {
      let [apps, appsReverse, categories, serviceClasses] = [
        new Map(),
        new Map(),
        new Map(),
        new Map(),
      ];

      appsResp.appIdentifications.forEach((ent) => {
        const { name, category, serviceClass, id } = ent;
        apps.set(name, { label: name, value: name, id: id });
        appsReverse.set(id.toString(), name);
        categories.set(category, { label: category, value: category });
        serviceClasses.set(serviceClass, { label: serviceClass, value: serviceClass});
      });

      this.setState({
        apps: apps,
        appsReverse: appsReverse,
        categories: [...categories.values()],
        serviceClasses: [...serviceClasses.values()]
      });

      if (!newPolicy) {
        getFirewallPolicy(id, resp => {
          const rules = convertFirewallRules(resp.rules, appsResp.appIdentifications);
          changePolicyForm(
            {
              name: resp.name,
              description: resp.description,
              rules
            },
            { silent: true }
          );
          this.setState({ origData: cloneDeep({ ...resp, rules }) });
        });
      } else {
        resetPolicyForm();
        const emptyPolicy = store.getState().forms.firewallPolicy.$form.initialValue;
        this.setState({ origData: cloneDeep(emptyPolicy) });
      }
    });

    const token = getStorageToken();
    const decodedToken = jwt.decode(token);
    this.props.getOrganization(decodedToken.org, org => {
      this.setState({ selectedOrg: org})
    })

    // Listen to page reload event, to warn the user
    // upon leaving the page with unsaved changes
    window.addEventListener('beforeunload', this.warnOnLeavingPage);
  }

  componentWillUnmount(){
    // Remove page reload listener
    window.removeEventListener('beforeunload', this.warnOnLeavingPage);
  }

  checkIfDataChanged() {
    const currentData = this.props.firewallPolicy;
    return (
      store.getState().forms.firewallPolicy.name.value !== this.state.origData.name ||
      store.getState().forms.firewallPolicy.description.value !== this.state.origData.description ||
      !isEqual(this.state.origData.rules, currentData.rules )
    );
  }

  warnOnLeavingPage(event) {
    if (this.checkIfDataChanged()) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  toggleRuleModal() {
    this.setState({ isRuleModalOpen: !this.state.isRuleModalOpen });
  }

  handleSavePolicy(vals) {
    const { name, description } = vals;
    const rules = this.props.firewallPolicy.rules || [];

    const policy = {
      name: name,
      description: description,
      rules: rules
    };

    const {
      newPolicy,
      resetPolicyForm,
      addFirewallPolicy,
      updFirewallPolicy,
    } = this.props;
    if (newPolicy) {
      addFirewallPolicy(policy, this.state.apps, () => {
        resetPolicyForm();
        this.setState({ origData: cloneDeep(policy) });
      });
    } else {
      policy._id = this.props.firewallPolicies.firewallPolicy._id;
      updFirewallPolicy(policy, this.state.apps, () => {
        this.setState({ origData: cloneDeep(policy) });
      });
    }
  }

  handleAddRule(priority, direction) {
    const emptyRule = store.getState().forms.firewallRule.$form.initialValue;
    this.props.changeRuleForm({ ...emptyRule, direction, isNewRule: true }, { silent: true });
    this.saveRuleFunction = this.addRule;
    this.rulePriority = priority;
    this.initalDirection = direction;
    this.setState({ isRuleModalOpen: true });
  }

  handleUpdateRule(row) {
    this.props.changeRuleForm({ ...row, isNewRule: false });
    this.saveRuleFunction = this.updateRule;
    this.rulePriority = row.priority;
    this.initalDirection = row.direction;
    this.setState({ isRuleModalOpen: true });
  }

  handleDuplicateRule(row) {
    this.props.changeRuleForm({ ...row, isNewRule: true }, { silent: true });
    this.saveRuleFunction = this.addRule;
    this.rulePriority = row.priority + 1;
    this.initalDirection = row.direction;
    this.setState({ isRuleModalOpen: true });
  }

  handleDeleteRule(priority, direction) {
    const rules =  this.props.firewallPolicy.rules.reduce((result, rule) => {
      if (rule.priority < priority || rule.direction !== direction) {
        result.push(rule);
      } else if (rule.priority > priority) {
        result.push({ ...rule, priority: rule.priority-1 });
      };
      return result;
    },[]);
    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
    // Clear selected rules to make sure
    // the deleted rule does not remain selected
    if (direction === 'inbound') {
      this.setState({ selectedInboundRules: [] });
    } else {
      this.setState({ selectedOutboundRules: [] });
    }
  }

  handleSwapRules(curIdx, newIdx, direction) {
    const rules = this.props.firewallPolicy.rules.map(rule => {
      if (rule.direction === direction && [curIdx, newIdx].includes(rule.priority)) {
        return { ...rule, priority: rule.priority === newIdx ? curIdx : newIdx }
      } else {
        return { ...rule }
      }
    });

    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
  }

  handleEnabled({ priority, direction }) {
    const rules = this.props.firewallPolicy.rules.map(rule => {
      if (rule.direction === direction && priority === rule.priority) {
        return { ...rule, enabled: !rule.enabled }
      } else {
        return { ...rule }
      }
    });
    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
  }

  handleDeleteSelected(direction) {
    const selected = direction === 'inbound' ? this.state.selectedInboundRules
      : this.state.selectedOutboundRules;
    const rules = this.props.firewallPolicy.rules.reduce((result, rule) => {
      if (rule.direction === direction && selected.includes(rule.priority)) {
        return result;
      } else if (rule.direction === direction) {
        const priority = result.filter(r => r.direction === direction).length;
        return [...result, {...rule, priority: priority}];
      } else {
        return [...result, rule];
      }
    }, []);
    if (direction === 'inbound') {
      this.setState({ selectedInboundRules: [] });
    } else {
      this.setState({ selectedOutboundRules: [] });
    };
    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
  }

  handleEnableSelected(direction, enabled) {
    const selected = direction === 'inbound' ? this.state.selectedInboundRules
      : this.state.selectedOutboundRules;
    const rules = this.props.firewallPolicy.rules.map(rule => {
      if (rule.direction === direction && selected.includes(rule.priority)) {
        return { ...rule, enabled: enabled }
      } else {
        return { ...rule }
      }
    });
    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
  }

  handleOnSelect(row, isSelect, direction) {
    const updatedSelected = (selectedRules, isSelect) => {
      return isSelect
        ? [...selectedRules, row.priority]
        : selectedRules.filter(priority => priority !== row.priority);
    };

    if (direction === 'inbound') {
      this.setState({
        selectedInboundRules: updatedSelected(this.state.selectedInboundRules, isSelect)
      });
    } else {
      this.setState({
        selectedOutboundRules: updatedSelected(this.state.selectedOutboundRules, isSelect)
      });
    }
  }

  handleOnSelectAll(isSelect, rows, direction) {
    const selectedRules = isSelect ? rows.filter(rule => rule.direction === direction)
      .map(rule => rule.priority) : [];
    if (direction === 'inbound') {
      this.setState({ selectedInboundRules: selectedRules });
    } else {
      this.setState({ selectedOutboundRules: selectedRules });
    }
  }

  updateRule(updRule) {
    const rules =  this.props.firewallPolicy.rules.map(rule => {
      if (this.initalDirection === rule.direction && this.rulePriority === rule.priority) {
        // check if direction was changed
        const priority = this.initalDirection === updRule.direction ? this.rulePriority
          : this.props.firewallPolicy.rules.filter(r => r.direction === updRule.direction).length;
        return {...updRule, priority};
      } else {
        return rule;
      }
    });
    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
  }

  addRule(rule) {
    const { firewallPolicy } = this.props;
    const priority = this.initalDirection === rule.direction ? this.rulePriority
      : firewallPolicy.rules.filter(r => r.direction === rule.direction).length;
    const rules = firewallPolicy.rules ? firewallPolicy.rules.map(
      r => r.direction !== rule.direction || r.priority < priority ? r
        : { ...r, priority: r.priority + 1 }
    ) : [];
    rules.push({ ...rule, priority });
    this.props.changePolicyForm(
      { ...this.props.firewallPolicy, rules },
      { silent: true }
    );
  }

  onDragEnd({ source, destination }) {
    // dropped outside the list
    if (!destination) {
      return;
    }
    this.handleSwapRules(source.index, destination.index, source.droppableId);
  }

  render() {
    const toTTString = this.context.toTTString

    this.FirewallRulesOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("First page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("Next page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "100", value: 100 },
        { text: "200", value: 200 },
        { text: "300", value: 300 }
      ]
    };


    this.ruleColumns = [
      { text: toTTString("ID"), dataField: "_id", hidden: true },
      {
        text: toTTString("Destination"),
        dataField: "classification.destination",
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        formatter: (cellContent, row) =>
        <Draggable
          key={row.priority}
          draggableId={row.direction + '-' + row.priority}
          index={row.priority}
        >
          {(provided, snapshot) => (
            <div
              data-tip data-for={"drag-and-drop-rule-" + row._id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ReactTooltip id={"drag-and-drop-rule-" + row._id}>
                <span><TT>Drag and Drop the rule</TT></span>
              </ReactTooltip>
              <DestinationFormatter rule={row} />
            </div>
          )}
        </Draggable>
      },
      {
        text: toTTString("Source"),
        dataField: "classification.source",
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        formatter: (cellContent, row) => <SourceFormatter rule={row} />
      },
      {
        text: toTTString("Action"),
        dataField: "action",
        headerStyle: (colum, colIndex) => {
          return {
            width: "10%",
            textAlign: "left"
          };
        },
        formatter: (value) => {
          return value === 'allow' ? (<>
            <FontAwesomeIcon icon="check" color="green" fixedWidth/>
            <TT>Allow</TT>
          </>) : (<>
            <FontAwesomeIcon icon='ban' color="red" fixedWidth/>
            <TT>Deny</TT>
          </>);
        }
      },
      {
        text: toTTString("Description"),
        dataField: "description",
        editable: false,
        formatter: val => val && val.length > 20 ? val.slice(0, 20)+'...' : val,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        }
      },
      {
        text: toTTString("Rule Actions"),
        dataField: "none",
        headerStyle: (colum, colIndex) => {
          return {
            width: "20%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          const { priority, direction } = row;

          return (
            <div>
              <ReactTooltip id={'enable-rule-tip-' + row.direction + row.priority}>
                <span style={{"fontSize": "0.8rem"}}>
                  {row.enabled ? toTTString('Disable rule') : toTTString('Enable rule')}
                </span>
              </ReactTooltip>
              <Button
                color={row.enabled ? 'danger' : 'success'}
                className="action-btn"
                data-tip
                data-for={'enable-rule-tip-' + row.direction + row.priority}
                size="sm"
                onClick={() => this.handleEnabled(row)}
              >
                <FontAwesomeIcon icon={row.enabled ? 'stop-circle' : 'check-circle'} fixedWidth />
              </Button>
              <ReactTooltip id={'update-rule-tip-' + row.direction + row.priority}>
                <span><TT>Update Rule</TT></span>
              </ReactTooltip>
              <Button
                color="warning"
                className="action-btn"
                data-tip
                data-for={'update-rule-tip-' + row.direction + row.priority}
                size="sm"
                onClick={() => this.handleUpdateRule(row)}
              >
                <FontAwesomeIcon icon="cog" fixedWidth />
              </Button>
              <ReactTooltip id={'add-above-tip-' + row.direction + row.priority}>
                <span><TT>Add rule above</TT></span>
              </ReactTooltip>
              <Button
                className="action-btn"
                data-tip
                data-for={'add-above-tip-' + row.direction + row.priority}
                color="success"
                size="sm"
                onClick={() => this.handleAddRule(priority, direction)}
              >
                <FontAwesomeIcon icon={["fas", "level-up-alt"]} fixedWidth />
              </Button>
              {
                <>
                  <ReactTooltip id={'add-below-tip-' + row.direction + row.priority}>
                    <span><TT>Add rule below</TT></span>
                  </ReactTooltip>
                  <Button
                    className="action-btn"
                    color="success"
                    data-tip
                    data-for={'add-below-tip-' + row.direction + row.priority}
                    size="sm"
                    onClick={() => this.handleAddRule(priority + 1, direction)}
                  >
                    <FontAwesomeIcon icon={["fas", "level-down-alt"]} fixedWidth />
                  </Button>
                  <ReactTooltip id={'duplicate-rule-tip-' + row.direction + row.priority}>
                    <span><TT>Duplicate rule</TT></span>
                  </ReactTooltip>
                  <Button
                    className="action-btn"
                    color="warning"
                    data-tip
                    data-for={'duplicate-rule-tip-' + row.direction + row.priority}
                    size="sm"
                    onClick={() => this.handleDuplicateRule(row)}
                  >
                    <FontAwesomeIcon icon='clone' fixedWidth />
                  </Button>
                  <ReactTooltip id={'delete-rule-tip-' + row.direction + row.priority}>
                    <span><TT>Delete rule</TT></span>
                  </ReactTooltip>
                  <Button
                    color="danger"
                    className="action-btn"
                    data-tip
                    data-for={'delete-rule-tip-' + row.direction + row.priority}
                    size="sm"
                    onClick={() => this.handleDeleteRule(priority, direction)}
                  >
                    <FontAwesomeIcon icon="trash-alt" fixedWidth />
                  </Button>
                </>
              }
            </div>
          );
        }
      }
    ];

    const rules = (this.props.firewallPolicy.rules ?
      [...this.props.firewallPolicy.rules] : [])
      .sort((r1, r2) => r1.priority - r2.priority)
      .reduce((result, rule) => {
        result[rule.direction].push(rule);
        return result;
      }, { inbound: [], outbound: [] });

    const { newPolicy } = this.props;

    if (!this.props.firewallPolicy) return '';

    const selectRow = direction => ({
      mode: "checkbox",
      clickToSelect: false,
      selected: direction === 'inbound' ? this.state.selectedInboundRules
        : this.state.selectedOutboundRules,
      onSelect: (row, isSelect) => this.handleOnSelect(row, isSelect, direction),
      onSelectAll: (isSelect, rows) => this.handleOnSelectAll(isSelect, rows, direction)
    });

    return (
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Policies</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/firewallpolicies"><TT>Firewall Policies</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{newPolicy ? toTTString("add") : toTTString("update")}</BreadcrumbItem>
        </Breadcrumb>
        <Prompt
          message={(location, action) => {
            if (this.checkIfDataChanged()) {
              return toTTString('You have unsaved changes') + '. ' +
                toTTString('Are you sure you want to leave this page?')
            }
          }}
        />
        <h4><TT>Firewall Policy</TT></h4>
        <Link to="/firewallpolicies">
          <Button color="info" className="back-btn policy-back-button">
            <FontAwesomeIcon icon="arrow-circle-left" fixedWidth />
          </Button>
        </Link>
        <Control.button
          className="btn btn-primary upper-panel-button action-btn-top"
          model="firewallPolicy"
          form="firewallPolicy"
          type="submit"
          disabled={{ valid: false }}
        >
          {`${newPolicy ? toTTString("Save Policy") : toTTString("Save & Update Devices")}`}
        </Control.button>
        <Form id="firewallPolicy" model="firewallPolicy" onSubmit={this.handleSavePolicy}>
          <Card className="col-lg-10">
            <Row className="form-group policy-name">
              <Col className="col-lg-4 col-12">
                <Row>
                  <Label htmlFor="name">
                    <span className="form-required-star">*</span>
                     <TT>Policy Name</TT></Label>
                  <Col>
                    <Control.text
                      model=".name"
                      id="name"
                      name="name"
                      placeholder={toTTString("Policy Name")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        name: validatePolicyName,
                        minLength: minLength(3),
                        maxLength: maxLength(50),
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".name"
                      show="touched"
                      messages={{
                        required: toTTString("Required Field"),
                        minLength: toTTString('Length must be at least 3'),
                        maxLength: toTTString('Length must be at most 50'),
                        name: toTTString("Invalid Policy Name Format"),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="col-lg col-12 ml-lg-5">
                <Row>
                  <Label htmlFor="description"><TT>Description</TT></Label>
                  <Col>
                    <Control.text
                      model=".description"
                      id="description"
                      name="description"
                      placeholder={toTTString("Policy Description")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        maxLength: maxLength(50),
                        description: validatePolicyDesc,
                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".description"
                      show="touched"
                      messages={{
                        maxLength: toTTString('Length must be at most 50'),
                        description: toTTString("Invalid Policy Description Format"),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Form>
        <Label className="ml-3">
          <TT>Outbound rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='outboundRulesTip'></span>
          <ReactTooltip id='outboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Allowed by default</TT>
            </span>
          </ReactTooltip>
        </Label>
        <ReactTooltip id="add-outbound-rule">
          <span><TT>Add outbound rule</TT></span>
        </ReactTooltip>
        <Button
          data-tip
          data-for="add-outbound-rule"
          className="btn btn-primary policy-rules-buttons mt-2 mb-2"
          onClick={() => this.handleAddRule(rules.outbound.length, 'outbound')}
        >
          <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
        </Button>
        {
          this.state.selectedOutboundRules.length > 0 ? <>
            <ReactTooltip id="enable-selected-outbound">
              <span><TT>Enable selected outbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="enable-selected-outbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('outbound', true)}
            >
              <TT>Enable</TT>
            </Button>
            <ReactTooltip id="disable-selected-outbound">
              <span><TT>Disable selected outbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="disable-selected-outbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('outbound', false)}
            >
              <TT>Disable</TT>
            </Button>
            <ReactTooltip id="delete-selected-outbound">
              <span><TT>Delete selected outbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="delete-selected-outbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleDeleteSelected('outbound')}
            >
              <TT>Delete</TT>
            </Button>
          </> : ''
        }
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="outbound">
            {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <BootstrapTable
                striped
                hover
                condensed
                classes="firewall-rules-table"
                keyField="priority"
                data={rules.outbound}
                columns={this.ruleColumns}
                pagination={paginationFactory(this.FirewallRulesOptions)}
                noDataIndication={toTTString("No outbound rules available")}
                defaultSorted={[{ dataField: "priority", order: "asc" }]}
                selectRow={selectRow('outbound')}
                rowEvents={{
                  onDoubleClick: (e, row, rowIndex) => this.handleUpdateRule(row)
                }}
                rowStyle={row => !row.enabled ? { color: 'grey' } : {}}
              />
              {provided.placeholder}
            </div>
            )}
          </Droppable>
        </DragDropContext>

        <hr />
        <Label className="ml-3">
          <TT>Inbound rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='inboundRulesTip'></span>
          <ReactTooltip id='inboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Denied by default</TT>
            </span>
          </ReactTooltip>
        </Label>
        <ReactTooltip id="add-inbound-rule">
          <span><TT>Add inbound rule</TT></span>
        </ReactTooltip>
        <Button
          data-tip
          data-for="add-inbound-rule"
          className="btn btn-primary policy-rules-buttons mt-2 mb-2"
          onClick={() => this.handleAddRule(rules.inbound.length, 'inbound')}
        >
          <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
        </Button>
        {
          this.state.selectedInboundRules.length > 0 ? <>
            <ReactTooltip id="enable-selected-inbound">
              <span><TT>Enable selected inbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="enable-selected-inbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('inbound', true)}
            >
              <TT>Enable</TT>
            </Button>
            <ReactTooltip id="disable-selected-inbound">
              <span><TT>Disable selected inbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="disable-selected-inbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('inbound', false)}
            >
              <TT>Disable</TT>
            </Button>
            <ReactTooltip id="delete-selected-inbound">
              <span><TT>Delete selected inbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="delete-selected-inbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleDeleteSelected('inbound')}
            >
              <TT>Delete</TT>
            </Button>
          </> : ''
        }
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="inbound">
            {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <BootstrapTable
                striped
                hover
                condensed
                classes="firewall-rules-table"
                keyField="priority"
                data={rules.inbound}
                columns={this.ruleColumns}
                pagination={paginationFactory(this.FirewallRulesOptions)}
                noDataIndication={toTTString("No inbound rules available")}
                defaultSorted={[{ dataField: "priority", order: "asc" }]}
                selectRow={selectRow('inbound')}
                rowEvents={{
                  onDoubleClick: (e, row, rowIndex) => this.handleUpdateRule(row)
                }}
                rowStyle={row => !row.enabled ? { color: 'grey' } : {}}
              />
              {provided.placeholder}
            </div>
            )}
          </Droppable>
        </DragDropContext>

        <FirewallRuleModal
          firewallRule={this.props.firewallRule}
          changeRuleForm={this.props.changeRuleForm}
          apps={this.state.apps}
          categories={this.state.categories}
          serviceClasses={this.state.serviceClasses}
          isOpen={this.state.isRuleModalOpen}
          toggle={this.toggleRuleModal}
          saveRule={this.saveRuleFunction}
          tunnelPort={this.state.selectedOrg?.vxlanPort}
        />
      </>
    );
  }
}

export default FirewallPolicy;
