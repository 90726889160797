import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col } from 'reactstrap';
import { Button } from "reactstrap";
import { TT, LanguageContext } from '../../../containers/Language';
import { getMfaRecoveryCodes } from '../../../redux/reducers/Auth';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../items/Loading';

const RecoveryCodes = props => {
  const { toTTString } = useContext(LanguageContext);

  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [allowToFinish, setAllowToFinish] = useState(false);

  useEffect(() => {
    setLoading(true)
    props.getMfaRecoveryCodes((res, error) => {
      setCodes(res?.codes ?? []);
      setLoading(false)
    },  props.history?.location?.state?.regenerate ?? false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   const downloadRecoveryCodes = () => {
    if (codes.length === 0) return;
    const data = codes.join('\n\n');
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', `flexiManage-recovery-codes.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setAllowToFinish(true);
  }

  const copyRecoveryCodes = () => {
    const data = codes.join('\n\n');
    navigator.clipboard.writeText(data);
    toast(<TT>Recovery Codes Copied</TT>, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });

    setAllowToFinish(true);
  }

  if (loading) return <Loading size={'3x'} />

  if (codes.length === 0) {
    return <></>
  }

  return (
    <>
      <Prompt
        when={!allowToFinish && codes.length > 0}
        message={() => {
          return toTTString('You didn\'t copy or download the Recovery Codes. Are you sure you want to leave this page?');
        }}
      />
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem><Link to="/members"><TT>Users</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>Recovery Codes</TT></BreadcrumbItem>
      </Breadcrumb>

      <Col md={7}>
        <div className="container">
          <h4><TT>Recovery codes</TT></h4>
        </div>

        <div className="alert alert-success mt-3">
          <TT>Please copy or download your recovery codes before proceeding</TT>
        </div>

        <div className="mt-3">
          <span>
            <TT>Should you ever lose your phone or access to your one time password secret,</TT> <TT>each of these recovery
              codes can be used one time each to regain access to your account.</TT> <TT>Please save them in a safe place,
              or you</TT> <TT>will</TT> <TT>lose access to your account.</TT>
          </span>
        </div>

        <div className='mt-3'>
          <Card>
            <CardBody>
              <ol>
                {codes.map(c => <li><div className='mt-2' key={c}>{c}</div></li>)}
              </ol>
            </CardBody>
          </Card>
        </div>
        <div className='mt-1'>
          <Button
            type="button"
            className={"btn btn-primary"}
            disabled={codes.length === 0}
            onClick={downloadRecoveryCodes}
          >
            <TT>Download</TT>
          </Button>
          <Button
            type="button"
            className={"btn btn-primary ml-2"}
            disabled={codes.length === 0}
            onClick={copyRecoveryCodes}
          >
            <TT>Copy</TT>
          </Button>

          <Button
            type="button"
            className={"btn btn-success ml-2"}
            disabled={!allowToFinish}
            onClick={() => props.history.push({ pathname: '/home' })}
          >
            <TT>Done</TT>
          </Button>

          <ToastContainer />
        </div>
      </Col>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getMfaRecoveryCodes: (cb, regenerate = false) => dispatch(getMfaRecoveryCodes(cb, regenerate))
  }
}

export default connect(null, mapDispatchToProps)(withRouter(RecoveryCodes));