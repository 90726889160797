import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Configuration = (
  state = {},
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *  Get Servers List
 **********************************************************************/

export const getRestServersList = cb => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "configuration/restServers",
      method: "GET",
      requestCB: () => {
      },
      successCB: response => {
        cb(response)
      },
      failureCB: () => {
      }
    })
  );
};
