import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import RemoteVpnConfigurations from "./apps/remoteVpn/remoteVpnConfigurations";
import { TT } from '../../containers/Language'

class AppConfigurations extends Component {

  componentDidMount() {
    this.props.getPurchasedApplication(this.props.id);
  }

  render() {
    const { purchasedApplication } = this.props;

    if (!purchasedApplication) {
      return null;
    }

    const { appStoreApp } = purchasedApplication;

    let appConfigurationComponent = null;
    switch (appStoreApp.identifier) {
      case "com.flexiwan.remotevpn":
        appConfigurationComponent = (
          <RemoteVpnConfigurations
            app={this.props.purchasedApplication}
            changeForm={this.props.changeForm}
            resetForm={this.props.resetForm}
            updateApplication={this.props.updateApplication}
          />
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link exact="true" to="/applications/installed"><TT>Installed Applications</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{appStoreApp.name}</BreadcrumbItem>
          <BreadcrumbItem active><TT>Configuration</TT></BreadcrumbItem>
        </Breadcrumb>
        <div className="container d-flex align-items-center justify-content-between">
          <h4 className="mr-2">{appStoreApp.name} <TT>Configuration</TT></h4>
        </div>
        {appConfigurationComponent}
      </React.Fragment>
    );
  }
}

export default AppConfigurations;