import React, { Component } from "react";
import {Alert} from "reactstrap";
import { Link } from 'react-router-dom';
import {TT} from '../containers/Language'

class TopMessages extends Component {

  componentDidMount() {
    // The account is updated every refresh token update,
    // see Auth.js -> updateJWT. This happens every 5 min.
    // Once the account will be updated, the message will also be updated
    if (this.props.accountId)
      this.props.getAccountSubscriptionStatus(this.props.accountId,()=>{});
  }

  render() {
    //let days = 0;
    let dateString = '';
    const trial_end = this.props?.accounts?.account?.trial_end;
    if (trial_end) {
      // const now = new Date();
      // now.setUTCHours(0, 0, 0, 0);
      // days = Math.max(Math.round((trial_end*1000-now.getTime())/86400000),0);
      dateString = new Date(trial_end*1000)
              .toLocaleString('en-US', { year: 'numeric',month: 'short', day: '2-digit'});
    }

    return (
        <div>
          {this.props.isAuthenticated?
            this.props?.accounts?.account?.isSubscriptionValid === false?
            (<Alert className="top-messages-warning" color="danger" >
              <TT>Account is suspended due to billing issues. The account owner should</TT> <Link to={`/billing/manage`}><TT>add a payment method</TT></Link> <TT>or contact flexiWAN</TT> <a href="mailto:finance@flexiwan.com"><TT>finance</TT></a>
            </Alert>):
            dateString !=='' ?
            (<Alert className="top-messages-warning" color="warning" >
              <TT>Account is in a free trial. Trial ends on</TT> {dateString}
            </Alert>)
            :null
          :null}
        </div>
    );
  }
}

export default TopMessages;
