import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const AccessTokens = (
  state = {
    accesstoken: null,
    accesstokens: [],
    options: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.ACCESS_TOKEN_ADD_REQUEST:
      return { ...state, accesstoken: null };
    case Actions.ACCESS_TOKEN_ADD_SUCCESS:
      return { ...state, accesstoken: action.accesstoken };
    case Actions.ACCESS_TOKEN_ADD_FAILURE:
      return { ...state, accesstoken: null };
    case Actions.ACCESS_TOKEN_DEL_REQUEST:
      return { ...state, accesstoken: action.accesstoken };
    case Actions.ACCESS_TOKEN_DEL_SUCCESS:
      return { ...state };
    case Actions.ACCESS_TOKEN_DEL_FAILURE:
      return { ...state, accesstoken: null };
    case Actions.ACCESS_TOKEN_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ACCESS_TOKEN_GETALL_SUCCESS:
      return { ...state, accesstokens: action.accesstokens, isLoading: false };
    case Actions.ACCESS_TOKEN_GETALL_FAILURE:
      return { ...state, accesstokens: [], isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add AccessToken
 **********************************************************************/
export const requestAddAccessToken = accesstoken => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_ADD_REQUEST,
    accesstoken
  });
};

export const receiveAddAccessToken = response => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_ADD_SUCCESS,
    accesstoken: response
  });
  dispatch(mainRedirectTo("/accesstokens"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Access key " + response.name + " added successfully"
    })
  );
};

export const failureAddAccessToken = error => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const addAccessToken = (accesstoken, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accesstokens",
      method: "POST",
      body: JSON.stringify(accesstoken),
      requestCB: () => {
        dispatch(requestAddAccessToken(accesstoken));
      },
      successCB: response => {
        dispatch(receiveAddAccessToken(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddAccessToken(error));
      }
    })
  );
};


/**********************************************************************
 *   Delete AccessToken
 **********************************************************************/
export const requestDelAccessToken = accesstoken => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_DEL_REQUEST,
    accesstoken
  });
};

export const receiveDelAccessToken = response => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Access key deleted successfully"
    })
  );
};

export const failureDelAccessToken = error => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_DEL_FAILURE
  });
};

export const delAccessToken = (accesstoken, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accesstokens/" + accesstoken.id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelAccessToken(accesstoken));
      },
      successCB: response => {
        dispatch(receiveDelAccessToken(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelAccessToken(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all accesstokens
 **********************************************************************/
export const requestGetAllAccessTokens = () => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_GETALL_REQUEST
  });
};

export const receiveGetAllAccessTokens = response => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_GETALL_SUCCESS,
    accesstokens: response
  });
};

export const failureGetAllAccessTokens = error => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_GETALL_FAILURE
  });
};

export const getAllAccessTokens = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accesstokens",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllAccessTokens());
      },
      successCB: response => {
        dispatch(receiveGetAllAccessTokens(response));
      },
      failureCB: error => {
        dispatch(failureGetAllAccessTokens(error));
      }
    })
  );
};

/**********************************************************************
 *   Get accesstoken
 **********************************************************************/
export const requestGetAccessToken = () => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_GET_REQUEST
  });
};

export const receiveGetAccessToken = response => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_GET_SUCCESS,
    accesstoken: response
  });
};

export const failureGetAccessToken = error => dispatch => {
  dispatch({
    type: Actions.ACCESS_TOKEN_GET_FAILURE
  });
};

export const getAccessToken = (accesstokenId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "accesstokens/" + accesstokenId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAccessToken());
      },
      successCB: response => {
        dispatch(receiveGetAccessToken(response[0]));
        success_cb(response[0]);
      },
      failureCB: error => {
        dispatch(failureGetAccessToken(error));
      }
    })
  );
};
