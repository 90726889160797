import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import queryString from "query-string";
import { alertMsgMessage } from "./AlertMsg";
const { baseUrl } = window.SERVER_CONFIG;

/**********************************************************************
 *   Get notifications configuration
 **********************************************************************/
export const requestGetNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_CONF_GET_REQUEST,
  });
};

export const receiveGetNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_CONF_GET_SUCCESS
  });
};

export const failureGetNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_CONF_GET_FAILURE,
  });
};

export const getNotificationsConf =
  (params, onSuccess = () => {}, onError = (error) => {}) =>
  (dispatch) => {
    const queryParams = queryString.stringify(params);
    return dispatch(
      FWFetch({
        url: baseUrl + `notificationsConf/${queryParams ? `?${queryParams}` : ""}`,
        method: "GET",
        requestCB: () => {
          dispatch(requestGetNotificationsConf());
        },
        successCB: (response, status, headers) => {
          dispatch(receiveGetNotificationsConf());
          onSuccess(response);
        },
        failureCB: (error) => {
          dispatch(failureGetNotificationsConf());
          onError(error);
        },
      })
    );
  };

/**********************************************************************
 *   Modify notifications settings
 **********************************************************************/
export const requestUpdNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_CONF_PUT_REQUEST,
  });
};

export const receiveUpdNotificationsConf =  response => dispatch => {
  const message =  { color: "success", text: response.message }
  dispatch({
    type: Actions.NOTIFICATIONS_CONF_PUT_SUCCESS,
  });
  dispatch(
    alertMsgMessage(message)
  );
};

export const failureUpdNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_CONF_PUT_FAILURE,
  });
  dispatch(
    alertMsgMessage({
      color: "danger",
      text: "An error occurred while updating the settings ",
    })
  );
};

export const updateNotificationsConf = (request) => (dispatch) => {
  return dispatch(
    FWFetch({
      url: baseUrl + `notificationsConf/`,
      method: "PUT",
      body: JSON.stringify(request),
      requestCB: () => {
        dispatch(requestUpdNotificationsConf());
      },
      successCB: (response, status, headers) => {
        dispatch(receiveUpdNotificationsConf(response));
      },
      failureCB: (error) => {
        dispatch(failureUpdNotificationsConf());
      },
    })
  );
};

/**********************************************************************
 *   Get email notifications settings
 **********************************************************************/
export const requestGetEmailNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.EMAIL_NOTIFICATIONS_CONF_GET_REQUEST,
  });
};

export const receiveGetEmailNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.EMAIL_NOTIFICATIONS_CONF_GET_SUCCESS,
  });
};

export const failureGetEmailNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.EMAIL_NOTIFICATIONS_CONF_GET_FAILURE,
  });
};

export const getEmailNotificationsConf =
  (params, onSuccess = () => {}, onError = (error) => {}) =>
  (dispatch) => {
    const queryParams = queryString.stringify(params);
    return dispatch(
      FWFetch({
        url: baseUrl + `notificationsConf/emails/${queryParams ? `?${queryParams}` : ""}`,
        method: "GET",
        requestCB: () => {
          dispatch(requestGetEmailNotificationsConf());
        },
        successCB: (response, status, headers) => {
          dispatch(receiveGetEmailNotificationsConf());
          onSuccess(response);
        },
        failureCB: (error) => {
          dispatch(failureGetEmailNotificationsConf());
          onError(error);
        },
      })
    );
  };

/**********************************************************************
 *   Modify email notifications settings
 **********************************************************************/
export const requestPutEmailNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.EMAIL_NOTIFICATIONS_CONF_PUT_REQUEST,
  });
};

export const receivePutEmailNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.EMAIL_NOTIFICATIONS_CONF_PUT_SUCCESS,
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "The settings were updated successfully",
    })
  );
};

export const failurePutEmailNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.EMAIL_NOTIFICATIONS_CONF_PUT_FAILURE,
  });
  dispatch(
    alertMsgMessage({
      color: "danger",
      text: "An error occurred while updating the settings ",
    })
  );
};

export const updateEmailNotificationsConf = (request) => (dispatch) => {
  return dispatch(
    FWFetch({
      url: baseUrl + `notificationsConf/emails/`,
      method: "PUT",
      body: JSON.stringify(request),
      requestCB: () => {
        dispatch(requestPutEmailNotificationsConf());
      },
      successCB: (response, status, headers) => {
        dispatch(receivePutEmailNotificationsConf());
      },
      failureCB: (error) => {
        dispatch(failurePutEmailNotificationsConf());
      },
    })
  );
};

/**********************************************************************
 *   Get account/system default notifications configuration
 **********************************************************************/
export const requestGetDefNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEFAULT_CONF_GET_REQUEST,
  });
};

export const receiveGetDefNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEFAULT_CONF_GET_SUCCESS,
  });
};

export const failureGetDefNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEFAULT_CONF_GET_FAILURE,
  });
};

export const getDefaultNotificationsConf =
  (params, onSuccess = () => {}) =>
  (dispatch) => {
    const queryParams = queryString.stringify(params);
    return dispatch(
      FWFetch({
        url:
          baseUrl +
          `notificationsConf/default/${queryParams ? `?${queryParams}` : ""}`,
        method: "GET",
        requestCB: () => {
          dispatch(requestGetDefNotificationsConf());
        },
        successCB: (response, status, headers) => {
          dispatch(receiveGetDefNotificationsConf());
          onSuccess(response);
        },
        failureCB: (error) => {
          dispatch(failureGetDefNotificationsConf());
        },
      })
    );
  };


/**********************************************************************
 *   Modify account default notifications configuration
 **********************************************************************/
export const requestPutDefNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEFAULT_CONF_PUT_REQUEST,
  });
};

export const receivePutDefNotificationsConf = response => dispatch => {
  const message =  { color: "success", text: response.message }
  dispatch({
    type: Actions.NOTIFICATIONS_DEFAULT_CONF_PUT_SUCCESS,
  });
  dispatch(
    alertMsgMessage(message)
  );
};

export const failurePutDefNotificationsConf = () => (dispatch) => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEFAULT_CONF_PUT_FAILURE,
  });
};

export const updateDefaultNotificationsConf = (request) => (dispatch) => {
    return dispatch(
      FWFetch({
        url:
          baseUrl +
          `notificationsConf/default/`,
        method: "PUT",
        body: JSON.stringify(request),
        requestCB: () => {
          dispatch(requestPutDefNotificationsConf());
        },
        successCB: (response, status, headers) => {
          dispatch(receivePutDefNotificationsConf(response));
        },
        failureCB: (error) => {
          dispatch(failurePutDefNotificationsConf());
        },
      })
    );
  };

/**********************************************************************
 *   Get webhook notifications settings
 **********************************************************************/
export const requestWebHookSettings = () => (dispatch) => {
  dispatch({
    type: Actions.WEBHOOK_SETTINGS_GET_REQUEST,
  });
};

export const receiveGetWebHookSettings = () => (dispatch) => {
  dispatch({
    type: Actions.WEBHOOK_SETTINGS_GET_SUCCESS,
  });
};

export const failureWebHookSettings = () => (dispatch) => {
  dispatch({
    type: Actions.WEBHOOK_SETTINGS_GET_FAILURE,
  });
};

export const getWebHookSettings =
  (params, onSuccess = () => {}, onError = (error) => {}) =>
  (dispatch) => {
    const queryParams = queryString.stringify(params);
    return dispatch(
      FWFetch({
        url: baseUrl + `notificationsConf/webhooks/${queryParams ? `?${queryParams}` : ""}`,
        method: "GET",
        requestCB: () => {
          dispatch(requestWebHookSettings());
        },
        successCB: (response, status, headers) => {
          dispatch(receiveGetWebHookSettings(response));
          onSuccess(response);
        },
        failureCB: (error) => {
          dispatch(failureWebHookSettings(error));
          onError(error);
        },
      })
    );
  };

/**********************************************************************
 *   Modify webhook notifications settings
 **********************************************************************/
export const requestPutWebHookSettings = () => (dispatch) => {
  dispatch({
    type: Actions.WEBHOOK_SETTINGS_PUT_REQUEST,
  });
};

export const receivePutWebHookSettings = () => (dispatch) => {
  dispatch({
    type: Actions.WEBHOOK_SETTINGS_PUT_SUCCESS,
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "The settings were updated successfully"
    })
  )
};

export const failurePutWebHookSettings = () => (dispatch) => {
  dispatch({
    type: Actions.WEBHOOK_SETTINGS_PUT_FAILURE,
  });
};

export const modifyWebHookSettings = (request) => (dispatch) => {
  return dispatch(
    FWFetch({
      url: baseUrl + `notificationsConf/webhooks/`,
      method: "PUT",
      body: JSON.stringify(request),
      requestCB: () => {
        dispatch(requestPutWebHookSettings());
      },
      successCB: (response, status, headers) => {
        dispatch(receivePutWebHookSettings());
      },
      failureCB: (error) => {
        dispatch(failurePutWebHookSettings(error));
      },
    })
  );
};
