import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import queryString from 'query-string';
import { alertMsgMessage } from "./AlertMsg";
const { baseUrl } = window.SERVER_CONFIG;

export const Notifications = (
  state = {
    notifications: [],
    notificationsMetadata: [],
    total: 0,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.NOTIFICATIONS_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.NOTIFICATIONS_GET_SUCCESS:
      return { ...state, notifications: action.notifications, total: action.total, isLoading: false };
    case Actions.NOTIFICATIONS_GET_FAILURE:
      return { ...state, notifications: [], total: 0, isLoading: false };
    case Actions.NOTIFICATIONS_GET_META_REQUEST:
      return { ...state, isLoading: true };
    case Actions.NOTIFICATIONS_GET_META_SUCCESS:
      return { ...state, notificationsMetadata: action.notificationsMetadata, isLoading: false };
    case Actions.NOTIFICATIONS_GET_META_FAILURE:
      return { ...state, notificationsMetadata: [], isLoading: false };
    case Actions.NOTIFICATIONS_UPD_REQUEST:
      return { ...state, isLoading: true };
    case Actions.NOTIFICATIONS_UPD_SUCCESS:
      return { ...state, isLoading: false };
    case Actions.NOTIFICATIONS_UPD_FAILURE:
      return { ...state, isLoading: false };
    case Actions.NOTIFICATIONS_DEL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.NOTIFICATIONS_DEL_SUCCESS:
      return { ...state, isLoading: false };
    case Actions.NOTIFICATIONS_DEL_FAILURE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get notifications
 **********************************************************************/
export const requestGetNotifications = () => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_GET_REQUEST
  });
};

export const receiveGetNotifications = (response, total) => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_GET_SUCCESS,
    notifications: response,
    total: +total
  });
};

export const failureGetNotifications = error => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_GET_FAILURE
  });
};

export const getNotifications = (params) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + `notifications/${queryParams ? `?${queryParams}` : ''}`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetNotifications());
      },
      successCB: (response, status, headers) => {
        dispatch(receiveGetNotifications(response, headers && headers.get('records-total')));
      },
      failureCB: error => {
        dispatch(failureGetNotifications(error));
      }
    })
  );
};

/**********************************************************************
 *   Get notifications metadata
 **********************************************************************/
export const requestGetNotificationsMetadata = () => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_GET_META_REQUEST
  });
};

export const receiveGetNotificationsMetadata = response => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_GET_META_SUCCESS,
    notificationsMetadata: response
  });
};

export const failureGetNotificationsMetadata = error => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_GET_META_FAILURE
  });
};

export const getNotificationsMetadata = (params) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + `notifications/${queryParams ? `?${queryParams}` : ''}`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetNotificationsMetadata());
      },
      successCB: response => {
        dispatch(receiveGetNotificationsMetadata(response));
      },
      failureCB: error => {
        dispatch(failureGetNotificationsMetadata(error));
      }
    })
  );
};

/**********************************************************************
 *   Update notifications
 **********************************************************************/
export const requestUpdNotifications = () => dispatch => {
    dispatch({
      type: Actions.NOTIFICATIONS_UPD_REQUEST
    });
  };

  export const receiveUpdNotifications = response => dispatch => {
    dispatch({
      type: Actions.NOTIFICATIONS_UPD_SUCCESS,
      notifications: response
    });
  };

  export const failureUpdNotifications = error => dispatch => {
    dispatch({
      type: Actions.NOTIFICATIONS_UPD_FAILURE
    });
  };

  export const updateNotifications = (notifications, status, resolve, success_cb) => dispatch => {
    const singleNotification = notifications.length === 1;
    const path = `notifications/${singleNotification ? `${notifications[0]}` : ''}`;
    const body = singleNotification ? { status, resolve} : {status: status, resolve, ids: notifications};
    return dispatch(
      FWFetch({
        url: baseUrl + path,
        method: "PUT",
        body: JSON.stringify(body),
        requestCB: () => {
          dispatch(requestUpdNotifications());
        },
        successCB: response => {
          dispatch(receiveUpdNotifications(response));
          success_cb(response);
        },
        failureCB: error => {
          dispatch(failureUpdNotifications(error));
        }
      })
    );
  };

/**********************************************************************
 *   Delete notifications
 **********************************************************************/
 export const requestDelNotifications = () => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEL_REQUEST
  });
};

export const receiveDelNotifications = response => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEL_SUCCESS,
    notifications: response
  });
  dispatch(
    alertMsgMessage({
      color: 'success',
      text: 'Notifications deleted successfully'
    })
  );
};

export const failureDelNotifications = error => dispatch => {
  dispatch({
    type: Actions.NOTIFICATIONS_DEL_FAILURE
  });
};

export const deleteNotifications = (filters, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "notifications",
      method: "DELETE",
      body: JSON.stringify({filters}),
      requestCB: () => {
        dispatch(requestDelNotifications());
      },
      successCB: response => {
        dispatch(receiveDelNotifications(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureDelNotifications(error));
      }
    })
  );
};