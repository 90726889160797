import React, { Component } from "react";
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import {
  DHCPFieldValidator,
  IPv4FieldValidator,
  IPv6FieldValidator,
  PublicIPValidator,
  metricValidator,
  mtuValidator,
  isOverlap
} from "../../utils/Validators";
import { Badge, Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import PathLabelSelectBox from '../items/PathLabelSelectBox';
import PublicIPModal from '../items/PublicIPModal';
import GatewayModal from '../items/GatewayModal';
import InternetAccessIcon from '../items/InternetAccessIcon';
import LinkStatusIcon from '../items/LinkStatusIcon';
import './DeviceInfo.css';
import ConfigureInterface from './configureInterface/ConfigureInterface';
import StaticDnsModal from "../items/StaticDnsModal";
import BandwidthModal from '../items/BandwidthModal';
import InterfaceModal from "../items/InterfaceModal";
import VlansModal from "../items/VlansModal";
import ColumnsModal from "../items/ColumnsModal";
import { loadLocalSettings, saveLocalSettings } from '../../utils/localSettings';
import { getInterfaceLockReasons, getBridgedInterface } from '../../utils/DevicesUtils';
import { TT, LanguageContext } from '../../containers/Language'
import { store } from "../../App";
import CreateDHCPAutomatically, { isSuggestInterfaceToHaveDhcpServer } from "../items/CreateDHCPAutomaticallyModal";
import { isDeviceVersionGreaterEquals } from "../../utils/DevicesUtils";

export const createIpField = (ip, mask) => {
  return ip === "" ? "" : mask === "" ? ip : ip + "/" + mask;
};
export const convertIsAssignedField = (isAssigned) => {
  return isAssigned ? 'Yes' : 'No';
};

export const convertIfcTypeField = (type) => {
  return type === "NONE" ? 'None' : type;
};

export const convertRoutingField = (protocol) => {
  return protocol === "NONE" ? 'None' : protocol;
};

/*
  defaultColumnsOrder object represents the order and shown/hidden columns state
  of the interfaces table, based on dataField value as a key.
  Negative values represent hidden columns,
  not defined values are fixed in the table (not orderable/hidable)
*/
const defaultColumnsOrder = {
  type: 1,
  isAssigned: 2,
  IPv4: 3,
  gateway: 4,
  metric: 5,
  PublicIP: 6,
  pathlabels: 7,
  routing: 8,
  IPv6: -1,
  bandwidthMbps: -2,
  dhcp: -3,
  mtu: -4,
  MAC: -5,
};

class InterfacesCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      interfacesOp: "",
      interfaceEntry: {},
      valid: true,
      isPublicIPModalOpen: false,
      isStaticDnsModalOpen: false,
      isGatewayModalOpen: false,
      isBandwidthModalOpen: false,
      isVlansModalOpen: false,
      isDeleteVlanModalOpen: false,
      isInterfaceModalOpen: false,
      isColumnsModalOpen: false,
      columnsOrder: defaultColumnsOrder,
      allowDoubleClick: true,
      labels: [],
      qosPoliciesObject: {},
      qosPoliciesOptions: [],
      dhcpConfirmationModal: {
        show: false,
        ifc: null
      }
    };

    this.ifSelectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: [],
      onSelect: (row, isSelect) => {
        console.log("onSelect: " + JSON.stringify(row) + ", " + isSelect);
      },
      onSelectAll: (isSelect, rows) => {
        console.log("onSelectAll: " + JSON.stringify(rows) + ", " + isSelect);
      }
    };

    this.handleLabelsChange = this.handleLabelsChange.bind(this);
    this.getLabels = this.getLabels.bind(this);
    this.togglePublicIPModal = this.togglePublicIPModal.bind(this);
    this.handlePublicIPChange = this.handlePublicIPChange.bind(this);
    this.toggleGatewayModal = this.toggleGatewayModal.bind(this);
    this.toggleStaticDnsModal = this.toggleStaticDnsModal.bind(this);
    this.handleGatewayChange = this.handleGatewayChange.bind(this);
    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    this.toggleBandwidthModal = this.toggleBandwidthModal.bind(this);
    this.handleBandwidthChange = this.handleBandwidthChange.bind(this);
    this.handleInterfaceChange = this.handleInterfaceChange.bind(this);
    this.handleAddVlan = this.handleAddVlan.bind(this);
    this.handleDeleteVlan = this.handleDeleteVlan.bind(this);
    this.toggleVlansModal = this.toggleVlansModal.bind(this);
    this.toggleDeleteVlanModal = this.toggleDeleteVlanModal.bind(this);
    this.populateInterface = this.populateInterface.bind(this);
    this.toggleInterfaceModal = this.toggleInterfaceModal.bind(this);
    this.toggleColumnsModal = this.toggleColumnsModal.bind(this);
    this.headerFormatterLinkStatus = this.headerFormatterLinkStatus.bind(this);
    this.renderRowButtons = this.renderRowButtons.bind(this);
  }

  componentDidMount() {
    let { columnsOrder } = loadLocalSettings('device-interfaces-card');
    if (columnsOrder) {
      this.setState({ columnsOrder });
    };
    this.props.getPathLabels(resp => {
      this.setState({ labels: resp })
      this.forceUpdate();
    });
    this.props.getQOSPoliciesList(resp => {
      const qosPoliciesOptions = [];
      const qosPoliciesObject = {};
      for (const policy of resp) {
        let policyName = policy.name;
        // check if that is the default device policy
        if (this.props.device?.policies?.qos?.policy?._id === policy._id) {
          policyName = policyName + ' (default)';
        }
        qosPoliciesOptions.push({ value: policy._id, label: policyName });
        qosPoliciesObject[policy._id] = policy.name;
      };
      this.setState({ qosPoliciesObject, qosPoliciesOptions })
      this.forceUpdate();
    });
  }

  populateInterface(row, callback) {
    const vlans = [];
    if (!row.parentDevId && this.props.interfaces) {
      for (const ifc of this.props.interfaces) {
        if (ifc.parentDevId === row.devId) {
          const lockReasons = getInterfaceLockReasons(ifc, this.props.device, this.props.deviceTunnels, this.props.dhcp);
          vlans.push({ ...ifc, tag: ifc.vlanTag, lockReasons });
        }
      }
    }
    let lockReasons = {};
    if (row.parentDevId) {
      lockReasons = getInterfaceLockReasons(row, this.props.device, this.props.deviceTunnels, this.props.dhcp);
    }
    this.props.changeForm('updateInterface')({
      ...row,
      lockReasons,
      metric: +row.metric,
      dnsServers: row.dnsServers.join(', '),
      dnsDomains: row.dnsDomains.join(', '),
      vlans
    });
    this.setState({ currentRow: { ...row } }, callback) ;
  }

  handleLabelsChange(selectedOptions, actionMeta) {
    const { name } = actionMeta;
    const ifc = this.props.interfaces.find(ifcEntry => ifcEntry._id === name)
    const labels = (selectedOptions || []).map(label => { return label.orig })
    ifc.pathlabels = labels;
    this.props.changeForm('updateInterface')({ ...this.props.row, pathlabels: labels });
    this.props.updateUserInputState('interfaces', true)
  }

  handlePublicIPChange(result, _id) {
    const ifc = this.props.interfaces.find(ifcEntry => ifcEntry._id === _id)
    ifc.PublicIP = result.PublicIP;
    ifc.useStun = result.useStun;
    ifc.useFixedPublicPort = result.useFixedPublicPort;
  }

  handleStaticDnsChange(result, _id) {
    const ifc = this.props.interfaces.find(ifcEntry => ifcEntry._id === _id)
    ifc.dnsServers = result.dnsServers.split(/\s*,\s*/).filter(v => v !== '');
    ifc.dnsDomains = result.dnsDomains.split(/\s*,\s*/).filter(v => v !== '');
    ifc.useDhcpDnsServers = result.useDhcpDnsServers;
  }

  handleConfigurationChange(result = null, close=true, success_msg=null) {
    if (result) {
      this.props.saveInterfaceConfiguration(this.state.interfaceEntry._id, result, success_msg);
    }

    if (close) {
      this.setState({
        interfacesOp: ""
      });
    }
  }

  handleGatewayChange(result, _id) {
    const ifc = this.props.interfaces.find(ifcEntry => ifcEntry._id === _id)
    ifc.gateway = result.gateway;
    ifc.monitorInternet = result.monitorInternet;
  }

  async getLabels() {
    return this.renderPathLabels(this.state.labels);
  }

  renderPathLabels(labels) {
    const convertedLabels = (labels || []).map(label => {
      return {
        orig: { ...label },
        value: label._id,
        label: (
          <Badge
            style={{ backgroundColor: label.color }}
            key={label._id}
            pill
          >
            {label.name}
          </Badge>
        )
      };
    })
    return convertedLabels;
  }

  toggleStaticDnsModal() {
    this.props.updateUserInputState('interfaces', true)
    this.setState({
      isStaticDnsModalOpen: !this.state.isStaticDnsModalOpen
    });
  }

  togglePublicIPModal() {
    this.props.updateUserInputState('interfaces', true)
    this.setState({
      isPublicIPModalOpen: !this.state.isPublicIPModalOpen
    });
  }

  toggleGatewayModal() {
    this.props.updateUserInputState('interfaces', true)
    this.setState({
      isGatewayModalOpen: !this.state.isGatewayModalOpen
    });
  }

  handleBandwidthChange(result, _id) {
    const ifc = this.props.interfaces.find(ifcEntry => ifcEntry._id === _id)
    ifc.bandwidthMbps = result.bandwidthMbps;
    ifc.qosPolicy = result.qosPolicy;
  }

  toggleBandwidthModal() {
    this.props.updateUserInputState('interfaces', true)
    this.setState({
      isBandwidthModalOpen: !this.state.isBandwidthModalOpen
    });
  }

  vlanTagChanged(prevDevId, newDevId, updateRequired, dhcp, staticRoutes, firewallRules) {
    for (const dhcpRecord of dhcp) {
      if (dhcpRecord.interface === prevDevId) {
        dhcpRecord.interface = newDevId;
        updateRequired.dhcp = true;
      }
    }
    for (const staticRoute of staticRoutes) {
      if (staticRoute.ifname === prevDevId) {
        staticRoute.ifname = newDevId;
        updateRequired.staticRoutes = true;
      }
    }
    for (const rule of firewallRules) {
      const { destination } = rule.classification || {};
      if (destination?.ipProtoPort?.interface === prevDevId) {
        rule.classification.destination.ipProtoPort.interface = newDevId;
        updateRequired.firewall = true;
      }
      if (rule.interfaces) {
        const newRuleInterfaces = [];
        for (const ifcDevId of rule.interfaces) {
          if (ifcDevId === prevDevId) {
            updateRequired.firewall = true;
            newRuleInterfaces.push(newDevId);
          } else {
            newRuleInterfaces.push(ifcDevId);
          }
        };
        if (updateRequired.firewall) {
          rule.interfaces = newRuleInterfaces;
        }
      }
    }
  }

  handleInterfaceChange(result) {
    let maxMetric = 1;
    const { vlans } = result;
    const updatedInterface = {
      ...result,
      dnsServers: result.dnsServers.split(/\s*,\s*/).filter(v => v !== ''),
      dnsDomains: result.dnsDomains.split(/\s*,\s*/).filter(v => v !== '')
    };
    if (result.parentDevId) {
      const newDevId = `vlan.${result.vlanTag}.${result.parentDevId}`;
      if (result.devId !== newDevId) {
        updatedInterface.devId = newDevId;
        updatedInterface.name = result.name.split('.')[0] + '.' + result.vlanTag;
      }
    }
    const updatedVlans = {};
    const updatedInterfaces = [];
    const { devId, name, driver } = this.state.currentRow;
    for (const ifc of this.props.interfaces) {
      if (ifc.type === 'WAN' && maxMetric < +ifc.metric) {
        maxMetric = +ifc.metric;
      }
      if (vlans) {
        const vlan = vlans.find(vlan => vlan.devId === ifc.devId);
        if (vlan) {
          // existing sub-interface should be updated
          updatedVlans[vlan.tag] = true;
          updatedInterfaces.push({
            ...ifc,
            devId: `vlan.${vlan.tag}.${devId}`,
            name: `${name}.${vlan.tag}`,
            vlanTag: vlan.tag,
            type: vlan.type,
            IPv4: vlan.IPv4,
            dhcp: vlan.dhcp,
            gateway: vlan.gateway,
            vlans: null,
          });
          continue;
        } else if (ifc.parentDevId === this.state.currentRow.devId) {
            // removed sub-interface
            const idParts = ifc.devId.split('.');
            if (idParts.length > 2 && idParts[0] === 'vlan' && idParts[1]) {
              const vlanTag = idParts[1];
              const parentDevId = idParts.slice(2).join('.');
              if (parentDevId === ifc.parentDevId) {
                updatedVlans[vlanTag] = true;
              }
            }
            continue;
        }
      };
      if (ifc.devId === this.state.currentRow.devId) {
        updatedInterfaces.push({ ...ifc, ...updatedInterface });
      } else {
        updatedInterfaces.push(ifc);
      };
    };

    const updateRequired = {
      dhcp: false,
      staticRoutes: false,
      firewall: false
    };
    const { dhcp, staticRoutes, firewallRules } = store.getState().devices || {};
    if (result.parentDevId) {
      const newDevId = `vlan.${result.vlanTag}.${result.parentDevId}`;
      if (result.devId !== newDevId) {
        this.vlanTagChanged(result.devId, newDevId, updateRequired, dhcp, staticRoutes, firewallRules);
      }
    }
    for (const vlan of vlans) {
      const newVlanDevId = `vlan.${vlan.tag}.${devId}`;
      if (vlan.devId && vlan.devId !== newVlanDevId) {
        this.vlanTagChanged(vlan.devId, newVlanDevId, updateRequired, dhcp, staticRoutes, firewallRules);
      }
      if (!updatedVlans[vlan.tag]) {
        // add a new VLAN sub-interface
        updatedInterfaces.push({
          ...updatedInterface,
          _id: vlan.tag + '.' + devId,
          parentDevId: devId,
          devId: 'vlan.' + vlan.tag + '.' + devId,
          driver: driver,
          isAssigned: result.isAssigned,
          name: name + '.' + vlan.tag,
          vlanTag: '' + vlan.tag,
          IPv4: vlan.IPv4,
          IPv4Mask: '',
          IPv6: '',
          IPv6Mask: '',
          gateway: vlan.gateway,
          metric: vlan.type === 'WAN' ? ++maxMetric : '',
          dhcp: vlan.dhcp,
          PublicIP: '',
          PublicPort: '',
          internetAccess: '',
          pathlabels: [],
          qosPolicy: null,
          linkStatus: '',
          routing: vlan.type === 'LAN' ? 'OSPF' : 'None',
          ospf: vlan.type === 'LAN' ? { area: '0' } : '',
          vlans: null,
          locked: false,
          type: vlan.type
        });
      }
    };
    if (updateRequired.dhcp) {
      this.props.saveDHCP(dhcp);
    }
    if (updateRequired.staticRoutes) {
      this.props.saveDeviceStaticRoutes(staticRoutes);
    }
    if (updateRequired.firewall) {
      this.props.saveDeviceFirewallRules(firewallRules);
    }
    this.props.saveDeviceInterfaces(updatedInterfaces);
    if (result.deviceType === 'lte') {
      const data = { ...this.props.configureLteInterface };
      // we changed the fields names to prevent user/password autofill.
      // before sending to server, we renamed those fields back.
      data.user = data.lteUser;
      data.password = data.ltePassword;
      delete data.lteUser;
      delete data.ltePassword;
      this.props.saveInterfaceConfiguration(result._id, data);
    } else if (result.deviceType === 'wifi') {
      const wifiConfig = {};
      if (this.props.configureWifi24.enable) {
        wifiConfig['2.4GHz'] = { ...this.props.configureWifi24 };
      }
      if (this.props.configureWifi5.enable) {
        wifiConfig['5GHz'] = { ...this.props.configureWifi5 };
      }
      this.props.saveInterfaceConfiguration(result._id, wifiConfig);
    }
    this.props.resetForm('updateInterface')();
  }

  handleAddVlan() {
    const { isAssigned, type } = this.props.row;
    const vlans = [...this.props.row.vlans];
    let maxTag = 0;
    for (const vlan of vlans) {
      if (maxTag < vlan.tag) {
        maxTag = +vlan.tag
      }
    }
    vlans.push({
      isAssigned: isAssigned,
      tag: maxTag + 1,
      type: type !== 'TRUNK' ? type : 'LAN',
      dhcp: type === 'LAN' || type === 'TRUNK' ? 'no' : 'yes',
      IPv4: '',
      gateway: '',
      locked: false,
    });
    this.props.changeForm('updateInterface')({ ...this.props.row, vlans });
  }

  handleDeleteVlan() {
    this.props.saveDeviceInterfaces(this.props.interfaces.filter(
      ifc => ifc.devId !== this.state.currentRow.devId
    ));
    this.toggleDeleteVlanModal();
  }

  toggleVlansModal() {
    this.props.updateUserInputState('interfaces', true)
    this.setState({
      isVlansModalOpen: !this.state.isVlansModalOpen
    });
  }

  toggleDeleteVlanModal() {
    this.props.updateUserInputState('interfaces', true)
    this.setState({
      isDeleteVlanModalOpen: !this.state.isDeleteVlanModalOpen
    });
  }

  toggleInterfaceModal() {
    this.setState({
      isInterfaceModalOpen: !this.state.isInterfaceModalOpen
    });
  }

  toggleColumnsModal() {
    this.setState({
      isColumnsModalOpen: !this.state.isColumnsModalOpen
    });
  }

  headerFormatterLinkStatus(column, colIndex, { sortElement, filterElement }) {
    return (
      <Button
        color="default"
        data-tip
        data-for={"setup-table"}
        size=""
        style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem' }}
        className=""
        onClick={(e) => {
          e.stopPropagation();
          this.toggleColumnsModal();
        }}
      >
        <FontAwesomeIcon icon="columns" size="1x" fixedWidth />
        <ReactTooltip id={"setup-table"}>
          <span><TT>Modify Columns</TT></span>
        </ReactTooltip>
      </Button>
    );
  }

  renderRowButtons(row, device, deviceTunnels, dhcp) {
    const toTTString = this.context.toTTString;
    const { agentVersion } = this.props.deviceStatus || {};
    // allowed to add VLAN only for dpdk interfaces
    if (!['dpdk', 'vlan'].includes(row.deviceType) || !isDeviceVersionGreaterEquals(agentVersion, '6.2.0')) {
      return '';
    } else if (row.parentDevId) {
      const lockReasons = Object.values(getInterfaceLockReasons(row, device, deviceTunnels, dhcp));
      return <>
        {
        <span data-tip data-for={"delete-vlan" + row._id}>
        <Button
          color="default"
          className="action-btn"
          disabled={lockReasons.length > 0}
          onClick={() => {
            this.setState({ currentRow: { ...row } }) ;
            this.toggleDeleteVlanModal();
          }}
        >
          <FontAwesomeIcon icon="trash-alt" fixedWidth />
          <ReactTooltip id={"delete-vlan" + row._id}>
            <span>
            {lockReasons.length > 0
              ? lockReasons.map(toTTString).join('. ')
              : <TT>Delete VLAN sub-interface</TT>
            }
            </span>
          </ReactTooltip>
        </Button>
        </span>
          }
      </>;
    }
    return <span className='text-right'>
      {
      <Button
        color="default"
        className="action-btn"
        data-tip
        data-for={"vlans-" + row.devId}
        onClick={() => this.populateInterface(row, this.toggleVlansModal)}
      >
        <FontAwesomeIcon icon='plus' fixedWidth />
        <ReactTooltip id={"vlans-" + row.devId}>
          <span><TT>VLAN sub-interfaces</TT></span>
        </ReactTooltip>
      </Button>
      }
    </span>;
  }

  showCreateDhcpEntryModal = ifc => {
    this.setState({ dhcpConfirmationModal: { show: true, ifc: ifc }});
  }

  closeDhcpConfirmationModal = () => {
    this.setState({ dhcpConfirmationModal: { show: false, ifc: null } });
  }

  render() {

    const toTTString = this.context.toTTString
    const metricsArr = this.props.interfaces &&
      this.props.interfaces.filter(i => i.type === 'WAN').map(i => +i.metric);
    const hasDuplicateMetrics = metricsArr && metricsArr.length !== new Set(metricsArr).size;
    const hasLanDhcp = (this.props.interfaces ?? []).some(i => i.type === 'LAN' && i.dhcp === 'yes')
    const { columnsOrder } = this.state;

    const ifColumns = [
      { text: toTTString("rowID"), dataField: "_id", hidden: true },
      {
        text: toTTString(""),
        dataField: "linkStatus",
        sort: false,
        editable: false,
        hidden: false,
        events: { onClick: () => {}},
        headerStyle: (colum, colIndex) => {
          return { textAlign: "center", verticalAlign: "top", padding: '0.25rem' };
        },
        headerFormatter: this.headerFormatterLinkStatus,
        style: { padding: '0.25rem' },
        formatter: (val, row) => {
          return (
            <Button
              color="default"
              className="action-btn"
              data-tip data-for={"modify-interface" + row._id}
              onClick={() => this.populateInterface(row, this.toggleInterfaceModal)}
            >
              <FontAwesomeIcon icon="cog" fixedWidth />
              <ReactTooltip id={'modify-interface'+row._id}>
                <span>
                  <TT>Configure interface</TT>
                </span>
              </ReactTooltip>
            </Button>
          )
        }
      },
      {
        text: toTTString("Name"),
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "140px" };
        },
        events: { onClick: () => {}}, // this trick will update the cells after the state changes
        formatExtraData: {
          device: this.props.device,
          deviceTunnels: this.props.deviceTunnels,
          dhcp: this.props.dhcp
        },
        formatter: (val, row, index, extraData) => {
          let intType = ['dpdk', 'vlan'].includes(row.deviceType) ? '' : row.deviceType;

          if (intType === "lte") {
            intType = `(${toTTString('LTE')})`
          } else if (intType === "wifi") {
            intType = `(${toTTString('WiFi')})`
          } else if (intType === "pppoe") {
            intType = `(${toTTString('PPPoE')})`
          }

          const link = (
            <div className="d-flex d-flex align-items-center text-nowrap">
              <div className="mr-2">
                <LinkStatusIcon
                  deviceStatus={this.props.deviceStatus}
                  row={row}
                />
              </div>
              <div>
                {val} <b>{intType}</b>
              </div>
              <div className='show-on-hover' style={{ width: '100%', textAlign: 'right' }}>
                <span style={{ marginLeft: '10px' }}>{this.renderRowButtons(row, extraData.device, extraData.deviceTunnels, extraData.dhcp)}</span>
              </div>
              {['wifi', 'lte'].includes(row.deviceType) &&
              <div>
                <Button
                  color="default"
                  data-tip
                  className="show-on-hover interfaces-cog-icon"
                  data-for={"setup-if-" + row.name}
                  size="sm"
                  onClick={() => this.setState({ interfacesOp: "update", interfaceEntry: row })}
                >
                  <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                  <ReactTooltip id={"setup-if-"+row.name}>
                    <span><TT>Setup Interface</TT></span>
                  </ReactTooltip>
                </Button>
              </div>
              }
            </div>
          )
          return link;
        }
      },
      {
        text: toTTString("Type"),
        dataField: "type",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "115px" };
        },
        editable: (value, row) => row.isAssigned === 'Yes' && !['lte', 'wifi', 'pppoe'].includes(row.deviceType),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
        }},
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row }) => (row.parentDevId ? ['LAN', 'WAN'] : ['LAN', 'WAN', 'TRUNK'])
            .map(t => ({ value: t, label: toTTString(t)}))
        }
      },
      {
        text: toTTString("Assigned"),
        dataField: "isAssigned",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "150px" };
        },
        editable: (value, row) => !row.parentDevId,
        editor: {
          type: Type.SELECT,
          options: [
            { value: "No", label: toTTString("No") },
            { value: "Yes", label: toTTString("Yes") }
          ]
        },
        validator: (newVal, row) => DHCPFieldValidator(newVal === 'No', row, this.props.dhcp),
        style: (cell, row, rowIndex, colIndex) => {
          return {
            color: cell === "Yes" ? "green" : "red"
          };
        },
        formatter: (val, row) => {
          const explanationText = val === 'No' ? 'Unassigned interface is managed by the device host' :
            row.parentDevId ? 'The VLAN sub-interface assignment is controlled by the parent interface' : '';
          return <>
            <TT>{val}</TT>
            {explanationText !== '' ? <>
            <span className="show-on-hover helpTooltip ml-3 mr-3" data-tip data-for={'isAssignedTip' + row.devId}></span>
            <ReactTooltip id={'isAssignedTip' + row.devId}>
              <span style={{"fontSize": "0.8rem"}}>
                <TT>{explanationText}</TT>
              </span>
            </ReactTooltip>
            </> : ''}
          </>;
        }
      },
      {
        text: toTTString("MAC"),
        dataField: "MAC",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "100px" };
        }
      },
      {
        text: toTTString("DHCP/Static"),
        dataField: "dhcp",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "145px" };
        },
        editable: (value, row) => row.isAssigned === 'Yes' &&
          ['LAN', 'WAN'].includes(row.type) && !['lte', 'wifi', 'pppoe'].includes(row.deviceType),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
        }},
        formatter: (val, row) => {
          if (row.type === 'TRUNK') {
            return toTTString('n/a');
          }
          return <>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                {val === "yes" ? toTTString("DHCP") : toTTString("Static")}
              </div>

              <div>
                {row.isAssigned === "Yes" && row.type === "WAN" ?
                  <div className="show-on-hover">
                    <Button
                      color="default"
                      data-tip
                      data-for={"setup-dns-if-" + row.name}
                      size="sm"
                      className="interfaces-cog-icon"
                      onClick={(e) => {
                        // this button is a child of the column which already has 'onclick' event for the "select" editor
                        // So, we have to prevent further propagation of current onclick.
                        e.stopPropagation();

                        this.setState({ currentRow: { ...row } });
                        this.toggleStaticDnsModal();
                      }}
                    >
                      <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                      <ReactTooltip id={"setup-dns-if-" + row.name}>
                        <span><TT>Setup DNS</TT></span>
                      </ReactTooltip>
                    </Button>
                  </div>
                : ''}
              </div>
            </div>
          </>
        },
        editor: {
          type: Type.SELECT,
          options: [
            { value: "no", label: toTTString("Static") },
            { value: "yes", label: toTTString("DHCP") }
          ]
        },
        validator: (newVal, row) => DHCPFieldValidator(newVal === 'yes', row, this.props?.dhcp ?? [])
      },
      {
        text: toTTString("IPv4"),
        dataField: "IPv4",
        sort: true,
        editable: (value, row) => row.isAssigned === 'Yes' && row.dhcp !== 'yes' &&
          !['lte'].includes(row.deviceType) && ['LAN', 'WAN'].includes(row.type),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
        }},
        formatter: (cellContent, row, rowIndex, { interfaces }) => {
          if ((!row.IPv4 && row.dhcp === 'yes') || (row.type === 'TRUNK')) {
            return toTTString('n/a');
          };

          if (getBridgedInterface(row, interfaces).length > 0) {
            return (
              <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {row.IPv4}
                <FontAwesomeIcon icon="network-wired" size="1x" fixedWidth data-tip data-for={"bridge-" + row._id} />
                <ReactTooltip id={"bridge-" + row._id}>
                  <span><TT>Bridge Mode</TT></span>
                </ReactTooltip>
              </span>);
          }

          return row.IPv4;
        },
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "200px" };
        },
        validator: (newValue, row, column) => {
          const isValid = IPv4FieldValidator(newValue, row, column);
          if (isValid !== true) {
            return isValid;
          }

          const dhcpEntry = this.props.dhcp.find(d => d.interface === row.devId);
          if (!dhcpEntry) {
            return true;
          };

          if (!isOverlap(newValue, dhcpEntry.rangeStart + '/32')) {
            return { valid: false, message: 'DHCP server range on this interface is not matched'}
          }
          return true;
        },
        formatExtraData: {
          interfaces: this.props.interfaces
        }
      },
      {
        text: toTTString("GW"),
        dataField: "gateway",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "145px" };
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
        }},
        editable: (value, row) => row.isAssigned === 'Yes' && row.dhcp !== 'yes'
          && row.type === 'WAN' && !['lte', 'wifi'].includes(row.deviceType),
        formatter: (cellContent, row, rowIndex) => {
          if (row.type === 'TRUNK') {
            return toTTString('n/a');
          }
          return row.type !== 'LAN' ? (
            <div>
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <InternetAccessIcon
                    deviceStatus={this.props.deviceStatus}
                    row={row}
                  />
                </div>
                <div>
                  {!row.gateway && row.dhcp === 'yes' ? toTTString('n/a') : row.gateway}
                </div>

                {row.isAssigned === 'Yes' && row.type === 'WAN' ?
                  <div className="show-on-hover ml-auto">
                    <Button
                      color="default"
                        data-tip
                        data-for={"setup-gw-" + row._id}
                        className="interfaces-cog-icon"
                        size="sm"
                        onClick={() => {
                          this.props.changUpdateInterfaceForm(row);
                          this.setState({ currentRow: {...row}});
                          this.toggleGatewayModal();
                        }}
                      >
                        <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                        <ReactTooltip id={"setup-gw-"+row._id}>
                          <span><TT>Setup Gateway</TT></span>
                        </ReactTooltip>
                      </Button>
                  </div>
                : ''}
              </div>
            </div>
          ) : ''
        },
        validator: PublicIPValidator
      },
      {
        text: toTTString("Metric"),
        dataField: "metric",
        sort: true,
        editable: (value, row) => row.isAssigned === 'Yes' && row.type === 'WAN' && row.deviceType !== 'pppoe',
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "100px" };
        },
        formatter: (value, row) => row.type === 'WAN' ? Number(value) : '',
        validator: metricValidator
      },
      {
        text: toTTString("MTU"),
        dataField: "mtu",
        type: "number",
        sort: true,
        editable: (value, row) => row.isAssigned === 'Yes' && row.deviceType !== 'pppoe' &&
          !row.parentDevId && ['LAN', 'WAN', 'TRUNK'].includes(row.type),
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "90px" };
        },
        formatter: (value, row) => row.parentDevId ? '' : +value || 1500,
        validator: mtuValidator
      },
      {
        text: toTTString("QoS"),
        dataField: "bandwidthMbps",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "145px" };
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {}
        },
        editable: false,
        formatter: (cellContent, row, rowIndex) => {
          const { deviceStatus } = this.props;
          const { isAssigned, type, bandwidthMbps, qosPolicy } = row;
          if (isAssigned !== 'Yes' || type !== 'WAN' || !isDeviceVersionGreaterEquals(deviceStatus.agentVersion, '6.0.0')) {
            return '';
          }
          return (
            <div>
              <div className="d-flex align-items-center font-size-80-percentage">
                <div>
                  {!bandwidthMbps ? toTTString('n/a') : `${bandwidthMbps.rx || '-'}/${bandwidthMbps.tx || '-'} Mbps`}
                  <br/>
                  <small className="text-muted font-size-70-percentage">
                    {qosPolicy ? (typeof qosPolicy === 'string' ? this.state.qosPoliciesObject[qosPolicy] : qosPolicy.name) : ''}
                  </small>
                </div>
                <div className="show-on-hover ml-auto">
                  <Button
                    color="default"
                      data-tip
                      data-for={"setup-bandwidth-" + row._id}
                      className="interfaces-cog-icon"
                      size="sm"
                      onClick={() => {
                        this.props.changUpdateInterfaceForm(row);
                        this.setState({ currentRow: {...row}});
                        this.toggleBandwidthModal();
                      }}
                    >
                      <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
                      <ReactTooltip id={"setup-bandwidth-"+row._id}>
                        <span><TT>Setup</TT>{' '}<TT>QoS</TT>{'/'}<TT>Bandwidth</TT></span>
                      </ReactTooltip>
                    </Button>
                </div>
              </div>
            </div>
          )
        }
      },
      {
        text: toTTString("IPv6"),
        dataField: "IPv6",
        sort: true,
        editable: (value, row) => row.isAssigned === 'Yes' && row.dhcp !== 'yes' &&
          ['LAN', 'WAN'].includes(row.type),
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "100px" };
        },
        validator: IPv6FieldValidator
      },
      {
        text: toTTString("Public IP"),
        dataField: "PublicIP",
        sort: true,
        editable: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
        }},
        formatter: (cellContent, row, rowIndex) => {
          return row.type !== 'LAN' ?
            <div className="d-flex align-items-center">
              <div>
                <div style={{ minWidth: '150px'}}>
                  {row.isPublicAddressRateLimited ? (
                  <span className="mr-1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      size="1x"
                      fixedWidth
                      data-tip
                      data-for={"public-addr-blocked-" + row._id}
                      color="#ebbc02"
                    />
                    <ReactTooltip id={"public-addr-blocked-" + row._id}>
                      <span>
                        <TT>The public IP/Port of this interface is changing at a high rate</TT>.
                        <TT>Recommended action: Disable STUN</TT><br/>
                        <TT>Click on "Sync" button to re-enable self-healing</TT>.
                      </span>
                    </ReactTooltip>
                  </span>
                  ) : ''}

                  {row.monitorInternet && row.useStun && row.internetAccess !== 'yes' ? toTTString('n/a') : row.PublicIP}
                </div>

                <div>
                  <small>
                    {(row.monitorInternet && row.useStun && row.internetAccess !== 'yes')
                      || !row.PublicIP ? '' : row.useStun && row.NatType ? row.NatType : toTTString('Static')}
                  </small>
                </div>

              </div>

              {row.isAssigned === 'Yes' && row.type === 'WAN' ?
                <div className="show-on-hover" style={{ width: '100%', textAlign: 'right' }}>
                    <Button
                      color="default"
                      data-tip
                      data-for={"setup-public-" + row._id}
                      size="sm"
                      className="interfaces-cog-icon"
                      onClick={() => {
                        this.props.changUpdateInterfaceForm(row);
                        this.setState({ currentRow: {...row}});
                        this.togglePublicIPModal();
                      }}
                    >
                      <FontAwesomeIcon icon="cog" fixedWidth size="lg"/>
                      <ReactTooltip id={"setup-public-" + row._id}>
                        <span><TT>Setup Public IP / Port / Stun</TT></span>
                      </ReactTooltip>
                    </Button>
                  </div>
                : ''}
          </div> : ''
        },
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "160px" };
        },
        validator: PublicIPValidator
      },
      {
        text: toTTString("Path Labels"),
        dataField: "pathlabels",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "200px" };
        },
        style: (cell, row, rowIndex, colIndex) => {
          return { overflow: "inherit" };
        },
        formatter: (cellContent, row, rowIndex, { labels }) => {
          return row.type !== 'WAN' || row.isAssigned !== 'Yes' ? '' : (
            <PathLabelSelectBox
              className='multi-select-box'
              id={row._id}
              values={row.pathlabels}
              options={labels}
              onChange={this.handleLabelsChange}
            />
          );
        },
        formatExtraData: {
          labels: this.state.labels,
          valid: this.state.valid
        }
      },
      {
        text: toTTString("Routing"),
        dataField: "routing",
        sort: true,
        editable: (value, row) => row.isAssigned === 'Yes' && ['LAN', 'WAN'].includes(row.type),
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left", verticalAlign: "top", minWidth: "130px" };
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            const ospf = { value: "OSPF", label: toTTString("OSPF") + " - (" + toTTString("Enable") + " & " + toTTString("Publish network")  + ")"};
            const bgp =  { value: "BGP", label: toTTString("BGP") + " - (" + toTTString("Publish network") + ")" };
            const ospfAndBgp =  { value: "OSPF,BGP", label: toTTString("OSPF") + ' + ' + toTTString("BGP") };
            const none = { value: "None", label: toTTString("None") }
            if (row.type === 'LAN') {
              return [none, ospf, bgp, ospfAndBgp]
            } else if (row.type === 'WAN') {
              return [none]
            }
          }
        }
      },
    ].map(c => ({
      ...c,
      hidden: c.dataField && columnsOrder[c.dataField] !== undefined ? (columnsOrder[c.dataField] <= 0) : c.hidden,
      order: c.dataField && columnsOrder[c.dataField] !== undefined ? columnsOrder[c.dataField] : (c.order || 0),
    })).sort((a, b) => a.order === b.order ? 0 : a.order < b.order ? -1 : 1);

     return (
      <React.Fragment>
        {
          this.state.interfacesOp === "update" ? (
            <ConfigureInterface
              interface={this.state.interfaceEntry}
              deviceStatus={this.props.deviceStatus}
              onSubmit={this.handleConfigurationChange}
              getInterfaceStatus={(interfaceId, cb) => this.props.getInterfaceStatus(this.props.device._id, interfaceId, cb)}
              performInterfaceAction={(interfaceId, data, cb=null, success_msg=null, req_msg=null) => this.props.performInterfaceAction(this.props.device._id, interfaceId, data, cb, success_msg, req_msg) }
              alertMsgMessage={this.props.alertMsgMessage}
              resetPinForm={this.props.resetPinForm}
              resetLteForm={this.props.resetLteForm}
              changeForm={this.props.changeForm}
              resetForm={this.props.resetForm}
              setInitial={this.props.setInitial}
            />
          ) : (
            <>
            {hasDuplicateMetrics ?
            <div className="alert alert-warning alert-small col-md-12" role="alert">
              <TT>Warning: Some WAN interfaces have duplicate metrics! Duplicate metrics for assigned interfaces are not allowed. Duplicate metrics for unassigned interfaces might cause networking issues</TT>
            </div> : ''}
            {hasLanDhcp ?
            <div className="alert alert-warning alert-small col-md-12" role="alert">
              <TT>Enabling DHCP on LAN may impact network settings and routes. It is mainly used on cloud to set the LAN routes</TT>
            </div> : ''}

            <BootstrapTable
              wrapperClasses={'scroll-x interfaces-card-table'}
              striped
              hover
              condensed
              keyField="_id"
              data={this.props.interfaces}
              columns={ifColumns}
              rowClasses={'hover-area'}
              rowStyle={(row) => {
                if (row.isAssigned !== 'Yes') {
                  return { color : 'grey' };
                }
              }}
              noDataIndication={toTTString("No Interfaces available")}
              defaultSorted={[{ dataField: "name", order: "asc" }]}
              rowEvents={{
                onDoubleClick: (e, row) => setTimeout(() => {
                  if (this.state.allowDoubleClick)
                    this.populateInterface(row, this.toggleInterfaceModal);
                }, 1)
              }}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                timeToCloseMessage: 30000,
                onStartEdit: (oldValue, newValue, row, column) => {
                  this.setState({ allowDoubleClick: false });
                  if (this.state.valid) {
                    this.setState({valid: false})
                    this.props.updateUserInputState('interfaces', false)
                  }
                },
                afterSaveCell: (oldValue, newValue, row, column) => {
                  this.setState({ allowDoubleClick: true });
                  // Reset relevant fields upon changing the interface type
                  if (column.dataField === 'type') {
                    // set 'routing' field to 'None' if interface type is not 'LAN'
                    row.routing = newValue !== 'LAN' ? 'None' : row.routing;

                    // clear IP address if interface type is 'TRUNK'
                    row.IPv4 = newValue === 'TRUNK' ? '' : row.IPv4;

                    // Clear WAN default GW when switching from WAN interface
                    row.gateway = newValue === 'WAN' ? row.gateway : '';
                    row.metric = newValue === 'WAN' ? row.metric : '';
                  }
                  if (column.dataField === 'isAssigned') {
                    // update the new value for all nested VLAN interfaces
                    this.props.saveDeviceInterfaces(this.props.interfaces.map(ifc => {
                      if (ifc.parentDevId === row.devId && !this.props.dhcp.some(d => d.interface === ifc.devId)) {
                        ifc.isAssigned = newValue
                      }
                      return ifc;
                    }));
                  }

                  // show modal to the user if to create DHCP entry automatically for the interface.
                  // Show it only if it is LAN, with Static IP, and only if interface changed from unassigned to assigned
                  if (isSuggestInterfaceToHaveDhcpServer(row, this.props.dhcp)) {
                    if (['isAssigned', 'IPv4', 'dhcp', 'type'].includes(column.dataField)) {
                      const origInterface = this.props.device.interfaces.find(i => i.devId === row.devId);
                      if (!origInterface?.isAssigned) {
                        const bridgedInterfaces = getBridgedInterface(row, this.props.interfaces);
                        if (bridgedInterfaces.length === 0 || !this.props.dhcp.some(d => bridgedInterfaces.some(b => b.devId === d.interface))) {
                          this.showCreateDhcpEntryModal(row)
                        }
                      }
                    }
                  }
                  this.setState({valid: true})
                  this.props.updateUserInputState('interfaces', true)
                }
              },
              )}
            />
            </>
          )
        }

        <CreateDHCPAutomatically
          show={this.state.dhcpConfirmationModal.show}
          close={this.closeDhcpConfirmationModal}
          ifc={this.state.dhcpConfirmationModal.ifc}
        />

        { this.state.isStaticDnsModalOpen ? (
          <StaticDnsModal
            isOpen = {true}
            toggle = {this.toggleStaticDnsModal}
            ifc = {this.state.currentRow}
            resetForm = {this.props.resetForm('staticDns')}
            changeForm={this.props.changeForm('staticDns')}
            onSubmit = {result => {
              this.handleStaticDnsChange(result, this.state.currentRow._id)
              this.toggleStaticDnsModal();
            }}
          />
        ) : null}

        <PublicIPModal
          isOpen = {this.state.isPublicIPModalOpen}
          toggle = {this.togglePublicIPModal}
          row = {this.state.currentRow}
          orgInfo = {this.props.device?.orgInfo}
          version = {this.props.device?.versions?.agent}
          changeForm={this.props.changUpdateInterfaceForm}
          onSubmit = {result => {
            this.handlePublicIPChange(result, this.state.currentRow._id)
            this.togglePublicIPModal();
          }}
        />
        <GatewayModal
          isOpen = {this.state.isGatewayModalOpen}
          toggle = {this.toggleGatewayModal}
          row = {this.state.currentRow}
          deviceStatus = {this.props.deviceStatus}
          onSubmit = {result => {
            this.handleGatewayChange(result, this.state.currentRow._id)
            this.toggleGatewayModal();
          }}
        />
        <BandwidthModal
          isOpen = {this.state.isBandwidthModalOpen}
          toggle = {this.toggleBandwidthModal}
          qosPolicies = {this.state.qosPoliciesOptions}
          row = {this.state.currentRow}
          onSubmit = {result => {
            this.handleBandwidthChange(result, this.state.currentRow._id)
            this.toggleBandwidthModal();
          }}
        />
        { this.state.isVlansModalOpen ? (
          <VlansModal
            isOpen = {true}
            toggle = {this.toggleVlansModal}
            device = {this.props.device}
            dhcp = {this.props.dhcp}
            deviceTunnels = {this.props.deviceTunnels}
            interfaces = {this.props.interfaces}
            row = {this.props.row}
            handleAddVlan = {this.handleAddVlan}
            changeForm={this.props.changeForm}
            setFormErrors={this.props.setFormErrors}
            onSubmit = {result => {
              this.handleInterfaceChange(result);
              this.toggleVlansModal();
            }}
          />
        ) : ''}
        { this.state.isInterfaceModalOpen ? (
          <InterfaceModal
            isOpen = {true}
            toggle = {this.toggleInterfaceModal}
            device = {this.props.device}
            dhcp = {this.props.dhcp}
            saveDHCP = {this.props.saveDHCP}
            deviceTunnels = {this.props.deviceTunnels}
            interfaces = {this.props.interfaces}
            row = {this.props.row}
            handleAddVlan = {this.handleAddVlan}
            qosPolicies = {this.state.qosPoliciesOptions}
            deviceStatus={this.props.deviceStatus}
            labels={this.state.labels}
            handleLabelsChange={this.handleLabelsChange}
            handleConfigurationChange={this.handleConfigurationChange}
            getInterfaceStatus={(interfaceId, cb) => this.props.getInterfaceStatus(this.props.device._id, interfaceId, cb)}
            performInterfaceAction={(interfaceId, data, cb=null, success_msg=null, req_msg=null) => this.props.performInterfaceAction(this.props.device._id, interfaceId, data, cb, success_msg, req_msg) }
            alertMsgMessage={this.props.alertMsgMessage}
            resetPinForm={this.props.resetPinForm}
            resetLteForm={this.props.resetLteForm}
            changeForm={this.props.changeForm}
            resetForm={this.props.resetForm}
            setFormErrors={this.props.setFormErrors}
            onSubmit = {result => {
              this.handleInterfaceChange(result);
              this.toggleInterfaceModal();
            }}
          />
        ) : ''}
        { this.state.isColumnsModalOpen ? (
          <ColumnsModal
            isOpen = {true}
            allColumns = {ifColumns}
            toggle = {this.toggleColumnsModal}
            defaultColumnsOrder={defaultColumnsOrder}
            onSubmit = {columnsOrder => {
              this.setState({ columnsOrder });
              saveLocalSettings('device-interfaces-card', { columnsOrder });
              this.toggleColumnsModal();
            }}
          />
        ) : ''}
        <Modal isOpen={this.state.isDeleteVlanModalOpen} toggle={this.toggleDeleteVlanModal}>
          <ModalHeader toggle={this.toggleDeleteVlanModal}><TT>Delete VLAN interface</TT></ModalHeader>
          <ModalBody>
            <div className="mb-3"><TT>Are you sure to delete VLAN interface?</TT></div>
            <Button color="danger" onClick={this.handleDeleteVlan}><TT>Delete</TT></Button>
            <Button className="float-right" color="outline-secondary" onClick={this.toggleDeleteVlanModal}><TT>Cancel</TT></Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    configureWifi24: state.configureWifi24,
    configureWifi5: state.configureWifi5,
    configureLteInterface: state.configureLteInterface,
    row: state.updateInterface
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFormErrors: (model, errors) => { dispatch(actions.setErrors(model, errors))},
});

export default connect(mapStateToProps, mapDispatchToProps)(InterfacesCard);

