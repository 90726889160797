import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Button
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
import {
  required,
  maxLength,
  minLength,
  isColorValid,
  validateLabelName,
  validateLabelDescription
} from "../../utils/Validators";
import { FWTextInput } from "../Common";
import "./PathLabels.css"
import { TT, LanguageContext  } from '../../containers/Language'

class PathLabelAttribute extends Component {
  getColorLabel(color) {
    return (
      <Label
        style={{
          backgroundColor: color,
          width: "37px",
          height: "37px",
          borderRadius: "3px"
        }}
      ></Label>
    );
  }

  render() {

    const {
      model,
      name,
      text,
      validators,
      messages,
      color,
      handleChange,
    } = this.props;

    return (
      <Row className="form-group">
        <Label htmlFor={name.toLowerCase()} md={2}>
          {name}
        </Label>
        <Col md={9} className="offset-md-1">
          <div className="container">
            {color ? this.getColorLabel(color) : ""}
            <Control.text
              model={model}
              id={name.toLowerCase()}
              name={name.toLowerCase()}
              placeholder={text}
              onChange={(event) => { handleChange(event.nativeEvent.target.value || "#") }}
              component={FWTextInput}
              withFieldValue
              validators={validators}
            ></Control.text>
          </div>
          <Errors
            className="text-danger"
            model={model}
            show="touched"
            messages={messages}
          />
        </Col>
      </Row>
    );
  }
}

class PathLabelOp extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.props.resetForm();
    this.state = {
      color: "#44ad8e"
    }
    this.colorsArray = [
      "#1b6996",
      "#1f78d1",
      "#007bff",
      "#04942f",
      "#06c940",
      "#20c997",
      "#ad0707",
      "#e60707",
      "#e85151",
      "#f0de1f",
      "#dbd80f",
      "#ffa10a"
    ]
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
  }

  componentDidMount() {
    const { op, id } = this.props;

    if (op === "Update") {
      this.props.getPathLabel(id, resp => {
        this.props.changeForm(
          {
            _id: resp._id,
            name: resp.name,
            description: resp.description,
            color: resp.color,
            type: resp.type === "DIA" ? true : false
          },
          { silent: true }
        )
        this.setState({ color: resp.color })
        }
      );
    }
  }

  handleSubmit(values) {
    const { op } = this.props;
    const labelValues = { ...values };
    labelValues.type = labelValues.type ? "DIA" : "Tunnel";

    if (op === "Add") {
      this.props.addPathLabel(labelValues, this.props.resetForm);
    } else {
      this.props.updPathLabel(labelValues, this.props.resetForm);
    }
  }

  handleColorChange(color) {
    if (color !== this.state.color) {
      this.setState({ color })
    }
  }

  handleColorClick(color) {
    // Dispatch a change action to update
    // the color in the redux form as well
    this.props.updateColor(
      color,
      { silent: true }
    )
    this.handleColorChange(color);
  }

  getColorButtons() {
    const buttons = this.colorsArray.map(color => {
        return(
          <Button
            key={color}
            className="color-button"
            style={{ backgroundColor: color }}
            onClick={() => this.handleColorClick(color)}
          ></Button>
        )
      })
    return buttons
  }

  render() {
    const toTTString = this.context.toTTString
    const { op } = this.props;
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/pathlabels"><TT>Path Labels</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>{`${op}`}</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4>{(op === 'Add')?<TT>Add</TT>:<TT>Update</TT>} <TT>Path Label</TT></h4>
        <div className="col-md-9">
          <Card id="pathLabelCard">
            <CardBody>
              <Form model="pathLabel" onSubmit={this.handleSubmit}>
                <PathLabelAttribute
                  model='.name'
                  name={toTTString("Name")}
                  text={toTTString("Path Label Name")}
                  handleChange={() => {}}
                  validators={{
                    required: required,
                    minLength: minLength(3),
                    maxLength: maxLength(30),
                    name: validateLabelName
                  }}
                  messages={{
                    required: toTTString("Required field"),
                    minLength: toTTString("Length must be at least 3"),
                    maxLength: toTTString("Length must be at most 30"),
                    name: toTTString("Invalid path label name format")
                  }}
                ></PathLabelAttribute>
                <PathLabelAttribute
                  model='.description'
                  name={toTTString("Description")}
                  text={toTTString("Path Label Description")}
                  handleChange={() => {}}
                  validators={{
                    maxLength: maxLength(50),
                    name: validateLabelDescription
                  }}
                  messages={{
                    maxLength: toTTString("Length must be at most 50"),
                    name: toTTString("Invalid path label description format")
                  }}
                ></PathLabelAttribute>
                <PathLabelAttribute
                  model='.color'
                  name={toTTString("Color")}
                  text={this.state.color}
                  validators={{ color: isColorValid }}
                  messages={{ color: toTTString("Invalid path label color") }}
                  color={this.state.color}
                  handleChange={this.handleColorChange}
                ></PathLabelAttribute>
                <Row className="form-group">
                  <Col md={{ size: 10, offset: 3 }}>
                    {this.getColorButtons()}
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="type" md={3}>
                    <TT>Direct Internet Access</TT>
                    <ReactTooltip id="DIATip">
                        <span style={{ fontSize: "0.8rem" }}>
                          <TT>Select this if the label will be used
                          for sending packets directly over the
                          internet</TT>.
                        </span>
                      </ReactTooltip>
                      <span className="helpTooltip" data-tip data-for="DIATip" />
                  </Label>
                  <Col md={3}>
                    <Label className="FWswitch">
                      <Control.checkbox model=".type" id="type" name="type" />{" "}
                      <span className="FWslider round" />
                    </Label>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={{ size: 10, offset: 3 }}>
                    <Control.button
                      model="pathLabel"
                      disabled={{ valid: false }}
                      className="btn btn-primary"
                    >
                      <TT>{this.props.op}</TT>
                    </Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default PathLabelOp;