import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Card, CardBody } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { required, maxLength, minLength, validateTokenName} from '../../utils/Validators';
import { FWTextInput } from '../Common';
import { TT, LanguageContext  } from '../../containers/Language'

class AddToken extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      servers: []
    }
  }

  componentDidMount() {
    this.props.getServersList(data => {
      this.setState({servers: data})
    })
  }

  handleSubmit(values) {
    console.log('Adding Token: ' + JSON.stringify(values));
    this.props.addToken(values, this.props.resetForm);
  }

  render() {
    const toTTString = this.context.toTTString
    return(
      <React.Fragment>
        <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
              <BreadcrumbItem><Link to="/tokens"><TT>Tokens</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>Add Token</TT></BreadcrumbItem>
            </Breadcrumb>
        <h4><TT>Add Token</TT></h4>
        <div className="col-md-9">
          <Card id='addTokenCard'>
            <CardBody>
              <Form model="addToken" onSubmit={(values) => this.handleSubmit(values)}>
                <Row className="form-group">
                  <Label htmlFor="name" md={2}><TT>Token Name</TT></Label>
                  <Col md={9}>
                    <Control.text model=".name" id="name" name="name" placeholder={toTTString("Token Name")}
                      component={FWTextInput} withFieldValue
                      validators={
                        {	
                          required: required,
                          minLength: minLength(3),
                          maxLength: maxLength(15),
                          name: validateTokenName
                        }
                      }
                    />
                    <Errors className="text-danger" model=".name" show="touched" 
                      messages={
                        {	
                          required: toTTString('Required field'),
                          minLength: toTTString('Length must be at least 3'),
                          maxLength: toTTString('Length must be at most 15'),
                          name: toTTString('Invalid token name format')
                        }
                      }
                    />
                  </Col>
                </Row>

                { this.state.servers.length > 1 ? (
                  <Row className="form-group">
                    <Label htmlFor="name" md={2}><TT>flexiManage Server</TT></Label>
                    <Col md={9}>
                      <Control.select
                        model=".server"
                        id="server"
                        name="server"
                        placeholder={toTTString("Rest Server")}
                        className="form-control"
                        validators={{
                          required: required
                        }}
                      >
                        <option key={0} value={''}>{toTTString("Select Server")}</option>
                        {
                          this.state.servers.map((server, idx) =>
                            <option key={idx + 1} value={server}>{server}</option>
                          )
                        }
                      </Control.select>
                      <Errors className="text-danger" model=".server" show="touched"
                        messages={{
                          required: toTTString('Required field')
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row className="form-group">
                  <Col md={{size:10, offset: 2}}>
                    <Control.button model="addToken" disabled={{ valid: false }}
                      className="btn btn-primary"><TT>Add</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default AddToken;