import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Label,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';
import { Control, Form, Errors, actions } from 'react-redux-form';
import Draggable from "react-draggable";
import { FWTextInput } from "../Common";
import { getBridgedInterface, getMajorVersion, getMinorVersion } from '../../utils/DevicesUtils';
import VlansTable from '../items/VlansTable';
import { TT, LanguageContext } from '../../containers/Language'
import {
  isIP4,
  isIP4WithMask,
  isIPList,
  isDomainList,
  PublicIPValidator,
  isNumber,
  minValue,
  metricValidator,
  mtuValidator,
  vlanTagValidator,
  isOverlap
} from "../../utils/Validators";

import ConfigureInterface from '../devices/configureInterface/ConfigureInterface';
import PathLabelSelectBox from '../items/PathLabelSelectBox';
import InternetAccessBadge from '../items/InternetAccessBadge';
import FwCollapse from '../items/FwCollapse';
import { Button } from "reactstrap";
import AddDHCP, { getNewDhcpEntry} from "../devices/DHCP/Add";
import cloneDeep from 'lodash/cloneDeep';
import { isDeviceVersionGreaterEquals } from "../../utils/DevicesUtils";

const changeToNumber = (model, value) => {
  return (dispatch) => {
    dispatch(actions.change(model, +value));
  };
}

const BandwidthBlock = props => {
  const { toTTString } = useContext(LanguageContext);
  return <>
    <Row className="form-group-compact">
      <Label htmlFor="qosPolicy" md={4}>
        <TT>QoS Policy</TT>
      </Label>
      <Col md={8}>
        <Control.select
          model=".qosPolicy"
          style={{ maxWidth: '320px' }}
          className="form-control"
        >
          <option key='' value='' >{toTTString('None')}</option>
          {props.qosPolicies && props.qosPolicies.map(p =>
            <option key={p.value} value={p.value}>{p.label}</option>
          )}
        </Control.select>
      </Col>
    </Row>
    <Row className="form-group-compact">
      <Label htmlFor=".bandwidthMbps.tx" md={4}>
        <TT>Bandwidth Tx, Mbps</TT>
      </Label>
      <Col md={8}>
        <Control.text
          type="text"
          model=".bandwidthMbps.tx"
          id=".bandwidthMbps.tx"
          name=".bandwidthMbps.tx"
          placeholder={toTTString("Bandwidth Tx, Mbps")}
          style={{ maxWidth: '100px'}}
          component={FWTextInput}
          changeAction={changeToNumber}
          withFieldValue
          validators={{
            isNumber: isNumber,
            minValue: val => val === "" || minValue(0.5)(val)
          }}
        />
        <Errors
          className="text-danger"
          model=".bandwidthMbps.tx"
          show="touched"
          messages={{
            isNumber: toTTString("Invalid bandwidth value"),
            minValue: toTTString('Bandwidth value must be greater than 0.5 Mbps')
          }}
        />
      </Col>
    </Row>
    <Row className="form-group-compact">
      <Label htmlFor=".bandwidthMbps.rx" md={4}>
        <TT>Bandwidth Rx, Mbps</TT>
      </Label>
      <Col md={8}>
        <Control.text
          type="text"
          model=".bandwidthMbps.rx"
          id=".bandwidthMbps.rx"
          name=".bandwidthMbps.rx"
          placeholder={toTTString("Bandwidth Rx, Mbps")}
          component={FWTextInput}
          style={{ maxWidth: '100px'}}
          changeAction={changeToNumber}
          withFieldValue
          validators={{
            isNumber: isNumber,
            minValue: val => val === "" || minValue(0.5)(val)
          }}
        />
        <Errors
          className="text-danger"
          model=".bandwidthMbps.rx"
          show="touched"
          messages={{
            isNumber: toTTString("Invalid bandwidth value"),
            minValue: toTTString('Bandwidth value must be greater than 0.5 Mbps')
          }}
        />
      </Col>
    </Row>
  </>
}

const PublicIpBlock = props => {

  const { toTTString } = useContext(LanguageContext);
  const { row } = props;

  const getSourcePort = (version, orgSourcePort) => {
    const major = getMajorVersion(version);
    const minor = getMinorVersion(version);
    // devices from 6.2 support configurable source port
    if (major > 6 || (major === 6 && minor >=2)) {
      return orgSourcePort;
    }
    return '4789';
  }

  const sourcePort = getSourcePort(props.version, props.orgInfo?.vxlanPort)

  return <>
    <Row>
      <Col md={{ offset: 4, size: 8 }} >
        {row.useStun ?
            <small className="text-muted">
              <TT>Public IP setting is disabled when using STUN</TT>
            </small> : ''
          }
      </Col>
    </Row>
    <Row className="form-group-compact align-items-center">
      <Label htmlFor="PublicIP" md={4}>
        <TT>Public IP</TT>
      </Label>
      <Col md={8}>
        <Control.text
          type="text"
          model=".PublicIP"
          id="PublicIP"
          name="PublicIP"
          style={{ maxWidth: '220px' }}
          placeholder={toTTString("Public IP Address")}
          component={FWTextInput}
          disabled={row.useStun}
          withFieldValue
          validators={{
            PublicIP: PublicIPValidator
          }}
        />
        <Errors
          className="text-danger"
          model=".PublicIP"
          show="touched"
          messages={{
            required: toTTString("Required field"),
            PublicIP: toTTString("Invalid Public IP Address")
          }}
        />
      </Col>
    </Row>
    {row.isPublicAddressRateLimited?
      <Col md={12} className="alert alert-warning mb-0">
          <small className="text-muted">
          <TT>The public IP/Port of this interface is changing at a high rate</TT>.
          <TT>Recommended action: Disable STUN</TT>.<br/>
          <TT>Click on "Sync" button to re-enable self-healing</TT>.
          </small>
      </Col>
    : '' }

    <Row className="form-group-compact align-items-center">
      <Label htmlFor="useStun" md={4}>
        <TT>Use STUN</TT>
      </Label>
      <Col md={8}>
        <Label className="FWswitch">
          <Control.checkbox
            model=".useStun"
            id="useStun"
            name="useStun"
            disabled={{ valid: false }}
            onChange={e => {
              if (!e.target.checked) {
                props.changeForm('updateInterface')({...row, useFixedPublicPort: false})
              }
            }}
          />{" "}
          <span className="FWslider round" />
        </Label>
      </Col>
    </Row>
    <Row className="form-group-compact">
      <Label htmlFor="useFixedPublicPort" md={4}>
        <div className="d-flex">
          <div>
            <TT>Force</TT> {sourcePort} <TT>UDP Port</TT>
          </div>
          <div>
            <span className="helpTooltip" data-tip data-for='fixedPortTip'></span>
          </div>
        </div>
        <ReactTooltip id='fixedPortTip'>
          <span style={{"fontSize": "0.8rem"}}>
            <TT>When this option turned on, other devices connected to this interface, will use destination port</TT> {sourcePort}.<br />
            <TT>Use this option when "Port Forwarding" is enabled for this interface</TT>.<br />
            <TT>STUN continues to monitor the Public IP address</TT>.<br />
            <TT tid="invalidOption">This option is invalid when STUN is disabled, in this case, the Public IP is specified manually and the port always uses</TT> {sourcePort}
          </span>
        </ReactTooltip>
      </Label>
      <Col md={8}>
        <Label className="FWswitch">
          <Control.checkbox
            model=".useFixedPublicPort"
            id="useFixedPublicPort"
            name="useFixedPublicPort"
            disabled={() => !row.useStun}
          />{" "}
          <span className="FWslider round" />
        </Label>
      </Col>
    </Row>
  </>
}

const StaticDnsBlock = props => {
  const { toTTString } = useContext(LanguageContext);
  const { row } = props;

  return <>
    {row.dhcp === 'yes'? (
      <Row className="form-group-compact">
        <Label htmlFor="useDhcpDnsServers" md={4}>
          <TT>Get DNS via DHCP</TT>
        </Label>
        <Col md={6}>
          <Label className="FWswitch">
            <Control.checkbox
              model=".useDhcpDnsServers"
              id="useDhcpDnsServers"
              name="useDhcpDnsServers"
            />{" "}
            <span className="FWslider round" />
          </Label>
        </Col>
      </Row>
    ) : null}

    <Row className="form-group-compact">
      <Label htmlFor="dns" md={4}>
        {
          row.useDhcpDnsServers === false || row.dhcp === 'no'? (
            <span className="form-required-star">*</span>
          ) : ''
        }
        <TT>DNS Servers</TT></Label>
      <Col>
        <Control.text
          type="text"
          model=".dnsServers"
          id="dnsServers"
          name="dnsServers"
          style={{ maxWidth: '220px'}}
          placeholder={toTTString("DNS servers")}
          component={FWTextInput}
          withFieldValue
          validators={{
            ipList: val => !val || isIPList(val)
          }}
          disabled={row.useDhcpDnsServers === true && row.dhcp === 'yes'}
        />
        <Errors
          className="text-danger"
          model=".dnsServers"
          show="touched"
          messages={{ ipList: toTTString('Invalid IPv4 Addresses')}}
        />
        <Errors
          className="text-danger"
          model="staticDns"
          show="touched"
          messages={{ serversRequired: toTTString('Required field')}}
        />
      </Col>
    </Row>

    <Row className="form-group-compact">
      <Label htmlFor="dnsDomains" md={4}>
        <TT>Search Domains</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model=".dnsDomains"
          id="dnsDomains"
          name="dnsDomains"
          style={{ maxWidth: '220px'}}
          placeholder={toTTString("Search domains list")}
          component={FWTextInput}
          withFieldValue
          validators={{ isDomainList: val => !val || isDomainList(val, false) }}  
        />
        <Errors
          className="text-danger"
          model=".dnsDomains"
          show="touched"
          messages={{ isDomainList: toTTString('Invalid domains list')}}  
        />
      </Col>
    </Row>
  </>
}

const MonitorInternetBlock = props => {
  return <>
    <Row className="form-group-compact">
      <Label htmlFor="monitorInternet" md={4}>
        <TT>Internet Access</TT>
      </Label>
      <Col md={8}>
        <InternetAccessBadge
          deviceStatus={props.deviceStatus}
          row={props.row}
        />
      </Col>
    </Row>
    <Row className="form-group-compact">
      <Label htmlFor="monitorInternet" md={4}>
        <TT>Monitor Internet</TT>
      </Label>
      <Col md={8}>
        <Label className="FWswitch">
          <Control.checkbox
            model=".monitorInternet"
            id="monitorInternet"
            name="monitorInternet"
          />{" "}
          <span className="FWslider round" />
        </Label>
      </Col>
    </Row>
  </>
}

const IpAddressBlock = props => {
  const { toTTString } = useContext(LanguageContext);
  const { row } = props;
  // Modification of the VLAN ID should be disabled only for specific lock reasons
  const vlanDisabledReasons = row.lockReasons ? ['locked', 'hasTunnel', 'hasDHCPServer']
    .reduce((r, k) => row.lockReasons[k] ? [...r, toTTString(row.lockReasons[k])] : r, []) : [];
  return <>
    <Row className="form-group-compact">
      <Label htmlFor="isAssigned" md={4}>
        <TT>Assigned</TT>
      </Label>
      <Col>
        <Control.select
          model=".isAssigned"
          id="isAssigned"
          name="isAssigned"
          disabled={!!row.parentDevId}
          className="form-control max-width-short"
        >
          {['No', 'Yes'].map(val => (
            <option key={val} value={val}>
              {toTTString(val)}
            </option>
          ))}
        </Control.select>
      </Col>
    </Row>

    <Row className="form-group-compact">
      <Label htmlFor="IPv4" md={4}>
        <TT>IP Address</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={".IPv4"}
          name={".IPv4"}
          id={"IPv4"}
          style={{ maxWidth: '220px'}}
          placeholder={toTTString("IP address")}
          component={FWTextInput}
          withFieldValue
          disabled={row.isAssigned !== 'Yes' || row.dhcp === 'yes' ||
            ['lte'].includes(row.deviceType) || !['LAN', 'WAN'].includes(row.type)
          }
          validators={{
            ip: isIP4WithMask
          }}
        />
        <Errors
          className="text-danger"
          model={".IPv4"}
          show="touched"
          messages={{
            ip: toTTString("Invalid IP Network Format"),
            overlapsWithDhcpRange: toTTString('DHCP server range on this interface is not matched')
          }}
        />
      </Col>
    </Row>

    { row.type === 'WAN' &&
    <Row className="form-group-compact">
      <Label htmlFor="gateway" md={4}>
        <TT>Gateway</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model=".gateway"
          name=".gateway"
          id="gateway"
          style={{ maxWidth: '220px'}}
          placeholder={toTTString("Gateway")}
          component={FWTextInput}
          withFieldValue
          disabled={row.isAssigned !== 'Yes' || row.dhcp === 'yes' || ['lte', 'wifi'].includes(row.deviceType)}
          validators={{
            ip: (ip) => row.dhcp === 'yes' || isIP4(ip)
          }}
        />
        <Errors
          className="text-danger"
          model={".gateway"}
          show="touched"
          messages={{
            ip: toTTString("Invalid IP Address Format"),
          }}
        />
      </Col>
    </Row>
    }

    { row.parentDevId &&
    <Row className="form-group-compact">
      <Label htmlFor="vlanTag" md={4}>
        <TT>VLAN ID</TT>
      </Label>
      <Col>
        { vlanDisabledReasons.length > 0 &&
        <ReactTooltip id='vlanTagTip'>
          <span style={{"fontSize": "0.8rem"}}>
            {vlanDisabledReasons.join('. ')}
          </span>
        </ReactTooltip>
        }
        <span data-tip data-for='vlanTagTip'>
          <Control.text
            type="text"
            model=".vlanTag"
            name=".vlanTag"
            id="vlanTag"
            style={{ maxWidth: '220px'}}
            placeholder={toTTString("VLAN ID")}
            component={FWTextInput}
            withFieldValue
            disabled={row.isAssigned !== 'Yes' || vlanDisabledReasons.length > 0}
            validators={{
              vlanTag: vlanTagValidator
            }}
          />
        </span>
        <Errors
          className="text-danger"
          model={".vlanTag"}
          show="touched"
          messages={{
            vlanTag: toTTString("VLAN tag should be a number between 1 and 4094"),
          }}
        />
      </Col>
    </Row>
    }

    { row.type === 'WAN' &&
    <Row className="form-group-compact">
      <Label htmlFor="metric" md={4}>
        <TT>Metric</TT>
      </Label>
      <Col>
        <Control.text
          type="number"
          model={".metric"}
          name={".metric"}
          id={"metric"}
          placeholder={toTTString("Metric")}
          component={FWTextInput}
          style={{ maxWidth: '100px'}}
          withFieldValue
          disabled={row.isAssigned !== 'Yes' || row.type !== 'WAN' || row.deviceType === 'pppoe'}
          validators={{
            metric: metricValidator
          }}
        />
        <Errors
          className="text-danger"
          model={".metric"}
          show="touched"
          messages={{
            metric: toTTString("Wrong Metric value"),
          }}
        />
      </Col>
    </Row>
    }

    <Row className="form-group-compact">
      <Label htmlFor="mtu" md={4}>
        <TT>MTU</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={".mtu"}
          name={".mtu"}
          id={"mtu"}
          disabled={row.isAssigned !== 'Yes' || !!row.parentDevId || row.deviceType === 'pppoe'}
          placeholder={toTTString("MTU")}
          component={FWTextInput}
          style={{ maxWidth: '100px'}}
          withFieldValue
          validators={{
            mtu: mtuValidator
          }}
        />
        <Errors
          className="text-danger"
          model={".mtu"}
          show="touched"
          messages={{
            mtu: toTTString("Wrong MTU value"),
          }}
        />
      </Col>
    </Row>

    { row.type === 'WAN' &&
    <Row className="form-group-compact">
      <Label htmlFor="pathlabels" md={4}>
        <TT>Path Labels</TT>
      </Label>
      <Col>
        <PathLabelSelectBox
          className='multi-select-box'
          id={row._id}
          style={{ maxWidth: '320px' }}
          values={row.pathlabels}
          options={props.labels}
          onChange={props.handleLabelsChange}
        />
      </Col>
    </Row>
    }
  </>
}

const InterfaceModal = (props) => {
  const { toTTString } = useContext(LanguageContext);
  const [tableValid, setTableValid] = useState(true);
  const [dhcpCreated, setDhcpCreated] = useState(null);
  const [dhcpDeleted, setDhcpDeleted] = useState(null);
  const { agentVersion } = props.deviceStatus || {};

  const { row } = props;
  if (!row) return '';
  const { name, isAssigned, type } = row;

  const routingOptions = [
    { value: "OSPF", label: toTTString("OSPF") + " - (" + toTTString("Enable") + " & " + toTTString("Publish network")  + ")"},
    { value: "BGP", label: toTTString("BGP") + " - (" + toTTString("Publish network") + ")" },
    { value: "OSPF,BGP", label: toTTString("OSPF") + ' + ' + toTTString("BGP") },
    { value: "None", label: toTTString("None") }
  ];

  const interfacesToCheck = getBridgedInterface(row, props.interfaces);
  if (!interfacesToCheck.length) {
    interfacesToCheck.push(row);
  }
  const dhcpServer = props.dhcp?.find(d => interfacesToCheck.some(i => i.devId === d.interface));

  const createDhcpEntryForInterface = (ifc) => {
    const dhcpEntry = getNewDhcpEntry(ifc)
    addDhcpEntry(dhcpEntry)
  }

  const addDhcpEntry = dhcpEntry => {
    const dhcpEntries = [...props.dhcp];
    dhcpEntries.push(dhcpEntry)
    props.saveDHCP(dhcpEntries);
    setDhcpCreated(dhcpEntry)
  }

  const deleteUpdateDhcpEntry = (dhcpEntry, isDelete) => {
    const dhcpEntries = [...props.dhcp];
    const index = dhcpEntries.findIndex(entry => {
      return entry._id === dhcpEntry._id;
    });
    if (index !== -1) {
      if (isDelete) {
        dhcpEntries.splice(index, 1);
        setDhcpDeleted(dhcpEntry)
      } else { // update
        dhcpEntries[index] = dhcpEntry;
      }
      props.saveDHCP(dhcpEntries) // Should be moved to device info update button
    }
  }

  const onClose = () => {
    if (dhcpCreated) {
      deleteUpdateDhcpEntry(dhcpCreated, true)
    }
    if (dhcpDeleted) {
      addDhcpEntry(dhcpDeleted)
    }

    props.toggle();
  }

  return (
  <Draggable handle=".handle-header">
  <Modal
    size='xl'
    scrollable='true'
    isOpen={props.isOpen}
  >
    <Form
      model="updateInterface"
      onSubmit={(res) => {
        if (tableValid) {
          props.onSubmit(res)
          // save dhcp form
          const updatedDhcp = cloneDeep(props.dhcpFormValues);
          updatedDhcp.dns = updatedDhcp.dns.split(/\s*,\s*/);
          deleteUpdateDhcpEntry(updatedDhcp, false);
        }
      }}
      onChange={(values) => {
        const { isAssigned, type, IPv4, gateway, dhcp } = values;
        props.setFormErrors('updateInterface.gateway',
          dhcp === 'yes' || isAssigned !== 'Yes' || type !== 'WAN'
            || isIP4(gateway) ? false : toTTString('Invalid IP Address Format')
        );
        props.setFormErrors('updateInterface.IPv4',
          dhcp === 'yes' || isAssigned !== 'Yes' || !['WAN', 'LAN'].includes(type)
            || (IPv4 !== '' && isIP4WithMask(values.IPv4)) ? false : toTTString('Invalid IP Network Format')
        );

        const dhcpEntry = props.dhcp.find(d => d.interface === row.devId);
        props.setFormErrors('updateInterface.IPv4', {
          overlapsWithDhcpRange: dhcpEntry && !isOverlap(values.IPv4, dhcpEntry.rangeStart + '/32')
        });
      }}
      validators={{
        '': {
          duplicateTags: values => {
            const tags = values.vlans.map(vlan => vlan.tag);
            return tags.length === new Set(tags).size;
          }
        },
        IPv4: {
          overlapsWithDhcpRange: ip => {
            const dhcpEntry = props.dhcp.find(d => d.interface === row.devId);
            if (!dhcpEntry) {
              return true;
            };

            if (!isOverlap(ip, dhcpEntry.rangeStart + '/32')) {
              return false;
            }
            return true;
          }
        }
      }}
    >
      <ModalHeader
        style={{ paddingTop: '0.35rem', paddingBottom: '0.35rem' }}
        className="handle-header"
        toggle={onClose}
      >
        <TT>{`Edit ${name} ${isAssigned !== 'Yes' ? '(not assigned)' : ''}`}</TT>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}
      >
      <Card>
        <CardBody>
          <Row>
            <Col>
              <IpAddressBlock
                row={row}
                handleLabelsChange={props.handleLabelsChange}
                labels={props.labels}
                dhcp={props.dhcp}
              />
            </Col>
            <Col>
              <Row className="form-group-compact">
                <Label htmlFor="type" md={4}>
                  <TT>Type</TT>
                </Label>
                <Col className="d-flex align-items-center">
                  <Control.select
                    model=".type"
                    id="type"
                    name="type"
                    disabled={row.isAssigned !== 'Yes' || ['lte', 'wifi', 'pppoe'].includes(row.deviceType)}
                    className="form-control max-width-short"
                  >
                    {(row.parentDevId ? ['LAN', 'WAN'] : ['LAN', 'WAN', 'TRUNK']).map(type => (
                      <option key={type} value={type}>
                        {toTTString(type)}
                      </option>
                    ))}
                  </Control.select>
                </Col>
              </Row>
              <Row className="form-group-compact">
                <Label  htmlFor="dhcp" md={4}>
                  <TT>DHCP/Static</TT>
                </Label>
                <Col>
                  <Control.select
                    model=".dhcp"
                    id="dhcp"
                    name="dhcp"
                    disabled={row.isAssigned !== 'Yes' || !['LAN', 'WAN'].includes(row.type)
                      || ['lte', 'wifi', 'pppoe'].includes(row.deviceType)}
                    className="form-control max-width-short"
                  >
                    {[{ value: 'no', label: 'Static' },
                      { value:'yes', label: 'DHCP' }].map(({ value, label }) => (
                      <option key={value} value={value}>
                        {toTTString(label)}
                      </option>
                    ))}
                  </Control.select>
                </Col>
              </Row>
              <Row className="form-group-compact">
                <Label  htmlFor="dhcp" md={4}>
                  <TT>MAC</TT>
                </Label>
                <Col>
                  <Control.text
                    type="text"
                    model={".MAC"}
                    name={".MAC"}
                    id={"MAC"}
                    disabled={true}
                    component={FWTextInput}
                    style={{ maxWidth: '220px'}}
                    withFieldValue
                  />
                </Col>
              </Row>

              { type === 'WAN' && <StaticDnsBlock {...props}/> }
            </Col>
          </Row>
        </CardBody>
      </Card>

      { (type === 'WAN' || type === 'TRUNK') && !dhcpServer ? null : ['LAN', 'WAN', 'TRUNK'].includes(type) &&
        <FwCollapse
          title={(
            <div className="d-flex align-items-center justify-content-between">
              {toTTString("DHCP Server")}
            </div>
          )}
          color="default"
        >
          { (type === 'WAN' || type === 'TRUNK') && dhcpServer ? (
            <span style={{ color: 'red' }}>
              <TT>DHCP server is defined on this interface, please remove it before switching interface type</TT>
            </span>
          ) : type === 'LAN' && dhcpServer ? (
            <AddDHCP dhcpEntry={dhcpServer} />
          ) : type === 'LAN' ? (
            <Button
              type="button"
              color="primary"
              onClick={() => createDhcpEntryForInterface(row)}
            >
              Add DHCP
            </Button>
          ) : null}

          {dhcpServer ? (
            <>
              <hr />
              <Button
                type="button"
                className="mr-2"
                color="danger"
                onClick={() => deleteUpdateDhcpEntry(dhcpServer, true)}
              >
                Delete DHCP
              </Button>
            </>
          ) : null}
        </FwCollapse>
      }

      { ['LAN', 'WAN'].includes(type) &&
      <FwCollapse
        title={toTTString("Advanced Options")}
        color="default"
      >
        { type === 'LAN' &&
        <Row>
          <Col md={6}>
            <Row className="form-group-compact">
              <Label htmlFor="routing" md={4}>
                <TT>Routing</TT>
              </Label>
              <Col md={8}>
                <Control.select
                  model=".routing"
                  style={{ maxWidth: '320px' }}
                  className="form-control"
                  disabled={row.isAssigned !== 'Yes' || !['LAN', 'WAN'].includes(row.type)}
                >
                  {routingOptions.map(r => (
                    <option key={r.value} value={r.value}>
                      {r.label}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
          </Col>
        </Row>
        }
        { type === 'WAN' &&
        <Row>
          <Col>
            <BandwidthBlock {...props}/>
            <MonitorInternetBlock {...props}/>
          </Col>
          <Col>
            <PublicIpBlock {...props}/>
          </Col>
        </Row>
        }
      </FwCollapse>
      }
      { row.deviceType === 'dpdk' && !row.parentDevId && isDeviceVersionGreaterEquals(agentVersion, '6.2.0') &&
      <FwCollapse
        title={toTTString("VLAN sub-interfaces")}
        color="default"
      >
        <Row>
          <Col>
            <VlansTable
              hideAddButton={true}
              onStartEdit={() => setTableValid(false)}
              afterSaveCell={() => setTableValid(true)}
              {...props}
            />
            <button
              onClick={() => {
                props.handleAddVlan();
              }}
              type="button"
              className="btn btn-primary"
            >
              <TT>Add VLAN</TT>
            </button>
          </Col>
        </Row>
      </FwCollapse>
      }
      { ['wifi', 'lte'].includes(row.deviceType) &&
      <FwCollapse
        title={toTTString('Configure') + ' ' + toTTString(row.deviceType.toUpperCase())}
        color="default"
      >
        <Row>
          <Col>
            <ConfigureInterface
              interface={row}
              deviceStatus={props.deviceStatus}
              getInterfaceStatus={props.getInterfaceStatus}
              performInterfaceAction={props.performInterfaceAction}
              alertMsgMessage={props.alertMsgMessage}
              resetPinForm={props.resetPinForm}
              resetLteForm={props.resetLteForm}
              changeForm={props.changeForm}
              resetForm={props.resetForm}
              setInitial={props.setInitial}
            />
          </Col>
        </Row>
      </FwCollapse>
      }
      </ModalBody>
      <Card>
        <CardBody
          style={{ paddingTop: '0.35rem', paddingBottom: '0.35rem' }}
        >
          <Row>
            <Col>
              <Errors
                className="text-danger"
                model="updateInterface"
                show="touched"
                messages={{
                  duplicateTags: toTTString("Duplicated VLAN tags are not allowed")
                }}
              />
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col className="text-right">
              <Control.button
                model="updateInterface"
                disabled={!props.dhcpFormValid || { valid: false}}
                className="btn btn-primary btn-ok"
              >
                <TT>Update</TT>
              </Control.button>
              <button
                onClick={onClose}
                type="button"
                className="btn btn-outline-secondary ml-1"
              >
                <TT>Cancel</TT>
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>

  </Modal>
  </Draggable>
  )
}

const mapStateToProps = state => {
  return {
    dhcpFormValid: state.forms.addDHCP.$form.valid,
    dhcpFormValues: state.addDHCP
  }
}

export default connect(mapStateToProps)(InterfaceModal);