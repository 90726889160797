import React, { Component } from "react";
import InfoNotAvailable from "../items/infoNotAvailable";
import { TT, LanguageContext } from '../../containers/Language'
import FwBootstrapTable from "../items/FwBootstrapTable";

class RoutingTableCard extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.getDeviceRoutes(this.props.id, resp => {

      if (resp.error === 'timeout') {
        this.setState({ infoNotAvailable: true });
      } else {
        let id = 0;
        const routesArr = resp.osRoutes.map(route => {
          route.id = id++;
          return route;
        });
        this.setState({
          osRoutesResponse: {
            status: resp.deviceStatus,
            osRoutes: routesArr,
            vppRoutes: resp.vppRoutes,
            infoNotAvailable: false
          }
        });
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      osRoutesResponse: null,
      infoNotAvailable: false
    };
  }

  render() {
    const toTTString = this.context.toTTString

    this.routesColumn = [
      { text: toTTString('rowID'), dataField: "id", hidden: true },
      {
        text: toTTString('Destination'),
        dataField: "destination",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "22%", textAlign: "left" };
        },
      },
      {
        text: toTTString('Gateway'),
        dataField: "gateway",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "22%", textAlign: "left" };
        },
      },
      {
        text: toTTString('Metric'),
        dataField: "metric",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "12%", textAlign: "left" };
        },
      },
      {
        text: toTTString('Protocol'),
        dataField: "protocol",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "22%", textAlign: "left" };
        },
      },
      {
        text: toTTString('Interface'),
        dataField: "interface",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "22%", textAlign: "left" };
        },
      },
    ];

    return (
      <React.Fragment>
        <h6><TT>Routing Table</TT></h6>

        <InfoNotAvailable show={this.state.infoNotAvailable} />

        {
          !this.state.osRoutesResponse ? (
            toTTString("Loading") + "..."
          ) : (
            <>
              <FwBootstrapTable
                pagination={true}
                data={this.state.osRoutesResponse.osRoutes}
                columns={this.routesColumn}
                keyField="id"
                noDataIndication={
                  this.state.osRoutesResponse.status === "disconnected"
                    ? toTTString("Device must be connected to view this tab ")
                    : []
                }
                defaultSorted={[{ dataField: "destination", order: "asc" }]}
                clientFilter={{
                  placeHolder: 'Filter by routes attributes',
                  options: [
                    {
                      category: toTTString("Destination"),
                      categorykey: "destination",
                      type: "text"
                    },
                    {
                      category: toTTString("Gateway"),
                      categorykey: "gateway",
                      type: "text"
                    },
                    {
                      category: toTTString("Metric"),
                      categorykey: "metric",
                      type: "text"
                    },
                    {
                      category: toTTString("Protocol"),
                      categorykey: "protocol",
                      type: "textoptions",
                      options: () => Array.from(new Set(this.state?.osRoutesResponse?.osRoutes?.map(r => r.protocol)))
                    },
                    {
                      category: toTTString("Interface"),
                      categorykey: "interface",
                      type: "textoptions",
                      options: () => Array.from(new Set(this.state?.osRoutesResponse?.osRoutes?.map(r => r.interface)))
                    },
                  ]
                }}
              />
            </>
          )}
      </React.Fragment>
    );
  }
}

export default RoutingTableCard;