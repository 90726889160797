import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createForms } from 'react-redux-form';

import { Main } from './reducers/Main';
import { Sidebar } from './reducers/SideBar';
import { AlertMsg } from './reducers/AlertMsg';
import { Auth } from './reducers/Auth';
import { Devices } from './reducers/Devices';
import { Tokens } from './reducers/Tokens';
import { AppIdentifications } from './reducers/AppIdentifications';
import { Tunnels } from './reducers/Tunnels';
import { Traffic } from './reducers/Traffic';
import { Health } from './reducers/Health';
import { Jobs } from './reducers/Jobs';
import { Notifications } from './reducers/Notifications';
import { Admin } from './reducers/Admin';
import { Tickets } from './reducers/Tickets';
import { Organizations } from './reducers/Organizations';
import { Accounts } from './reducers/Accounts';
import { Members} from './reducers/Members';
import { Invoices, Coupons } from './reducers/Invoices';
import { Portals } from './reducers/Portals';
import { AccessTokens } from './reducers/AccessTokens';
import { PathLabels } from './reducers/PathLabels';
import { MLpolicies } from './reducers/MLPolicies';
import { Applications } from './reducers/Applications';
import { FirewallPolicies } from './reducers/FirewallPolicies';
import { QOSPolicies } from './reducers/QOSPolicies';
import { Configuration } from './reducers/Configuration';
import { Peers } from './reducers/Peers';

import {
  InitialDevice,
  InitialDeviceMsg,
  InitialToken,
  InitialDHCP,
  InitialCoupon,
  InitialStaticRoute,
  InitialUpdToken,
  InitialLogsCard,
  InitialPacketTracesCard,
  InitialOrganization,
  InitialUpdOrganization,
  InitialUserCreateAccount1,
  InitialUserCreateAccount2,
  InitialAddMember,
  InitialUpdateMember,
  InitialUpdateAccount,
  InitialAddAccessToken,
  InitialPathLabel,
  InitialMLPolicy,
  InitialMlRule,
  InitialFirewallPolicy,
  InitialFirewallRule,
  InitialQOSPolicy,
  InitialAppIdentification,
  InitialAddAppIdentificationRule,
  InitialRemoteVpnConfiguration,
  InitialRemoteVpnDeviceConfiguration,
  InitialConfigureWifi5,
  InitialConfigureWifi24,
  InitialConfigureLteInterface,
  InitialInterface,
  InitialDashboardStatsOptions,
  InitialOSPFConfiguration,
  InitialUpdatePin,
  InitialPeer,
  InitialBGPConfiguration,
  InitialBGPNeighbor,
  InitialRoutingFilter,
  InitialRoutingFilterRule,
  initialMfaVerifyForm,
  initialUseRecoveryCode,
  InitialHardwareConfiguration,
  InitialStaticDns,
  InitialModifyNotificationForm,
  InitialModifyTunnelNotificationsForm,
  InitialWebhookSettingsForm,
  InitialVrrpGroup,
  InitialVrrpGroupDevice,
  InitialDhcpOption,
  InitialAdvancedRoutingConfiguration
 } from './Forms';

export const Store = () => {
  const store = createStore(
    combineReducers({
      main: Main,
      sidebar: Sidebar,
      auth: Auth,
      alertmsg: AlertMsg,
      devices: Devices,
      tokens: Tokens,
      appIdentifications: AppIdentifications,
      organizations: Organizations,
      accounts: Accounts,
      members: Members,
      invoices: Invoices,
      coupons: Coupons,
      accesstokens: AccessTokens,
      pathlabels: PathLabels,
      mlpolicies: MLpolicies,
      firewallPolicies: FirewallPolicies,
      qosPolicies: QOSPolicies,
      tunnels: Tunnels,
      traffic: Traffic,
      health: Health,
      jobs: Jobs,
      notifications: Notifications,
      admin: Admin,
      tickets: Tickets,
      portal: Portals,
      applications: Applications,
      configuration: Configuration,
      peers: Peers,
      ...createForms({
        deviceInfo: InitialDevice,
        sendDevice: InitialDeviceMsg,
        configurePeer: InitialPeer,
        addToken: InitialToken,
        addDHCP: InitialDHCP,
        addCoupon: InitialCoupon,
        updToken: InitialUpdToken,
        addStaticRoute: InitialStaticRoute,
        LogsCard: InitialLogsCard,
        PacketTracesCard: InitialPacketTracesCard,
        addOrganization: InitialOrganization,
        updOrganization: InitialUpdOrganization,
        userCreateAccount1: InitialUserCreateAccount1,
        userCreateAccount2: InitialUserCreateAccount2,
        updateAccount: InitialUpdateAccount,
        addMember: InitialAddMember,
        updMember: InitialUpdateMember,
        addAccessToken: InitialAddAccessToken,
        pathLabel: InitialPathLabel,
        mlpolicy: InitialMLPolicy,
        mlRule: InitialMlRule,
        firewallPolicy: InitialFirewallPolicy,
        firewallRule: InitialFirewallRule,
        qosPolicy: InitialQOSPolicy,
        qosTrafficMap: {},
        addAppIdentification: InitialAppIdentification,
        addAppIdentificationRule: InitialAddAppIdentificationRule,
        remoteVpnConfiguration: InitialRemoteVpnConfiguration,
        remoteVpnDeviceConfiguration: InitialRemoteVpnDeviceConfiguration,
        configureLteInterface: InitialConfigureLteInterface,
        configureWifi24: InitialConfigureWifi24,
        configureWifi5: InitialConfigureWifi5,
        updateInterface: InitialInterface,
        DashboardStatsOptions: InitialDashboardStatsOptions,
        OSPFConfiguration: InitialOSPFConfiguration,
        AdvancedRoutingForm: InitialAdvancedRoutingConfiguration,
        BGPConfiguration: InitialBGPConfiguration,
        BGPNeighbor: InitialBGPNeighbor,
        routingFilter: InitialRoutingFilter,
        routingFilterRule: InitialRoutingFilterRule,
        updatePinConfiguration: InitialUpdatePin,
        verifyMfa: initialMfaVerifyForm,
        useRecoveryCode: initialUseRecoveryCode,
        hardwareConfiguration: InitialHardwareConfiguration,
        staticDns: InitialStaticDns,
        vrrpGroup: InitialVrrpGroup,
        vrrpGroupDevice: InitialVrrpGroupDevice,
        dhcpOption: InitialDhcpOption,
        modifyNotificationForm: InitialModifyNotificationForm,
        modifyTunnelNotificationsForm: InitialModifyTunnelNotificationsForm,
        modifyWebhookSettingsForm: InitialWebhookSettingsForm
      })
    }), process.env.REACT_APP_REDUX_LOGGER==="true"? applyMiddleware(thunk, logger):applyMiddleware(thunk)
  );
  return store;
};