import React, {useContext, useState, useEffect} from "react";
import {
  Card,
  CardBody,
  Row,
  Label,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Control, Form, Errors } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { PublicIPValidator } from '../../utils/Validators';
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext } from '../../containers/Language';
import { getMajorVersion, getMinorVersion } from "../../utils/DevicesUtils";

export default function PublicIpModal (props) {
  const { toTTString } = useContext(LanguageContext);
  const [row, setRow] = useState(undefined);

  useEffect(() => {
    if (props.isOpen) {
      setRow(props.row);
    }
  }, [props.row, props.isOpen]);

  if (row === undefined) {
    return '';
  }

  const getSourcePort = (version, orgSourcePort) => {
    const major = getMajorVersion(version);
    const minor = getMinorVersion(version);
    // devices from 6.2 support configurable source port
    if (major > 6 || (major === 6 && minor >=2)) {
      return orgSourcePort;
    }
    return '4789';
  }

  const sourcePort = getSourcePort(props.version, props.orgInfo?.vxlanPort)

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      size="md"
    >
      <ModalHeader toggle={props.toggle}>
        <TT>Public IP Info</TT>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form
              model="updateInterface"
              onSubmit={props.onSubmit}
            >
              <Row>
                <Col md={{ offset: 4, size: 8 }}>
                  {row.useStun ?
                      <small className="text-muted">
                        <TT>Public IP setting is disabled when using STUN</TT>
                      </small> : ''
                    }
                </Col>
              </Row>
              <Row className="form-group align-items-center">
                <Label htmlFor="PublicIP" md={4}>
                  <TT>Public IP</TT>
                </Label>
                <Col md={8}>
                  <Control.text
                    type="text"
                    model=".PublicIP"
                    id="PublicIP"
                    name="PublicIP"
                    placeholder={toTTString("Public IP Address")}
                    component={FWTextInput}
                    disabled={row.useStun}
                    withFieldValue
                    validators={{
                      PublicIP: PublicIPValidator
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".PublicIP"
                    show="touched"
                    messages={{
                      required: toTTString("Required field"),
                      PublicIP: toTTString("Invalid Public IP Address")
                    }}
                  />
                </Col>
              </Row>

              <hr></hr>

              {row.isPublicAddressRateLimited?
                <Col md={12} className="alert alert-warning mb-0">
                    <small className="text-muted">
                    <TT>The public IP/Port of this interface is changing at a high rate</TT>.
                    <TT>Recommended action: Disable STUN</TT>.<br/>
                    <TT>Click on "Sync" button to re-enable self-healing</TT>.
                    </small>
                </Col>
              : '' }

              <Row className="form-group align-items-center">
                <Label htmlFor="useStun" md={4}>
                  <TT>Use STUN</TT>
                </Label>
                <Col md={8}>
                  <Label className="FWswitch">
                    <Control.checkbox
                      model=".useStun"
                      id="useStun"
                      name="useStun"
                      disabled={{ valid: false }}
                      onChange={e => {
                        if (!e.target.checked) {
                          setRow({...row, useStun: false, useFixedPublicPort: false})
                          props.changeForm({...row, useFixedPublicPort: false})
                        } else {
                          setRow({...row, useStun: true})
                          props.changeForm({...row})
                        }
                      }}
                    />{" "}
                    <span className="FWslider round" />
                  </Label>
                </Col>
              </Row>

              <hr></hr>

              <Row className="form-group">
                <Label htmlFor="useFixedPublicPort" md={4}>
                  <div className="d-flex">
                    <div>
                      <TT>Force</TT> {sourcePort} <TT>UDP Port</TT>
                    </div>
                    <div>
                      <span className="helpTooltip" data-tip data-for='fixedPortTip'></span>
                    </div>
                  </div>
                  <ReactTooltip id='fixedPortTip'>
                    <span style={{"fontSize": "0.8rem"}}>
                      <TT>When this option turned on, other devices connected to this interface, will use destination port</TT> {sourcePort}.<br />
                      <TT>Use this option when "Port Forwarding" is enabled for this interface</TT>.<br />
                      <TT>STUN continues to monitor the Public IP address</TT>.<br />
                      <TT tid="invalidOption">This option is invalid when STUN is disabled, in this case, the Public IP is specified manually and the port always uses</TT> {sourcePort}
                    </span>
                  </ReactTooltip>
                </Label>
                <Col md={8}>
                  <Label className="FWswitch">
                    <Control.checkbox
                      model=".useFixedPublicPort"
                      id="useFixedPublicPort"
                      name="useFixedPublicPort"
                      disabled={() => !row.useStun}
                      onChange={e => setRow({...row, useFixedPublicPort: e.target.checked})}
                    />{" "}
                    <span className="FWslider round" />
                  </Label>
                </Col>
              </Row>

              <Row className="form-group">
                <Col xs={6} md={{ size: 3, offset: 4 }}>
                  <Control.button
                    model="updateInterface"
                    disabled={{ valid: false }}
                    className="btn btn-success btn-ok"
                  >
                    <TT>OK</TT>
                  </Control.button>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={props.toggle}
                    type="button"
                    className="btn btn-outline-secondary pull-right"
                  >
                    <TT>Cancel</TT>
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}
