import React, { useState, useContext } from "react";
import { Control, Errors, Form } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';
import ReactTooltipWithLink from '../../../items/ReactTooltipWithLink';
import { FWTextInput } from "../../../Common";
import {
  required,
  isIP4,
  isEmpty,
  minValue,
  isNumber
} from "../../../../utils/Validators";
import {
  getStartEndIPv4,
  getRangeAndMask,
} from "../../../../utils/ipCalculator";
import { TT, LanguageContext } from '../../../../containers/Language'

const DeviceVpnConfiguration = props => {
  const { toTTString } = useContext(LanguageContext)
  const [networks, setNetworks] = useState([]);
  return (
    <>
      <Form
        id="remoteVpnDeviceConfiguration"
        model="remoteVpnDeviceConfiguration"
        onSubmit={props.handleSubmit}
        onChange={vals => {
          // Calculate the requested networks in order to show them to the user

          if (!vals.networkStartIp || !vals.connectionsNumber) return;
          if (!vals.networkStartIp  === '' || !vals.connectionsNumber === '') return;

          // vpn server range should be minimum /29 (8 ips)
          const num = Math.max(vals.connectionsNumber, 8)
          const { range, mask } = getRangeAndMask(num);
          const vpnServerNetworks = [];
          for (let i = 0; i < props.devicesCount; i++) {
            const [startIp] = getStartEndIPv4(vals.networkStartIp, mask, range * i);
            vpnServerNetworks.push(`${startIp}/${mask}`);
          }

          setNetworks(vpnServerNetworks)
        }}
      >
        <Row className="form-group">
          <Label htmlFor="networkStartIp" md={6}>
            <span className="form-required-star">*</span>
            <TT>VPN server start address</TT>
            <ReactTooltipWithLink id="networkStartIpTip">
              <span style={{ fontSize: "0.8rem" }}>
                <TT>The server will allocate addresses to clients out of the given network</TT>
              </span>
            </ReactTooltipWithLink>
          </Label>
          <Col md={6}>
            <Control.text
              model=".networkStartIp"
              id="networkStartIp"
              name="networkStartIp"
              placeholder={toTTString("IP")}
              component={FWTextInput}
              withFieldValue
              validators={{
                required: required,
                isIP4: isIP4,
              }}
            />
            <Errors
              className="text-danger"
              model=".networkStartIp"
              show="touched"
              messages={{
                required: toTTString("Required Field"),
                isIP4: toTTString("Invalid IP Format"),
              }}
            />
          </Col>
        </Row>

        <Row className="form-group align-items-center">
          <Label htmlFor="connectionsNumber" md={6} className="d-flex align-items-center">
            <div>
              <span className="form-required-star">*</span>
              <TT>VPN client connections per installed device</TT>
            </div>
            <ReactTooltipWithLink id="connectionsNumberTip">
              <span style={{ fontSize: "0.8rem" }}>
                <TT>The number of allowed client connections for this VPN Server</TT><br />
                <TT>
                  The actually available VPN connections is limited by the number of
                  licenses defined in the
                </TT>
                <Link to={`/applications/${props.appId}/configurations`} className="link-force-color">
                  <TT> applications configuration screen</TT>
                </Link>
              </span>
            </ReactTooltipWithLink>
          </Label>
          <Col md={6}>
            <Control.text
              model=".connectionsNumber"
              id="connectionsNumber"
              name="connectionsNumber"
              placeholder={toTTString("Number")}
              component={FWTextInput}
              withFieldValue
              validators={{
                required: required,
                isNumber: (val) => {
                  return !isEmpty(val) && isNumber(val) && val >= 1;
                },
                minValue: minValue(1),
              }}
            />
            {networks.length > 0 ? (
              <small className="form-text text-muted">
                <TT>The following networks will be configured</TT>:<br />
                {networks.splice(0, 5).join(', ')}{networks.length >= 5 ? `...${networks[networks.length - 1]}` : null}
              </small>
            ): null}
            <Errors
              className="text-danger"
              model=".connectionsNumber"
              show="touched"
              messages={{
                required: toTTString("Required Field"),
                isNumber: toTTString("Value should be a number"),
                minValue: toTTString("Number should be at least 1"),
                isPowerOfTwo: toTTString("Number should be a power of 2"),
              }}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <Control.button
              form="remoteVpnDeviceConfiguration"
              model="remoteVpnDeviceConfiguration"
              type="submit"
              className={"btn btn-primary action-btn-top"}
            >
              <TT>Save</TT>
            </Control.button>
          </Col>
        </Row>
      </Form>
    </>
  )
}


export default DeviceVpnConfiguration;