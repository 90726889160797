import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TT } from '../containers/Language'

/**
 * Convert firewall rules from backend response
 * @param {Array} rules
 * @param {Array} appIdentifications
 */
export const convertFirewallRules = (rules, appIdentifications) => {
  return Array.isArray(rules) ? rules.map(rule => {
    const resultRule = { ...rule,
      classification: {
        sourceType: 'any',
        source: {
          ipPort: {}
        },
        destinationType: 'any',
        destination: {
          ipProtoPort: {}
        }
      }
    };
    for (const side of ['source', 'destination']) {
      if (rule.classification[side]) {
        const { trafficId, trafficTags, ipPort, ipProtoPort, lanNat } = rule.classification[side];
        if (trafficId) {
          // Convert traffic IDs to names
          const appItem = appIdentifications.find( app => app.id === trafficId);
          const appName = appItem ? appItem.name : trafficId;
          resultRule.classification[side + 'Type'] = 'trafficId';
          resultRule.classification[side].appName = appName;
        } else if (trafficTags) {
          resultRule.classification[side + 'Type'] = 'trafficTags';
          resultRule.classification[side].trafficTags = trafficTags;
        } else if (lanNat) {
          resultRule.classification[side + 'Type'] = 'lanNat';
          resultRule.classification[side].lanNat = lanNat;
        } else if (ipPort) {
          resultRule.classification[side + 'Type'] = 'ipPort';
          resultRule.classification[side].ipPort = ipPort;
        } else if (ipProtoPort) {
          resultRule.classification[side + 'Type'] = 'ipProtoPort';
          resultRule.classification[side].ipProtoPort = ipProtoPort;
        }
      }
    }
    return resultRule;
  }) : [];
};


/**
 * Presentation of the destination in the firewall rules table
 * @param {Object} rule
 */
export const DestinationFormatter = ({ rule, interfaces }) => {
  const { destination, destinationType } = rule.classification;
  const items = [];
  if (destination) {
    const { appName, trafficTags, ipProtoPort, lanNat } = destination;
    if (destinationType === 'trafficId' && appName) {
      items.push(<>
        <FontAwesomeIcon className='mr-1' icon='qrcode' color='grey' fixedWidth/>
        {' '}<TT>Traffic ID</TT>: {appName}
      </>);
    } else if (destinationType === 'trafficTags' && trafficTags) {
      items.push(<>
        <FontAwesomeIcon className='mr-1' icon='hashtag' color='grey' fixedWidth/>
        {' '}<TT>Traffic Tags</TT>: {Object.values(trafficTags).join(', ')}
      </>);
    } else if (rule.direction === 'lanNat' && lanNat) {
      const { match } = lanNat;
      if (match) {
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='sitemap' color='grey' fixedWidth/>
          {' '}<TT>IP</TT>: {match}
        </>);
      }
    } else if (destinationType === 'ipProtoPort' && ipProtoPort) {
      const { ip, ports, protocols } = ipProtoPort;
      if (ip) {
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='sitemap' color='grey' fixedWidth/>
          {' '}<TT>IP</TT>: {ip}
        </>);
      }
      if (ports) {
        const hasMany = ports.includes('-');
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='bezier-curve' color='grey' fixedWidth/>
          {' '}<TT>{hasMany ? 'Ports' : 'Port'}</TT>: {ports}
        </>);
      }
      if (protocols && protocols.length > 0) {
        const hasMany = ipProtoPort.protocols.length > 1;
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='stream' color='grey' fixedWidth/>
          {' '}<TT>{hasMany ? 'Protocols' : 'Protocol'}</TT>: {protocols.join(', ')}
        </>);
      }
      if (rule.direction === 'inbound' && ipProtoPort.interface) {
        let inboundInterface = ipProtoPort.interface;
        if (interfaces) {
          const ifc = interfaces.find(i => i.devId === ipProtoPort.interface);
          if (ifc) {
            inboundInterface = `${ifc.type}-${ifc.name}`;
          }
        }
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='ethernet' color='grey' fixedWidth/>
          {' '}<TT>Interface</TT>: {inboundInterface}
        </>);
      }
    }
  }
  return <div className='col'>
    {items.length ? items.map((item, index) => (
      <div className='row' key={index}>{item}</div>
    )) : <div className='row'>
      <FontAwesomeIcon className='mr-1' icon='asterisk' color='grey' fixedWidth/>
      <TT>Any</TT>
    </div>}
  </div>
};

/**
 * Presentation of the source in the firewall rules table
 * @param {Object} rule
 */
export const SourceFormatter = ({ rule, interfaces }) => {
  const { source, sourceType } = rule.classification;
  const items = [];
  if (source && ['edgeAccess', 'portForward'].includes(rule.inbound)) {
    const { appName, ipPort } = source;
    if (sourceType === 'trafficId' && appName) {
      items.push(<>
        <FontAwesomeIcon className='mr-1' icon='qrcode' color='grey' fixedWidth/>
        {' '}<TT>Traffic ID</TT>: {appName}
      </>);
    } else if (sourceType === 'ipPort' && ipPort) {
      const { ip, ports } = ipPort;
      if (ip) {
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='sitemap' color='grey' fixedWidth/>
          {' '}<TT>IP</TT>: {ip}
        </>);
      }
      if (ports) {
        const hasMany = ports.includes('-');
        items.push(<>
          <FontAwesomeIcon className='mr-1' icon='bezier-curve' color='grey' fixedWidth/>
          {' '}<TT>{hasMany ? 'Ports' : 'Port'}</TT>: {ports}
        </>);
      }
    }
  }
  if (rule.direction === 'lanNat' && rule.classification?.source?.lanNat) {
    const { match, interface: devId } = rule.classification.source.lanNat;
    if (match) {
      items.push(<>
        <FontAwesomeIcon className='mr-1' icon='sitemap' color='grey' fixedWidth/>
        {' '}<TT>IP</TT>: {match}
      </>);
    }
    if (devId) {
      const ifc = interfaces.find(i => i.devId === devId);
      items.push(<>
        <FontAwesomeIcon className='mr-1' icon='ethernet' color='grey' fixedWidth/>
        {' '}<TT>Interface</TT>: {ifc ? `${ifc.type}-${ifc.name}` : devId}
      </>);
    }
  }
  if (rule.direction === 'outbound' && rule.interfaces && rule.interfaces.length > 0) {
    items.push(<>
      <FontAwesomeIcon className='mr-1' icon='ethernet' color='grey' fixedWidth/>
      {' '}<TT>Interfaces</TT>: {rule.interfaces.map(devId => {
        if (interfaces) {
          const ifc = interfaces.find(i => i.devId === devId);
          if (ifc) {
            return `${ifc.type}-${ifc.name}`;
          }
        }
        return devId;
      }).join(', ')}
    </>);
  }
  return <div className='col'>
    {items.length ? items.map((item, index) => (
      <div className='row' key={index}>{item}</div>
    )) : <div className='row'>
      <FontAwesomeIcon className='mr-1' icon='asterisk' color='grey' fixedWidth/>
      <TT>Any</TT>
    </div>}
  </div>
};
