import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Card, CardBody } from 'reactstrap';
import { Control, Form } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { TT, LanguageContext  } from '../../containers/Language'

class UpdMember extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      permissionTo: "account"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }	

  componentDidMount() {
    this.props.getMember(this.props.id, 
      (resp) => {
        this.props.getMemberOptions(resp.to, (optResp) => {
          this.props.changeForm({ _id: resp._id,
            userId: resp.user__id,
            userPermissionTo:resp.to, 
            userEntity: (resp.to ==='organization')?resp.organization__id:(resp.to ==='group')?resp.group:resp.account_name,
            userRole: resp.role });
          this.setState({ permissionTo: resp.to });
      });
    });
  }

  handleSubmit(values) {
    console.log('Updating Member: ' + JSON.stringify(values));
    this.props.updMember(values, this.props.resetForm);
  }

  getUserRole(permissionTo) {
    switch(permissionTo) {
      case 'account':
        return 'owner';
      case 'group':
      case 'organization':
        return 'manager';
      default:
        return 'owner';
    }
  }

  render() {
    const toTTString = this.context.toTTString
    return(
      <React.Fragment>
        <Breadcrumb>
              <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/members"><TT>Users</TT></Link></BreadcrumbItem>
              <BreadcrumbItem active><TT>Update User</TT></BreadcrumbItem>
            </Breadcrumb>
        <h4><TT>Update User</TT></h4>
        <div className="col-md-5">
          <Card id='updMemberCard'>
            <CardBody>
              <Form model="updMember" onSubmit={(values) => this.handleSubmit(values)}>
                <Row className="form-group">
                  <Label htmlFor="userPermissionTo" md={4}>
                    <TT>Permission To</TT>
                  </Label>
                  <Col md={8}>
                    <Control.select
                      model=".userPermissionTo"
                      id="userPermissionTo"
                      name="userPermissionTo"
                      placeholder={toTTString("Account")}
                      className="form-control"
                      onChange={event => {
                        this.props.getMemberOptions(event.target.value,
                          (resp) => this.props.changeFormEntity(this.props.members.options[0].id,{'silent':true}));
                          this.props.changeFormRole(this.getUserRole(event.target.value),{'silent':true});
                        this.setState({ permissionTo: event.target.value })
                      }}
                    >
                    <option value="account" disabled hidden>
                      {toTTString("Account")}
                    </option>
                    <option value={"account"}>{toTTString("Account")}</option>
                    <option value={"group"}>{toTTString("Group")}</option>
                    <option value={"organization"}>{toTTString("Organization")}</option>
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userEntity" md={4}>
                    <TT>Entity</TT>
                  </Label>
                  <Col md={8}>
                    <Control.select
                      model=".userEntity"
                      id="userEntity"
                      name="userEntity"
                      placeholder=""
                      className="form-control"
                      disabled={!this.props.members.options.length}  
                    >
                    {this.props.members.options.length?
                    <option value={this.props.members.options[0].id} disabled hidden>
                      {this.props.members.options[0].value}
                    </option>:null}
                    {this.props.members.options.map(option => <option value={option.id} key={option.id}>{option.value}</option>)}
                    </Control.select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Label htmlFor="userRole" md={4}>
                    <TT>Role</TT>
                  </Label>
                  <Col md={8}>
                    <Control.select
                      model=".userRole"
                      id="userRole"
                      name="userRole"
                      placeholder={toTTString("Owner")}
                      className="form-control"
                    >
                    <option
                      value={ this.getUserRole(this.state.permissionTo)}
                      disabled
                      hidden>
                      { this.state.permissionTo === "account" ? toTTString("Owner") : toTTString("Manager") }
                    </option>
                    {this.state.permissionTo === "account" ? <option value={"owner"}>{toTTString('Owner')}</option> : "" }
                    <option value={"manager"}>{toTTString("Manager")}</option>
                    <option value={"viewer"}>{toTTString("Viewer")}</option>
                    </Control.select>
                  </Col>
                </Row>                                

                <Row className="form-group">
                  <Col md={{size:10, offset: 4}}>
                    <Control.button model="updMember" disabled={{ valid: false }} 
                      className="btn btn-primary"><TT>Update</TT></Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdMember;