import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { TT } from "../../containers/Language";

const ConfirmationModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          {props.question}
        </div>
        {props.color !== '' &&
        <Button color={props.color || 'danger'} disabled={props.disabled} onClick={props.onConfirm}>
          <TT>{props.successText || 'Yes'}</TT>
        </Button>
        }
        {props.doNotShowCancel === true ? null : (
          <Button
            className="float-right"
            color="outline-secondary"
            onClick={props.toggle}
          >
            <TT>Cancel</TT>
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal;