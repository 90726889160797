import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { capitalize } from '../../utils/Conversions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TT, LanguageContext  } from '../../containers/Language';
import MFA from '../users/MFA/MFA';

class Members extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.getAllMembers();

    const showMfaModal = new URLSearchParams(this.props.location.search).get("showMfaModal")
    if (showMfaModal) {
      this.toggleConfigureMfaModal()
      this.props.history.push({ search: ''})
    }

    if (this.statusTimer == null) {
      const timer = setInterval(function(that) {
        // Only update when in focus
        if (document.hasFocus()) {
          that.props.getAllMembers();
        }
      },60000, this);
      this.statusTimer = timer;
    }
  }
  componentWillUnmount() {
    if (this.statusTimer != null) { 
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  componentDidUpdate(prevProps) {
    const showMfaModal = new URLSearchParams(this.props.location.search).get("showMfaModal")
    const prevShowMfaModal = new URLSearchParams(prevProps.location.search).get("showMfaModal")
    if (showMfaModal !== prevShowMfaModal && showMfaModal) {
      this.toggleConfigureMfaModal()
      this.props.history.push({ search: ''})
    }
  }

  constructor(props) {
    super(props);

    this.statusTimer = null;

    this.state = {
      isDelModalOpen: false,
      isResetMfaModalOpen: false,
      isConfigureMfaModalOpen: false,
      isRecoveryCodesModalOpen: false,
      modalEntity: null,
    };

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen
    });
  }

  handleRegenerateRecoveryCodes = row => {
    this.setState({modalEntity: row});
    this.toggleRecoveryCodesModal();
  }

  toggleRecoveryCodesModal = () => {
    this.setState({
      isRecoveryCodesModalOpen: !this.state.isRecoveryCodesModalOpen
    });
  }

  approveRegenerate = () => {
    this.toggleRecoveryCodesModal();
    this.props.history.push({ pathname: '/2fa/recoveryCodes', state: { regenerate: true } })
  }

  handleDelete(row) {
    this.setState({modalEntity: row});
    this.toggleDelModal();
  }

  approveDelete() {
    this.toggleDelModal();
    this.props.delMember(this.state.modalEntity, this.props.getAllMembers);
  }


  toggleResetMfaModal = () => {
    this.setState({
      isResetMfaModalOpen: !this.state.isResetMfaModalOpen
    });
  }

  handleResetMfa = row => {
    this.setState({modalEntity: row});
    this.toggleResetMfaModal();
  }

  approveResetMfa = () => {
    this.toggleResetMfaModal();
    this.props.resetMemberMfa(this.state.modalEntity.user__id, this.props.getAllMembers)
  }

  toggleConfigureMfaModal = () => {
    this.setState({
      isConfigureMfaModalOpen: !this.state.isConfigureMfaModalOpen
    });
  }

  handleOnSelect = (row, isSelect) => {
  }

  handleOnSelectAll = (isSelect, rows) => {
  }

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (this.props.auth.userId && row.user__id === this.props.auth.userId)
      style.backgroundColor = '#fff3c7';
    return style;
  };

  render() {
    const toTTString = this.context.toTTString;

    const loggedInUser = this.props.members.members.find(m => m.user__id === this.props.auth.userId);
    const isAccountOwnerLoggedIn = loggedInUser?.to === 'account' && loggedInUser?.role === 'owner';

    const columns = [
        {text: toTTString('Name'), dataField: 'user_name', sort:true,
            headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'left' };
          }
        }, 
        {text: toTTString('Email'), dataField: 'user_email', sort:true,
          headerStyle: (colum, colIndex) => {
            return { width: '25%', textAlign: 'left' };
          } 
        },
        {text: toTTString('Permission To'), dataField: 'to', sort:true,
          headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            return <TT>{capitalize(cellContent)}</TT>
          }
        },
        {text: toTTString('Entity'), dataField: 'group', sort:true,
          headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            if (row.to === 'account') return (row.account_name);
            if (row.to === 'group') return (row.group);
            if (row.to === 'organization') return (row.organization_name);
            return ('N/A');
          }
        },
        {text: toTTString('Role'), dataField: 'role', sort:true,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            return <TT>{capitalize(cellContent)}</TT>
          }
        },
        {text: toTTString('Actions'), dataField: 'none', sort:false,
          headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'left' };
          },
          formatter: (cellContent, row) => {
            return (
              <div className='d-flex'>
                <div>
                  { this.props.auth.userId && row.user__id === this.props.auth.userId ? null : (
                    <React.Fragment>
                      <Link to={"/members/update/" + row._id}>
                        <Button color="warning" className="action-btn" data-tip data-for='update-a' size="sm">
                          <FontAwesomeIcon icon="cog" fixedWidth />
                        </Button>
                      </Link>
                      <ReactTooltip id='update-a'><span><TT>Update User</TT></span></ReactTooltip>
                      <Button color="danger" className="action-btn" data-tip data-for='delete-a' size="sm"
                        onClick={() => this.handleDelete(row)}>
                        <FontAwesomeIcon icon="trash-alt" fixedWidth />
                      </Button>
                      <ReactTooltip id='delete-a'><span><TT>Delete User</TT></span></ReactTooltip>
                    </React.Fragment>
                  )}
                </div>

                <div>
                  { row?.user_mfa_enabled ? (
                    <>
                      { (isAccountOwnerLoggedIn || (this.props.auth.userId && row.user__id === this.props.auth.userId)) ? ( // logged in user or account owner
                        <>
                          <Button color="danger" className="action-btn"  size="sm" data-tip data-for={'reset-mfa-a' + row.user__id}
                            onClick={() => this.handleResetMfa(row)}>
                            <FontAwesomeIcon icon="qrcode" fixedWidth />
                          </Button>
                          <ReactTooltip id={'reset-mfa-a' + row.user__id}><span><TT>Remove Two-Factor-Authentication</TT></span></ReactTooltip>
                        </>
                      ) : null}

                      { this.props.auth.userId && row.user__id === this.props.auth.userId ? ( // logged in user
                        <>
                          <Button color="info" className="action-btn"  size="sm" data-tip data-for={'show-recovery-code-' + row.user__id}
                            onClick={() => this.handleRegenerateRecoveryCodes(row)}>
                            <FontAwesomeIcon icon="key" fixedWidth />
                          </Button>
                          <ReactTooltip id={'show-recovery-code-' + row.user__id}><span><TT>Regenerate Recovery Codes</TT></span></ReactTooltip>
                        </>
                      ) : null}
                    </>
                  ) : null }

                  { !row?.user_mfa_enabled && this.props.auth.userId && row.user__id === this.props.auth.userId ? (
                    <>
                      <Button color="success" className="action-btn"  size="sm" data-tip data-for={'configure-mfa-a' + row.user__id}
                        onClick={this.toggleConfigureMfaModal}>
                        <FontAwesomeIcon icon="qrcode" fixedWidth />
                      </Button>
                      <ReactTooltip id={'configure-mfa-a' + row.user__id}><span><TT>Enable Two-Factor-Authentication</TT></span></ReactTooltip>
                    </>
                  ) : null}
                </div>
              </div>
            );
          }
        }
    ];

    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
                                                      <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                    </span>),
      sizePerPageList: [{text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}]
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: false,
      selected: [],
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

  return(
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>Users</TT></BreadcrumbItem>
      </Breadcrumb>
      <div className="container">        
        <h4><TT>Users</TT></h4>
        {this.props.members.isLoading? <div className="signal"></div> : null}
      </div>
      <div className="col-md-12">
        <div className="mb-3">
        <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
        <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
          onClick = {this.props.getAllMembers}>
          <FontAwesomeIcon icon="sync-alt" />
        </Button>
        <Link to="/members/add">
          <Button color="success" className="action-btn-top" size="sm"><TT>Invite User</TT></Button>
        </Link>
        </div>      
        <BootstrapTable striped hover condensed
          keyField='_id' 
          data={ this.props.members.members } 
          columns={ columns }
          pagination={ paginationFactory(paginationOptions) }
          noDataIndication={toTTString("No users available")}
          defaultSorted={[{dataField: 'name',order: 'asc'}] }
          selectRow={ selectRow }
          rowStyle={ this.rowStyle } />
      </div>
      <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
        <ModalHeader toggle={this.toggleDelModal}><TT>Delete User</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3"><TT>Are you sure to delete user?</TT></div>
          <Button color="danger" onClick={this.approveDelete}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={this.toggleDelModal}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>

      <Modal isOpen={this.state.isResetMfaModalOpen} toggle={this.toggleResetMfaModal}>
        <ModalHeader toggle={this.toggleResetMfaModal}><TT>Remove Two-Factor-authenticator for user</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3"><TT>Are you sure you want to remove the Two-Factor-authenticator for the selected user?</TT></div>
          <Button color="danger" onClick={this.approveResetMfa}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={this.toggleResetMfaModal}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>

      <Modal isOpen={this.state.isConfigureMfaModalOpen} toggle={this.toggleConfigureMfaModal} centered={true}>
        <ModalHeader toggle={this.toggleConfigureMfaModal}><TT>Configure Two-Factor Authentication</TT></ModalHeader>
        <ModalBody>
            <MFA
              onVerified={() => {
                this.toggleConfigureMfaModal();
                this.props.history.push({ pathname: '/2fa/recoveryCodes' })
                this.props.getAllMembers();
              }}
              showTitle={false}
            />
        </ModalBody>
      </Modal>

      <Modal isOpen={this.state.isRecoveryCodesModalOpen} toggle={this.toggleRecoveryCodesModal}>
        <ModalHeader toggle={this.toggleRecoveryCodesModal}><TT>Regenerate Recovery Codes</TT></ModalHeader>
        <ModalBody>
          <div className="mb-3"><TT>Are you sure you want to regenerate a new set of Recovery Codes? Your current codes will not be valid anymore</TT></div>
          <Button color="danger" onClick={this.approveRegenerate}><TT>Yes</TT></Button>
          <Button className="float-right" color="outline-secondary" onClick={this.toggleRecoveryCodesModal}><TT>Cancel</TT></Button>
        </ModalBody>
      </Modal>
    </React.Fragment>
    );
  }
}
export default withRouter(Members);