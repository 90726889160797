import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Verify from "./Verify";
import Configure from "./Configure";
import Loading from '../../items/Loading';
import { getUserMfa } from '../../../redux/reducers/Auth';

const MFA = props => {
  const { getUserMfa } = props;

  const [mfaEnabled, setMfaEnabled] = useState(null)

  useEffect(() => {
    getUserMfa(res => {
      setMfaEnabled(res?.isEnabled ?? false)
    })
  }, [getUserMfa])

  const onScanned = () => {
    setMfaEnabled(true)
  }

  let comp = null;
  if (mfaEnabled) {
    comp = <Verify
      isWithinLoginCard={props.isWithinLoginCard}
      onVerified={props.onVerified}
      onMaxAttempts={props.onMaxAttempts}
    />
  }

  if (mfaEnabled === false) {
    comp = <Configure onScanned={onScanned} onVerified={props.onVerified} showTitle={props.showTitle} />
  }

  if (mfaEnabled === null || mfaEnabled === undefined) {
    comp = <div className="text-center"><Loading size="3x" /></div>
  }

  return (
    <div>
      {comp}
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getUserMfa: (cb) => dispatch(getUserMfa(cb))
  }
}

export default connect(null, mapDispatchToProps)(MFA);
