import React, { useEffect, useState, useContext } from 'react';
import { connect }from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Row, Col, Button, Label, Card, CardBody } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { Link, withRouter } from 'react-router-dom';
import { required, isIP4, minValue, maxValue, maxLength, isLocalOrBroadcastAddress } from '../../../utils/Validators';
import { FWTextInput } from '../../Common'
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext } from '../../../containers/Language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveVrrpGroup, getVrrpGroup, getDeviceVrrpInterfaces } from '../../../redux/reducers/HighAvailability';
import FwBootstrapTable from '../../items/FwBootstrapTable';
import VrrpDevicesModal from './VrrpDevicesModal';
import uniqueId from 'lodash/uniqueId';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import withSaveBeforeLeaving from '../../../HOC/SaveBeforeLeaving';

const formName = 'vrrpGroup';

const ConfigureVRRP = props => {
  const { id, changeForm, resetForm, vrrpForm, getVrrpGroup, getDeviceVrrpInterfaces, setOrig } = props;

  const { devices: devicesField, virtualIp: virtualIpField, virtualRouterId: virtualRouterIdField } = vrrpForm;
  const { valid: virtualIpFieldValid, value: virtualIpFieldValue, focus: virtualIpFieldFocus } = virtualIpField;

  const { toTTString } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [virtualMac, setVirtualMac] = useState('')
  const [deviceToUpdate, setDeviceToUpdate] = useState(null)
  const [isAddDeviceModalOpened, setIsAddDeviceModalOpened] = useState(false)
  const [devices, setDevices] = useState({})
  const [devicesLoaded, setDevicesLoaded] = useState(false);
  const [oldVirtualIp, setOldVirtualIp] = useState(null)
  const [sameIpWarning, setSameIpWarning] = useState(null)

  // Specify how to clean up this component
  useEffect(() => {
    return function cleanup() {
      resetForm(formName)()
    };
  }, [resetForm]);

  useEffect(() => {
    let mac = 'N/A';
    if (virtualRouterIdField.value && virtualRouterIdField.valid) {
      const hex = parseInt(virtualRouterIdField.value).toString(16).toUpperCase();
      mac = `00-00-5E-00-01-${('0' + hex).slice(-2)}` // add "0" if hex is only one number (1 -> 01)
    }
    setVirtualMac(mac);
  }, [virtualRouterIdField.value, virtualRouterIdField.valid])

  useEffect(() => {
    if (virtualIpFieldValid && virtualIpFieldValue && !virtualIpFieldFocus && oldVirtualIp !== virtualIpFieldValue) {
      setOldVirtualIp(virtualIpFieldValue)
      setDevices({})
      setDevicesLoaded(false)
      getDeviceVrrpInterfaces(virtualIpFieldValue, (res, err) => {
        if (res) {
          setDevices(keyBy(res, '_id'));
          setDevicesLoaded(true)
        }
      })
    }
  }, [virtualIpFieldValid, virtualIpFieldValue, getDeviceVrrpInterfaces, virtualIpFieldFocus, oldVirtualIp, vrrpForm]);

  useEffect(() => {
    let ifcName = null;
    for (const selectedDevice of devicesField.$form.value) {
      const dev = devices[selectedDevice.device];
      if (!dev) {
        continue
      }

      const ifc = dev?.interfaces[selectedDevice.interface]
      if (ifc?.IPv4.split('/')?.[0] === virtualIpFieldValue && +selectedDevice.priority === 255) {
        ifcName = `${dev.name} ${ifc.name}`;
        break;
      }
    }
    setSameIpWarning(ifcName);
  }, [devicesField, devices, virtualIpFieldValue])

  useEffect(() => {
    if (!id) {
      return
    }

    setIsLoading(true)
    getVrrpGroup(id, (res, err) => {
      if (res) {
        changeForm(formName)(res);
        setOrig(res)
      }
      setIsLoading(false)
    });
  }, [getVrrpGroup, changeForm, id, setOrig]);

  const handleSubmit = values => {
    props.saveVrrp(values);
  }

  const closeModal = () => {
    setIsAddDeviceModalOpened(false);
    setDeviceToUpdate(null);
  }

  const handleAddDevice = device => {
    const updated = { ...device};

    // handle add or update
    const _id = device._id || uniqueId('tempId-vrrp-device');
    const devices = cloneDeep(props.vrrpGroup.devices)

    const idx = devices.findIndex(n => n._id === _id)
    if (idx > -1) {
      devices[idx] = updated;
    } else {
      devices.push({...updated, _id })
    }

    changeForm(formName + '.devices')(devices);

    closeModal()
  }

  const handleDeleteDevice = (row, devicesList) => {
    const devices = cloneDeep(devicesList);
    const index = devices.findIndex(n => {
      return n._id === row._id;
    });

    if (index > -1) {
      devices.splice(index, 1);
      changeForm(formName + '.devices')(devices);
    }
  }

  const getPageTitle = () => {
    return id ? 'Update VRRP Group' : 'Add VRRP Group';
  }

  return(
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>High-Availability</TT></BreadcrumbItem>
        <BreadcrumbItem active><Link to="/vrrp">VRRP</Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>{getPageTitle()}</TT></BreadcrumbItem>
      </Breadcrumb>

      <Col md={12} className="d-flex align-items-center">
        <h4><TT>{getPageTitle()}</TT></h4>
        {isLoading ? <div className="signal m-0 ml-2"></div> : null}
      </Col>

      <div className="col-md-12">
        <Card id='configurePeerCard'>
          <CardBody>
            <Form
              model={formName}
              onSubmit={values => handleSubmit(values)}
              onChange={vals => {
                props.setUpdated(vals);
              }}
              validators={{
                'devices[].device': {
                  deviceFoundInOverlappingDevices: device => {
                    if (!devicesLoaded) {
                      return true;
                    }
                    return device in devices;
                  },
                },
                devices: {
                  deviceDuplication: devices => {
                    const set = new Set();
                    let duplicationFound = false;
                    for (const device of devices) {
                      const key = `${device.device}-${device.interface}`
                      if (set.has(key)) {
                        duplicationFound = true;
                        break;
                      }
                      set.add(key);
                    }
                    return !duplicationFound;
                  },
                  priorityDuplication: devices => {
                    const set = new Set();
                    let duplicationFound = false;
                    for (const device of devices) {
                      if (set.has(+device.priority)) {
                        duplicationFound = true;
                        break;
                      }
                      set.add(+device.priority);
                    }
                    return !duplicationFound;
                  },
                  interfaceHaveSameMask: deviceList => {
                    const masks = new Set();
                    for (const device of deviceList) {
                      const IPv4 = devices?.[device.device]?.interfaces?.[device.interface]?.IPv4;
                      if (!IPv4) return true;
                      const mask = IPv4.split('/').pop()
                      masks.add(mask)
                    }
                    return masks.size < 2;
                  },
                  isNetworkOrBroadcastAddr: deviceList => {
                    const dev = deviceList?.[0];
                    if (!dev) {
                      return true;
                    }

                    const IPv4 = devices?.[dev.device]?.interfaces?.[dev.interface]?.IPv4;
                    if (!IPv4) {
                      return true;
                    }
                    return !isLocalOrBroadcastAddress(props.vrrpGroup.virtualIp, IPv4.split('/').pop());
                  }
                }
              }}
            >
              <Row className="form-group">
                <Label htmlFor="name" md={2}>
                  <span className="form-required-star">*</span>
                  <TT>Name</TT>
                  <span className="helpTooltip" data-tip data-for='nameTip'></span>
                  <ReactTooltip id='nameTip'>
                    <span>
                      <TT>Specify a name that describes your VRRP configuration. Optional</TT>
                    </span>
                  </ReactTooltip>
                </Label>
                <Col md={4}>
                  <Control.text
                    model=".name"
                    id="name"
                    name="name"
                    placeholder={toTTString("Name")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      required: required,
                      maxLength: maxLength(30),
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".name"
                    show="touched"
                    messages={{
                      required: toTTString('Required field'),
                      maxLength: toTTString("Length must be at most 30")
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="virtualRouterId" md={2}>
                  <span className="form-required-star">*</span>
                  <TT>Virtual Router ID</TT>
                  <span className="helpTooltip" data-tip data-for='virtualRouterIdTip'></span>
                  <ReactTooltip id='virtualRouterIdTip'>
                    <span>
                      <TT>The virtual router ID (VRID). All routers in the same VRID communicate with each other.</TT><br/>
                      <TT>Specify a VRID value between 1 and 255</TT>
                    </span>
                  </ReactTooltip>
                </Label>
                <Col md={4}>
                  <Control
                    id="virtualRouterId"
                    model=".virtualRouterId"
                    name="virtualRouterId"
                    placeholder={toTTString("Virtual Router ID")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      required: required,
                      minValue: minValue(1),
                      maxValue: maxValue(255),
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".virtualRouterId"
                    show="touched"
                    messages={{
                      required: toTTString('Required field'),
                      minValue: toTTString('Virtual Router ID must be greater than or equal to 1'),
                      maxValue: toTTString('Virtual Router ID must be less than or equal to 255')
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="virtualIp" md={2}>
                  <span className="form-required-star">*</span>
                  <TT>Virtual IP</TT>
                  <span className="helpTooltip" data-tip data-for='virtualIpTip'></span>
                  <ReactTooltip id='virtualIpTip'>
                    <span>
                      <TT>The IP Address of the virtual router. All routers in the same VRID will have this virtual IP address.</TT><br/>
                      <TT>The address should be configured as the default router on the hosts</TT>
                    </span>
                  </ReactTooltip>
                </Label>
                <Col md={4}>
                  <Control.text
                    model=".virtualIp"
                    id="virtualIp"
                    name="virtualIp"
                    placeholder={toTTString("Virtual IP")}
                    component={FWTextInput}
                    withFieldValue
                    validators={{
                      required: required,
                      isIP4: isIP4
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".virtualIp"
                    show="touched"
                    messages={{
                      required: toTTString('Required field'),
                      isIP4: toTTString('Invalid Virtual IP. IPv4 without mask expected'),
                    }}
                  />
                  <Errors
                    className="text-danger"
                    model=".devices"
                    show={true}
                    messages={{
                      isNetworkOrBroadcastAddr: toTTString('Virtual IP cannot be Network or Broadcast IP'),
                    }}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Label md={2}>
                  <TT>Virtual MAC</TT>
                  <span className="helpTooltip" data-tip data-for='virtualMacTip'></span>
                  <ReactTooltip id='virtualMacTip'>
                    <span>
                      <TT>Common MAC address of the format 00:00:5E:00:01:XX. The last octet is the VRID</TT>
                    </span>
                  </ReactTooltip>
                </Label>

                <Col md={4}>
                  <Control.text
                    model=".virtualMac"
                    id="virtualMac"
                    name="virtualMac"
                    value={virtualMac}
                    component={FWTextInput}
                    disabled
                    withFieldValue
                  ></Control.text>
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="preemption" md={2}>
                  <span className="form-required-star">*</span>
                  <TT>Preemption</TT>
                  <span className="helpTooltip" data-tip data-for='preemptionTip'></span>
                  <ReactTooltip id='preemptionTip'>
                    <span>
                      <TT>Controls whether a higher priority Backup router preempts a lower priority Master. Default: enabled</TT><br />
                    </span>
                  </ReactTooltip>
                </Label>
                <Col md={8} className='d-flex align-items-center'>
                  <Label className="FWswitch">
                    <Control.checkbox
                      model=".preemption"
                      id="preemption"
                      name="preemption"
                    />{" "}
                    <span className="FWslider round" />
                  </Label>

                  { !props.vrrpGroup.preemption && props.vrrpGroup.devices?.some(d => {
                    return [...d.trackInterfacesOptional, ...d.trackInterfacesMandatory].length > 0
                  }) ? <PreemptionIsDisabled classText="ml-2" /> : null}
                </Col>
              </Row>

              <Row className="form-group">
                <Label htmlFor="acceptMode" md={2}>
                  <span className="form-required-star">*</span>
                  <TT>Accept Mode</TT>
                  <span className="helpTooltip" data-tip data-for='acceptModeTip'></span>
                  <ReactTooltip id='acceptModeTip'>
                    <span>
                      <TT>Controls whether a virtual router in Master state will accept packets addressed to the virtual IP address. Default: disabled</TT><br />
                    </span>
                  </ReactTooltip>
                </Label>
                <Col md={8}>
                  <Label className="FWswitch">
                    <Control.checkbox
                      model=".acceptMode"
                      id="acceptMode"
                      name="acceptMode"
                    />{" "}
                    <span className="FWslider round" />
                  </Label>
                </Col>
              </Row>

              <Row className="form-group">
                <Col className="d-flex align-items-center">
                  <TT>Devices</TT>
                  <span data-tip data-for='addDeviceTip'>
                    <Button
                      color="success"
                      className="ml-2"
                      onClick={() => setIsAddDeviceModalOpened(true)}
                      size="sm"
                      disabled={Object.keys(devices).length === 0}
                      type="button"
                    >
                      <TT>Add Device</TT>
                    </Button>
                  </span>
                  <div>
                    {isLoading ? <div className="signal m-0 ml-2"></div> : null}
                  </div>
                  { Object.keys(devices).length === 0 ? (
                    <ReactTooltip id='addDeviceTip'>
                      <span>
                        <TT>Devices should be overlapping with the Virtual IP. Please configure Virtual IP first and ensure that you have devices which overlapping this IP</TT>
                      </span>
                    </ReactTooltip>
                  ) : null}
                </Col>
              </Row>

              { sameIpWarning ? (
                <div
                  id="device-send-alert"
                  className="alert alert-warning col-md-12"
                  role="alert"
                >
                  Please be aware that {sameIpWarning} has the same IP as the virtual IP.<br />
                  As a result, there are a few different behaviors to keep in mind:<br />
                  1. {sameIpWarning} will respond to ICMP messages regardless of whether accept mode is enabled.<br />
                  2. {sameIpWarning} will always take priority over the backup, regardless of whether Preemption is enabled.
                </div>

              ) : null}

              <Row className="form-group">
                <Col>
                  <FwBootstrapTable
                    data={props.vrrpGroup?.devices ?? []}
                    noDataIndication={toTTString("No VRRP devices configured")}
                    columns={[
                      {
                        text: toTTString("Name"),
                        dataField: 'device',
                        sort:true,
                        headerStyle: (colum, colIndex) => {
                          return { width: '10%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          const device = extraData[cellContent];
                          return (
                            <>
                              {device?.name}
                              <Errors
                                className="text-danger"
                                model=".devices"
                                show="touched"
                                messages={{
                                  deviceDuplication: toTTString('Duplication in devices')
                                }}
                              />
                              <Errors
                                className="text-danger"
                                model={`.devices[${rowIndex}].device`}
                                show={true}
                                messages={{
                                  deviceFoundInOverlappingDevices: toTTString(`Device (ID: ${cellContent.slice(0, 4) + '...' + cellContent.slice(cellContent.length - 4, cellContent.length)}) is not overlapping with the configured virtual IP`)
                                }}
                              />
                            </>
                          )
                        },
                        formatExtraData: devices
                      },
                      {
                        text: toTTString("Interface"),
                        dataField: 'interface',
                        sort:false,
                        headerStyle: (colum, colIndex) => {
                          return { width: '10%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          const device = extraData[row.device];
                          const ifc = device?.interfaces[cellContent];
                          return ifc?.name;
                        },
                        formatExtraData: devices
                      },
                      {
                        text: toTTString("IP"),
                        dataField: 'interfaceIp',
                        isDummyField: true,
                        sort:false,
                        headerStyle: (colum, colIndex) => {
                          return { width: '10%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          const device = extraData[row.device];
                          const ifc = device?.interfaces[row.interface];
                          return (
                            <>
                            {ifc?.IPv4}
                            <Errors
                                className="text-danger"
                                model=".devices"
                                show={true}
                                messages={{
                                  interfaceHaveSameMask: toTTString('Interfaces must have the same subnet')
                                }}
                              />
                            </>
                          )
                        },
                        formatExtraData: devices
                      },
                      {
                        text: toTTString("Priority"),
                        dataField: 'priority',
                        sort:false,
                        headerStyle: (colum, colIndex) => {
                          return { width: '8%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          const device = extraData.devices[row.device];
                          const ifc = device?.interfaces[row.interface];
                          const isSameAsVIP = ifc?.IPv4?.split('/')?.[0] === extraData.virtualIpFieldValue;
                          return (
                            <>
                              <div className='d-flex justify-content-between align-items-center'>
                                <span>{cellContent}</span>
                                { +cellContent === 0 ? (
                                  <>
                                    <FontAwesomeIcon
                                      icon="exclamation-triangle"
                                      size="md"
                                      fixedWidth
                                      color="#ebc41b"
                                      data-tip
                                      data-for={`sameIp-${rowIndex}`}
                                    />

                                    <ReactTooltip id={`sameIp-${rowIndex}`}>
                                      <span>
                                        <TT>The number 0 as a priority indicates that the device stopped participating in VRRP</TT>
                                      </span>
                                    </ReactTooltip>
                                  </>
                                ) : isSameAsVIP && +cellContent !== 255 ? (
                                  <>
                                    <FontAwesomeIcon
                                      icon="exclamation-triangle"
                                      size="md"
                                      fixedWidth
                                      color="#ebc41b"
                                      data-tip
                                      data-for={`sameIp-${rowIndex}`}
                                    />

                                    <ReactTooltip id={`sameIp-${rowIndex}`}>
                                      <span>
                                        <TT>Interface with the same IP as the Virtual IP must have priority of 255</TT>
                                      </span>
                                    </ReactTooltip>
                                  </>
                                ) : !isSameAsVIP && +cellContent === 255 ? (
                                  <>
                                    <FontAwesomeIcon
                                      icon="exclamation-triangle"
                                      size="md"
                                      fixedWidth
                                      color="#ebc41b"
                                      data-tip
                                      data-for={`sameIp-${rowIndex}`}
                                    />

                                    <ReactTooltip id={`sameIp-${rowIndex}`}>
                                      <span>
                                        <TT>Priority of 255 must only be used for interfaces with the same IP as the virtual IP</TT>
                                      </span>
                                    </ReactTooltip>
                                  </>
                                ) : null}
                              </div>
                              <Errors
                                className="text-danger"
                                model={`.devices`}
                                show={true}
                                messages={{
                                  priorityDuplication: toTTString('Duplication in priority')
                                }}
                              />
                            </>
                          )
                        },
                        formatExtraData: { devices, virtualIpFieldValue }
                      },
                      {
                        text: toTTString("Optional Track interfaces"),
                        dataField: 'trackInterfacesOptional',
                        sort:false,
                        headerStyle: (colum, colIndex) => {
                          return { width: '20%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          const device = extraData.devices[row.device];
                          if (!device) return;

                          const names = [];
                          for (const trackIfc of cellContent ?? []) {
                            const name = device.interfaces[trackIfc]?.name;
                            if (name) {
                              names.push(name);
                            }
                          }

                          return (
                            <>
                              { !extraData.vrrpGroup.preemption && names.length > 0 ? <PreemptionIsDisabled classText="mr-2" /> : null}
                              <span>{names.join(', ')}</span>
                            </>
                          )
                        },
                        formatExtraData: { devices, vrrpGroup: props.vrrpGroup }
                      },
                      {
                        text: toTTString("Mandatory Track interfaces"),
                        dataField: 'trackInterfacesMandatory',
                        sort:false,
                        headerStyle: (colum, colIndex) => {
                          return { width: '20%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          const device = extraData.devices[row.device];
                          if (!device) return;

                          const names = [];
                          for (const trackIfc of cellContent ?? []) {
                            const name = device.interfaces[trackIfc]?.name;
                            if (name) {
                              names.push(name);
                            }
                          }

                          return (
                            <>
                              { !extraData.vrrpGroup.preemption && names.length > 0 ? <PreemptionIsDisabled classText="mr-2" /> : null}
                              <span>{names.join(', ')}</span>
                            </>
                          )
                        },
                        formatExtraData: { devices, vrrpGroup: props.vrrpGroup }
                      },
                      {
                        text: toTTString("Actions"),
                        dataField: 'actions',
                        isDummyField: true,
                        sort:false,
                        headerStyle: (colum, colIndex) => {
                          return { width: '10%', textAlign: 'left' };
                        },
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          return (
                            <div>
                              <Button
                                color="warning"
                                className="action-btn"
                                data-tip
                                data-for='update-a'
                                size="sm"
                                type="button"
                                onClick={() => {
                                  setDeviceToUpdate(row);
                                  setIsAddDeviceModalOpened(true)
                                }}
                              >
                                <FontAwesomeIcon icon="cog" fixedWidth />
                              </Button>
                              <ReactTooltip id='update-a'><span><TT>Configure VRRP Device</TT></span></ReactTooltip>

                              <Button type="button" color="danger" className="action-btn" data-tip data-for='delete-a' size="sm"
                                onClick={() => handleDeleteDevice(row, extraData)}>
                                <FontAwesomeIcon icon="trash-alt" fixedWidth />
                              </Button>
                              <ReactTooltip id='delete-a'><span><TT>Delete VRRP Device</TT></span></ReactTooltip>
                            </div>
                          );
                        },
                        formatExtraData: props.vrrpGroup?.devices
                      }
                    ]}
                    keyField="_id"
                  />
                </Col>
              </Row>

              { isAddDeviceModalOpened ? (
                <VrrpDevicesModal
                  close={() => closeModal()}
                  deviceToUpdate={deviceToUpdate}
                  devices={devices}
                  virtualIp={virtualIpFieldValue}
                  selectedDevices={devicesField?.$form?.value?.map(d => d.device) ?? []}
                  resetForm={resetForm}
                  onSubmit={handleAddDevice}
                />
              ) : null}

              <Row className="form-group mt-2">
                <Col>
                  <Control.button
                    model={formName}
                    className="btn btn-primary"
                  >
                    <TT>Save</TT>
                  </Control.button>

                  <Link to="/vrrp">
                    <button
                      type="button"
                      className="btn btn-outline-dark ml-2"
                    >
                      <TT>Cancel</TT>
                    </button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}

const PreemptionIsDisabled = props => {
  return (
    <>
      <FontAwesomeIcon
        icon="exclamation-triangle"
        size="md"
        fixedWidth
        color="#ff1b1b"
        className={props.classText}
        data-tip
        data-for={`preemption`}
      />

      <ReactTooltip id={`preemption`}>
        <span>
          <TT>When Preemption is disabled, tracked interfaces has no effect</TT>
        </span>
      </ReactTooltip>
    </>
  )
};

const mapStateToProps = state => {
  return {
    vrrpForm: state.forms.vrrpGroup,
    vrrpGroup: state.vrrpGroup
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVrrpGroup: (cb, id) => dispatch(getVrrpGroup(cb, id)),
    saveVrrp: values => dispatch(saveVrrpGroup(values)),
    getDeviceVrrpInterfaces: (virtualIp, cb) => dispatch(getDeviceVrrpInterfaces(virtualIp, cb))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSaveBeforeLeaving(withRouter(ConfigureVRRP)));