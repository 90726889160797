export const getRangeAndMask = number => {
  const baseTwo = Math.ceil(Math.log2(number));
  const mask = 32 - baseTwo;
  const range = Math.pow(2, baseTwo);
  return { range, mask };
}

/**
 * Find the start and end IPv4 from ip + mask
 * @param {String} ip     IPv4 address
 * @param {String} mask   Number of bit masks
 * @param {String} shift  Number of IPs to shift from start/end
 */
export const getStartEndIPv4 = (ipString, mask, shift=0) => {
  function u(n) { return n >>> 0; } // convert to unsigned
  function ip(n) {
      return [
          (n >>> 24) & 0xFF,
          (n >>> 16) & 0xFF,
          (n >>>  8) & 0xFF,
          (n >>>  0) & 0xFF
      ].join('.');
  }
  const m = ipString.split('.');
  const addr32 = m.reduce((a, o) => {
      return u(+a << 8) + +o;
  });
  const maskNum = u(~0 << (32 - +mask));
  return [ip(u(addr32 & maskNum)+shift), ip(u(addr32 | ~maskNum)-shift)];
}