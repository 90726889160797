const { jwtInSessionStorage } = window.SERVER_CONFIG;

const storage = jwtInSessionStorage ? sessionStorage : localStorage;

const tokenKey = 'token';
const refreshTokenKey = 'refreshToken';

const saveStorageToken = token => {
  storage.setItem(tokenKey, token)
}

const removeStorageToken = () => {
  storage.removeItem(tokenKey)
}

const getStorageToken = () => {
  return storage.getItem(tokenKey)
}

const saveStorageRefreshToken = token => {
  storage.setItem(refreshTokenKey, token)
}

const removeStorageRefreshToken = () => {
  storage.removeItem(refreshTokenKey)
}

const getStorageRefreshToken = () => {
  return storage.getItem(refreshTokenKey)
}

export {
  saveStorageToken,
  removeStorageToken,
  getStorageToken,
  saveStorageRefreshToken,
  removeStorageRefreshToken,
  getStorageRefreshToken
};
