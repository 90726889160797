import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Traffic = (
  state = {
    traffic: [],
    tunnelTraffic: [],
    isLoading: false,
    selectOptions: {
      device: "FFFF",
      deviceId: undefined,
      interface: "FFFF",
      interfaceNum: undefined,
      direction: 'rxtx',
      directionValue: 'rxtx',
    }
  },
  action
) => {
  switch (action.type) {
    // Get traffic
    case Actions.TRAFFIC_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.TRAFFIC_GET_SUCCESS:
      return { ...state, traffic: action.traffic, isLoading: false };
    case Actions.TRAFFIC_GET_FAILURE:
      return { ...state, traffic: [], isLoading: false };
    case Actions.TUNNEL_TRAFFIC_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.TUNNEL_TRAFFIC_GET_SUCCESS:
      return { ...state, tunnelTraffic: action.tunnelTraffic, isLoading: false };
    case Actions.TUNNEL_TRAFFIC_GET_FAILURE:
      return { ...state, tunnelTraffic: [], isLoading: false };
    case Actions.TRAFFIC_SET_OPTIONS:
      return { ...state, selectOptions: {...state.selectOptions, ...action.options}};
    default:
      return state;
  }
};

/**********************************************************************
 *   Get traffic
 **********************************************************************/
export const requestGetTraffic = () => dispatch => {
  dispatch({
    type: Actions.TRAFFIC_GET_REQUEST
  });
};

export const receiveGetTraffic = response => dispatch => {
  dispatch({
    type: Actions.TRAFFIC_GET_SUCCESS,
    traffic: response
  });
};

export const failureGetTraffic = error => dispatch => {
  dispatch({
    type: Actions.TRAFFIC_GET_FAILURE
  });
};

// If deviceId == null, get all devices
export const getTraffic = (deviceId, ifNum, success_cb) => dispatch => {
  const startTime = Math.round((new Date()).getTime() / 1000) - 7200;  // get two hours backward
  return dispatch(
    FWFetch({
      url: baseUrl + "devices" + (deviceId && deviceId !== "FFFF" ? "/" + deviceId : "") + "/statistics"
        + "?startTime=" + startTime
        + ((deviceId && ifNum && ifNum !==undefined)? "&ifnum="+ encodeURIComponent(ifNum) : ""),
      method: "GET",
      requestCB: () => {
        dispatch(requestGetTraffic());
      },
      successCB: response => {
        dispatch(receiveGetTraffic(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureGetTraffic(error));
      }
    })
  );
};

/**********************************************************************
 *   Get tunnel traffic
 **********************************************************************/
export const requestGetTunnelTraffic = () => dispatch => {
  dispatch({
    type: Actions.TUNNEL_TRAFFIC_GET_REQUEST
  });
};

export const receiveGetTunnelTraffic = response => dispatch => {
  dispatch({
    type: Actions.TUNNEL_TRAFFIC_GET_SUCCESS,
    tunnelTraffic: response
  });
};

export const failureGetTunnelTraffic = error => dispatch => {
  dispatch({
    type: Actions.TUNNEL_TRAFFIC_GET_FAILURE
  });
};

// If deviceId == null, get all devices
export const getTunnelTraffic = (deviceId, tunnelNum, success_cb) => dispatch => {
  const startTime = Math.round((new Date()).getTime() / 1000) - 7200;  // get two hours backward
  return dispatch(
    FWFetch({
      url: baseUrl + "devices" + (deviceId ? "/" + deviceId : "") + "/tunnelstats"
        + "?startTime=" + startTime
        + ((deviceId && tunnelNum && tunnelNum !==undefined)? "&tunnelnum="+ encodeURIComponent(tunnelNum) : ""),
      method: "GET",
      requestCB: () => {
        dispatch(requestGetTunnelTraffic());
      },
      successCB: response => {
        dispatch(receiveGetTunnelTraffic(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureGetTunnelTraffic(error));
      }
    })
  );
};

export const setSelectOptions = (options) => dispatch => {
  return dispatch({
    type: Actions.TRAFFIC_SET_OPTIONS,
    options: options
  });
};
