import React, { Component } from 'react';
import { Row, Col, Label, Card, CardBody, Button } from 'reactstrap';
import { Control, Form } from 'react-redux-form';
import { required } from '../../utils/Validators';
import InfoNotAvailable from '../items/infoNotAvailable';
import { TT, LanguageContext } from '../../containers/Language'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

class SendDevice extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      output:'',
      status: '',
      inProgress: false,
      infoNotAvailable: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOutput = this.handleOutput.bind(this);
    this.handleChangeCommand = this.handleChangeCommand.bind(this);
    this.getUrlForSavingOutput = this.getUrlForSavingOutput.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  handleOutput(response) {
    if (response.error === 'timeout' || response.deviceStatus === 'disconnected') {
      this.setState({infoNotAvailable: true, inProgress:false});
    } else {
      const output = [response.message.output,
      <br />,
      <span style={{color: 'red'}}>{(response.message.error !== ''? 'Error: ' + response.message.error : '')}</span>,
      <br />,
      <span style={{color: 'red'}}>{(response.message.returncode !== 0 ? 'Error Code: ' + response.message.returncode : '')}</span>];
      this.setState({infoNotAvailable: false, output: output, inProgress:false});
    }
  }

  handleSubmit(values) {
    this.setState({output:'', inProgress:true});
    this.props.sendDevice({_id:this.props.id, entity:"agent" , command:values.command }, this.handleOutput, ()=>{});
  }

  handleChangeCommand(option) {
    const selection = JSON.parse(option.target.value);
    this.props.changeGeneralForm('sendDevice.command')(selection.command,{'silent':true});
  };

  showToast = () => {
    toast(<TT>Output was copied to clipboard!</TT>, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

   getUrlForSavingOutput(){
    const data = [this.state.output[0]];
    const properties = {type: 'text/plain'};
    let file;
    try {
      file = new File(data, "output.txt", properties);
    } catch (e) {
      file = new Blob(data, properties);
    }
    return URL.createObjectURL(file);
  }

  render() {
    const toTTString = this.context.toTTString;

    const commandOptions = [
      {text:toTTString('Custom Command'), value:JSON.stringify({"entity":"agent","command":""})},
      {text:toTTString('flexiEdge Date'), value:JSON.stringify({"entity":"agent","command":"date"})},
      {text:toTTString('Ping'), value:JSON.stringify({"entity":"agent","command":"ping -c 5 google.com"})},
      {text:toTTString('10Sec PCAP capture'), value:JSON.stringify({"entity":"agent","command":"sudo vppctl pcap dispatch trace on max 10000 file vppcap.pcap buffer-trace dpdk-input 10000 && sleep 10 && sudo vppctl pcap dispatch trace off"})},
      {text:toTTString('10Sec VmWare PCAP capture'), value:JSON.stringify({"entity":"agent","command":"sudo vppctl pcap dispatch trace on max 10000 file vppcap.pcap buffer-trace vmxnet3-input 10000 && sleep 10 && sudo vppctl pcap dispatch trace off"})},
      {text:toTTString('Restart flexiEdge Controller'), value:JSON.stringify({"entity":"agent","command":"systemctl restart flexiwan-router"})},
      // eslint-disable-next-line max-len
      {text:toTTString('Update GPG Key'), value:JSON.stringify({"entity":"agent","command":"if [ -x /usr/bin/curl ]; then curl -s https://deb.flexiwan.com/flexiWAN/gpgkey/flexiwan.ng.source.gpg.key | apt-key add - 2>/dev/null; else wget -qO- https://deb.flexiwan.com/flexiWAN/gpgkey/flexiwan.ng.source.gpg.key | apt-key add - 2>/dev/null; fi"})},
      {text:toTTString('Show BGP summary'), value:JSON.stringify({"entity":"agent","command":"vtysh -c 'show bgp summary'"})},
      {text:toTTString('Show OSPF neighbor'), value:JSON.stringify({"entity":"agent","command":"vtysh -c 'show ip ospf neighbor'"})},
      {text:toTTString('View IKEv2/IPsec peers'), value:JSON.stringify({"entity":"agent","command":"vppctl show ikev2 sa details"})},
      {text:toTTString('Install the "ca-certificates" apt package'), value:JSON.stringify({"entity":"agent","command":"apt install -y ca-certificates"})}
    ];

    return(
      <React.Fragment>
        <div className="col-md-10">
          <div
            id="device-send-alert"
            className="alert alert-danger col-md-12"
            role="alert"
          >
            <TT>Warning: Device commands can cause irreversible damage</TT>. <TT>Use only if you know what you're doing</TT>. <TT>Command execution is limited to 60 seconds</TT>.
          </div>

          <InfoNotAvailable show={this.state.infoNotAvailable} />

          {this.state.status === 'disconnected' ? (
            <div
              id="device-send-alert"
              className="alert alert-warning col-md-12"
              role="alert"
            >
              <TT>Device must be connected to view logs</TT>
            </div>
          ) : null}

          <Card id='sendDeviceCard'>
            <CardBody>
              <Form model="sendDevice" onSubmit={(values) => this.handleSubmit(values)}>
                <Row className="form-group">
                  <Label htmlFor="message" md={2}>Message</Label>
                  <Col md={6}>
                        <Control.select model=".message" id="message" name="message"
                          className="form-control"
                          onChange={this.handleChangeCommand}
                        >
                          <option
                            value={commandOptions[0].value} disabled hidden
                            key={commandOptions[0].value}
                          >
                            {commandOptions[0].text}
                          </option>
                          {commandOptions.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
                        </Control.select>
                  </Col>
                </Row>
                <Row className="form-group commandArea">
                  <Label htmlFor="command" md={2}><TT>Command</TT></Label>
                  <Col md={10}>
                    <Control.textarea model=".command" id="command" name="command" placeholder={toTTString("Command")}
                      validators={ { required: required } }
                      style = {{'min-width': '100%'}}
                      rows = {5}
                      readOnly = {false}
                    />
                  </Col>
                </Row>
                <Row className="form-group outputArea">
                  <Label htmlFor="output" md={2}><TT>Output</TT></Label>
                    <Col md={10}>
                      <pre id="command-output-area">
                        {this.state.inProgress ?
                          <div
                            className="signal"
                            style={{'margin-left': '50%', 'margin-top': '15px'}}>
                          </div> :
                        this.state.output}
                      </pre>
                    </Col>
                </Row>
                <Row className="form-group">
                  <Col md={{size:10, offset: 2}}>
                    <Control.button
                      model="sendDevice"
                      disabled={ (values) => !values.valid || this.state.inProgress }
                      className="btn btn-primary">
                      <TT>Send Command</TT>
                    </Control.button>
                    <a download="output.txt" href={this.getUrlForSavingOutput()}>
                      <Button
                      disabled={!this.state.output}
                      className="btn btn-primary copySaveBtn"
                      onClick={()=>this.getUrlForSavingOutput()}>
                        <TT>Save</TT>
                      </Button>
                    </a>
                      <CopyToClipboard text={this.state.output[0]}
                      onCopy={() => this.showToast()}>
                      <Button
                        disabled={!this.state.output}
                        className="btn btn-primary copySaveBtn">
                        <TT>Copy</TT>
                      </Button>
                    </CopyToClipboard>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default SendDevice;