import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {
  Button,
  Label, Modal, ModalBody, ModalHeader
} from "reactstrap";
import { Form, Control  } from "react-redux-form";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FirewallRuleModal from '../items/FirewallRuleModal';
import { store } from "../../App";
import {
  convertFirewallRules,
  DestinationFormatter,
  SourceFormatter
} from "../../utils/FirewallUtils"
import { TT, LanguageContext  } from '../../containers/Language'
import { getMajorVersion, getMinorVersion } from '../../utils/DevicesUtils';

class DeviceFirewallCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      applicationIfcs: [],
      selectedRules: { inbound: [], outbound: [], lanNat: [] },
      isRuleModalOpen: false,
      origData : {},
      isApproveDisableSystemRuleModalOpen: false,
      selectedDisabledSystemRule: null
    };

    this.rulePriority = 0;
    this.initalDirection = "";
    this.saveRuleFunction = {};

    // global rules must be applied after device specific rules
    // assuming there will be not more than 10000 local rules
    this.globalPriorityShift = 10000;

    this.addRule = this.addRule.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleSwapRules = this.handleSwapRules.bind(this);
    this.handleUpdateRule = this.handleUpdateRule.bind(this);
    this.handleDuplicateRule = this.handleDuplicateRule.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleEnableSelected = this.handleEnableSelected.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
    this.toggleRuleModal = this.toggleRuleModal.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.props.getDevice(this.props.id, resp => {
      const { applications } = resp;
      const updated = [];
      applications.forEach(application => {
        const { app } = application;
        if (app) {
          const { name, identifier } = app.appStoreApp;
          // map applications interfaces to be similar to a regular interface
          if (identifier === 'com.flexiwan.remotevpn') {
            updated.push({
              devId: `app_${identifier}`,
              isAssigned: 'Yes',
              type: 'LAN',
              IPv4: application.configuration.subnet,
              name: name
            })
          }
        }
      })

      this.setState({applicationIfcs: updated })
    });
  }

  toggleRuleModal() {
    this.setState({ isRuleModalOpen: !this.state.isRuleModalOpen });
  }

  handleAddRule(priority, direction) {
    const emptyRule = store.getState().forms.firewallRule.$form.initialValue;
    this.props.changeRuleForm({
      ...emptyRule,
      direction: direction || 'inbound',
      isNewRule: true
    }, { silent: true });

    this.saveRuleFunction = this.addRule;
    this.rulePriority = priority;
    this.initalDirection = direction;
    this.setState({ isRuleModalOpen: true });
  }

  handleUpdateRule(row) {
    this.props.changeRuleForm({ ...row, isNewRule: false });
    this.saveRuleFunction = this.updateRule;
    this.rulePriority = row.priority;
    this.initalDirection = row.direction;
    this.setState({ isRuleModalOpen: true });
  }

  handleDuplicateRule(row) {
    if (row.system) {
      row.system = false;
      row.interfaces = [];
      row.reference = null;
    }

    this.props.changeRuleForm({ ...row, isNewRule: true }, { silent: true });
    this.saveRuleFunction = this.addRule;
    this.rulePriority = row.priority + 1;
    this.initalDirection = row.direction;
    this.setState({ isRuleModalOpen: true });
  }

  handleDeleteRule(priority, direction) {
    const rules =  this.props.firewallRules.reduce((result, rule) => {
      if (rule.priority < priority || rule.direction !== direction) {
        result.push(rule);
      } else if (rule.priority > priority) {
        result.push({ ...rule, priority: rule.priority-1 });
      };
      return result;
    },[]);
    this.props.saveDeviceFirewallRules(rules);
    // Clear selected rules to make sure
    // the deleted rule does not remain selected
    this.setState({
      selectedRules: {
        ...this.state.selectedRules,
        [direction]: []
      }
    });
  }

  handleSwapRules(curIdx, newIdx, direction) {
    const rules = this.props.firewallRules.map(rule => {
      if (rule.direction === direction && [curIdx, newIdx].includes(rule.priority)) {
        return { ...rule, priority: rule.priority === newIdx ? curIdx : newIdx }
      } else {
        return { ...rule }
      }
    });
    this.props.saveDeviceFirewallRules(rules);
  }

  toggleApproveDisableSystemRuleModal = rule => {
    this.setState({ isApproveDisableSystemRuleModalOpen: !this.state.isApproveDisableSystemRuleModalOpen});
  }

  approveDisableSystemRule = () => {
    this.handleEnabled(this.state.selectedDisabledSystemRule, false);
    this.setState({ selectedDisabledSystemRule: null });
    this.toggleApproveDisableSystemRuleModal();
  }

  handleEnabled(rule, showSystemWarning = true) {
    const { priority, direction, system, enabled } = rule;
    if (system && enabled === true && showSystemWarning) {
      this.setState({ selectedDisabledSystemRule: rule });
      this.toggleApproveDisableSystemRuleModal();
      return;
    }

    const rules = this.props.firewallRules.map(rule => {
      if (rule.direction === direction && priority === rule.priority) {
        return { ...rule, enabled: !rule.enabled }
      } else {
        return { ...rule }
      }
    });
    this.props.saveDeviceFirewallRules(rules);
  }

  handleDeleteSelected(direction) {
    const selected = this.state.selectedRules[direction];
    const rules = this.props.firewallRules.reduce((result, rule) => {
      if (rule.direction === direction && selected.includes(rule.priority)) {
        return result;
      } else if (rule.direction === direction) {
        const priority = result.filter(r => r.direction === direction).length;
        return [...result, {...rule, priority: priority}];
      } else {
        return [...result, rule];
      }
    }, []);
    this.setState(prev => ({ ...prev, [direction]: [] }));
    this.props.saveDeviceFirewallRules(rules);
  }

  handleEnableSelected(direction, enabled) {
    const selected = this.state.selectedRules[direction];
    const rules = this.props.firewallRules.map(rule => {
      if (rule.direction === direction && selected.includes(rule.priority)) {
        return { ...rule, enabled: enabled }
      } else {
        return { ...rule }
      }
    });
    this.props.saveDeviceFirewallRules(rules);
  }

  handleOnSelect(row, isSelect, direction) {
    const updatedSelected = (selectedRules, isSelect) => {
      return isSelect
        ? [...selectedRules, row.priority]
        : selectedRules.filter(priority => priority !== row.priority);
    };
    this.setState({
      selectedRules: {
        ...this.state.selectedRules,
        [direction]: updatedSelected(this.state.selectedRules[direction], isSelect)
      }
    });
  }

  handleOnSelectAll(isSelect, rows, direction) {
    const selectedRules = isSelect ? rows.filter(rule => rule.direction === direction)
      .map(rule => rule.priority) : [];
    this.setState({
      selectedRules: {
        ...this.state.selectedRules,
        [direction]: selectedRules
      }
    });
  }

  updateRule(updRule) {
    const rules =  this.props.firewallRules.map(rule => {
      if (this.initalDirection === rule.direction && this.rulePriority === rule.priority) {
        // check if direction was changed
        const priority = this.initalDirection === updRule.direction ? this.rulePriority
          : this.props.firewallRules.filter(r =>
            r.direction === updRule.direction && r.priority < this.globalPriorityShift
          ).length;
        return {...updRule, priority};
      } else {
        return rule;
      }
    });
    this.props.saveDeviceFirewallRules(rules);
  }

  addRule(rule) {
    const priority = this.initalDirection === rule.direction ? this.rulePriority
      : this.props.firewallRules.filter(r =>
        r.direction === rule.direction && r.priority < this.globalPriorityShift
      ).length;

    const rules = this.props.firewallRules.map(
      r => r.direction !== rule.direction || r.priority < priority ? r
        : { ...r, priority: r.priority + 1 }
    );
    rules.push({ ...rule, priority });
    this.props.saveDeviceFirewallRules(rules);
  }

  onDragEnd({ source, destination }) {
    // dropped outside the list
    if (!destination) {
      return;
    }
    this.handleSwapRules(source.index, destination.index, source.droppableId);
  }

  disableEnableRuleComp = (row, enabled, isGlobalRule) => {
    return <>
      <ReactTooltip id={'enable-rule-tip-' + row.direction + row.priority}>
        <span style={{"fontSize": "0.8rem"}}>
          {
            !row.deviceSpecificRulesEnabled ? <TT>Devices specific rules not enabled</TT> :
            row.enabled ? <TT>Disable the rule</TT> : <TT>Enable the rule</TT>
          }
        </span>
      </ReactTooltip>
      <Button
        color={enabled ? 'danger' : 'success'}
        className="action-btn"
        data-tip
        data-for={'enable-rule-tip-' + row.direction + row.priority}
        size="sm"
        disabled={isGlobalRule || !row.deviceSpecificRulesEnabled}
        onClick={() => this.handleEnabled(row)}
      >
        <FontAwesomeIcon icon={enabled ? 'stop-circle' : 'check-circle'} fixedWidth />
      </Button>
    </>
  }

  updateRuleComp = row => {
    return <>
      <ReactTooltip id={'update-rule-tip-' + row.direction + row.priority}>
        <span><TT>Update rule</TT></span>
      </ReactTooltip>
      <Button
        color="warning"
        className="action-btn"
        data-tip
        data-for={'update-rule-tip-' + row.direction + row.priority}
        size="sm"
        onClick={() => this.handleUpdateRule(row)}
      >
        <FontAwesomeIcon icon="cog" fixedWidth />
      </Button>
    </>
  }

  duplicateRuleComp = row => {
    return <>
      <ReactTooltip id={'duplicate-rule-tip-' + row.direction + row.priority}>
        <span><TT>Duplicate rule</TT></span>
      </ReactTooltip>
      <Button
        className="action-btn"
        color="warning"
        data-tip
        data-for={'duplicate-rule-tip-' + row.direction + row.priority}
        size="sm"
        onClick={() => this.handleDuplicateRule(row)}
      >
        <FontAwesomeIcon icon='clone' fixedWidth />
      </Button>
    </>
  }

  render() {
    const toTTString = this.context.toTTString

    this.ruleColumns = [
      { text: toTTString("ID"), dataField: "_id", hidden: true },
      {
        text: toTTString("Destination"),
        dataField: "classification.destination",
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        formatter: (cellContent, row) => <>
        { row.priority >= this.globalPriorityShift // global rules
          ?
            <div data-tip data-for={"drag-and-drop-rule-" + row._id}>
              <ReactTooltip id={"drag-and-drop-rule-" + row._id}>
                <span><TT>Global rule can be modified on the firewall policy page.</TT></span>
              </ReactTooltip>
              <DestinationFormatter
                rule={row}
                interfaces={this.props.interfaces}
              />
            </div>
          : row.priority < 0 ? ( // system rules
            <div data-tip data-for={"drag-and-drop-rule-" + row._id}>
              <ReactTooltip id={"drag-and-drop-rule-" + row._id}>
                <span><TT>System rule cannot be modified or deleted</TT></span>
              </ReactTooltip>
              <DestinationFormatter
                rule={row}
                interfaces={this.props.interfaces}
              />
            </div>
            )
          : // device specific rules
            <Draggable
              key={row.priority}
              draggableId={row.direction + '-' + row.priority}
              index={row.priority}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  data-tip
                  data-for={"drag-and-drop-rule-" + row.direction + '-' + row.priority}
                >
                  <ReactTooltip id={"drag-and-drop-rule-" + row.direction + '-' + row.priority}>
                    <span><TT>Drag and Drop the rule</TT></span>
                  </ReactTooltip>
                  <DestinationFormatter
                    rule={row}
                    interfaces={this.props.interfaces}
                  />
                </div>
              )}
            </Draggable>
          }
        </>
      },
      {
        text: toTTString("Source"),
        dataField: "classification.source",
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        formatter: (cellContent, row) => <SourceFormatter
          rule={row}
          interfaces={this.props.interfaces}
        />
      },
      {
        text: toTTString("Action"),
        dataField: "action",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "15%",
            textAlign: "left"
          };
        },
        formatter: (value, row) => {
          return row.direction === 'lanNat' ? <>
            <FontAwesomeIcon icon="exchange-alt" color="midnightblue" fixedWidth/>
            <TT>NAT</TT> 1:1
            <br/>src {row.classification.source.lanNat?.action}
            <br/>dst {row.classification.destination.lanNat?.action || '0.0.0.0/0'}
          </> : row.inbound === 'edgeAccess' ?
            value === 'allow' ? <>
              <FontAwesomeIcon icon="check" color="green" fixedWidth/>
              <TT>Allow</TT>
            </> : <>
              <FontAwesomeIcon icon='ban' color="red" fixedWidth/>
              <TT>Deny</TT>
            </> : row.inbound === 'nat1to1' ? <>
              <FontAwesomeIcon icon="exchange-alt" color="midnightblue" fixedWidth/>
              {<><TT>NAT</TT> 1:1<br/>{row.internalIP}</>}
            </> : row.inbound === 'portForward' ? <>
              <FontAwesomeIcon icon="random" color="darkblue" fixedWidth/>
              {<><TT>Port Fwd</TT>.<br/>{row.internalIP}:{row.internalPortStart}</>}
            </> : '';
          }
      },
      {
        text: toTTString("Description"),
        dataField: "description",
        sort: false,
        editable: false,
        formatter: (val, row, idx, extraData) => {
          const isSystemRule = row.system === true;
          if (isSystemRule) {
            const app = extraData.applications.find(a => a.app._id === row.reference);
            if (app) {
              val = `System Rule: Auto installed by ${app.app.appStoreApp.name} - ${val}`;
            }
          }
          return val;
        },
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        },
        formatExtraData: { applications: this.props.device?.applications ?? [] }
      },
      {
        text: toTTString("Rule Actions"),
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "20%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          const { priority, direction } = row;
          const isGlobalRule = row.priority >= this.globalPriorityShift;
          const isSystemRule = row.system === true;
          const policy = isGlobalRule && this.props.device.policies.firewall.policy;
          const enabled = row.enabled && (row.deviceSpecificRulesEnabled || isGlobalRule);
          return !isGlobalRule && !isSystemRule ?
            // non global and non system rule
            <>
              { this.disableEnableRuleComp(row, enabled, isGlobalRule) }
              { this.updateRuleComp(row) }

              <ReactTooltip id={'add-above-tip-' + row.direction + row.priority}>
                <span><TT>Add rule above</TT></span>
              </ReactTooltip>
              <Button
                className="action-btn"
                data-tip
                data-for={'add-above-tip-' + row.direction + row.priority}
                color="success"
                size="sm"
                onClick={() => this.handleAddRule(priority, direction)}
              >
                <FontAwesomeIcon icon={["fas", "level-up-alt"]} fixedWidth />
              </Button>
              <ReactTooltip id={'add-below-tip-' + row.direction + row.priority}>
                <span><TT>Add rule below</TT></span>
              </ReactTooltip>
              <Button
                className="action-btn"
                color="success"
                data-tip
                data-for={'add-below-tip-' + row.direction + row.priority}
                size="sm"
                onClick={() => this.handleAddRule(priority + 1, direction)}
              >
                <FontAwesomeIcon icon={["fas", "level-down-alt"]} fixedWidth />
              </Button>

              { this.duplicateRuleComp(row) }

              <ReactTooltip id={'delete-rule-tip-' + row.direction + row.priority}>
                <span><TT>Delete rule</TT></span>
              </ReactTooltip>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for={'delete-rule-tip-' + row.direction + row.priority}
                size="sm"
                onClick={() => this.handleDeleteRule(priority, direction)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
            </>
          : isSystemRule ? <>
            { this.disableEnableRuleComp(row, enabled, isGlobalRule) }
            { this.duplicateRuleComp(row) }
          </> :
            <>
              <ReactTooltip id={'enable-glrule-tip-' + row.direction + row.priority}>
                <span style={{"fontSize": "0.8rem"}}>
                  {
                    (row.enabled) ? <TT>Global rule can be disabled on firewal policy page</TT> : <TT>Global rule can be enabled on firewal policy page</TT>
                  }
                </span>
              </ReactTooltip>
              <Button
                color={enabled ? 'danger' : 'success'}
                className="action-btn"
                data-tip
                data-for={'enable-glrule-tip-' + row.direction + row.priority}
                size="sm"
                disabled={true}
              >
                <FontAwesomeIcon icon={enabled ? 'stop-circle' : 'check-circle'} fixedWidth />
              </Button>
              <ReactTooltip id={'update-glrule-tip-' + row.direction + row.priority}>
                <span><TT>Click to open global firewall policy</TT></span>
              </ReactTooltip>
              <Link to={"/firewallpolicies/" + policy._id}>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for={'update-glrule-tip-' + row.direction + row.priority}
                  size="sm"
                >
                  <FontAwesomeIcon icon="shield-alt" fixedWidth />
                </Button>
              </Link>
            </>
        }
      }
    ];

    this.FirewallRulesOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("First page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("Next page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "100", value: 100 },
        { text: "200", value: 200 },
        { text: "300", value: 300 }
      ]
    };

    const { policy, status } = this.props.device ? this.props.device.policies.firewall : {};
    const deviceSpecificRulesEnabled = this.props.deviceInfo.deviceSpecificRulesEnabled;
    const policyRules = policy && policy.rules && status.startsWith('install') ? policy.rules
      .map(r => ({ ...r, priority: r.priority + this.globalPriorityShift})) : [];
    const rules = [
      ...(this.props.firewallRules || []),
      ...(convertFirewallRules(policyRules, this.props.appIdentifications))
    ].sort((r1, r2) => r1.priority - r2.priority)
    .reduce((result, rule) => {
      result[rule.direction].push({
        ...rule,
        deviceSpecificRulesEnabled: this.props.deviceInfo.deviceSpecificRulesEnabled
      });
      return result;
    }, { inbound: [], outbound: [], lanNat: [] });

    const selectRow = direction => ({
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.selectedRules[direction],
      nonSelectable: rules[direction].map(r => r.priority)
        .filter(priority => priority >= this.globalPriorityShift || priority < 0),
      onSelect: (row, isSelect) => this.handleOnSelect(row, isSelect, direction),
      onSelectAll: (isSelect, rows) => this.handleOnSelectAll(isSelect, rows, direction)
    });

    const isSystemRulesEnabled = (this.props?.firewallRules ?? []).filter(r => r.system && r.enabled).length > 0 && deviceSpecificRulesEnabled;

    const major = getMajorVersion(this.props.device?.versions?.agent);
    const minor = getMinorVersion(this.props.device?.versions?.agent);
    const isDevSupportsSourcePort = major > 6 || (major === 6 && minor >= 2);
    const isDevSupportsLanNat = major > 6 || (major === 6 && minor >= 3);
    const tunnelPort = isDevSupportsSourcePort ? this.props.device?.orgInfo?.vxlanPort : '4789';

    return (
      <>
      <Form
        model="deviceInfo"
        onUpdate={(formValue) => {
          this.props.updateUserInputState(
            "general",
            formValue.$form.valid);
        }}
      >
        <Label className="FWswitch mr-3" data-tip data-for='systemRuleEnabledTip'>
          <Control.checkbox
            model=".deviceSpecificRulesEnabled"
            id="deviceSpecificRulesEnabled"
            name="deviceSpecificRulesEnabled"
            disabled={isSystemRulesEnabled}
          />
          <span className="FWslider round" />
        </Label>
        <span style={{ position: 'relative', top: '6px' }}>
          {deviceSpecificRulesEnabled ? <TT>Device specific rules enabled</TT> : <TT>Device specific rules disabled</TT>}
        </span>
        { isSystemRulesEnabled ? (
          <ReactTooltip id='systemRuleEnabledTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Some System rules enabled. Please disabled them first</TT>
            </span>
          </ReactTooltip>
        ) : null}

        <hr  style={{ marginBottom: 0, marginTop: '7px' }}/>
        <Label className="ml-3">
          <TT>Outbound rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='outboundRulesTip'></span>
          <ReactTooltip id='outboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Allowed by default</TT>
            </span>
          </ReactTooltip>
        </Label>
        <ReactTooltip id="add-outbound-rule">
          <span><TT>Add outbound rule</TT></span>
        </ReactTooltip>
        <Button
          data-tip
          data-for="add-outbound-rule"
          className="btn btn-primary policy-rules-buttons mt-2 mb-2"
          onClick={() => this.handleAddRule(rules.outbound.filter(r =>
            r.priority < this.globalPriorityShift).length, 'outbound')}
        >
          <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
        </Button>
        {
          this.state.selectedRules['outbound'].length > 0 ? <>
            <ReactTooltip id="enable-selected-outbound">
              <span><TT>Enable selected outbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="enable-selected-outbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('outbound', true)}
            >
              <TT>Enable</TT>
            </Button>
            <ReactTooltip id="disable-selected-outbound">
              <span><TT>Disable selected outbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="disable-selected-outbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('outbound', false)}
            >
              <TT>Disable</TT>
            </Button>
            <ReactTooltip id="delete-selected-outbound">
              <span><TT>Delete selected outbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="delete-selected-outbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleDeleteSelected('outbound')}
            >
              <TT>Delete</TT>
            </Button>
          </> : ''
        }
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="outbound">
            {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <BootstrapTable
                striped
                hover
                condensed
                classes="firewall-rules-table"
                keyField="priority"
                data={rules.outbound}
                columns={this.ruleColumns}
                pagination={paginationFactory(this.FirewallRulesOptions)}
                noDataIndication={toTTString("No outbound rules available")}
                defaultSorted={[{ dataField: "priority", order: "asc" }]}
                selectRow={selectRow('outbound')}
                rowEvents={{
                  onDoubleClick: (e, row) => row.priority < this.globalPriorityShift ?
                    this.handleUpdateRule(row) : false
                }}
                rowStyle={ row => !row.enabled || (!deviceSpecificRulesEnabled && row.priority < this.globalPriorityShift)
                  ? { color: 'grey' } : {}
                }
              />
              {provided.placeholder}
            </div>
          )}
          </Droppable>
        </DragDropContext>
        <hr  style={{ margin: 0 }}/>
        <Label className="ml-3">
          <TT>Inbound rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='inboundRulesTip'></span>
          <ReactTooltip id='inboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Denied by default</TT>
            </span>
          </ReactTooltip>
        </Label>
        <ReactTooltip id="add-inbound-rule">
          <span><TT>Add inbound rule</TT></span>
        </ReactTooltip>
        <Button
          data-tip
          data-for="add-inbound-rule"
          className="btn btn-primary policy-rules-buttons mt-2 mb-2"
          onClick={() => this.handleAddRule(rules.inbound.filter(r =>
            r.priority < this.globalPriorityShift).length, 'inbound')}
        >
          <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
        </Button>
        {
          this.state.selectedRules['inbound'].length > 0 ? <>
            <ReactTooltip id="enable-selected-inbound">
              <span><TT>Enable selected inbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="enable-selected-inbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('inbound', true)}
            >
              <TT>Enable</TT>
            </Button>
            <ReactTooltip id="disable-selected-inbound">
              <span><TT>Disable selected inbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="disable-selected-inbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('inbound', false)}
            >
              <TT>Disable</TT>
            </Button>
            <ReactTooltip id="delete-selected-inbound">
              <span><TT>Delete selected inbound rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="delete-selected-inbound"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleDeleteSelected('inbound')}
            >
              <TT>Delete</TT>
            </Button>
          </> : ''
        }
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="inbound">
            {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <BootstrapTable
                striped
                hover
                condensed
                classes="firewall-rules-table"
                keyField="priority"
                data={rules.inbound}
                columns={this.ruleColumns}
                pagination={paginationFactory(this.FirewallRulesOptions)}
                noDataIndication={toTTString("No inbound rules available")}
                defaultSorted={[{ dataField: "priority", order: "asc" }]}
                selectRow={selectRow('inbound')}
                rowEvents={{
                  onDoubleClick: (e, row) => (row.priority < this.globalPriorityShift) &&  !row.system ?
                    this.handleUpdateRule(row) : false
                }}
                rowStyle={ row => !row.enabled || (!deviceSpecificRulesEnabled && row.priority < this.globalPriorityShift)
                  ? { color: 'grey' } : {}
                }
              />
              {provided.placeholder}
            </div>
            )}
          </Droppable>
        </DragDropContext>
        {isDevSupportsLanNat && <>
        <hr  style={{ margin: 0 }}/>
        <Label className="ml-3">
          <TT>LAN NAT rules</TT>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='lanNatRulesTip'></span>
          <ReactTooltip id='lanNatRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>Allowed only on LAN interfaces</TT>
            </span>
          </ReactTooltip>
        </Label>
        <ReactTooltip id="add-lan-nat-rule">
          <span><TT>Add LAN NAT rule</TT></span>
        </ReactTooltip>
        <Button
          data-tip
          data-for="add-lan-nat-rule"
          className="btn btn-primary policy-rules-buttons mt-2 mb-2"
          onClick={() => this.handleAddRule(rules.lanNat.filter(r =>
            r.priority < this.globalPriorityShift).length, 'lanNat')}
        >
          <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
        </Button>
        {
          this.state.selectedRules['lanNat'].length > 0 ? <>
            <ReactTooltip id="enable-selected-lan-nat">
              <span><TT>Enable selected LAN NAT rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="enable-selected-lan-nat"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('lanNat', true)}
            >
              <TT>Enable</TT>
            </Button>
            <ReactTooltip id="disable-selected-lan-nat">
              <span><TT>Disable selected LAN NAT rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="disable-selected-lan-nat"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleEnableSelected('lanNat', false)}
            >
              <TT>Disable</TT>
            </Button>
            <ReactTooltip id="delete-selected-lan-nat">
              <span><TT>Delete selected LAN NAT rules</TT></span>
            </ReactTooltip>
            <Button
              data-tip data-for="delete-selected-lan-nat"
              className="btn btn-primary policy-rules-buttons mt-2 mb-2"
              onClick={() => this.handleDeleteSelected('lanNat')}
            >
              <TT>Delete</TT>
            </Button>
          </> : ''
        }
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="lanNat">
            {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <BootstrapTable
                striped
                hover
                condensed
                classes="firewall-rules-table"
                keyField="priority"
                data={rules.lanNat}
                columns={this.ruleColumns}
                pagination={paginationFactory(this.FirewallRulesOptions)}
                noDataIndication={toTTString("No LAN NAT rules available")}
                defaultSorted={[{ dataField: "priority", order: "asc" }]}
                selectRow={selectRow('lanNat')}
                rowEvents={{
                  onDoubleClick: (e, row) => (row.priority < this.globalPriorityShift) &&  !row.system ?
                    this.handleUpdateRule(row) : false
                }}
                rowStyle={ row => !row.enabled || (!deviceSpecificRulesEnabled && row.priority < this.globalPriorityShift)
                  ? { color: 'grey' } : {}
                }
              />
              {provided.placeholder}
            </div>
            )}
          </Droppable>
        </DragDropContext>
        </>}

        <FirewallRuleModal
          firewallRule={this.props.firewallRule}
          interfaces={this.props.interfaces}
          applicationIfcs={this.state.applicationIfcs}
          changeRuleForm={this.props.changeRuleForm}
          apps={this.props.apps}
          categories={this.props.categories}
          serviceClasses={this.props.serviceClasses}
          isOpen={this.state.isRuleModalOpen}
          toggle={this.toggleRuleModal}
          saveRule={this.saveRuleFunction}
          tunnelPort={tunnelPort}
        />
      </Form>

        <Modal isOpen={this.state.isApproveDisableSystemRuleModalOpen} toggle={this.toggleApproveDisableSystemRuleModal}>
          <ModalHeader toggle={this.toggleApproveDisableSystemRuleModal}>
            <TT>Disable system rule</TT>?
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>
                The system created this rule. Disabling this rule might cause improper behavior. Are you sure?
              </TT>
            </div>
            <Button color="danger" onClick={this.approveDisableSystemRule}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="default"
              onClick={this.toggleApproveDisableSystemRuleModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default DeviceFirewallCard;
