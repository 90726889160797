import React from "react";
import { useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap";
import { Control, Form, Errors, actions } from "react-redux-form";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import {
  maxLength,
  isIP4,
  isIP4Mask,
  isValidPrefixLength,
  isPort,
  isPortValid,
  isEmpty
} from "../../utils/Validators";
import SearchableSelectBox from '../items/SearchableSelectBox';
import { FWTextInput, FWDataList } from '../Common';
import { store } from "../../App";
import standartPorts from '../../utils/standartPorts';
import { TT, LanguageContext } from '../../containers/Language'
import { selectInterfacesConvert } from '../../utils/DevicesUtils';
import LanNatRuleModal from './LanNatRuleModal';

const standartPortsList = Object.entries(standartPorts).map(([port, service]) => ({
  name: `${service} (${port})`,
  value: port
}));

const inboundOptions = {
  edgeAccess: "This flexiEdge Access",
  portForward: "Port Forward",
  nat1to1: "NAT 1:1"
};

const handleChangeAction = (model, value) => {
  return (dispatch) => {
    dispatch(actions.change(model, value ? value.value : ""));
  };
}

const isIPAddrValid = (ip) => {
  if (!ip || ip === "") return true;

  const [addr, mask] = ip.split("/");
  return isIP4(addr) && isIP4Mask(mask);
}

const isInternalPortOverlap = (value, param) => {
  const { firewallRule, devices } = store.getState();
  const { priority, classification, inbound } = firewallRule;
  let { internalIP, internalPortStart } = firewallRule;
  if (inbound !== 'portForward') {
    // check only for port forwards rules
    return true;
  }
  if (param === 'port') {
    internalPortStart = value;
  } else if (param === 'ip') {
    internalIP = value;
  }
  const portForwardRules = (devices.firewallRules || [])
    .filter(r => r.inbound === 'portForward' && r.priority !== priority
      && internalIP === r.internalIP);

  const lockedPorts = [];
  for (const rule of portForwardRules) {
    const { ports: usedPorts } = rule.classification.destination.ipProtoPort;
    if (usedPorts && usedPorts.includes("-")) {
      const [portLow, portHigh] = usedPorts.split("-");
      for (let usedPort = +portLow; usedPort <= +portHigh; usedPort++) {
        lockedPorts.push(+rule.internalPortStart + usedPort - portLow);
      }
    } else {
      lockedPorts.push(+rule.internalPortStart);
    }
  }
  const { ports } = classification.destination.ipProtoPort;
  if (ports && ports.includes("-")) {
    // in case of ports range need to check internal forwarded range as well
    const [portLow, portHigh] = ports.split("-");
    const internalPortEnd = +internalPortStart + (portHigh - portLow);
    for (const lockedPort of lockedPorts) {
      if ((+internalPortStart <= lockedPort) && (lockedPort <= +internalPortEnd)) {
        return false;
      }
    }
  } else {
    for (const lockedPort of lockedPorts) {
      if (+internalPortStart === lockedPort) {
        return false;
      }
    }
  }
  return true
}

const isPortForwardValid = (ports, tunnelPort) => {
  const state = store.getState();
  const currentRule = state.firewallRule;
  // validate only port forward rules
  if (currentRule && currentRule.inbound !== 'portForward') {
    return true;
  }
  // Port can't be empty in case of port forward rule
  if (isEmpty(ports)) {
    return false;
  }
  const lockedPorts = [+tunnelPort];
  const { interface: wanIfc } = currentRule.classification.destination.ipProtoPort;
  const portForwardRules = (state.devices.firewallRules || [])
    .filter(r => r.inbound === 'portForward' && r.priority !== currentRule.priority
      && wanIfc === r.classification.destination.ipProtoPort.interface);

  for (const usedPorts of portForwardRules.map(r => r.classification.destination.ipProtoPort.ports)) {
    if (usedPorts && usedPorts.includes("-")) {
      const [portLow, portHigh] = usedPorts.split("-");
      for (let usedPort = +portLow; usedPort <= +portHigh; usedPort++) {
        lockedPorts.push(usedPort);
      }
    } else {
      lockedPorts.push(+usedPorts);
    }
  }
  if (ports.includes("-")) {
    const [portLow, portHigh] = ports.split("-");
    for (const lockedPort of lockedPorts) {
      if ((+portLow <= lockedPort) && (lockedPort <= +portHigh)) {
        return false;
      }
    }
  } else {
    for (const lockedPort of lockedPorts) {
      if (+ports === lockedPort) {
        return false;
      }
    }
  }
  return true;
}

const LanInterfacesSelect = ({ rule, changeRuleForm, interfaces, applicationIfcs }) => {
  const { toTTString } = useContext(LanguageContext);
  return interfaces && rule.direction === 'outbound' ?
  <Row>
    <Label htmlFor="interfaces" md={4} className="col-form-label-sm"><TT>Interfaces</TT> </Label>
    <Col>
      <Select
        options={[...interfaces, ...applicationIfcs]
          .filter(ifc => ifc.isAssigned === 'Yes' && ifc.type === 'LAN')
          .map(selectInterfacesConvert)
        }
        isMulti={true}
        value={[...interfaces, ...applicationIfcs]
          .filter(ifc => rule.interfaces.includes(ifc.devId))
          .map(selectInterfacesConvert)
        }
        onChange={result =>
          changeRuleForm({
            ...rule,
            interfaces: (result || []).map(ifc => ifc.value)
          })
        }
        model={".interfaces"}
        name={"interfaces"}
        id={"interfaces"}
        placeholder={toTTString("All LAN interfaces")}
      />
    </Col>
  </Row> : ''
}


const AppNameClassifier = ({ apps, model }) => (
  <>
    <Row className="form-group">
      <Label htmlFor={model} md={4} className="col-form-label-sm">
        <TT>Name</TT>
        {model.includes('source') ?
        <>
          <span className="helpTooltip ml-3 mr-3" data-tip data-for='outboundRulesTip'></span>
          <ReactTooltip id='outboundRulesTip'>
            <span style={{"fontSize": "0.8rem"}}>
              <TT>the protocol is ignored if defined in the traffic name rules</TT>
            </span>
          </ReactTooltip>
        </>: ''
        }

      </Label>
      <Col>
        <Control
          model={model}
          id={model}
          name={model}
          placeholder=""
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={[...apps.values()]}
        />
      </Col>
    </Row>
  </>
)

const TrafficTagsClassifier = ({ categories, serviceClasses, model }) => {
  const { toTTString } = useContext(LanguageContext);

  return (
  <>
    <Row className="form-group">
      <Label htmlFor="category" md={4} className="col-form-label-sm">
        <TT>Category</TT>
      </Label>
      <Col>
        <Control
          model={model+".category"}
          id={model+".category"}
          name={model+".category"}
          className="category-select"
          placeholder=""
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={categories}
        />
      </Col>
    </Row>
    <Row className="form-group">
      <Label htmlFor="serviceClass" md={4} className="col-form-label-sm">
        <TT>Service Class</TT>
      </Label>
      <Col>
        <Control
          model={model+".serviceClass"}
          id={model+".serviceClass"}
          name={model+".serviceClass"}
          placeholder=""
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={serviceClasses}
        />
      </Col>
    </Row>
    <Row className="form-group">
      <Label htmlFor="importance" md={4} className="col-form-label-sm">
        <TT>Importance</TT>
      </Label>
      <Col>
        <Control.select
          model={model+".importance"}
          id={model+".importance"}
          name={model+".importance"}
          placeholder=""
          className="form-control"
        >
          {['', 'low', 'medium', 'high'].map((importance) => (
            <option key={importance} value={importance}>
              {toTTString(importance)}
            </option>
          ))}
        </Control.select>
      </Col>
    </Row>
  </>
  )
}

const IpPortProtocolClassifier = ({ rule, model }) => {
  const { ipProtoPort } = rule.classification.destination;
  const { toTTString } = useContext(LanguageContext);
  return <>
    <Row className="form-group">
      <Label htmlFor="ip" md={4} className="col-form-label-sm">
        <TT>IP Address</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={model+".ip"}
          name={model+".ip"}
          id={model+".ip"}
          placeholder={toTTString("IP/mask")}
          component={FWTextInput}
          withFieldValue
          validators={{
            ip: isIPAddrValid,
            prefixLen: isValidPrefixLength
          }}
        />
        <Errors
          className="text-danger"
          model={model+".ip"}
          show="touched"
          messages={{
            ip: toTTString("Invalid IP Network Format"),
            prefixLen: toTTString("Invalid prefix for given mask")
          }}
        />
      </Col>
    </Row>
    <Row className="form-group">
      <Label htmlFor="protocols" md={4} className="col-form-label-sm">
        <TT>Protocols</TT>
      </Label>
      <Col>
        <Control
          component={Select}
          options={[toTTString('icmp'), toTTString('tcp'), toTTString('udp')].map(p => ({value: p, label: p.toUpperCase()}))}
          isMulti={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={(ipProtoPort.protocols || [])
            .map(p => ({value: p, label: p.toUpperCase()}))
          }
          validators={{
            selected: val => val?.length
          }}
          changeAction={(model, value) => dispatch => dispatch(actions.change(model, (value || []).map(p => p.value)))}
          model={model+".protocols"}
          name={model+".protocols"}
          id={model+".protocols"}
          placeholder={toTTString("Select protocols") + "..."}
        />
        <Errors
          className="text-danger"
          model={model+".protocols"}
          show="touched"
          messages={{
            selected: toTTString("At least one protocol must be specified"),
          }}
        />
      </Col>
    </Row>
    { !ipProtoPort.protocols || !ipProtoPort.protocols.includes('icmp') ?
    <Row className="form-group">
      <Label htmlFor="ports" md={4} className="col-form-label-sm">
        <TT>Ports range</TT>
        <span className="helpTooltip" data-tip data-for='inboundPortsTip'></span>
        <ReactTooltip id='inboundPortsTip'>
          <span style={{"fontSize": "0.8rem"}}>
            <TT>Start searching by service name or provide a ports range</TT>
          </span>
        </ReactTooltip>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={model+".ports"}
          name={model+".ports"}
          id={model+".ports"}
          placeholder={toTTString("Any")}
          component={FWDataList}
          listOptions={standartPortsList}
          withFieldValue
          validators={{
            ports: isPortValid,
          }}
        />
        <Errors
          className="text-danger"
          model={model+".ports"}
          show="touched"
          messages={{
            ports: toTTString("Invalid ports range ([low port-high port])"),
          }}
        />
      </Col>
    </Row> : ''}
  </>
}

const IpPortClassifier = ({ model }) => {
  const { toTTString } = useContext(LanguageContext);
  return (
  <>
    <Row className="form-group">
      <Label htmlFor="ip" md={4} className="col-form-label-sm">
        <TT>IP Address</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={model+".ip"}
          name={model+".ip"}
          id={model+".ip"}
          placeholder={toTTString("IP/mask")}
          component={FWTextInput}
          withFieldValue
          validators={{
            ip: isIPAddrValid,
            prefixLen: isValidPrefixLength
          }}
        />
        <Errors
          className="text-danger"
          model={model+".ip"}
          show="touched"
          messages={{
            ip: toTTString("Invalid IP Network Format"),
            prefixLen: toTTString("Invalid prefix for given mask")
          }}
        />
      </Col>
    </Row>
    <Row className="form-group">
      <Label htmlFor="ports" md={4} className="col-form-label-sm">
        <TT>Ports range</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={model+".ports"}
          name={model+".ports"}
          id={model+".ports"}
          placeholder={toTTString("Ports range")}
          component={FWDataList}
          listOptions={standartPortsList}
          withFieldValue
          validators={{
            ports: isPortValid,
          }}
        />
        <Errors
          className="text-danger"
          model={model+".ports"}
          show="touched"
          messages={{
            ports: toTTString("Invalid ports range ([low port-high port])"),
          }}
        />
      </Col>
    </Row>
  </>
  )
}

const InboundDestinationClassifier = ({ model, interfaces, rule, changeRuleForm, tunnelPort }) => {
  const { toTTString } = useContext(LanguageContext);
  return (
  <>
    <Row className="form-group">
      <Label md={4} className="col-form-label-sm">
        <TT>WAN Interface</TT>
      </Label>
      <Col>
        {interfaces ? (
          <>
            <Select
              options={[...interfaces]
                .filter(ifc => ifc.isAssigned === 'Yes' && ifc.type === 'WAN')
                .map(selectInterfacesConvert)
              }
              isMulti={false}
              isClearable={true}
              value={rule.classification.destination.ipProtoPort.interface ?
                ([rule.classification.destination.ipProtoPort.interface]).map(selectedIfc => {
                  const ifc = interfaces.find(i => i.devId === selectedIfc);
                  return selectInterfacesConvert(ifc);
                })
                : null
              }
              onChange={result => {
                changeRuleForm({
                  ...rule,
                  classification: { ...rule.classification,
                    destination: { ...rule.classification.destination,
                      ipProtoPort: { ...rule.classification.destination.ipProtoPort,
                        interface: result ? result.value : null
                      }
                    }
                  }
                })
              }}
              model={model + ".interface"}
              name={model + ".interface"}
              id={model + ".interface"}
              placeholder={toTTString(
                rule.inbound === 'edgeAccess' ? "All WAN interfaces" : "Select WAN interface"
              )}
            />
            <Errors
              className="text-danger"
              model={model + ".interface"}
              show="touched"
              messages={{
                required: toTTString("Required field")
              }}
            />
          </>
        )
        : <Input
            disabled={true}
            value={toTTString("All (to access flexiEdge)")}
          />
        }
      </Col>
    </Row>
    { rule.inbound !== 'nat1to1' ? <>
    <Row className="form-group">
      <Label htmlFor="ports" md={4} className="col-form-label-sm">
        <TT>Ports range</TT>
      </Label>
      <Col>
        <Control.text
          type="text"
          model={model+".ports"}
          name={model+".ports"}
          id={model+".ports"}
          placeholder={toTTString("Ports range")}
          component={FWDataList}
          listOptions={standartPortsList}
          withFieldValue
          validators={{
            ports: isPortValid,
            portForward: val => isPortForwardValid(val, tunnelPort)
          }}
        />
        <Errors
          className="text-danger"
          model={model+".ports"}
          show="touched"
          messages={{
            ports: toTTString("Invalid ports range ([low port-high port])"),
            portForward: toTTString("This value can't be used for port forwarding"),
          }}
        />
      </Col>
    </Row>
    <Row className="form-group">
      <Label htmlFor="protocols" md={4} className="col-form-label-sm">
        <TT>Protocols</TT>
      </Label>
      <Col>
        <Control
          component={Select}
          options={[toTTString('tcp'), toTTString('udp')].map(p => ({value: p, label: p.toUpperCase()}))}
          isMulti={true}
          isRequired={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={(rule.classification.destination.ipProtoPort.protocols || [])
            .map(p => ({value: p, label: p.toUpperCase()}))
          }
          validators={{
            selected: val => val?.length
          }}
          changeAction={(model, value) => dispatch => dispatch(actions.change(model, (value || []).map(p => p.value)))}
          model={model+".protocols"}
          name={model+".protocols"}
          id={model+".protocols"}
          placeholder={toTTString("Select protocols") + "..."}
        />
        <Errors
          className="text-danger"
          model={model+".protocols"}
          show="touched"
          messages={{
            selected: toTTString("At least one protocol must be specified"),
          }}
        />
      </Col>
    </Row>
    </> : ''}
  </>
  )
}

const InternalIP = () => {
const { toTTString } = useContext(LanguageContext);
return (
  <>
    <Label htmlFor="internalIP" md={4} className="col-form-label-sm">
      <TT>Internal IP Address</TT>
    </Label>
    <Col>
    <Control.text
      type="text"
      model=".internalIP"
      name="internalIP"
      id="internalIP"
      placeholder={toTTString("IP Address")}
      component={FWTextInput}
      withFieldValue
      validators={{
        ip: isIP4,
        overlap: ip => isInternalPortOverlap(ip, 'ip')
      }}
    />
    <Errors
      className="text-danger"
      model=".internalIP"
      show="touched"
      messages={{
        ip: toTTString("Invalid IP Address Format"),
        overlap: toTTString("Internal ports overlap")
      }}
    />
    </Col>
  </>
  )
}

const InternalPortStart = () => {
  const { toTTString } = useContext(LanguageContext);
  return (
  <>
    <Label htmlFor=".internalPortStart"  md={4} className="col-form-label-sm">
      <TT>Internal Start Port</TT>
      <span className="helpTooltip" data-tip data-for='internalPortStartTip'></span>
      <ReactTooltip id='internalPortStartTip'>
        <span style={{"fontSize": "0.8rem"}}>
          <TT>Internal start port forwarding mapping relative to Destination ports range</TT>
        </span>
      </ReactTooltip>
    </Label>
    <Col>
    <Control.text
      type="text"
      model=".internalPortStart"
      name="internalPortStart"
      id=".internalPortStart"
      placeholder={toTTString("Internal Port Start")}
      component={FWTextInput}
      withFieldValue
      validators={{
        port: isPort,
        overlap: port => isInternalPortOverlap(port, 'port')
      }}
    />
    <Errors
      className="text-danger"
      model=".internalPortStart"
      show="touched"
      messages={{
        port: toTTString("Invalid Port Format"),
        overlap: toTTString("Internal ports overlap")
      }}
    />
    </Col>
  </>
  )
}

const DestinationClassifiersCard = props => {
  const { toTTString } = useContext(LanguageContext);
  const { direction, classification } = props.rule;
  const { destinationType } = classification;
  const destinationClassifiers = {
    any : toTTString("Any"),
    trafficId : toTTString("Traffic Name"),
    trafficTags: toTTString("Traffic Tags"),
    ipProtoPort: toTTString("Custom IP/Port/Protocol")
  };

  return (
<Card>
  <CardHeader>
    <Row>
      <Label htmlFor="destinationType" md={4} className="col-form-label-sm font-weight-bold">
        { direction !== 'inbound' ? <TT>App / Destination</TT> : <TT>Destination</TT> }
      </Label>
      <Col>
        { direction !== 'inbound' ?
          <Control.select
            model=".classification.destinationType"
            id="destinationType"
            name="classification.destinationType"
            className="form-control"
          >
            {Object.entries(destinationClassifiers).map(([key, name]) => (
              <option key={key} value={key}>
                {name}
              </option>
            ))}
          </Control.select>
        : ''}
      </Col>
    </Row>
  </CardHeader>
  <CardBody>
    <Row className="form-group">
      <Col>
      { direction !== 'inbound' ?
        (type => {
          switch (type) {
            case 'trafficId':
              return <AppNameClassifier
                model='.classification.destination.appName'
                apps={props.apps}
              />
            case 'trafficTags':
              return <TrafficTagsClassifier
                model='.classification.destination.trafficTags'
                categories={props.categories}
                serviceClasses={props.serviceClasses}
              />
            case 'ipProtoPort':
              return <IpPortProtocolClassifier
                rule={props.rule}
                changeRuleForm={props.changeRuleForm}
                model='.classification.destination.ipProtoPort'
              />
            default:
              return '';
          }
        })(destinationType)
        : <InboundDestinationClassifier
            model='.classification.destination.ipProtoPort'
            interfaces={props.interfaces}
            rule={props.rule}
            tunnelPort={props.tunnelPort}
            changeRuleForm={props.changeRuleForm}
        />
      }
      </Col>
    </Row>
  </CardBody>
</Card>
)}

const SourceClassifiersCard = (props) => {
  const { toTTString } = useContext(LanguageContext);
  const { sourceType } = props.rule.classification;
  const sourceClassifiers = {
    any : toTTString("Any"),
    trafficId : toTTString("Traffic Name"),
    ipPort: toTTString("Custom IP/Port")
  };
  return (
<Card className='mb-2'>
  <CardHeader>
    <Row>
      <Label htmlFor="sourceType" md={4} className="col-form-label-sm font-weight-bold">
        <TT>Source</TT>
      </Label>
      <Col>
        <Control.select
          model=".classification.sourceType"
          id="sourceType"
          name="classification.sourceType"
          className="form-control"
        >
          {Object.entries(sourceClassifiers).map(([key, name]) => (
            <option key={key} value={key}>
              {name}
            </option>
          ))}
        </Control.select>
      </Col>
    </Row>
  </CardHeader>
  <CardBody>
    <Row className="form-group">
      <Col>
      {(type => {
          switch (type) {
            case 'trafficId':
              return <AppNameClassifier
                model='.classification.source.appName'
                apps={props.apps}
              />
            case 'trafficTags':
              return <TrafficTagsClassifier
                model='.classification.source.trafficTags'
                categories={props.categories}
                serviceClasses={props.serviceClasses}
              />
            case 'ipPort':
              return <IpPortClassifier
                model='.classification.source.ipPort'
              />
            default:
              return '';
          }
        })(sourceType)}
      </Col>
    </Row>
  </CardBody>
</Card>
)}

const ActionCard = props =>
<Card>
  <CardHeader>
    <Row>
      <Label htmlFor="sourceType" md={4} className="col-form-label-sm font-weight-bold">
        <TT>Action</TT>
      </Label>
    </Row>
  </CardHeader>
  <CardBody>
    <Row className="form-group">
      <Col>
        <Row className="form-group">
          <InternalIP/>
        </Row>
        {
          props.rule.inbound === 'portForward' ?
          <Row className="form-group">
            <InternalPortStart/>
          </Row> : ''
        }
      </Col>
    </Row>
  </CardBody>
</Card>

const FirewallRuleModal = (props) => {
  const { toTTString } = useContext(LanguageContext);
  const { enabled, direction, inbound, isNewRule } = props.firewallRule;
  if (direction === 'lanNat') return <LanNatRuleModal {...props}/>;
  return (
  <Modal
    size='xl'
    isOpen={props.isOpen}
  >
    <ModalHeader toggle={props.toggle}>
      <TT params={{direction: toTTString(direction)}}>Firewall #direction# rule</TT>
    </ModalHeader>
    <ModalBody>
      <Card>
        <CardBody>
          <Form
            model="firewallRule"
            validators={{
              'classification.destination.ipProtoPort.interface': {
                required: val => {
                  // dont allow nat1to1 and port forward to be without wan interface
                  const { direction, inbound, classification } = props.firewallRule;
                  if (direction === 'inbound' && inbound !== 'edgeAccess') {
                    if ((classification?.destination?.ipProtoPort?.interface ?? '') !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return true;
                }}
            }}
            onSubmit={result => {
              props.saveRule(result)
              props.toggle();
            }}
          >
            <Row>
              <Col>
                <Row className="form-group">
                  <Col md={6}>
                    <Card className='border-0'>
                      <CardBody className='pb-0'>
                        <Row className="form-group">
                          <Label htmlFor="action" md={4} className="col-form-label-sm font-weight-bold">
                            {enabled ? <TT>Enabled</TT> : <TT>Disabled</TT>}
                          </Label>
                          <Col>
                            <Label className="FWswitch" data-tip data-for='ruleEnableTip' >
                              <Control.checkbox
                                model=".enabled"
                                id="ruleEnabled"
                                name="enabled"
                              />{" "}
                              <span className="FWslider round" />
                            </Label>
                            <ReactTooltip id='ruleEnableTip'>
                              <span style={{"fontSize": "0.8rem"}}>
                                {enabled ? <TT>Disable the rule</TT> : <TT>Enable the rule</TT>}
                              </span>
                            </ReactTooltip>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          {props.interfaces && direction === 'inbound' ? <>
                            <Label  htmlFor="inbound" md={4} className="col-form-label-sm font-weight-bold">
                              <TT>Type</TT>
                            </Label>
                            <Col>
                              <Control.select
                                model=".inbound"
                                id="inbound"
                                name="inbound"
                                className="form-control"
                              >
                                {Object.entries(inboundOptions).map(([key, name]) => (
                                  <option key={key} value={key}>
                                    {toTTString(name)}
                                  </option>
                                ))}
                              </Control.select>
                            </Col>
                          </> : ''}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className='border-0'>
                      <CardBody className='pb-0'>
                        <Row>
                          <Label htmlFor="ruleDescription" md={4} className="col-form-label-sm font-weight-bold">
                            <TT>Description</TT>
                          </Label>
                          <Col>
                            <Control.textarea
                              model=".description"
                              id="ruleDescription"
                              name="description"
                              className="form-control"
                              placeholder={toTTString("Rule Description")}
                              validators={{
                                maxLength: maxLength(100)
                              }}
                            />
                            <Errors
                              className="text-danger"
                              model=".name"
                              show="touched"
                              messages={{
                                maxLength: toTTString("Rule description length must be at most 100")
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="form-group">
              <Col>
                <DestinationClassifiersCard
                  rule={props.firewallRule}
                  changeRuleForm={props.changeRuleForm}
                  interfaces={props.interfaces}
                  apps={props.apps}
                  categories={props.categories}
                  serviceClasses={props.serviceClasses}
                  tunnelPort={props.tunnelPort}
                />
              </Col>
              <Col>
                {['edgeAccess', 'portForward'].includes(inbound) ? (
                <SourceClassifiersCard
                  rule={props.firewallRule}
                  changeRuleForm={props.changeRuleForm}
                  interfaces={props.interfaces}
                  apps={props.apps}
                  categories={props.categories}
                  serviceClasses={props.serviceClasses}
                />) : ''}
                {['nat1to1', 'portForward'].includes(inbound) ? (
                <ActionCard
                  rule={props.firewallRule}
                />) : ''}
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="form-group">
                  <Col md={6}>
                    <Card className='border-0'>
                      <CardBody className='pb-0'>
                        <LanInterfacesSelect
                          rule={props.firewallRule}
                          changeRuleForm={props.changeRuleForm}
                          interfaces={props.interfaces}
                          applicationIfcs={props?.applicationIfcs ?? []}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {
                      direction === 'outbound' ?
                      <Card className='border-0'>
                        <CardBody className='pb-0 pt-0'>
                          <Row>
                            <Label htmlFor="action" md={4} className="col-form-label-sm">
                              <TT>Action</TT>
                            </Label>
                            <Col>
                              <Control.select
                                model=".action"
                                id="action"
                                name="action"
                                className="form-control"
                              >
                                {['allow', 'deny'].map(action => (
                                  <option key={action} value={action}>
                                    {toTTString(action)}
                                  </option>
                                ))}
                              </Control.select>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card> : ''
                    }
                  </Col>
                  <Col className="text-right align-bottom">
                    <Control.button
                      model="updateRule"
                      disabled={{ valid: false }}
                      className="btn btn-primary"
                    >
                      {isNewRule ? toTTString('Add Rule') : toTTString('Update Rule')}
                    </Control.button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </ModalBody>
  </Modal>
  )};

  export default FirewallRuleModal;