/**
 * Check if interface is inside a bridge
 *
 * Note! This function works with tableInterfaces array and not with interfaces array that comes from server.
 * There are differences between them - see at saveDeviceInterfaces in DeviceInfo.js
 * @param {Object} ifc
 * @param {Array} interfaces
 * @return {Boolean} Indication if interface is inside a bridge
 */
 export const getBridgedInterface = (ifc, interfaces) => {
    if (ifc.isAssigned !== 'Yes' || !ifc.IPv4 || ifc.type !== 'LAN') return [];
    const bridgedInterfaces = interfaces.filter(i => i.isAssigned === 'Yes' && i.type === 'LAN' && i.devId !== ifc.devId && i.IPv4 === ifc.IPv4);
    if (bridgedInterfaces.length) {
        return [ifc, ...bridgedInterfaces]
    }
    return [];
};

/**
 * Get the major version X from a string in semVer format X.Y.Z
 * @param {String} versionString
 */
export const getMajorVersion = (versionString) => {
  return versionString ? parseInt(versionString.split('.')[0], 10) : undefined;
};

/**
 * Get the minor version Y from a string in semVer format X.Y.Z
 * @param {String} versionString
 */
export const getMinorVersion = (versionString) => {
  return versionString ? parseInt(versionString.split('.')[1], 10) : undefined;
};

export const isDeviceVersionGreaterEquals = (deviceVersion, version) => {
  if (!deviceVersion || !version) {
    return undefined;
  }

  const source = deviceVersion.split('.').map(Number);
  const target = version.split('.').map(Number);

  if (source.length !== 3 || target.length !== 3) {
    return undefined;
  }

  for (let i = 0; i < source.length; i++) {
    if (source[i] > target[i]) {
      return true;
    } else if (source[i] < target[i]) {
      return false;
    }
  }

  // Both version are equal
  return true;
};

/**
 * Converts interface object to { value, label } item required by 'selects'
 * @param {Object} ifc network interface object
 */
 export const selectInterfacesConvert = ifc => ({
  value: ifc.devId,
  label: `${ifc.name} - ${ifc.IPv4}`,
  IPv4: ifc.IPv4
});

/**
  * Returns an array of text with reasons why the interface is locked and can't be removed
  * @param {Object} ifc
  * @param {Object} device
  * @param {Array} deviceTunnels
  * @return {Object} lock reasons
  */
export const getInterfaceLockReasons = (ifc, device, deviceTunnels, dhcp) => {
  const lockReasons = {};
  if (!ifc?.devId || !device) return lockReasons;

  const hasTunnel = Array.isArray(deviceTunnels) && deviceTunnels.some(t =>
    ifc._id === t.interfaceA || ifc._id === t.interfaceB
  );
  const hasStaticRoute = device?.staticroutes?.some(s => ifc.devId === s.ifname);
  const hasFirewallRule = device?.firewall?.rules?.some(r =>
    (r.interfaces?.includes(ifc.devId)) ||
    (r.classification?.destination?.ipProtoPort?.interface === ifc.devId)
  );
  const hasDHCPServer = dhcp?.some(d => d.interface === ifc.devId);

  if (ifc.locked) lockReasons.locked = "This VLAN is locked, to unlock, delete VLAN from flexiEdge UI";
  if (hasTunnel) lockReasons.hasTunnel = "The interface is used in tunnels";
  if (hasStaticRoute) lockReasons.hasStaticRoute = "Static route is defined on the interface";
  if (hasFirewallRule) lockReasons.hasFirewallRule = "The interface is used in firewall rules";
  if (hasDHCPServer) lockReasons.hasDHCPServer = "DHCP server is defined on the interface";

  return lockReasons;
}