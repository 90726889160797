import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { TT, LanguageContext } from "../containers/Language";
import ReactTooltip from "react-tooltip";
import { Control, Form, Errors } from "react-redux-form";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { required, isNumber,validateNotificationField, validateNotificationsForm, isValidURL } from "../utils/Validators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paginationFactory from "react-bootstrap-table2-paginator";
import withSaveBeforeLeaving from "../HOC/SaveBeforeLeaving";
import { FWTextInput } from "./Common";
import isEqual from 'lodash/isEqual';

class NotificationsConf extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      isNotificationModalOpen: false,
      isEmailModalOpen: false,
      isResetModalOpen: false,
      isWebHookModalOpen: false,
      tableResetTo: "",
      appliesTo: "organization",
      entityNameOptions: [],
      selectedEntityName: props.auth.orgName,
      selectedEntityID: props.auth.org,
      users: null,
      popupUsers: null,
      notifications: null,
      currentEventName: "",
      accountDefault: null,
      systemDefault: null,
      dataChanged: false,
      webHookSettings: {},
      loggedInUserPermission: "",
      isUserPermittedToModify: "",
      shouldShowWarningUnderEntity: false,
      isUserPermittedToView: true
    };
  }

  componentDidMount() {
    this.props.getAllOrganizations((resp) => {
      this.setState({ organizations: resp });
    });
    this.fetchAndSetNotificationsConf({ org: this.props.auth.org });
    this.fetchAndSetEmailNotificationsConf({ org: this.props.auth.org });
    this.props.getMemberOptions("organization", (resp) => {
      this.setState({ entityNameOptions: resp });
    });
    this.props.getDefaultNotificationsConf({ account: this.props.auth.account }, (response) => {
      this.setState({ accountDefault: response });
    });
    this.props.getDefaultNotificationsConf({ account: null }, (resp) => {
      this.setState({ systemDefault: resp });
    });
    this.fetchAndSetWebHookSettings({ org: this.props.auth.org });
    this.props.getAllMembers().then(() => {
      const loggedInUser = this.props.members.members.find(
        (member) => member.user__id === this.props.auth.userId
      );
      const loggedInUserPermission = `${loggedInUser?.to} ${loggedInUser?.role}`
      this.setState({
        loggedInUserPermission,
        isUserPermittedToModify: !loggedInUserPermission.includes("viewer")
      });
    });
  }

  /**
   * Fetches and sets all configurations for the provided entity type.
   * @param {Object} entityDetails - Object that provides contextual information ( e.g { account: accountId })
  */
  fetchAndSetAllConfigurations = async (entityDetails) => {
    try {
      await this.fetchAndSetNotificationsConf(entityDetails);
      await this.fetchAndSetEmailNotificationsConf(entityDetails);
      await this.fetchAndSetWebHookSettings(entityDetails);
      return true;
    } catch (error) {
      console.error('Error in fetching and setting configurations:', error);
      return false;
    }
  };

  fetchAndSetNotificationsConf = (entityDetails) => {
    return new Promise((resolve, reject) => {
      this.props.getNotificationsConf(entityDetails, (response) => {
        if (response) {
          this.setState({ notifications: response });
          this.props.setOrig({notificationsSettings: response, emailNotifications: this.state.popupUsers, webHookSettings: this.props.webhookForm });
          this.props.setUpdated({notificationsSettings: response, emailNotifications: this.state.popupUsers, webHookSettings: this.props.webhookForm });
          resolve(response);
        } else {
          reject('No response received for notifications configuration.');
        }
      }, (error) => {
        reject('Error fetching notifications configuration: ' + error);
      });
    });
  };

  fetchAndSetWebHookSettings = (entityDetails) => {
    return new Promise((resolve, reject) => {
      this.props.getWebHookSettings(entityDetails, (response) => {
        if (response) {
          this.setState({ webHookSettings: response });
          this.props.setOrig({notificationsSettings: this.state.notifications, emailNotifications: this.state.popupUsers, webHookSettings: response });
          this.props.setUpdated({notificationsSettings: this.state.notifications, emailNotifications: this.state.popupUsers, webHookSettings: response });
          this.props.changeModifyWebhookSettingsForm(response);
          resolve(response);
        } else {
          reject('No response received for webhook settings.');
        }
      }, (error) => {
        reject('Error fetching webhook settings: ' + error);
      });
    });
  };

  fetchAndSetEmailNotificationsConf = (entityDetails) => {
    return new Promise((resolve, reject) => {
      this.props.getEmailNotificationsConf(entityDetails, (response) => {
        if (response) {
          this.setState({ popupUsers: response, users: response });
          this.props.setOrig({notificationsSettings: this.state.notifications, emailNotifications: response, webHookSettings: this.props.webhookForm });
          this.props.setUpdated({notificationsSettings: this.state.notifications, emailNotifications: response, webHookSettings: this.props.webhookForm });
          resolve(response);
        } else {
          reject('No response received for email notifications configuration.');
        }
      }, (error) => {
        reject('Error fetching email notifications configuration: ' + error);
      });
    });
  };

  isSetAsDefaultButtonActive = () => {
    const { appliesTo, loggedInUserPermission, notifications } = this.state;

    if (loggedInUserPermission !== 'account owner') {
      return false
    }

    if (appliesTo === 'organization') {
      return true;
    }

    for (const eventType in notifications){
      for (const setting in notifications[eventType]){
         if (notifications[eventType][setting] === 'varies'){
          return false
        }
      }
    }
    return true;
  }

  convertObjectToArray = (data) => {
    return Object.keys(data).map(key => ({[key]: data[key]}));
  }

  handleSubmit = () => {
    const {
      selectedEntityID,
      notifications,
      popupUsers,
      appliesTo,
    } = this.state;

    const hasNotificationsTableChanged = this.checkIfDataChanged('notificationsSettings');
    const hasEmailNotificationsChanged = this.checkIfDataChanged('emailNotifications');
    const hasWebHookSettingsChanged = this.checkIfDataChanged('webHookSettings');

    const configGenerators = {
      account: (key, value) => ({ account: selectedEntityID, [key]: value }),
      organization: (key, value) => ({ org: selectedEntityID, [key]: value }),
      group: (key, value) => ({ account: this.props.auth.account, group: selectedEntityID, [key]: value }),
    };

    const generateConfig = (key, value) => {
      const generator = appliesTo === 'organization' ? 'organization' : appliesTo === 'account' ? 'account' : 'group';
      return configGenerators[generator](key, value);
    };

    if (hasNotificationsTableChanged) {
      this.props.updateNotificationsConf(generateConfig('rules', notifications));
    }

    if (hasEmailNotificationsChanged) {
      this.props.updateEmailNotificationsConf(generateConfig('emailsSigning', popupUsers));
    }

    if (hasWebHookSettingsChanged) {
      this.props.modifyWebHookSettings(generateConfig('webHookSettings', this.props.webhookForm));
    }

    if (hasNotificationsTableChanged || hasEmailNotificationsChanged || hasWebHookSettingsChanged) {
      this.props.setOrig({
        notificationsSettings: notifications,
        emailNotifications: popupUsers,
        webHookSettings: this.props.webhookForm,
      });
      this.props.setUpdated({
        notificationsSettings: notifications,
        emailNotifications: popupUsers,
        webHookSettings: this.props.webhookForm,
      });
    }

  };

  /**
   * Resets notification settings to default values.
   * @param {string|null} resetTo - Specifies the type of default ("account" or "system"). If null, uses 'tableResetTo' from state.
   */
  handleResetToDefault = (resetTo = null) => {
    const { currentEventName, accountDefault, systemDefault, tableResetTo, notifications, popupUsers } = this.state;
    const { changeModifyNotificationForm, webhookForm, setUpdated } = this.props;

    let updatedNotifications;
    const isSingleEventReset = resetTo !== null;

    // Reset defaults for a single event
    if (isSingleEventReset) {
      updatedNotifications = resetTo === "account"
        ? accountDefault[currentEventName]
        : systemDefault[currentEventName];
      changeModifyNotificationForm(updatedNotifications);
    // Reset defaults for the entire table
    } else {
      updatedNotifications = tableResetTo === "account"
        ? { ...accountDefault }
        : { ...systemDefault };

      if (!isEqual(updatedNotifications, notifications)) {
        setUpdated({
          notificationsSettings: updatedNotifications,
          emailNotifications: popupUsers,
          webHookSettings: webhookForm
        });
        this.setState({
          dataChanged: true,
          notifications: updatedNotifications,
          isResetModalOpen: false,
        });
      } else {
        this.setState({
          isResetModalOpen: false,
        });
      }
    }
  }

  toggleEmailModal = () => {
    this.setState((state) => ({
      isEmailModalOpen: !state.isEmailModalOpen,
      popupUsers: state.users,
    }));
  }

  toggleWebHookModal = () => {
    this.setState((state) => ({
      isWebHookModalOpen: !state.isWebHookModalOpen,
    }));
  }

  cancelEmailsChanges = () => {
    this.setState((state) => ({
      isEmailModalOpen: false,
      popupUsers: state.users,
    }));
  }

  updateEmailsChanges = () => {
    if(!isEqual(this.state.popupUsers, this.state.users)){
      this.setState(
        (state) => ({
          dataChanged: true,
          users: state.popupUsers,
        })
      );
      this.props.setUpdated({notificationsSettings: this.state.notifications, emailNotifications: this.state.popupUsers, webHookSettings: this.props.webhookForm });
    }
    this.setState({
      isEmailModalOpen: false
    })
  }

  handleCheckboxChange = (_, row, changedField) => {
      this.setState({
        popupUsers: this.state.popupUsers.map((user) => {
          if (user._id === row._id) {
            return {
              ...user,
              [changedField]: !user[changedField],
            };
          }
          return user;
        }),
      });
  }

  cancelWebHookChanges = () => {
    this.setState({
      isWebHookModalOpen: false,
    });
    this.props.changeModifyWebhookSettingsForm(this.state.webHookSettings);
  }

  updateWebHookChanges = (values) => {
    if(!isEqual(values, this.state.webHookSettings)){
      this.setState({
        dataChanged: true,
        webHookSettings: values,
      })
      this.props.setUpdated({notificationsSettings: this.state.notifications, emailNotifications: this.state.popupUsers, webHookSettings: values });
    }
    this.setState({
      isWebHookModalOpen: false
    })
  }

  toggleResetModal = (kindOfReset) => {
    this.setState((state) => ({
      isResetModalOpen: !state.isResetModalOpen,
      tableResetTo: kindOfReset,
    }));
  }

  toggleNotificationModal = () => {
    this.setState((prevState) => ({
      isNotificationModalOpen: !prevState.isNotificationModalOpen,
    }));
  }

  openNotificationModal = (row) => {
    const eventName = Object.keys(row)[0];
    this.setState({
      isNotificationModalOpen: true,
      currentEventName: eventName,
    });
    const eventData = row[eventName];
    this.props.changeModifyNotificationForm(eventData);
  }

  updateNotificationChanges = ({ ...notificationSettings }) => {
    const { notifications, currentEventName } = this.state;
    // Initialize a copy of the existing notifications state
    let updatedNotifications = { ...notifications };
    for (let key in notificationSettings) {
      if ( notificationSettings[key] === "true" || notificationSettings[key] === "false") {
        notificationSettings[key] = notificationSettings[key] === "true";
      }
    }
    updatedNotifications[currentEventName] = notificationSettings;

    if(!isEqual(updatedNotifications,notifications)){
      this.props.setUpdated({notificationsSettings: updatedNotifications, emailNotifications: this.state.popupUsers, webHookSettings: this.props.webhookForm });
      this.setState(
        {
          dataChanged: true,
          notifications: updatedNotifications,
        });
    }

    this.toggleNotificationModal()
  }

  createColumn = (
    text,
    dataField,
    sort,
    editable,
    headerStyle,
    formatter,
    hidden = false
  ) => {
    return {
      text: text,
      dataField: dataField,
      sort: sort,
      editable: editable,
      headerStyle: headerStyle,
      formatter: formatter,
      hidden: hidden,
    };
  }

  getValidationObject = (values) => {
    return {
      [this.state.currentEventName]: {
        warningThreshold: values.warningThreshold,
        criticalThreshold: values.criticalThreshold,
      },
    };
  };

  checkIfDataChanged = (specificObjectToCheck = null) => {
    const { isDataChanged, orig, updated } = this.props;
    if (!specificObjectToCheck){
      return (isDataChanged(orig, updated))
    } else {
      return (isDataChanged(orig[specificObjectToCheck], updated[specificObjectToCheck]))
    }
  }

  warnOnDataChange = () => {
    if (this.checkIfDataChanged()) {
      return window.confirm("You have unsaved changes. Are you sure you want to continue?");
    }
    return true;
  };

  handleApplyToChange = async (value, resp) => {
    const { auth } = this.props;
    const response = resp[0];

    // If the user denies to continue, don't proceed with the logic
    if (!this.warnOnDataChange()) {
        return;
    }

    this.setState({
        appliesTo: value,
        entityNameOptions: resp,
        selectedEntityName: response.value,
        selectedEntityID: response.id,
    });

    let configObject = {};
    switch (value) {
      case "account":
        configObject = { account: response.id };
        break;
      case "organization":
        configObject = { org: response.id };
        break;
      case "group":
        configObject = {
          group: response.id,
          account: auth.account,
        };
        break;
      default:
        break;
    }

    const canAccessAllSettings = await this.fetchAndSetAllConfigurations(configObject);
    this.setState({
      isUserPermittedToView: canAccessAllSettings
    });
    if(canAccessAllSettings){
      const shouldShowGroupOrAccountWarning = value === "account" || value === "group"
      this.setState({
        shouldShowWarningUnderEntity: shouldShowGroupOrAccountWarning? true : false
      })
    }
  };

  handleEntityNameChange = async (value) => {
     // If the user denies to continue, don't proceed with the logic
    if (!this.warnOnDataChange()) {
      return
    }
    this.setState({
      selectedEntityID: value,
      selectedEntityName: this.state.entityNameOptions.find(
        (obj) => obj.id === value
      ).value,
    });
    let configObject = {};
    let entityType = this.state.appliesTo;
    switch (entityType) {
      case "account":
        configObject = { account: value };
        break;
      case "organization":
        configObject = { org: value };
        break;
      case "group":
        configObject = {
          group: value,
          account: this.props.auth.account,
        };
        break;
      default:
    }
    const canAccessAllSettings = await this.fetchAndSetAllConfigurations(configObject);
    this.setState({
      isUserPermittedToView: canAccessAllSettings
    });
  };

  render() {
    const toTTString = this.context.toTTString;
    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("Next page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("First page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{ from: from, to: to, size: size }}>
            Showing #from# to #to# of #size# Results
          </TT>
        </span>
      ),
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };

    this.notificationColumns = [
      this.createColumn("rowID", "_id", false, false, null, null, true),
      this.createColumn(
        toTTString("Notification"),
        "notification",
        true,
        false,
        (column, colIndex) => {
          return { width: "22%", textAlign: "left" };
        },
        (cellContent, row) => {
          return <TT>{Object.keys(row)[0]}</TT>;
        }
      ),
      this.createColumn(
        toTTString("Notification Settings"),
        "notificationSettings",
        true,
        false,
        (column, colIndex) => {
          return { width: "95%", textAlign: "left" };
        },
        (cellContent, row) => {
          const notificationKey = Object.keys(row)[0];
          const {
            severity,
            warningThreshold,
            criticalThreshold,
            thresholdUnit,
            immediateEmail,
            resolvedAlert,
            sendWebHook,
          } = row[notificationKey];
          return (
            <div>
              {severity ? (
                <>
                  <strong>
                    <TT>Severity</TT>:
                  </strong>{" "}
                  <TT>{severity}</TT>
                  {" | "}
                </>
              ) : notificationKey === "Temperature" ? (
                <></>
              ) : (
                <>
                  <strong>
                    <TT>Warning threshold</TT>:
                  </strong>{" "}
                  <TT>
                    {`${
                      warningThreshold === "varies"
                        ? "varies"
                        : warningThreshold
                    }${
                      warningThreshold !== "varies" ? thresholdUnit : ""
                    }`}{" "}
                  </TT>{" "}
                  {" | "}
                  <strong>
                    <TT>Critical threshold</TT>:
                  </strong>{" "}
                  <TT>
                    {`${
                      criticalThreshold === "varies"
                        ? "varies"
                        : criticalThreshold
                    }${
                      criticalThreshold !== "varies" ? thresholdUnit : ""
                    }`}{" "}
                  </TT>{" "}
                  {" | "}
                </>
              )}
              <strong>
                <TT>Send email</TT>:
              </strong>{" "}
              <TT>
                {immediateEmail === "varies"
                  ? "varies"
                  : immediateEmail
                  ? "as it happens"
                  : "on daily summary"}
              </TT>
              {" | "}
              <strong>
                <TT>Send webhook</TT>:
              </strong>{" "}
              <TT>
                {sendWebHook === "varies"
                  ? "varies"
                  : sendWebHook
                  ? "Yes"
                  : "No"}
              </TT>
              {resolvedAlert !== null && (
                <>
                  {" | "}
                  <strong>
                    <TT>Notify on resolution</TT>:
                  </strong>{" "}
                  <TT>
                    {resolvedAlert === "varies"
                      ? "varies"
                      : resolvedAlert
                      ? "Yes"
                      : "No"}
                  </TT>
                </>
              )}
            </div>
          );
        }
      ),
      this.state.isUserPermittedToModify &&
        this.createColumn(
          toTTString("Actions"),
          "actions",
          false,
          false,
          (column, colIndex) => {
            return { width: "10%", textAlign: "left" };
          },
          (cellContent, row, rowIndex) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for="modify-settings"
                  size="sm"
                  onClick={() => this.openNotificationModal(row)}
                >
                  <FontAwesomeIcon icon="cog" fixedWidth />
                </Button>
                <ReactTooltip id="modify-settings">
                  <span>
                    <TT>Modify the notification settings</TT>
                  </span>
                </ReactTooltip>
              </div>
            );
          }
        ),
    ];

    const usersEmailsColumns = [
      this.createColumn("rowID", "_id", false, false, null, null, true),
      this.createColumn(
        toTTString("Name"),
        "name",
        true,
        false,
        (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        (cellContent, row) => {
          return <span key={row._id}>{row.name + " " + row.lastName}</span>;
        }
      ),
      this.createColumn(
        toTTString("Email"),
        "email",
        true,
        false,
        (colum, colIndex) => {
          return { width: "40%", textAlign: "left" };
        },
        (cellContent, row) => {
          return <span key={row._id}>{row.email}</span>;
        }
      ),
    ];

    const createCheckboxColumn = (label, propName) => {
      return this.createColumn(
        toTTString(label),
        propName,
        false,
        true,
        (colum, colIndex) => ({ width: "25%", textAlign: "left" }),
        (cellContent, row) => {
          return (
            <span className="checkbox-container">
              <span className="emailCheckbox">
                {renderCheckboxSVG(row[propName] === null ? null : row[propName])}
              </span>
              <input
                className="checkbox-input"
                type="checkbox"
                checked={row[propName]}
                key={row._id}
                data-tip={row[propName] === null ? true : undefined}
                data-for="crossOrgChange"
                onChange={(e) => {
                  this.handleCheckboxChange(e, row, propName);
                }}
              />
            </span>
          );
        }
      );
    };

    const checkboxColumns = [
      createCheckboxColumn("Sign to critical alerts", "signedToCritical"),
      createCheckboxColumn("Sign to warning alerts", "signedToWarning"),
      createCheckboxColumn("Sign to Daily summary", "signedToDaily"),
    ];

    this.usersEmailsColumns = [...usersEmailsColumns, ...checkboxColumns];

    const rowStyle = () => {
      return { backgroundColor: "transparent" };
    };

    const renderSelectField = (label, model, tooltipText, options) => (
      <Row className="form-group">
        <Label htmlFor={model} md={5}>
          <TT>{label}</TT>
          {tooltipText && <span className="helpTooltip" data-tip data-for={model + "Tip"}></span>}
        </Label>
        {tooltipText && (
          <ReactTooltip id={model + "Tip"}>
            <span style={{ fontSize: "0.8rem" }}>
              <TT>{tooltipText}</TT>
            </span>
          </ReactTooltip>
        )}
        <Col md={5}>
          <Control.select
            style={{ border: this.props.form[model] === "varies" ? "1px solid orange" : "" }}
            model={"." + model}
            data-tip={this.props.form[model] === "varies" ? true : undefined}
            data-for="crossOrgChange"
            id={model}
            name={model}
            className="form-control"
          >
            {this.props.form[model] === "varies" && <option value={"varies"}>{toTTString("varies")}</option>}
            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {toTTString(option.label)}
              </option>
            ))}
          </Control.select>
        </Col>
      </Row>
    );

    const renderTextInputField = (label, model, placeholder, validators = {}) => (
      <Row className="form-group">
        <Label htmlFor={model} md={5}>
          <TT>{label}</TT>
        </Label>
        <Col md={5}>
          <Control.text
            style={{
              border: this.props.form[model] === "varies" ? "1px solid orange" : "",
            }}
            model={"." + model}
            id={model}
            name={model}
            placeholder={toTTString(placeholder)}
            component={FWTextInput}
            withFieldValue
            validators={validators}
            data-tip={this.props.form[model] === "varies" ? true : undefined}
            data-for="crossOrgChange"
          />
          <Errors
            className="text-danger"
            model={"." + model}
            messages={{
              required: toTTString("Required field"),
              isNumber: toTTString("Enter a number"),
              range: () => {
                const validationResult = validateNotificationField(
                  this.props.form[model],
                  this.props.form.thresholdUnit
                );
                return toTTString(validationResult.message);
              }
            }}
          />
          <Errors
            className="text-danger"
            model="."
            messages={{
              validateNotificationsForm: () => {
                const validationResult = validateNotificationsForm(this.getValidationObject(this.props.form));
                const error = validationResult.errors[0]; // Assuming there's only one eventType.
                return error ? toTTString(error.message) : "";
              }
            }}
          />
        </Col>
        <span className="mt-2">{this.props.form.thresholdUnit}</span>
      </Row>
    );

    const renderCheckboxSVG = (property) => (
      property === null ? (
        <svg viewBox="0 0 14 14" focusable="false">
          <rect className="indeterminate" fill="#f3ac06" stroke="grey" x="0.5" y="0.5" rx="1.5" ry="1.5" width="13" height="13"></rect>
          <polyline stroke="#fff" strokeWidth="2" fill="none" points="2.5,7 11.5,7"></polyline>
        </svg>
      ) : property ? (
        <svg viewBox="0 0 14 14" focusable="false">
          <rect className="checked" fill="#1966ff" stroke="#0073bb" x="0.5" y="0.5" rx="1.5" ry="1.5" width="13" height="13"></rect>
          <polyline stroke="#fff" strokeWidth="2" fill="none" points="2.5,7 6,10 11,3"></polyline>
        </svg>
      ) : (
        <svg viewBox="0 0 14 14">
          <rect className="unchecked" fill="#fff" stroke="grey" strokeWidth="1" x="0.5" y="0.5" rx="1.5" ry="1.5" width="13" height="13"></rect>
        </svg>
      )
    );

    const CustomCheckbox = ({ label, property, model, id, name, dataTip, dataFor }) => (
      <Row className="form-group">
        <Label htmlFor={id} md={5}>
          <TT>{label}</TT>
          {":"}
        </Label>
        <Col className="form-field mt-2" md={7}>
          <span style={{ position: "relative" }} type="text">
            <span
              className="emailCheckbox"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
              }}
              data-tip={dataTip}
              data-for={dataFor}
            >
              {renderCheckboxSVG(property)}
            </span>
            <Control.checkbox
              model={model}
              id={id}
              name={name}
              withFieldValue
              style={{ opacity: 0 }}
              type="checkbox"
              checked={property}
              disabled={!this.state.isUserPermittedToModify}
            />
          </span>
        </Col>
      </Row>
    );

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home">
              <TT>Home</TT>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <TT>Account</TT>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <TT>Configure notifications</TT>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="col-md-11 notificationsConf">
          <Row>
            <Col md={3} style= {{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '10px'}}>
              <div style = {{ justifyContent: 'space-between', display: 'flex', gap: '30px', alignItems: 'center'}}>
                <Label htmlFor="applyTo" className="w-50">
                  <TT>Applies to</TT>
                <span
                  className="helpTooltip"
                  data-tip
                  data-for="applyNotificationsTip"
                  style={{ display: 'inline-block'}}
                ></span>
                <ReactTooltip id="applyNotificationsTip">
                  <span style={{ fontSize: "0.8rem" }}>
                    <TT>The resource level that you want to configure its notifications</TT>
                  </span>
                </ReactTooltip>
                </Label>
                  <Control.select
                    model=".applyTo"
                    id="applyTo"
                    name="applyTo"
                    className="form-control no-border-on-click"
                    onChange={(event) => {
                      this.props.getMemberOptions(event.target.value, (resp) => {
                        this.handleApplyToChange(event.target.value,resp)
                      });
                    }}
                    style = {{ maxWidth: '60%'}}
                  >
                  <option value="organization" key="org">
                    {toTTString("Organization")}
                  </option>
                  <option value="account" key="account">
                    {toTTString("Account")}
                  </option>
                  <option value="group" key="group">
                    {toTTString("Group")}
                  </option>
                </Control.select>
              </div>
              <div style = {{ justifyContent: 'space-between', display: 'flex', gap: '30px', alignItems: 'center'}}>
                <Label htmlFor="entityName" className="w-50">
                  <TT>Entity</TT>
                  <span
                    className="helpTooltip"
                    data-tip
                    data-for="entityNameTip"
                    style={{ display: 'inline-block' }}
                  ></span>
                  <ReactTooltip id="entityNameTip">
                    <span style={{ fontSize: "0.8rem" }}>
                      <TT>The name of the</TT> <TT>{this.state.appliesTo}</TT>
                    </span>
                  </ReactTooltip>
                </Label>
                <Control.select
                  model=".entityName"
                  id="entityName"
                  name="entityName"
                  className="form-control no-border-on-click"
                  value={this.state.selectedEntityID}
                  onChange={(event) => {
                    this.handleEntityNameChange(event.target.value)
                  }}
                  style = {{ maxWidth: '60%'}}
                >
                  {this.state.entityNameOptions.map((res) => (
                    <option key={res.id} value={res.id} data-key={res.id}>
                      {res.value}
                    </option>
                  ))}
                </Control.select>
              </div>
              {(this.state.shouldShowWarningUnderEntity) && (
                <Row className="ml-1 mb-2" style={{ width: '100vw'}}>
                  <strong>
                    {"* "}
                    <TT>
                      Applying the changes on this page will update the settings for
                      all organizations under the
                    </TT>{" "}
                    {toTTString(this.state.appliesTo)} :{" "}
                    {this.state.selectedEntityName}
                  </strong>
                </Row>
              )}
              {this.state.isUserPermittedToView &&
                <div style = {{ justifyContent: 'space-between', display: 'flex', gap: '5px'}}>
                  <Control.button
                    form={"configureNotifications"}
                    model=".manageEmails"
                    disabled={{ valid: false }}
                    className="btn btn-primary no-border-on-click"
                    size="sm"
                    onClick={() => this.toggleEmailModal()}
                  >
                    <TT>Email notifications</TT>
                  </Control.button>
                  <Control.button
                    form={"configureNotifications"}
                    model=".manageEmails"
                    disabled={{ valid: false }}
                    className="btn btn-primary no-border-on-click"
                    size="sm"
                    onClick={() => this.toggleWebHookModal()}
                  >
                    <TT>Webhook notifications</TT>
                  </Control.button>
                </div>
              }
            </Col>
          </Row>
        {this.state.isUserPermittedToView &&
         <>
        {this.state.notifications && (
          <BootstrapTable
            keyField="_id"
            data={this.convertObjectToArray(this.state.notifications)}
            columns={this.notificationColumns}
            rowStyle={rowStyle}
          />
        )}
        <div style={{ float: "left" }}>
          <Control.button
            style={{ width: "90px" }}
            form={"configureNotifications"}
            type="submit"
            model=".applySettings"
            disabled={!this.state.dataChanged || { valid: false }}
            className={
              this.state.dataChanged
                ? "btn btn-primary no-border-on-click notifications-buttons"
                : "btn grayed-out-btn no-border-on-click notifications-buttons"
            }
            size="sm"
            onClick={this.handleSubmit}
          >
            <TT>Apply</TT>
          </Control.button>
        </div>
        {this.state.isUserPermittedToModify && (
          <>
            <div style={{ float: "right", display: "flex" }}>
              {this.isSetAsDefaultButtonActive() && (
                <>
                  <Control.button
                    data-tip
                    data-for="set-as-account-default"
                    form={"configureNotifications"}
                    type="submit"
                    model=".setAsAccountDefault"
                    className="btn btn-primary notifications-buttons"
                    size="sm"
                    onClick={() => {
                      this.props.updateDefaultNotificationsConf({
                        account: this.props.auth.account,
                        rules: this.state.notifications,
                      });
                      this.setState({
                        accountDefault: this.state.notifications,
                      });
                    }}
                  >
                    <TT>Set as account default</TT>
                  </Control.button>
                  <ReactTooltip id="set-as-account-default">
                    <span>
                      <TT>
                        New organizations will automatically receive these
                        settings
                      </TT>
                    </span>
                  </ReactTooltip>
                </>
              )}
              <div className={"ml-3"} style={{ width: "180px" }}>
                <Control.select
                  data-tip
                  data-for="reset-to-default"
                  model=".tableResetTo"
                  id="tableResetTo"
                  name="tableResetTo"
                  className="form-control custom-outline-danger no-border-on-click notifications-buttons"
                  onChange={(event) => {
                    this.toggleResetModal(event.target.value);
                  }}
                >
                  <option value="" hidden>
                    {toTTString("Reset")}
                  </option>
                  <option value="account" key="resetToAccount">
                    {toTTString("Reset to account defaults")}
                  </option>
                  <option value="factory" key="resetToFactory">
                    {toTTString("Reset to system defaults")}
                  </option>
                </Control.select>
                <ReactTooltip id="reset-to-default">
                  <span>
                    <TT>
                      Reset the table settings to the account/system defaults
                    </TT>
                  </span>
                </ReactTooltip>
              </div>
            </div>
          </>
        )}
        </>}
          <Modal
            isOpen={this.state.isNotificationModalOpen}
            toggle={this.toggleNotificationModal}
          >
            {this.state.currentEventName && (
              <Form
                model="modifyNotificationForm"
                onSubmit={(values) => this.updateNotificationChanges(values)}
                validators={{
                  "": {
                    validateNotificationsForm: (values) => {
                      if (values.criticalThreshold && values.warningThreshold && values.criticalThreshold!== 'varies' && values.warningThreshold !== 'varies') {
                        const objectToValidate = this.getValidationObject(values);
                        return validateNotificationsForm(objectToValidate).valid;
                      }
                      return true;
                    },
                  },
                }}
              >
                <ModalHeader toggle={this.toggleNotificationModal}>
                  {this.state.currentEventName ? (
                    <strong>
                      <TT>{this.state.currentEventName}</TT>
                    </strong>
                  ) : (
                    ""
                  )}
                </ModalHeader>
                <ModalBody>
                  {this.state.currentEventName === "Temperature" && (
                    <TT>
                      * The warning and critical temperature thresholds are
                      taken from the device temperature
                    </TT>
                  )}
                  {!this.props.form.severity &&
                    this.state.currentEventName !== "Temperature" && (
                      <>
                        {renderTextInputField(
                          "Warning threshold",
                          "warningThreshold",
                          "Enter a number",
                          this.props.form.warningThreshold !== "varies"
                            ? {
                                required,
                                isNumber,
                                range: (val) => validateNotificationField(val, this.props.form.thresholdUnit).valid
                              }
                            : null
                        )}
                        {renderTextInputField(
                          "Critical threshold",
                          "criticalThreshold",
                          "Enter a number",
                          this.props.form.criticalThreshold !== "varies"
                            ? {
                                required,
                                isNumber,
                                range: (val) => validateNotificationField(val, this.props.form.thresholdUnit).valid
                              }
                            : null
                        )}
                      </>
                    )}
                  {this.state.currentEventName !== "Temperature" &&
                    this.props.form.severity && (
                      <>
                      {renderSelectField("Severity", "severity", "", [
                        { value: "critical", label:"critical"},
                        { value: "warning", label:"warning"}
                      ])}
                    </>
                    )}
                     {renderSelectField("Email alerts", "immediateEmail", "Email alerts for registered users", [
                        { value: "true", label: "as it happens" },
                        { value: "false", label: "on daily summary" }
                      ])}
                     {renderSelectField("Send webhook", "sendWebHook", "Send an alert to the organization webhook", [
                        { value: "true", label: "Yes" },
                        { value: "false", label: "No" }
                      ])}
                  {this.props.form.resolvedAlert !== null && (
                    <>
                       {renderSelectField("Notify on resolution", "resolvedAlert", "Notify when resolved", [
                          { value: "true", label: "Yes" },
                          { value: "false", label: "No" }
                        ])}
                    </>
                  )}
                  <div style={{ marginBottom: "60px", marginTop: "30px" }}>
                    <div style={{ float: "right", width: "90px" }}>
                      <select
                        data-tip
                        data-for="reset"
                        className="form-control custom-outline-danger no-border-on-click"
                        onChange={(event) => {
                          this.handleResetToDefault(event.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {toTTString("Reset")}
                        </option>
                        <option value="account" key="resetToAccount">
                          {toTTString("Reset to account defaults")}
                        </option>
                        <option value="factory" key="resetToFactory">
                          {toTTString("Reset to system defaults")}
                        </option>
                      </select>
                      <ReactTooltip id="reset">
                        <span>
                          <TT>
                            Reset the settings to the account/system defaults
                          </TT>
                        </span>
                      </ReactTooltip>
                    </div>
                    <div style={{ float: "left" }}>
                      <Button
                        style={{ width: "90px" }}
                        className="float-right no-border-on-click"
                        color="black"
                        onClick={() => this.toggleNotificationModal()}
                        type="button"
                      >
                        <TT>Cancel</TT>
                      </Button>
                      <Control.button
                        model="modifyNotificationForm"
                        style={{ width: "90px" }}
                        className="float-left btn btn-primary no-border-on-click"
                        color="outline-secondary"
                        disabled={{ valid: false }}
                        type="submit"
                      >
                        <TT>OK</TT>
                      </Control.button>
                    </div>
                  </div>
                  <ReactTooltip id="crossOrgChange">
                    <span>
                      <TT>
                        The value of this field differs across organizations,
                        and modifying it will update the value for all of them
                      </TT>
                    </span>
                  </ReactTooltip>
                </ModalBody>
              </Form>
            )}
          </Modal>
          <Modal
            size="lg"
            style={{ maxWidth: "800px", width: "100%" }}
            isOpen={this.state.isEmailModalOpen}
            toggle={this.toggleEmailModal}
          >
            <ModalHeader toggle={this.toggleEmailModal}>
              <strong>
                <TT>Manage Notifications Emails</TT>
              </strong>
            </ModalHeader>
            <ModalBody>
              <div>
                <TT>
                  Use the table below to define the types of emails the user
                  will receive
                </TT>
                .<br />
                <strong>
                  <TT>
                    The table includes only users that are authorized to each organization in the
                  </TT>{" "}
                  <TT>{this.state.appliesTo}</TT>
                  {" " + this.state.selectedEntityName}.
                </strong>
              </div>
              <div style={{ marginTop: "20px" }}>
                {this.state.popupUsers && (
                  <BootstrapTable
                    className="custom-table-class"
                    keyField="rowKey"
                    data={this.state.popupUsers}
                    columns={this.usersEmailsColumns}
                    rowStyle={rowStyle}
                    pagination={paginationFactory(paginationOptions)}
                  />
                )}
                 <ReactTooltip id="crossOrgChange">
                      <span>
                        <TT>
                          The value of this field differs across organizations,
                          and modifying it will update the value for all of them
                        </TT>
                      </span>
                  </ReactTooltip>
              </div>
                <Button
                  className="float-right no-border-on-click"
                  color="outline-secondary"
                  onClick={() => this.cancelEmailsChanges()}
                >
                  <TT>Cancel</TT>
                </Button>
                <Button
                  className="float-left btn btn-primary"
                  color="outline-secondary"
                  onClick={() => this.updateEmailsChanges()}
                  style={{ width: "70px" }}
                >
                  <TT>OK</TT>
                </Button>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.isWebHookModalOpen}
            toggle={this.toggleWebHookModal}
          >
            <Form
              model="modifyWebhookSettingsForm"
              onSubmit={(values) => this.updateWebHookChanges(values)}
            >
              <ModalHeader toggle={this.toggleWebHookModal}>
                <strong>
                  <TT>Manage webhooks notifications</TT>
                </strong>
              </ModalHeader>
              <ModalBody>
                <div>
                  <Row className="form-group">
                    <Label htmlFor="webhookURL" md={5}>
                      <TT>Webhook URL</TT>:
                    </Label>
                    <Col className="form-field" md={7}>
                      <Control.text
                        style={{
                          border:
                            this.props.webhookForm.webhookURL === null
                              ? "1px solid orange"
                              : "",
                        }}
                        model=".webhookURL"
                        id="webhookURL"
                        name="webhookURL"
                        placeholder={toTTString("Enter a URL")}
                        component={FWTextInput}
                        withFieldValue
                        validators={
                          this.props.webhookForm.webhookURL !== null
                            ? {
                                required: required,
                                isValidURL: (value) => {
                                  if(value){
                                    return isValidURL(value)
                                  }
                                  return true
                                },
                                secured: (value) => {
                                  if(value){
                                    return value.startsWith("https://");
                                  }
                                  return true
                                },
                                lengthLimit: (value) => value.length <= 256
                              }
                            : null
                        }
                        data-tip={
                          this.props.webhookForm.webhookURL === null
                            ? true
                            : undefined
                        }
                        data-for="crossOrgChange"
                      />
                      <Errors
                        className="text-danger"
                        model=".webhookURL"
                        show="touched"
                        messages={
                          this.props.webhookForm.webhookURL !== null
                            ? {
                                required: toTTString("Required field"),
                                isValidURL: toTTString("Please enter a valid URL"),
                                secured: toTTString("Please use only an HTTPS address"),
                                lengthLimit: toTTString("The URL is too long")
                              }
                            : {}
                        }
                      />
                    </Col>
                  </Row>
                  <CustomCheckbox
                    label="Send critical alerts"
                    property={this.props.webhookForm.sendCriticalAlerts}
                    model=".sendCriticalAlerts"
                    id="sendCriticalAlerts"
                    name="sendCriticalAlerts"
                    dataTip={this.props.webhookForm.sendCriticalAlerts === null ? true : undefined}
                    dataFor="crossOrgChange"
                  />

                  <CustomCheckbox
                    label="Send warning alerts"
                    property={this.props.webhookForm.sendWarningAlerts}
                    model=".sendWarningAlerts"
                    id="sendWarningAlerts"
                    name="sendWarningAlerts"
                    dataTip={this.props.webhookForm.sendWarningAlerts === null ? true : undefined}
                    dataFor="crossOrgChange"
                  />
                </div>
                {this.state.isUserPermittedToModify && (
                  <div style={{ marginBottom: "50px" }}>
                    <Button
                      className="float-right no-border-on-click"
                      color="outline-secondary"
                      onClick={() => this.cancelWebHookChanges()}
                      type="button"
                    >
                      <TT>Cancel</TT>
                    </Button>
                    <Control.button
                      model="modifyWebhookSettingsForm"
                      className="float-left btn btn-primary no-border-on-click"
                      color="outline-secondary"
                      disabled={{ valid: false }}
                      type="submit"
                      style={{ width: "70px" }}
                    >
                      <TT>OK</TT>
                    </Control.button>
                  </div>
                 )}
                <ReactTooltip id="crossOrgChange">
                  <span>
                    <TT>
                      The value of this field differs across organizations,
                      and modifying it will update the value for all of them
                    </TT>
                  </span>
                </ReactTooltip>
              </ModalBody>
            </Form>
          </Modal>

          {
            <Modal
              isOpen={this.state.isResetModalOpen}
              toggle={this.toggleResetModal}
            >
              <ModalHeader toggle={this.toggleResetModal}>
                <strong>
                  <TT>Reset to {this.state.tableResetTo} defaults </TT>
                </strong>
              </ModalHeader>
              <ModalBody>
                <span>
                  <TT>
                    You are about to reset the settings to the{" "}
                    {this.state.tableResetTo} defaults.
                  </TT>
                  <br />
                  <TT> Are you sure?</TT>
                </span>
                <div style={{ marginTop: "10px" }}>
                  <Button
                    className="float-right"
                    color="outline-secondary"
                    onClick={this.toggleResetModal}
                  >
                    <TT>Cancel</TT>
                  </Button>
                  <Button
                    className="float-left btn btn-primary"
                    color="outline-secondary"
                    onClick={() => this.handleResetToDefault()}
                  >
                    <TT>Yes</TT>
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.modifyNotificationForm,
    webhookForm: state.modifyWebhookSettingsForm
  };
};

export default withSaveBeforeLeaving(
  connect(mapStateToProps)(NotificationsConf)
);