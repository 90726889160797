import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLoginAuthMethods, setLoginProcessScreen } from '../../../redux/reducers/Auth';
import Loading from '../../items/Loading';
import BottomCard from "./BottomCard";


const AuthMethods = props => {
  const [methods, setMethods] = useState({})
  const [loading, setLoading] = useState(true)

  const { getLoginAuthMethods } = props;

  const getAuthMethods = useCallback(async () => {
    getLoginAuthMethods(data => {
      setLoading(false);
      setMethods(data.methods)
    });
  }, [getLoginAuthMethods]);

  useEffect(() => {
    getAuthMethods()
  }, [getAuthMethods])

  if (loading) {
    return <Loading size="3x" />;
  }

  let comp = null;
  if (Object.values(methods ?? {}).every(v => v === 0)) {
    comp = (
      <>
        No authentication method configured. Please contact support
      </>
    )
  } else {
    comp = (
      <>
        <Row className="mb-2">
          <Col md={12}>Recovery options</Col>
        </Row>
        <Row>
          <Col md={12}>
            <ListGroup>
              { methods?.recoveryCodes ? (
                <ListGroupItem onClick={() => props.setLoginScreen(3)} className="auth-method-option">
                  <div className="mr-2">
                    <FontAwesomeIcon icon="key" fixedWidth />
                  </div>
                  <div>Use your Recovery Codes</div>
                </ListGroupItem>
              ) : null}

              { methods?.authenticatorApp ? (
                <ListGroupItem onClick={() => props.setLoginScreen(1)} className="auth-method-option">
                  <div className="mr-2">
                    <FontAwesomeIcon icon="qrcode" fixedWidth />
                  </div>
                  <div>Use Authenticator application</div>
                </ListGroupItem>
              ) : null}
            </ListGroup>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      {comp}

      <BottomCard showContactUs={true} showTryAnotherMethod={false}/>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getLoginAuthMethods: cb => dispatch(getLoginAuthMethods(cb)),
    setLoginScreen: screenNum => dispatch(setLoginProcessScreen(screenNum)),
  }
}

export default connect(null, mapDispatchToProps)(AuthMethods);
