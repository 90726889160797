const getLocalSettings = () => {
  let settings = {};
  const settingsStr = localStorage.getItem('settings');
  if (settingsStr) {
    try {
      settings = JSON.parse(settingsStr);
    } catch(e) {
      console.error('Failed to parse local settings');
    }
  }
  return settings;
}
  
export const saveLocalSettings = (page, values) => {
  const settings = getLocalSettings();
  settings[page] = values;
  localStorage.setItem('settings', JSON.stringify(settings));
}
  
export const loadLocalSettings = (page) => {
  return getLocalSettings()[page] || {};
}

export const clearLocalSettings = () => {
  const devicesCardSettings = loadLocalSettings('device-interfaces-card');
  localStorage.setItem('settings', '');
  // keep settings with interfaces page columns order
  saveLocalSettings('device-interfaces-card', devicesCardSettings)
}
