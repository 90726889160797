import React, {Component} from 'react';
import { Alert } from 'reactstrap';
import { TT, LanguageContext  } from '../containers/Language'

class AlertMsg extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.props.alertMsgMessage({'color':null,'text':null});
  }

  render() {
    return (
      <div className='centerAbs'>
        <Alert color={this.props.alertmsg.msg.color} isOpen={this.props.alertmsg.isOpen} toggle={this.onDismiss}>
          <TT convertValues={true} splitSentence={true}>{this.props.alertmsg.msg.text}</TT>
        </Alert>
      </div>
    );
  }
}

export default AlertMsg;