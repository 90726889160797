import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Portals = (
  state = {
    portal: null,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.CUSTOMER_PORTAL_OPEN_REQUEST:
      return { ...state, isLoading: true, portal: null };
    case Actions.CUSTOMER_PORTAL_OPEN_SUCCESS:
        return { ...state, portal: action.portal, isLoading: false };
    case Actions.CUSTOMER_PORTAL_OPEN_FAILURE:
      return { ...state, portal: null, isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Open Portal
 **********************************************************************/
export const requestOpenPortal = () => dispatch => {
  dispatch({
    type: Actions.CUSTOMER_PORTAL_OPEN_REQUEST
  });
};

export const receiveOpenPortal = response => dispatch => {
  dispatch({
    type: Actions.CUSTOMER_PORTAL_OPEN_SUCCESS,
    portal: response
  });
};

export const failureOpenPortal = error => dispatch => {
  dispatch({
    type: Actions.CUSTOMER_PORTAL_OPEN_FAILURE
  });
};

export const openPortal = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "portals",
      method: "GET",
      requestCB: () => {
        dispatch(requestOpenPortal());
      },
      successCB: response => {
        dispatch(receiveOpenPortal(response));
      },
      failureCB: error => {
        dispatch(failureOpenPortal(error));
      }
    })
  );
};
