import React, { useContext, useState } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';

import { languageOptions } from '../languages';
import { LanguageContext } from '../containers/Language';

export default function LanguageSelector() {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const { userLanguageChange, userLanguage } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = e => userLanguageChange(e.target.value);

    return (
      <Dropdown
        right="true"
        nav
        inNavbar
        isOpen={dropdownOpen}
        className="mr-0"
        toggle={toggle} >
        <DropdownToggle nav caret>
          <div className="topbar-language navbar-text">
            <div style={{'marginTop': '10px'}}>
              {languageOptions[userLanguage]}
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu right className="header-account-menu" onClick={handleLanguageChange}>
        <DropdownItem header>Choose Language:</DropdownItem>
        <DropdownItem key={0} divider />
          {Object.entries(languageOptions).map(([id, name]) => (
            <DropdownItem key={id} value={id} style={{'marginLeft' : '30px'}}>
              {/* <img src={require('../assets/images/languages_Icons/' + id + '.png').default} height="20" width="20" alt="English" />  */}
              {name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
};
