import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Health = (
  state = {
    health: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    // Get health
    case Actions.HEALTH_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.HEALTH_GET_SUCCESS:
      return { ...state, health: action.health, isLoading: false };
    case Actions.HEALTH_GET_FAILURE:
      return { ...state, health: [], isLoading: false };
    default:
      return state;
  }
};

/**********************************************************************
 *   Get health
 **********************************************************************/
export const requestGetHealth = () => dispatch => {
  dispatch({
    type: Actions.HEALTH_GET_REQUEST
  });
};

export const receiveGetHealth = response => dispatch => {
  dispatch({
    type: Actions.HEALTH_GET_SUCCESS,
    health: response
  });
};

export const failureGetHealth = error => dispatch => {
  dispatch({
    type: Actions.HEALTH_GET_FAILURE
  });
};

// If deviceId == null, get all devices
export const getHealth = (deviceId, success_cb) => dispatch => {
  const startTime = Math.round((new Date()).getTime() / 1000) - 7200;  // get two hours backward
  return dispatch(
    FWFetch({
      url: baseUrl + "devices" + (deviceId ? "/" + deviceId : "") + "/health"
      + "?startTime=" + startTime,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetHealth());
      },
      successCB: response => {
        dispatch(receiveGetHealth(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureGetHealth(error));
      }
    })
  );
};

