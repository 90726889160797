import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

export default function FwCollapse(props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const toggle = () => {
    if (typeof props.changed === 'function') {
      props.changed(!isOpen);
    }
    setIsOpen(!isOpen);
  }
  const buttonColor = props.color || 'primary';
  const level = props.level ? `${props.level / 2}rem` : "0rem";
  let cardStyle = {
    marginLeft: level
  };
  if(props.noborder) {
    cardStyle.border = "none"
  };

  return (
    <>
      <div className='d-flex align-items-center mb-2' style={{marginLeft: level}}>
        <Button color={buttonColor} onClick={toggle} className="text-left d-flex justify-content-between" style={{'minWidth': '180px'}}>
          {props.title}
          <FontAwesomeIcon icon={isOpen ? "caret-up" : "caret-down"} style={{height:"1.15em"}} fixedWidth />
        </Button>

        { props.tip ? (
          <>
            <span className="ml-2 helpTooltip" data-tip data-for={props.title}></span>
            <ReactTooltip id={props.title}>
              <span>{props.tip}</span>
            </ReactTooltip>
          </>
        ) : null}
      </div>

      <Collapse isOpen={isOpen}>
        { props.noCard ? (
          <>{props.children}</>
        ) : (
          <Card style={cardStyle}>
            <CardBody>
              {props.children}
            </CardBody>
          </Card>
        )}
      </Collapse>
    </>
  )
};
