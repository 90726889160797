import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TT, LanguageContext  } from '../../containers/Language'

class AccessTokens extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    console.log("AccessTokens componentDidMount");

    this.props.getAllAccessTokens();
    if (this.statusTimer == null) {
      console.log("AccessTokens: Setting periodic timer");
      const timer = setInterval(function (that) {
        console.log("AccessTokens periodic update");
        // Only update when in focus
        if (document.hasFocus()) {
          that.props.getAllAccessTokens();
        }
      }, 60000, this);
      this.statusTimer = timer;
    }
  }
  componentWillUnmount() {
    console.log("AccessTokens componentWillUnmount");
    if (this.statusTimer != null) {
      console.log("AccessTokens: Clearing periodic timer");
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  constructor(props) {
    super(props);

    this.statusTimer = null;

    this.state = {
      isDelModalOpen: false,
      delEntity: null,
      copySuccess: false
    };

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen
    });
  }

  handleDelete(id) {
    console.log("handleDelete: " + id);
    this.setState({ delEntity: {id} });
    this.toggleDelModal();
  }
  approveDelete() {
    console.log("Delete clicked for accesstokenID: " + this.state.delEntity.id);
    this.toggleDelModal();
    this.props.delAccessToken(this.state.delEntity, this.props.getAllAccessTokens);
  }

  showToast = () => {
    const toTTString = this.context.toTTString
    toast(toTTString("Access Key Copied!"), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  handleOnSelect = (row, isSelect) => {
  }

  handleOnSelectAll = (isSelect, rows) => {
  }

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (this.props.auth.userId && row.user__id === this.props.auth.userId)
      style.backgroundColor = '#fff3c7';
    return style;
  };

  render() {
    const toTTString = this.context.toTTString
    const columns = [
      { text: toTTString("rowID"), dataField: "_id", hidden: true },
      {
        text: toTTString('Name'), dataField: 'name', sort: true,
        headerStyle: (colum, colIndex) => {
          return { minWidth: '120px', textAlign: 'left' };
        }
      },
      {
        text: toTTString('Key'), dataField: 'token', sort: false,
        headerStyle: (colum, colIndex) => {
          return { textAlign: 'left' };
        },
        formatter: (_, row) => {
          return (
            <div style={{width:'max(500px, calc(100vw - 1050px))'}}>
              {row.token}
            </div>
          );
        }
      },
      {
        text: toTTString('To'), dataField: 'to', sort: true,
        headerStyle: (colum, colIndex) => {
          return { minWidth: '100px', textAlign: 'left' };
        },
        style: { 'textTransform': 'capitalize' },
      },
      {
        text: toTTString('Entity'), dataField: 'group', sort: true,
        headerStyle: (colum, colIndex) => {
          return { minWidth: '100px', textAlign: 'left' };
        },
        style: { 'textTransform': 'capitalize' },
        formatter: (_, row) => {
          return row.to === 'organization'? row.organization : row.to === 'group'? row.group : 
          this.props.auth.accountName;
        }
      },
      {
        text: toTTString('Role'), dataField: 'role', sort: true,
        headerStyle: (colum, colIndex) => {
          return { minWidth: '100px', textAlign: 'left' };
        },
        style: { 'textTransform': 'capitalize' },
      },
      {
        text: toTTString('Status'), dataField: 'isValid', sort: true,
        headerStyle: (colum, colIndex) => {
          return { minWidth: '100px', textAlign: 'left' };
        },
        style: { 'textTransform': 'capitalize' },
        formatter: (_, row) => {
          return row.isValid ? toTTString("Active") : toTTString("Revoked");
        }
      },
      {
        text: toTTString('Actions'), dataField: 'none', sort: false,
        headerStyle: (colum, colIndex) => {
          return { minWidth: '100px', textAlign: 'left' };
        },
        formatter: (_, row) => {
          return (<div>
            <CopyToClipboard text={row.token}
              onCopy={() => this.showToast()}>
              <Button color="primary" className="action-btn" data-tip data-for='copy-a' size="sm">
                <FontAwesomeIcon icon="clone" fixedWidth />
              </Button>
            </CopyToClipboard>
            <Button color="danger" className="action-btn" data-tip data-for='delete-a' size="sm"
              onClick={() => this.handleDelete(row._id)}>
              <FontAwesomeIcon icon="trash-alt" fixedWidth />
            </Button>
            <ReactTooltip id='delete-a'><span><TT>Delete Access Key</TT></span></ReactTooltip>
            <ReactTooltip id='copy-a'><span><TT>Copy To Clipboard</TT></span></ReactTooltip>
            <ToastContainer />
          </div>);
        }
      }
    ];

    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
        <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                    </span>),
      sizePerPageList: [{ text: '10', value: 10 }, { text: '50', value: 50 }, { text: '100', value: 100 }]
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: false,
      selected: [],
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem><TT>Accounts</TT></BreadcrumbItem>
          <BreadcrumbItem active><Link to="/accesstokens"><TT>Access Keys</TT></Link></BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
          <h4><TT>Access Keys</TT></h4>
          {this.props.accesstokens.isLoading ? <div className="signal"></div> : null}
        </div>
        <div className="col-md-12">
          <div className="mb-3">
          <ReactTooltip id='refresh-a'><span><TT>Refresh</TT></span></ReactTooltip>
          <Button color="info" className="refresh-btn" data-tip data-for='refresh-a' size="sm"
            onClick={this.props.getAllAccessTokens}>
            <FontAwesomeIcon icon="sync-alt" />
          </Button>
          <Link to="/accesstokens/add">
            <Button color="success" className="action-btn-top" size="sm"><TT>Add Access Key</TT></Button>
          </Link>
          </div>
          <BootstrapTable striped hover condensed
            wrapperClasses={'scroll-x'}
            keyField='_id'
            data={this.props.accesstokens.accesstokens}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={toTTString("No data available")}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            selectRow={ selectRow }
            />
        </div>
        <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
          <ModalHeader toggle={this.toggleDelModal}><TT>Delete Access Key</TT></ModalHeader>
          <ModalBody>
            <div className="mb-3"><TT>Are you sure to delete access key?</TT><br/><TT>Access permissions will be revoked</TT>.</div>
            <Button color="danger" onClick={this.approveDelete}><TT>Yes</TT></Button>
            <Button className="float-right" color="outline-secondary" onClick={this.toggleDelModal}><TT>Cancel</TT></Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
export default AccessTokens;