import React, { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import StructuredFilter from './structured-filter/lib/main';
import { TT, LanguageContext } from '../../containers/Language'
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FwBootstrapTable = props => {
  const { toTTString } = useContext(LanguageContext);
  const [filters, setFilters] = useState([]);

  const paginationOptions = {
    paginationSize: 5,
    alwaysShowAllBtns: true,
    pageStartIndex: 0,
    firstPageText: toTTString("First"),
    prePageText: toTTString("Back"),
    nextPageText: toTTString("Next"),
    lastPageText: toTTString("Last"),
    nextPageTitle: toTTString("First page"),
    prePageTitle: toTTString("Pre page"),
    firstPageTitle: toTTString("Next page"),
    lastPageTitle: toTTString("Last page"),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
                                                    <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                  </span>),
    sizePerPageList: [{text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}]
  };

  const updateFilters = filters => {
   setFilters(filters);
  }

  const getFilteredData = routes => {
    const filterComparator = (val) => {
      let matched = true;
      for (const filter of filters) {
        switch (filter.operator) {
          case '==':
            matched = val[filter.categorykey].toString() === filter.value;
            break
          case '!=':
            matched = val[filter.categorykey].toString() !== filter.value;
            break
          case 'contains':
              matched = val[filter.categorykey].toString().includes(filter.value);
              break
          case '!contains':
              matched = !val[filter.categorykey].toString().includes(filter.value);
              break
          default:
            break;
        }
      }

      return matched;
    }
    return routes.filter(filterComparator);
  }

  return (
    <>
      { props.clientFilter ? (
        <div className="container filter-bar mb-2">
          <StructuredFilter
            className={"search-query form-control"}
            placeholder={
              filters.length === 0
                ? toTTString(props.clientFilter.placeHolder ?? "Filter by attributes")
                : toTTString("Add filter")
            }
            value={filters}
            options={props.clientFilter.options}
            onChange={updateFilters}
            operatorSigns={{
              textoptions: {
                "==": "==",
              },
              text: {
                "==": "==",
                contains: "in",
              },
            }}
          />
          <FontAwesomeIcon icon="search" fixedWidth />
          <Button
            className="btn-primary filter-box-btn"
            onClick={() => setFilters([])}
          >
            <TT>Clear Filters</TT>
          </Button>
        </div>
      ) : null}

      <BootstrapTable
        striped
        hover={props.hover ?? true}
        condensed
        keyField={props.keyField || 'undefined'}
        data={props.clientFilter ? getFilteredData(props.data ?? []) : props.data ?? []}
        columns={props.columns}
        pagination={props.pagination ? paginationFactory(paginationOptions) : undefined}
        noDataIndication={props.noDataIndication ?? toTTString("No data available")}
        expandRow={props.expandRow ?? undefined}
        size={props.size ?? undefined}
        rowStyle={props.rowStyle ?? undefined}
      />
    </>
  )
}

export default FwBootstrapTable;
