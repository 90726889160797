import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Label,
} from "reactstrap";
import PathLabelBadge from "../pathlabels/PathLabelBadge";
import "./Policies.css";
import { LanguageContext, TT } from '../../containers/Language'

class Action extends Component {
  static contextType = LanguageContext;
  renderLinks(links) {
    const toTTString = this.context.toTTString;
    let id = 0;
    const linksList = links.map(link => {
      const { pathlabels, order } = link;
      const labels = pathlabels.map(label => {
        const { _id, name, color } = label;
        return (
          <div key={_id}>
            <PathLabelBadge
              name={name}
              color={color}
            />
          </div>
        );
      });
      const result = (
        <div key={id++}>
          <Label><TT params={{id: id}}>Group #id#</TT>:</Label>
          <div className="offset-md-1">
            <Label>
              <TT params={{order: toTTString(order.replace('-',' '))}}>
                Labels selection order: #order#
              </TT>
            </Label>
            <div className="container">
              <Label><TT>Labels</TT>:</Label>
              <div className="container col-md-12 link-group">
                {labels}
              </div>
            </div>
          </div>
        </div>
      );

      return result;
    });
    return (
      <div>
        <Label className="rule-attr"><TT>Path Labels Groups</TT>:</Label>
        <div className="offset-md-1 col-md-8">
          {linksList}
        </div>
      </div>
    )
  }

  render() {
    const toTTString = this.context.toTTString;
    const { links, order, fallback } = this.props.action;

    return (
      <React.Fragment>
        {this.renderLinks(links)}
        {links && links.length > 1 &&
        <div className="container">
          <Label className="rule-attr col-md-8">
            <TT params={{order: toTTString(order.replace('-',' '))}}>
              Selection order: #order#
            </TT>
          </Label>
        </div>
        }
        <div className="container">
          <Label className="rule-attr col-md-6">
            <TT params={{fallback: toTTString(fallback.replace('-',' '))}}>
              Fallback Action: #fallback#
            </TT>
          </Label>
        </div>
      </React.Fragment>
    );
  }
}

class Classification extends Component {
  static contextType = LanguageContext;
  renderPrefixClassifier(prefix) {
    const toTTString = this.context.toTTString;
    let prefixes = [];
    Object.entries(prefix).forEach(([field, value]) => {
      if (value && value !== "") {
        prefixes.push(
          <React.Fragment key={field}>
            <Label className="col-md-6">
              {`${field === "ip" ?
                toTTString("IP address") :
                toTTString(field.charAt(0).toUpperCase() + field.slice(1))}: ${value}`
              }
            </Label>
            <br/>
          </React.Fragment>
        );
      }
    });

    return (
      <React.Fragment>
        <Label className="rule-attr col-md-6"><TT>IP Rules</TT>:</Label>
        <div className="offset-md-1">
          {prefixes}
        </div>
        <hr/>
      </React.Fragment>
    );
  }

  renderAppClassifier(application) {
    const toTTString = this.context.toTTString;
    let app = [];
    Object.entries(application).forEach(([field, value]) => {
      const fieldToSpace = field.split(/(?=[A-Z])/).join(' ');

      if (value && value !== "") {
        app.push(
          <React.Fragment key={field}>
            <Label className="col-md-12">
              {`${toTTString(fieldToSpace.charAt(0).toUpperCase() + fieldToSpace.slice(1))}: ${value}`}
            </Label>
            <br/>
          </React.Fragment>
        );
      }
    });

    return (
      <React.Fragment>
        <Label className="rule-attr col-md-6"><TT>Application</TT>:</Label>
        <div className="offset-md-1">
          {app}
        </div>
        <hr/>
      </React.Fragment>
    );
  }

  render() {
    const { isDefault } = this.props;
    const { prefix } = this.props.classification || null;
    const { application } = this.props.classification || null;

    const classifiers = isDefault ?
      <Label>
        <TT>All traffic</TT>
      </Label>
      :
      <React.Fragment>
        {application ? this.renderAppClassifier(application) : ""}
        {prefix ? this.renderPrefixClassifier(prefix) : ""}
      </React.Fragment>

    return classifiers;
  }
}

class MultiLinkRuleCard extends Component {
  render() {
    const { isDefault } = this.props;
    const { classification, action } = this.props.rule;

    return (
      <div className="rule-card">
        <Card>
          <div className="container">
            <CardBody className="col-md-6">
              <Card className="sub-rule-card">
                <CardHeader className="sub-rule-header"><TT>Traffic Classification</TT></CardHeader>
                <CardBody className="rule-attr">
                  <Classification
                    classification={classification}
                    isDefault={isDefault}
                  />
                </CardBody>
              </Card>
            </CardBody>
            <CardBody className="col-md-6">
              <Card className="sub-rule-card">
                <CardHeader className="sub-rule-header"><TT>Action</TT></CardHeader>
                <CardBody>
                  <Action
                    action={action}
                  />
                </CardBody>
              </Card>
            </CardBody>
          </div>
        </Card>
        </div>
    );
  }
}

export default MultiLinkRuleCard;
