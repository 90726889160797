import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Control, Errors, actions } from "react-redux-form";
import { Label, Row, Col } from 'reactstrap';
import { TT, LanguageContext } from '../../../containers/Language';
import { required, } from '../../../utils/Validators'
import { verifyMfaRecoveryCode } from '../../../redux/reducers/Auth';
import { FWTextInput } from '../../Common';
import BottomCard from "./BottomCard";

const formModel = "useRecoveryCode";

const UseRecoveryCode = props => {
  const { toTTString } = useContext(LanguageContext);

  const handleSubmit = values => {
    props.verifyMfaRecoveryCode(values.recoveryCode)
  }

  // cleanup
  useEffect(() => {
    return function cleanup() {
      props.resetForm()
    };
  })

  return (
    <Form
      id="useRecoveryCodeForm"
      model={formModel}
      onSubmit={handleSubmit}
    >
      <Row className="form-group">
        <Label htmlFor="recoveryCode" md={4} className="d-flex align-items-center justify-content-between">
          <TT>Recovery Code</TT>
        </Label>
        <Col md={8}>
          <Control.text
            type="password"
            model=".recoveryCode"
            component={FWTextInput}
            withFieldValue
            id="recoveryCode"
            name="recoveryCode"
            validators={{
              required: required
            }}
          />
          <Errors
            className="text-danger"
            model=".recoveryCode"
            show="touched"
            messages={{
              required: toTTString('Required field'),
            }}
          />
        </Col>
      </Row>

      <Row className="form-group">
        <Col md={12}>
          <Control.button
            form="useRecoveryCodeForm"
            type="submit"
            model={formModel}
            className={"btn btn-primary"}
            style={{'minWidth':'460px'}}
          >
            <TT>Validate</TT>
          </Control.button>
        </Col>
      </Row>

      <BottomCard />
    </Form>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    verifyMfaRecoveryCode: code => dispatch(verifyMfaRecoveryCode(code)),
    resetForm: () =>  dispatch(actions.reset(formModel)),
  }
}

export default connect(null, mapDispatchToProps)(UseRecoveryCode);
