// Function to convert rates and set precision
// Convert to rates of K, M, G
// precision = number of digits to output after decimal point
// units = string for adding after the converted number
export const rateConversion = (num, precision, units="") => {
  if (num > 1000000000) return (num/1000000000).toFixed(precision) + " G" + units;
  if (num > 1000000) return (num/1000000).toFixed(precision) + " M" + units;
  if (num > 1000) return (num/1000).toFixed(precision) + " K" + units;
  return num.toFixed(precision) + " " + units;
};

// Function that capitalize first letter of a string
export const capitalize = (s) => {
  if (s.length > 0) return s[0].toUpperCase() + s.slice(1);
  return s;
}