import * as Actions from "../Actions";
import { mainRedirectTo } from "./Main";
import { alertMsgMessage } from "./AlertMsg";
import { FWFetch } from "../../utils/Network";
import cloneDeep from 'lodash/cloneDeep';
const { baseUrl } = window.SERVER_CONFIG;

export const HighAvailability = (
  state = {
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.VRRP_GETALL_REQUEST:
    case Actions.VRRP_GET_REQUEST:
    case Actions.VRRP_SAVE_REQUEST:
    case Actions.VRRP_DEL_REQUEST:
    case Actions.VRRP_DEVICE_INTERFACES_REQUEST:
    case Actions.VRRP_GET_STATUS_REQUEST:

        return { ...state, isLoading: true };

    case Actions.VRRP_GETALL_SUCCESS:
    case Actions.VRRP_GETALL_FAILURE:
    case Actions.VRRP_GET_SUCCESS:
    case Actions.VRRP_GET_FAILURE:
    case Actions.VRRP_SAVE_SUCCESS:
    case Actions.VRRP_SAVE_FAILURE:
    case Actions.VRRP_DEL_SUCCESS:
    case Actions.VRRP_DEL_FAILURE:
    case Actions.VRRP_DEVICE_INTERFACES_SUCCESS:
    case Actions.VRRP_DEVICE_INTERFACES_FAILURE:
    case Actions.VRRP_GET_STATUS_SUCCESS:
    case Actions.VRRP_GET_STATUS_FAILURE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get all VRRP Groups
 **********************************************************************/
export const requestGetVrrpGroups = () => dispatch => {
  dispatch({
    type: Actions.VRRP_GETALL_REQUEST
  });
};

export const receiveGetVrrpGroups = response => dispatch => {
  dispatch({
    type: Actions.VRRP_GETALL_SUCCESS,
    vrrpGroups: response
  });
};

export const failureGetVrrpGroups = error => dispatch => {
  dispatch({
    type: Actions.VRRP_GETALL_FAILURE
  });
};

export const getVrrpGroups = (cb = null, deviceId = null) => dispatch => {
  let url =  baseUrl + "vrrp";
  if (deviceId) {
    url += '?deviceId=' + deviceId;
  }
  return dispatch(
    FWFetch({
      url: url,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetVrrpGroups());
      },
      successCB: response => {
        dispatch(receiveGetVrrpGroups(response));
        if (cb) {
          cb(response, null);
        }
      },
      failureCB: error => {
        dispatch(failureGetVrrpGroups(error));
        if (cb) {
          cb(null, error);
        }
      }
    })
  );
};

/**********************************************************************
 *   Get VRRP Group
 **********************************************************************/
export const requestGetVrrpGroup = () => dispatch => {
  dispatch({
    type: Actions.VRRP_GET_REQUEST
  });
};

export const receiveGetVrrpGroup = response => dispatch => {
  dispatch({
    type: Actions.VRRP_GET_SUCCESS,
    vrrpGroups: response
  });
};

export const failureGetVrrpGroup = error => dispatch => {
  dispatch({
    type: Actions.VRRP_GET_FAILURE
  });
};

export const getVrrpGroup = (id, cb = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "vrrp/" + id,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetVrrpGroup());
      },
      successCB: response => {
        dispatch(receiveGetVrrpGroup(response));
        if (cb) {
          cb(response, null);
        }
      },
      failureCB: error => {
        dispatch(failureGetVrrpGroup(error));
        if (cb) {
          cb(null, error);
        }
      }
    })
  );
};

/**********************************************************************
 *   Save Vrrp Group
 **********************************************************************/
 export const requestSaveVrrpGroup = () => dispatch => {
  dispatch({
    type: Actions.VRRP_SAVE_REQUEST
  });
};

export const receiveSaveVrrpGroup = (method, response) => dispatch => {
  dispatch({
    type: Actions.VRRP_SAVE_SUCCESS
  });

  const { vrrpGroup, metadata } = response;

  dispatch(mainRedirectTo("/vrrp"));
  let name = '';
  let action = '';
  if (method === 'POST') {
    name = vrrpGroup.name;
    action = 'created';
  } else {
    name = vrrpGroup.name;
    action = 'updated';
  }
  let msg = `VRRP Group ${name} ${action} successfully`;

  const { jobs, reasons } = metadata;
  if (jobs && reasons?.length === 0) {
    msg += `. ${jobs} job${jobs === 1 ? '' : 's'} added`;
  } if (jobs && reasons?.length > 0) {
    msg += `. ${jobs} job${jobs === 1 ? '' : 's'} added. Some jobs were failed: ${reasons.join(',')}`;
  }

  dispatch(
    alertMsgMessage({
      color: "success",
      text: msg
    })
  );
};

export const failureSaveVrrpGroup = error => dispatch => {
  dispatch({
    type: Actions.VRRP_SAVE_FAILURE
  });
};

export const saveVrrpGroup = (vrrpGroup, cb = null) => dispatch => {
  let url = 'vrrp';
  let method = '';

  if (vrrpGroup._id) {
    url += '/' + vrrpGroup._id;
    method = 'PUT';
  } else {
    method = 'POST';
  }

  const data = cloneDeep(vrrpGroup);
  // remove tempIds that we use for vrrp devices
  data.devices = data.devices.map(d => {
    if(d._id.startsWith('tempId-vrrp-device')) {
      delete d._id;
    }
    return d;
  })

  return dispatch(
    FWFetch({
      url: baseUrl + url,
      method: method,
      body: JSON.stringify(data),
      requestCB: () => {
        dispatch(requestSaveVrrpGroup());
      },
      successCB: response => {
        dispatch(receiveSaveVrrpGroup(method, response));

        if (cb) {
          cb(response)
        }
      },
      failureCB: error => {
        dispatch(failureSaveVrrpGroup(error));
      }
    })
  );
};


/**********************************************************************
 *   Delete VRRP group
 **********************************************************************/
export const requestDelVrrpGroup = () => dispatch => {
  dispatch({
    type: Actions.VRRP_DEL_REQUEST
  });
};

export const receiveDelVrrpGroup = vrrpGroup => dispatch => {
  dispatch({
    type: Actions.VRRP_DEL_SUCCESS
  });

  dispatch(
    alertMsgMessage({
      color: "success",
      text: "VRRP Group " + vrrpGroup.name + " deleted successfully"
    })
  );
};

export const failureDelVrrpGroup = error => dispatch => {
  dispatch({
    type: Actions.VRRP_DEL_FAILURE
  });
};

export const deleteVrrpGroup = (vrrp, cb = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "vrrp/" + vrrp._id,
      method: "DELETE",
      body: JSON.stringify(vrrp),
      requestCB: () => {
        dispatch(requestDelVrrpGroup());
      },
      successCB: response => {
        dispatch(receiveDelVrrpGroup(vrrp));
        if (cb) {
          cb(response, null)
        }
      },
      failureCB: error => {
        dispatch(failureDelVrrpGroup(error));
        if (cb) {
          cb(null, error)
        }
      }
    })
  );
};

/**********************************************************************
 *   Get devices overlapping with VRRP Virtual IP
 **********************************************************************/
export const requestGetDeviceVrrpInterfaces = () => dispatch => {
  dispatch({
    type: Actions.VRRP_DEVICE_INTERFACES_REQUEST
  });
};

export const receiveGetDeviceVrrpInterfaces = response => dispatch => {
  dispatch({
    type: Actions.VRRP_DEVICE_INTERFACES_SUCCESS,
    vrrpGroups: response
  });
};

export const failureGetDeviceVrrpInterfaces = error => dispatch => {
  dispatch({
    type: Actions.VRRP_DEVICE_INTERFACES_FAILURE
  });
};

export const getDeviceVrrpInterfaces = (virtualIp, cb = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "vrrp/deviceVrrpInterfaces?virtualIp=" + virtualIp,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetDeviceVrrpInterfaces());
      },
      successCB: response => {
        dispatch(receiveGetDeviceVrrpInterfaces(response));
        if (cb) {
          cb(response, null);
        }
      },
      failureCB: error => {
        dispatch(failureGetDeviceVrrpInterfaces(error));
        if (cb) {
          cb(null, error);
        }
      }
    })
  );
};


/**********************************************************************
 *   Get VRRP Status
 **********************************************************************/
export const requestGetVrrpStatus = () => dispatch => {
  dispatch({
    type: Actions.VRRP_GET_STATUS_REQUEST
  });
};

export const receiveGetVrrpStatus = response => dispatch => {
  dispatch({
    type: Actions.VRRP_GET_STATUS_SUCCESS,
  });
};

export const failureGetVrrpStatus = error => dispatch => {
  dispatch({
    type: Actions.VRRP_GET_STATUS_FAILURE
  });
};

export const getVrrpStatus = (cb = null) => dispatch => {
  return dispatch(
    FWFetch({
      url: `${baseUrl}vrrp/status`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetVrrpStatus());
      },
      successCB: response => {
        dispatch(receiveGetVrrpStatus(response));
        if (cb) {
          cb(response, null);
        }
      },
      failureCB: error => {
        dispatch(failureGetVrrpStatus(error));
        if (cb) {
          cb(null, error);
        }
      }
    })
  );
};

