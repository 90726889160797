import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardTitle,
  Row, Col
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmail } from "../../utils/Validators";
import querySearch from "stringquery";
import { TT } from '../../containers/Language'

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
        readyToReset:false,
        password:"",
        passwordConf:"",
        token:"",
        passwordSize:8,
        email: "",
        id: ""
    };
    this.email = React.createRef();
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const query = querySearch(this.props.query);
    if (this.email.isConnected) {
      this.email.value = (query && query.email)?query.email:'';
    }
    if (!this.props.isAuthenticated && query.id && query.t) this.setState({
        readyToReset:true,
        id: query.id,
        token: query.t
    });
  }

  handleReset(event) {
    this.props.resetPasswordAccount({
      type: ((this.state.readyToReset)?"update":"reset"),
      email: ((this.email && this.email.value)? this.email.value:''),
      password: this.state.password,
      token: this.state.token,
      id: this.state.id
    });
    event.preventDefault();
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />;
    }

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Reset Password</TT></BreadcrumbItem>
        </Breadcrumb>
        <div>
          <Card id="resetPasswordCard">
            <CardBody>
              <CardTitle><TT>Reset Password</TT></CardTitle>
              <hr />
              <Form onSubmit={this.handleReset}>
                <FormGroup>
                  {!this.state.readyToReset ?
                    <Row>
                      <Label htmlFor="email" md={4}><TT>Email</TT></Label>
                      <Col md={8}>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          innerRef={input => (this.email = input)}
                          onChange={event => {
                            this.setState({ email: event.target.value });
                          }}
                        />
                        <div className="text-danger">
                          <span>
                            {this.state.email.length && !isEmail(this.state.email)
                              ? "Must be a valid email"
                              : ""}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  : null }
                </FormGroup>

                {this.state.readyToReset?
                <React.Fragment>
                    <FormGroup>
                    <Row>
                    <Label htmlFor="password" md={4}>Password</Label>
                    <Col md={8}>
                        <Input
                        type="password"
                        id="password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => {this.setState({password:e.target.value})}}
                        />
                        <div className="text-danger"><span>{
                            (this.state.password.length > 0 && this.state.password.length < this.state.passwordSize)?
                            `Length must be at least ${this.state.passwordSize}`:""}</span></div>
                    </Col>
                    </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                        <Label htmlFor="passwordConf" md={4}>Password Confirmation</Label>
                        <Col md={8}>
                            <Input
                            type="password"
                            id="passwordConf"
                            name="passwordConf"
                            value={this.state.passwordConf}
                            onChange={(e) => {this.setState({passwordConf:e.target.value})}}
                            />
                            <div className="text-danger"><span>{
                                (this.state.passwordConf.length > 0 && this.state.passwordConf !== this.state.password)?
                                "Password not matched":""}</span></div>
                        </Col>
                        </Row>
                    </FormGroup>
                </React.Fragment>
                :null}
                <Row>
                  <Col md={12}>
                    <Button type="submit" value="submit" color="primary" style={{'float':'right', 'minWidth':'460px'}}
                      disabled={(this.state.readyToReset && this.state.passwordConf.length === 0) ||
                                (this.state.readyToReset && this.state.password.length < this.state.passwordSize) ||
                                (!this.state.readyToReset && this.state.email.length === 0) ||
                                (this.state.email.length && !isEmail(this.state.email)) ||
                                (this.state.passwordConf !== this.state.password)}>
                      {this.state.readyToReset?<TT>Update Password</TT>:<TT>Reset Password</TT>}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />
              <div className="bottom-card-message">
                <div>
                  <TT>Back to</TT> <Link to="/login"><TT>Log In</TT></Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default ResetPassword;
