import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { _ } from "underscore";
const { baseUrl } = window.SERVER_CONFIG;

export const AppIdentifications = (
	state = {
		appIdentifications: [],
		meta: {
			customUpdatedAt: "",
			importedUpdatedAt: ""
		},
		importedappIdentifications: [],
		isLoading: false,
		newRules: [],
		installed: {}
	},
	action
) => {
	switch (action.type) {
		case Actions.APPIDENTIFICATION_ADD_REQUEST:
			return { ...state, appIdentification: null };
		case Actions.APPIDENTIFICATION_ADD_SUCCESS:
			return { ...state, appIdentification: action.appIdentification };
		case Actions.APPIDENTIFICATION_ADD_FAILURE:
			return { ...state, appIdentification: null };
		case Actions.APPIDENTIFICATION_GETALL_REQUEST:
			return { ...state, isLoading: true };
		case Actions.APPIDENTIFICATION_GETALL_SUCCESS:
			return { ...state, meta: action.appIdentifications.meta, appIdentifications: action.appIdentifications.appIdentifications, isLoading: false };
		case Actions.APPIDENTIFICATION_GETALL_FAILURE:
			return { ...state, appIdentifications: [], isLoading: false };
		case Actions.IMPORTEDAPPIDENTIFICATIONS_GETALL_REQUEST:
			return { ...state, isLoading: true };
		case Actions.IMPORTEDAPPIDENTIFICATIONS_GETALL_SUCCESS:
			return { ...state, importedappIdentifications: action.importedappIdentifications, isLoading: false };
		case Actions.IMPORTEDAPPIDENTIFICATIONS_GETALL_FAILURE:
			return { ...state, importedappIdentifications: [], isLoading: false };
		case Actions.APPIDENTIFICATION_RULE_ADD:
			return { ...state, newRules: updateAppIdentificationRules(state.newRules, action.newRules, true) };
		case Actions.APPIDENTIFICATION_RULE_DELETE:
			return { ...state, newRules: updateAppIdentificationRules(state.newRules, action.newRules, false) };
		case Actions.APPIDENTIFICATION_INSTALLED_GETALL_REQUEST:
			return { ...state, isLoading: true };
		case Actions.APPIDENTIFICATION_INSTALLED_GETALL_SUCCESS:
			return { ...state, installed: action.installed, isLoading: false };
		case Actions.APPIDENTIFICATION_INSTALLED_GETALL_FAILURE:
			return { ...state, installed: {}, isLoading: false };
		case Actions.APPIDENTIFICATIONS_DEVICE_UPDATE_REQUEST:
			return state;
		case Actions.APPIDENTIFICATIONS_DEVICE_UPDATE_SUCCESS:
			return state;
		case Actions.APPIDENTIFICATIONS_DEVICE_UPDATE_FAILURE:
			return state;
		case Actions.APPIDENTIFICATION_UPD_REQUEST:
			return { ...state, pathLabel: action.pathLabel };
		case Actions.APPIDENTIFICATION_UPD_SUCCESS:
			return { ...state };
		case Actions.APPIDENTIFICATION_UPD_FAILURE:
			return { ...state };
		default:
			return state;
	}
};

/* Action Creators */
/**********************************************************************
 *   Add App Identification Identification
 **********************************************************************/
export const requestAddAppIdentification = appIdentification => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_ADD_REQUEST,
		appIdentification: appIdentification
	});
};

export const receiveAddAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_ADD_SUCCESS,
		appIdentification: response
	});
	dispatch(mainRedirectTo("/appidentifications"));
	dispatch(
		alertMsgMessage({
			color: "success",
			text: "App Identification " + response.name + " added successfully"
		})
	);
};

export const failureAddAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_ADD_FAILURE
	});
	dispatch(
		alertMsgMessage({ color: "danger", text: "Error: " + error.message })
	);
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const addAppIdentification = (appIdentification, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications",
			method: "POST",
			body: JSON.stringify(appIdentification),
			requestCB: () => {
				dispatch(requestAddAppIdentification(appIdentification));
			},
			successCB: response => {
				dispatch(receiveAddAppIdentification(response));
				success_cb();
			},
			failureCB: error => {
				dispatch(failureAddAppIdentification(error));
			}
		})
	);
};

/**********************************************************************
 *   Get all app identifications
 **********************************************************************/
export const requestGetAllAppIdentifications = () => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_GETALL_REQUEST
	});
};

export const receiveGetAllAppIdentifications = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_GETALL_SUCCESS,
		appIdentifications: response
	});
};

export const failureGetAllAppIdentifications = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_GETALL_FAILURE
	});
};

export const getAllAppIdentifications = (success_cb = () => {}) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications",
			method: "GET",
			requestCB: () => {
				dispatch(requestGetAllAppIdentifications());
			},
			successCB: response => {
				dispatch(receiveGetAllAppIdentifications(response));
				success_cb(response);
			},
			failureCB: error => {
				dispatch(failureGetAllAppIdentifications(error));
			}
		})
	);
};

/**********************************************************************
 *   Get all imported app identifications
 **********************************************************************/
export const requestgetAllImportedAppIdentifications = () => dispatch => {
	dispatch({
		type: Actions.IMPORTEDAPPIDENTIFICATIONS_GETALL_REQUEST
	});
};

export const receivegetAllImportedAppIdentifications = response => dispatch => {
	dispatch({
		type: Actions.IMPORTEDAPPIDENTIFICATIONS_GETALL_SUCCESS,
		importedappIdentifications: response
	});
};

export const failuregetAllImportedAppIdentifications = error => dispatch => {
	dispatch({
		type: Actions.IMPORTEDAPPIDENTIFICATIONS_GETALL_FAILURE
	});
};

export const getAllImportedAppIdentifications = () => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/imported",
			method: "GET",
			requestCB: () => {
				dispatch(requestgetAllImportedAppIdentifications());
			},
			successCB: response => {
				dispatch(receivegetAllImportedAppIdentifications(response));
			},
			failureCB: error => {
				dispatch(failuregetAllImportedAppIdentifications(error));
			}
		})
	);
};

/**********************************************************************
 *   Get installed app identifications
 **********************************************************************/
export const requestGetInstalledAppIdentifications = () => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_INSTALLED_GETALL_REQUEST
	});
};

export const receiveGetInstalledAppIdentifications = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_INSTALLED_GETALL_SUCCESS,
		installed: response
	});
};

export const failureGetInstalledAppIdentifications = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_INSTALLED_GETALL_FAILURE
	});
};

export const getInstalledAppIdentifications = () => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/installed",
			method: "GET",
			requestCB: () => {
				dispatch(requestGetInstalledAppIdentifications());
			},
			successCB: response => {
				dispatch(receiveGetInstalledAppIdentifications(response));
			},
			failureCB: error => {
				dispatch(failureGetInstalledAppIdentifications(error));
			}
		})
	);
};

/**********************************************************************
 *   Update devices appIdentification
 **********************************************************************/
export const requestAppIdentificationUpdateDevices = () => dispatch => {
	dispatch({
	  type: Actions.APPIDENTIFICATIONS_DEVICE_UPDATE_REQUEST
	});
  };

export const receiveAppIdentificationUpdateDevices = response => dispatch => {
	const numJobs = (response.ids)? response.ids.length:0;
	let alertMsg;
	if (numJobs === 0) alertMsg = "No jobs queued, waiting jobs might not completed yet";
	else alertMsg = `${numJobs} job${numJobs>1?'s':''} queued, devices are updated when all jobs completed`;
	dispatch({
		type: Actions.APPIDENTIFICATIONS_DEVICE_UPDATE_SUCCESS
	});
	dispatch(alertMsgMessage({
		color: "success",
		text: alertMsg
	}));
};

export const failureAppIdentificationUpdateDevices = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATIONS_DEVICE_UPDATE_FAILURE
	});
};

export const appIdentificationUpdateDevices = (devices, isInstall, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
		url: baseUrl + "devices/apply",
		method: "POST",
		body: JSON.stringify({
			method: "appIdentification",
			devices: devices,
			action: (isInstall)? 'add':'del'
		}),
		requestCB: () => {
			dispatch(requestAppIdentificationUpdateDevices());
		},
		successCB: response => {
			dispatch(receiveAppIdentificationUpdateDevices(response));
			success_cb(response);
		},
		failureCB: error => {
			dispatch(failureAppIdentificationUpdateDevices(error));
		}
		})
	);
};


/**********************************************************************
 *   Delete AppIdentification
 **********************************************************************/
export const requestDelAppIdentification = appIdentification => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_DEL_REQUEST,
		appIdentification: appIdentification
	});
};

export const receiveDelAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_DEL_SUCCESS
	});
	dispatch(
		alertMsgMessage({
			color: "success",
			text:
				"App Identification deleted successfully"
		})
	);
};

export const failureDelAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_DEL_FAILURE
	});
};

export const delAppIdentification = (appIdentification, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/" + appIdentification.id,
			method: "DELETE",
			requestCB: () => {
				dispatch(requestDelAppIdentification(appIdentification));
			},
			successCB: response => {
				dispatch(receiveDelAppIdentification(response));
				success_cb();
			},
			failureCB: error => {
				dispatch(failureDelAppIdentification(error));
			}
		})
	);
};

export const addAppIdentificationRule = (rule) => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_RULE_ADD,
		newRules: rule
	});
};

export const deleteAppIdentificationRule = (rule) => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_RULE_DELETE,
		newRules: rule
	});
};

/**
 * Update App Identification Rules
 * @param {List} state  - previous rules state
 * @param {List} newRules - List of rules
 * @param {boolean} isAdd - should rule be added (true) or removed (false)
 * @return {object) - new rules state
 */
const updateAppIdentificationRules = (state, newRules, isAdd) => {
	console.log(`updateAppIdentificationRules: Enter, current count of rules ${state.length}, isAdd ${isAdd}`);
	if (newRules[0] === null) {
		// clear all rules
		return [];
	}
	newRules.forEach(newRule => {
		if (isAdd) {
			// Add dummy id field which is composite of the ip, ports and protocol. If not exist,
			// the reactstrap table will complain about duplicate id when trying to add duplicate
			// rule. Idealyy this should not happen, just extra precaution step.
			state.push({ ...newRule, id: `${newRule.ip}-${newRule.ports}-${newRule.protocol}` });
			return state;
		}
	});

	// assume single rule to be deleted upon each request
	return state.filter(function (value) { return !_.isEqual(value, newRules[0]) });
}

/**********************************************************************
 *   Get app identification
 **********************************************************************/
export const requestGetAppIdentification = () => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_GET_REQUEST
	});
};

export const receiveGetAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_GET_SUCCESS,
		pathLabel: response
	});
};

export const failureGetAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_GET_FAILURE
	});
};

export const getAppIdentification = (appIdentificationId, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/imported/" + appIdentificationId,
			method: "GET",
			requestCB: () => {
				dispatch(requestGetAppIdentification());
			},
			successCB: response => {
				dispatch(requestGetAppIdentification(response));
				success_cb(response);
			},
			failureCB: error => {
				dispatch(failureGetAppIdentification(error));
			}
		})
	);
};

export const requestGetCustomAppIdentification = () => dispatch => {
	dispatch({
		type: Actions.CUSTOM_APPIDENTIFICATION_GET_REQUEST
	});
};

export const receiveGetCustomAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.CUSTOM_APPIDENTIFICATION_GET_SUCCESS,
		pathLabel: response
	});
};

export const failureGetCustomAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.CUSTOM_APPIDENTIFICATION_GET_FAILURE
	});
};

export const getCustomAppIdentification = (appIdentificationId, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/custom/" + appIdentificationId,
			method: "GET",
			requestCB: () => {
				dispatch(requestGetCustomAppIdentification());
			},
			successCB: response => {
				dispatch(receiveGetCustomAppIdentification(response));
				success_cb(response);
			},
			failureCB: error => {
				dispatch(failureGetCustomAppIdentification(error));
			}
		})
	);
};

/**********************************************************************
 *   Update App Identification
 **********************************************************************/
export const requestUpdateAppIdentification = appIdentification => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_UPDATE_REQUEST,
		appIdentification: appIdentification
	});
};

export const receiveUpdateAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_UPDATE_SUCCESS
	});
	dispatch(mainRedirectTo("/appidentifications"));
	dispatch(
		alertMsgMessage({
			color: "success",
			text:
				"App Identification " + response.name + " updated successfully"
		})
	);
};

export const failureUpdateAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_UPDATE_FAILURE
	});
	dispatch(
		alertMsgMessage({ color: "danger", text: "Error: " + error.message })
	);
};

export const updateAppIdentification = (appIdentification, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/imported/" + appIdentification.id,
			method: "PUT",
			body: JSON.stringify({
				name: appIdentification.name,
				category: appIdentification.category,
				serviceClass: appIdentification.serviceClass,
				importance: appIdentification.importance
			}),
			requestCB: () => {
				dispatch(requestUpdateAppIdentification(appIdentification));
			},
			successCB: response => {
				dispatch(receiveUpdateAppIdentification(response));
				success_cb();
			},
			failureCB: error => {
				dispatch(failureUpdateAppIdentification(error));
			}
		})
	);
};

export const requestUpdateCustomAppIdentification = appIdentification => dispatch => {
	dispatch({
		type: Actions.CUSTOM_APPIDENTIFICATION_UPDATE_REQUEST,
		appIdentification: appIdentification
	});
};

export const receiveUpdateCustomAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.CUSTOM_APPIDENTIFICATION_UPDATE_SUCCESS
	});
	dispatch(mainRedirectTo("/appidentifications"));
	dispatch(
		alertMsgMessage({
			color: "success",
			text:
				"App Identification " + response.name + " updated successfully"
		})
	);
};

export const failureUpdateCustomAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.CUSTOM_APPIDENTIFICATION_UPDATE_FAILURE
	});
	dispatch(
		alertMsgMessage({ color: "danger", text: "Error: " + error.message })
	);
};

export const updateCustomAppIdentification = (appIdentification, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: baseUrl + "appidentifications/custom/" + appIdentification.id,
			method: "PUT",
			body: JSON.stringify(appIdentification),
			requestCB: () => {
				dispatch(requestUpdateCustomAppIdentification(appIdentification));
			},
			successCB: response => {
				dispatch(receiveUpdateCustomAppIdentification(response));
				success_cb();
			},
			failureCB: error => {
				dispatch(failureUpdateCustomAppIdentification(error));
			}
		})
	);
};

/**********************************************************************
 *   Reset App Identification
 **********************************************************************/
export const requestResetAppIdentification = APPIDENTIFICATIONS_ => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_RESET_REQUEST,
		APPIDENTIFICATIONS_
	});
};

export const receiveResetAppIdentification = response => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_RESET_SUCCESS
	});
	dispatch(mainRedirectTo("/appidentifications"));
	dispatch(
		alertMsgMessage({
			color: "success",
			text:
				"App Identification " + response.name + " updated successfully"
		})
	);
};

export const failureResetAppIdentification = error => dispatch => {
	dispatch({
		type: Actions.APPIDENTIFICATION_RESET_FAILURE
	});
	dispatch(
		alertMsgMessage({ color: "danger", text: "Error: " + error.message })
	);
};

export const resetAppIdentification = (appIdentification, success_cb) => dispatch => {
	return dispatch(
		FWFetch({
			url: `${baseUrl}appidentifications/imported/${appIdentification.id}/reset`,
			method: "PUT",
			body: JSON.stringify({}),
			requestCB: () => {
				dispatch(requestResetAppIdentification(appIdentification));
			},
			successCB: response => {
				dispatch(receiveResetAppIdentification(response));
				success_cb();
			},
			failureCB: error => {
				dispatch(failureResetAppIdentification(error));
			}
		})
	);
};