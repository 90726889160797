import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import keyExchangeMethods from '../../utils/keyExchangeMethods';
import { TT, LanguageContext } from '../../containers/Language';

class Organizations extends Component {
  static contextType = LanguageContext;
  componentDidMount() {
    this.props.getAllOrganizations();
    if (this.statusTimer == null) {
      const timer = setInterval(
        function (that) {
          // Only update when in focus
          if (document.hasFocus()) {
            that.props.getAllOrganizations();
          }
        },
        60000,
        this
      );
      this.statusTimer = timer;
    }
  }
  componentWillUnmount() {
    if (this.statusTimer != null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
  }

  constructor(props) {
    super(props);

    this.statusTimer = null;

    this.state = {
      isDelModalOpen: false,
      delEntity: null,
      orgSelected: []
    };

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleSelectOrg = this.handleSelectOrg.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen,
    });
  }

  handleDelete(row) {
    this.setState({ delEntity: row });
    this.toggleDelModal();
  }
  approveDelete() {
    this.toggleDelModal();
    // Clear selected organizations when deleted
    this.setState({ orgSelected: [] });
    this.props.delOrganization(this.state.delEntity, this.props.getAllOrganizations);
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        orgSelected: [...this.state.orgSelected, row._id],
      }));
    } else {
      this.setState(() => ({
        orgSelected: this.state.orgSelected.filter((id) => id !== row._id),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const ids = rows.map((r) => r._id);
      this.setState(() => ({
        orgSelected: ids,
      }));
    } else {
      this.setState(() => ({
        orgSelected: [],
      }));
    }
  };

  handleSelectOrg = () => {
    this.props.selectOrganization({ org: this.state.orgSelected[0] }, () => {
      this.setState(() => ({ orgSelected: [] }));
    });
  };

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (this.props.auth.org && row._id === this.props.auth.org) style.backgroundColor = '#fff3c7';
    return style;
  };

  render() {
    const toTTString = this.context.toTTString;

    const columns = [
      {
        text: toTTString('Name'),
        dataField: 'name',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
      },
      {
        text: toTTString('Description'),
        dataField: 'description',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '30%', textAlign: 'left' };
        },
      },
      {
        text: toTTString('Group'),
        dataField: 'group',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
      },
      {
        text: toTTString('Tunnels Key Exchange Method'),
        dataField: 'encryptionMethod',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '25%', textAlign: 'left' };
        },
        formatter: (value) => keyExchangeMethods[value],
      },
      {
        text: toTTString('VXLAN Port'),
        dataField: 'vxlanPort',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '25%', textAlign: 'left' };
        },
      },
      {
        text: toTTString('Tunnel Range'),
        dataField: 'tunnelRange',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '25%', textAlign: 'left' };
        },
      },
      {
        text: toTTString('Actions'),
        dataField: 'none',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
        formatter: (cellContent, row) => {
          if (this.props.auth.org && row._id === this.props.auth.org) {
            return (
              <div>
                <Link to={'/organizations/update/' + row._id}>
                  <Button
                    color='warning'
                    className='action-btn'
                    data-tip
                    data-for='update-a'
                    size='sm'
                  >
                    <FontAwesomeIcon icon='cog' fixedWidth />
                  </Button>
                </Link>
                <ReactTooltip id='update-a'>
                  <span>
                    <TT>Update Organization</TT>
                  </span>
                </ReactTooltip>
                <Button
                  color='danger'
                  className='action-btn'
                  data-tip
                  data-for='delete-a'
                  size='sm'
                  onClick={() => this.handleDelete(row)}
                >
                  <FontAwesomeIcon icon='trash-alt' fixedWidth />
                </Button>
                <ReactTooltip id='delete-a'>
                  <span>
                    <TT>Delete Organization</TT>
                  </span>
                </ReactTooltip>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];

    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className='react-bootstrap-table-pagination-total'>
          <TT params={{ from: from, to: to, size: size }}>
            Showing #from# to #to# of #size# Results
          </TT>
        </span>
      ),
      sizePerPageList: [
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      clickToExpand: false,
      selected: this.state.orgSelected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/home'>
              <TT>Home</TT>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <TT>Accounts</TT>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <TT>Organizations</TT>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className='container'>
          <h4>
            <TT>Organizations</TT>
          </h4>
          {this.props.organizations.isLoading ? <div className='signal'></div> : null}
        </div>
        <div className='col-md-12'>
          <div className='mb-3'>
            <ReactTooltip id='refresh-a'>
              <span>
                <TT>Refresh</TT>
              </span>
            </ReactTooltip>
            <Button
              color='info'
              className='refresh-btn'
              data-tip
              data-for='refresh-a'
              size='sm'
              onClick={this.props.getAllOrganizations}
            >
              <FontAwesomeIcon icon='sync-alt' />
            </Button>
            <Link to='/organizations/add'>
              <Button color='success' className='action-btn-top' size='sm'>
                <TT>New Organization</TT>
              </Button>
            </Link>
            {this.state.orgSelected.length === 1 ? (
              <Button
                color='success'
                className='action-btn-top'
                size='sm'
                onClick={() => this.handleSelectOrg()}
              >
                <TT>Set Organization</TT>
              </Button>
            ) : null}
          </div>
          <BootstrapTable
            striped
            hover
            condensed
            keyField='_id'
            data={this.props.organizations.organizations.map((org) => {
              return { ...org, selected: org._id === this.props.auth.org };
            })}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={toTTString('No organizations available')}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            selectRow={selectRow}
            rowStyle={this.rowStyle}
          />
        </div>
        <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
          <ModalHeader toggle={this.toggleDelModal}>
            <TT>Delete Organization</TT>
          </ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TT>Are you sure to delete organization and all of its inventory?</TT>
            </div>
            <Button color='danger' onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button className='float-right' color='outline-secondary' onClick={this.toggleDelModal}>
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
export default Organizations;
