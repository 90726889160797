import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Prompt } from 'react-router';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { LanguageContext } from '../containers/Language';

const isDataChanged = (orig, updated) => {
  return !isEqual(orig, updated);
}

const withSaveBeforeLeaving = WrappedComponent => props => {
  const { toTTString } = useContext(LanguageContext);

  const [orig, setOrig] = useState(null);
  const [updated, setUpdated] = useState(null);

  const warnOnLeavingPage = useCallback(event => {
    if (isDataChanged(orig, updated)) {
      event.preventDefault();
      event.returnValue = true;
    }
  }, [orig, updated]);

  useEffect(() => {
    window.addEventListener('beforeunload', warnOnLeavingPage);
    return () => { // clean up
      window.removeEventListener('beforeunload', warnOnLeavingPage);
    }
  }, [warnOnLeavingPage])

  const dataChanged = isDataChanged(orig, updated);

  // use callback to avoid re-rendering of this function on every render cycle
  const setOrigCb = useCallback(orig => {
    setOrig(cloneDeep(orig))
  }, []);

  // use callback to avoid re-rendering of this function on every render cycle
  const setUpdatedCb = useCallback(updated => {
    setUpdated(cloneDeep(updated))
  }, []);

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (dataChanged) {
              return toTTString('You have unsaved changes') + '. ' + toTTString('Are you sure you want to leave this page?')
            }
        }}
      />

      <WrappedComponent
        { ...props }
        orig={orig}
        updated={updated}
        setOrig={setOrigCb}
        setUpdated={setUpdatedCb}
        isDataChanged={isDataChanged}
      />
    </>
  )
}

export default withSaveBeforeLeaving;