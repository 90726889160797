import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { TT } from '../../containers/Language';

import HardwareConfiguration from './HardwareConfiguration';
import { getMajorVersion } from '../../utils/DevicesUtils';

const HardwareConfigurationButton = props => {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  if (props.deviceVersion && getMajorVersion(props.deviceVersion) < 6) {
    return null;
  }

  return (
    <>
      <Button
        color="success"
        className="btn btn-primary"
        onClick={toggleModal}
      >
        <TT>Hardware Configuration</TT>
      </Button>

      <Modal
        isOpen={openModal}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>
          <TT>Hardware Configuration</TT>
        </ModalHeader>
        <ModalBody>
          <HardwareConfiguration
            deviceId={props.deviceId}
            onFinished={toggleModal}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default HardwareConfigurationButton;