import React, { useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Type } from "react-bootstrap-table2-editor";
import { LanguageContext, TT } from '../../../containers/Language';
import { Control, actions, Errors } from 'react-redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { required, isIPList, minLength, maxLength, isMtu, isInteger, isDomain } from '../../../utils/Validators';
import { FWArrayEditableTable } from '../../Common';
import { store } from '../../../App';
import { connect } from 'react-redux';

const validators = {
  required: {
    func: required,
    msg: 'Required Field'
  },
  isIPList: {
    func: isIPList,
    msg: 'Invalid IPv4 list (comma separated)'
  },
  minLength: (min) => {
    return {
      func: minLength(min),
      msg: `Minimum length is ${min}`
    }
  },
  maxLength: (max) => {
    return {
      func: maxLength(max),
      msg: `Maximum allowed length is ${max}`
    }
  },
  isMtu: {
    func: isMtu,
    msg: 'MTU should be a number between 500 and 9999'
  },
  isInteger: {
    func: isInteger,
    msg: 'Invalid integer'
  },
  isDomain: {
    func: (val) => isDomain(val, false),
    msg: 'Invalid domain name'
  }
}

// PLEASE KEEP IT IN ORDER
const dhcpOptions = [
  { label: 'time-offset', code: '2', validators: [validators.required, validators.isInteger]},
  { label: 'routers', code: '3', validators: [validators.required, validators.isIPList]},
  { label: 'domain-name', code: '15', validators: [validators.required, validators.isDomain]},
  { label: 'interface-mtu', code: '26', validators: [ validators.required, validators.isMtu] },
  { label: 'ntp-servers', code: '42', validators: [ validators.required, validators.isIPList] },
  { label: 'tftp-server-name', code: '66', validators: [ validators.required, validators.maxLength(50), validators.minLength(1)] },
]

const Options = props => {
  const { toTTString } = useContext(LanguageContext);

  // this component is inside DHCP forms: "addDHCP" or "updDHCP"
  return (
    <>
      <Row className="form-group">
        <Col md={12}>
            <Row>
              <Col className="d-flex align-items-center">
                <div><TT>DHCP Options</TT></div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Control
                  model=".options"
                  id="options"
                  name="options"
                  component={FWArrayEditableTable}
                  form={props.form}
                  hideAddButton={props.hideAddButton}
                  withFieldValue
                  showRemove={false}
                  columns={[
                    {
                      text: toTTString("Option"),
                      dataField: "option",
                      sort: false,
                      editable: true,
                      headerStyle: (colum, colIndex) => {
                        return { width: '35%' };
                      },
                      editor: {
                        type: Type.SELECT,
                        options: dhcpOptions.map(o => ({ label: `(${o.code}) ${o.label}`, value: o.label }))
                      },
                      formatter: (cellContent, row, rowIndex) => {
                        return (
                          <>
                            {cellContent}
                            <Errors
                              className="text-danger"
                              model={`.options[${rowIndex}].option`}
                              show={(field) => (!field.pristine || field.touched) && !field.valid}
                              messages={{
                                required: toTTString('Required field'),
                                duplication: 'Same option cannot be configured twice'
                              }}
                            />
                          </>
                        )
                      }
                    },
                    {
                      text: toTTString("Code"),
                      dataField: "code",
                      sort: false,
                      headerStyle: (colum, colIndex) => {
                        return { width: '15%' };
                      },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                      }},
                      editable: false
                    },
                    {
                      text: toTTString("Value"),
                      dataField: "value",
                      sort: false,
                      headerStyle: (colum, colIndex) => {
                        return { width: '35%' };
                      },
                      editable: true,
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                      }},
                      editor: {
                        type: Type.TEXT,
                      },
                      validator: (newVal, row) => {
                        const text = dhcpOptions.find(d => d.label === row.option);
                        for (const validator of text?.validators) {
                          const isValid = validator.func(newVal);
                          if (!isValid) {
                            return {
                              valid: false,
                              message: validator.msg
                            };
                          }
                        }
                      },
                      formatter: (cellContent, row, rowIndex) => {
                        return (
                          <>
                            {cellContent}
                            <Errors
                              className="text-danger"
                              model={`.options[${rowIndex}].value`}
                              show={(field) => (!field.pristine || field.touched) && !field.valid}
                              messages={{
                                required: toTTString('Required field'),
                              }}
                            />
                          </>
                        )
                      }
                    },
                    {
                      text: toTTString("Actions"),
                      isDummyField: true,
                      dataField: 'actions',
                      sort: false,
                      editable: false,
                      headerStyle: (colum, colIndex) => {
                        return { width: '10%' };
                      },
                      formatter: (content, row, rowIndex, formatExtraData) => {
                        return (
                          <span data-tip data-for={"delete-option" + row.rowIndex}>
                            <Button
                              color="danger"
                              type="button"
                              style={{ marginTop: '5px' }}
                              className="action-btn"
                              size="sm"
                              onClick={() => {
                                const form = store.getState()[props.form];
                                const options = Array.from(form.options);
                                options.splice(rowIndex, 1);
                                props.changeForm(props.form, { ...form, options });
                              }}
                            >
                              <FontAwesomeIcon icon="trash-alt" fixedWidth />
                            </Button>
                          </span>
                        )
                      },
                    },
                  ]}
                  onStartEdit={(oldValue, newValue, row, column) => {
                    if (typeof props.onStartEdit === 'function') props.onStartEdit(oldValue, newValue, row, column);
                  }}
                  afterSaveCell={(oldValue, newValue, row, column) => {
                    if (typeof props.afterSaveCell === 'function') props.afterSaveCell(oldValue, newValue, row, column);
                    if (column.dataField === 'option') {
                      const option = dhcpOptions.find(d => d.label === row.option);
                      row.code = option.code;
                    }
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".options"
                  show="touched"
                  messages={{
                    optionDuplication: toTTString('Same option cannot be configured twice'),
                    optionIsEmpty: toTTString('Option cannot be empty')
                  }}
                />
              </Col>
            </Row>
        </Col>
      </Row>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    changeForm: (model, values) => dispatch(actions.change(model, values))
  }
}

export default connect(null, mapDispatchToProps)(Options);