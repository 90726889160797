import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Label,
  Card,
  CardBody
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { Link } from "react-router-dom";
import { required, maxLength, minLength, validateTokenName } from "../../utils/Validators";
import { FWTextInput } from "../Common";
import { TT, LanguageContext  } from '../../containers/Language'

class UpdToken extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.getToken(this.props.id, resp =>
      this.props.changeForm(
        { _id: resp._id, name: resp.name },
        { silent: true }
      )
    );
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    console.log("Updating Token: " + JSON.stringify(values));
    this.props.updToken(values, this.props.resetForm);
  }

  render() {
    const toTTString = this.context.toTTString
    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/tokens"><TT>Tokens</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Update Token</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Update Token</TT></h4>
        <div className="col-md-9">
          <Card id="updTokenCard">
            <CardBody>
              <Form
                model="updToken"
                onSubmit={values => this.handleSubmit(values)}
              >
                <Row className="form-group">
                  <Label htmlFor="name" md={2}>
                    <TT>Token Name</TT>
                  </Label>
                  <Col md={9}>
                    <Control.text
                      model=".name"
                      id="name"
                      name="name"
                      placeholder={toTTString("Token Name")}
                      component={FWTextInput}
                      withFieldValue
                      validators={{
                        required: required,
                        minLength: minLength(3),
                        maxLength: maxLength(15),
                        name: validateTokenName

                      }}
                    />
                    <Errors
                      className="text-danger"
                      model=".name"
                      show="touched"
                      messages={{
                        required: toTTString("Required field"),
                        minLength: toTTString("Length must be at least 3"),
                        maxLength: toTTString("Length must be at most 15"),
                        name: toTTString("Invalid token name format")
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={{ size: 10, offset: 2 }}>
                    <Control.button
                      model="updToken"
                      disabled={{ valid: false }}
                      className="btn btn-primary"
                    >
                      <TT>Update Token</TT>
                    </Control.button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdToken;
