import React, { useState, useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem, Nav, NavItem, Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { TT } from '../../../containers/Language';
import { getVrrpGroups, deleteVrrpGroup } from '../../../redux/reducers/HighAvailability';
import VrrpGroups from './VrrpGroups';
import Status from './Status';
import * as qs from 'query-string';

const VRRP = props => {
  const query = useMemo(() => {
    return qs.parse(props.location.search)
  }, [props.location.search]);

  const [currentTab, setCurrentTab] = useState(query.tab || 'VrrpGroup');

  const changeTab = tab => {
    setCurrentTab(tab);
  }

  const getTabsMap = () => {
    return {
      VrrpGroup: <VrrpGroups />,
      Status: <Status />
    }
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
        <BreadcrumbItem active><TT>High-Availability</TT></BreadcrumbItem>
        <BreadcrumbItem active><TT>VRRP</TT></BreadcrumbItem>
      </Breadcrumb>

      <div className="container">
        <h4><TT>VRRP</TT></h4>
      </div>

      <Row>
        <Col>
          <Nav className="nav-tabs">
            <NavItem>
              <Link to={props.location.pathname + "?tab=VrrpGroup"} onClick={() => changeTab('VrrpGroup')}
                className={currentTab === "VrrpGroup" ? "active nav-link" : "nav-link"}
              >
                <TT>VRRP Groups</TT>
              </Link>
            </NavItem>

            <NavItem>
              <Link to={props.location.pathname + "?tab=Status"} onClick={() => changeTab('Status')}
                className={currentTab === "Status" ? "active nav-link" : "nav-link"}
              >
                <TT>VRRP Status</TT>
              </Link>
            </NavItem>
          </Nav>
        </Col>
      </Row>
        {getTabsMap()[currentTab]}
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getVrrpGroups: cb => dispatch(getVrrpGroups(cb)),
    deleteVrrpGroup: (id, cb) => dispatch(deleteVrrpGroup(id, cb))
  }
}

export default connect(null, mapDispatchToProps)(withRouter(VRRP));