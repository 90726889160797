import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Label,
  Button,
  Input
} from "reactstrap";
import { Control, Form, Errors, actions } from "react-redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {
  required,
  minLength,
  maxLength,
  validateRuleName,
  isIP4,
  isIP4Mask,
  isPortValid,
  isValidPrefixLength
} from "../../utils/Validators";
import isEqual from 'lodash/isEqual';
import PathLabelSelectBox from '../items/PathLabelSelectBox';
import SearchableSelectBox from '../items/SearchableSelectBox';
import { FWTextInput } from "../Common";
import Checkbox from '../items/Checkbox';
import { TT, LanguageContext } from '../../containers/Language'

class MultiLinkRuleOp extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.nextGroupId = 0;

    let pathLabelsGroups = [{
      pathlabels: [],
      order: "priority",
      id: 0
    }];

    // Initialize path labels groups for
    // existing rules that are being modified
    const { rule } = this.props;
    if (rule && !isEqual({}, rule)) {
      const labels = rule.action.links.map(label => {
        const { pathlabels, order } = label;
        const newLabel = { id: this.nextGroupId, pathlabels, order };
        this.nextGroupId++;
        return newLabel;
      });
      pathLabelsGroups = JSON.parse(JSON.stringify(labels));
    }

    this.state = {
      pathLabelsGroups: pathLabelsGroups,
      isAdvancedMode: false,
      selectByAppCategory: true,
      advancedFormTouched: false,
      selectedClassifier: "application"
    }

    this.emptyPrefix = {
      ip: "",
      ports: "",
      protocol: ""
    }

    this.emptyApp = {
      name: "",
      category: "",
      serviceClass: "",
      importance: ""
    }

    this.validateForm = this.validateForm.bind(this);
    this.setClassifier = this.setClassifier.bind(this);
    this.handleSaveRule = this.handleSaveRule.bind(this);
    this.cleanRuleForm = this.cleanRuleForm.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.setAdvFormTouched = this.setAdvFormTouched.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.toggleAppSelectBy = this.toggleAppSelectBy.bind(this);
    this.closeAddRuleModal = this.closeAddRuleModal.bind(this);
    this.handleLabelsChange = this.handleLabelsChange.bind(this);
    this.handleAddPathLabelsGroup = this.handleAddPathLabelsGroup.bind(this);
  }

  componentDidMount() {
    const { rule } = this.props;

    // Nothing to do for new rules
    if (rule && isEqual({}, rule)) return;
    const { name, priority, enabled, action, classification } = rule;

    // Add default values to missing classification fields.
    // This is done in oder to properly initialize the rule
    // Redux form.
    const { prefix, application } = classification;
    let selectedClassifier = "application";
    let selectByAppCategory = true;
    if (!application) {
      selectedClassifier ="prefix"
    } else if (application.name && application.name !== "") {
      selectByAppCategory = false;
    }

    this.props.changeRuleForm(
      {
        name: name,
        priority: priority,
        enabled: enabled ? "enabled" : "disabled",
        action: action,
        classification: {
          application: application || this.emptyApp,
          prefix: prefix || this.emptyPrefix
        }
      },
      { silent: true }
    );

    this.setState({ selectedClassifier, selectByAppCategory });
  }

  toggleAppSelectBy(selector) {
    this.setClassifier("application");
    this.setState({
      selectByAppCategory: selector === "category",
    });
  }

  setAdvFormTouched(event) {
    // Advanced form is considered "touched" if
    // any of the prefix field, or the application
    // name/service class/importance has been set
    const { prefix } = event.classification;
    const { name, serviceClass, importance } = event.classification.application;

    const touched =
      !isEqual((prefix || this.emptyPrefix), this.emptyPrefix) ||
      (name !== "" || serviceClass !== "" || importance !== "");

    if (this.state.advancedFormTouched !== touched) {
      this.setState({ advancedFormTouched: touched });
    }
  }

  setClassifier(classifier) {
    if (classifier === this.state.selectedClassifier) return;
    switch (classifier) {
      case "prefix":
        this.props.resetAppForm();
        break;
      case "application":
        this.props.resetPrefixForm();
        break;
      default:
        break;
    }

    this.setState({ selectedClassifier: classifier });
  }

  handleModeChange() {
    const { isAdvancedMode } = this.state;
    this.setState({ isAdvancedMode: !isAdvancedMode });
  }

  cleanRuleForm() {
    this.setState({ selectByAppCategory: true });
    this.setState({
      pathLabelsGroups: [{
        pathlabels: [],
        order: "priority",
        id: 1
      }]
    });
    this.props.resetRuleForm();
  }

  closeAddRuleModal() {
    this.cleanRuleForm();
    this.props.toggleAddRuleForm();
  }

  handleSaveRule(rule) {
    const { name, priority, enabled, classification, action } = rule
    const { pathLabelsGroups } = this.state;
    const { addRule } = this.props;

    const newRule = {
      name: name,
      priority: priority,
      enabled: enabled === "enabled",
      action: { ...action },
      classification: {},
    };

    newRule.action.links = [...pathLabelsGroups];

    // Add only non-empty classifications
    const { prefix, application } = classification;
    if (prefix && !isEqual(prefix, this.emptyPrefix)) {
      newRule.classification.prefix = { ...prefix };
    }

    if (application && !isEqual(application, this.emptyApp)) {
      const { name, category, serviceClass, importance } = application;
      if (this.state.selectByAppCategory) {
        newRule.classification.application = { category, serviceClass, importance };
      } else {
        newRule.classification.application = { name };
      }
    }

    addRule(newRule);
    this.closeAddRuleModal();
  }

  handleLabelsChange(selectedOptions, actionMeta) {
    const { name } = actionMeta;
    const labels = (selectedOptions || []).map(label => { return label.orig })
    const groups = this.state.pathLabelsGroups.map(group => {
      let newGroup = { ...group };
      if (group.id === name) {
        newGroup.pathlabels = labels;
      }
      return group.id === name ? newGroup : group;
    });
    this.setState({ pathLabelsGroups: groups });
  }

  handleOrderChange(event, id) {
    const { value } = event.target;
    const groups = this.state.pathLabelsGroups.map(group => {
      let newGroup = { ...group };
      if (group.id === id) {
        newGroup.order = value;
      }
      return newGroup;
    });

    this.setState({ pathLabelsGroups: groups });
  }

  isIPAddrValid(ip) {
    if (!ip || ip === "") return true;

    const [addr, mask] = ip.split("/");
    return isIP4(addr) && isIP4Mask(mask);
  }

  validateForm(vals) {
    // Skip form validation for the default disabled rule
    const { isDefault } = this.props;
    if (isDefault && vals.enabled !== 'enabled') {
      return true;
    }
    // First validate path labels, as validation is
    // the same for both simple and advance modes
    const { pathLabelsGroups } = this.state;
    for (let group of pathLabelsGroups) {
      if (!group.pathlabels.length) return false;
    }
    // Skip form validation for the default rule with path labels
    if (isDefault) {
      return true;
    }

    // Check that at least one of
    // the classifiers is not empty
    const { prefix } = vals.classification;
    const { application } = vals.classification;

    return !isEqual(prefix, this.emptyPrefix) ||
           !isEqual(application, this.emptyApp);
  }

  handleAddPathLabelsGroup() {
    const group = {
      id: ++this.nextGroupId,
      pathlabels: [],
      order: "priority"
    };
    this.setState(prevState => ({
      pathLabelsGroups: [...prevState.pathLabelsGroups, group]
    }));
  }

  handleRemovePathLabelsGroup(groupId) {
    const groups = [...this.state.pathLabelsGroups].filter(group => {
      return group.id !== groupId;
    })
    this.setState({ pathLabelsGroups: groups });
  }

  handleChangeAction(model, value) {
    return (dispatch) => {
      dispatch(actions.change(model, value ? value.value : ""));
    };
  }

  renderAdvancedMode() {
    const toTTString = this.context.toTTString
    const { selectedClassifier, pathLabelsGroups } = this.state;
    const { apps, categories, serviceClasses } = this.props;
    return (
      <div className="container">
        <Card id="class" className="col-md-6">
          <CardHeader><TT>Traffic Classifiers</TT></CardHeader>
          <CardBody>
            <Row className="form-group" onClick={() => this.toggleAppSelectBy("name")}>
              <Col md={4}>
                <Input
                  type="radio"
                  name="selectBy"
                  checked={
                    this.state.selectedClassifier === "application"
                    && !this.state.selectByAppCategory
                  }
                  onChange={() => this.toggleAppSelectBy("name")}
                />
                <Label className="rule-attr"><TT>Application Name</TT></Label>
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.toggleAppSelectBy("name")}>
              <Label htmlFor="appName" md={3}>
                <TT>Name</TT>
              </Label>
              <Col md={9}>
                <Control
                  model="mlRule.classification.application.name"
                  id="appName"
                  name="appName"
                  placeholder=""
                  isDisabled={
                    selectedClassifier !== "application" ||
                    this.state.selectByAppCategory
                  }
                  changeAction={this.handleChangeAction}
                  component={SearchableSelectBox}
                  options={[...apps.values()]}
                />
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.toggleAppSelectBy("category")}>
              <Col md={6}>
                <Input
                  type="radio"
                  name="selectBy"
                  checked={
                    this.state.selectedClassifier === "application"
                    && this.state.selectByAppCategory
                  }
                  onChange={() => this.toggleAppSelectBy("category")}
                />
                <Label className="rule-attr"><TT>Application Category</TT></Label>
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.toggleAppSelectBy("category")}>
              <Label htmlFor="category" md={3}>
                <TT>Category</TT>
              </Label>
              <Col md={9}>
                <Control
                  model="mlRule.classification.application.category"
                  id="category"
                  name="category"
                  isDisabled={
                    selectedClassifier !== "application" ||
                    !this.state.selectByAppCategory
                  }
                  className="category-select"
                  placeholder=""
                  changeAction={this.handleChangeAction}
                  component={SearchableSelectBox}
                  options={categories}
                />
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.toggleAppSelectBy("category")}>
              <Label htmlFor="serviceClass" md={3}>
                <TT>Service Class</TT>
              </Label>
              <Col md={9}>
                <Control
                  model="mlRule.classification.application.serviceClass"
                  id="serviceClass"
                  name="serviceClass"
                  isDisabled={
                    selectedClassifier !== "application" ||
                    !this.state.selectByAppCategory
                  }
                  placeholder=""
                  changeAction={this.handleChangeAction}
                  component={SearchableSelectBox}
                  options={serviceClasses}
                />
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.toggleAppSelectBy("category")}>
              <Label htmlFor="importance" md={3}>
                <TT>Importance</TT>
              </Label>
              <Col md={9}>
                <Control.select
                  model="mlRule.classification.application.importance"
                  id="importance"
                  name="importance"
                  disabled={
                    selectedClassifier !== "application" ||
                    !this.state.selectByAppCategory
                  }
                  placeholder=""
                  className="form-control"
                >
                  {['', 'low', 'medium', 'high'].map((importance) => (
                    <option key={importance} value={importance}>
                      {toTTString(importance)}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
            <hr />
            <Row className="form-group" onClick={() => this.setClassifier("prefix")}>
              <Col md={6}>
                <Input
                  type="radio"
                  name="selectClassifier"
                  checked={selectedClassifier === "prefix"}
                  onChange={() => this.setClassifier("prefix")}
                />
                <Label className="rule-attr" ><TT>IP Rules</TT ></Label>
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.setClassifier("prefix")}>
              <Label htmlFor="ip" md={3}>
                <TT>IP Address</TT>
              </Label>
              <Col md={9}>
                <Control.text
                  type="text"
                  model="mlRule.classification.prefix.ip"
                  disabled={selectedClassifier !== "prefix"}
                  id="ip"
                  name="ip"
                  placeholder={toTTString("Destination IP/mask")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    ip: this.isIPAddrValid,
                    prefixLen: isValidPrefixLength
                  }}
                />
                <Errors
                  className="text-danger"
                  model="mlRule.classification.prefix.ip"
                  show="touched"
                  messages={{
                    ip: toTTString("Invalid IP Network Format"),
                    prefixLen: toTTString("Invalid prefix for given mask")
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.setClassifier("prefix")}>
              <Label htmlFor="ports" md={3}>
                <TT>Ports range</TT>
              </Label>
              <Col md={9}>
                <Control.text
                  type="text"
                  model="mlRule.classification.prefix.ports"
                  disabled={selectedClassifier !== "prefix"}
                  id="ports"
                  name="ports"
                  placeholder={toTTString("Destination Ports range")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    ports: isPortValid,
                  }}
                />
                <Errors
                  className="text-danger"
                  model="mlRule.classification.prefix.ports"
                  show="touched"
                  messages={{
                    ports: toTTString("Invalid ports range ([low port-high port])"),
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group" onClick={() => this.setClassifier("prefix")}>
              <Label htmlFor="protocol" md={3}>
                <TT>Protocol</TT>
              </Label>
              <Col md={9}>
                <Control.select
                  model="mlRule.classification.prefix.protocol"
                  disabled={selectedClassifier !== "prefix"}
                  id="protocol"
                  name="protocol"
                  placeholder=""
                  className="form-control"
                >
                  {['', 'tcp', 'udp'].map((protocol) => (
                    <option key={protocol} value={protocol}>
                      {toTTString(protocol)}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card id="action" className="col-md-6">
          <CardHeader><TT>Action</TT></CardHeader>
          <CardBody>
            <Label className="rule-attr"><TT>Path Labels Groups</TT></Label>
            <Row className="form-group add-link-button-row">
              <Col className="offset-md-11">
                <Button
                  className="add-link-button"
                  onClick={this.handleAddPathLabelsGroup}
                >
                  <FontAwesomeIcon icon={["fas", "plus"]} />
                </Button>
              </Col>
            </Row>
            {this.renderPathLabelsGroups()}
            <br />
            <Row className="form-group">
              <Col md={4} style={{display: 'flex', alignItems: 'center'}}>
                <Label htmlFor="order" style={{marginRight:'20px'}}>
                  <TT>Order Between Groups</TT>
                </Label>
                <ReactTooltip id="SelectGroupBy">
                  <span style={{ fontSize: "0.8rem" }}>
                    <TT>Select the order of path labels group</TT>. <br />
                    <TT>Priority: The first available group will be used</TT>. <br />
                    <TT>Load Balancing: All available groups will be used</TT>.
                  </span>
                </ReactTooltip>
                <span
                  className="helpTooltip"
                  data-tip
                  data-for="SelectGroupBy"
                />
              </Col>
              <Col md={8}>
                <Control.select
                  model="mlRule.action.order"
                  id="order"
                  name="order"
                  placeholder=""
                  className="form-control rule-select-box"
                  style={{ marginTop: "25px" }}
                  disabled={(pathLabelsGroups || []).length < 2}
                >
                  {['priority', 'load balancing'].map((order) => (
                    <option key={order} value={order.replace(" ", "-")}>
                      {toTTString(order)}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} style={{display: 'flex', alignItems: 'center'}}>
                <Label htmlFor="fallback" style={{marginRight:'20px'}}>
                  <TT>Fallback Action</TT>
                </Label>
                <ReactTooltip id="Fallback">
                  <span style={{ fontSize: "0.8rem" }}>
                    <TT>A fallback action that will be taken
                    if none of the selected path labels is available</TT> <br />
                  </span>
                </ReactTooltip>
                <span
                  className="helpTooltip"
                  data-tip
                  data-for="Fallback"
                />
              </Col>
              <Col md={8}>
                <Control.select
                  model="mlRule.action.fallback"
                  id="fallback"
                  name="fallback"
                  placeholder=""
                  className="form-control rule-select-box"
                  style={{ marginTop: "10px" }}
                >
                  {['drop', 'by destination'].map((fallback) => (
                    <option key={fallback} value={fallback.replace(" ", "-")}>
                      {toTTString(fallback)}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }

  renderSimpleMode() {
    const toTTString = this.context.toTTString
    const { pathLabels, categories, isDefault } = this.props;
    const { pathlabels, order, id } = this.state.pathLabelsGroups[0];

    return (
      <div className="container">
        <Card id="class" className="col-md-6">
          <CardHeader><TT>Traffic Classifiers</TT></CardHeader>
        {
          isDefault ?
            <Label className="rule-attr" md={12}>
              <TT>All traffic not matched by any of the rules above</TT>
            </Label>
            :
            <CardBody>
            <Row className="form-group">
              <Label htmlFor="appCategory" md={3}>
                <TT>Application Category</TT>
              </Label>
              <Col md={9}>
                <Control
                  style={{marginTop: "10px"}}
                  model="mlRule.classification.application.category"
                  id="category"
                  name="category"
                  className="category-select"
                  placeholder=""
                  isDisabled={
                    !this.state.selectByAppCategory ||
                    this.state.selectedClassifier !== 'application'
                  }
                  changeAction={this.handleChangeAction}
                  component={SearchableSelectBox}
                  options={categories}
                />
              </Col>
            </Row>
            </CardBody>
        }
        </Card>
        <Card id="class" className="col-md-6">
          <CardHeader><TT>Action</TT></CardHeader>
          <CardBody>
            <Row className="form-group path-label-row">
              <Label htmlFor="pathlabels" md={4}>
                <TT>Path Labels</TT>
              </Label>
              <Col md={8}>
                <Control.custom
                  model={`mlRule.action.links[0].pathlabels`}
                  id={0}
                  key={0}
                  component={PathLabelSelectBox}
                  placeholder={toTTString("Select path labels") + "..."}
                  values={pathlabels}
                  options={pathLabels}
                  onChange={this.handleLabelsChange}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="order" md={4}>
                <TT>Selection Order</TT>
                <ReactTooltip id="SelectBy">
                  <span style={{ fontSize: "0.8rem" }}>
                    <TT>Select the order of path labels</TT>. <br />
                    <TT>Priority: The first available label will be used</TT>. <br />
                    <TT>Load Balancing: All available labels will be used</TT>. <br />
                    <TT>Link Quality: The label will be used based on best link quality</TT>.
                  </span>
                </ReactTooltip>
                <span className="helpTooltip" data-tip data-for="SelectBy" />
              </Label>
              <Col md={8}>
                <Control.select
                  model={`mlRule.action.links[${id}].order`}
                  id={0}
                  name="order"
                  placeholder=""
                  className="form-control"
                  defaultValue={order}
                  onChange={(event) => this.handleOrderChange(event, id)}
                  disabled={(pathlabels || []).length < 2}
                >
                  {['priority', 'load balancing', 'link quality'].map((order) => (
                    <option key={order} value={order.replace(" ", "-")}>
                      {toTTString(order)}
                    </option>
                  ))}
                </Control.select>
              </Col>
          </Row>
          </CardBody>
        </Card>
      </div>
    );
  }

  renderPathLabelsGroups() {
    const { pathLabels } = this.props;
    const groups = this.state.pathLabelsGroups;
    const numOfGroups = groups.length;

    return groups.map(group => {
      const toTTString = this.context.toTTString
      const { pathlabels, order, id } = group
      return (
        <div key={id} className="container">
          <Card className="col-md-11 offset-md-1 path-label-card">
            <Row className="form-group path-label-row">
              <Label htmlFor="pathLabelsGroup" md={4}>
                <TT>Path Labels</TT>
              </Label>
              <Col md={8}>
                <Control.custom
                  model={`mlRule.action.links[${id}].pathlabels`}
                  component={PathLabelSelectBox}
                  placeholder={toTTString("Select path labels") + "..."}
                  id={id}
                  values={pathlabels}
                  options={pathLabels}
                  onChange={this.handleLabelsChange}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} style={{display: 'flex', alignItems: 'center'}}>
                <Label htmlFor="groupOrder" style={{marginRight:'20px'}}>
                  <TT>Selection Order</TT>
                </Label>
                <ReactTooltip id="SelectBy">
                    <span style={{ fontSize: "0.8rem" }}>
                      <TT>Select the order of path labels</TT>. <br />
                      <TT>Priority: The first available label will be used</TT>. <br />
                      <TT>Load Balancing: All available labels will be used</TT>. <br />
                      <TT>Link Quality: The label will be used based on best link quality</TT>.
                    </span>
                  </ReactTooltip>
                  <span
                    className="helpTooltip"
                    style={{flexShrink: 0}}
                    data-tip
                    data-for="SelectBy"
                  />
              </Col>
              <Col md={8}>
                <Control.select
                  model={`mlRule.action.links[${id}].order`}
                  id="groupOrder"
                  name="groupOrder"
                  placeholder=""
                  className="form-control"
                  defaultValue={order}
                  onChange={(event) => this.handleOrderChange(event, id)}
                  disabled={(pathlabels || []).length < 2}
                  style={{ marginTop: "10px" }}
                >
                  {['priority', 'load balancing', 'link quality'].map((order) => (
                    <option key={order} value={order.replace(" ", "-")}>
                      {toTTString(order)}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
          </Card>
          {numOfGroups > 1 ? (
            <Button
              className="remove-link-button"
              onClick={() => this.handleRemovePathLabelsGroup(id)}
            >
              <FontAwesomeIcon icon={["fas", "times"]} />
            </Button>
          ) : (
            ""
          )}
        </div>
      );
    });
  }

  render() {
    const toTTString = this.context.toTTString
    const { isOpen, isDefault, rule } = this.props;
    const { isAdvancedMode } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={this.closeAddRuleModal} size="xl">
        <ModalHeader toggle={this.closeAddRuleModal}>
          {isEqual({}, rule) ? <TT>New Path Selection Rule</TT> : <TT>Update Path Selection Rule</TT>}
        </ModalHeader>
        <ModalBody className={`${isAdvancedMode ? "rule-modal" : ""}`}>
          <Form
            id="add-ml-rule"
            model="mlRule"
            onSubmit={this.handleSaveRule}
            onChange={(event) => this.setAdvFormTouched(event)}
            validators={{
              // Form-level validator
              "": {
                classification: this.validateForm
              }
            }}
          >
            <Row className="form-group col-md-12">
              <Label className="rule-attr rule-attr-box"><TT>Name</TT></Label>
              <Col className="col-md-4">
                <Control.text
                  type="text"
                  model="mlRule.name"
                  id="ruleName"
                  name="name"
                  placeholder={toTTString("Rule Name")}
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    required: required,
                    minLength: minLength(3),
                    maxLength: maxLength(15),
                    name: validateRuleName
                  }}
                />
                <Errors
                  className="text-danger"
                  model="mlRule.name"
                  show="touched"
                  messages={{
                    required: toTTString("Required field"),
                    minLength: toTTString('Length must be at least 3'),
                    maxLength: toTTString('Length must be at most 15'),
                    name: toTTString("Invalid rule name format")
                  }}
                />
              </Col>
              <Label className="rule-attr rule-attr-box"><TT>Status</TT></Label>
              <Col className="col-md-2">
                <Control.select
                  model="mlRule.enabled"
                  id="enabled"
                  name="enabled"
                  className="form-control"
                  defaultValue="enabled"
                >
                  {['enabled', 'disabled'].map(status => (
                    <option key={status} value={status}>
                      {toTTString(status)}
                    </option>
                  ))}
                </Control.select>
              </Col>
            </Row>
          </Form>
          {this.state.isAdvancedMode
            ? this.renderAdvancedMode()
            : this.renderSimpleMode()}
          <ModalFooter>
            <Control.button
              model="mlRule"
              form="add-ml-rule"
              type="submit"
              disabled={{ valid: false }}
              className="btn btn-primary add-rule-button"
            >
              {isEqual({}, rule) ? <TT>Add Rule</TT> : <TT>Update Rule</TT>}
            </Control.button>
            <Col className="col-md-2">
              <Checkbox
                label={toTTString("Advanced mode")}
                disabled={isDefault}
                isSelected={isAdvancedMode}
                uid="advancedMode"
                onCheckboxChange={this.handleModeChange}
              />
            </Col>
              <Label
                className="advance-mode-label"
                style={{
                  color: `${this.state.advancedFormTouched ||
                            this.state.pathLabelsGroups.length > 1 ?
                            "green" : "grey"}`
                }}
              >
                {`| ${
                  this.state.advancedFormTouched ||
                  this.state.pathLabelsGroups.length > 1
                    ? toTTString('set')
                    : toTTString('not set')
                }`}
              </Label>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

export default MultiLinkRuleOp;
