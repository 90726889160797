import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import { alertMsgMessage } from "./AlertMsg";
import { getAccountSubscriptionStatus } from "./Accounts";
import { mainRedirectTo } from "./Main";
import { clearLocalSettings } from '../../utils/localSettings';
import { getStorageToken, getStorageRefreshToken, removeStorageToken, removeStorageRefreshToken, saveStorageToken } from "../../utils/Token";
const { baseUrl, registerRedirectUrl } = window.SERVER_CONFIG;
const jwt = require("jsonwebtoken");

// If token does not exist or expired return false, otherwise true
const parseToken = () => {
  const token = getStorageToken();
  const refreshToken = getStorageRefreshToken();

  const defaultState = () => {
    removeStorageToken();
    removeStorageRefreshToken();
    localStorage.removeItem("user");
    return {
      userId: null,
      isAuthenticated: false,
      org: null,
      orgName: null,
      account: null,
      accountName: null,
      expiration: 0,
      perms: {},
      loginToken: null,
      loginScreen: 0
    };
  }
  if (!token || !refreshToken) return defaultState();
  const decodedToken = jwt.decode(token, { complete: true });
  const decodedRefreshToken = jwt.decode(refreshToken, { complete: true });
  if (!decodedToken || !decodedRefreshToken) {
    return defaultState()
  }
  // Log the user out if both the access
  // and refresh tokens are expired
  const dateNow = new Date();
  if (decodedRefreshToken.payload.exp * 1000 < dateNow.getTime() &&
      decodedToken.payload.exp * 1000 < dateNow.getTime()) {
    return defaultState()
  }
  return {
    userId: decodedToken.payload._id,
    isAuthenticated: true,
    org: decodedToken.payload.org,
    orgName: decodedToken.payload.orgName,
    account: decodedToken.payload.account,
    expiration: decodedToken.payload.exp,
    accountName: decodedToken.payload.accountName,
    perms: decodedToken.payload.perms
  };
};

export const Auth = (
  state = {...parseToken(), 
    token: getStorageToken(), 
    user: localStorage.getItem("user")
  },
  action) => {
  switch (action.type) {
    case Actions.AUTH_UPDATE_JWT:
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        expiration: action.expiration,
        org: action.org,
        orgName: action.orgName,
        account: action.account,
        accountName: action.accountName,
        perms: action.perms
      };
    case Actions.SET_LOGIN_PROCESS_SCREEN:
      return { ...state, loginScreen: action.number };
    case Actions.AUTH_LOGIN_REQUEST:
      return { ...state, userId:null, isAuthenticated:false, user:null, token:null,
        org: null, orgName:null, account:null, accountName:null, expiration: 0, perms: {}, loginToken: null };

    case Actions.AUTH_LOGIN_SUCCESS:
      if (action.status === 'logged in') {
        return { ...state, isAuthenticated: true, user: action.name, loginToken: null };
      } else {
        return { ...state, isAuthenticated: false, user: action.name, loginToken: action.loginToken };
      }
    case Actions.AUTH_MFA_LOGIN_SUCCESS:
        return { ...state, isAuthenticated: true, user: action.name, loginToken: null };

    case Actions.AUTH_LOGIN_FAILURE:
      return { ...state, userId:null, isAuthenticated: false, user: null, token: null,
        org: null, orgName:null, account:null, accountName:null, expiration: 0, perms: {}, loginToken: null };
    case Actions.AUTH_LOGOUT_REQUEST:
      return state;
    case Actions.AUTH_LOGOUT_SUCCESS:
      return { ...state, userId:null, isAuthenticated: false, token: null, user: null,
        org: null, orgName:null, account:null, accountName:null, expiration: 0, perms: {}, loginToken: null, loginScreen: 0 };

    case Actions.AUTH_CREATE_REQUEST:
      return state;
    case Actions.AUTH_CREATE_SUCCESS:
      return state;
    case Actions.AUTH_CREATE_FAILURE:
      return state;

    case Actions.AUTH_VERIFY_REQUEST:
      return state;
    case Actions.AUTH_VERIFY_SUCCESS:
      return state;
    case Actions.AUTH_VERIFY_FAILURE:
      return state;

    case Actions.AUTH_REVERIFY_REQUEST:
      return state;
    case Actions.AUTH_REVERIFY_SUCCESS:
      return state;
    case Actions.AUTH_REVERIFY_FAILURE:
      return state;
    case Actions.AUTH_RESETPW_REQUEST:
      return state;
    case Actions.AUTH_RESETPW_SUCCESS:
      return state;
    case Actions.AUTH_RESETPW_FAILURE:
      return state;

    case Actions.MFA_IS_NOT_CONFIGURED:
      return { ...state, mfaNotConfigured: true };
    default:
      return state;
  }
};

/* Action Creators */
export const updateJWT = token => dispatch => {
  const decodedToken = jwt.decode(token);
  if (!decodedToken) return;
  // save the token in local storage
  saveStorageToken(token);
  dispatch({
    type: Actions.AUTH_UPDATE_JWT,
    token: token,
    expiration: decodedToken.exp,
    userId: decodedToken._id,
    org: decodedToken.org,
    orgName: decodedToken.orgName,
    account: decodedToken.account,
    accountName: decodedToken.accountName,
    perms: decodedToken.perms
  });
  // Get the account info only if organization is valid, otherwise, the get account itself
  // will have refresh-jwt token which will call again to updateJWT and infinite loop
  // If org is null, page is redirected to add organization top message is not shown
  if (decodedToken.org !== null && decodedToken.orgName !== null) {
    dispatch(getAccountSubscriptionStatus(decodedToken.account, ()=>{}));
  }
};

export const requestLogin = creds => dispatch => {
  dispatch({
    type: Actions.AUTH_LOGIN_REQUEST,
    creds
  });
};

export const receiveLogin = (response) => dispatch => {
  clearLocalSettings();
  dispatch({
    type: Actions.AUTH_LOGIN_SUCCESS,
    name : response.name,
    loginToken: response.token,
    status: response.status
  });
};

export const loginError = error => dispatch => {
  dispatch({
    type: Actions.AUTH_LOGIN_FAILURE
  });
};

export const mfaIsNotConfigured = response => dispatch => {
  dispatch({
    type: Actions.MFA_IS_NOT_CONFIGURED,
  });

  dispatch(mainRedirectTo("/mfa"));
};
export const mfaIsRequired = response => dispatch => {
  dispatch({
    type: Actions.MFA_IS_REQUIRED,
    name : response.name
  });
};

export const loginUser = (creds, failure_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/login",
      method: "POST",
      body: JSON.stringify(creds),
      requestCB: () => {
        dispatch(requestLogin(creds));
      },
      successCB: (response, status) => {
        dispatch(receiveLogin(response));
        if (response.name) {
          localStorage.setItem("user", response.name)
        }
      },
      failureCB: error => {
        dispatch(loginError(error));
        failure_cb();
      }
    })
  );
};

// get QR code
export const getQRCode = (cb) => (dispatch) => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/mfa/getMfaConfigUri",
      allowLoginToken: true,
      method: "GET",
      requestCB: () => {
      },
      successCB: (response, status) => {
        cb(response);

      },
      failureCB: error => {
        dispatch(logoutUser(error));
      }
    })
  );
};

export const receiveVerify2FA = (response) => dispatch => {
  localStorage.setItem("user", response.name);
  clearLocalSettings();
  dispatch({
    type: Actions.AUTH_MFA_LOGIN_SUCCESS,
    name : response.name
  });
};

export const verify2FA = (data, successCb, errorCb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/mfa/verify",
      allowLoginToken: true,
      method: "POST",
      body: JSON.stringify(data),
      requestCB: () => {},
      successCB: (response, status) => {
        if (successCb) {
          successCb(response);
        }

        // If login was successful, set the user in local storage
        localStorage.setItem("user", response.name);
        clearLocalSettings();
        // Dispatch the success action
        dispatch(receiveVerify2FA(response));
      },
      failureCB: error => {
        if (errorCb) {
          errorCb(error);
        }
      }
    })
  );
};

export const setLoginProcessScreen = number => dispatch => {
  dispatch({
    type: Actions.SET_LOGIN_PROCESS_SCREEN,
    number
  });
};

export const getMfaRecoveryCodes = (cb, regenerate = false) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/mfa/generateRecoveryCodes?regenerate=" + regenerate,
      method: "GET",
      requestCB: () => {
      },
      successCB: (response, status) => {
        cb(response, null)
      },
      failureCB: error => {
        cb(null, error)
      }
    })
  );
};

export const verifyMfaRecoveryCode = (recoveryCode) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/mfa/verifyRecoveryCode",
      allowLoginToken: true,
      method: "POST",
      body: JSON.stringify({ recoveryCode }),
      requestCB: () => {
      },
      successCB: (response, status) => {
        dispatch(receiveVerify2FA(response));
      },
      failureCB: error => {

      }
    })
  );
};

export const verifyBackupEmailCode = (code) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/mfa/verifyCodeToBackupEmail",
      allowLoginToken: true,
      method: "POST",
      body: JSON.stringify({ code }),
      requestCB: () => {},
      successCB: (response, status) => {
        dispatch(receiveVerify2FA(response));
      },
      failureCB: error => {}
    })
  );
};

export const getLoginAuthMethods = cb => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/login/methods",
      allowLoginToken: true,
      method: "GET",
      requestCB: () => {
      },
      successCB: (response, status) => {
        cb(response)
      },
      failureCB: error => {
      }
    })
  );
};

export const getUserMfa = cb => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/mfa/isEnabled",
      allowLoginToken: true,
      method: "GET",
      requestCB: () => {
      },
      successCB: (response, status) => {
        cb(response)
      },
      failureCB: error => {
      }
    })
  );
};


// Logout
export const requestLogout = () => {
  return {
    type: Actions.AUTH_LOGOUT_REQUEST
  };
};

export const receiveLogout = () => {
  return {
    type: Actions.AUTH_LOGOUT_SUCCESS
  };
};

export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  removeStorageToken()
  localStorage.removeItem("user");
  removeStorageRefreshToken();
  clearLocalSettings();
  dispatch(receiveLogout());
};

// Create user and account
export const requestCreate = () => dispatch => {
  dispatch({
    type: Actions.AUTH_CREATE_REQUEST,
  });
};

export const receiveCreate = () => dispatch => {
  dispatch({
    type: Actions.AUTH_CREATE_SUCCESS,
  });
  dispatch(
    alertMsgMessage({ color: "success", text: "Account created, check your e-mail to activate your account" })
  ); 
};

export const errorCreate = () => dispatch => {
  dispatch({
    type: Actions.AUTH_CREATE_FAILURE
  });
};

export const createUser = (values, success_cb, failure_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "users/register",
      method: "POST",
      body: JSON.stringify(values),
      requestCB: () => {
        dispatch(requestCreate());
      },
      successCB: () => {
        // If login was successful, redirect to verify mail message
        // Dispatch the success action
        dispatch(receiveCreate());
        success_cb();
        if (registerRedirectUrl) {
          dispatch(mainRedirectTo("/create-account?state=completed"));
          dispatch(mainRedirectTo(registerRedirectUrl));
        } else {
          dispatch(mainRedirectTo("/login"));
        }
        // Push data to GTM
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event' : 'create_account_of_fleximanage',
            'email' : values?.email || '',
            'phone_number' :  values?.userPhoneNumber || ''
        });
      },
      failureCB: () => {
        dispatch(errorCreate());
        failure_cb();
      }
    })
  );
};

// verify user and account
export const requestVerify = () => dispatch => {
  dispatch({
    type: Actions.AUTH_VERIFY_REQUEST,
  });
};

export const receiveVerify = () => dispatch => {
  dispatch({
    type: Actions.AUTH_VERIFY_SUCCESS,
  });
  dispatch(
    alertMsgMessage({ color: "success", text: "Your account is now active. Please login." })
  );  
};

export const errorVerify = () => dispatch => {
  dispatch({
    type: Actions.AUTH_VERIFY_FAILURE
  });
};

export const verifyAccount = (values) => dispatch => {
  console.log("Verify Account, values="+JSON.stringify(values));
  return dispatch(
    FWFetch({
      url: baseUrl + "users/verify-account",
      method: "POST",
      body: JSON.stringify(values),
      requestCB: () => {
        dispatch(requestVerify());
      },
      successCB: () => {
        // If login was successful, redirect to verify mail message
        // Dispatch the success action
        dispatch(receiveVerify());
        dispatch(mainRedirectTo("/login"));
      },
      failureCB: () => {
        dispatch(errorVerify());
      }
    })
  );
};

// Re-verify account
export const requestReverify = () => dispatch => {
  dispatch({
    type: Actions.AUTH_REVERIFY_REQUEST,
  });
};

export const receiveReverify = () => dispatch => {
  dispatch({
    type: Actions.AUTH_REVERIFY_SUCCESS,
  });
  dispatch(
    alertMsgMessage({ color: "success", text: "Success, check your e-mail to activate your account" })
  );  
};

export const errorReverify = () => dispatch => {
  dispatch({
    type: Actions.AUTH_REVERIFY_FAILURE
  });
};

export const reverifyAccount = (values) => dispatch => {
  console.log("Reverify Account, values="+JSON.stringify(values));
  return dispatch(
    FWFetch({
      url: baseUrl + "users/reverify-account",
      method: "POST",
      body: JSON.stringify(values),
      requestCB: () => {
        dispatch(requestReverify());
      },
      successCB: () => {
        // If login was successful, redirect to verify mail message
        // Dispatch the success action
        dispatch(receiveReverify());
        dispatch(mainRedirectTo("/login"));
      },
      failureCB: () => {
        dispatch(errorReverify());
      }
    })
  );
};

// Reset/Update Password
export const requestResetPassword = () => dispatch => {
  dispatch({
    type: Actions.AUTH_RESETPW_REQUEST,
  });
};

export const receiveResetPassword = (type) => dispatch => {
  dispatch({
    type: Actions.AUTH_RESETPW_SUCCESS,
  });
  dispatch(
    alertMsgMessage({ color: "success", text: 
      (type==="update")?"Your password has been reset":"Success, check your e-mail to reset your password"})
  );  
};

export const errorResetPassword = () => dispatch => {
  dispatch({
    type: Actions.AUTH_RESETPW_FAILURE
  });
};

export const resetPasswordAccount = (values) => dispatch => {
  console.log("Reset Account Password, values="+JSON.stringify(values));
  return dispatch(
    FWFetch({
      url: baseUrl + "users/reset-password",
      method: "POST",
      body: JSON.stringify(values),
      requestCB: () => {
        dispatch(requestResetPassword());
      },
      successCB: () => {
        // If login was successful, redirect to verify mail message
        // Dispatch the success action
        dispatch(receiveResetPassword(values.type));
        if (values.type && values.type === "update") dispatch(mainRedirectTo("/login"));
      },
      failureCB: () => {
        dispatch(errorResetPassword());
      }
    })
  );
};
