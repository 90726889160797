import React, {useContext} from "react";
import ReactTooltip from 'react-tooltip';
import { LanguageContext } from '../../containers/Language'

export default function InternetAccessIcon ({deviceStatus, row}) {
  const { toTTString } = useContext(LanguageContext);
  let params = {};
  let internetAccess = row.internetAccess;
  const isConnected = deviceStatus && deviceStatus.connection === 'connected';
  if (deviceStatus && deviceStatus.interfaces) {
    const interfaceStatus = deviceStatus.interfaces.find(i => i.devId === row.devId);
    if (interfaceStatus) {
      internetAccess = interfaceStatus.internetAccess;
    }
  };
  if (!isConnected) {
    params.status = 'disabled';
    params.text = toTTString('device not connected');
  } else if (!row.monitorInternet || internetAccess === '') {
    params.status = 'disabled';
    params.text = toTTString('internet monitoring not available');
  } else if (internetAccess === 'yes') {
    params.status = 'active';
    params.text = toTTString('online - internet access available');
  } else {
    params.status = 'inactive';
    params.text = toTTString('offline - no internet access');
  };

  return <span
      className={'status-circle ' + params.status}
      data-tip data-for={'internet-access-' + row._id}
    >
      <ReactTooltip id={'internet-access-' + row._id}>
        <span>{params.text}</span>
      </ReactTooltip>
    </span>;
}