import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import { alertMsgMessage } from "./AlertMsg";
import queryString from 'query-string';
const { baseUrl } = window.SERVER_CONFIG;

export const Jobs = (
  state = {
    jobs: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.JOB_GET_REQUEST:
      return { ...state, jobs: [], total:0, isLoading: true };
    case Actions.JOB_GET_SUCCESS:
      return { ...state, jobs: action.jobs, total: action.total, isLoading: false };
    case Actions.JOB_GET_FAILURE:
      return { ...state, jobs: [], total:0, isLoading: false };
    case Actions.JOB_DEL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.JOB_DEL_SUCCESS:
      return { ...state, isLoading: false };
    case Actions.JOB_DEL_FAILURE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get job
 **********************************************************************/
export const requestGetJob = () => dispatch => {
  dispatch({
    type: Actions.JOB_GET_REQUEST
  });
};

export const receiveGetJob = (response, total) => dispatch => {
  dispatch({
    type: Actions.JOB_GET_SUCCESS,
    jobs: response,
    total: total
  });
};

export const failureGetJob = error => dispatch => {
  dispatch({
    type: Actions.JOB_GET_FAILURE
  });
};

export const getJobs = (state, params) => dispatch => {
  const queryParams = queryString.stringify(params);
  return dispatch(
    FWFetch({
      url: baseUrl + `jobs?status=${state}&${queryParams}`,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetJob());
      },
      successCB: (response, status, headers) => {
        dispatch(receiveGetJob(response, headers && headers.get('records-total')));
      },
      failureCB: error => {
        dispatch(failureGetJob(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete Jobs
 **********************************************************************/
export const requestDelJobs = () => dispatch => {
  dispatch({
    type: Actions.JOB_DEL_REQUEST,
  });
};

export const receiveDelJobs = response => dispatch => {
  dispatch({
    type: Actions.JOB_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Jobs deleted successfully"
    })
  );
};

export const failureDelJobs = error => dispatch => {
  dispatch({
    type: Actions.JOB_DEL_FAILURE
  });
};

export const delJobs = (state, jobsIds, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "jobs",
      method: "DELETE",
      body: JSON.stringify({ids:jobsIds}),
      requestCB: () => {
        dispatch(requestDelJobs());
      },
      successCB: response => {
        dispatch(receiveDelJobs(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelJobs(error));
      }
    })
  );
};

export const deleteAllJobs = (filters, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "jobs",
      method: "DELETE",
      body: JSON.stringify({filters}),
      requestCB: () => {
        dispatch(requestDelJobs());
      },
      successCB: response => {
        dispatch(receiveDelJobs(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelJobs(error));
      }
    })
  );
};