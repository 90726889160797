import React, { Component } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import PathLabelBadge from './PathLabelBadge';
import { TT, LanguageContext } from '../../containers/Language'

class PathLabels extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      isDelModalOpen: false,
      delRow: null,
      labels: []
    }

    this.labelColumns = [
      { text: <TT>rowID</TT>, dataField: "_id", hidden: true },
      {
        text: <TT>Name</TT>,
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          const { color, name } = row;
          return (
            <PathLabelBadge
              name={name}
              color={color}
            />
          );
        }
      },
      {
        text: <TT>Type</TT>,
        dataField: "type",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        }
      },
      {
        text: <TT>Description</TT>,
        dataField: "description",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "50%", textAlign: "left" };
        }
      },
      {
        text: <TT>Actions</TT>,
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "10%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link to={"/pathlabels/update/" + row._id}>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for="edit-a"
                  size="sm"
                >
                  <FontAwesomeIcon icon="cog" fixedWidth />
                </Button>
              </Link>
              <ReactTooltip id="edit-a">
                <span><TT>Edit</TT></span>
              </ReactTooltip>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="delete-a"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id="delete-a">
                <span><TT>Delete</TT></span>
              </ReactTooltip>
            </div>
          );
        }
      }
    ];
    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
  }

  componentDidMount() {
    this.props.getAllPathLabels(resp => {
      this.setState({ labels: resp });
    });
  }

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen
    });
  }

  handleDelete(row) {
    this.setState({ delRow: row });
    this.toggleDelModal();
  }

  approveDelete() {
    const { delRow } = this.state;
    this.props.delPathLabel(delRow, () => {
      this.props.getAllPathLabels(resp => {
        this.setState({ labels: resp });
      })
    });
    this.toggleDelModal();
  }

  render() {
    const toTTString = this.context.toTTString
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      clickToExpand: false,
      // selected: () => {},
      // onSelect: () => {},
      // onSelectAll: () => {}
    };
    const labelPaginationOptions = {
      paginationSize: 10,
      alwaysShowAllBtns: false,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('First page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('Next page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "100", value: 100 }
      ]
    };

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Path Labels</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Path Labels</TT></h4>
        <div className="col-md-12">
          <div className="mb-3">
          <Link to="/pathlabels/add">
            <Button color="success" className="action-btn-top" size="sm">
              <TT>New Label</TT>
            </Button>
          </Link>
          </div>
          <BootstrapTable
            striped
            hover
            condensed
            keyField="_id"
            data={this.state.labels}
            columns={this.labelColumns}
            pagination={paginationFactory(labelPaginationOptions)}
            noDataIndication={toTTString("No path labels available")}
            defaultSorted={[{ dataField: "name", order: "asc" }]}
            selectRow={selectRow}
          />
        </div>
        <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
          <ModalHeader toggle={this.toggleDelModal}>
            <TT>Delete Path Label</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT params={{deleteThis: this.state.delRow ? this.state.delRow.name : ""}}>Are you sure you want to delete #deleteThis#?</TT>
            </div>
            <Button color="danger" onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleDelModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PathLabels;