import React from 'react';
import './StatusSteps.css';

const colors = {
  success: {backgroundColor: '#e7f5e8', borderColor: '#257324', color: '#257324'},
  warning:{backgroundColor:'#fef3e5', borderColor:'#e4ae77',color:'#dc9145'},
  secondary:{backgroundColor:'#f6f7f8',borderColor:'#bbb',color:'#bbb'},
  danger:{backgroundColor:'#fee7e6', borderColor:'#d67c78',color:'#d67c78'}
}

export const StatusStep = ({title, style, color, children, click, ...props}) => {
  let useStyle = (color && colors.hasOwnProperty(color))
    ? colors[color]
    : style
      ? style
      : {};
  if (click) useStyle = {...useStyle, cursor:'pointer'};
  return(
    <div className="status-step" {...props}>
      <div className="status-content">
        <p className="h6 mb-1 mt-1">{title}</p>
        <div className="inner-text" style={useStyle} onClick={click}>
          {children===undefined || isNaN(children)? '...':children}
        </div>
    </div>
  </div>
  )
};

export const StatusSteps = (props) => {
  return(
    <div className="status-steps" {...props}>
      {props.children}
    </div>
  )
};

