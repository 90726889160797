import React, { Component } from "react";
import {
  maxLength,
  minLength,
  isEmail,
  isEmpty,
  isNumber,
  isPhoneNumber,
  validateName,
  validateCompanyName,
  validateJobTitle
} from "../../utils/Validators";
import {
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row, Col,
} from "reactstrap";
import { Control, Form, Errors } from "react-redux-form";
import { FWTextInput, FWTextPhone } from "../Common";
import { Link, Redirect } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import ReCAPTCHA from "react-google-recaptcha";
import { countryList } from '../../utils/Countries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TT, LanguageContext  } from '../../containers/Language'
import '../items/Checkbox.css';
import * as qs from 'query-string';
const { captchaSiteKey } = window.SERVER_CONFIG;
class CreateAccount extends Component {
  constructor(props) {
    super(props);
    const query = qs.parse(props.search);

    this.recaptchaRef = React.createRef();

    this.state = {
      formStep: 1,
      completed: query?.state === 'completed' || false,
      valuesStep1: {},
      items: {
        step1: [
          <Form key="step1"
            id="userCreateAccount1"
            model="userCreateAccount1"
            onSubmit={values => this.handleSubmit1(values)}
          >
            <Row className="form-group">
              <Label htmlFor="accountName" md={5}>
                <span className="form-required-star">*</span>
                Company Name <span className="helpTooltip" data-tip data-for='accountNameTip'></span>
                <ReactTooltip id='accountNameTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    Will be used as your account name
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  model=".accountName"
                  id="accountName"
                  name="accountName"
                  placeholder="Company Name"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    maxLength: maxLength(30),
                    minLength: minLength(2),
                    validateCompanyName: validateCompanyName
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".accountName"
                  show="touched"
                  messages={{
                    maxLength: "Length must be at most 30",
                    minLength: "Length must be at least 2",
                    validateCompanyName: "Special characters not allowed"
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="email" md={5}>
                <span className="form-required-star">*</span>
                Business Email <span className="helpTooltip" data-tip data-for='emailTip'></span>
                <ReactTooltip id='emailTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    This email is used to login into your account,<br />
                    Account notifications will be sent to this email.<br />
                    Consider using a company email alias.
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  type="email"
                  model=".email"
                  id="email"
                  name="email"
                  placeholder="Business Email"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    email: isEmail
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".email"
                  show="touched"
                  messages={{
                    email: 'Must be a valid email'
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="userFirstName" md={5}>
                <span className="form-required-star">*</span>
                First Name
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  model=".userFirstName"
                  id="userFirstName"
                  name="userFirstName"
                  placeholder="First Name"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    maxLength: maxLength(15),
                    minLength: minLength(2),
                    validateName: validateName
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".userFirstName"
                  show="touched"
                  messages={{
                    maxLength: "Length must be at most 15",
                    minLength: "Length must be at least 2",
                    validateName: "Special characters not allowed"
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="userLastName" md={5}>
                <span className="form-required-star">*</span>
                Last Name
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  model=".userLastName"
                  id="userLastName"
                  name="userLastName"
                  placeholder="Last Name"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    maxLength: maxLength(15),
                    minLength: minLength(2),
                    validateName: validateName
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".userLastName"
                  show="touched"
                  messages={{
                    maxLength: "Length must be at most 15",
                    minLength: "Length must be at least 2",
                    validateName: "Special characters not allowed"
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="userJobTitle" md={5}>
                <span className="form-required-star">*</span>
                Job Title
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  model=".userJobTitle"
                  id="userJobTitle"
                  name="userJobTitle"
                  placeholder="Job Title"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    maxLength: maxLength(30),
                    minLength: minLength(2),
                    validateJobTitle: validateJobTitle
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".userJobTitle"
                  show="touched"
                  messages={{
                    maxLength: "Length must be at most 30",
                    minLength: "Length must be at least 2",
                    validateJobTitle: "Special characters not allowed"
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Label htmlFor="userPhoneNumber" md={5}>
                <span className="form-not-required-star"> </span>
                Phone Number <span className="helpTooltip" data-tip data-for='phoneNumberTip'></span>
                <ReactTooltip id='phoneNumberTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                  Optional but important. Another way for us<br />
                  to reach you when needed
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  model=".userPhoneNumber"
                  id="userPhoneNumber"
                  name="userPhoneNumber"
                  placeholder="Phone Number"
                  component={FWTextPhone}
                  withFieldValue
                  validators={{
                    isPhoneNumber: (number) => {return isEmpty(number) || number==='+' || isPhoneNumber(number)},
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".userPhoneNumber"
                  show="touched"
                  messages={{
                    isPhoneNumber: "Not a valid phone number",
                  }}
                />
              </Col>
            </Row>            
            <Row className="form-group">
              <Label htmlFor="password" md={5}>
                <span className="form-required-star">*</span>
                Password <span className="helpTooltip" data-tip data-for='passwordNameTip'></span>
                <ReactTooltip id='passwordNameTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    Password between 8 to 15 chars
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  type="password"
                  model=".password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    maxLength: maxLength(15),
                    minLength: minLength(8)
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".password"
                  show="touched"
                  messages={{
                    maxLength: "Length must be at most 15",
                    minLength: "Length must be at least 8"
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col className="form-field" md={12}>
                <Control.button style={{'float':'right', 'minWidth':'460px'}}
                  form={"userCreateAccount1"}
                  type="submit"
                  model="userCreateAccount1"
                  disabled={{valid: false}} 
                  className="btn btn-primary"
                >
                  Next
                </Control.button>
                <span style={{"fontSize": "0.8rem", 'marginLeft':'15px'}}>
                  Proceed to create a new account. You become this account owner.
                </span>
              </Col>
            </Row>
          </Form>
        ],
        step2: [
          <Form key="step2"
            id="userCreateAccount2"
            model="userCreateAccount2"
            onSubmit={values => this.handleSubmit(values)}
          >
            <Row className="form-group">
              <Label htmlFor="country" md={5}>
                <span className="form-required-star">*</span>
                Country <span className="helpTooltip" data-tip data-for='countryTip'></span>
                <ReactTooltip id='countryTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    HQ country of your company
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.select
                  model=".country"
                  id="country"
                  name="country"
                  placeholder="Country"
                  className="form-control"
                  validators={{
                    selected: (val) => { return !isEmpty(val);}
                  }}
                >
                  <option value="" disabled hidden>
                    Select Country
                  </option>
                  {countryList.map(option => <option value={option.value} key={option.value}>{option.name}</option>)}
                </Control.select>
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="companySize" md={5}>
                <span className="form-required-star">*</span>
                Company Size <span className="helpTooltip" data-tip data-for='companySizeTip'></span>
                <ReactTooltip id='companySizeTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    Approximately, what’s the size<br />
                    of your company?
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.select
                  model=".companySize"
                  id="companySize"
                  name="companySize"
                  placeholder="Company Size"
                  className="form-control"
                  validators={{
                    selected: (val) => { return !isEmpty(val); }
                  }}
                >
                  <option value="" disabled hidden>
                    Company Size
                  </option>
                  <option value={"0-10"}>1-10 employees</option>
                  <option value={"10-100"}>10-100 employees</option>
                  <option value={"100-1000"}>100-1,000 employees</option>
                  <option value={"1000-10000"}>1,000-10,000 employees</option>
                  <option value={"10000+"}>More than 10,000 employees</option>
                </Control.select>
              </Col>
            </Row>

            <Row className="form-group">
              <Label htmlFor="serviceType" md={5}>
                <span className="form-required-star">*</span>
                Usage Type <span className="helpTooltip" data-tip data-for='serviceTypeTip'></span>
                <ReactTooltip id='serviceTypeTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    Are you looking to offer SD-WAN<br />
                    to your customers or is it for<br />
                    internal use by your company’s network
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.select
                  model=".serviceType"
                  id="serviceType"
                  name="serviceType"
                  placeholder="Service Type"
                  className="form-control"
                  validators={{
                    selected: (val) => { return !isEmpty(val); }
                  }}
                >
                  <option value="" disabled hidden>
                    Usage Type
                  </option>
                  <option value={"Provider"}>Offer to customers</option>
                  <option value={"Internal"}>Internal use</option>                 
                </Control.select>
              </Col>
            </Row>            
            <Row className="form-group">
              <Label htmlFor="numberSites" md={5}>
                <span className="form-required-star">*</span>
                Number Of Sites <span className="helpTooltip" data-tip data-for='numSitesTip'></span>
                <ReactTooltip id='numSitesTip'>
                  <span style={{"fontSize": "0.8rem"}}>
                    Total number of sites you manage
                  </span>
                </ReactTooltip>
              </Label>
              <Col className="form-field" md={7}>
                <Control.text
                  model=".numberSites"
                  id="numberSites"
                  name="numberSites"
                  placeholder="Number Of Sites"
                  component={FWTextInput}
                  withFieldValue
                  validators={{
                    isNumber: (val) => { return !isEmpty(val) && isNumber(val) && val >=1 }
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".numberSites"
                  show="touched"
                  messages={{
                    isNumber: "Must be at least 1",
                  }}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={12} style={{'paddingLeft':'15px'}}>
                <Control.checkbox
                  type="checkbox"
                  label=""
                  id="acceptTerms"
                  className="fwcheckbox"
                  name="acceptTerms"
                  model=".acceptTerms"
                  validators={{
                    accepted: (val) => val === true,
                  }}
                />
                <label htmlFor="acceptTerms">
                  <span></span>
                  I agree to the <a href="https://flexiwan.com/legal/terms-of-service/" target="_blank" rel="noopener noreferrer">
                    <b>Terms Of Service</b> </a>and <a href="https://flexiwan.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer"><b>Privacy Policy</b> </a>
                </label>
                <Errors
                  className="text-danger"
                  model=".acceptTerms"
                  show = {{pristine:false}}
                  messages={{
                    accepted: "Terms and Conditions must be accepted"
                  }}
                />
              </Col>
            </Row>
            {captchaSiteKey !== ""?
            <Row className="form-group">
              <Col className="form-field" md={12}>
                <Control.text style={{'display': 'none', 'height':0, 'padding':0, 'margin':0, 'border':0}}
                  id="captcha"
                  name="captcha"
                  model=".captcha"
                  value={undefined}
                  validators={{
                    selected: (token) => token !== '',
                  }}
                />
                <ReCAPTCHA style={{'float':'right'}}
                  ref={this.recaptchaRef}
                  sitekey={captchaSiteKey}
                  onChange={token => this.props.setForm2CaptchaToken(token,{'silent':true})}
                  onExpired={() => this.props.setForm2CaptchaToken('',{'silent':true})}
                  onErrored={() => this.props.setForm2CaptchaToken('',{'silent':true})}
                />
              </Col>
            </Row>:null}
            <Row className="form-group">
              <Col className="form-field" md={12}>
                <Control.button style={{'float':'right', 'minWidth':'460px'}}
                  form={"userCreateAccount2"}
                  type="submit"
                  model="userCreateAccount2"
                  disabled={{valid:false}}
                  className="btn btn-primary"
                >
                  Create
                </Control.button>
              </Col>
            </Row>
          </Form>
        ]
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit1(values) {
    this.setState({valuesStep1:{...values}, formStep:2});
    this.props.setForm2CaptchaToken('',{'silent':true});
  }

  handleSubmit(values) {
    this.props.createUser({...this.state.valuesStep1, ...values},
      () => { // success callback
        this.props.resetForm1();
        this.props.resetForm2();
      },
      ()=>{   // failure callback
        if (captchaSiteKey !== "") this.recaptchaRef.current.reset();
        this.props.setForm2CaptchaToken('',{'silent':true});
      }
    );
  }

  render() {
    const toTTString = this.context.toTTString
    const items =  {
      step1: [
        <Form key="step1"
          id="userCreateAccount1"
          model="userCreateAccount1"
          onSubmit={values => this.handleSubmit1(values)}
        >
          <Row className="form-group">
            <Label htmlFor="accountName" md={5}>
              <span className="form-required-star">*</span>
              <TT>Company Name</TT> <span className="helpTooltip" data-tip data-for='accountNameTip'></span>
              <ReactTooltip id='accountNameTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>Will be used as your account name</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                model=".accountName"
                id="accountName"
                name="accountName"
                placeholder={toTTString("Company Name")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  maxLength: maxLength(30),
                  minLength: minLength(2),
                  validateCompanyName: validateCompanyName
                }}
              />
              <Errors
                className="text-danger"
                model=".accountName"
                show="touched"
                messages={{
                  maxLength: toTTString("Length must be at most 30"),
                  minLength: toTTString("Length must be at least 2"),
                  validateCompanyName: toTTString("Special characters not allowed")
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="email" md={5}>
              <span className="form-required-star">*</span>
              <TT>Business Email</TT> <span className="helpTooltip" data-tip data-for='emailTip'></span>
              <ReactTooltip id='emailTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>This email is used to login into your account</TT>,<br />
                  <TT>Account notifications will be sent to this email</TT>.<br />
                  <TT>Consider using a company email alias</TT>.
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                type="email"
                model=".email"
                id="email"
                name="email"
                placeholder={toTTString("Business Email")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  email: isEmail
                }}
              />
              <Errors
                className="text-danger"
                model=".email"
                show="touched"
                messages={{
                  email: toTTString('Must be a valid email')
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="userFirstName" md={5}>
              <span className="form-required-star">*</span>
              <TT>First Name</TT>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                model=".userFirstName"
                id="userFirstName"
                name="userFirstName"
                placeholder={toTTString("First Name")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  maxLength: maxLength(15),
                  minLength: minLength(2),
                  validateName: validateName
                }}
              />
              <Errors
                className="text-danger"
                model=".userFirstName"
                show="touched"
                messages={{
                  maxLength: toTTString("Length must be at most 15"),
                  minLength: toTTString("Length must be at least 2"),
                  validateName: toTTString("Special characters not allowed")
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="userLastName" md={5}>
              <span className="form-required-star">*</span>
              <TT>Last Name</TT>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                model=".userLastName"
                id="userLastName"
                name="userLastName"
                placeholder={toTTString("Last Name")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  maxLength: maxLength(15),
                  minLength: minLength(2),
                  validateName: validateName
                }}
              />
              <Errors
                className="text-danger"
                model=".userLastName"
                show="touched"
                messages={{
                  maxLength: toTTString("Length must be at most 15"),
                  minLength: toTTString("Length must be at least 2"),
                  validateName: toTTString("Special characters not allowed")
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="userJobTitle" md={5}>
              <span className="form-required-star">*</span>
              <TT>Job Title</TT>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                model=".userJobTitle"
                id="userJobTitle"
                name="userJobTitle"
                placeholder={toTTString("Job Title")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  maxLength: maxLength(30),
                  minLength: minLength(2),
                  validateJobTitle: validateJobTitle
                }}
              />
              <Errors
                className="text-danger"
                model=".userJobTitle"
                show="touched"
                messages={{
                  maxLength: toTTString("Length must be at most 30"),
                  minLength: toTTString("Length must be at least 2"),
                  validateJobTitle: toTTString("Special characters not allowed")
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="userPhoneNumber" md={5}>
              <span className="form-not-required-star"> </span>
              <TT>Phone Number</TT> <span className="helpTooltip" data-tip data-for='phoneNumberTip'></span>
              <ReactTooltip id='phoneNumberTip'>
                <span style={{"fontSize": "0.8rem"}}>
                <TT>Optional but important</TT>.
                <TT>Another way for us to reach you when needed</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                model=".userPhoneNumber"
                id="userPhoneNumber"
                name="userPhoneNumber"
                placeholder={toTTString("Phone Number")}
                component={FWTextPhone}
                withFieldValue
                validators={{
                  isPhoneNumber: (number) => {return isEmpty(number) || number==='+' || isPhoneNumber(number)},
                }}
              />
              <Errors
                className="text-danger"
                model=".userPhoneNumber"
                show="touched"
                messages={{
                  isPhoneNumber: toTTString("Not a valid phone number"),
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="password" md={5}>
              <span className="form-required-star">*</span>
              <TT>Password</TT> <span className="helpTooltip" data-tip data-for='passwordNameTip'></span>
              <ReactTooltip id='passwordNameTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>Password between 8 to 15 chars</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                type="password"
                model=".password"
                id="password"
                name="password"
                placeholder={toTTString("Password")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  maxLength: maxLength(15),
                  minLength: minLength(8)
                }}
              />
              <Errors
                className="text-danger"
                model=".password"
                show="touched"
                messages={{
                  maxLength: toTTString("Length must be at most 15"),
                  minLength: toTTString("Length must be at least 8")
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col className="form-field" md={12}>
              <Control.button style={{'float':'right', 'minWidth':'460px'}}
                form={"userCreateAccount1"}
                type="submit"
                model="userCreateAccount1"
                disabled={{valid: false}}
                className="btn btn-primary"
              >
                <TT>Next</TT>
              </Control.button>
              <span style={{"fontSize": "0.8rem", 'marginLeft':'15px'}}>
                <TT>Proceed to create a new account</TT>. <TT>You become this account owner</TT>.
              </span>
            </Col>
          </Row>
        </Form>
      ],
      step2: [
        <Form key="step2"
          id="userCreateAccount2"
          model="userCreateAccount2"
          onSubmit={values => this.handleSubmit(values)}
        >
          <Row className="form-group">
            <Label htmlFor="country" md={5}>
              <span className="form-required-star">*</span>
              <TT>Country</TT> <span className="helpTooltip" data-tip data-for='countryTip'></span>
              <ReactTooltip id='countryTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>HQ country of your company</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.select
                model=".country"
                id="country"
                name="country"
                placeholder={toTTString("Country")}
                className="form-control"
                validators={{
                  selected: (val) => { return !isEmpty(val);}
                }}
              >
                <option value="" disabled hidden>
                  {toTTString('Select Country')}
                </option>
                {countryList.map(option => <option value={option.value} key={option.value}>{toTTString(option.name)}</option>)}
              </Control.select>
            </Col>
          </Row>

          <Row className="form-group">
            <Label htmlFor="companySize" md={5}>
              <span className="form-required-star">*</span>
              <TT>Company Size</TT> <span className="helpTooltip" data-tip data-for='companySizeTip'></span>
              <ReactTooltip id='companySizeTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>Approximately, whatÔÇÖs the size of your company?</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.select
                model=".companySize"
                id="companySize"
                name="companySize"
                placeholder={toTTString("Company Size")}
                className="form-control"
                validators={{
                  selected: (val) => { return !isEmpty(val); }
                }}
              >
                <option value="" disabled hidden>
                  {toTTString("Company Size")}
                </option>
                <option value={"0-10"}>{toTTString("1-10 employees")}</option>
                <option value={"10-100"}>{toTTString("10-100 employees")}</option>
                <option value={"100-1000"}>{toTTString("100-1,000 employees")}</option>
                <option value={"1000-10000"}>{toTTString("1,000-10,000 employees")}</option>
                <option value={"10000+"}>{toTTString("More than 10,000 employees")}</option>
              </Control.select>
            </Col>
          </Row>

          <Row className="form-group">
            <Label htmlFor="serviceType" md={5}>
              <span className="form-required-star">*</span>
              <TT>Usage Type</TT> <span className="helpTooltip" data-tip data-for='serviceTypeTip'></span>
              <ReactTooltip id='serviceTypeTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>?Are you looking to offer SD-WAN
                  to your customers or is it for
                  internal use by your companyÔÇÖs network</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.select
                model=".serviceType"
                id="serviceType"
                name="serviceType"
                placeholder={toTTString("Service Type")}
                className="form-control"
                validators={{
                  selected: (val) => { return !isEmpty(val); }
                }}
              >
                <option value="" disabled hidden>
                  {toTTString("Usage Type")}
                </option>
                <option value={"Provider"}>{toTTString("Offer to customers")}</option>
                <option value={"Internal"}>{toTTString("Internal use")}</option>
              </Control.select>
            </Col>
          </Row>
          <Row className="form-group">
            <Label htmlFor="numberSites" md={5}>
              <span className="form-required-star">*</span>
              <TT>Number Of Sites</TT> <span className="helpTooltip" data-tip data-for='numSitesTip'></span>
              <ReactTooltip id='numSitesTip'>
                <span style={{"fontSize": "0.8rem"}}>
                  <TT>Total number of sites you manage</TT>
                </span>
              </ReactTooltip>
            </Label>
            <Col className="form-field" md={7}>
              <Control.text
                model=".numberSites"
                id="numberSites"
                name="numberSites"
                placeholder={toTTString("Number Of Sites")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  isNumber: (val) => { return !isEmpty(val) && isNumber(val) && val >=1 }
                }}
              />
              <Errors
                className="text-danger"
                model=".numberSites"
                show="touched"
                messages={{
                  isNumber: toTTString("Must be at least 1"),
                }}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col md={12} style={{'paddingLeft':'15px'}}>
              <Control.checkbox
                type="checkbox"
                label=""
                id="acceptTerms"
                className="fwcheckbox"
                name="acceptTerms"
                model=".acceptTerms"
                validators={{
                  accepted: (val) => val === true,
                }}
              />
              <label htmlFor="acceptTerms">
                <span></span>
                <TT>I agree to the</TT> <a href="https://flexiwan.com/legal/terms-of-service/" target="_blank" rel="noopener noreferrer">
                  <b><TT>Terms Of Service</TT></b> </a><TT>and</TT> <a href="https://flexiwan.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer"><b><TT>Privacy Policy</TT></b> </a>
              </label>
              <Errors
                className="text-danger"
                model=".acceptTerms"
                show = {{pristine:false}}
                messages={{
                  accepted: toTTString("Terms and Conditions must be accepted")
                }}
              />
            </Col>
          </Row>
          {captchaSiteKey !== ""?
          <Row className="form-group">
            <Col className="form-field" md={12}>
              <Control.text style={{'display': 'none', 'height':0, 'padding':0, 'margin':0, 'border':0}}
                id="captcha"
                name="captcha"
                model=".captcha"
                value={undefined}
                validators={{
                  selected: (token) => token !== '',
                }}
              />
              <ReCAPTCHA style={{'float':'right'}}
                ref={this.recaptchaRef}
                sitekey={captchaSiteKey}
                onChange={token => this.props.setForm2CaptchaToken(token,{'silent':true})}
                onExpired={() => this.props.setForm2CaptchaToken('',{'silent':true})}
                onErrored={() => this.props.setForm2CaptchaToken('',{'silent':true})}
              />
            </Col>
          </Row>:null}
          <Row className="form-group">
            <Col className="form-field" md={12}>
              <Control.button style={{'float':'right', 'minWidth':'460px'}}
                form={"userCreateAccount2"}
                type="submit"
                model="userCreateAccount2"
                disabled={{valid:false}}
                className="btn btn-primary"
              >
                <TT>Create</TT>
              </Control.button>
            </Col>
          </Row>
        </Form>
      ]
    }


    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />;
    }

    return (
      <>
      {this.state.completed?
      <div className="create-account-complete-text">
        <h1 >Please Wait ...</h1>
      </div>:
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Create Account</TT></BreadcrumbItem>
        </Breadcrumb>
        <div>
          <Card id="createAccountCard">
            <CardBody>
              <CardTitle>
                {this.state.formStep===1?null:
                <Button style={{'position':'absolute', 'left':'15px', 'top':'0.9rem'}}
                  color="info"
                  className="back-btn"
                  onClick={()=>{this.setState({formStep:1});}}
                >
                <FontAwesomeIcon icon="arrow-circle-left" fixedWidth />
                </Button>}                
                <TT>Create Account of</TT> flexi<font style={{'color':'#4c9c90'}}>Manage</font> (<TT params={{ stepNum: this.state.formStep }}>Step #stepNum#</TT>/2)
              </CardTitle>
              <hr />
              {items['step'+this.state.formStep]}
              <hr />
              <div className="bottom-card-message">
                <TT>Already have an account?</TT> <Link to="/login"><TT>Log In</TT></Link>
              </div>
            </CardBody>
          </Card>
        </div>
      </>}
      </>
    );
  }
}
CreateAccount.contextType = LanguageContext;
export default CreateAccount;
