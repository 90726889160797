import React, { Component } from "react";
import TimeSeriesGraph from '../items/TimeSeriesGraph';

/**
 * Health component class
 * Description:
 * Display the tunnel RTT and Drop rate graphs for 2 hours backward and refresh every 1 min
 */
class Health extends Component {

  render() {

    const colors = [
      '#4e79a7', '#f28e2c', '#e15759', '#76b7b2', '#59a14f',
      '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab'
    ]

    const lineInfoOptions = (type, num) => {
      const options = {
        'CPU':{
          'color':colors[num%colors.length],
          'yval': (d)=> {
            const cpu = (d.cpu)? +d.cpu[num]:0;
            return cpu;
          },
          'axisLocation': 'right',
          'axisText': 'CPU',
          'grid': 'width',
          'units': ' [%]'
        },
        'Mem':{
          'color':colors[0],
          'yval': (d)=> {
            const mem = (d.mem)? +d.mem:0;
            return mem;
          },
          'axisLocation': 'right',
          'grid': 'width',
          'units': ' [%]'
        },
        'Disk':{
          'color':colors[0],
          'yval': (d)=> {
            const disk = (d.disk)? +d.disk:0;
            return disk;
          },
          'axisLocation': 'right',
          'grid': 'width',
          'units': ' [%]'
        },
        'Temp':{
          'color':colors[0],
          'yval': (d)=> {
            const temp = (d.temp && d.temp.value)? +d.temp.value:0;
            return temp;
          },
          'axisLocation': 'right',
          'grid': 'width',
          'units': ' [DEG C]'
        }
      };
      return options[type];
    }

    const lineInfo = {};
    if (this.props.graphType === 'CPU' && this.props.traffic.length) {
      for (let i=this.props.graphBatchStart;i<this.props.graphBatchEnd;i++) {
        lineInfo['CPU'+i] = lineInfoOptions(this.props.graphType, i)
      }
    } else {
      lineInfo[this.props.graphType] = lineInfoOptions(this.props.graphType, 0);
    }

    return (
      <TimeSeriesGraph
        height={200}
        lastUpdate={this.props.lastUpdate}
        traffic={this.props.traffic}
        lineInfo={lineInfo}
        divId={this.props.divId}
      />
    );
  }
}

export default Health;
