import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Label, Row, Col } from "reactstrap";
import { TT, LanguageContext } from '../../../containers/Language';
import { verify2FA } from '../../../redux/reducers/Auth';
import { FWTextInput } from '../../Common';
import { Control, Form, actions, Errors } from 'react-redux-form';
import { required, isNumber } from '../../../utils/Validators'
import ReactTooltip from 'react-tooltip';
import BottomCard from '../Login/BottomCard';
import Loading from '../../items/Loading';

const formModel = "verifyMfa";

const Verify = props => {
  const { toTTString } = useContext(LanguageContext);

  const [loading, setLoading] = useState(false);
  const [failedCount, setFailedCount] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleVerification = values => {

    setLoading(true)
    props.verify2FA(
      values,
      () => {
        setLoading(false)
        setFailedCount(0);

        if (props.onVerified) {
          props.onVerified(props.isFirstValidation);
        }
      },
      () => {
        setLoading(false)
        setDisableSubmit(true)
        setFailedCount( failedCount + 1);

        if (failedCount >= 5 && props.onMaxAttempts) {
          return props.onMaxAttempts();
        }

        const t = setTimeout(() => {
          setDisableSubmit(false)
        }, 1000)
        setTimer(t)
      }
    );
  }

  // cleanup
  useEffect(() => {
    return function cleanup() {
      if (timer) {
        clearTimeout(timer);
      }
      props.resetForm()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form
      onSubmit={handleVerification}
      model={formModel}
      id="verifyMfaForm"
      autocomplete="off"
    >
      <Row className="form-group">
        <Label htmlFor="token" md={5} className="d-flex align-items-center justify-content-between">
          <TT>Verify token</TT>
          <span className="helpTooltip ml-2" data-tip data-for='tokenTip'></span>
          <ReactTooltip id='tokenTip'>
              <span style={{"fontSize": "0.8rem"}}>
                Enter the 2FA code from your authenticator application for this site
              </span>
          </ReactTooltip>
        </Label>
        <Col md={7}>
          <Control.text
            type="text"
            model=".token"
            component={FWTextInput}
            withFieldValue
            id="token"
            name="token"
            autoFocus
            autoComplete="off"
            validators={{
              required: required,
              isNumber: isNumber
            }}
          />
        </Col>
        <Col md={{ offset: 5, size: 7 }}>
          <Errors
            className="text-danger"
            model=".token"
            show="touched"
            messages={{
              required: toTTString('Required field'),
              isNumber: toTTString("Code must be numbers"),
            }}
          />
        </Col>
      </Row>

      <Row className="form-group">
        <Col md={12}>
          { loading ? (
            <div className='text-center'>
              <Loading size="3x" />
            </div>
          ) : (
            <Control.button
              form="verifyMfaForm"
              type="submit"
              model="verifyMfa"
              className={"btn btn-primary"}
              disabled={disableSubmit}
              style={{'minWidth':'460px'}}
            >
              {disableSubmit ? <Loading color="white"/> : <TT>Verify</TT>}
            </Control.button>
          )}
        </Col>
      </Row>

      { props.isWithinLoginCard ? (
        <BottomCard />
      ) : null}
    </Form>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    verify2FA: (data, successCb, errorCb) => dispatch(verify2FA(data, successCb, errorCb)),
    resetForm: () =>  dispatch(actions.reset(formModel)),
  }
}

export default connect(null, mapDispatchToProps)(Verify);