import * as Actions from "../Actions";
import * as qs from 'query-string';
import { FWFetch } from "../../utils/Network";
const { baseUrl } = window.SERVER_CONFIG;

export const Tickets = (
  state = {
    info: [],
    comments: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.TICKETS_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.TICKETS_GET_SUCCESS:
      return { ...state, info: action.tickets, isLoading: false };
    case Actions.TICKETS_GET_FAILURE:
      return { ...state, info: [], isLoading: false };
    case Actions.TICKET_COMMENTS_GET_REQUEST:
      return { ...state, comments:[], isLoading: true };
    case Actions.TICKET_COMMENTS_GET_SUCCESS:
      return { ...state, comments: action.comments, isLoading: false };
    case Actions.TICKET_COMMENTS_GET_FAILURE:
      return { ...state, comments: [], isLoading: false };

    default:
      return state;
  }
};

/**********************************************************************
 *   Get tickets
 **********************************************************************/
export const requestGetTickets = () => dispatch => {
  dispatch({
    type: Actions.TICKETS_GET_REQUEST
  });
};

export const receiveGetTickets = response => dispatch => {
  dispatch({
    type: Actions.TICKETS_GET_SUCCESS,
    tickets: response
  });
};

export const failureGetTickets = error => dispatch => {
  dispatch({
    type: Actions.TICKETS_GET_FAILURE
  });
};

export const getTickets = (page = 0, size, cb = null) => dispatch => {

  const params = { page, size };
  var encodedValues = qs.stringify(params);

  return dispatch(
    FWFetch({
      url: baseUrl + "tickets?" + encodedValues,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetTickets());
      },
      successCB: response => {
        dispatch(receiveGetTickets(response));
        if (cb) {
          cb();
        }
      },
      failureCB: error => {
        dispatch(failureGetTickets(error));
      }
    })
  );
};

/**********************************************************************
 *   Get ticket comments
 **********************************************************************/
 export const requestGetTicketComments = () => dispatch => {
  dispatch({
    type: Actions.TICKET_COMMENTS_GET_REQUEST
  });
};

export const receiveGetTicketComments = response => dispatch => {
  dispatch({
    type: Actions.TICKET_COMMENTS_GET_SUCCESS,
    comments: response
  });
};

export const failureGetTicketComments = error => dispatch => {
  dispatch({
    type: Actions.TICKET_COMMENTS_GET_FAILURE
  });
};

export const getTicketComments = (ticketId, cb = null) => dispatch => {

  return dispatch(
    FWFetch({
      url: baseUrl + "tickets/" + ticketId + "/comments",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetTicketComments());
      },
      successCB: response => {
        dispatch(receiveGetTicketComments(response));
        if (cb) {
          cb();
        }
      },
      failureCB: error => {
        dispatch(failureGetTicketComments(error));
      }
    })
  );
};
