import React, { Component } from "react";
import { Badge } from "reactstrap";
import Select from 'react-select';
import { LanguageContext } from '../../containers/Language'

class PathLabelSelectBox extends Component {
  static contextType = LanguageContext
  filter(option, searchText) {
    const { name } = option.data.orig || option.data;
    return name.toLowerCase().includes(searchText);
  }

  renderPathLabels(labels, badgeLabels) {
    const convertedLabels = (labels || []).map(label => {
      return {
        orig: { ...label },
        value: label._id,
        label: badgeLabels ? (
          <Badge
            style={{
              backgroundColor: label.color
            }}
            key={label._id}
            pill
          >
            {label.name}
          </Badge>
        ) : label.name
      };
    });
    return convertedLabels;
  }

  render() {
    const { id, values, options, placeholder, badgeLabels = true, ...rest } = this.props;
    const renderedValues = this.renderPathLabels(values, badgeLabels);
    const renderedOptions = this.renderPathLabels(options, badgeLabels);
    const toTTString = this.context.toTTString

    return (
      <Select
        placeholder={placeholder || toTTString("Select") + "..."}
        name={id}
        defaultValue={renderedValues}
        options={renderedOptions}
        isMulti={true}
        filterOption={this.filter}
        styles={badgeLabels ? {
          multiValue: styles => {
            return {
              ...styles,
              backgroundColor: "#f7f5f5"
            };
          }
        } : {} }
        {...rest}
      />
    );
  }
}

export default PathLabelSelectBox;
