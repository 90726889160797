import React, { Component, useContext } from "react";
import LanguageSelector from './LanguageSelector';
import {
  Navbar,
  NavbarBrand,
  Nav,
  Button,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import logoImg from "../assets/images/logot.png";
import menuImg from "../assets/images/icn_menu.png";
import logoutImg from "../assets/images/icn_logout.png";
import feedbackImg from "../assets/images/icn_feedback.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext  } from '../containers/Language'
import { getStorageToken } from "../utils/Token";
const jwt = require("jsonwebtoken");
const { companyName, feedbackUrl, removeBranding }  = window.SERVER_CONFIG;

// Device Card List
const AccountList = (props) => {
  const { toTTString } = useContext(LanguageContext);
  let accountArray = toTTString("Loading") + "...";
  if (props.accounts.length !== 0) {

    accountArray = props.accounts.map(account => {
      return (
        <DropdownItem className= {( account._id === props.auth.account) ? "header-account-list-highlight" :""}
          key={account._id}
          onClick={() => props.accountClicked(account._id)}>
          {account.name + " : " + account._id.substring(0,13)}
        </DropdownItem>
      )});
    accountArray.unshift(toTTString("Select Account"), <DropdownItem key={0} divider />);
  }

  return (
    <div className="header-account-list">
      {accountArray}
    </div>
  );
};

class Header extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = { accountDropdown: false }
    this.toggleNav = this.toggleNav.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);
    this.accountClicked = this.accountClicked.bind(this);
  }

  toggleNav() {
    this.props.mainSidebarToggle(this.props.auth.isAuthenticated);
    // Call resize events to make sure all window sizes are updated
    window.dispatchEvent(new Event("resize"));
  }

  handleLogout() {
    this.props.logoutUser();
  }

  toggleAccount() {

    // When opened, get user accounts
    if (this.state.accountDropdown === false) this.props.getAllAccounts();

    this.setState(prevState => ({
      accountDropdown: !prevState.accountDropdown
    }));

  }

  accountClicked(id) {
    const selected = this.props.accounts.accounts.find(a => a._id === id);
    const isAccountUsesMfa = selected?.forceMfa ?? false;
    const token = getStorageToken();
    if (token && !jwt.decode(token)?.mfaVerified && isAccountUsesMfa) {
      this.props.history.push({ pathname: '/members', search: "showMfaModal=true" })
      return;
    }

    this.props.selectAccount({account:id}, ()=>{});
  }

  render() {
    const navbarClassesNames = [];
    if (this.props.isLoading) {
      navbarClassesNames.push("loading");
    }

    return (
      <React.Fragment>
        <Navbar id="topbar" expand>
          <div style={{minWidth:"240px"}}>
            <Button
              onClick={this.toggleNav}
              onMouseDown={(e) => e.preventDefault()}
            >
              <img src={menuImg} height="14" width="25" alt="Toggle Menu" />
            </Button>
            <NavbarBrand href="/">
              <img src={logoImg} height="26" alt={companyName} />
            </NavbarBrand>
            {(companyName !== "flexiWAN" && !removeBranding) ? (
              <span class="powered-by"><TT>Powered by flexiWAN</TT></span>
            ) : (
              ""
            )}
          </div>
          <Nav navbar className="ml-auto">
              <LanguageSelector />
          </Nav>
          {this.props.auth.isAuthenticated ? (
            <React.Fragment>
              <Nav navbar>
                {feedbackUrl !== "" ? (
                  <React.Fragment>
                    <div className="feedback-link">
                      <a
                        href={feedbackUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-tip
                        data-for="feedback-a"
                      >
                        <img
                          src={feedbackImg}
                          height="30"
                          width="32"
                          alt="Feedback"
                        />
                      </a>
                      <ReactTooltip id="feedback-a" place="bottom">
                        <span><TT>Give Us Some Feedback</TT></span>
                      </ReactTooltip>
                    </div>
                    <div className="vertical-divider"></div>
                  </React.Fragment>
                ) : null}
                <Dropdown
                  right="true"
                  nav
                  inNavbar
                  setActiveFromChild
                  isOpen={this.state.accountDropdown}
                  toggle={this.toggleAccount}
                >
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon="user" fixedWidth />
                    <div className="topbar-account navbar-text">
                      <div>{this.props.auth.user}</div>
                      <div className="font-weight-bold">
                        {this.props.auth.accountName}
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right className="header-account-menu">
                    <AccountList 
                      acount ={this.props.account}                        
                      auth = {this.props.auth}  
                      accounts={this.props.accounts.accounts}
                      accountClicked={this.accountClicked}
                    />
                  </DropdownMenu>
                </Dropdown>
              </Nav>
              <Button
                onClick={this.handleLogout}
                onMouseDown={(e) => e.preventDefault()}
              >
                <img src={logoutImg} height="25" width="25" alt="Logout" />
              </Button>
            </React.Fragment>
          ) : (
            <div />
          )}
          <div className={navbarClassesNames.join(" ")} />
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
