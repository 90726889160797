import React, { Component } from "react";
import "../dashboards/Traffic.css";
import NetworkTraffic from "../dashboards/NetworkTraffic";
import Health from "../dashboards/Health";

class StatisticsCard extends Component {
  constructor(props) {
    super(props);

    this.readTimer = null;

    this.state = {
      lastUpdate: 0
    }

    this.updateTraffic = this.updateTraffic.bind(this);
    this.updateOnFocus = this.updateOnFocus.bind(this);
  }

  componentDidMount() {
    this.updateTraffic();

    if (this.readTimer === null) {
      console.log("StatisticsCard: Setting periodic timer");
      const timer = setInterval(function(that) {
          console.log("StatisticsCard periodic update");
          // Only update when in focus
          if (document.hasFocus()) {
              that.updateTraffic();
          }
      },60000, this);
      this.readTimer = timer;
    }
    window.addEventListener('focus', this.updateOnFocus);
  }

  componentWillUnmount() {
    if (this.readTimer != null) {
      clearInterval(this.readTimer);
      this.readTimer = null;
    }
    window.removeEventListener("focus", this.updateOnFocus);
  }

  updateTraffic() {
    const cb = ()=>this.setState({lastUpdate:new Date().getTime()});
    this.props.getTraffic(this.props.id, undefined, cb);
    this.props.getHealth(this.props.id, cb);
  }

  /**
   * Read and update graph when UI in focus
   * @return {None}
   */
  updateOnFocus() {
    this.updateTraffic();
  }

  render() {

    const CPU_BATCH_SIZE = 10;
    const cpuBatches = [];

    if (this.props.health.health && this.props.health.health.length) {
      const numCpus = (this.props.health.health[0].cpu)? this.props.health.health[0].cpu.length:0;
      const numCpuBatches = numCpus/CPU_BATCH_SIZE;
      for (let i=0;i<numCpuBatches;i++) {
        cpuBatches.push(
          <Health
            graphType={'CPU'}
            graphBatchStart={i*CPU_BATCH_SIZE}
            graphBatchEnd={Math.min(i*CPU_BATCH_SIZE+10, numCpus)}
            key={'cpu-status' + i}
            lastUpdate={this.state.lastUpdate}
            traffic={this.props.health.health}
            divId={'cpu-status' + i}
          />
        );
      }
      if (this.props.health.health[0].mem) {
        cpuBatches.push(
          <Health
            graphType={'Mem'}
            key={'mem-status'}
            lastUpdate={this.state.lastUpdate}
            traffic={this.props.health.health}
            divId='mem-status'
          />
        );
      }
      if (this.props.health.health[0].disk) {
        cpuBatches.push(
          <Health
            graphType={'Disk'}
            key={'disk-status'}
            lastUpdate={this.state.lastUpdate}
            traffic={this.props.health.health}
            divId='disk-status'
          />
        );
      }
      if (this.props.health.health[0].temp) {
        cpuBatches.push(
          <Health
            graphType={'Temp'}
            key={'temp-status'}
            lastUpdate={this.state.lastUpdate}
            traffic={this.props.health.health}
            divId='temp-status'
          />
        );
      }
    }

    return (
      <React.Fragment>
        <NetworkTraffic
          graphType={'rxtx'}
          lastUpdate={this.state.lastUpdate}
          traffic={this.props.traffic.traffic}
          divId='network-chart'
        />
        {cpuBatches}
      </React.Fragment>
    );
  }
}

export default StatisticsCard;
