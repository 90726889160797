import React, { Component } from "react";
import { Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TT, LanguageContext } from '../../../containers/Language'

class DHCPCard extends Component {
  static contextType = LanguageContext

  handleDelete = row => {
    const dhcpEntries = [...this.props.dhcpEntries];
    const index = dhcpEntries.findIndex(entry => {
      return entry._id === row._id;
    });
    if (index !== -1) {
      dhcpEntries.splice(index, 1);
      this.props.saveDHCP(dhcpEntries) // Should be moved to device info update button
      this.props.updateUserInputState('interfaces', true);
    }
  }

  render() {
    const toTTString = this.context.toTTString

    this.paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
        <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                    </span>),
      sizePerPageList: [{ text: '10', value: 10 }, { text: '50', value: 50 }, { text: '100', value: 100 }]
    };

    const DHCPColumn = [
      { text: toTTString("rowID"), dataField: "_id", hidden: true },
      {
        text: toTTString("Interface"),
        dataField: "interface",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          const ifByDevId = this.props.interfaces.find(
            i => i.devId === row.interface
          );

          if (!ifByDevId) {
            return toTTString("Unknown")
          };

          const same_addr = this.props.interfaces.filter(i => i.devId === ifByDevId.devId || (i.IPv4 === ifByDevId.IPv4 && i.isAssigned === 'Yes'))
          return same_addr.map(i => i.name).join(' / ')
        }
      },
      {
        text: toTTString("IP Range"),
        dataField: "rangeStart",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return `${row.rangeStart}-${row.rangeEnd}`;
        }
      },
      {
        text: toTTString("DNS"),
        dataField: "dns",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return row.dns.join(', ');
        }
      },
      {
        text: toTTString("MAC Assigned"),
        dataField: " ",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return (row.macAssign.length === 0)? toTTString("No"):toTTString("Yes");
        }
      },
      {
        text: toTTString('Actions'), dataField: 'none', sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
        formatter: (cellContent, row) => {
          return (
            <div>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="delete-a"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id="delete-a">
                <span><TT>Delete Entry</TT></span>
              </ReactTooltip>
            </div>
          );
        }
      }
    ];

    return (
      <React.Fragment>
        <h6><TT>DHCP Table</TT></h6>
        <BootstrapTable
          striped
          hover
          condensed
          keyField="_id"
          data={this.props.dhcpEntries}
          columns={DHCPColumn}
          pagination={paginationFactory(this.paginationOptions)}
          noDataIndication={toTTString("No DHCP Entries available")}
          defaultSorted={[{ dataField: "interface", order: "asc" }]}
        />
      </React.Fragment>
    );
  }
}

export default DHCPCard;