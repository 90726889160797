import React, { Component } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TT, LanguageContext } from '../../containers/Language'

class AppIdentifications extends Component {
  static contextType = LanguageContext
  componentDidMount() {
    this.props.getAllAppIdentifications();
    this.props.getInstalledAppIdentifications();
  }

  constructor(props) {
    super(props);

    this.state = {
      isDelModalOpen: false,
      isResetModalOpen: false,
      delEntity: null,
      resetEntity: {
        name: '',
      },
      expandedRowsCustom: [],
      expandedRowsImported: [],
    };

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.toggleResetModal = this.toggleResetModal.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
    this.updateAppIdentificationOnDevices = this.updateAppIdentificationOnDevices.bind(
      this
    );
    this.approveReset = this.approveReset.bind(this);
  }

  expandedColumns = [
    {
      text: <TT>IP</TT>,
      dataField: 'ip',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '34%', textAlign: 'left' };
      },
    },
    {
      text: <TT>Ports</TT>,
      dataField: 'ports',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '33%', textAlign: 'left' };
      },
    },
    {
      text: <TT>Protocol</TT>,
      dataField: 'protocol',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '33%', textAlign: 'left' };
      },
    },
  ];

  toggleDelModal() {
    this.setState({
      isDelModalOpen: !this.state.isDelModalOpen,
    });
  }

  handleDelete(row) {
    console.log('handleDelete: ' + JSON.stringify(row));
    this.setState({ delEntity: row });
    this.toggleDelModal();
  }
  approveDelete() {
    console.log(
      'Delete clicked for appIdentification id: ' + this.state.delEntity.id
    );
    this.toggleDelModal();
    this.props.delAppIdentification(this.state.delEntity, () => {
      this.props.getAllAppIdentifications();
      this.props.getInstalledAppIdentifications();
    });
  }

  updateAppIdentificationOnDevices() {
    if (!this.props.appIdentifications.installed.devices) return;
    const toUpdate = this.props.appIdentifications.installed.devices.reduce(
      (obj, dev) => {
        if (dev.status === 'install') obj.toInstall[dev._id] = true;
        if (dev.status === 'uninstall') obj.toUninstall[dev._id] = true;
        return obj;
      },
      { toInstall: {}, toUninstall: {} }
    );
    if (Object.keys(toUpdate.toInstall).length > 0) {
      this.props.appIdentificationUpdateDevices(
        toUpdate.toInstall,
        true,
        () => {}
      );
    }
    if (Object.keys(toUpdate.toUninstall).length > 0) {
      this.props.appIdentificationUpdateDevices(
        toUpdate.toUninstall,
        false,
        () => {}
      );
    }
  }
  toggleResetModal() {
    this.setState({
      isResetModalOpen: !this.state.isResetModalOpen,
    });
  }
  handleReset(row) {
    console.log('handleReset: ' + JSON.stringify(row));
    this.setState({ resetEntity: row });
    this.toggleResetModal();
  }
  approveReset() {
    console.log(
      'Reset clicked for appIdentification id: ' + this.state.resetEntity.id
    );
    this.toggleResetModal();
    this.props.resetAppIdentification(
      this.state.resetEntity,
      this.props.getAllAppIdentifications
    );
  }

  render() {
    const toTTString = this.context.toTTString
    const handleMoreCustomButtonClick = (id) => {
      if (!this.state.expandedRowsCustom.includes(id)) {
        this.setState(() => ({
          expandedRowsCustom: [...this.state.expandedRowsCustom, id],
        }));
        return;
      }
      this.setState(() => ({
        expandedRowsCustom: this.state.expandedRowsCustom.filter(
          (x) => x !== id
        ),
      }));
    };

    const handleMoreImportedButtonClick = (id) => {
      if (!this.state.expandedRowsImported.includes(id)) {
        this.setState(() => ({
          expandedRowsImported: [...this.state.expandedRowsImported, id],
        }));
        return;
      }
      this.setState(() => ({
        expandedRowsImported: this.state.expandedRowsImported.filter(
          (x) => x !== id
        ),
      }));
    };

    const importanceFormatter = (cell, _row, _rowIndex, _formatExtraData) => {
      if (cell === 'high') {
        return <span className="text-danger"><TT>high</TT></span>;
      }
      if (cell === 'medium') {
        return <span className="text-warning"><TT>medium</TT></span>;
      }
      if (cell === 'low') {
        return <span className="text-info"><TT>low</TT></span>;
      }

      return <span><TT>{cell}</TT></span>;
    };

    const modifiedFormatter = (cell, _row, _rowIndex, _formatExtraData) => {
      if (_row.modified === true) {
        return <span>{`*${cell}`}</span>;
      }
      return <span>{cell}</span>;
    };

    const expandRowImported = {
      renderer: (row) => (
        <div className="col-md-12">
          <BootstrapTable
            striped
            hover
            condensed
            keyField="_id"
            data={row.rules}
            columns={this.expandedColumns}
            pagination={paginationFactory(rulesPaginationOptions)}
            noDataIndication={toTTString("No rules available")}
            defaultSorted={[{ dataField: 'ip', order: 'asc' }]}
          />
        </div>
      ),
      expanded: this.state.expandedRowsImported,
      expandByColumnOnly: true,
    };

    const expandRowCustom = {
      renderer: (row) => (
        <div className="col-md-12">
          <BootstrapTable
            striped
            hover
            condensed
            keyField="_id"
            data={row.rules}
            columns={this.expandedColumns}
            pagination={paginationFactory(rulesPaginationOptions)}
            noDataIndication={toTTString("No rules available")}
            defaultSorted={[{ dataField: 'ip', order: 'asc' }]}
          />
        </div>
      ),
      expanded: this.state.expandedRowsCustom,
      expandByColumnOnly: true,
    };

    const columnsImported = [
      {
        text: <TT>Name</TT>,
        dataField: 'name',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
        formatter: modifiedFormatter,
      },
      {
        text: <TT>Description</TT>,
        dataField: 'description',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Category</TT>,
        dataField: 'category',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Service Class</TT>,
        dataField: 'serviceClass',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Importance</TT>,
        dataField: 'importance',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
        align: 'center',
        formatter: importanceFormatter,
      },
      {
        text: <TT>Actions</TT>,
        dataField: 'actions',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
        align: <TT>center</TT>,
        formatter: (_cellContent, row, _rowIndex, formatExtraData) => {
          return (
            <div>
              <Link to={'/appidentifications/customize/' + row.id}>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for="clone-a"
                  size="sm"
                >
                  <FontAwesomeIcon icon="clone" fixedWidth />
                </Button>
              </Link>
              <ReactTooltip id="clone-a">
                <span><TT>Customize App Identification</TT></span>
              </ReactTooltip>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="reset-a"
                size="sm"
                onClick={() => this.handleReset(row)}
                disabled={row.modified === false}
              >
                <FontAwesomeIcon icon="redo" fixedWidth />
              </Button>
              <ReactTooltip id="reset-a">
                <span><TT>Reset App Identification</TT></span>
              </ReactTooltip>
              <Button
                color="info"
                className="action-btn"
                size="sm"
                data-tip
                data-for="more"
                onClick={() => handleMoreImportedButtonClick(row.id)}
              >
                <FontAwesomeIcon
                  icon={
                    formatExtraData.includes(row.id)
                      ? 'ellipsis-h'
                      : 'ellipsis-v'
                  }
                  fixedWidth
                />
              </Button>
              <ReactTooltip id="more">
                <span><TT>More</TT></span>
              </ReactTooltip>
            </div>
          );
        },
        // passes the expanded rows state as formatExtraData parameter, so the expand
        // icon of particular row will be modified whenever the expanded rows state is changed
        formatExtraData: this.state.expandedRowsImported,
      },
    ];

    const columnsCustom = [
      {
        text: <TT>Name</TT>,
        dataField: 'name',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Description</TT>,
        dataField: 'description',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Category</TT>,
        dataField: 'category',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Service Class</TT>,
        dataField: 'serviceClass',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Importance</TT>,
        dataField: 'importance',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
        align: 'center',
        formatter: importanceFormatter,
      },
      {
        text: <TT>Actions</TT>,
        dataField: 'actions',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'left' };
        },
        align: <TT>center</TT>,
        formatter: (_cellContent, row, _rowIndex, formatExtraData) => {
          return (
            <div>
              <Link to={'/appidentifications/update/' + row.id}>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for="update-a"
                  size="sm"
                >
                  <FontAwesomeIcon icon="cog" fixedWidth />
                </Button>
              </Link>
              <ReactTooltip id="update-a">
                <span><TT>Update App Identification</TT></span>
              </ReactTooltip>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="delete-a"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id="delete-a">
                <span><TT>Delete App Identification</TT></span>
              </ReactTooltip>
              <Button
                color="info"
                className="action-btn"
                size="sm"
                data-tip
                data-for="more"
                onClick={() => handleMoreCustomButtonClick(row.id)}
              >
                <FontAwesomeIcon
                  icon={
                    formatExtraData.includes(row.id)
                      ? 'ellipsis-h'
                      : 'ellipsis-v'
                  }
                  fixedWidth
                />
              </Button>
              <ReactTooltip id="more">
                <span><TT>More</TT></span>
              </ReactTooltip>
            </div>
          );
        },
        // passes the expanded rows state as formatExtraData parameter, so the expand
        // icon of particular row will be modified whenever the expanded rows state is changed
        formatExtraData: this.state.expandedRowsCustom,
      },
    ];

    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 1,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    const rulesPaginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 1,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      selected: [],
      onSelect: (row, isSelect) => {
        console.log('onSelect: ' + JSON.stringify(row) + ', ' + isSelect);
      },
      onSelectAll: (isSelect, rows) => {
        console.log('onSelectAll: ' + JSON.stringify(rows) + ', ' + isSelect);
      },
    };

    // TODO: figure out locale and date/time format
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    const importedUpdatedAt =
      this.props.appIdentifications.meta.importedUpdatedAt === ''
        ? (<TT>Not updated yet</TT>)
        : (<TT params={{lastUpdated: new Date(
          this.props.appIdentifications.meta.importedUpdatedAt
        ).toLocaleDateString('en-US', options)}}>Last update on #lastUpdated#</TT>);
    const customUpdatedAt =
      this.props.appIdentifications.meta.customUpdatedAt === ''
        ? (<TT>Not updated yet</TT>)
        : (<TT params={{lastUpdated: new Date(
          this.props.appIdentifications.meta.customUpdatedAt
        ).toLocaleDateString('en-US', options)}}>Last update on #lastUpdated#</TT>);

    const importedAppIdentifications = this.props.appIdentifications.appIdentifications.filter(
      (app) => app.name.startsWith('custom') === false
    );
    const customAppIdentifications = this.props.appIdentifications.appIdentifications.filter(
      (app) => app.name.startsWith('custom') === true
    );

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Inventory</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>App Identifications</TT></BreadcrumbItem>
        </Breadcrumb>
        {/* Update App Identifications Button */}
        {this.props.appIdentifications.installed.totalNotUpdated ? (
          <div
            className="alert alert-warning col-md-12"
            style={{
              marginTop: '1rem',
              paddingBottom: '1.2rem',
              paddingTop: '1rem',
            }}
          >
            <FontAwesomeIcon
              icon="exclamation-triangle"
              size="1x"
              fixedWidth
              style={{ paddingRight: '3px' }}
              color="#ebbc02"
            />
            <TT params={{num: this.props.appIdentifications.installed.totalNotUpdated}}>Some (#num#)
            devices are not updated, click to update</TT>
            <Button
              className="btn-primary btn-right"
              onClick={this.updateAppIdentificationOnDevices}
            >
              <TT>Update App Identification</TT>
            </Button>
          </div>
        ) : null}
        {/* Custom App Identifications part */}
        <div className="container">
          <h4><TT>Custom App Identifications</TT></h4>
          {this.props.appIdentifications.isLoading ? (
            <div className="signal"></div>
          ) : null}
          <span className="title-small">{customUpdatedAt}</span>
        </div>
        <div className="col-md-12">
          <div className="mb-3">
          <Link to="/appidentifications/add">
            <Button color="success" className="action-btn-top" size="sm">
              <TT>New App Identification</TT>
            </Button>
          </Link>
          </div>
          <BootstrapTable
            striped
            hover
            condensed
            keyField="id"
            data={customAppIdentifications}
            columns={columnsCustom}
            expandRow={expandRowCustom}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={toTTString("No app identifications available")}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            selectRow={selectRow}
          />
        </div>
        {/* Imported App Identifications part */}
        <div className="container">
          <h4><TT>Imported App Identifications</TT></h4>
          {this.props.appIdentifications.isLoading ? (
            <div className="signal"></div>
          ) : null}
          <span className="title-small">{importedUpdatedAt}</span>
        </div>
        <div className="col-md-12">
          <BootstrapTable
            striped
            hover
            condensed
            keyField="id"
            data={importedAppIdentifications}
            columns={columnsImported}
            expandRow={expandRowImported}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={toTTString("No app identifications available")}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            selectRow={selectRow}
          />
        </div>
        <Modal isOpen={this.state.isDelModalOpen} toggle={this.toggleDelModal}>
          <ModalHeader toggle={this.toggleDelModal}>
            <TT>Delete App Identification</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT>Are you sure to delete app identification?</TT>
            </div>
            <Button color="danger" onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleDelModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isResetModalOpen}
          toggle={this.toggleResetModal}
        >
          <ModalHeader toggle={this.toggleResetModal}>
            <TT>Reset App Identification</TT>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3"><TT params={{name: this.state.resetEntity.name}}>Are you sure to reset #name# app identification to default settings?</TT></div>
            <Button color="danger" onClick={this.approveReset}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleResetModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
export default AppIdentifications;
