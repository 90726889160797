import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  Button,
  Row,
  Label,
  Col,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Collapse
} from "reactstrap";
import { Control, Form, Errors, actions } from 'react-redux-form';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _uniqueId from 'lodash/uniqueId';
import {
  required,
  isIP4,
  IPv4FieldValidator,
  isNumber,
  isValidPrefixLength,
  isOverlap
} from '../../utils/Validators';
import { FWTextInput } from '../Common';
import { getTunnelIPs } from '../../utils/TunnelsUtils';
import { getBridgedInterface } from '../../utils/DevicesUtils';
import PathLabelBadge from '../pathlabels/PathLabelBadge';
import SearchableSelectBox from '../items/SearchableSelectBox';
import { TT, LanguageContext } from '../../containers/Language'
import cloneDeep from 'lodash/cloneDeep';
import { isDeviceVersionGreaterEquals } from "../../utils/DevicesUtils";

class StaticRoutesCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.state = {
      selectedRoutes: [],
      isDevActionsOpen: false,
      isTunnelGateway: false,
      isAdvancedOpen: false,
      isConditionRequired: false
    };

    this.toggleAddStaticEntry = this.toggleAddStaticEntry.bind(this);
    this.getInterfacesList = this.getInterfacesList.bind(this);
    this.handleAddStaticEntry = this.handleAddStaticEntry.bind(this);
    this.handleChangeAction = this.handleChangeAction.bind(this);
  }

  handleDelete(row) {
    const updatedStaticRoutes = cloneDeep(this.props.staticroutes);
    const index = updatedStaticRoutes.findIndex(route => {
      return route._id === row._id;
    })
    if (index !== -1) updatedStaticRoutes.splice(index, 1);

    this.props.saveDeviceStaticRoutes(updatedStaticRoutes)
    this.props.updateUserInputState('general', true);
  }

  componentWillUnmount() {
    this.props.resetForm('addStaticRoute')()
  }

  toggleAddStaticEntry() {
    if (this.state.isAddStaticEntryModalOpen) {
      this.props.resetForm('addStaticRoute')()
    }

    this.setState({
      isAddStaticEntryModalOpen: !this.state.isAddStaticEntryModalOpen,
      isAdvancedOpen: false
    });
  }

  handleAddStaticEntry() {
    this.toggleAddStaticEntry();
  }

  handleRedistributeChanges = (field, val) => {
    const updatedStaticRoutes = cloneDeep(this.props.staticroutes);
    this.state.selectedRoutes.forEach(routeId => {
      const idx = updatedStaticRoutes.findIndex(r => r._id === routeId);
      updatedStaticRoutes[idx][field] = val;
    })

    this.props.saveDeviceStaticRoutes(updatedStaticRoutes)
    this.setState({selectedRoutes: []});
  }

  approveAddStaticEntry(data) {
    if (
      !data?.conditions?.[0]?.type &&
      !data?.conditions?.[0]?.via &&
      !data?.conditions?.[0]?.destination
    ) {
      data.conditions = [];
    }

    this.toggleAddStaticEntry();
    document.getElementById("add-static-route-form").reset();
    const tempId = _uniqueId('tempId-');
    const updatedStaticRoutes = cloneDeep(this.props.staticroutes);
    updatedStaticRoutes.push({ _id: tempId, ...data });
    this.props.saveDeviceStaticRoutes(updatedStaticRoutes)
    this.props.updateUserInputState('general', true);
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        selectedRoutes: [...this.state.selectedRoutes, row._id]
      });
    } else {
      this.setState({
        selectedRoutes: this.state.selectedRoutes.filter(id => id !== row._id)
      });
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ selectedRoutes: rows.map(r => r._id) });
    } else {
      this.setState({ selectedRoutes: [] });
    }
  }

  toggleDevActionsDropDown = () => {
    this.setState({
      isDevActionsOpen: !this.state.isDevActionsOpen
    });
  }

  getInterfacesList() {
    // we filter out bridged interfaces since we don't set ip on these interfaces.
    // a user can set the static route for bridge IP only without specifying the interface name
    return this.props.interfaces.filter(i => {
      if (!i.IPv4) return false;
      const isBridged = getBridgedInterface(i, this.props.interfaces);
      return isBridged.length === 0; // we show only non bridged interfaces
    }).sort((a, b) => a.name > b.name ? 1 : -1);
  }

  getIfcsAndTunnels = (allowLan, allowUnassigned) => {
    const interfaces = [];
    const tunnels = [];

    this.props.interfaces.forEach(i => {
      if (!allowUnassigned && i.isAssigned === 'No') {
        return
      }

      if (i.type === 'LAN') {
        if (!allowLan) {
          return;
        }

        if (!i.IPv4) {
          return;
        }

        interfaces.push({
          name: i.name,
          ip: i.IPv4,
          dev: { devId: i.devId },
        });
        return
      }

      if (i.gateway === ''){
        return
      }

      interfaces.push({
        name: i.name,
        ip: i.gateway,
        dev: { devId: i.devId },
      });
    })

    const currentDeviceId = this.props.id;
    this.props.deviceTunnels.forEach(t => {
      const toDevice = t.peer ? t.peer.name : (t.deviceA._id === currentDeviceId) ? t.deviceB.name: t.deviceA.name;
      const { ip1, ip2 } = getTunnelIPs(t.num, t.org.tunnelRange);
      const ip = t.peer ? ip1 : t.deviceA._id === currentDeviceId ? ip2 : ip1;

      tunnels.push({
        name: `${t.peer ? 'Peer ' : ''}Tunnel#${t.num} to ${toDevice}`,
        ip: ip,
        dev: { tunnelId: t.num },
        pathlabel: t.pathlabel
      })
    });

    return { interfaces, tunnels };
  }

  getConditionsVia = () => {
    const result = [];

    const { interfaces, tunnels } = this.getIfcsAndTunnels(true, false);
    interfaces.forEach(ifc => {
      result.push({
        label: ifc.name + ` (${ifc.ip})`,
        value: ifc.dev
      })
    })

    tunnels.forEach(tunnel => {
      const pathLabelJSX = this.getPathLabelJsx(tunnel)
      result.push({
        label: [
          `${tunnel.name}`,
          pathLabelJSX ? <span>{pathLabelJSX}</span> : null,
          `(${tunnel.ip})`
        ],
        key: tunnel.dev.tunnelId,
        value: tunnel.dev
      })
    })

    return result;
  }

  getPathLabelJsx = (tunnel) => {
    return tunnel.pathlabel
      ? <PathLabelBadge size={'sm'} name={tunnel.pathlabel.name} key={tunnel.pathlabel.name} color={tunnel.pathlabel.color}/>
      : null;
  }

  getGateways = () => {
    const result = [];

    const { interfaces, tunnels } = this.getIfcsAndTunnels(false, true);
    interfaces.forEach(ifc => {
      result.push({
        label: [
          ifc.ip + ' - ',
          <span>{ifc.name}</span>
        ],
        value: ifc.ip
      })
    })

    tunnels.forEach(tunnel => {
      const pathLabelJSX = this.getPathLabelJsx(tunnel)
      result.push({
        label:[
          tunnel.ip + ' - ' + tunnel.name,
          pathLabelJSX ? <span>{pathLabelJSX}</span> : null,
        ],
        key: tunnel.dev.tunnelId,
        value: tunnel.ip
      })
    })
    return result;
  }

  handleChangeAction(model, value) {
    const val = value?.value;
    let resetInterface = false;
    if (model === 'addStaticRoute.gateway') {
      let start = this.props.deviceTunnels[0]?.org.tunnelRange.split('.')
      start = `${start?.[0]}.${start?.[1]}.`

      //  We can assume that if there are no tunnels, the IP is for a regular interface and not for a tunnel
      if (this.props.deviceTunnels && val && val.startsWith(start)) {
        this.setState({ isTunnelGateway: true})
        resetInterface = true;
      } else {
        this.setState({ isTunnelGateway: false})
      }
    }
    return (dispatch) => {
      if (resetInterface) {
        dispatch(actions.change('addStaticRoute.ifname', ''));
      }
      dispatch(actions.change(model, val ? val : value));
    };
  }

  render() {
    const interfacesList = this.getInterfacesList();
    const toTTString = this.context.toTTString

    this.paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">
        <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
                                                    </span>),
      sizePerPageList: [{ text: '10', value: 10 }, { text: '50', value: 50 }, { text: '100', value: 100 }]
    };

    const staticRoutesColumn = [
      { text: toTTString('rowID'), dataField: "_id", hidden: true },
      {
        text: toTTString('Destination'),
        dataField: "destination",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "15%", textAlign: "left" };
        }
      },
      {
        text: toTTString('Gateway'),
        dataField: "gateway",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "13%", textAlign: "left" };
        }
      },
      {
        text: toTTString('Interface'),
        dataField: "ifname",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (ifname, row) => {
          const ifc = this.props.interfaces.find(i => i.devId === ifname);
          return (
            <>
              <div>{ifc ? ifc.name : ifname}</div>
              {row.onLink ? <div><small className="text-muted"> Use "onlink" enabled</small></div> : null}
            </>
          )
        }
      },
      {
        text: toTTString('Metric'),
        dataField: "metric",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        }
      },
      {
        text: toTTString('Redistribution'),
        isDummyField: true,
        dataField: 'redistribution',
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex, ) => {
          return { width: "13%", textAlign: "left" };
        },
        formatter: (cell, row) => {
          return (
            <>
              {row.redistributeViaOSPF ? (
                <Badge color="success">
                  {toTTString("OSPF")}
                </Badge>
              ) : null}
              {row.redistributeViaBGP ? (
                <Badge className="ml-2" color="success">
                  {toTTString("BGP")}
                </Badge>
              ) : null}
            </>
          )
        },
      },
      {
        text: toTTString('Advanced'),
        dataField: 'conditions',
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex, ) => {
          return { width: "20%", textAlign: "left" };
        },
        style: { lineHeight: 'normal' },
        classes: 'pt-1 pb-1',
        formatter: (cell, row) => {
          if (cell?.length > 0) {
            // if no condition
            if (!cell[0].destination) return;

            let via = 'N/A';
            if (cell[0].via?.devId) {
              const ifc = this.props.interfaces.find(i => i.devId === cell[0].via?.devId);
              if (ifc) {
                via = ifc.name
              }
            } else {
              const tunnel = this.props.deviceTunnels.find(t => t.num === cell[0].via?.tunnelId);
              if (tunnel) {
                const { ip1, ip2 } = getTunnelIPs(tunnel.num, tunnel.org.tunnelRange);
                const ip = tunnel.peer ? ip1 : tunnel.deviceA._id === this.props.id ? ip2 : ip1;
                const pathLabelJSX = this.getPathLabelJsx(tunnel);

                via = [`Tunnel ${tunnel.num} - ${ip} - `, pathLabelJSX ? <span>{pathLabelJSX}</span> : null]
              }
            }

            const type = cell[0].type === 'route-exist' ? 'Exist' : 'Not Exist';
            return (
              <>
                <small><b><u>Condition</u></b></small>
                <br />
                <small><b>Dest:</b> {cell[0].destination}</small>
                <br />
                <small><b>Via:</b> {via}</small>
                <br />
                <small><b>Install if route in condition:</b> {type}</small>
              </>
            )
          }

          return;
        }
      },
      {
        text: toTTString("Status"),
        dataField: 'status',
        sort: false,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "left" };
        },
        formatter: (cell, row) => {
          if (row?._id.startsWith('tempId')) {
            return null
          };
          return (
          <Badge color={row.isPending ? "warning" : "success"}>
            {row.isPending ? (
              <>
                <span data-tip data-for={`pending-${row._id}`}><TT>Pending</TT></span>
                <ReactTooltip id={`pending-${row._id}`}>
                  <span>
                    <TT>{row.pendingReason}</TT>
                  </span>
                </ReactTooltip>
                </>
            ) : <TT>Active</TT>}
          </Badge>
          )
        }
      },
      {
        text: toTTString('Actions'),
        dataField: 'none',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '8%', textAlign: 'left' };
        },
        formatter: (cellContent, row) => {
          return (
            <React.Fragment>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="delete-a"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id="delete-a">
                <span>
                  <TT>Warning</TT>:<br></br> <TT>deleting a static route may cause
                  network misbehavior</TT>
                </span>
              </ReactTooltip>
            </React.Fragment>
          );
        }
      }
    ];

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      selected: this.state.selectedRoutes,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    return (
      <React.Fragment>
        <div>
          <h6><TT>Static Routing Table</TT></h6>
          <Row className="align-items-center mb-3">
            <Col className="col-auto pr-0">
            <Button
              onClick={this.handleAddStaticEntry}
              className={"btn btn-primary"}
            >
              <TT>Add Route</TT>
            </Button>
            </Col>
            {this.state.selectedRoutes.length >= 1?
            <Col>
              <Dropdown
                size="md"
                isOpen={this.state.isDevActionsOpen}
                toggle={this.toggleDevActionsDropDown}
              >
                <DropdownToggle caret className="btn-success">
                  <TT>Actions</TT>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header><TT>OSPF</TT></DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleRedistributeChanges('redistributeViaOSPF', true)}
                  >
                    <TT>Redistribute via OSPF</TT>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleRedistributeChanges('redistributeViaOSPF', false)}
                  >
                    <TT>Disable redistribute via OSPF</TT>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem header><TT>BGP</TT></DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleRedistributeChanges('redistributeViaBGP', true)}
                  >
                    <TT>Redistribute via BGP</TT>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleRedistributeChanges('redistributeViaBGP', false)}
                  >
                    <TT>Disable redistribute via BGP</TT>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </Col>
              : null
            }
          </Row>
        </div>
        <BootstrapTable
          wrapperClasses={'scroll-x'}
          striped
          hover
          condensed
          keyField="_id"
          data={this.props.staticroutes}
          columns={staticRoutesColumn}
          pagination={paginationFactory(this.paginationOptions)}
          noDataIndication={toTTString("No Static Routes available")}
          defaultSorted={[{ dataField: "interface", order: "asc" }]}
          selectRow={ selectRow }
        />
        <Modal
          isOpen={this.state.isAddStaticEntryModalOpen}
          toggle={this.toggleAddStaticEntry}
          size={"lg"}
        >
          <ModalHeader toggle={this.toggleAddStaticEntry}>
            <TT>Add Static Entry</TT>
          </ModalHeader>
          <ModalBody>
            <Card id="addStaticRouteCard">
              <CardBody>
                <Form
                  id="add-static-route-form"
                  model="addStaticRoute"
                  onSubmit={(values) => {
                    this.approveAddStaticEntry(cloneDeep(values));
                  }}
                  onChange={values => {
                    if (
                      values?.conditions?.[0]?.type ||
                      values?.conditions?.[0]?.via ||
                      values?.conditions?.[0]?.destination
                    ) {
                      this.setState({ isConditionRequired: true })
                      // this.props.validateConditions()
                    } else {
                      this.setState({ isConditionRequired: false })
                    }
                  }}
                  validators={{
                    'conditions[0].destination': {
                      required: val => !this.state.isConditionRequired ? true: required(val)
                    },
                    'conditions[0].type': {
                      required: val => !this.state.isConditionRequired ? true: required(val)
                    },
                    'conditions[0].via': {
                      required: val => {
                        return !this.state.isConditionRequired ? true: required(val?.devId ?? val?.tunnelId)
                      }
                    },
                    '': {
                      gatewayInSubnet: values => {
                        if (!values.gateway || !isIP4(values.gateway)) {
                          return true;
                        }

                        if (values.onLink) {
                          return true;
                        }

                        const gatewaySubnet = `${values.gateway}/32`
                        if (values.ifname) {
                          const ifc = this.props.interfaces.find(i => i.devId === values.ifname);

                          // this.props.interfaces is the interfaces table and not the device interfaces that comes from the server.
                          // In this array we store the mask includes in the ifc.IPv4 field (10.0.0.1/24).
                          // But in the array from server, the IP and mask are separated into two fields.
                          return isOverlap(ifc.IPv4, gatewaySubnet)
                        } else {
                          let valid = this.props.interfaces.filter(ifc => ifc.IPv4).some(ifc => {
                            return isOverlap(ifc.IPv4, gatewaySubnet)
                          });
                          if (!valid) {
                            valid = this.props.deviceTunnels.some(tunnel => {
                              const { ip1 } = getTunnelIPs(tunnel.num, tunnel.org.tunnelRange);
                              return isOverlap(`${ip1}/31`, gatewaySubnet)
                            });
                          }
                          return valid;
                        }
                      }
                    }
                  }}
                >
                  <Row className="form-group">
                    <Label htmlFor="destination" md={4}>
                      <span className="form-required-star">*</span>
                      <TT>Destination</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        type="text"
                        model=".destination"
                        id="destination"
                        name="destination"
                        placeholder={toTTString("Destination Network/Mask")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          destination: IPv4FieldValidator,
                          prefixLen: isValidPrefixLength
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".destination"
                        show="touched"
                        messages={{
                          required: toTTString("Required field"),
                          destination: toTTString("Invalid IPv4 Network Format"),
                          prefixLen: toTTString("Invalid prefix for given mask"),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Label htmlFor="gateway" md={4}>
                      <span className="form-required-star">*</span>
                      <TT>Gateway IP</TT>
                    </Label>
                    <Col md={8}>
                      <Control
                        model=".gateway"
                        id="gateway"
                        name="gateway"
                        placeholder={toTTString("Gateway IP / Tunnel")}
                        changeAction={this.handleChangeAction}
                        component={SearchableSelectBox}
                        creatable={true}
                        options={this.getGateways()}
                        formatCreateLabel=""
                        validators={{
                          required: required,
                          gateway: isIP4,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".gateway"
                        show="touched"
                        messages={{
                          required: toTTString("Required field"),
                          gateway: toTTString("Invalid IPv4 Address"),
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model="addStaticRoute"
                        show="touched"
                        messages={{
                          gatewayInSubnet: toTTString("Interface IP and gateway are not on the same subnet")
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Label htmlFor="" md={4}>
                      <TT>Interface</TT>
                    </Label>
                    <Col md={8}>
                      <Control.select
                        model=".ifname"
                        id="ifname"
                        name="ifname"
                        placeholder=""
                        className="form-control"
                        disabled={!interfacesList.length || this.state.isTunnelGateway}
                      >
                        <option key="" value={""}>{toTTString("Select Interface")}</option>

                        {interfacesList.map(option =>
                          <option value={option.devId} key={option.devId}>
                            {option.name} ({option.IPv4})
                          </option>
                          )
                        }
                      </Control.select>
                    </Col>
                  </Row>

                  { this.props.form.ifname !== '' ? (
                    <Row className="form-group">
                      <Label htmlFor="onLink" md={4}>
                        <TT>Set</TT> "onlink" <span className="helpTooltip" data-tip data-for='onLinkTip'></span>
                        <ReactTooltip id='onLinkTip'>
                          <span style={{"fontSize": "0.8rem"}}>
                            <TT>Pretend that the 'nexthop' is directly attached to this link, even if it does not match any interface prefix.</TT>
                          </span>
                        </ReactTooltip>
                      </Label>
                      <Col md={8}>
                        <Label className="FWswitch">
                          <Control.checkbox
                            model=".onLink"
                            id="onLink"
                            name="onLink"
                          />{" "}
                          <span className="FWslider round" />
                        </Label>
                      </Col>
                    </Row>
                  ) : null}

                  <Row className="form-group">
                    <Label htmlFor="gateway" md={4}>
                      <TT>Metric</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        type="text"
                        model=".metric"
                        id="metric"
                        name="metric"
                        placeholder={toTTString("Metric")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          number: isNumber,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".metric"
                        show="touched"
                        messages={{
                          number: toTTString("Must be number"),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Label htmlFor="redistributeViaOSPF" md={4}>
                      <TT>Redistribute via OSPF</TT>
                    </Label>
                    <Col md={8}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".redistributeViaOSPF"
                          id="redistributeViaOSPF"
                          name="redistributeViaOSPF"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="redistributeViaBGP" md={4}>
                      <TT>Redistribute via BGP</TT>
                    </Label>
                    <Col md={8}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".redistributeViaBGP"
                          id="redistributeViaBGP"
                          name="redistributeViaBGP"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  { this.props?.device?.versions?.agent && isDeviceVersionGreaterEquals(this.props?.device?.versions?.agent, '6.3.30') ? (
                    <Row className="form-group">
                      <Label md={4}>
                        <Button
                          color="warning"
                          className="btn btn-primary btn mt-2 btn-secondary d-flex align-items-center"
                          size="sm"
                          type="button"
                          onClick={() => this.setState({ isAdvancedOpen: !this.state.isAdvancedOpen})}
                        >
                          <TT>Advanced</TT>
                          <FontAwesomeIcon icon={this.state.isAdvancedOpen ? "caret-up" : "caret-down"} style={{height:"1.15em"}} fixedWidth />
                        </Button>
                      </Label>
                    </Row>
                  ) : null}

                  <Collapse
                    isOpen={this.state.isAdvancedOpen}
                  >
                    <hr className="mt-0" />
                    <Row className="form-group">
                      <Label htmlFor="conditions[0].destination" md={4}>
                        <TT><b>Condition</b></TT>
                      </Label>
                    </Row>
                    <Row className="form-group">
                      <Label htmlFor="conditions[0].destination" md={4}>
                        { this.state.isConditionRequired ? <span className="form-required-star">*</span> : null}
                        <TT>Destination</TT>
                      </Label>
                      <Col md={8}>
                        <Control.text
                          type="text"
                          model=".conditions[0].destination"
                          id="conditions[0].destination"
                          name="conditions[0].destination"
                          placeholder={toTTString("Network/Mask")}
                          component={FWTextInput}
                          withFieldValue
                          validators={{
                            destination: IPv4FieldValidator,
                            prefixLen: isValidPrefixLength
                          }}
                        />
                        <Errors
                          className="text-danger"
                          model=".conditions[0].destination"
                          show="touched"
                          messages={{
                            required: toTTString("Required Field"),
                            destination: toTTString("Invalid IPv4 Network Format"),
                            prefixLen: toTTString("Invalid prefix for given mask"),
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <Label htmlFor="conditions[0].via" md={4}>
                        { this.state.isConditionRequired ? <span className="form-required-star">*</span> : null}
                        <TT>Via</TT>
                      </Label>
                      <Col md={8}>
                        <Control
                          model=".conditions[0].via"
                          id="conditions[0].via"
                          name="conditions[0].via"
                          placeholder={toTTString("Gateway IP / Tunnel")}
                          changeAction={this.handleChangeAction}
                          component={SearchableSelectBox}
                          creatable={false} // At the moment, we support interfaces or tunnels
                          options={this.getConditionsVia()}
                          formatCreateLabel=""
                        />
                        <Errors
                          className="text-danger"
                          model=".conditions[0].via"
                          show="touched"
                          messages={{
                            required: toTTString("Required Field"),
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Label htmlFor="conditions[0].type" md={4}>
                        { this.state.isConditionRequired ? <span className="form-required-star">*</span> : null}
                        <TT>Install if route in condition</TT>
                      </Label>
                      <Col md={8}>
                        <Control.select
                          model=".conditions[0].type"
                          id="conditions[0].type"
                          name="conditions[0].type"
                          placeholder=""
                          className="form-control"
                        >
                          <option key="0" value={""}>{toTTString("Select Type")}</option>
                          <option key="1" value="route-not-exist">{toTTString("Not Exist")}</option>
                          <option key="2" value="route-exist">{toTTString("Exist")}</option>
                        </Control.select>
                        <Errors
                            className="text-danger"
                            model=".conditions[0].type"
                            show="touched"
                            messages={{
                              required: toTTString("Required Field"),
                            }}
                          />
                      </Col>
                    </Row>
                  </Collapse>

                  <hr />

                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Control.button
                        model="addStaticRoute"
                        disabled={{ valid: false }}
                        className="btn btn-primary"
                      >
                        <TT>Add Static Route</TT>
                      </Control.button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.addStaticRoute,
    device: state.devices.device
  }
}

export default connect(mapStateToProps, null)(StaticRoutesCard);