import React, {useContext} from "react";
import ReactTooltip from 'react-tooltip';
import { LanguageContext } from '../../containers/Language'

export default function LinkStatusIcon ({deviceStatus, row}) {
  const { toTTString } = useContext(LanguageContext);
  let params = {};
  let linkStatus = row.linkStatus;
  const isConnected = deviceStatus && deviceStatus.connection === 'connected';
  if (deviceStatus && deviceStatus.interfaces) {
    const interfaceStatus = deviceStatus.interfaces.find(i => i.devId === row.devId);
    if (interfaceStatus) {
      linkStatus = interfaceStatus.linkStatus;
    }
  };
  if (!isConnected) {
    params.status = 'disabled';
    params.text = toTTString('device not connected');
  } else if (linkStatus === 'down') {
    params.status = 'inactive';
    params.text = toTTString('link status DOWN');
  } else if (linkStatus === 'up') {
    params.status = 'active';
    params.text = toTTString('link status UP');
  } else {
    params.status = 'disabled';
    params.text = toTTString('link status unknown');
  };

  return <span
      className={'status-circle ' + params.status}
      data-tip data-for={'link-status-' + row._id}
    >
      <ReactTooltip id={'link-status-' + row._id}>
        <span>{params.text}</span>
      </ReactTooltip>
    </span>;
}