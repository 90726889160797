import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
const { baseUrl } = window.SERVER_CONFIG;

export const Tokens = (
  state = {
    token: null,
    tokens: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.TOKEN_ADD_REQUEST:
      return { ...state, token: null };
    case Actions.TOKEN_ADD_SUCCESS:
      return { ...state, token: action.token };
    case Actions.TOKEN_ADD_FAILURE:
      return { ...state, token: null };
    case Actions.TOKEN_UPD_REQUEST:
      return { ...state, token: action.token };
    case Actions.TOKEN_UPD_SUCCESS:
      return { ...state };
    case Actions.TOKEN_UPD_FAILURE:
      return { ...state, token: null };
    case Actions.TOKEN_DEL_REQUEST:
      return { ...state, token: action.token };
    case Actions.TOKEN_DEL_SUCCESS:
      return { ...state };
    case Actions.TOKEN_DEL_FAILURE:
      return { ...state, token: null };
    case Actions.TOKEN_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.TOKEN_GETALL_SUCCESS:
      return { ...state, tokens: action.tokens, isLoading: false };
    case Actions.TOKEN_GETALL_FAILURE:
      return { ...state, tokens: [], isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add Token
 **********************************************************************/
export const requestAddToken = token => dispatch => {
  dispatch({
    type: Actions.TOKEN_ADD_REQUEST,
    token
  });
};

export const receiveAddToken = response => dispatch => {
  dispatch({
    type: Actions.TOKEN_ADD_SUCCESS,
    token: response.name
  });
  dispatch(mainRedirectTo("/tokens"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Token " + response.name + " added successfully"
    })
  );
};

export const failureAddToken = error => dispatch => {
  dispatch({
    type: Actions.TOKEN_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const addToken = (token, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "tokens",
      method: "POST",
      body: JSON.stringify(token),
      requestCB: () => {
        dispatch(requestAddToken(token));
      },
      successCB: response => {
        dispatch(receiveAddToken(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddToken(error));
      }
    })
  );
};

/**********************************************************************
 *   Update Token
 **********************************************************************/
export const requestUpdToken = token => dispatch => {
  dispatch({
    type: Actions.TOKEN_UPD_REQUEST,
    token
  });
};

export const receiveUpdToken = response => dispatch => {
  dispatch({
    type: Actions.TOKEN_UPD_SUCCESS
  });
  dispatch(mainRedirectTo("/tokens"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Token " + store.getState().tokens.token.name + " updated successfully"
    })
  );
};

export const failureUpdToken = error => dispatch => {
  dispatch({
    type: Actions.TOKEN_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const updToken = (token, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "tokens/" + token._id,
      method: "PUT",
      body: JSON.stringify(token),
      requestCB: () => {
        dispatch(requestUpdToken(token));
      },
      successCB: response => {
        dispatch(receiveUpdToken(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdToken(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete Token
 **********************************************************************/
export const requestDelToken = token => dispatch => {
  dispatch({
    type: Actions.TOKEN_DEL_REQUEST,
    token
  });
};

export const receiveDelToken = response => dispatch => {
  dispatch({
    type: Actions.TOKEN_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Token " + store.getState().tokens.token.name + " deleted successfully"
    })
  );
};

export const failureDelToken = error => dispatch => {
  dispatch({
    type: Actions.TOKEN_DEL_FAILURE
  });
};

export const delToken = (token, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "tokens/" + token._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelToken(token));
      },
      successCB: response => {
        dispatch(receiveDelToken(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelToken(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all tokens
 **********************************************************************/
export const requestGetAllTokens = () => dispatch => {
  dispatch({
    type: Actions.TOKEN_GETALL_REQUEST
  });
};

export const receiveGetAllTokens = response => dispatch => {
  dispatch({
    type: Actions.TOKEN_GETALL_SUCCESS,
    tokens: response
  });
};

export const failureGetAllTokens = error => dispatch => {
  dispatch({
    type: Actions.TOKEN_GETALL_FAILURE
  });
};

export const getAllTokens = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "tokens",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllTokens());
      },
      successCB: response => {
        dispatch(receiveGetAllTokens(response));
      },
      failureCB: error => {
        dispatch(failureGetAllTokens(error));
      }
    })
  );
};

/**********************************************************************
 *   Get token
 **********************************************************************/
export const requestGetToken = () => dispatch => {
  dispatch({
    type: Actions.TOKEN_GET_REQUEST
  });
};

export const receiveGetToken = response => dispatch => {
  dispatch({
    type: Actions.TOKEN_GET_SUCCESS,
    token: response
  });
};

export const failureGetToken = error => dispatch => {
  dispatch({
    type: Actions.TOKEN_GET_FAILURE
  });
};

export const getToken = (tokenId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "tokens/" + tokenId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetToken());
      },
      successCB: response => {
        dispatch(receiveGetToken(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetToken(error));
      }
    })
  );
};
