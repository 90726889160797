import React, { useEffect, useState, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col,
  ModalBody, Modal, ModalHeader, Button, Label, Card, CardBody } from 'reactstrap';
import { Control, Form, Errors, actions } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { required, maxLength, minLength, isIPList, isDomainList, isIP4, validateStringNoSpaces, isInteger, isPortValid, isEmail } from '../../utils/Validators';
import { FWTextInput } from '../Common'
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import SearchableSelectBox from '../items/SearchableSelectBox';
import FwCollapse from '../items/FwCollapse';
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext } from '../../containers/Language'

const idTypes = [
  { label: 'ip4-addr', value: 'ip4-addr' },
  { label: 'fqdn', value: 'fqdn' },
  { label: 'email', value: 'email' }
];

const cryptoAlgs = [
  { label: 'des-iv64', value: 'des-iv64' },
  { label: 'des', value: 'des' },
  { label: '3des', value: '3des' },
  { label: 'rc5', value: 'rc5' },
  { label: 'idea', value: 'idea' },
  { label: 'cast', value: 'cast' },
  { label: 'blowfish', value: 'blowfish' },
  { label: '3idea', value: '3idea' },
  { label: 'des-iv32', value: 'des-iv32' },
  { label: 'null', value: 'null' },
  { label: 'aes-cbc', value: 'aes-cbc' },
  { label: 'aes-ctr', value: 'aes-ctr' },
  { label: 'aes-gcm-16', value: 'aes-gcm-16' },
];

const integAlgs = [
  { label: 'none', value: 'none' },
  { label: 'md5-96', value: 'md5-96' },
  { label: 'sha1-96', value: 'sha1-96' },
  { label: 'des-mac', value: 'des-mac' },
  { label: 'kpdk-md5', value: 'kpdk-md5' },
  { label: 'aes-xcbc-96', value: 'aes-xcbc-96' },
  { label: 'md5-128', value: 'md5-128' },
  { label: 'sha1-160', value: 'sha1-160' },
  { label: 'cmac-96', value: 'cmac-96' },
  { label: 'aes-128-gmac', value: 'aes-128-gmac' },
  { label: 'aes-192-gmac', value: 'aes-192-gmac' },
  { label: 'aes-256-gmac', value: 'aes-256-gmac' },
  { label: 'hmac-sha2-256-128', value: 'hmac-sha2-256-128' },
  { label: 'hmac-sha2-384-192', value: 'hmac-sha2-384-192' },
  { label: 'hmac-sha2-512-256', value: 'hmac-sha2-512-256' },
]

const dhGroups = [
  { label: 'none', value: 'none' },
  { label: 'modp-768 (Group 1)', value: 'modp-768' },
  { label: 'modp-1024 (Group 2)', value: 'modp-1024' },
  { label: 'modp-1536 (Group 5)', value: 'modp-1536' },
  { label: 'modp-2048 (Group 14)', value: 'modp-2048' },
  { label: 'modp-3072 (Group 15)', value: 'modp-3072' },
  { label: 'modp-4096 (Group 16)', value: 'modp-4096' },
  { label: 'modp-6144 (Group 17)', value: 'modp-6144' },
  { label: 'modp-8192 (Group 18)', value: 'modp-8192' },
  // { label: 'ecp-256 (Group 19)', value: 'ecp-256' },
  // { label: 'ecp-384 (Group 20)', value: 'ecp-384' },
  // { label: 'ecp-521 (Group 21)', value: 'ecp-521' },
  { label: 'modp-1024-160 (Group 22)', value: 'modp-1024-160' },
  { label: 'modp-2048-224 (Group 23)', value: 'modp-2048-224' },
  { label: 'modp-2048-256 (Group 24)', value: 'modp-2048-256' },
  // { label: 'ecp-192', value: 'ecp-192' }
]

const handleChangeAction = (model, value) => {
  return (dispatch) => {
    dispatch(actions.change(model, value?.value || value));
  };
}

const localIdIpv4TemplateText = "Automatic";

const formName = 'configurePeer';

const ConfigurePeer = ({ id, peers, getAllPeers, resetForm, savePeer, changeForm, setFormErrors }) => {
  const { toTTString } = useContext(LanguageContext);
  const resetPeerForm = resetForm(formName)

  const [updatedPeer, setUpdatedPeer] = useState(null);
  const [idType, setIdType] = useState('fqdn');

  const [origPeer, setOrigPeer] = useState(null);

  useEffect(() => {
    if (id) {
      if (peers.length === 0) {
        getAllPeers();
      } else {
        const peer = peers.find(p => p._id === id);

        setOrigPeer(Object.assign({}, peer));

        if (Array.isArray(peer.urls)) {
          peer.urls = peer.urls.join(", ");
          peer.ips = peer.ips.join(", ");
        }
        changeForm(formName)(peer);
      }
    }
  }, [id, getAllPeers, peers, changeForm]);

  // Specify how to clean up this component
  useEffect(() => {
    return function cleanup() {
      resetPeerForm()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeApproval = () => {
   savePeer(updatedPeer, resetPeerForm);
    setUpdatedPeer(null)
  }

  const handleSubmit = values => {
    let fixedValues = {...values};
    fixedValues.urls = fixedValues.urls === '' ? []: fixedValues.urls.split(/\s*,\s*/);
    fixedValues.ips = fixedValues.ips === '' ? []: fixedValues.ips.split(/\s*,\s*/);

    if (id) {
      fixedValues = { ...fixedValues, _id: id}

      const origValues = omit(origPeer, 'name');
      const updatedValues = omit(fixedValues, 'name');

      const isNeedToChangeTunnels = !isEqual(origValues, updatedValues);

      if (isNeedToChangeTunnels) {
        setUpdatedPeer(fixedValues)
      } else {
        savePeer(fixedValues, resetPeerForm);
      }
    } else {
      savePeer(fixedValues, resetPeerForm);
    }
  }

  const getPageTitle = () => {
    return id ? 'Update Peer' : 'Add Peer';
  }

  const validateIdBasedOnType = (values, type, id, allowedIpv4Value = null) => {
    if (values[type] && values[id]) {
      let isValid = true;
      if (values[type] === 'ip4-addr') {
        if (!allowedIpv4Value || values[id] !== allowedIpv4Value) {
          isValid = isIP4(values[id]);
        }
      }

      if (values[type] === 'email') {
        isValid = isEmail(values[id]);
      }

      if (!isValid) {
        setFormErrors(formName + '.' + id, 'The value does not match the selected ID type');
      } else {
        setFormErrors(formName + '.' + id, false);
      }
      return isValid;
    }
    return true
  }

  return(
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
        <BreadcrumbItem active>Inventory</BreadcrumbItem>
        <BreadcrumbItem><Link to="/peers">Peers</Link></BreadcrumbItem>
        <BreadcrumbItem active>{getPageTitle()}</BreadcrumbItem>
      </Breadcrumb>

      <Col md={12}>
        <h4>{getPageTitle()}</h4>
      </Col>

      <div className="col-md-12" style={{ maxWidth: '1200px' }}>
        <Card id='configurePeerCard'>
          <CardBody>
            <Form
              model={formName}
              onSubmit={values => handleSubmit(values)}
              onChange={values => {
                setIdType(values.idType);
              }}
              validators={{
                '': {
                  localIdIsValidBasedOnIdType: values => validateIdBasedOnType(values, 'idType', 'localId', localIdIpv4TemplateText) ,
                  remoteIdIsValidBasedOnIdType: values => validateIdBasedOnType(values, 'remoteIdType', 'remoteId'),
                  remotePortRangeLowToHigh: values => {
                    if (values.remotePortRangeStart && values.remotePortRangeEnd) {
                      if (parseInt(values.remotePortRangeStart) >= parseInt(values.remotePortRangeEnd)) {
                        return false;
                      }
                    }

                    return true;
                  },
                  localPortRangeLowToHigh: values => {
                    if (values.localPortRangeStart && values.localPortRangeEnd) {
                      if (parseInt(values.localPortRangeStart) > parseInt(values.localPortRangeEnd)) {
                        return false;
                      }
                    }

                    return true;
                  },
                }
              }}
            >
              <Row>
                <Col md={12}>
                  <Row className="form-group">
                    <Label htmlFor="name" md={2}>
                      <span className="form-required-star">*</span>
                      <TT>Name</TT>
                    </Label>
                    <Col md={4}>
                      <Control.text
                        model=".name"
                        id="name"
                        name="name"
                        placeholder={toTTString("Name")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          minLength: minLength(3),
                          maxLength: maxLength(25),
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".name"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          validateStringNoSpaces: toTTString('Spaces are not allowed'),
                          minLength: toTTString('Length must be at least 3'),
                          maxLength: toTTString('Length must be at most 25'),
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="idType" md={2}>
                      <span className="form-required-star">*</span>
                      <TT>Local ID Type</TT>
                      {idType === 'ip4-addr' ? (
                        <>
                          <span className="helpTooltip" data-tip data-for='localIdType'></span>
                          <ReactTooltip id='localIdType'>
                            <span>
                              <TT>Specify a public IP address</TT>.<br />
                              <TT>Select "Automatic" if you want flexiManage to fill it automatically when creating the peer tunnel</TT>
                            </span>
                        </ReactTooltip>
                        </>
                      ) : null}
                    </Label>
                    <Col md={3}>
                      <Control
                        id="idType"
                        model=".idType"
                        name="idType"
                        placeholder={toTTString("Local ID Type")}
                        changeAction={handleChangeAction}
                        component={SearchableSelectBox}
                        autoComplete="new-password"
                        options={idTypes}
                        validators={{
                          required: required
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".idType"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                        }}
                      />
                    </Col>

                    <Col>
                      {idType === 'ip4-addr' ? (
                        <Control
                          id="localId"
                          model=".localId"
                          name="localId"
                          placeholder={toTTString("Local ID")}
                          changeAction={handleChangeAction}
                          component={SearchableSelectBox}
                          options={[
                            { label: localIdIpv4TemplateText, value: localIdIpv4TemplateText }
                          ]}
                          creatable={true}
                          validators={{
                            required: required
                          }}
                        />
                      ) : (
                        <Control.text
                          model=".localId"
                          id="localId"
                          name="localId"
                          placeholder={toTTString("Local ID")}
                          component={FWTextInput}
                          withFieldValue
                          validators={{
                            required: required,
                            validateStringNoSpaces: validateStringNoSpaces
                          }}
                        />
                      )}
                      <Errors
                        className="text-danger"
                        model=".localId"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          validateStringNoSpaces: toTTString('Spaces are not allowed'),
                          localIdIsValidBasedOnIdType: toTTString('The value does not match the selected ID type'),
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="remoteIdType" md={2}>
                      <span className="form-required-star">*</span>
                      <TT>Remote ID Type</TT>
                    </Label>
                    <Col md={3}>
                      <Control
                        id="remoteIdType"
                        model=".remoteIdType"
                        name="remoteIdType"
                        placeholder={toTTString("ID Type")}
                        changeAction={handleChangeAction}
                        component={SearchableSelectBox}
                        options={idTypes}
                        validators={{
                          required: required
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".remoteIdType"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                        }}
                      />
                    </Col>

                    <Col>
                      <Control.text
                        model=".remoteId"
                        id="remoteId"
                        name="remoteId"
                        placeholder={toTTString("Remote ID")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          validateStringNoSpaces: validateStringNoSpaces
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".remoteId"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          validateStringNoSpaces: toTTString('Spaces are not allowed'),
                          remoteIdIsValidBasedOnIdType: toTTString('The value does not match the selected ID type')
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="psk" md={2}>
                      <span className="form-required-star">*</span>
                      <TT>PSK</TT>
                    </Label>
                    <Col md={6}>
                      <Control.text
                        model=".psk"
                        id="psk"
                        name="psk"
                        placeholder={toTTString("PSK")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          validateStringNoSpaces: validateStringNoSpaces
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".psk"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          validateStringNoSpaces: toTTString('Spaces are not allowed'),
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="remoteIP" md={2}>
                      <span className="form-required-star">*</span>
                      <TT>Remote IP</TT>
                      {idType === 'ip4-addr' ? (
                        <>
                          <span className="helpTooltip" data-tip data-for='remoteId'></span>
                          <ReactTooltip id='remoteId'>
                            <span>
                              <TT>IP of the remote side the peer tunnel connected to</TT>.<br />
                              <TT>When the ID type is ipv4-addr, the remote IP is commonly set as the remote ID</TT>
                            </span>
                        </ReactTooltip>
                        </>
                      ) : null}
                    </Label>
                    <Col md={3}>
                      <Control.text
                        model=".remoteIP"
                        id="remoteIP"
                        name="remoteIP"
                        placeholder={toTTString("Remote IP")}
                        component={FWTextInput}
                        withFieldValue
                        validators={{
                          required: required,
                          isIP4: isIP4
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".remoteIP"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          isIP4: toTTString('Invalid IPv4')
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <hr className="mb-3" />

              <Row>
                <Col md={12}>
                  <FwCollapse
                   title={toTTString("Peer Monitoring")}
                   tip={
                     (
                        <>
                        <TT>Peer tunnels monitor the IKEv2 session by default</TT>.<br />
                        <TT>Use IP or URL to add an overlay monitoring on top of the tunnel to validate the end-to-end reachability</TT>.<br />
                        <TT>High monitoring drop rate will mark the peer tunnel as not connected</TT>.<br />
                        <TT>It's possible to specify multiple IPs and URLs, comma separated</TT>
                        </>
                     )
                   }
                  >
                    <Row>
                      <Col md={5}>
                        <Row>
                          <Label htmlFor="ips" md={2}>
                          <TT>IPs</TT>
                          </Label>
                          <Col md={9}>
                            <Control.text
                              type="text"
                              model=".ips"
                              id="ips"
                              name="ips"
                              placeholder={toTTString("IPs")}
                              component={FWTextInput}
                              withFieldValue
                              validators={{
                                ipList: val => val === '' || isIPList(val)
                              }}
                            />
                            <Errors
                              className="text-danger"
                              model=".ips"
                              show="touched"
                              messages={{
                                required: toTTString('Required field'),
                                ipList: toTTString('Invalid IPv4 Addresses')
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col md={5}>
                        <Row>
                          <Label htmlFor="urls" md={2}>
                          <TT>URLs</TT>
                          </Label>
                          <Col md={9}>
                            <Control.text
                              model=".urls"
                              id="urls"
                              name="urls"
                              placeholder={toTTString("URLs")}
                              component={FWTextInput}
                              withFieldValue
                              validators={{
                                isDomainList: val => val === '' || isDomainList(val)
                              }}
                            />
                            <Errors
                              className="text-danger"
                              model=".urls"
                              show="touched"
                              messages={{
                                isDomainList: toTTString('Invalid urls list')
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FwCollapse>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <FwCollapse title={toTTString("Cryptography")}>
                    <Row>
                      <Col md={6}>
                        <Row className="form-group">
                          <Col>
                            <TT>IKE Parameters</TT>
                          </Col>
                        </Row>

                        <CryptoAlg id="ikeCryptoAlg"/>
                        <KeySize id="ikeKeySize" />
                        <InterAlg id="ikeIntegAlg" />
                        <DhGroup id="ikeDhGroup" />

                        <Row className="form-group">
                          <Label htmlFor="ikeLifeTime" md={4}>
                            <span className="form-required-star">*</span>
                            <TT>Lifetime</TT>
                          </Label>
                          <Col md={8}>
                            <Control.text
                              model=".ikeLifeTime"
                              id="ikeLifeTime"
                              name="ikeLifeTime"
                              placeholder={toTTString("Life Time")}
                              component={FWTextInput}
                              withFieldValue
                              validators={{
                              required: required,
                              isInteger: isInteger
                              }}
                            />
                            <small className="form-text text-muted"><TT>Seconds</TT></small>
                            <Errors
                              className="text-danger"
                              model=".ikeLifeTime"
                              show="touched"
                              messages={{
                                required: toTTString('Required field'),
                                isInteger: toTTString('Invalid number'),
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col md={6}>
                        <Row className="form-group">
                          <Col>
                            <TT>ESP Parameters</TT>
                          </Col>
                        </Row>

                        <CryptoAlg id="espCryptoAlg"/>
                        <KeySize id="espKeySize" />
                        <InterAlg id="espIntegAlg" />
                        {/* <DhGroup id="espDhGroup" /> */}

                        <Row className="form-group">
                          <Label md={4}>
                            <span className="form-required-star">*</span>
                            <TT>PFS</TT>
                          </Label>

                          <Col md={6}>
                            <Label className="FWswitch">
                              <Control.checkbox
                                model=".pfs"
                                id="pfs"
                                name="pfs"
                              />{" "}
                              <span className="FWslider round" />
                            </Label>
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Label htmlFor="sessionLifeTime" md={4}>
                            <span className="form-required-star">*</span>
                            <TT>Lifetime</TT>
                          </Label>
                          <Col md={8}>
                            <Control.text
                              model=".sessionLifeTime"
                              id="sessionLifeTime"
                              name="sessionLifeTime"
                              placeholder={toTTString("Life Time")}
                              component={FWTextInput}
                              withFieldValue
                              validators={{
                              required: required,
                              isInteger: isInteger
                              }}
                            />
                            <small className="form-text text-muted"><TT>Seconds</TT></small>
                            <Errors
                              className="text-danger"
                              model=".sessionLifeTime"
                              show="touched"
                              messages={{
                                required: toTTString('Required field'),
                                isInteger: toTTString('Invalid number'),
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FwCollapse>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <FwCollapse title={toTTString("Traffic Selector")}>
                      <Row>
                        <Col md={6}>
                          <Row className="form-group">
                            <Col>
                              <TT>Local Traffic Selector</TT>
                            </Col>
                          </Row>

                          <IpRange id="localIpRange" />
                          <PortRange id="localPortRange" />
                          <Protocol id="localProtocol" />
                        </Col>

                        <Col md={6}>
                          <Row className="form-group">
                            <Col>
                              <TT>Remote Traffic Selector</TT>
                            </Col>
                          </Row>

                          <IpRange id="remoteIpRange" />
                          <PortRange id="remotePortRange" />
                          <Protocol id="remoteProtocol" />
                        </Col>
                      </Row>
                  </FwCollapse>
                </Col>
              </Row>

              <hr className="mb-3" />

              <Row className="form-group  mt-2">
                <Col md={12}>
                    <Control.button
                      model="configurePeer"
                      className="btn btn-primary">
                        <TT>Save</TT>
                    </Control.button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>

      {/* Delete Peer Modal */}
      <Modal isOpen={updatedPeer} toggle={() => setUpdatedPeer(null)}>
        <ModalHeader>Update {updatedPeer?.name} Configuration?</ModalHeader>
        <ModalBody>
          <div
            className="mb-3 alert alert-warning"
            role="alert"
          >
            The system will recreate existing tunnels based on this configuration.
          </div>
          <Button color="success" onClick={() => handleChangeApproval()}>Yes</Button>
          <Button className="float-right" color="outline-secondary" onClick={() => setUpdatedPeer(null)}>Cancel</Button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const CryptoAlg = props => {
  return (
    <Row className="form-group">
      <Label htmlFor={props.id} md={4}>
        <span className="form-required-star">*</span>
        Crypto-alg
      </Label>
      <Col md={8}>
        <Control
          id={props.id}
          model={"." + props.id}
          name={props.id}
          placeholder="crypto-alg"
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={cryptoAlgs}
          validators={{
            required: required
          }}
        />
        <Errors
          className="text-danger"
          model={"." + props.id}
          show="touched"
          messages={{
            required: 'Required field'
          }}
        />
      </Col>
    </Row>
  )
}

const KeySize = props => {
  return (
    <Row className="form-group">
      <Label htmlFor={props.id} md={4}>
        <span className="form-required-star">*</span>
        Key-size
      </Label>
      <Col md={8}>
        <Control
          id={props.id}
          model={"." + props.id}
          name={props.id}
          placeholder="key-size"
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={[
            { label: '128', value: '128' },
            { label: '256', value: '256' }
          ]}
          validators={{
            required: required
          }}
        />
        <Errors
          className="text-danger"
          model={"." + props.id}
          show="touched"
          messages={{
            required: 'Required field'
          }}
        />
      </Col>
    </Row>
  )
}

const InterAlg = props => {
  return (
    <Row className="form-group">
      <Label htmlFor={props.id} md={4}>
        <span className="form-required-star">*</span>
        Integ-alg
      </Label>
      <Col md={8}>
        <Control
          id={props.id}
          model={"." + props.id}
          name={props.id}
          placeholder="integ-alg"
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={integAlgs}
          validators={{
            required: required
          }}
        />
        <Errors
          className="text-danger"
          model={"." + props.id}
          show="touched"
          messages={{
            required: 'Required field'
          }}
        />
      </Col>
    </Row>
  )
}

const DhGroup = props => {
  return (
    <Row className="form-group">
      <Label htmlFor={props.id} md={4}>
        <span className="form-required-star">*</span>
        DH group
      </Label>
      <Col md={8}>
        <Control
          id={props.id}
          model={"." + props.id}
          name={props.id}
          placeholder="dh group"
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={dhGroups}
          validators={{
            required: required
          }}
        />
        <Errors
          className="text-danger"
          model={"." + props.id}
          show="touched"
          messages={{
            required: 'Required field'
          }}
        />
      </Col>
    </Row>
  )
}

const IpRange = props => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <>
      <Row className="form-group">
        <Label htmlFor={props.id + "Start"} md={3}>
          <span className="form-required-star">*</span>
            <TT>IP Range</TT>
        </Label>

        <Col md={4}>
          <Control.text
            model={"." + props.id + "Start"}
            id={props.id + "Start"}
            name={props.id + "Start"}
            placeholder={toTTString("IP Start")}
            component={FWTextInput}
            withFieldValue
            validators={{
              required: required,
              isIP4: isIP4
            }}
          />
          <small className="form-text text-muted"><TT>From</TT></small>
          <Errors
            className="text-danger"
            model={"." + props.id + "Start"}
            show="touched"
            messages={{
              required: toTTString('Required field'),
              isIP4: toTTString('Invalid IPv4')
            }}
          />
        </Col>

        <Col md={4}>
          <Control.text
            model={"." + props.id + "End"}
            id={props.id + "End"}
            name={props.id + "End"}
            placeholder={toTTString("IP End")}
            component={FWTextInput}
            withFieldValue
            validators={{
              required: required,
              isIP4: isIP4
            }}
          />
          <small className="form-text text-muted"><TT>To</TT></small>
          <Errors
            className="text-danger"
            model={"." + props.id  + "End"}
            show="touched"
            messages={{
              required: toTTString('Required field'),
              isIP4: toTTString('Invalid IPv4')
            }}
          />
        </Col>
      </Row>

    </>
  )
}

const PortRange = props => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <>
      <Row className="form-group">
        <Label htmlFor={props.id + "Start"} md={3}>
          <span className="form-required-star">*</span>
          <TT>Port Range</TT>
        </Label>

        <Col md={4}>
          <Control.text
            model={"." + props.id + "Start"}
            id={props.id + "Start"}
            name={props.id + "Start"}
            placeholder={toTTString("Port Range Start")}
            component={FWTextInput}
            withFieldValue
            validators={{
              required: required,
              isPortValid: isPortValid
            }}
          />
          <small className="form-text text-muted"><TT>From</TT></small>
          <Errors
            className="text-danger"
            model={"." + props.id + "Start"}
            show="touched"
            messages={{
              required: toTTString('Required field'),
              isPortValid: toTTString('Port number is invalid')
            }}
          />
          { props.id.includes('local') ? (
            <Errors
              className="text-danger"
              model={formName}
              show="touched"
              messages={{
                localPortRangeLowToHigh: toTTString("Local range is invalid")
              }}
            />
          ) : (
            <Errors
              className="text-danger"
              model={formName}
              show="touched"
              messages={{
                remotePortRangeLowToHigh: toTTString("Remote range is invalid")
              }}
            />
          )}
        </Col>

        <Col md={4}>
          <Control.text
            model={"." + props.id + "End"}
            id={props.id + "End"}
            name={props.id + "End"}
            placeholder={toTTString("Port Range End")}
            component={FWTextInput}
            withFieldValue
            validators={{
              required: required,
              isPortValid: isPortValid
            }}
          />
          <small className="form-text text-muted"><TT>To</TT></small>
          <Errors
            className="text-danger"
            model={"." + props.id  + "End"}
            show="touched"
            messages={{
              required: toTTString('Required field'),
              isPortValid: toTTString('Port number is invalid')
            }}
          />
        </Col>
      </Row>

    </>
  )
}

const Protocol = props => {
  const { toTTString } = useContext(LanguageContext);
  return (
    <Row className="form-group">
      <Label htmlFor={props.id} md={3}>
        <span className="form-required-star">*</span>
        <TT>Protocol</TT>
      </Label>
      <Col md={4}>
        <Control
          id={props.id}
          model={"." + props.id}
          name={props.id}
          placeholder={toTTString("Protocol")}
          changeAction={handleChangeAction}
          component={SearchableSelectBox}
          options={[
            { label: 'any', value: 'any'},
            { label: 'icmp', value: 'icmp'},
            { label: 'tcp', value: 'tcp'},
            { label: 'udp', value: 'udp'}
          ]}
          validators={{
            required: required
          }}
        />
        <Errors
          className="text-danger"
          model={"." + props.id}
          show="touched"
          messages={{
            required: toTTString('Required field')
          }}
        />
      </Col>
    </Row>
  )
}

export default ConfigurePeer;