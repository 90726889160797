const standartPorts = {
    '20': 'FTP-Data',
    '21': 'FTP',
    '22': 'SSH',
    '23': 'Telnet',
    '25': 'SMTP',
    '53': 'DNS',
    '69': 'TFTP',
    '80': 'HTTP',
    '110': 'POP3',
    '115': 'SFTP',
    '119': 'NNTP',
    '123': 'NTP',
    '137': 'NetBIOS-NS',
    '138': 'NetBIOS-DGM',
    '139': 'NetBIOS-SSN',
    '143': 'IMAP',
    '161': 'SNMP',
    '162': 'SNMP-Trap',
    '179': 'BGP',
    '389': 'LDAP',
    '443': 'HTTPS',
    '445': 'MS DS',
    '465': 'SMTP/S',
    '500': 'ISAKMP',
    '514': 'Syslog',
    '587': 'Submission',
    '636': 'LDAP/S',
    '853': 'DNS over TLS',
    '993': 'IMAP/S',
    '995': 'POP3/S',
    '1194': 'OpenVPN',
    '1512': 'MS WINS',
    '1701': 'L2TP',
    '1723': 'PPTP',
    '1755': 'MMS/TCP',
    '1812': 'RADIUS',
    '1813': 'RADIUS accounting',
    '1863': 'MSN',
    '3389': 'MS RDP',
    '3478': 'STUN',
    '3544': 'Teredo',
    '4500': 'IPsec NAT-T',
    '5004': 'RTP',
    '5060': 'SIP',
    '5900': 'VNC',
    '7000': 'MMS/UDP',
};
export default standartPorts;