/* Main Actions */
export const MAIN_SIDEBAR_TOGGLE = 'MAIN_SIDEBAR_TOGGLE';
export const MAIN_TOPBAR_LOADING = 'MAIN_TOPBAR_LOADING';
export const MAIN_REDIRECT_TO = 'MAIN_REDIRECT_TO';

/* Sidebar Actions */
export const SIDEBAR_CONFIG_DROPDOWN_TOGGLE = 'SIDEBAR_CONFIG_DROPDOWN_TOGGLE';
export const SIDEBAR_DASHBOARD_DROPDOWN_TOGGLE = 'SIDEBAR_DASHBOARD_DROPDOWN_TOGGLE';
export const SIDEBAR_TROUBLESHOOT_DROPDOWN_TOGGLE = 'SIDEBAR_TROUBLESHOOT_DROPDOWN_TOGGLE';
export const SIDEBAR_ACCOUNTS_DROPDOWN_TOGGLE = 'SIDEBAR_ACCOUNTS_DROPDOWN_TOGGLE';
export const SIDEBAR_USERS_DROPDOWN_TOGGLE = 'SIDEBAR_USERS_DROPDOWN_TOGGLE';
export const SIDEBAR_TRAFFIC_OPT_DROPDOWN_TOGGLE = 'SIDEBAR_TRAFFIC_OPT_DROPDOWN_TOGGLE'
export const SIDEBAR_SECURITY_DROPDOWN_TOGGLE = 'SIDEBAR_SECURITY_DROPDOWN_TOGGLE'
export const SIDEBAR_HA_DROPDOWN_TOGGLE = 'SIDEBAR_HA_DROPDOWN_TOGGLE'
export const SIDEBAR_APPLICATIONS_DROPDOWN_TOGGLE = 'SIDEBAR_APPLICATIONS_DROPDOWN_TOGGLE'

/* Alert Messages */
export const ALERT_MSG_MESSAGE = 'ALERT_MSG_MESSAGE';

/* User / Account Actions */
export const AUTH_UPDATE_JWT = 'AUTH_UPDATE_JWT';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_MFA_LOGIN_SUCCESS = 'AUTH_MFA_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';
export const AUTH_CREATE_REQUEST = 'AUTH_CREATE_REQUEST';
export const AUTH_CREATE_SUCCESS = 'AUTH_CREATE_SUCCESS';
export const AUTH_CREATE_FAILURE = 'AUTH_CREATE_FAILURE';
export const AUTH_VERIFY_REQUEST = 'AUTH_VERIFY_REQUEST';
export const AUTH_VERIFY_SUCCESS = 'AUTH_VERIFY_SUCCESS';
export const AUTH_VERIFY_FAILURE = 'AUTH_VERIFY_FAILURE';
export const AUTH_REVERIFY_REQUEST = 'AUTH_REVERIFY_REQUEST';
export const AUTH_REVERIFY_SUCCESS = 'AUTH_REVERIFY_SUCCESS';
export const AUTH_REVERIFY_FAILURE = 'AUTH_REVERIFY_FAILURE';
export const AUTH_RESETPW_REQUEST = 'AUTH_RESETPW_REQUEST';
export const AUTH_RESETPW_SUCCESS = 'AUTH_RESETPW_SUCCESS';
export const AUTH_RESETPW_FAILURE = 'AUTH_RESETPW_FAILURE';
export const ACCOUNT_GETALL_REQUEST = 'ACCOUNT_GETALL_REQUEST';
export const ACCOUNT_GETALL_SUCCESS = 'ACCOUNT_GETALL_SUCCESS';
export const ACCOUNT_GETALL_FAILURE = 'ACCOUNT_GETALL_FAILURE';
export const ACCOUNT_GET_REQUEST = 'ACCOUNT_GET_REQUEST';
export const ACCOUNT_GET_SUCCESS = 'ACCOUNT_GET_SUCCESS';
export const ACCOUNT_GET_FAILURE = 'ACCOUNT_GET_FAILURE';
export const ACCOUNT_GET_SUBSCRIPTION_STATUS_REQUEST = "ACCOUNT_GET_SUBSCRIPTION_STATUS_REQUEST";
export const ACCOUNT_GET_SUBSCRIPTION_STATUS_SUCCESS = "ACCOUNT_GET_SUBSCRIPTION_STATUS_SUCCESS";
export const ACCOUNT_GET_SUBSCRIPTION_STATUS_FAILURE = "ACCOUNT_GET_SUBSCRIPTION_STATUS_FAILURE";
export const ACCOUNT_SELECT_REQUEST = 'ACCOUNT_SELECT_REQUEST';
export const ACCOUNT_SELECT_SUCCESS = 'ACCOUNT_SELECT_SUCCESS';
export const ACCOUNT_SELECT_FAILURE = 'ACCOUNT_SELECT_FAILURE';
export const ACCOUNT_UPD_REQUEST = "ACCOUNT_UPD_REQUEST";
export const ACCOUNT_UPD_SUCCESS = "ACCOUNT_UPD_SUCCESS";
export const ACCOUNT_UPD_FAILURE = "ACCOUNT_UPD_FAILURE";

/* Multi Factor Authentications Actions */
export const MFA_IS_NOT_CONFIGURED = 'MFA_IS_NOT_CONFIGURED';
export const MFA_IS_REQUIRED = 'MFA_IS_REQUIRED';

export const SET_LOGIN_PROCESS_SCREEN = 'SET_LOGIN_PROCESS_SCREEN';

/* Device Actions */
export const DEVICE_UPD_REQUEST = 'DEVICE_UPD_REQUEST';
export const DEVICE_UPD_SUCCESS = 'DEVICE_UPD_SUCCESS';
export const DEVICE_UPD_FAILURE = 'DEVICE_UPD_FAILURE';
export const DEVICE_DEL_REQUEST = 'DEVICE_DEL_REQUEST';
export const DEVICE_DEL_SUCCESS = 'DEVICE_DEL_SUCCESS';
export const DEVICE_DEL_FAILURE = 'DEVICE_DEL_FAILURE';
export const DEVICE_GETALL_CLEAR = 'DEVICE_GETALL_CLEAR';
export const DEVICE_GETALL_IDS_REQUEST = 'DEVICE_GETALL_IDS_REQUEST';
export const DEVICE_GETALL_IDS_SUCCESS = 'DEVICE_GETALL_IDS_SUCCESS';
export const DEVICE_GETALL_IDS_FAILURE = 'DEVICE_GETALL_IDS_FAILURE';
export const DEVICE_GETALL_REQUEST = 'DEVICE_GETALL_REQUEST';
export const DEVICE_GETALL_SUCCESS = 'DEVICE_GETALL_SUCCESS';
export const DEVICE_GETALL_FAILURE = 'DEVICE_GETALL_FAILURE';
export const DEVICE_GET_REQUEST = 'DEVICE_GET_REQUEST';
export const DEVICE_GET_SUCCESS = 'DEVICE_GET_SUCCESS';
export const DEVICE_GET_FAILURE = 'DEVICE_GET_FAILURE';
export const DEVICE_GET_CONF_REQUEST = 'DEVICE_GET_CONF_REQUEST';
export const DEVICE_GET_CONF_SUCCESS = 'DEVICE_GET_CONF_SUCCESS';
export const DEVICE_GET_CONF_FAILURE = 'DEVICE_GET_CONF_FAILURE';
export const DEVICE_GET_LOGS_REQUEST = 'DEVICE_GET_LOGS_REQUEST';
export const DEVICE_GET_LOGS_SUCCESS = 'DEVICE_GET_LOGS_SUCCESS';
export const DEVICE_GET_LOGS_FAILURE = 'DEVICE_GET_LOGS_FAILURE';
export const DEVICE_GET_TUNNELS_REQUEST = 'DEVICE_GET_TUNNELS_REQUEST';
export const DEVICE_GET_TUNNELS_SUCCESS = 'DEVICE_GET_TUNNELS_SUCCESS';
export const DEVICE_GET_TUNNELS_FAILURE = 'DEVICE_GET_TUNNELS_FAILURE';
export const DEVICE_GET_ROUTES_REQUEST = 'DEVICE_GET_ROUTES_REQUEST';
export const DEVICE_GET_ROUTES_SUCCESS = 'DEVICE_GET_ROUTES_SUCCESS';
export const DEVICE_GET_ROUTES_FAILURE = 'DEVICE_GET_ROUTES_FAILURE';
export const DEVICE_GET_PACKET_TRACES_REQUEST = 'DEVICE_GET_PACKET_TRACES_REQUEST';
export const DEVICE_GET_PACKET_TRACES_SUCCESS = 'DEVICE_GET_PACKET_TRACES_SUCCESS';
export const DEVICE_GET_PACKET_TRACES_FAILURE = 'DEVICE_GET_PACKET_TRACES_FAILURE';
export const DEVICE_SHOW_OVERLAPPING_CONFIRMATION = 'DEVICE_SHOW_OVERLAPPING_CONFIRMATION';
export const DEVICE_HIDE_OVERLAPPING_CONFIRMATION = 'DEVICE_HIDE_OVERLAPPING_CONFIRMATION';
/* Device Static Routes */
export const SAVE_DEVICE_STATIC_ROUTES = 'SAVE_DEVICE_STATIC_ROUTES';
export const DEVICE_SND_REQUEST = 'DEVICE_SND_REQUEST';
export const DEVICE_SND_SUCCESS = 'DEVICE_SND_SUCCESS';
export const DEVICE_SND_FAILURE = 'DEVICE_SND_FAILURE';
export const DEVICE_START_REQUEST = 'DEVICE_START_REQUEST';
export const DEVICE_START_SUCCESS = 'DEVICE_START_SUCCESS';
export const DEVICE_START_FAILURE = 'DEVICE_START_FAILURE';
export const DEVICE_STOP_REQUEST = 'DEVICE_STOP_REQUEST';
export const DEVICE_STOP_SUCCESS = 'DEVICE_STOP_SUCCESS';
export const DEVICE_STOP_FAILURE = 'DEVICE_STOP_FAILURE';
export const DEVICE_UPGRADE_REQUEST = 'DEVICE_UPGRADE_REQUEST';
export const DEVICE_UPGRADE_SUCCESS = 'DEVICE_UPGRADE_SUCCESS';
export const DEVICE_UPGRADE_FAILURE = 'DEVICE_UPGRADE_FAILURE';
export const DEVICE_UPGRADE_OS_REQUEST = 'DEVICE_UPGRADE_OS_REQUEST';
export const DEVICE_UPGRADE_OS_SUCCESS = 'DEVICE_UPGRADE_OS_SUCCESS';
export const DEVICE_UPGRADE_OS_FAILURE = 'DEVICE_UPGRADE_OS_FAILURE';
export const DEVICE_POLICY_REQUEST = 'DEVICE__POLICY_REQUEST';
export const DEVICE_POLICY_SUCCESS = 'DEVICE__POLICY_SUCCESS';
export const DEVICE_POLICY_FAILURE = 'DEVICE__POLICY_FAILURE';
export const DEVICE_APPLICATION_REQUEST = 'DEVICE_APPLICATION_REQUEST';
export const DEVICE_APPLICATION_SUCCESS = 'DEVICE_APPLICATION_SUCCESS';
export const DEVICE_APPLICATION_FAILURE = 'DEVICE_APPLICATION_FAILURE';

export const DEVICE_APPLICATION_UNINSTALL_REQUEST = 'DEVICE_APPLICATION_UNINSTALL_REQUEST';
export const DEVICE_APPLICATION_UNINSTALL_SUCCESS = 'DEVICE_APPLICATION_UNINSTALL_SUCCESS';
export const DEVICE_APPLICATION_UNINSTALL_FAILURE = 'DEVICE_APPLICATION_UNINSTALL_FAILURE';

// export const DEVICE_WIFI_AVAILABLE_NETWORKS_REQUEST = 'DEVICE_WIFI_AVAILABLE_NETWORKS_REQUEST';
// export const DEVICE_WIFI_AVAILABLE_NETWORKS_SUCCESS = 'DEVICE_WIFI_AVAILABLE_NETWORKS_SUCCESS';
// export const DEVICE_WIFI_AVAILABLE_NETWORKS_FAILURE = 'DEVICE_WIFI_AVAILABLE_NETWORKS_FAILURE';
// export const DEVICE_WIFI_CONNECT_REQUEST = 'DEVICE_WIFI_CONNECT_REQUEST';
// export const DEVICE_WIFI_CONNECT_SUCCESS = 'DEVICE_WIFI_CONNECT_SUCCESS';
// export const DEVICE_WIFI_CONNECT_FAILURE = 'DEVICE_WIFI_CONNECT_FAILURE';
// export const DEVICE_LTE_CONNECT_REQUEST = 'DEVICE_LTE_CONNECT_REQUEST';
// export const DEVICE_LTE_CONNECT_SUCCESS = 'DEVICE_LTE_CONNECT_SUCCESS';
// export const DEVICE_LTE_CONNECT_FAILURE = 'DEVICE_LTE_CONNECT_FAILURE';
export const DEVICE_SAVE_INTERFACE_CONFIGURATION_REQUEST = 'DEVICE_SAVE_INTERFACE_CONFIGURATION_REQUEST';
export const DEVICE_GET_INTERFACE_STATUS_REQUEST = 'DEVICE_GET_INTERFACE_STATUS_REQUEST';
export const DEVICE_GET_INTERFACE_STATUS_SUCCESS = 'DEVICE_GET_INTERFACE_STATUS_SUCCESS';
export const DEVICE_GET_INTERFACE_STATUS_FAILURE = 'DEVICE_GET_INTERFACE_STATUS_FAILURE';
export const DEVICE_SCHED_UPGRADE_REQUEST = 'DEVICE_SCHED_UPGRADE_REQUEST';
export const DEVICE_SCHED_UPGRADE_SUCCESS = 'DEVICE_SCHED_UPGRADE_SUCCESS';
export const DEVICE_SCHED_UPGRADE_FAILURE = 'DEVICE_SCHED_UPGRADE_FAILURE';
export const DEVICE_TUNNEL_REQUEST = 'DEVICE_TUNNEL_REQUEST';
export const DEVICE_TUNNEL_SUCCESS = 'DEVICE_TUNNEL_SUCCESS';
export const DEVICE_TUNNEL_FAILURE = 'DEVICE_TUNNEL_FAILURE';
export const DEVICE_LATEST_VER_REQUEST = 'DEVICE_LATEST_VER_REQUEST';
export const DEVICE_LATEST_VER_SUCCESS = 'DEVICE_LATEST_VER_SUCCESS';
export const DEVICE_LATEST_VER_FAILURE = 'DEVICE_LATEST_VER_FAILURE';
/* ROUTING Actions */
export const DEVICE_SAVE_OSPF_REQUEST = 'DEVICE_SAVE_OSPF_REQUEST';
export const DEVICE_SAVE_BGP_REQUEST = 'DEVICE_SAVE_BGP_REQUEST';
export const DEVICE_SAVE_ROUTING_FILTERS_REQUEST = 'DEVICE_SAVE_ROUTING_FILTERS_REQUEST';
export const DEVICE_SAVE_ADVANCED_ROUTING_REQUEST = 'DEVICE_SAVE_ADVANCED_ROUTING_REQUEST';
/* DHCP Actions */
export const DEVICE_SAVE_DHCP_REQUEST = 'DEVICE_SAVE_DHCP_REQUEST';
export const DEVICE_SAVE_DHCP_SUCCESS = 'DEVICE_SAVE_DHCP_SUCCESS';
export const DEVICE_SAVE_DHCP_FAILURE = 'DEVICE_SAVE_DHCP_FAILURE';
export const SAVE_DEVICE_INTERFACES = 'SAVE_DEVICE_INTERFACES';
/* Reset Device Configuration Actions */
export const DEVICE_RESET_CONF_REQUEST = 'DEVICE_RESET_CONF_REQUEST';
export const DEVICE_RESET_CONF_SUCCESS = 'DEVICE_RESET_CONF_SUCCESS';
export const DEVICE_RESET_CONF_FAILURE = 'DEVICE_RESET_CONF_FAILURE';
/* Modify Device Hardware Actions */
export const DEVICE_MODIFY_HARDWARE_REQUEST = 'DEVICE_MODIFY_HARDWARE_REQUEST';
export const DEVICE_MODIFY_HARDWARE_SUCCESS = 'DEVICE_MODIFY_HARDWARE_SUCCESS';
export const DEVICE_MODIFY_HARDWARE_FAILURE = 'DEVICE_MODIFY_HARDWARE_FAILURE';
/* Sync Device Configuration Actions */
export const DEVICE_SYNC_CONF_REQUEST = 'DEVICE_SYNC_CONF_REQUEST';
export const DEVICE_SYNC_CONF_SUCCESS = 'DEVICE_SYNC_CONF_SUCCESS';
export const DEVICE_SYNC_CONF_FAILURE = 'DEVICE_SYNC_CONF_FAILURE';
/* Device Generate IKEv2 Key/Certificate Actions */
export const DEVICE_GENERATE_IKEV2_REQUEST = 'DEVICE_GENERATE_IKEV2_REQUEST';
export const DEVICE_GENERATE_IKEV2_SUCCESS = 'DEVICE_GENERATE_IKEV2_SUCCESS';
export const DEVICE_GENERATE_IKEV2_FAILURE = 'DEVICE_GENERATE_IKEV2_FAILURE';
/* Device update coordinates */
export const DEVICE_UPD_COORDS_REQUEST = 'DEVICE_UPD_COORDS_REQUEST';
export const DEVICE_UPD_COORDS_SUCCESS = 'DEVICE_UPD_COORDS_SUCCESS';
export const DEVICE_UPD_COORDS_FAILURE = 'DEVICE_UPD_COORDS_FAILURE';
/* Device Firewall Actions */
export const DEVICE_SAVE_FIREWALL_RULES = 'DEVICE_SAVE_FIREWALL_RULES';
/* Replace Devices Actions */
export const DEVICE_REPLACE_REQUEST = 'DEVICE_REPLACE_REQUEST';
export const DEVICE_REPLACE_SUCCESS = 'DEVICE_REPLACE_SUCCESS';
export const DEVICE_REPLACE_FAILURE = 'DEVICE_REPLACE_FAILURE';
/* Device recovery Info */
export const DEVICE_RECOVERY_INFO_REQUEST = 'DEVICE_RECOVERY_INFO_REQUEST';
export const DEVICE_RECOVERY_INFO_SUCCESS = 'DEVICE_RECOVERY_INFO_SUCCESS';
export const DEVICE_RECOVERY_INFO_FAILURE = 'DEVICE_RECOVERY_INFO_FAILURE';
/* Device Tokens Actions */
export const TOKEN_ADD_REQUEST = 'TOKEN_ADD_REQUEST';
export const TOKEN_ADD_SUCCESS = 'TOKEN_ADD_SUCCESS';
export const TOKEN_ADD_FAILURE = 'TOKEN_ADD_FAILURE';
export const TOKEN_UPD_REQUEST = 'TOKEN_UPD_REQUEST';
export const TOKEN_UPD_SUCCESS = 'TOKEN_UPD_SUCCESS';
export const TOKEN_UPD_FAILURE = 'TOKEN_UPD_FAILURE';
export const TOKEN_DEL_REQUEST = 'TOKEN_DEL_REQUEST';
export const TOKEN_DEL_SUCCESS = 'TOKEN_DEL_SUCCESS';
export const TOKEN_DEL_FAILURE = 'TOKEN_DEL_FAILURE';
export const TOKEN_GETALL_REQUEST = 'TOKEN_GETALL_REQUEST';
export const TOKEN_GETALL_SUCCESS = 'TOKEN_GETALL_SUCCESS';
export const TOKEN_GETALL_FAILURE = 'TOKEN_GETALL_FAILURE';
export const TOKEN_GET_REQUEST = 'TOKEN_GET_REQUEST';
export const TOKEN_GET_SUCCESS = 'TOKEN_GET_SUCCESS';
export const TOKEN_GET_FAILURE = 'TOKEN_GET_FAILURE';

/* App Identifications Actions */
export const APPIDENTIFICATION_ADD_REQUEST = 'APPIDENTIFICATION_ADD_REQUEST';
export const APPIDENTIFICATION_ADD_SUCCESS = 'APPIDENTIFICATION_ADD_SUCCESS';
export const APPIDENTIFICATION_ADD_FAILURE = 'APPIDENTIFICATION_ADD_FAILURE';
export const APPIDENTIFICATION_GET_REQUEST = 'APPIDENTIFICATION_GET_REQUEST';
export const APPIDENTIFICATION_GET_SUCCESS = 'APPIDENTIFICATION_GET_SUCCESS';
export const APPIDENTIFICATION_GET_FAILURE = 'APPIDENTIFICATION_GET_FAILURE';
export const CUSTOM_APPIDENTIFICATION_GET_REQUEST = 'CUSTOM_APPIDENTIFICATION_GET_REQUEST';
export const CUSTOM_APPIDENTIFICATION_GET_SUCCESS = 'CUSTOM_APPIDENTIFICATION_GET_SUCCESS';
export const CUSTOM_APPIDENTIFICATION_GET_FAILURE = 'CUSTOM_APPIDENTIFICATION_GET_FAILURE';
export const APPIDENTIFICATION_UPD_REQUEST = 'APPIDENTIFICATION_UPD_REQUEST';
export const APPIDENTIFICATION_UPD_SUCCESS = 'APPIDENTIFICATION_UPD_SUCCESS';
export const APPIDENTIFICATION_UPD_FAILURE = 'APPIDENTIFICATION_UPD_FAILURE';
export const APPIDENTIFICATION_GETALL_REQUEST = 'APPIDENTIFICATION_GETALL_REQUEST';
export const APPIDENTIFICATION_GETALL_SUCCESS = 'APPIDENTIFICATION_GETALL_SUCCESS';
export const APPIDENTIFICATION_GETALL_FAILURE = 'APPIDENTIFICATION_GETALL_FAILURE';
export const APPIDENTIFICATION_INSTALLED_GETALL_REQUEST = 'APPIDENTIFICATION_INSTALLED_GETALL_REQUEST';
export const APPIDENTIFICATION_INSTALLED_GETALL_SUCCESS = 'APPIDENTIFICATION_INSTALLED_GETALL_SUCCESS';
export const APPIDENTIFICATION_INSTALLED_GETALL_FAILURE = 'APPIDENTIFICATION_INSTALLED_GETALL_FAILURE';
export const APPIDENTIFICATIONS_DEVICE_UPDATE_REQUEST = 'APPIDENTIFICATIONS_DEVICE_UPDATE_REQUEST';
export const APPIDENTIFICATIONS_DEVICE_UPDATE_SUCCESS = 'APPIDENTIFICATIONS_DEVICE_UPDATE_SUCCESS';
export const APPIDENTIFICATIONS_DEVICE_UPDATE_FAILURE = 'APPIDENTIFICATIONS_DEVICE_UPDATE_FAILURE';
export const APPIDENTIFICATION_DEL_REQUEST = 'APPIDENTIFICATION_DEL_REQUEST';
export const APPIDENTIFICATION_DEL_SUCCESS = 'APPIDENTIFICATION_DEL_REQUEST';
export const APPIDENTIFICATION_DEL_FAILURE = 'APPIDENTIFICATION_DEL_REQUEST';
export const IMPORTEDAPPIDENTIFICATIONS_GETALL_REQUEST = 'IMPORTEDAPPIDENTIFICATIONS_GETALL_REQUEST';
export const IMPORTEDAPPIDENTIFICATIONS_GETALL_SUCCESS = 'IMPORTEDAPPIDENTIFICATIONS_GETALL_SUCCESS';
export const IMPORTEDAPPIDENTIFICATIONS_GETALL_FAILURE = 'IMPORTEDAPPIDENTIFICATIONS_GETALL_FAILURE';
export const APPIDENTIFICATION_RULE_ADD = 'APPIDENTIFICATION_RULE_ADD';
export const APPIDENTIFICATION_RULE_DELETE = 'APPIDENTIFICATION_RULE_DELETE';
export const APPIDENTIFICATION_UPDATE_REQUEST = 'APPIDENTIFICATION_UPDATE_REQUEST';
export const APPIDENTIFICATION_UPDATE_SUCCESS = 'APPIDENTIFICATION_UPDATE_SUCCESS';
export const APPIDENTIFICATION_UPDATE_FAILURE = 'APPIDENTIFICATION_UPDATE_FAILURE';
export const CUSTOM_APPIDENTIFICATION_UPDATE_REQUEST = 'CUSTOM_APPIDENTIFICATION_UPDATE_REQUEST';
export const CUSTOM_APPIDENTIFICATION_UPDATE_SUCCESS = 'CUSTOM_APPIDENTIFICATION_UPDATE_SUCCESS';
export const CUSTOM_APPIDENTIFICATION_UPDATE_FAILURE = 'CUSTOM_APPIDENTIFICATION_UPDATE_FAILURE';
export const APPIDENTIFICATION_RESET_REQUEST = 'APPIDENTIFICATION_RESET_REQUEST';
export const APPIDENTIFICATION_RESET_SUCCESS = 'APPIDENTIFICATION_RESET_SUCCESS';
export const APPIDENTIFICATION_RESET_FAILURE = 'APPIDENTIFICATION_RESET_FAILURE';

/* Billing Coupons Actions */
export const COUPON_ADD_REQUEST = 'COUPON_ADD_REQUEST';
export const COUPON_ADD_SUCCESS = 'COUPON_ADD_SUCCESS';
export const COUPON_ADD_FAILURE = 'COUPON_ADD_FAILURE';
export const COUPON_DEL_REQUEST = 'COUPON_DEL_REQUEST';
export const COUPON_DEL_SUCCESS = 'COUPON_DEL_SUCCESS';
export const COUPON_DEL_FAILURE = 'COUPON_DEL_FAILURE';
export const COUPON_GETALL_REQUEST = 'COUPON_GETALL_REQUEST';
export const COUPON_GETALL_SUCCESS = 'COUPON_GETALL_SUCCESS';
export const COUPON_GETALL_FAILURE = 'COUPON_GETALL_FAILURE';

/* Device Organizations Actions */
export const ORGANIZATION_ADD_REQUEST = 'ORGANIZATION_ADD_REQUEST';
export const ORGANIZATION_ADD_SUCCESS = 'ORGANIZATION_ADD_SUCCESS';
export const ORGANIZATION_ADD_FAILURE = 'ORGANIZATION_ADD_FAILURE';
export const ORGANIZATION_UPD_REQUEST = 'ORGANIZATION_UPD_REQUEST';
export const ORGANIZATION_UPD_SUCCESS = 'ORGANIZATION_UPD_SUCCESS';
export const ORGANIZATION_UPD_FAILURE = 'ORGANIZATION_UPD_FAILURE';
export const ORGANIZATION_DEL_REQUEST = 'ORGANIZATION_DEL_REQUEST';
export const ORGANIZATION_DEL_SUCCESS = 'ORGANIZATION_DEL_SUCCESS';
export const ORGANIZATION_DEL_FAILURE = 'ORGANIZATION_DEL_FAILURE';
export const ORGANIZATION_GETALL_REQUEST = 'ORGANIZATION_GETALL_REQUEST';
export const ORGANIZATION_GETALL_SUCCESS = 'ORGANIZATION_GETALL_SUCCESS';
export const ORGANIZATION_GETALL_FAILURE = 'ORGANIZATION_GETALL_FAILURE';
export const ORGANIZATION_GET_REQUEST = 'ORGANIZATION_GET_REQUEST';
export const ORGANIZATION_GET_SUCCESS = 'ORGANIZATION_GET_SUCCESS';
export const ORGANIZATION_GET_FAILURE = 'ORGANIZATION_GET_FAILURE';
export const ORGANIZATION_SELECT_REQUEST = 'ORGANIZATION_SELECT_REQUEST';
export const ORGANIZATION_SELECT_SUCCESS = 'ORGANIZATION_SELECT_SUCCESS';
export const ORGANIZATION_SELECT_FAILURE = 'ORGANIZATION_SELECT_FAILURE';
export const ORGANIZATION_GET_SUMMARY_REQUEST = 'ORGANIZATION_GET_SUMMARY_REQUEST';
export const ORGANIZATION_GET_SUMMARY_SUCCESS = 'ORGANIZATION_GET_SUMMARY_SUCCESS';
export const ORGANIZATION_GET_SUMMARY_FAILURE = 'ORGANIZATION_GET_SUMMARY_FAILURE';


/* Members Actions */
export const MEMBER_ADD_REQUEST = 'MEMBER_ADD_REQUEST';
export const MEMBER_ADD_SUCCESS = 'MEMBER_ADD_SUCCESS';
export const MEMBER_ADD_FAILURE = 'MEMBER_ADD_FAILURE';
export const MEMBER_UPD_REQUEST = 'MEMBER_UPD_REQUEST';
export const MEMBER_UPD_SUCCESS = 'MEMBER_UPD_SUCCESS';
export const MEMBER_UPD_FAILURE = 'MEMBER_UPD_FAILURE';
export const MEMBER_DEL_REQUEST = 'MEMBER_DEL_REQUEST';
export const MEMBER_DEL_SUCCESS = 'MEMBER_DEL_SUCCESS';
export const MEMBER_DEL_FAILURE = 'MEMBER_DEL_FAILURE';
export const MEMBER_GETALL_REQUEST = 'MEMBER_GETALL_REQUEST';
export const MEMBER_GETALL_SUCCESS = 'MEMBER_GETALL_SUCCESS';
export const MEMBER_GETALL_FAILURE = 'MEMBER_GETALL_FAILURE';
export const MEMBER_GET_REQUEST = 'MEMBER_GET_REQUEST';
export const MEMBER_GET_SUCCESS = 'MEMBER_GET_SUCCESS';
export const MEMBER_GET_FAILURE = 'MEMBER_GET_FAILURE';
export const MEMBER_SELECT_REQUEST = 'MEMBER_SELECT_REQUEST';
export const MEMBER_SELECT_SUCCESS = 'MEMBER_SELECT_SUCCESS';
export const MEMBER_SELECT_FAILURE = 'MEMBER_SELECT_FAILURE';
export const MEMBER_GETOPT_REQUEST = 'MEMBER_GETOPT_REQUEST';
export const MEMBER_GETOPT_SUCCESS = 'MEMBER_GETOPT_SUCCESS';
export const MEMBER_GETOPT_FAILURE = 'MEMBER_GETOPT_FAILURE';
/* Billing Actions */
export const INVOICE_GETALL_REQUEST = 'INVOICE_GETALL_REQUEST';
export const INVOICE_GETALL_SUCCESS = 'INVOICE_GETALL_SUCCESS';
export const INVOICE_GETALL_FAILURE = 'INVOICE_GETALL_FAILURE';
export const INVOICE_GETALL_RESET = 'INVOICE_GETALL_RESET';

/* Customer Portal */
export const CUSTOMER_PORTAL_OPEN_REQUEST = 'CUSTOMER_PORTAL_OPEN_REQUEST';
export const CUSTOMER_PORTAL_OPEN_SUCCESS = 'CUSTOMER_PORTAL_OPEN_SUCCESS';
export const CUSTOMER_PORTAL_OPEN_FAILURE = 'CUSTOMER_PORTAL_OPEN_FAILURE';

/* Access Token Actions */
export const ACCESS_TOKEN_ADD_REQUEST = 'ACCESS_TOKEN_ADD_REQUEST';
export const ACCESS_TOKEN_ADD_SUCCESS = 'ACCESS_TOKEN_ADD_SUCCESS';
export const ACCESS_TOKEN_ADD_FAILURE = 'ACCESS_TOKEN_ADD_FAILURE';
export const ACCESS_TOKEN_DEL_REQUEST = 'ACCESS_TOKEN_DEL_REQUEST';
export const ACCESS_TOKEN_DEL_SUCCESS = 'ACCESS_TOKEN_DEL_SUCCESS';
export const ACCESS_TOKEN_DEL_FAILURE = 'ACCESS_TOKEN_DEL_FAILURE';
export const ACCESS_TOKEN_GETALL_REQUEST = 'ACCESS_TOKEN_GETALL_REQUEST';
export const ACCESS_TOKEN_GETALL_SUCCESS = 'ACCESS_TOKEN_GETALL_SUCCESS';
export const ACCESS_TOKEN_GETALL_FAILURE = 'ACCESS_TOKEN_GETALL_FAILURE';
export const ACCESS_TOKEN_GET_REQUEST = 'ACCESS_TOKEN_GET_REQUEST';
export const ACCESS_TOKEN_GET_SUCCESS = 'ACCESS_TOKEN_GET_SUCCESS';
export const ACCESS_TOKEN_GET_FAILURE = 'ACCESS_TOKEN_GET_FAILURE';

/* Device Tunnels Actions */
export const TUNNEL_GETALL_REQUEST = 'TUNNEL_GETALL_REQUEST';
export const TUNNEL_GETALL_SUCCESS = 'TUNNEL_GETALL_SUCCESS';
export const TUNNEL_GETALL_FAILURE = 'TUNNEL_GETALL_FAILURE';
export const TUNNEL_GETALL_CLEAR = 'TUNNEL_GETALL_CLEAR';
export const TUNNEL_DEL_REQUEST = 'TUNNEL_DEL_REQUEST';
export const TUNNEL_DEL_SUCCESS = 'TUNNEL_DEL_SUCCESS';
export const TUNNEL_DEL_FAILURE = 'TUNNEL_DEL_FAILURE';
export const TUNNEL_PUT_NOTIFICATIONS_REQUEST = 'TUNNEL_PUT_NOTIFICATIONS_REQUEST';
export const TUNNEL_PUT_NOTIFICATIONS_SUCCESS = 'TUNNEL_PUT_NOTIFICATIONS_SUCCESS'
export const TUNNEL_PUT_NOTIFICATIONS_FAILURE = 'TUNNEL_PUT_NOTIFICATIONS_FAILURE';

/* Peer Actions */
export const PEER_GETALL_REQUEST = 'PEER_GETALL_REQUEST';
export const PEER_GETALL_SUCCESS = 'PEER_GETALL_SUCCESS';
export const PEER_GETALL_FAILURE = 'PEER_GETALL_FAILURE';
export const PEER_SAVE_REQUEST = 'PEER_SAVE_REQUEST';
export const PEER_SAVE_SUCCESS = 'PEER_SAVE_SUCCESS';
export const PEER_SAVE_FAILURE = 'PEER_SAVE_FAILURE';
export const PEER_DEL_REQUEST = 'PEER_DEL_REQUEST';
export const PEER_DEL_SUCCESS = 'PEER_DEL_SUCCESS';
export const PEER_DEL_FAILURE = 'PEER_DEL_FAILURE';

/* Traffic Actions */
export const TRAFFIC_GET_REQUEST = 'TRAFFIC_GET_REQUEST';
export const TRAFFIC_GET_SUCCESS = 'TRAFFIC_GET_SUCCESS';
export const TRAFFIC_GET_FAILURE = 'TRAFFIC_GET_FAILURE';
export const TUNNEL_TRAFFIC_GET_REQUEST = 'TUNNEL_TRAFFIC_GET_REQUEST';
export const TUNNEL_TRAFFIC_GET_SUCCESS = 'TUNNEL_TRAFFIC_GET_SUCCESS';
export const TUNNEL_TRAFFIC_GET_FAILURE = 'TUNNEL_TRAFFIC_GET_FAILURE';
export const TRAFFIC_SET_OPTIONS = 'TRAFFIC_SET_OPTIONS';

/* Health Actions */
export const HEALTH_GET_REQUEST = 'HEALTH_GET_REQUEST';
export const HEALTH_GET_SUCCESS = 'HEALTH_GET_SUCCESS';
export const HEALTH_GET_FAILURE = 'HEALTH_GET_FAILURE';

/* Job Actions */
export const JOB_GET_REQUEST = 'JOB_GET_REQUEST';
export const JOB_GET_SUCCESS = 'JOB_GET_SUCCESS';
export const JOB_GET_FAILURE = 'JOB_GET_FAILURE';
export const JOB_DEL_REQUEST = 'JOB_DEL_REQUEST';
export const JOB_DEL_SUCCESS = 'JOB_DEL_SUCCESS';
export const JOB_DEL_FAILURE = 'JOB_DEL_FAILURE';

/* Admin Info */
export const ADMIN_GET_REQUEST = 'ADMIN_GET_REQUEST';
export const ADMIN_GET_SUCCESS = 'ADMIN_GET_SUCCESS';
export const ADMIN_GET_FAILURE = 'ADMIN_GET_FAILURE';

/* Tickets Info */
export const TICKETS_GET_REQUEST = 'TICKETS_GET_REQUEST';
export const TICKETS_GET_SUCCESS = 'TICKETS_GET_SUCCESS';
export const TICKETS_GET_FAILURE = 'TICKETS_GET_FAILURE';
export const TICKET_COMMENTS_GET_REQUEST = 'TICKET_COMMENTS_GET_REQUEST';
export const TICKET_COMMENTS_GET_SUCCESS = 'TICKET_COMMENTS_GET_SUCCESS';
export const TICKET_COMMENTS_GET_FAILURE = 'TICKET_COMMENTS_GET_FAILURE';

/* Notifications Actions */
export const NOTIFICATIONS_GET_REQUEST = 'NOTIFICATIONS_GET_REQUEST';
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS';
export const NOTIFICATIONS_GET_FAILURE = 'NOTIFICATIONS_GET_FAILURE';
export const NOTIFICATIONS_GET_META_REQUEST = 'NOTIFICATIONS_GET_META_REQUEST';
export const NOTIFICATIONS_GET_META_SUCCESS = 'NOTIFICATIONS_GET_META_SUCCESS';
export const NOTIFICATIONS_GET_META_FAILURE = 'NOTIFICATIONS_GET_META_FAILURE';
export const NOTIFICATIONS_CONF_GET_REQUEST = 'NOTIFICATIONS_CONF_GET_REQUEST';
export const NOTIFICATIONS_CONF_GET_SUCCESS = 'NOTIFICATIONS_CONF_GET_SUCCESS';
export const NOTIFICATIONS_CONF_GET_FAILURE = 'NOTIFICATIONS_CONF_GET_FAILURE';
export const NOTIFICATIONS_DEFAULT_CONF_GET_REQUEST = 'NOTIFICATIONS_DEFAULT_CONF_GET_REQUEST';
export const NOTIFICATIONS_DEFAULT_CONF_GET_SUCCESS = 'NOTIFICATIONS_DEFAULT_CONF_GET_SUCCESS';
export const NOTIFICATIONS_DEFAULT_CONF_GET_FAILURE = 'NOTIFICATIONS_DEFAULT_CONF_GET_FAILURE';
export const NOTIFICATIONS_DEFAULT_CONF_PUT_REQUEST = 'NOTIFICATIONS_DEFAULT_CONF_PUT_REQUEST';
export const NOTIFICATIONS_DEFAULT_CONF_PUT_SUCCESS = 'NOTIFICATIONS_DEFAULT_CONF_PUT_SUCCESS';
export const NOTIFICATIONS_DEFAULT_CONF_PUT_FAILURE = 'NOTIFICATIONS_DEFAULT_CONF_PUT_FAILURE';
export const EMAIL_NOTIFICATIONS_CONF_GET_REQUEST = 'EMAIL_NOTIFICATIONS_CONF_GET_REQUEST';
export const EMAIL_NOTIFICATIONS_CONF_GET_SUCCESS = 'EMAIL_NOTIFICATIONS_CONF_GET_SUCCESS';
export const EMAIL_NOTIFICATIONS_CONF_GET_FAILURE = 'EMAIL_NOTIFICATIONS_CONF_GET_FAILURE';
export const WEBHOOK_SETTINGS_GET_REQUEST = 'WEBHOOK_SETTINGS_GET_REQUEST';
export const WEBHOOK_SETTINGS_GET_SUCCESS = 'WEBHOOK_SETTINGS_GET_SUCCESS';
export const WEBHOOK_SETTINGS_GET_FAILURE = 'WEBHOOK_SETTINGS_GET_FAILURE';
export const WEBHOOK_SETTINGS_PUT_REQUEST = 'WEBHOOK_SETTINGS_PUT_REQUEST';
export const WEBHOOK_SETTINGS_PUT_SUCCESS = 'WEBHOOK_SETTINGS_PUT_SUCCESS';
export const WEBHOOK_SETTINGS_PUT_FAILURE = 'WEBHOOK_SETTINGS_PUT_FAILURE';
export const NOTIFICATIONS_CONF_PUT_REQUEST = 'NOTIFICATIONS_CONF_PUT_REQUEST';
export const NOTIFICATIONS_CONF_PUT_SUCCESS = 'NOTIFICATIONS_CONF_PUT_SUCCESS';
export const NOTIFICATIONS_CONF_PUT_FAILURE = 'NOTIFICATIONS_CONF_PUT_FAILURE';
export const EMAIL_NOTIFICATIONS_CONF_PUT_REQUEST = 'EMAIL_NOTIFICATIONS_CONF_PUT_REQUEST';
export const EMAIL_NOTIFICATIONS_CONF_PUT_SUCCESS = 'EMAIL_NOTIFICATIONS_CONF_PUT_SUCCESS';
export const EMAIL_NOTIFICATIONS_CONF_PUT_FAILURE = 'EMAIL_NOTIFICATIONS_CONF_PUT_FAILURE';
export const NOTIFICATIONS_UPD_REQUEST = 'NOTIFICATIONS_UPD_REQUEST';
export const NOTIFICATIONS_UPD_SUCCESS = 'NOTIFICATIONS_UPD_SUCCESS';
export const NOTIFICATIONS_UPD_FAILURE = 'NOTIFICATIONS_UPD_FAILURE';
export const NOTIFICATIONS_DEL_REQUEST = 'NOTIFICATIONS_DEL_REQUEST';
export const NOTIFICATIONS_DEL_SUCCESS = 'NOTIFICATIONS_DEL_SUCCESS';
export const NOTIFICATIONS_DEL_FAILURE = 'NOTIFICATIONS_DEL_FAILURE';

/* Path Labels Actions */
export const PATH_LABEL_ADD_REQUEST = 'PATH_LABEL_ADD_REQUEST';
export const PATH_LABEL_ADD_SUCCESS = 'PATH_LABEL_ADD_SUCCESS';
export const PATH_LABEL_ADD_FAILURE = 'PATH_LABEL_ADD_FAILURE';
export const PATH_LABEL_UPD_REQUEST = 'PATH_LABEL_UPD_REQUEST';
export const PATH_LABEL_UPD_SUCCESS = 'PATH_LABEL_UPD_SUCCESS';
export const PATH_LABEL_UPD_FAILURE = 'PATH_LABEL_UPD_FAILURE';
export const PATH_LABEL_DEL_REQUEST = 'PATH_LABEL_DEL_REQUEST';
export const PATH_LABEL_DEL_SUCCESS = 'PATH_LABEL_DEL_SUCCESS';
export const PATH_LABEL_DEL_FAILURE = 'PATH_LABEL_DEL_FAILURE';
export const PATH_LABEL_GETALL_REQUEST = 'PATH_LABEL_GETALL_REQUEST';
export const PATH_LABEL_GETALL_SUCCESS = 'PATH_LABEL_GETALL_SUCCESS';
export const PATH_LABEL_GETALL_FAILURE = 'PATH_LABEL_GETALL_FAILURE';
export const PATH_LABEL_GET_REQUEST = 'PATH_LABEL_GET_REQUEST';
export const PATH_LABEL_GET_SUCCESS = 'PATH_LABEL_GET_SUCCESS';
export const PATH_LABEL_GET_FAILURE = 'PATH_LABEL_GET_FAILURE';

/* Multi Link Policies Actions */
export const ML_POLICIES_ADD_REQUEST = 'ML_POLICIES_ADD_REQUEST';
export const ML_POLICIES_ADD_SUCCESS = 'ML_POLICIES_ADD_SUCCESS';
export const ML_POLICIES_ADD_FAILURE = 'ML_POLICIES_ADD_FAILURE';
export const ML_POLICIES_UPD_REQUEST = 'ML_POLICIES_UPD_REQUEST';
export const ML_POLICIES_UPD_SUCCESS = 'ML_POLICIES_UPD_SUCCESS';
export const ML_POLICIES_UPD_FAILURE = 'ML_POLICIES_UPD_FAILURE';
export const ML_POLICIES_DEL_REQUEST = 'ML_POLICIES_DEL_REQUEST';
export const ML_POLICIES_DEL_SUCCESS = 'ML_POLICIES_DEL_SUCCESS';
export const ML_POLICIES_DEL_FAILURE = 'ML_POLICIES_DEL_FAILURE';
export const ML_POLICIES_GETALL_REQUEST = 'ML_POLICIES_GETALL_REQUEST';
export const ML_POLICIES_GETALL_SUCCESS = 'ML_POLICIES_GETALL_SUCCESS';
export const ML_POLICIES_GETALL_FAILURE = 'ML_POLICIES_GETALL_FAILURE';
export const ML_POLICIES_GET_REQUEST = 'ML_POLICIES_GET_REQUEST';
export const ML_POLICIES_GET_SUCCESS = 'ML_POLICIES_GET_SUCCESS';
export const ML_POLICIES_GET_FAILURE = 'ML_POLICIES_GET_FAILURE';
export const ML_POLICIES_LIST_GET_REQUEST = 'ML_POLICIES_LIST_GET_REQUEST';
export const ML_POLICIES_LIST_GET_SUCCESS = 'ML_POLICIES_LIST_GET_SUCCESS';
export const ML_POLICIES_LIST_GET_FAILURE = 'ML_POLICIES_LIST_GET_FAILURE';
export const ML_POLICIES_GET_META_REQUEST = 'ML_POLICIES_GET_META_REQUEST';
export const ML_POLICIES_GET_META_SUCCESS = 'ML_POLICIES_GET_META_SUCCESS';
export const ML_POLICIES_GET_META_FAILURE = 'ML_POLICIES_GET_META_FAILURE';

/* Applications Actions */
export const APPLICATIONS_GET_REPOSITORY_REQUEST = 'APPLICATIONS_GET_REPOSITORY_REQUEST';
export const APPLICATIONS_GET_REPOSITORY_SUCCESS = 'APPLICATIONS_GET_REPOSITORY_SUCCESS';
export const APPLICATIONS_GET_REPOSITORY_FAILURE = 'APPLICATIONS_GET_REPOSITORY_FAILURE';
export const APPLICATIONS_GET_PURCHASED_REQUEST = 'APPLICATIONS_GET_PURCHASED_REQUEST';
export const APPLICATIONS_GET_PURCHASED_SUCCESS = 'APPLICATIONS_GET_PURCHASED_SUCCESS';
export const APPLICATIONS_GET_PURCHASED_FAILURE = 'APPLICATIONS_GET_PURCHASED_FAILURE';
export const APPLICATIONS_GET_ONE_PURCHASED_REQUEST = 'APPLICATIONS_GET_ONE_PURCHASED_REQUEST';
export const APPLICATIONS_GET_ONE_PURCHASED_SUCCESS = 'APPLICATIONS_GET_ONE_PURCHASED_SUCCESS';
export const APPLICATIONS_PURCHASE_REQUEST = 'APPLICATIONS_PURCHASE_REQUEST';
export const APPLICATIONS_PURCHASE_SUCCESS = 'APPLICATIONS_PURCHASE_SUCCESS';
export const APPLICATIONS_PURCHASE_FAILURE = 'APPLICATIONS_PURCHASE_FAILURE';
export const APPLICATIONS_UNINSTALL_REQUEST = 'APPLICATIONS_UNINSTALL_REQUEST';
export const APPLICATIONS_UNINSTALL_FAILURE = 'APPLICATIONS_UNINSTALL_FAILURE';
export const APPLICATIONS_UPGRADE_REQUEST = 'APPLICATIONS_UPGRADE_REQUEST';
export const APPLICATIONS_UPGRADE_FAILURE = 'APPLICATIONS_UPGRADE_FAILURE';
export const APPLICATIONS_STATUS_REQUEST = 'APPLICATIONS_STATUS_REQUEST';
export const APPLICATIONS_STATUS_SUCCESS = 'APPLICATIONS_STATUS_SUCCESS';
export const APPLICATIONS_STATUS_FAILURE = 'APPLICATIONS_STATUS_FAILURE';
export const APPLICATIONS_UPDATE_REQUEST = 'APPLICATIONS_UPDATE_REQUEST';
export const APPLICATIONS_UPDATE_SUCCESS = 'APPLICATIONS_UPDATE_SUCCESS';
export const APPLICATIONS_UPDATE_FAILURE = 'APPLICATIONS_UPDATE_FAILURE';
export const APPLICATIONS_ACTION_REQUEST = 'APPLICATIONS_ACTION_REQUEST';
export const APPLICATIONS_ACTION_SUCCESS = 'APPLICATIONS_ACTION_SUCCESS';
export const APPLICATIONS_ACTION_FAILURE = 'APPLICATIONS_ACTION_FAILURE';

/* Firewall Policies Actions */
export const FIREWALL_POLICIES_ADD_REQUEST = 'FIREWALL_POLICIES_ADD_REQUEST';
export const FIREWALL_POLICIES_ADD_SUCCESS = 'FIREWALL_POLICIES_ADD_SUCCESS';
export const FIREWALL_POLICIES_ADD_FAILURE = 'FIREWALL_POLICIES_ADD_FAILURE';
export const FIREWALL_POLICIES_UPD_REQUEST = 'FIREWALL_POLICIES_UPD_REQUEST';
export const FIREWALL_POLICIES_UPD_SUCCESS = 'FIREWALL_POLICIES_UPD_SUCCESS';
export const FIREWALL_POLICIES_UPD_FAILURE = 'FIREWALL_POLICIES_UPD_FAILURE';
export const FIREWALL_POLICIES_DEL_REQUEST = 'FIREWALL_POLICIES_DEL_REQUEST';
export const FIREWALL_POLICIES_DEL_SUCCESS = 'FIREWALL_POLICIES_DEL_SUCCESS';
export const FIREWALL_POLICIES_DEL_FAILURE = 'FIREWALL_POLICIES_DEL_FAILURE';
export const FIREWALL_POLICIES_GETALL_REQUEST = 'FIREWALL_POLICIES_GETALL_REQUEST';
export const FIREWALL_POLICIES_GETALL_SUCCESS = 'FIREWALL_POLICIES_GETALL_SUCCESS';
export const FIREWALL_POLICIES_GETALL_FAILURE = 'FIREWALL_POLICIES_GETALL_FAILURE';
export const FIREWALL_POLICIES_GET_REQUEST = 'FIREWALL_POLICIES_GET_REQUEST';
export const FIREWALL_POLICIES_GET_SUCCESS = 'FIREWALL_POLICIES_GET_SUCCESS';
export const FIREWALL_POLICIES_GET_FAILURE = 'FIREWALL_POLICIES_GET_FAILURE';
export const FIREWALL_POLICIES_LIST_GET_REQUEST = 'FIREWALL_POLICIES_LIST_GET_REQUEST';
export const FIREWALL_POLICIES_LIST_GET_SUCCESS = 'FIREWALL_POLICIES_LIST_GET_SUCCESS';
export const FIREWALL_POLICIES_LIST_GET_FAILURE = 'FIREWALL_POLICIES_LIST_GET_FAILURE';
export const FIREWALL_POLICIES_GET_META_REQUEST = 'FIREWALL_POLICIES_GET_META_REQUEST';
export const FIREWALL_POLICIES_GET_META_SUCCESS = 'FIREWALL_POLICIES_GET_META_SUCCESS';
export const FIREWALL_POLICIES_GET_META_FAILURE = 'FIREWALL_POLICIES_GET_META_FAILURE';

/* QOS Policies Actions */
export const QOS_POLICIES_ADD_REQUEST = 'QOS_POLICIES_ADD_REQUEST';
export const QOS_POLICIES_ADD_SUCCESS = 'QOS_POLICIES_ADD_SUCCESS';
export const QOS_POLICIES_ADD_FAILURE = 'QOS_POLICIES_ADD_FAILURE';
export const QOS_POLICIES_UPD_REQUEST = 'QOS_POLICIES_UPD_REQUEST';
export const QOS_POLICIES_UPD_SUCCESS = 'QOS_POLICIES_UPD_SUCCESS';
export const QOS_POLICIES_UPD_FAILURE = 'QOS_POLICIES_UPD_FAILURE';
export const QOS_POLICIES_DEL_REQUEST = 'QOS_POLICIES_DEL_REQUEST';
export const QOS_POLICIES_DEL_SUCCESS = 'QOS_POLICIES_DEL_SUCCESS';
export const QOS_POLICIES_DEL_FAILURE = 'QOS_POLICIES_DEL_FAILURE';
export const QOS_POLICIES_GETALL_REQUEST = 'QOS_POLICIES_GETALL_REQUEST';
export const QOS_POLICIES_GETALL_SUCCESS = 'QOS_POLICIES_GETALL_SUCCESS';
export const QOS_POLICIES_GETALL_FAILURE = 'QOS_POLICIES_GETALL_FAILURE';
export const QOS_POLICIES_GET_REQUEST = 'QOS_POLICIES_GET_REQUEST';
export const QOS_POLICIES_GET_SUCCESS = 'QOS_POLICIES_GET_SUCCESS';
export const QOS_POLICIES_GET_FAILURE = 'QOS_POLICIES_GET_FAILURE';
export const QOS_POLICIES_LIST_GET_REQUEST = 'QOS_POLICIES_LIST_GET_REQUEST';
export const QOS_POLICIES_LIST_GET_SUCCESS = 'QOS_POLICIES_LIST_GET_SUCCESS';
export const QOS_POLICIES_LIST_GET_FAILURE = 'QOS_POLICIES_LIST_GET_FAILURE';
export const QOS_POLICIES_GET_META_REQUEST = 'QOS_POLICIES_GET_META_REQUEST';
export const QOS_POLICIES_GET_META_SUCCESS = 'QOS_POLICIES_GET_META_SUCCESS';
export const QOS_POLICIES_GET_META_FAILURE = 'QOS_POLICIES_GET_META_FAILURE';
export const QOS_POLICIES_GET_TRAFFIC_MAP_REQUEST = 'QOS_POLICIES_GET_TRAFFIC_MAP_REQUEST';
export const QOS_POLICIES_GET_TRAFFIC_MAP_SUCCESS = 'QOS_POLICIES_GET_TRAFFIC_MAP_SUCCESS';
export const QOS_POLICIES_GET_TRAFFIC_MAP_FAILURE = 'QOS_POLICIES_GET_TRAFFIC_MAP_FAILURE';
export const QOS_POLICIES_UPD_TRAFFIC_MAP_REQUEST = 'QOS_POLICIES_UPD_TRAFFIC_MAP_REQUEST';
export const QOS_POLICIES_UPD_TRAFFIC_MAP_SUCCESS = 'QOS_POLICIES_UPD_TRAFFIC_MAP_SUCCESS';
export const QOS_POLICIES_UPD_TRAFFIC_MAP_FAILURE = 'QOS_POLICIES_UPD_TRAFFIC_MAP_FAILURE';

export const VERSION_GET_REQUEST = 'VERSION_GET_REQUEST';
export const VERSION_GET_SUCCESS = 'VERSION_GET_SUCCESS';
export const VERSION_GET_FAILURE = 'VERSION_GET_FAILURE';

/* VRRP Actions */
export const VRRP_GETALL_REQUEST = 'VRRP_GETALL_REQUEST';
export const VRRP_GETALL_SUCCESS = 'VRRP_GETALL_SUCCESS';
export const VRRP_GETALL_FAILURE = 'VRRP_GETALL_FAILURE';
export const VRRP_GET_REQUEST = 'VRRP_GET_REQUEST';
export const VRRP_GET_SUCCESS = 'VRRP_GET_SUCCESS';
export const VRRP_GET_FAILURE = 'VRRP_GET_FAILURE';
export const VRRP_SAVE_REQUEST = 'VRRP_SAVE_REQUEST';
export const VRRP_SAVE_SUCCESS = 'VRRP_SAVE_SUCCESS';
export const VRRP_SAVE_FAILURE = 'VRRP_SAVE_FAILURE';
export const VRRP_DEL_REQUEST = 'VRRP_DEL_REQUEST';
export const VRRP_DEL_SUCCESS = 'VRRP_DEL_SUCCESS';
export const VRRP_DEL_FAILURE = 'VRRP_DEL_FAILURE';
export const VRRP_DEVICE_INTERFACES_REQUEST = 'VRRP_DEVICE_INTERFACES_REQUEST';
export const VRRP_DEVICE_INTERFACES_SUCCESS = 'VRRP_DEVICE_INTERFACES_SUCCESS';
export const VRRP_DEVICE_INTERFACES_FAILURE = 'VRRP_DEVICE_INTERFACES_FAILURE';
export const VRRP_GET_STATUS_REQUEST = 'VRRP_GET_STATUS_REQUEST';
export const VRRP_GET_STATUS_SUCCESS = 'VRRP_GET_STATUS_SUCCESS';
export const VRRP_GET_STATUS_FAILURE = 'VRRP_GET_STATUS_FAILURE';
