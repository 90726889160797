import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { TT, LanguageContext } from '../containers/Language';

class Tickets extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props)
    this.state = { expanded: [] };
    this.paginationOptions = this.paginationOptions.bind(this);
    this.handleOnExpand = this.handleOnExpand.bind(this);
    this.renderHandler = this.renderHandler.bind(this);
  }

  componentDidMount() {
    this.props.getInfo(0, -1, resp => {});
  }

  handleOnExpand(row, isExpand, rowIndex, e) {
    if (isExpand) {
      this.props.getComments(row.id.toString());
      this.setState(() => ({
        expanded: [row.id]
      }));
    }
  }

  renderHandler() {
    let comments = (<pre>Loading...</pre>);
    if (this.props.tickets.comments.length > 0) {
      comments = [];
      this.props.tickets.comments.forEach(c => {
        comments.push(<pre style={{whiteSpace:"pre-wrap"}}>{c.body}</pre>);
        comments.push(<hr />);
      });
    }
    return row => {
      return (
          <div>
            {comments}
          </div>
      )
    }
  }

  paginationOptions = () => {
    const toTTString = this.context.toTTString
    const self = this;
    return {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => {
        return (
          <span className="react-bootstrap-table-pagination-total">
            <TT params={{from: from, to: to, size: self.props.tickets.info ? self.props.tickets.info.length : size}}>Showing #from# to #to# of #size# Results</TT>
          </span>
        )
      },
      sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}]
    }
  };
  render() {
    const toTTString = this.context.toTTString;
    const expandRow = {
      onlyOneExpanding: true,
      onExpand: this.handleOnExpand,
      expanded: this.state.expanded,
      renderer: this.renderHandler(),
    };

    return(
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home"><TT>Home</TT></Link></BreadcrumbItem>
          <BreadcrumbItem active><TT>Tickets</TT></BreadcrumbItem>
        </Breadcrumb>
        {this.props.tickets.info.length?
          <>
            <h4><TT>Ticket List</TT></h4>
            <BootstrapTable
                striped
                hover
                condensed
                keyField="id"
                data={this.props.tickets.info}
                columns={ticketsColumns(toTTString)}
                pagination={paginationFactory(this.paginationOptions())}
                noDataIndication={toTTString("No Tickets available")}
                defaultSorted={[{ dataField: "updated_at", order: "desc" }]}
                expandRow={ expandRow }
              />
          </>:null
        }
      </React.Fragment>
    );
  }
}

const ticketsColumns = (toTTString) => {
  return [
    { text: <TT>ID</TT>,
      dataField: "id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", textAlign: 'left' };
      }
    },
    {
      text: <TT>Subject</TT>,
      dataField: "subject",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "40%", textAlign: "left" };
      }
    },
    {
      text: <TT>Submitter</TT>,
      dataField: "via.source.from.name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "left" };
      }
    },
    {
      text: <TT>Updated At</TT>,
      dataField: 'updated_at',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: 'left' };
      },
      formatter: (cellContent, row) => {
        return (new Date(row.updated_at)
          .toLocaleString('en-US', { year: 'numeric',month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'}));
      }
    },
    {
      text: <TT>Status</TT>,
      dataField: "status",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", textAlign: 'left' };
      },
      formatter: (cellContent, row) => {
        return (<div>
                    <Badge color={row.status === 'pending'? 'primary': 'secondary'}>
                        <TT>{row.status}</TT>
                    </Badge>
                </div>);
      }
    }
  ];
}

export default Tickets;