import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
import { clearLocalSettings } from '../../utils/localSettings';
const { baseUrl } = window.SERVER_CONFIG;

export const Organizations = (
  state = {
    organization: null,
    organizations: [],
    isLoading: false,
    summary: {}
  },
  action
) => {
  switch (action.type) {
    case Actions.ORGANIZATION_ADD_REQUEST:
      return { ...state, organization: null };
    case Actions.ORGANIZATION_ADD_SUCCESS:
      return { ...state, organization: action.organization };
    case Actions.ORGANIZATION_ADD_FAILURE:
      return { ...state, organization: null };
    case Actions.ORGANIZATION_UPD_REQUEST:
      return { ...state, organization: action.organization };
    case Actions.ORGANIZATION_UPD_SUCCESS:
      return { ...state };
    case Actions.ORGANIZATION_UPD_FAILURE:
      return { ...state, organization: null };
    case Actions.ORGANIZATION_DEL_REQUEST:
      return { ...state, organization: action.organization };
    case Actions.ORGANIZATION_DEL_SUCCESS:
      return { ...state };
    case Actions.ORGANIZATION_DEL_FAILURE:
      return { ...state, organization: null };
    case Actions.ORGANIZATION_SELECT_REQUEST:
      return { ...state };
    case Actions.ORGANIZATION_SELECT_SUCCESS:
      return { ...state, organization: action.organization };
    case Actions.ORGANIZATION_SELECT_FAILURE:
      return { ...state };
    case Actions.ORGANIZATION_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ORGANIZATION_GETALL_SUCCESS:
      return { ...state, organizations: action.organizations, isLoading: false };
    case Actions.ORGANIZATION_GETALL_FAILURE:
      return { ...state, organizations: [], isLoading: false };
    case Actions.ORGANIZATION_GET_SUMMARY_REQUEST:
      return { ...state, isLoading: true };
    case Actions.ORGANIZATION_GET_SUMMARY_SUCCESS:
      return { ...state, summary: action.summary, isLoading: false };
    case Actions.ORGANIZATION_GET_SUMMARY_FAILURE:
      return { ...state, summary: {}, isLoading: false };
    default:
      return state;
  }
};

/* Action Creators */
/**********************************************************************
 *   Add Organization
 **********************************************************************/
export const requestAddOrganization = organization => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_ADD_REQUEST,
    organization
  });
};

export const receiveAddOrganization = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_ADD_SUCCESS,
    organization: response
  });
  dispatch(mainRedirectTo("/organizations"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Organization " + response.name + " added successfully"
    })
  );
};

export const failureAddOrganization = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const addOrganization = (organization, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "organizations",
      method: "POST",
      body: JSON.stringify(organization),
      requestCB: () => {
        dispatch(requestAddOrganization(organization));
      },
      successCB: response => {
        dispatch(receiveAddOrganization(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddOrganization(error));
      }
    })
  );
};

/**********************************************************************
 *   Update Organization
 **********************************************************************/
export const requestUpdOrganization = organization => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_UPD_REQUEST,
    organization
  });
};

export const receiveUpdOrganization = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_UPD_SUCCESS
  });
  dispatch(mainRedirectTo("/organizations"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Organization " + store.getState().organizations.organization.name + " updated successfully"
    })
  );
};

export const failureUpdOrganization = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

// success_cb is a function to call when successfully added
// It mainly reset the form
export const updOrganization = (organization, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "organizations/" + organization._id,
      method: "PUT",
      body: JSON.stringify(organization),
      requestCB: () => {
        dispatch(requestUpdOrganization(organization));
      },
      successCB: response => {
        dispatch(receiveUpdOrganization(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdOrganization(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete Organization
 **********************************************************************/
export const requestDelOrganization = organization => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_DEL_REQUEST,
    organization
  });
};

export const receiveDelOrganization = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_DEL_SUCCESS
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Organization " + store.getState().organizations.organization.name + " deleted successfully"
    })
  );
};

export const failureDelOrganization = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_DEL_FAILURE
  });
};

export const delOrganization = (organization, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "organizations/" + organization._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelOrganization(organization));
      },
      successCB: response => {
        dispatch(receiveDelOrganization(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelOrganization(error));
      }
    })
  );
};

/**********************************************************************
 *   Select Organization
 **********************************************************************/
export const requestSelectOrganization = organization => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_SELECT_REQUEST,
    organization
  });
};

export const receiveSelectOrganization = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_SELECT_SUCCESS,
    organization: response
  });
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Organization " + store.getState().organizations.organization.name + " set"
    })
  );
};

export const failureSelectOrganization = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_SELECT_FAILURE
  });
};

export const selectOrganization = (organization, success_cb) => dispatch => {
  clearLocalSettings();
  return dispatch(
    FWFetch({
      url: baseUrl + "organizations/select",
      method: "POST",
      body: JSON.stringify(organization),
      requestCB: () => {
        dispatch(requestSelectOrganization(organization));
      },
      successCB: response => {
        dispatch(receiveSelectOrganization(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureSelectOrganization(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all organizations
 **********************************************************************/
export const requestGetAllOrganizations = () => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GETALL_REQUEST
  });
};

export const receiveGetAllOrganizations = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GETALL_SUCCESS,
    organizations: response
  });
};

export const failureGetAllOrganizations = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GETALL_FAILURE
  });
};

export const getAllOrganizations = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "organizations",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllOrganizations());
      },
      successCB: response => {
        dispatch(receiveGetAllOrganizations(response));
      },
      failureCB: error => {
        dispatch(failureGetAllOrganizations(error));
      }
    })
  );
};

/**********************************************************************
 *   Get organization
 **********************************************************************/
export const requestGetOrganization = () => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GET_REQUEST
  });
};

export const receiveGetOrganization = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GET_SUCCESS,
    organization: response
  });
};

export const failureGetOrganization = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GET_FAILURE
  });
};

export const getOrganization = (organizationId, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "organizations/" + organizationId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetOrganization());
      },
      successCB: response => {
        dispatch(receiveGetOrganization((Object.keys(response).length!==0)?response:null));
        success_cb((Object.keys(response).length!==0)?response:null);
      },
      failureCB: error => {
        dispatch(failureGetOrganization(error));
      }
    })
  );
};

/**********************************************************************
 *   Get organization summary
 **********************************************************************/
 export const requestGetOrganizationSummary = () => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GET_SUMMARY_REQUEST
  });
};

export const receiveGetOrganizationSummary = response => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GET_SUMMARY_SUCCESS,
    summary: response
  });
};

export const failureGetOrganizationSummary = error => dispatch => {
  dispatch({
    type: Actions.ORGANIZATION_GET_SUMMARY_FAILURE
  });
};

export const getOrganizationSummary = () => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + 'organizations/summary',
      method: "GET",
      requestCB: () => {
        dispatch(requestGetOrganizationSummary());
      },
      successCB: response => {
        dispatch(receiveGetOrganizationSummary(response));
      },
      failureCB: error => {
        dispatch(failureGetOrganizationSummary(error));
      }
    })
  );
};
