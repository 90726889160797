import React, {useContext} from "react";
import { Badge } from "reactstrap";
import { TT, LanguageContext } from '../../containers/Language'

export default function InternetAccessBadge ({deviceStatus, row}) {
  const { toTTString } = useContext(LanguageContext);
  const badgeParams = {};
  let internetAccess = row.internetAccess;
  const isConnected = deviceStatus && deviceStatus.connection === 'connected';
  if (deviceStatus && deviceStatus.interfaces) {
    const interfaceStatus = deviceStatus.interfaces.find(i => i.devId === row.devId);
    if (interfaceStatus) {
      internetAccess = interfaceStatus.internetAccess;
    }
  };
  if (!row.monitorInternet || internetAccess === '') {
    return <i><TT>internet monitoring not available</TT></i>;
  } else if (!isConnected) {
    badgeParams.color = 'danger';
    badgeParams.text = toTTString('not connected');
  } else if (internetAccess === 'yes') {
    badgeParams.color = 'success';
    badgeParams.text = toTTString('online');
  } else {
    badgeParams.color = 'danger';
    badgeParams.text = toTTString('offline');
  };

  return <Badge color={badgeParams.color}>
    {badgeParams.text}
  </Badge>
}