import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import { TT } from '../../containers/Language';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { getDeviceRecoveryInfo } from '../../redux/reducers/Devices';

const getUrlForSavingRecoveryInfo = recoveryInfo => {
  const data = [recoveryInfo];
  const properties = {type: 'text/plain'};
  let file;
  try {
    file = new File(data, "fwagent_info.txt", properties);
  } catch (e) {
    file = new Blob(data, properties);
  }
  return URL.createObjectURL(file);
};

const RecoveryInfoCard = props => {
  const { id: deviceId, getDeviceRecoveryInfo } = props;

  useEffect(() => {
    getDeviceRecoveryInfo(deviceId);
  }, [deviceId, getDeviceRecoveryInfo]);

  return (
    <>
      <Row>
        <Col md={7}>
          <Card>
            <CardBody>
              <div
                className="alert alert-small alert-warning col-md-12"
                role="alert"
              >
                <TT>Use this procedure to recover the same hardware instance. To recover to a different hardware use the "Replace Device" action in the devices page</TT>
              </div>
              <Row>
                <Col className='col-1'>
                  <div className="textCircle">
                    1
                  </div>
                </Col>
                <Col className='col-11'>
                  <div className="stepText" style={{marginBottom:'10px'}}>
                    <p><TT>Save or Copy the information below</TT>:</p>
                    <pre style={{marginBottom:'20px', wordWrap:'break-word', whiteSpace:'break-spaces'}}>
                      {props.recoveryInfo || 'Please wait ...'}
                    </pre>
                    <a download="fwagent_info.txt" href={getUrlForSavingRecoveryInfo(props.recoveryInfo)}>
                      <Button
                        color='primary'
                        disabled={!props.recoveryInfo}
                        className='mr-1'
                        onClick={()=>getUrlForSavingRecoveryInfo(props.recoveryInfo)}>
                        <TT>Save</TT>
                      </Button>
                    </a>
                    <CopyToClipboard text={props.recoveryInfo}
                      onCopy={() => {
                        toast(<TT>Output was copied to clipboard!</TT>, {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });}}>
                      <Button
                        color='primary'
                        disabled={!props.recoveryInfo}>
                        <TT>Copy</TT>
                      </Button>
                    </CopyToClipboard>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='col-1'>
                  <div className="textCircle">
                    2
                  </div>
                </Col>
                <Col className='col-11'>
                  <div className="stepText">
                    <p><TT>Save it on the device in</TT>:</p>
                    <pre style={{marginBottom:'20px', wordWrap:'break-word', whiteSpace:'break-spaces'}}>
                      /etc/flexiwan/agent/fwagent_info.txt
                    </pre>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='col-1'>
                  <div className="textCircle">
                    3
                  </div>
                </Col>
                <Col className='col-11'>
                  <div className="stepText">
                    <p><TT>Restart the device control service</TT></p>
                    <pre style={{marginBottom:'20px', wordWrap:'break-word', whiteSpace:'break-spaces'}}>
                      sudo systemctl restart flexiwan-router
                    </pre>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='col-1'>
                  <div className="textCircle">
                    4
                  </div>
                </Col>
                <Col className='col-11'>
                  <div className="stepText">
                    <p><TT>Wait till the configuration is synced to your device</TT></p>
                  </div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getDeviceRecoveryInfo: deviceId => dispatch(getDeviceRecoveryInfo(deviceId))
  }
};

const mapStateToProps = state => {
  return {
    recoveryInfo: state.devices.recoveryInfo
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryInfoCard);