import React, { Component } from 'react';
import { Row, Col, Label, Button, ButtonGroup } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import { FWTextInput } from '../Common';
import { minValue, maxValue } from '../../utils/Validators';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './DeviceInfo.css';
import InfoNotAvailable from "../items/infoNotAvailable";
import { TT, LanguageContext } from '../../containers/Language'

class LogsCard extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.state = {
      logs: null,
      logsFetched: false,
      status: '',
      logViewType: 'raw',
      filter: '',
      displayOrder: 'recent-first',
      infoNotAvailable: false,
      logOptions: {
        fwagent: { text: 'flexiEdge Agent' },
        agentui: { text: 'flexiEdge UI' },
        syslog: { text: 'syslog' },
        dhcp: { text: 'DHCP Server' },
        vpp: { text: 'VPP' },
        ospf: { text: 'Routing' },
        hostapd: { text: 'Hostapd' },
        application_ids: { text: 'App Identifications' }
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
  }

  componentDidMount() {
    const updatedLogOptions = { ...this.state.logOptions };

    this.props.getDevice(this.props.id, resp => {
      const { applications } = resp;
      applications.forEach(application => {
        const { app } = application;
        if (app) {
          const { name, identifier } = app.appStoreApp;
          updatedLogOptions[identifier] = { text: name, isApp: true};
        }
      })

      this.setState({logOptions: updatedLogOptions })
    });
  }

  reverseArray(array) {
    let reversed = [];
    for (let i = array.length - 1; i >= 0; i--) {
      reversed.push(array[i]);
    }

    return reversed;
  }

  handleSubmit(values) {
    this.setState({
      logsFetched: false,
    });

    this.props.getDeviceLogs(
      this.props.id,
      { limit: values.limit, filter: values.filter },
      (resp) => {
        if (resp.error === 'timeout') {
          this.setState({ infoNotAvailable: true });
        } else {
          this.setState({
            logs: resp.logs,
            reversedLogs: this.reverseArray(resp.logs),
            status: resp.deviceStatus,
            logsFetched: true,
            filter: values.filter,
            infoNotAvailable: false
          });
        }
      },
      () => {
        this.setState({
          logsFetched: true,
          status: 'error',
          logs: ''
        });
      }
    );
  }

  renderRaw(logs) {
    return (
      <div>
        <h5><TT>Logs</TT>:</h5>
        <pre className="log-lines-wrapper">{`${logs.join('\n')}`}</pre>
      </div>
    );
  }

  renderTable(logs) {

    const toTTString = this.context.toTTString

    const columns = [
      {
        text: '#',
        dataField: 'index',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'left' };
        },
      },
      {
        text: <TT>Logs</TT>,
        dataField: 'message',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '90%', textAlign: 'left' };
        },
      },
    ];

    const paginationOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 1,
      firstPageText: toTTString('First'),
      prePageText: toTTString('Back'),
      nextPageText: toTTString('Next'),
      lastPageText: toTTString('Last'),
      nextPageTitle: toTTString('Next page'),
      prePageTitle: toTTString('Pre page'),
      firstPageTitle: toTTString('First page'),
      lastPageTitle: toTTString('Last page'),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    return (
      <BootstrapTable
        striped
        hover
        condensed
        keyField="index"
        data={logs.map((message, index) => {
          return { index: index + 1, message };
        })}
        columns={columns}
        pagination={paginationFactory(paginationOptions)}
        noDataIndication={toTTString("No logs are available")}
        defaultSorted={[{ dataField: 'index', order: 'asc' }]}
      />
    );
  }

  setDisplayOrder(newDisplayOrder) {
    this.setState({
      displayOrder: newDisplayOrder,
    });
  }

  renderLogs() {

    const toTTString = this.context.toTTString

    const { logs, reversedLogs, displayOrder, logsFetched, logViewType } = this.state;

    if (!logs) return;

    if (!logsFetched) return <div>Loading...</div>;

    if (logs.length === 0) return toTTString('No logs found');

    const displayLogs = displayOrder === 'recent-first' ? reversedLogs : logs;

    if (logViewType === 'table') return this.renderTable(displayLogs);

    return this.renderRaw(displayLogs);
  }

  onCheckboxBtnClick = (logViewType) => {
    this.setState({
      logViewType: logViewType,
    });
  };

  createLogDownloadUrl() {
    const { logs, reversedLogs, displayOrder, filter } = this.state;
    const downloadLogs = displayOrder === 'recent-first' ? reversedLogs : logs;
    const data = downloadLogs ? [downloadLogs.join('\n')] : [];
    const properties = { type: 'text/plain' };
    let file;
    // Try using File but fallback to Blob
    try {
      file = new File(data, `${filter}.txt`, properties);
    } catch (e) {
      file = new Blob(data, properties);
    }
    return URL.createObjectURL(file);
  }

  render() {
    const toTTString = this.context.toTTString

    this.logOptions = {
      fwagent: toTTString('flexiEdge Agent'),
      agentui: toTTString('flexiEdge UI'),
      syslog: toTTString('syslog'),
      dhcp: toTTString('DHCP Server'),
      vpp: toTTString('VPP'),
      ospf: toTTString('OSPF'),
      hostapd: toTTString('Hostapd'),
      application_ids: toTTString('App Identifications')
    };


    const {
      logs,
      status,
      logViewType,
      filter,
      logsFetched,
      displayOrder,
    } = this.state;

    return (
      <React.Fragment>
        <Form
          className="log-card-upper-panel"
          model="LogsCard"
          onSubmit={(values) => this.handleSubmit(values)}
        >
          <Row className="form-group">
            <Label htmlFor="limit" md={1} xs={1}>
              <TT>Lines</TT>
            </Label>
            <Col md={2} xs={4}>
              <Control.text
                model=".limit"
                id="limit"
                name="limit"
                placeholder={toTTString("Number of log lines (default is 100)")}
                component={FWTextInput}
                withFieldValue
                validators={{
                  minValue: minValue(0),
                  maxValue: maxValue(10000),
                }}
              />
              <Errors
                className="text-danger"
                model=".limit"
                messages={{
                  minValue: toTTString('Number of lines must be a positive number'),
                  maxValue: toTTString('Number of lines cannot exceed 10000'),
                }}
              />
            </Col>
            <Label htmlFor="filter" md={1} xs={1}>
              <TT>Filter</TT>
            </Label>
            <Col md={3} xs={3}>
              <Control.select
                model=".filter"
                id="filter"
                name="filter"
                className="form-control"
              >
                {Object.keys(this.state.logOptions).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {this.state.logOptions[key].text}
                    </option>
                  );
                })}
              </Control.select>
            </Col>
            <Col md={2} xs={3}>
              <Control.button
                model="LogsCard"
                className="btn btn-primary action-btn-top"
              >
                <TT>Fetch Logs</TT>
              </Control.button>
            </Col>
            <Col md={{ offset: 0 }} xs={{ offset: 0 }}>
              <ButtonGroup className="withMargin">
                <Button
                  className="transp-btn"
                  data-tip
                  data-for="first-a"
                  onClick={() => this.setDisplayOrder('recent-first')}
                  active={displayOrder === 'recent-first'}
                >
                  <FontAwesomeIcon icon="sort-amount-up" fixedWidth />
                </Button>
                <Button
                  className="transp-btn"
                  data-tip
                  data-for="last-a"
                  onClick={() => this.setDisplayOrder('recent-last')}
                  active={displayOrder === 'recent-last'}
                >
                  <FontAwesomeIcon icon="sort-amount-down" fixedWidth />
                </Button>
              </ButtonGroup>
              <ReactTooltip id="first-a">
                <span><TT>Display recent first</TT></span>
              </ReactTooltip>
              <ReactTooltip id="last-a">
                <span><TT>Display recent last</TT></span>
              </ReactTooltip>
              <ButtonGroup className="withMargin">
                <Button
                  className="transp-btn"
                  data-tip
                  data-for="raw-a"
                  onClick={() => this.onCheckboxBtnClick('raw')}
                  active={logViewType === 'raw'}
                >
                  <FontAwesomeIcon icon="receipt" fixedWidth />
                </Button>
                <Button
                  className="transp-btn"
                  data-tip
                  data-for="table-a"
                  onClick={() => this.onCheckboxBtnClick('table')}
                  active={logViewType === 'table'}
                >
                  <FontAwesomeIcon icon="list" fixedWidth />
                </Button>
              </ButtonGroup>
              <ReactTooltip id="raw-a">
                <span><TT>Display raw</TT></span>
              </ReactTooltip>
              <ReactTooltip id="table-a">
                <span><TT>Display table</TT></span>
              </ReactTooltip>
              <Button
                className="transp-btn-with-margin"
                data-tip
                data-for="download-a"
                disabled={!logsFetched || status === 'error'}
                download={`${filter}.log`}
                href={this.createLogDownloadUrl()}
                onClick={() => this.createLogDownloadUrl()}
              >
                <FontAwesomeIcon icon="download" fixedWidth />
              </Button>
              <ReactTooltip id="download-a">
                <span>
                  <TT params={{name: this.state.logOptions[filter]?.text}}>
                    Download #name# Logs
                    </TT>
                </span>
              </ReactTooltip>
            </Col>
          </Row>
        </Form>

        <InfoNotAvailable show={this.state.infoNotAvailable} />

        {!logs
          ? ''
          : status === 'disconnected'
          ? <TT>Device must be connected to view logs</TT>
          : this.renderLogs()}
      </React.Fragment>
    );
  }
}

export default LogsCard;
