import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip';
import { TT, LanguageContext } from '../../containers/Language'

class MultiLinkPolicies extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);

    this.state = {
      mlpolicies: [],
      delModalOpen: false,
      currentPolicy: {}
    };

    this.installCountMap = {
      installed: { color: "black", text: 'Installed' },
      pending: { color: "black", text: 'Pending' },
      failed: { color: "red", text: 'Failed' },
      deleted: { color: "red", text: 'Job removed' },
    }

    this.policyColumns = [
      { text: <TT>rowID</TT>, dataField: "_id", hidden: true },
      {
        text: <TT>Name</TT>,
        dataField: "name",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        }
      },
      {
        text: <TT>Description</TT>,
        dataField: "description",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "50%", textAlign: "left" };
        }
      },
      {
        text: <TT>Status</TT>,
        dataField: "installCount",
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          const { installCount } = row;
          const status = [];

          Object.entries(installCount).forEach(([field, value]) => {
            const { color, text } = this.installCountMap[field];
            if (field === 'installed' || value !== 0) {
              status.push(
                <div key={field}>
                  <span style={{color: color}}>
                    <TT>{text}</TT>{`: ${value}`}
                  </span>
                </div>
              );
            }
          });
          return status
        }
      },
      {
        text: <TT>Actions</TT>,
        dataField: "none",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return {
            width: "10%",
            textAlign: "left"
          };
        },
        formatter: (cellContent, row) => {
          const { _id, name } = row
          return (
            <React.Fragment>
              <Link to={`/pathselectionpolicies/${_id}`}>
                <Button
                  color="warning"
                  className="action-btn"
                  data-tip
                  data-for="update-a"
                  size="sm"
                >
                  <FontAwesomeIcon icon="cog" fixedWidth />
                </Button>
              </Link>
              <ReactTooltip id="update-a">
                <span><TT>Edit Policy</TT></span>
              </ReactTooltip>
              <Link to={`/devices?mlpolicy=${name}`}>
                <Button
                  className="action-btn"
                  data-tip
                  data-for="targets-a"
                  color="success"
                  size="sm"
                >
                  <FontAwesomeIcon icon={["fas", "filter"]} fixedWidth/>
                </Button>
                <ReactTooltip id="targets-a">
                  <span><TT>Show all devices running this policy</TT></span>
                </ReactTooltip>
              </Link>
              <Button
                color="danger"
                className="action-btn"
                data-tip
                data-for="delete-a"
                size="sm"
                onClick={() => this.handleDelete(row)}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth />
              </Button>
              <ReactTooltip id="delete-a">
                <span><TT>Delete</TT></span>
              </ReactTooltip>
            </React.Fragment>
          );
        }
      }
    ];

    this.toggleDelModal = this.toggleDelModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.approveDelete = this.approveDelete.bind(this);
  }

  componentDidMount() {
    this.props.getMLPoliciesMetaData(resp => {
      this.setState({ mlpolicies: resp })
    });
  }

  toggleDelModal() {
    this.setState({ delModalOpen: !this.state.delModalOpen });
  }

  handleDelete(row) {
    this.setState({ currentPolicy: row });
    this.toggleDelModal();
  }

  approveDelete() {
    const { currentPolicy } = this.state;

    this.props.delMLPolicy(currentPolicy, () => {
      this.props.getMLPoliciesMetaData(resp => {
        this.setState({ mlpolicies: resp })
      });
    })
    this.toggleDelModal();
  }

  render() {
    const toTTString = this.context.toTTString

    this.MLPoliciesOptions = {
      paginationSize: 5,
      alwaysShowAllBtns: true,
      pageStartIndex: 0,
      firstPageText: toTTString("First"),
      prePageText: toTTString("Back"),
      nextPageText: toTTString("Next"),
      lastPageText: toTTString("Last"),
      nextPageTitle: toTTString("First page"),
      prePageTitle: toTTString("Pre page"),
      firstPageTitle: toTTString("Next page"),
      lastPageTitle: toTTString("Last page"),
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          <TT params={{from: from, to: to, size: size}}>Showing #from# to #to# of #size# Results</TT>
        </span>
      ),
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "100", value: 100 }
      ]
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      clickToExpand: false,
      // selected: () => {},
      // onSelect: () => {},
      // onSelectAll: () => {}
    };

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/home"><TT>Home</TT></Link>
          </BreadcrumbItem>
          <BreadcrumbItem active><TT>Policies</TT></BreadcrumbItem>
          <BreadcrumbItem active><TT>Path Selection Policies</TT></BreadcrumbItem>
        </Breadcrumb>
        <h4><TT>Path Selection Policies</TT></h4>
        <div className="col-md-12">
          <div className="mb-3">
            <ReactTooltip id="refresh-a">
              <span><TT>Refresh</TT></span>
            </ReactTooltip>
            <Button
              color="info"
              className="refresh-btn"
              data-tip
              data-for="refresh-a"
              size="sm"
              onClick={() => {
                this.props.getMLPoliciesMetaData(resp => {
                  this.setState({ mlpolicies: resp });
                });
              }}
            >
              <FontAwesomeIcon icon="sync-alt" />
            </Button>
            <Link to="/pathselectionpolicies/add">
              <Button color="success" className="action-btn-top" size="sm">
                <TT>New Policy</TT>
              </Button>
            </Link>
          </div>
          <BootstrapTable
            striped
            hover
            condensed
            keyField="_id"
            data={this.state.mlpolicies}
            columns={this.policyColumns}
            pagination={paginationFactory(this.MLPoliciesOptions)}
            noDataIndication={toTTString("No Path Selection policies available")}
            defaultSorted={[{ dataField: "name", order: "asc" }]}
            selectRow={selectRow}
          />
        </div>
        <Modal isOpen={this.state.delModalOpen} toggle={this.toggleDelModal}>
          <ModalHeader toggle={this.toggleDelModal}><TT>Delete Policy</TT></ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <TT params={{policyName: (this.state.currentPolicy.name) ? this.state.currentPolicy.name : ""}}>Delete policy #policyName#</TT>
            </div>
            <Button color="danger" onClick={this.approveDelete}>
              <TT>Yes</TT>
            </Button>
            <Button
              className="float-right"
              color="outline-secondary"
              onClick={this.toggleDelModal}
            >
              <TT>Cancel</TT>
            </Button>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default MultiLinkPolicies;
