import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Label, Card, CardBody, Badge, CardHeader, Button, Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Control, Form, Errors, actions } from 'react-redux-form';
import { FWTextInput, FWPasswordInput } from '../../Common';
import { isNumber, required, length } from '../../../utils/Validators';
import ReactTooltip from 'react-tooltip';
import InfoNotAvailable from "../../items/infoNotAvailable";
import moment from 'moment';
import './ConfigureLte.css';
import { TT, LanguageContext } from '../../../containers/Language'
import Loading from '../../items/Loading';
import ConfirmationModal from '../../items/ConfirmationModal';
import Signal from '../../items/Signal';

const isPinDisabled = pinState => {
  return !pinState || pinState === 'disabled'
}

const getPinFieldValidators = pinState => {
  return {
    required: val => isPinDisabled(pinState) || required(val),
    length: val => isPinDisabled(pinState) || length(4)(val) || length(8)(val),
    isNumber: isNumber
  }
};

class ConfigureLTE extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);
    this.statusTimer = null;
    this.successPinMessage = "PIN configured successfully!";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleGetApn = this.handleGetApn.bind(this);
    this.updateLteMonitorInfo = this.updateLteMonitorInfo.bind(this);
    this.getPinState = this.getPinState.bind(this);
    this.togglePinModal = this.togglePinModal.bind(this);
    this.handlePinOperations = this.handlePinOperations.bind(this);

    this.state = {
      monitorLoading: false,
      info: {},
      interface: null,
      passwordRequired: false,
      pinModalIsOpen: false,
      blockPinModel: false,
      showPendingMessage: null,
      pinIsChanged: false,
      infoNotAvailable: false,
      skipUpdatingLteInfo: false, // sometimes we don't want updating LTE info,
      confirmationModal: {
        isOpen: false,
        question: '',
        title: '',
        onConfirm: () => {}
      }
    }
  }

  handleClose() {
    if (typeof this.props.onSubmit === 'function')
      this.props.onSubmit(null);
  }

  save = (values) => {
    const data = { ...values };

    // we changed the fields names to prevent user/password autofill.
    // before sending to server, we renamed those fields back.
    data.user = data.lteUser;
    data.password = data.ltePassword;
    delete data.lteUser;
    delete data.ltePassword;

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(
        data
      )
    }
  }

  handleSubmit(values) {
    this.save(values)
  }

  togglePinModal() {
    const newState = { pinModalIsOpen: !this.state.pinModalIsOpen };

    // ensure skipUpdatingLteInfo is false when modal is closed
    if (newState.pinModalIsOpen === false) {
      newState.skipUpdatingLteInfo = false;
    } else {
      newState.skipUpdatingLteInfo = true;
    }

    this.setState(newState);
  }

  removeLteInfoFromCache = () => {
    const lteStatus = this?.props?.deviceStatus?.lteStatus ?? {};
    const devId = this?.props?.interface?.devId;
    const hasLteInCache = devId in lteStatus;
    if (hasLteInCache) {
      delete this?.props?.deviceStatus?.lteStatus[devId];
    }
  }


  /**
   * LTE monitoring info is sent with get-device-status and saved in the server memory.
   * We can fetch the monitoring info from the server cache of from the device directly.
   * It depends on router version. We send it with -get-device-status only from 5.2.X versions.
   * Fetching the data from the device might take time.
   * The "fromServer" parameter indicates if to fetch data from cache or from the device.
   * @param  {boolean?} fromServer indicates if to fetch data from cache or from the device.
   * @return {void}
   */
  updateLteMonitorInfo(fromServer=false) {

    const fillApnIfNeeded = (defaultSettings) => {
      // if lte configurations are empty, it means that the user never configured it
      // and in this case, we fill the apn automatically
      const configurationIsEmpty = Object.keys(this.props.interface?.configuration ?? {}).length === 0;
      const formApnState = this.props.lteForm.apn;
      if (
        configurationIsEmpty &&
        (formApnState.value === '' || formApnState.value === undefined) && // don't change existing value
        formApnState.touched === false // don't change if user touched this field
      ) {
        this.handleGetApn(defaultSettings)
      }
    }

    if (this.state.skipUpdatingLteInfo) return;

    const lteStatus = this?.props?.deviceStatus?.lteStatus ?? {};
    const devId = this?.props?.interface?.devId;
    const hasLteInCache = devId in lteStatus;
    if (!fromServer && !this.state.monitorLoading && hasLteInCache) {
      this.setState({ info: lteStatus[devId] })
      fillApnIfNeeded(lteStatus[devId].defaultSettings)
      this.props.validatePinField(this.getPinState())
      return;
    };

    // fetch from server only if there is already pending requests
    if (this.state.monitorLoading) {
      return;
    }

    this.setState({monitorLoading: true})

    this.props.getInterfaceStatus(this.props.interface._id, resp => {
      const newState = { monitorLoading: false, infoNotAvailable: false };
      if (resp?.error === 'timeout') {
        // if error, set to false, then we can try to call it again in couple of seconds
        const stateIsEmpty = Object.keys(this.state.info).length === 0;
        if (stateIsEmpty) {
          newState.infoNotAvailable = true;
        }
      } else if (resp) {
        // info arrived from device
        newState.info = resp.status;
      }

      this.setState(newState);
      this.props.validatePinField(this.getPinState())

      // if apn is empty, try to get it from defaultSettings
      fillApnIfNeeded(resp.status.defaultSettings)
    });
  }

  componentDidMount() {
    const useCache = true;

    // update LTE monitoring periodically
    if (this.statusTimer === null) {
      const timer = setInterval(() => {
        if (document.hasFocus()) {
          this.updateLteMonitorInfo(!useCache)
        }
      }, 15000)
      this.statusTimer = timer;
    }
    this.updateLteMonitorInfo(!useCache)

    // fill form with user configurations
    const configurationIsEmpty = Object.keys(this.props.interface?.configuration ?? {}).length === 0;
    if (!configurationIsEmpty) {
      const conf = {...this.props.interface.configuration};

      // to prevent user/password autofill, we can't use "user" and "password" field names.
      // hence, we renamed those fields in UI only.
      conf.lteUser = conf.user;
      conf.ltePassword = conf.password;

      this.props.changeForm('configureLteInterface')(conf)
    }

    let metric = this.props.interface.metric;
    if (!metric || metric === '') {
      metric = '0'
    }
    this.props.changeForm('configureLteInterface.metric')(metric)
  }

  componentWillUnmount() {
    if (this.statusTimer != null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
    this.props.resetLteForm()
  }

  handleGetApn(defaultSettings) {
    if (defaultSettings) {
      this.props.changeForm('configureLteInterface.apn')(defaultSettings.apn);
      this.props.changeForm('configureLteInterface.ltePassword')(defaultSettings.password);
      this.props.changeForm('configureLteInterface.lteUser')(defaultSettings.username);
      if (defaultSettings?.auth !== 'none') {
        this.props.changeForm('configureLteInterface.auth')(defaultSettings.auth);
      }
    }
  }

  closeConfirmationModal = (val) => {
    this.setState({
      confirmationModal: {
        ...this.state.confirmationModal,
        isOpen: val === undefined ? !this.state.confirmationModal.isOpen : val
      }
    })
  }

  performResetModem = () => {
    this.setState({monitorLoading: true})
    this.props.performInterfaceAction(
      this.props.interface._id,
      { op: 'reset'},
      () => {
        this.setState({monitorLoading: false })
        this.closeConfirmationModal(false)
        this.updateLteMonitorInfo(true)
      },
      null,
      "Reset sent to device. It might take a bit to reconnect")
  }

  handleReset() {
    const retriesLeft = this.getPinState('pin1Retries')
    const pinStatus = this.getPinState('pin1Status')
    if (retriesLeft && +retriesLeft === 1 && pinStatus !== 'blocked') {
      // show confirmation modal
      this.setState({ confirmationModal: {
        isOpen: true,
        title: 'Reset Modem',
        question: 'Your SIM has only one PIN retry left. Continue with Reset Modem with a wrong PIN can block your SIM card. Make sure your PIN is correct. Continue with Reset Modem?',
        onConfirm: this.performResetModem
      }})
      return;
    } else {
      this.performResetModem()
    }
  }

  updateFormPinAndSubmit = (pin) => {
    this.props.changeForm('configureLteInterface.pin')(pin);

    // we save it to redux store automatically because we need force the user
    // click on update device button to update the device configuration with the new pin
    if (typeof this.props.onSubmit === 'function')
      // we submit the form only if user touched it.
      // If user opened the LTE screen and immediately opened the PIN modal, without touching the form
      // we don't want to save partial part of form since it skip form validation
      if (this.props.interface.configuration.enable !== undefined) {
        this.props.onSubmit({
          ...this.props.interface.configuration,
          pin: pin,
          metric: this.props.interface.metric
        }, false)
      }
  }

  handlePinOperations(values) {
    const formWidth = document.querySelector('#pinModalCardBody').offsetWidth;
    const formHeight = document.querySelector('#pinModalCardBody').offsetHeight;
    this.setState({monitorLoading: true, blockPinModel: true, showPendingMessage: null, modalSize:{width:formWidth, height: formHeight}});
    return new Promise((resolve, reject) => {
      this.props.performInterfaceAction(
        this.props.interface._id,
        { op: 'pin', params: values},
        (resp, err) => {

          // here resp might be null
          if (resp?.error === 'timeout' || resp?.deviceStatus === 'disconnected') {
            this.setState({
              monitorLoading: false,
              showPendingMessage: <TT>Information not available, Please check that the device is connected and try again</TT>,
              blockPinModel: false,
            });
            return resolve();
          }

          let pendingMessage = null;
          let updatedData = null;
          let pinIsChanged = false;
          if (resp) {
            pendingMessage = this.successPinMessage;

            if (values.newPin && values.newPin !== "") {
              // if pin changed, or unblocked with puk - we set the new pin automatically
              if (values.newPin !== values.currentPin || (values.puk && values.puk !== "")) {
                this.updateFormPinAndSubmit(values.newPin)
                pinIsChanged = true;
              }
            }

            const currentFormPin = this.props.lteForm.pin.value;
            if (values.enable === false && currentFormPin && !values.puk) {
              this.updateFormPinAndSubmit('')
              pinIsChanged = true;
            }

            if (values.enable === true && !currentFormPin) {
              this.updateFormPinAndSubmit(values.newPin || values.currentPin)
              pinIsChanged = true;
            }

            updatedData = resp.message.data;
          }

          if (err) {
            try {
              err = JSON.parse(err.message.replace(/'/g, '"'));
              updatedData = err.data
              pendingMessage = err.err_msg
            } catch (e) {
              this.setState({
                monitorLoading: false,
                showPendingMessage:  <TT>{err.message}</TT>,
                blockPinModel: false,
              });
              return resolve();
            }
          }

          this.removeLteInfoFromCache()

          // updated pin state
          this.setState({
            pinIsChanged: pinIsChanged,
            monitorLoading: false,
            showPendingMessage: pendingMessage,
            blockPinModel: false,
            info: {
              ...this.state.info,
              pinState: updatedData
            }
          });

          // Update the info in props so we will use it
          // if a user exit from this tab and imminently get back
          this.props.interface.deviceParams.initial_pin1_state = updatedData

          resolve();
        })
    })
  }

  getPinState(key="pin1Status") {
    if (Object.keys(this.state.info).length) {
      return this.state.info.pinState[key] || (key === 'pin1Status' ? 'disabled' : '')
    } else if (this.props.interface.deviceParams && this.props.interface.deviceParams.initial_pin1_state) {
      return this.props.interface.deviceParams.initial_pin1_state[key];
    } else {
      if (key === 'pin1Status') {
        return 'disabled' // if no info from edge, set default as disabled pin
      }
      return '';
    }
  }

  getRssiQuality = (rssi, technologies) => {
    if (technologies.includes('lte')) {
      if (rssi > -65) { // Strong signal with maximum data speeds
        return 100
      } else if (rssi > -75) { // Strong signal with good data speeds
        return 75;
      } else if (rssi > -85) { // Fair but useful, fast and reliable data speeds may be attained, but marginal data with drop-outs is possible
        return 50;
      } else if (rssi > -95) { // Performance will drop drastically
        return 25;
      }
    } else {
      if (rssi >= -70) { // Strong signal with maximum data speeds
        return 100
      } else if (rssi > -85) { // Strong signal with good data speeds
        return 75;
      } else if (rssi > -100) { // Fair but useful, fast and reliable data speeds may be attained, but marginal data with drop-outs is possible      
        return 25;
      } else if (rssi > -110) { // Performance will drop drastically
        return 0;
      }
    }
  }

  getSignalQuality = signals => {
    if (!signals) return 0;

    if ('text' in signals) {
      const txt = signals.text;
      if (txt === 'Marginal') {
        return 15
      }
      if (txt === 'Very low') {
        return 30
      }
      if (txt === 'Low') {
        return 45;
      }
      if (txt === 'Good') {
        return 60
      }
      if (txt === 'Very Good') {
        return 75
      }
      return 100;
    }

    if (signals.rssi) {
      return this.getRssiQuality(signals.rssi, signals.technologies ?? [])
    }

    if (signals.quality) {
      return signals.quality
    }

    return 0;
  }
  getSignalTip = signals => {
    // signals object example:
    //  {
    //    'rssi'        : '',
    //    'rsrp'        : '',
    //    'rsrq'        : '',
    //    'rscp'        : '',
    //    'sinr'        : '',
    //    'ecio'        : '',
    //    'snr'         : '',
    //    'quality'     : '',
    //    'technologies': []
    //  }
    let arr = [];
    for (const key in signals) {
      if (key === 'quality' || key === 'technologies' || !signals[key]) {
        continue
      }

      arr.push(<p className='m-1'>{key.toUpperCase()}: {signals[key]}</p>)
    }
    return arr
  }

  render() {
    const toTTString = this.context.toTTString
    const inModal = typeof this.props.onSubmit !== 'function';
    return (
      <>
        {!inModal && <h4>{this.props.interface ? this.props.interface.name + ' - ' + toTTString('LTE') : null}</h4>}

        <InfoNotAvailable show={this.state.infoNotAvailable} />

        { this.state.info?.simStatus && this.state.info?.simStatus !== 'present' ? (
          <div
            className="alert alert-danger col-md-12"
            role="alert"
          >
            <TT tid="lteSimWarning">
              The modem doesn't recognize a SIM card.
              Please make sure you have inserted the SIM card in the correct slot and click on "Reset Modem" button.
            </TT>
            <br />
            <TT tid="lteSimWarningExpected">The expected "Sim Card Status" below should be "Present"</TT>
          </div>
        ) : null }

        <Row>
          <Col md={5}>
            <Card id='configureLteInterfaceCard'>
              <CardHeader>
                <TT>Configuration</TT>
              </CardHeader>
              <CardBody>
                <Form
                  id="configureLteInterfaceForm"
                  model="configureLteInterface"
                  onChange={vals => {
                    if (vals.user && vals.user !== '') {
                      this.setState({ passwordRequired: true })
                    } else {
                      this.setState({ passwordRequired: false })
                    }
                  }}
                  validators={{
                    '': {
                      isAuthRequired: vals => {
                        if (
                          ((vals.lteUser && vals.lteUser !== '') ||
                          (vals.ltePassword && vals.ltePassword !== '')) &&
                          vals.auth === ''
                        ) {
                          return false;
                        } else {
                         return true;
                        }
                      }
                    }
                  }}
                  onSubmit={(values) => this.handleSubmit(values)}
                >
                  <Row className="form-group">
                    <Label htmlFor="enable" md={4}>
                      <TT>Enable</TT>
                    </Label>
                    <Col md={8}>
                      <Label className="FWswitch">
                        <Control.checkbox
                          model=".enable"
                          id="enable"
                          name="enable"
                        />{" "}
                        <span className="FWslider round" />
                      </Label>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="pinState" md={4}>
                      <TT>PIN State</TT>
                    </Label>
                    <Col md={8} className="d-flex align-items-center">
                      <Control.text
                        model=".pinState"
                        id="pinState"
                        name=".pinState"
                        placeholder=""
                        disabled
                        component={FWTextInput}
                        withFieldValue
                        className="form-control"
                        value= {this.getPinState()}
                        />
                          <i
                            className="ml-2"
                            data-tip
                            style={{'cursor': 'pointer'}}
                            data-for={"setup-pin"}
                            onClick={() => {
                              this.props.changeForm('updatePinConfiguration.enable')(this.getPinState() !== "disabled");
                              this.togglePinModal()
                            }}
                          >
                            <FontAwesomeIcon icon="cog" fixedWidth />
                            <ReactTooltip id={"setup-pin"}>
                              <span><TT>Setup PIN</TT></span>
                            </ReactTooltip>
                          </i>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="pin" md={4}>
                      {!isPinDisabled(this.getPinState()) ? (
                        <span className="form-required-star">*</span>
                      ) : null}
                      <TT>PIN</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        model=".pin"
                        id="pin"
                        name="pin"
                        placeholder={toTTString("PIN")}
                        data-lpignore="true"
                        component={FWPasswordInput}
                        onPasswordChange={val => {
                          this.props.changeForm('configureLteInterface.pin')(val)
                        }}
                        withFieldValue
                        disabled={!this.props.lteForm.enable.value || this.getPinState() === 'blocked'}
                        autoComplete="off"
                        validators={getPinFieldValidators(this.getPinState())}
                      />
                      <Errors
                        className="text-danger"
                        model=".pin"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          length: toTTString('Pin must be with 4 or 8 digits'),
                          isNumber: toTTString('Pin should be with numbers only')
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="metric" md={4}>
                      <span className="form-required-star">*</span>
                      <TT>Gateway Metric</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        type="text"
                        model=".metric"
                        id="metric"
                        name="metric"
                        placeholder={toTTString("Gateway Metric")}
                        data-lpignore="true"
                        component={FWTextInput}
                        disabled={!this.props.lteForm.enable.value}
                        validators={{ isNumber: isNumber, required: required }}
                        withFieldValue
                      />
                      <Errors
                        className="text-danger"
                        model=".metric"
                        show="touched"
                        messages={{ isNumber: toTTString('Metric must be a number'), required: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="apn" md={4}>
                      <span className="form-required-star">*</span>
                      <TT>APN</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        type="text"
                        model=".apn"
                        id="apn"
                        name="apn"
                        placeholder={toTTString("APN")}
                        data-lpignore="true"
                        component={FWTextInput}
                        withFieldValue
                        disabled={!this.props.lteForm.enable.value}
                        validators={{ required: required }}
                      />
                      <Errors
                        className="text-danger"
                        model=".apn"
                        show="touched"
                        messages={{ required: toTTString('Required field') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="auth" md={4}>
                      <TT>Auth</TT>
                    </Label>
                    <Col md={8}>
                      <Control.select
                        type="text"
                        model=".auth"
                        id="auth"
                        name="auth"
                        data-lpignore="true"
                        disabled={!this.props.lteForm.enable.value}
                        className="form-control"
                      >
                        <option key="0" value={""}>{toTTString("Select")}</option>
                        <option key="1" value={"PAP"}>{toTTString('PAP')}</option>
                        <option key="2" value={"CHAP"}>{toTTString('CHAP')}</option>
                        <option key="3" value={"MSCHAPV2"}>{toTTString('MSCHAPV2')}</option>
                      </Control.select>
                      <Errors
                        className="text-danger"
                        model="."
                        show={true}
                        messages={{ isAuthRequired: toTTString('Username or password requires an auth protocol') }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="lteUser" md={4}>
                      <TT>User</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        type="text"
                        model=".lteUser"
                        id="lteUser"
                        name="lteUser"
                        placeholder={toTTString("User")}
                        data-lpignore="true"
                        disabled={!this.props.lteForm.enable.value}
                        component={FWTextInput}
                        withFieldValue
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Label htmlFor="ltePassword" md={4}>
                      {this.state.passwordRequired ? <span className="form-required-star">*</span> : null}
                      <TT>Password</TT>
                    </Label>
                    <Col md={8}>
                      <Control.text
                        type="text"
                        model=".ltePassword"
                        id="ltePassword"
                        name="ltePassword"
                        disabled={!this.props.lteForm.enable.value}
                        placeholder={toTTString("Password")}
                        data-lpignore="true"
                        component={FWTextInput}
                        withFieldValue
                      />
                    </Col>
                  </Row>

                  <Row>
                    {!inModal &&
                    <Col md={8} className="d-flex">
                      <Control.button
                        form={"configureLteInterfaceForm"}
                        type="submit"
                        model="configureLteInterface"
                        className={"btn btn-primary"}
                      >
                        <TT>Save</TT>
                      </Control.button>

                      <button
                        onClick={this.handleClose}
                        type="button"
                        className="btn btn-outline-secondary ml-2"
                      >
                        <TT>Cancel</TT>
                      </button>
                    </Col>}
                    <Col className="d-flex justify-content-end">
                      <Button
                        className="btn btn-primary"
                        disabled={this.props.interface?.deviceParams?.defaultSettings ? false : true}
                        onClick={() => {
                          if (Object.keys(this.state.info).length > 0) {
                            this.handleGetApn(this.state.info.defaultSettings)
                          } else {
                            this.handleGetApn(this.props.interface?.deviceParams?.defaultSettings)
                          }
                        }}
                      >
                        <TT>Get SIM Info</TT>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>


          <Col md={7}>
            <Card className="small-hr-margin">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <TT>Information</TT>

                  { this.state.monitorLoading ? (
                    <Loading size="sm" />
                  ) : (
                    this.props.deviceStatus.connection === 'disconnected' ? null : (
                      <div className="d-flex justify-content-between align-items-center">
                        <small className="text-muted"> {this.state.info?.time ? 'Updated at ' + moment(this.state.info.time).format('h:mm:ss a MMM DD, YYYY') : null}</small>
                        <ReactTooltip id='refresh-lte-stats'><span><TT>Refresh</TT></span></ReactTooltip>
                        <Button
                          data-tip data-for='refresh-lte-stats'
                          color="default"
                          size="sm"
                          className="ml-2"
                          onClick={() => this.updateLteMonitorInfo(true)}
                        >
                          <FontAwesomeIcon icon="sync-alt" />
                        </Button>
                      </div>
                    )
                  ) }
                </div>
              </CardHeader>
              <CardBody>
                {!this.props.deviceStatus.isApproved ? (
                  <span><TT>The device must be approved to view this information</TT></span>
                ) : this.props.deviceStatus.connection === 'disconnected' ? (
                  <span><TT>Device must be connected to view this information</TT></span>
                ) : this.state?.info?.state  === 'RESETTING' ? (
                  <span><TT>The modem is in reset process. Please wait and click on refresh again in a few moments..</TT></span>
                ) : this.state?.info?.mode === 'QMI'? (
                  <span><TT>The modem is in QMI mode, which is unsupported. Use fwsystem_checker to switch to the supported mode</TT></span>
                ) : (
                  <>
                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>LTE Connectivity</TT>
                    </Label>
                    <Col md={9}>
                      {this.props.deviceStatus.connection === 'disconnected' ? null : (
                        <div className="d-flex justify-content-between align-items-baseline">
                          { this.getPinState() === 'enabled-not-verified' ? (
                            <div className='d-flex align-items-baseline'>
                              <Badge
                                className="device-card-badge"
                                color="danger"
                              >
                                <TT>SIM Locked</TT>
                              </Badge>
                              <span className="helpTooltip" data-tip data-for='simLockedTip'></span>
                              <ReactTooltip id='simLockedTip'>
                                <span>
                                  <TT>The SIM is locked with a PIN. Add your PIN or disable it to unlock</TT>
                                </span>
                              </ReactTooltip>
                            </div>
                          ) : this.getPinState() === 'blocked' ? (
                            <div className='d-flex align-items-baseline'>
                              <Badge
                                className="device-card-badge"
                                color="danger"
                              >
                                <TT>SIM Blocked</TT>
                              </Badge>
                              <span className="helpTooltip" data-tip data-for='simBlockedTip'></span>
                              <ReactTooltip id='simBlockedTip'>
                                <span>
                                  <TT>The SIM is blocked. Add your PUK code</TT>
                                </span>
                              </ReactTooltip>
                            </div>
                          ) : (
                            <Badge
                              className="device-card-badge"
                              color={ Object.keys(this.state.info).length === 0 ? "warning" : this.state.info.connectivity && this.state.info.connectivity === true ? "success" : "danger"}
                            >
                              { Object.keys(this.state.info).length === 0 ? <TT>Pending</TT> :  this.state.info.connectivity && this.state.info.connectivity === true ? <TT>Connected</TT> : <TT>Not Connected</TT>}
                            </Badge>
                          )}

                          <Button
                            size="sm"
                            className="btn btn-danger"
                            onClick={this.handleReset}
                            >
                            <TT>Reset Modem</TT>
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>Connection State</TT>
                    </Label>
                    { this.state.info.connectionState && typeof this.state.info.connectionState === "string" ? (
                      <Col md={9}>
                          <div className="d-flex justify-content-between align-items-baseline">
                            <Badge
                              className="device-card-badge"
                              color={this.state.info.connectionState === "activated" ? "success" : "danger"}
                            >
                            {
                              this.state.info.connectionState === "activated" ? toTTString("Activated") : toTTString('Deactivated')
                            }
                              {/* {this.state.info.connectionState.charAt(0).toUpperCase() + this.state.info.connectionState.slice(1)} */}
                            </Badge>
                          </div>
                      </Col>
                    ) : (
                      <Label md={9} className="col-form-label-sm">
                        {Object.keys(this.state.info).length === 0 ? null : (
                          <><TT>Unknown</TT></>
                        )}
                      </Label>
                    )
                    }
                  </Row>

                  <hr></hr>

                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>Registration State</TT>
                    </Label>
                    <Label md={9} className="col-form-label-sm">
                      { this.state.info.registrationNetworkState ? (
                        <>
                          {this.state.info.registrationNetworkState.registrationState}
                          {this.state.info.registrationNetworkState.networkError && this.state.info.registrationNetworkState.networkError !== 'unknown' ? (
                            <> (<TT>{this.state.info.registrationNetworkState.networkError}</TT>)</>
                          ) : null}
                        </>
                      ) : (
                        Object.keys(this.state.info).length === 0 ? null : (
                          <><TT>Unknown</TT></>
                        )
                      )}
                    </Label>
                  </Row>

                  <hr></hr>

                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>Vendor / Model</TT>
                    </Label>
                    <Label md={9} className="col-form-label-sm">
                      { this.state.info.hardwareInfo ? (
                        <>
                          {this.state.info.hardwareInfo.vendor}. {this.state.info.hardwareInfo.model}
                        </>
                      ) : null }
                    </Label>
                  </Row>
                  <hr></hr>

                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>IMEI</TT>
                    </Label>
                    <Label md={9} className="col-form-label-sm">
                      {this.state?.info?.hardwareInfo?.imei ?? 'N/A'}
                    </Label>
                  </Row>
                  <hr></hr>

                  <Row className="form-group mb-0">
                    <Label md={3} className="col-form-label-sm">
                      <TT>Sim Card Status</TT>
                    </Label>
                    <Col md={9}>
                    <div className="d-flex justify-content-between align-items-baseline">
                        <Badge
                          className="device-card-badge"
                          color={this.state.info.simStatus === 'present' ? "success" : "danger"}
                        >
                          { this.state.info.simStatus === 'present' ? <TT>Present</TT> : <TT>Not Present</TT>}
                        </Badge>
                      </div>
                    </Col>
                  </Row>

                  {this.getPinState() === 'enabled-not-verified' ? null : (
                    <>
                      <hr></hr>
                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>Operator Name</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          { this.state.info.systemInfo ? (
                            <>
                              {this.state.info.systemInfo.operatorName}
                            </>
                          ) : null }
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>MCC</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          { this.state.info.systemInfo ? (
                            <>
                              {this.state.info.systemInfo.mcc}
                            </>
                          ) : null }
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>MNC</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          { this.state.info.systemInfo ? (
                            <>
                              {this.state.info.systemInfo.mnc}
                            </>
                          ) : null }
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>ICCID</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          {this.state?.info?.sim?.iccid ?? 'N/A'}
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>IMSI</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          {this.state?.info?.sim?.imsi ?? 'N/A'}
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>Phone Number</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          {this.state.info.phoneNumber  === '' ? <TT>N/A</TT> : this.state.info.phoneNumber}
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>Cell ID</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          { this.state.info.systemInfo ? (
                            <>
                              {this.state.info.systemInfo.cellId}
                            </>
                          ) : null }
                        </Label>
                      </Row>

                      <hr></hr>

                      { this.state?.info?.signals?.technologies ? (
                        <>
                          <Row className="form-group mb-0 align-items-center">
                            <Label md={3} className="col-form-label-sm">
                              <TT>Access Technology</TT>
                            </Label>
                            <Label md={9}>
                                <small>{this.state?.info?.signals?.technologies?.map(t => t.toUpperCase()).join(', ')}</small>
                            </Label>
                          </Row>
                          <hr></hr>
                        </>
                      ) : null}

                      <Row className="form-group mb-0 align-items-center">
                        <Label md={3} className="col-form-label-sm">
                          <TT>Signal</TT>
                        </Label>
                        <Label md={9} className='d-flex align-items-center'>
                          <div data-tip data-for='signalTip'>
                            <Signal size='25' quality={this.getSignalQuality(this.state?.info?.signals ?? {})} />
                          </div>

                          <ReactTooltip id='signalTip'>
                            { this.getSignalTip(this.state?.info?.signals ?? {})}
                          </ReactTooltip>
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>Uplink Speed</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          { this.state.info.packetServiceState && this.state.info.packetServiceState.uplinkSpeed ? (
                            <>
                              {(this.state.info.packetServiceState.uplinkSpeed.split('bps')[0] / 1000000) + ' ' + toTTString('mbps')}
                            </>
                          ) : null }
                        </Label>
                      </Row>

                      <hr></hr>

                      <Row className="form-group mb-0">
                        <Label md={3} className="col-form-label-sm">
                          <TT>Downlink Speed</TT>
                        </Label>
                        <Label md={9} className="col-form-label-sm">
                          { this.state.info.packetServiceState && this.state.info.packetServiceState.downlinkSpeed ? (
                            <>
                              {(this.state.info.packetServiceState.downlinkSpeed.split('bps')[0] / 1000000) + ' ' + toTTString('mbps')}
                            </>
                          ) : null }
                        </Label>
                      </Row>
                    </>
                  )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.pinModalIsOpen}
          toggle={this.togglePinModal}
          backdrop={this.state.blockPinModel ? "static" : true}
          centered={true}
          onClosed={() => this.setState({showPendingMessage: null}, () => this.props.resetPinForm())}
        >
          <ModalHeader toggle={this.state.blockPinModel ? () => {} : () => this.togglePinModal()}>
            <TT>SIM PIN Configuration</TT>
          </ModalHeader>
          <ModalBody>
            {this.state.showPendingMessage ? (
              <p className={this.state.showPendingMessage === this.successPinMessage ? 'alert alert-success' : 'alert alert-danger'}>
                {this.state.showPendingMessage}
              </p>
            ) : null}

            {this.state.showPendingMessage === this.successPinMessage && this.state.pinIsChanged ? (
              <p className='alert alert-warning'>
                <TT>Click on "Update Device" to save the new PIN in your device configuration</TT>
              </p>
            ) : null}
            <Card>
              <CardBody id="pinModalCardBody">
                { this.state.blockPinModel ? (
                  <p style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: this.state.modalSize.height,
                    width: this.state.modalSize.width
                  }}>
                  <TT>Please wait for SIM configuration</TT>
                  </p>
                ) : (
                  <Form
                    id="updatePinConfigurationForm"
                    model="updatePinConfiguration"
                    onSubmit={async values => {
                      await this.handlePinOperations(values)
                      this.updateLteMonitorInfo(true)
                    }}
                  >
                    <Row className="form-group">
                      <Label htmlFor="enable" md={4}>
                        <TT>Enable</TT>
                      </Label>
                      <Col md={8} className="form-field">
                        <Label className="FWswitch">
                          <Control.checkbox
                            model=".enable"
                            id="enable"
                            name="enable"
                            disabled={() => this.getPinState() === 'blocked'}
                          />{" "}
                          <span className="FWslider round" />
                        </Label>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <Label htmlFor="pinState" md={4}>
                        <TT>PIN State</TT>
                      </Label>
                      <Col md={8} className="form-field">
                        <Control.text
                          model=".pinState"
                          id="pinState"
                          name=".pinState"
                          placeholder=""
                          disabled
                          className="form-control"
                          value= {this.getPinState()}
                          />
                      </Col>
                    </Row>

                  <Row className="form-group">
                    <Label htmlFor="retries" md={4}>
                      <TT>Retries Left</TT>:
                    </Label>
                    <Col md={8} className="form-field">
                      <Control.text
                        model=".retries"
                        id="retries"
                        name=".retries"
                        placeholder=""
                        disabled
                        className="form-control"
                        value= {this.getPinState('pin1Status') === 'blocked' ? 'N/A' : this.getPinState("pin1Retries")}
                        />
                    </Col>
                  </Row>

                  { this.getPinState() === 'blocked' ? (
                    <Row className="form-group">
                      <Label htmlFor="pukRetries" md={4}>
                        <TT>PUK Retries Left</TT>:
                      </Label>
                      <Col md={8} className="form-field">
                        <Control.text
                          model=".pukRetries"
                          id="pukRetries"
                          name=".pukRetries"
                          placeholder=""
                          disabled
                          className="form-control"
                          value= {this.getPinState("puk1Retries")}
                          />
                      </Col>
                    </Row>
                  ) : null}

                  <Row className="form-group">
                    <Label htmlFor="currentPin" md={4}>
                      {
                        this.getPinState() !== 'blocked' ? (
                          <span className="form-required-star">*</span>
                        ) : null
                      }
                      <TT>Current PIN</TT>
                    </Label>
                    <Col md={8} className="form-field">
                      <Control.text
                        model=".currentPin"
                        id="currentPin"
                        name="currentPin"
                        placeholder={toTTString("Current PIN")}
                        data-lpignore="true"
                        component={FWPasswordInput}
                        onPasswordChange={val => {
                          this.props.changeForm('updatePinConfiguration.currentPin')(val)
                        }}
                        withFieldValue
                        disabled={() => this.getPinState() === 'blocked'}
                        validators={{
                          length: val => this.getPinState() === 'blocked' || length(4)(val) || length(8)(val),
                          isNumber: val => this.getPinState() === 'blocked' || isNumber(val),
                          required: val => this.getPinState() === 'blocked' || required(val)
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".currentPin"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          length: toTTString('Pin must be with 4 or 8 digits'),
                          isNumber: toTTString('Pin should be with numbers only')
                        }}
                      />
                    </Col>
                  </Row>

                  { this.getPinState() === 'blocked' ? (
                    <Row className="form-group">
                    <Label htmlFor="newPin" md={4}>
                      {
                        this.getPinState() === 'blocked' ? (
                          <span className="form-required-star">*</span>
                        ) : null
                      }
                      <TT>PUK</TT><span className="helpTooltip" data-tip data-for='pukTip'></span>
                      <ReactTooltip id='pukTip'>
                        <span style={{"fontSize": "0.8rem"}}>
                          <TT>In order to unblock your SIM card
                          You must specify a the PUK code number</TT>.
                        </span>
                      </ReactTooltip>
                    </Label>
                    <Col md={8} className="form-field">
                      <Control.text
                        model=".puk"
                        id="puk"
                        name="puk"
                        placeholder={toTTString("PUK")}
                        data-lpignore="true"
                        component={FWPasswordInput}
                        onPasswordChange={val => {
                          this.props.changeForm('updatePinConfiguration.puk')(val)
                        }}
                        withFieldValue
                        validators={{
                          isNumber: val => !val || isNumber(val),
                          length: val => !val || length(8)(val),
                          required: val => this.getPinState() !== 'blocked' || required(val)
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".puk"
                        show="touched"
                        messages={{
                          isNumber: toTTString('PUK should be with numbers only'),
                          length: toTTString('PUK must be 8 digits'),
                          required: toTTString('Required field')
                        }}
                      />
                    </Col>
                  </Row>
                  ) : null}

                  <Row className="form-group">
                    <Label htmlFor="newPin" md={4}>
                      {
                        this.getPinState() === 'blocked' ? (
                          <span className="form-required-star">*</span>
                        ) : null
                      }
                      <TT>New PIN</TT><span className="helpTooltip" data-tip data-for='changePinTip'></span>
                      <ReactTooltip id='changePinTip'>
                        {
                          this.getPinState() === 'blocked' ? (
                            <span style={{"fontSize": "0.8rem"}}>
                              <TT>In order to unblock your SIM card
                              You must specify a new PIN number</TT>.
                            </span>
                          ) : (
                            <span style={{"fontSize": "0.8rem"}}>
                              <TT>For changing your PIN, specify it here</TT>
                              <TT>If don't wish to change your PIN, please leave this field empty</TT>
                            </span>
                          )
                        }
                      </ReactTooltip>
                    </Label>
                    <Col md={8} className="form-field">
                      <Control.text
                        model=".newPin"
                        id="newPin"
                        name="newPin"
                        placeholder={toTTString("New PIN")}
                        data-lpignore="true"
                        component={FWPasswordInput}
                        onPasswordChange={val => {
                          this.props.changeForm('updatePinConfiguration.newPin')(val)
                        }}
                        withFieldValue
                        validators={{
                          length: val => !val || length(4)(val) || length(8)(val),
                          isNumber: val => !val || isNumber(val),
                          required: val => this.getPinState() !== 'blocked' || required(val)
                        }}
                      />
                      <Errors
                        className="text-danger"
                        model=".newPin"
                        show="touched"
                        messages={{
                          required: toTTString('Required field'),
                          length: toTTString('Pin must be 4 or 8 digits'),
                          isNumber: toTTString('Pin should be with numbers only')
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Col md={4}></Col>
                    <Col md={8} className="form-field">
                      { this.state.blockPinModel ? (
                        <>
                        <Control.button
                          model="updatePinConfiguration"
                          className="btn btn-primary"
                          disabled={true}
                        >
                          <TT>Apply</TT>
                        </Control.button>
                        </>
                      ) : (
                        <Control.button
                          model="updatePinConfiguration"
                          className="btn btn-primary"
                          disabled={{ valid: false }}
                        >
                          <TT>Apply</TT>
                        </Control.button>
                      )}
                    </Col>
                  </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>


        <ConfirmationModal
          isOpen={this.state.confirmationModal.isOpen}
          toggle={() => this.closeConfirmationModal()}
          title={this.state.confirmationModal.title}
          question={this.state.confirmationModal.question}
          color={'danger'}
          onConfirm={this.state.confirmationModal.onConfirm}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    lteForm: state.forms.configureLteInterface
  }
};

const mapDispatchToProps = dispatch => {
  return {
    validatePinField: (pinState) => dispatch(actions.validate('configureLteInterface.pin', getPinFieldValidators(pinState)))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureLTE);
