import * as Actions from "../Actions";
import { alertMsgMessage } from "./AlertMsg";
import { mainRedirectTo } from "./Main";
import { FWFetch } from "../../utils/Network";
import { store } from "../../App";
const { baseUrl } = window.SERVER_CONFIG;

export const FirewallPolicies = (
  state = {
    firewallPolicy: {},
    firewallPolicies: [],
    firewallPoliciesMeta: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case Actions.FIREWALL_POLICIES_ADD_REQUEST:
      return { ...state };
    case Actions.FIREWALL_POLICIES_ADD_SUCCESS:
      return { ...state };
    case Actions.FIREWALL_POLICIES_ADD_FAILURE:
      return { ...state };
    case Actions.FIREWALL_POLICIES_UPD_REQUEST:
      return { ...state, firewallPolicy: action.firewallPolicy };
    case Actions.FIREWALL_POLICIES_UPD_SUCCESS:
      return { ...state };
    case Actions.FIREWALL_POLICIES_UPD_FAILURE:
      return { ...state };
    case Actions.FIREWALL_POLICIES_DEL_REQUEST:
      return { ...state, firewallPolicy: action.firewallPolicy };
    case Actions.FIREWALL_POLICIES_DEL_SUCCESS:
      return { ...state };
    case Actions.FIREWALL_POLICIES_DEL_FAILURE:
      return { ...state };
    case Actions.FIREWALL_POLICIES_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.FIREWALL_POLICIES_GET_SUCCESS:
      return { ...state, firewallPolicy: action.firewallPolicy, isLoading: false };
    case Actions.FIREWALL_POLICIES_GET_FAILURE:
      return { ...state, firewallPolicy: {}, isLoading: false };
    case Actions.FIREWALL_POLICIES_GETALL_REQUEST:
      return { ...state, isLoading: true };
    case Actions.FIREWALL_POLICIES_GETALL_SUCCESS:
      return { ...state, firewallPolicies: action.firewallPolicies, isLoading: false };
    case Actions.FIREWALL_POLICIES_GETALL_FAILURE:
      return { ...state, firewallPolicies: [], isLoading: false };
    case Actions.FIREWALL_POLICIES_LIST_GET_REQUEST:
      return { ...state, isLoading: true };
    case Actions.FIREWALL_POLICIES_LIST_GET_SUCCESS:
      return { ...state, firewallPolicies: action.firewallPolicies, isLoading: false };
    case Actions.FIREWALL_POLICIES_LIST_GET_FAILURE:
      return { ...state, firewallPolicies: [], isLoading: false };
    case Actions.FIREWALL_POLICIES_GET_META_REQUEST:
      return { ...state, isLoading: true };
    case Actions.FIREWALL_POLICIES_GET_META_SUCCESS:
      return { ...state, firewallPoliciesMeta: action.firewallPoliciesMeta, isLoading: false };
    case Actions.FIREWALL_POLICIES_GET_META_FAILURE:
      return { ...state, firewallPoliciesMeta: [], isLoading: false };
    default:
      return state;
  }
};

/* General functions */
const preparePolicy = (firewallPolicy, appsMap) => {
  // Convert app name to app ID in all relevant rules
  const { _id, name, description } = firewallPolicy;
  const newPolicy = { _id, name, description };

  newPolicy.rules = firewallPolicy.rules.map(rule => {
    const { _id, description, priority, enabled, direction, action } = rule;
    const newRule = { _id, description, priority, enabled, direction, action };
    if (direction === 'inbound') {
      newRule.inbound = rule.inbound;
    }
    newRule.classification = {};
    const { source, destination, sourceType, destinationType } = rule.classification;
    if (source) {
      if (sourceType === 'trafficId') {
        const appItem = appsMap.get(source.appName);
        newRule.classification.source = {
          trafficId: appItem ? appItem.id : source.appName
        }
      } else if (['trafficTags', 'ipPort'].includes(sourceType)) {
        newRule.classification.source = {};
        newRule.classification.source[sourceType] = {};
        for (const attr of Object.keys(source[sourceType])) {
          if (source[sourceType][attr]) {
            newRule.classification.source[sourceType][attr] = source[sourceType][attr];
          }
        }
      }
    }
    if (destination) {
      if (destinationType === 'trafficId') {
        const appItem = appsMap.get(destination.appName);
        newRule.classification.destination = {
          trafficId: appItem ? appItem.id : destination.appName
        }
      } else if (['trafficTags', 'ipProtoPort'].includes(destinationType)) {
        newRule.classification.destination = {};
        newRule.classification.destination[destinationType] = {};
        for (const attr of Object.keys(destination[destinationType])) {
          if (destination[destinationType][attr]) {
            newRule.classification.destination[destinationType][attr] = destination[destinationType][attr];
          }
        }
      }
    }
    return newRule;
  });
  return newPolicy;
}

/* Action Creators */
/**********************************************************************
 *   Add a firewall policy
 **********************************************************************/
export const requestAddFirewallPolicy = firewallPolicy => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_ADD_REQUEST,
  });
};

export const receiveAddFirewallPolicy = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_ADD_SUCCESS
  });
  dispatch(mainRedirectTo("/firewallpolicies"));
  dispatch(
    alertMsgMessage({
      color: "success",
      text: "Firewall policy " + response.name + " added successfully"
    })
  );
};

export const failureAddFirewallPolicy = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_ADD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const addFirewallPolicy = (firewallPolicy, appsMap, success_cb) => dispatch => {
  const newPolicy = preparePolicy(firewallPolicy, appsMap);
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies",
      method: "POST",
      body: JSON.stringify(newPolicy),
      requestCB: () => {
        dispatch(requestAddFirewallPolicy(firewallPolicy));
      },
      successCB: response => {
        dispatch(receiveAddFirewallPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureAddFirewallPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Update a firewall policy
 **********************************************************************/
export const requestUpdFirewallPolicy = firewallPolicy => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_UPD_REQUEST,
    firewallPolicy
  });
};

export const receiveUpdFirewallPolicy = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_UPD_SUCCESS,
  });
  const { message, name } = response;
  dispatch(
    alertMsgMessage({
      color: "success",
      text: `Firewall policy "${name}" saved successfully ${message ? '. ' + message : ''}`
    })
  );
};

export const failureUpdFirewallPolicy = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_UPD_FAILURE
  });
  dispatch(
    alertMsgMessage({ color: "danger", text: "Error: " + error.message })
  );
};

export const updFirewallPolicy = (firewallPolicy, appsMap, success_cb) => dispatch => {
  const newPolicy = preparePolicy(firewallPolicy, appsMap);
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies/" + newPolicy._id,
      method: "PUT",
      body: JSON.stringify(newPolicy),
      requestCB: () => {
        dispatch(requestUpdFirewallPolicy(firewallPolicy));
      },
      successCB: response => {
        dispatch(receiveUpdFirewallPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureUpdFirewallPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Delete a firewall policy
 **********************************************************************/
export const requestDelFirewallPolicy = firewallPolicy => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_DEL_REQUEST,
    firewallPolicy
  });
};

export const receiveDelFirewallPolicy = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_DEL_SUCCESS
  });

  const { name } = store.getState().firewallPolicies.firewallPolicy;
  dispatch(
    alertMsgMessage({
      color: "success",
      text:
        "Firewall policy " + name + " deleted successfully"
    })
  );
};

export const failureDelFirewallPolicy = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_DEL_FAILURE
  });
};

export const delFirewallPolicy = (firewallPolicy, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies/" + firewallPolicy._id,
      method: "DELETE",
      requestCB: () => {
        dispatch(requestDelFirewallPolicy(firewallPolicy));
      },
      successCB: response => {
        dispatch(receiveDelFirewallPolicy(response));
        success_cb();
      },
      failureCB: error => {
        dispatch(failureDelFirewallPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Get all firewall policies
 **********************************************************************/
export const requestGetAllFirewallPolicies = () => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GETALL_REQUEST
  });
};

export const receiveGetAllFirewallPolicies = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GETALL_SUCCESS,
    firewallPolicies: response
  });
};

export const failureGetAllFirewallPolicies = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GETALL_FAILURE
  });
};

export const getAllFirewallPolicies = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetAllFirewallPolicies());
      },
      successCB: response => {
        dispatch(receiveGetAllFirewallPolicies(response));
        success_cb(response)
      },
      failureCB: error => {
        dispatch(failureGetAllFirewallPolicies(error));
      }
    })
  );
};

/**********************************************************************
 *   Get a list of all firewall policies
 **********************************************************************/
export const requestGetFirewallPoliciesList = () => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_LIST_GET_REQUEST
  });
};

export const receiveGetFirewallPoliciesList = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_LIST_GET_SUCCESS,
    firewallPolicy: response
  });
};

export const failureGetFirewallPoliciesList = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_LIST_GET_FAILURE
  });
};

export const getFirewallPoliciesList = (success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies/list",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetFirewallPoliciesList());
      },
      successCB: response => {
        dispatch(receiveGetFirewallPoliciesList(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetFirewallPoliciesList(error));
      }
    })
  );
};


/**********************************************************************
 *   Get a firewall policy
 **********************************************************************/
export const requestGetFirewallPolicy = () => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GET_REQUEST
  });
};

export const receiveGetFirewallPolicy = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GET_SUCCESS,
    firewallPolicy: response
  });
};

export const failureGetFirewallPolicy = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GET_FAILURE
  });
};

export const getFirewallPolicy = (firewallPolicyId, success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies/" + firewallPolicyId,
      method: "GET",
      requestCB: () => {
        dispatch(requestGetFirewallPolicy());
      },
      successCB: response => {
        dispatch(receiveGetFirewallPolicy(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetFirewallPolicy(error));
      }
    })
  );
};

/**********************************************************************
 *   Get firewall policies meta data
 **********************************************************************/
export const requestGetFirewallPoliciesMetaData = () => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GET_META_REQUEST
  });
};

export const receiveGetFirewallPoliciesMetaData = response => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GET_META_SUCCESS,
    firewallPoliciesMeta: response
  });
};

export const failureGetFirewallPoliciesMetaData = error => dispatch => {
  dispatch({
    type: Actions.FIREWALL_POLICIES_GET_META_FAILURE
  });
};

export const getFirewallPoliciesMetaData = (success_cb = () => {}) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "firewallpolicies/meta",
      method: "GET",
      requestCB: () => {
        dispatch(requestGetFirewallPoliciesMetaData());
      },
      successCB: response => {
        dispatch(receiveGetFirewallPoliciesMetaData(response));
        success_cb(response);
      },
      failureCB: error => {
        dispatch(failureGetFirewallPoliciesMetaData(error));
      }
    })
  );
};
