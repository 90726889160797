import React, {useContext} from 'react';
import { Row, Col, Button } from 'reactstrap';
import { TT, LanguageContext } from '../../../containers/Language';
import { Control, Errors, actions } from 'react-redux-form';
import { FWArrayEditableTable } from '../../Common';
import { Type } from "react-bootstrap-table2-editor";
import { store } from '../../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';


const StaticAssignments = props => {
  const { toTTString } = useContext(LanguageContext);

  return (
    <>
      <Row className="form-group">
        <Col md={12}>
          <Row>
            <Col className="d-flex align-items-center">
              <div><TT>Static Assignments</TT></div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Control
                model=".macAssign"
                id="macAssign"
                name="macAssign"
                component={FWArrayEditableTable}
                form={props.form}
                hideAddButton={props.hideAddButton}
                withFieldValue
                showRemove={false}
                columns={[
                  {
                    text: toTTString("Host"),
                    dataField: "host",
                    sort: false,
                    editable: true,
                    headerStyle: (colum, colIndex) => {
                      return { width: '15%' };
                    },
                    editor: {
                      type: Type.TEXT,
                    },
                    formatter: (cellContent, row, rowIndex) =>{
                      return (
                        <>
                          {cellContent}
                          <Errors
                            className="text-danger"
                            model={`.macAssign[${rowIndex}].host`}
                            show={(field) => (!field.pristine || field.touched) && !field.valid}
                            messages={{
                              required: toTTString('Required Field'),
                              validateHostName: toTTString('Invalid Host'),
                              maxLength: toTTString("Length must be at most 255"),
                              minLength: toTTString("Length must be at least 1"),
                              hostDuplication: toTTString("Host name already exists"),
                            }}
                          />
                        </>
                      )
                    }
                  },
                  {
                    text: toTTString("MAC"),
                    dataField: "mac",
                    sort: false,
                    headerStyle: (colum, colIndex) => {
                      return { width: '25%' };
                    },
                    editable: true,
                    formatter: (cellContent, row, rowIndex) => {
                      return (
                        <>
                          {cellContent}
                          <Errors
                            className="text-danger"
                            model={`.macAssign[${rowIndex}].mac`}
                            show={(field) => (!field.pristine || field.touched) && !field.valid}
                            messages={{
                              required: toTTString('Required field'),
                              isMac: toTTString('Invalid MAC Address'),
                              macDuplication: toTTString('MAC is already exists'),
                            }}
                          />
                        </>
                      )
                    }
                  },
                  {
                    text: toTTString("IPv4"),
                    dataField: "ipv4",
                    sort: false,
                    headerStyle: (colum, colIndex) => {
                      return { width: '20%' };
                    },
                    editable: true,
                    editor: {
                      type: Type.TEXT,
                    },
                    formatter: (cellContent, row, rowIndex) => {
                      return (
                        <>
                          {cellContent}
                          <Errors
                            className="text-danger"
                            model={`.macAssign[${rowIndex}].ipv4`}
                            show={(field) => (!field.pristine || field.touched) && !field.valid}
                            messages={{
                              required: toTTString('Required field'),
                              isIpv4: toTTString('Invalid IPv4 Address'),
                              ipDuplication: toTTString('IPv4 is already exists'),
                            }}
                          />
                        </>
                      )
                    }
                  },
                  {
                    text: toTTString("Use Host Name as DHCP Option"),
                    dataField: 'useHostNameAsDhcpOption',
                    sort: false,
                    headerStyle: (colum, colIndex) => {
                      return { width: '30%' };
                    },
                    editable: true,
                    initialValue: true,
                    editor: {
                      type: Type.SELECT,
                      options: [
                        { label: 'YES', value: true},
                        { label: 'NO', value: false}
                      ]
                    },
                    formatter: (cellContent) => {
                      return cellContent === true ? 'YES' : 'NO';
                    }
                  },
                  {
                    text: toTTString("Actions"),
                    isDummyField: true,
                    dataField: 'actions',
                    sort: false,
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                      return { width: '10%' };
                    },
                    formatter: (content, row, rowIndex, formatExtraData) => {
                      return (
                        <span>
                          <Button
                            color="danger"
                            type="button"
                            style={{ marginTop: '5px' }}
                            className="action-btn"
                            size="sm"
                            onClick={() => {
                              const form = store.getState()[props.form];
                              const macAssign = Array.from(form.macAssign);
                              macAssign.splice(rowIndex, 1);
                              props.changeForm(props.form, { ...form, macAssign });
                            }}
                          >
                            <FontAwesomeIcon icon="trash-alt" fixedWidth />
                          </Button>
                        </span>
                      )
                    },
                  },
                ]}
                afterSaveCell={(oldValue, newValue, row, column) => {
                  if (column.dataField === 'useHostNameAsDhcpOption') {
                    row.useHostNameAsDhcpOption = newValue === 'true'
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    changeForm: (model, values) => dispatch(actions.change(model, values))
  }
}


export default connect(null, mapDispatchToProps)(StaticAssignments);